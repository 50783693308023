import { PublicationContext } from 'centrifuge';
import { hasProperty } from 'utils/object';
import { TaskId } from '../task/types';
import { CentMessageDataV2 } from './internal';
import { CentCompanyEventBN } from './types-bn';
import { CentPrivateEventKontur } from './types-kontur';
import { CentPrivateEventNotice } from './types-notice';
import { CentPrivateEventWaGreen } from './types-wagreen';

export interface CentMessage extends PublicationContext {
  data: CentMessageData;
}

export interface CentMessageData {
  companyId: string;
  clientId: string;
  events: CentEvent[];
}
export const isCentMessageDataV1 = (data: unknown): data is CentMessageData =>
  hasProperty(data, 'events') &&
  Array.isArray(data.events) &&
  data.events.every((e) => e && typeof e.type === 'string');

export enum CentEventTypes {
  message = 'message',
  deletePost = 'deletePost',
  updatePost = 'updatePost',
  pinnedPost = 'pinnedPost',
  unPinnedPost = 'unPinnedPost',
  updateChannel = 'updateChannel',
  userTyping = 'userTyping',
  userEndTyping = 'userEndTyping',
  updateCompany = 'updateCompany',
  newMail2 = 'newMail2',
  moveMail = 'moveMail',
  deleteMail2 = 'deleteMail2',
  mailDraftUpdate = 'mailDraftUpdate',
  postReaction = 'postReaction',
  postRemoveReaction = 'postRemoveReaction',
}

//todo types
export interface CentEvent {
  type: CentEventTypes;
  data: unknown;
}

//

export * from './types-bn';
export * from './types-kontur';
export * from './types-notice';
export * from './types-pact';
export * from './types-wagreen';

export type CentPrivateEventKnown =
  | CentPrivateEventKontur
  | CentPrivateEventNotice
  // | CentPrivateEventPact
  | CentPrivateEventWaGreen;

export interface CentPrivateMessage extends PublicationContext {
  data: CentPrivateEventKnown;
}

// v2

export const enum CentTeamEventV2 {
  tasksCreated = 'tasksCreated',
}

export interface CentTeamDataTasksCreated {
  tasksId: TaskId[];
}
export type CentTeamTasksCreated = CentMessageDataV2<
  CentTeamEventV2.tasksCreated,
  CentTeamDataTasksCreated
>;

export type CentTeamMessageDataV2 = CentCompanyEventBN | CentTeamTasksCreated;
