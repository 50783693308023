import { useStore } from 'effector-react';
import { FC } from 'react';
import { Placement } from '@popperjs/core/lib/enums';
import { Popover2 } from 'ui/display';
import { $anchorEl } from './model';
import cl from './Tooltip.module.scss';

interface Props {}

export const Tooltip: FC<Props> = () => {
  const anchorEl = useStore($anchorEl);

  const dataset = anchorEl?.dataset;
  const placement = dataset?.placement as Placement | undefined;
  const noArrow = !!dataset?.noArrow;

  return (
    <Popover2
      isShow={!!anchorEl}
      anchorEl={anchorEl}
      withBackdrop={false}
      onClickOutside={() => {}}
      placement={placement ?? 'top'}
      className={cl.popover}
      offset={[0, 10]}
      withArrow={!noArrow}
    >
      {anchorEl &&
        (anchorEl.dataset.html ? (
          <div dangerouslySetInnerHTML={{ __html: anchorEl.dataset.tip ?? '' }} />
        ) : (
          anchorEl.dataset.tip
        ))}
    </Popover2>
  );
};
