import { LAYOUT_BREAKPOINT } from 'styles/_constants';

type BreakPoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type AdaptiveRange = BreakPoints | `=${BreakPoints}` | `<=${BreakPoints}`;

export const AdaptiveRangeClassTags: Readonly<Record<AdaptiveRange, string>> = {
  xs: '_up_xs',
  sm: '_up_sm',
  md: '_up_md',
  lg: '_up_lg',
  xl: '_up_xl',
  '=xs': '_only_xs',
  '=sm': '_only_sm',
  '=md': '_only_md',
  '=lg': '_only_lg',
  '=xl': '_only_xl',
  '<=xs': '_down_xs',
  '<=sm': '_down_sm',
  '<=md': '_down_md',
  '<=lg': '_down_lg',
  '<=xl': '_down_xl',
};

interface Opt {
  minWidth?: number;
  maxWidth?: number;
}
const buildQuery = ({ minWidth, maxWidth }: Opt = {}) =>
  [
    undefined !== minWidth && `(min-width: ${minWidth}px)`,
    undefined !== maxWidth && `(max-width: ${maxWidth}px)`,
  ]
    .filter(Boolean)
    .join(' and ') || 'all';

const threshold = 0.02;

export const AdaptiveRangeMediaQuery: Readonly<Record<AdaptiveRange, string>> = {
  xs: buildQuery(),
  sm: buildQuery({ minWidth: LAYOUT_BREAKPOINT.SM }),
  md: buildQuery({ minWidth: LAYOUT_BREAKPOINT.MD }),
  lg: buildQuery({ minWidth: LAYOUT_BREAKPOINT.LG }),
  xl: buildQuery({ minWidth: LAYOUT_BREAKPOINT.XL }),

  '=xs': buildQuery({
    maxWidth: LAYOUT_BREAKPOINT.SM - threshold,
  }),
  '=sm': buildQuery({
    minWidth: LAYOUT_BREAKPOINT.SM,
    maxWidth: LAYOUT_BREAKPOINT.MD - threshold,
  }),
  '=md': buildQuery({
    minWidth: LAYOUT_BREAKPOINT.MD,
    maxWidth: LAYOUT_BREAKPOINT.LG - threshold,
  }),
  '=lg': buildQuery({
    minWidth: LAYOUT_BREAKPOINT.LG,
    maxWidth: LAYOUT_BREAKPOINT.XL - threshold,
  }),
  '=xl': buildQuery({
    minWidth: LAYOUT_BREAKPOINT.XL,
  }),

  '<=xs': buildQuery({ maxWidth: LAYOUT_BREAKPOINT.SM - threshold }),
  '<=sm': buildQuery({ maxWidth: LAYOUT_BREAKPOINT.MD - threshold }),
  '<=md': buildQuery({ maxWidth: LAYOUT_BREAKPOINT.LG - threshold }),
  '<=lg': buildQuery({ maxWidth: LAYOUT_BREAKPOINT.XL - threshold }),
  '<=xl': buildQuery(),
};

export const adaptiveRangeToString = (range: AdaptiveRange | null | undefined) => {
  if (!range) {
    return null;
  }
  // if (typeof range === "string") {
  return AdaptiveRangeMediaQuery[range];
  // }

  // return range.map((r) => AdaptiveRangeMediaQuery[r]).join(",") || "all";
};
