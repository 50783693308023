import { reEmail, reUrlHost } from '../regexp';

export const enum TextNodeType {
  TEXT,
  URL_WEB,
  URL_MAILTO,
}
interface TextNodeBase {
  _pos: number;
  type: TextNodeType;
  content: string;
}
interface TextNodeText extends TextNodeBase {
  type: TextNodeType.TEXT;
}
interface TextNodeLink extends TextNodeBase {
  type: TextNodeType.URL_WEB | TextNodeType.URL_MAILTO;
  href: string;
}
export type TextNode = TextNodeText | TextNodeLink;

// `ws` - web abs
// `ws` - web short
const reWeb = new RegExp(`(?:(?:(?<wa>https?://)|(?<ws>(?=www\\.)))${reUrlHost.source}\\S+)`, 'u');
// `ma` - mail abs
// `ms` - mail short
const reMailto = new RegExp(`(?:(?<ma>mailto:${reEmail.source})|(?<ms>${reEmail.source}))`, 'u');

// Safari `(?<=...)`, `(?<!...)` https://caniuse.com/js-regexp-lookbehind
//const re = new RegExp(`(?<![\\p{L}\\p{N}])(?:${reWeb.source}|${reMailto.source})`, 'gu');
const re = new RegExp(
  `(?<skip>^|[^\\p{L}\\p{N}])(?<url>(?:${reWeb.source}|${reMailto.source}))`,
  'gu',
);

export const parseTextMessageToRender = (input: string) => {
  const result: TextNode[] = [];
  let pos = 0;
  input = String(input);
  for (const m of input.matchAll(re)) {
    // `m` должен иметь тип `RegExpExecArray` - разве нет?
    const g = m.groups;
    if (g) {
      const urlIndex = m.index! + g['skip'].length;

      if (urlIndex > pos) {
        result.push({
          _pos: pos,
          type: TextNodeType.TEXT,
          content: input.substring(pos, urlIndex),
        });
      }

      let type = TextNodeType.URL_WEB;
      let href = g['url'];
      if (g['ms'] !== undefined) {
        type = TextNodeType.URL_MAILTO;
        href = 'mailto:' + href;
      } else if (g['ma'] !== undefined) {
        type = TextNodeType.URL_MAILTO;
      } else if (g['ws'] !== undefined) {
        href = 'http://' + href;
      }

      result.push({
        _pos: urlIndex,
        type,
        content: g['url'],
        href,
      });
    } else {
      result.push({
        _pos: m.index!,
        type: TextNodeType.TEXT,
        content: m[0],
      });
    }

    pos = m.index! + m[0].length;
  }
  if (pos < input.length) {
    result.push({
      type: TextNodeType.TEXT,
      content: input.substring(pos),
      _pos: pos,
    });
  }

  return result;
};
