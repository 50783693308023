import { dataOnly, downloadInstance } from 'api/instance';
import { ApiResponse2, PaginatedResponse2, PaginationParams } from 'api/types';
import { TagId } from 'models/tags/types';
import {
  AssigneesTasksBulkForm,
  DeleteBulkForm,
  StatDay,
  StatWeek,
  TaskAssigneeUpdateForm,
  TaskCreateForm,
  TaskEditForm,
  TaskFromApi,
  TaskId,
  TaskPriority,
  TaskStatus,
  TaskTypicalEditForm,
  TaskTypicalSettings,
  TaskTypicalSettingsUpdateForm,
} from 'models/task/types';
import { CompanyId } from 'models/company/types';
import { UserId } from 'models/user/types';
import { CustomerId } from 'models/customer/types';
import { WithBookkeeper, WithCustomer } from '../../types/request';

export type TaskListMode = 'all' | 'assigned_to_me' | 'assigned_by_me' | 'reviewed_by_me';
export type TaskType =
  | 'all'
  | 'typical_tasks'
  | 'user_tasks'
  | 'customer_tasks'
  | 'tax'
  | 'user_local_tasks';
export type TaskListExportFormat = 'pdf' | 'xls';
export type AdditionalDataType = 'checklists' | 'timer-started-anybody';

export interface TaskListFilter {
  /** идентификатор бухкомпании, к которой привязаны задачи */
  bookkeeper_team_id: CompanyId;
  /** TEMP пользователь, который делает запрос */
  user_id?: UserId;
  /** фильтр по идентификатору клиентской компании, к которой привязаны задачи. */
  team_id?: CustomerId;
  /** Режим списка:
   all - все задачи

   assigned_to_me - «Мои задачи»
   1. созданные пользователем, но без назначенных ответственных;
   2. назначенные пользователю;
   3. типовые задачи клиентов, в которых пользователь ответственный бух.

   assigned_by_me - «Я поручил» */
  list_mode: TaskListMode;
  /** Фильтрация по типу задач:
   all - все задачи
   typical_tasks - типовые задачи
   user_tasks - пользовательские задачи
   user_local_tasks — задачи сотрудников бухкомпании (которые без клиентской компании)
   tax - налоговые задачи (типовые + рассчитываемые)
   */
  task_type: TaskType;

  date_from?: string;

  date_to?: string;
  /** Выборка по фильтрам date_from—date_to осуществляется по полю date_end. Иначе по date_start, date_end, status_updated_at. */
  date_end_filter?: 'true' | undefined;
  date_status_filter?: 'true' | undefined;
  /** Task Status */
  status?: TaskStatus;
  /** Priority */
  priority?: TaskPriority;
  /** Фильтр по идентификатору пользователя, назначенному на выполнение задач */
  assignee?: UserId;
  /** Вернуть задачи, у которых только 1 ответственный*/
  has_one_assignee?: boolean;
  /** Вернуть задачи, у которых нет ответственных. Не сочетается с has_one_assignee, assignee и list_mode != all. */
  has_no_assignee?: boolean;
  /** Какие доп. данные вернуть:*/
  load?: AdditionalDataType[];
  /** Фильтр по тегам (локальным и глобальным, не важно) */
  tags?: TagId[];
  /** Выборка по фильтру непрочитанных уведомлений о новых задачах.*/
  is_from_notices?: boolean;
  /**
   * Исключить из выборки личные задачи сотрудников (автор задачи == единственный исполнитель).
   * При этом, собственные задачи пользователя, от имени которого производится запрос, возвращаются как обычно.
   *
   * Не влияет на типовые задачи.
   *
   * @see https://gitlab.com/finkoper_front/slack-accounting-web/-/issues/157
   * @see https://gitlab.com/finkoper/finwork-tasks/-/issues/81
   */
  exclude_users_self_tasks?: boolean;

  include_deleted?: boolean;
  /** Поиск по тексту задачи. Поддерживает синтаксис:
    "поиск по фразе" один or два  -не_встречается */
  search?: string;
}

export interface TaskListParams extends TaskListFilter, PaginationParams {}

export interface TaskListExportParams extends TaskListFilter {
  format: TaskListExportFormat;
}

export interface TaskGetParams {
  task_id: TaskId;
  bookkeeper_team_id: CompanyId;
}

export interface TaskTypicalDeleteParams {
  task_id: TaskId;
  bookkeeper_team_id: CompanyId;
  team_id: CustomerId | null;
}

export interface TaskTypicalClearCacheParams {
  bookkeeper_team_id: CompanyId;
  team_id: CustomerId | null;
  userId?: UserId;
}

export interface AnalyticsParams {
  /** идентификатор бухкомпании, к которой привязаны задачи */
  bookkeeper_team_id: CompanyId;
  date: string;
}

export type AnalyticsMode =
  | 'open/expired_open'
  | 'expired_open/expired_closed'
  | 'closed/expired_closed';

export interface AnalyticsMonthParams extends AnalyticsParams {
  mode: AnalyticsMode;
}

const api = {
  list: (params: TaskListParams, signal?: AbortSignal) =>
    dataOnly.get<null, ApiResponse2<TaskFromApi[], true> & PaginatedResponse2>(`/api2/tasks`, {
      params,
      signal,
    }),

  listExport: (params: TaskListExportParams) =>
    downloadInstance.get<null, string>(`/api2/tasks/export`, { params }),

  calendarExport: (params: TaskListParams) =>
    downloadInstance.get<null, string>(`/api2/tasks/export/calendar`, { params }),

  get: ({ task_id, ...params }: TaskGetParams) =>
    dataOnly.get<null, ApiResponse2<TaskFromApi>>(`/api2/tasks/${task_id}`, { params }),

  create: (form: TaskCreateForm) =>
    dataOnly.post<null, ApiResponse2<TaskFromApi | TaskFromApi[]>>(`/api2/tasks`, form),

  delete: (taskId: TaskId, userId?: UserId) =>
    dataOnly.delete<null, ApiResponse2>(`/api2/tasks/${taskId}`, { data: { userId } }),

  /** Удалить перечисленные задачи */
  deleteBulk: (data: DeleteBulkForm) =>
    dataOnly.delete<null, ApiResponse2>(`/api2/tasks`, { data }),

  patch: (taskId: TaskId, form: Partial<TaskEditForm>) =>
    dataOnly.patch<null, ApiResponse2<TaskFromApi>>(`/api2/tasks/${taskId}`, form),

  getTypical: ({ task_id, ...params }: TaskTypicalDeleteParams) =>
    dataOnly.get<null, ApiResponse2<TaskFromApi>>(`/api2/tasks/typical/${task_id}`, { params }),

  /** обновление типовой задачи */
  updateTypical: (taskId: TaskId, form: TaskTypicalEditForm) =>
    dataOnly.put<null, ApiResponse2<TaskFromApi>>(`/api2/tasks/typical/${taskId}`, form),

  /** Удалить типовую задачу */
  deleteTypical: (params: TaskTypicalDeleteParams) =>
    dataOnly.delete<null, ApiResponse2>(`/api2/tasks/typical/${params.task_id}`, {
      data: { bookkeeper_team_id: params.bookkeeper_team_id, team_id: params.team_id },
    }),

  /** Аналитика по дням */
  analyticsByDay: (params: AnalyticsParams) =>
    dataOnly.get<null, ApiResponse2<StatDay>>(`/api2/tasks/analytics/by-day`, { params }),
  /** Аналитика по месяцам */
  analyticsByMonth: (params: AnalyticsMonthParams) =>
    dataOnly.get<null, ApiResponse2<StatWeek[] | null>>(`/api2/tasks/analytics/by-month`, {
      params,
    }),

  /** Добавить/удалить ответственного бухгалтера у всех открытых задач клиентской компании*/
  assigneesTasks: (form: TaskAssigneeUpdateForm) =>
    dataOnly.post<null, ApiResponse2<void>>(`/api2/tasks/assignees`, form),

  /** Изменить ответственных бухгалтеров у перечисленных задач*/
  assigneesTasksBulk: (form: AssigneesTasksBulkForm) =>
    dataOnly.post<null, ApiResponse2<void>>(`/api2/tasks/bulk`, form),

  /** Получить настройки типовых задач */
  typicalSettings: (customerId: CustomerId) =>
    dataOnly.get<null, ApiResponse2<TaskTypicalSettings>>(`/api2/tasks/typical/settings`, {
      params: { teamID: customerId },
    }),

  /** @deprecated Но снова используется при добавлении нового пользователя в компанию и переназначении его в автоматических задачах */
  typicalSettingsUpdateOld: (data: WithBookkeeper & WithCustomer) =>
    dataOnly.post<null, ApiResponse2<TaskTypicalSettings>>(`/api2/tasks/typical/settings`, data),

  typicalSettingsUpdate: (data: TaskTypicalSettingsUpdateForm) =>
    dataOnly.patch<null, ApiResponse2<TaskTypicalSettings>>(`/api2/tasks/typical/settings`, data),

  /** Восстановить удалённую задачу */
  restore: (taskId: TaskId) => dataOnly.post(`/api2/tasks/${taskId}`, {}),
};

export default api;
