import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  isEqual,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfSecond,
} from 'date-fns';
import { createEffect, createEvent, createStore, Event, sample } from 'effector';
import * as RoMap from '@cubux/readonly-map';
import { EMPTY_MAP } from 'constants/utils';
import { instancesCounters } from '../utils/instancesCounters';

export type Unit = 'd' | 'h' | 'm' | 's';

const startOf: Record<Unit, (date: Date) => Date> = {
  d: startOfDay,
  h: startOfHour,
  m: startOfMinute,
  s: startOfSecond,
};
const addOne: Record<Unit, (date: Date) => Date> = {
  d: (d) => addDays(d, 1),
  h: (d) => addHours(d, 1),
  m: (d) => addMinutes(d, 1),
  s: (d) => addSeconds(d, 1),
};

type TId = ReturnType<typeof setTimeout>;

const onFreeTimeout = createEvent<Unit>();
const onFreeTimeoutFx = createEffect(({ tid }: { unit: Unit; tid: TId }) => clearTimeout(tid));

const onUpdate = createEvent<Unit>();
const onUpdateTimeoutFx = createEffect(({ unit, tid }: { unit: Unit; tid?: TId }) => {
  if (tid) {
    clearTimeout(tid);
  }

  const now = new Date();
  const date = startOf[unit](now);
  const next = addOne[unit](date);
  return {
    date,
    tid: setTimeout(() => onUpdate(unit), next.getTime() - now.getTime() + 50),
  };
});

const refreshWithWantedFx = createEffect(
  ({ prev, wanted }: { prev: ReadonlyMap<Unit, TId>; wanted: ReadonlySet<Unit> }) => {
    for (const unit of [...prev.keys()]) {
      if (!wanted.has(unit)) {
        onFreeTimeout(unit);
      }
    }

    for (const unit of [...wanted]) {
      if (!prev.has(unit)) {
        onUpdate(unit);
      }
    }
  },
);

const { $wantedNames, watchInstance, addInstance, removeInstance } = instancesCounters<Unit>();
// $wantedNames.watch((d) => console.log('>> wanted', d));
// onUpdateTimeoutFx.done.watch(({ params, result }) =>
//   console.log('>> update', params, result, 'now', new Date()),
// );
// onFreeTimeoutFx.done.watch(({ params, result }) =>
//   console.log('>> free', params, result, 'now', new Date()),
// );

const _$timeout = createStore<ReadonlyMap<Unit, TId>>(EMPTY_MAP)
  .on(onFreeTimeoutFx.done, (map, { params: { unit } }) => RoMap.remove(map, unit))
  .on(onUpdateTimeoutFx.done, (map, { params: { unit }, result: { tid } }) =>
    RoMap.set(map, unit, tid),
  );
sample({
  clock: $wantedNames,
  source: _$timeout,
  fn: (prev, wanted) => ({ prev, wanted }),
  target: refreshWithWantedFx,
});

sample({
  clock: onFreeTimeout,
  source: _$timeout,
  filter: (map, unit) => map.has(unit),
  fn: (map, unit) => ({ unit, tid: map.get(unit)! }),
  target: onFreeTimeoutFx,
});
sample({
  clock: onUpdate,
  source: _$timeout,
  fn: (map, unit) => ({ unit, tid: map.get(unit) }),
  target: onUpdateTimeoutFx,
});

export const watchUnit = watchInstance;

interface GateLike {
  open: Event<any>;
  close: Event<any>;
}
export const watchUnitByGate = (unit: Unit, gate: GateLike) => {
  const o = gate.open.watch(() => addInstance(unit));
  const c = gate.close.watch(() => removeInstance(unit));
  return () => {
    c();
    o();
  };
};

export const $currentDatesMap = createStore<ReadonlyMap<Unit, Date>>(EMPTY_MAP)
  .on($wantedNames, (map, wanted) => RoMap.filter(map, (_, unit) => wanted.has(unit)))
  .on(onUpdateTimeoutFx.done, (map, { params: { unit }, result: { date } }) =>
    RoMap.set(map, unit, date),
  );

/**
 * Часто используемое "сегодня". Для адекватного значения надо применять
 * `watchUnit('d')` или `watchUnitByGate('d', SomeGate)`
 */
export const $today = $currentDatesMap.map<Date>((map, prev) => {
  const next = map.get('d') ?? startOfDay(new Date());
  return prev && isEqual(prev, next) ? prev : next;
});
