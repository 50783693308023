import { ChangeEventHandler, FC, useCallback, useRef, useState } from 'react';
import { Input } from 'ui/input/Input';
import { TextButton2 } from '../Button';
import cn from 'classnames';
import cl from './SearchButton.module.scss';

interface Props {
  className?: string;
  inputClassName?: string;
  buttonClassName?: string;
  expandDirection?: 'left' | 'right';
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const SearchButton: FC<Props> = ({
  className,
  inputClassName,
  buttonClassName,
  expandDirection = 'right',
  onChange,
}) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    setFocused(true);

    inputRef.current?.focus();
  }, []);

  return (
    <div
      className={cn(
        cl.root,
        focused && cl.focused,
        expandDirection === 'left' && cl.expandLeft,
        className,
      )}
    >
      <Input
        className={cn(cl.input, inputClassName)}
        ref={inputRef}
        inputSize="small"
        allowClear
        onChange={onChange}
        onClickClear={() => {
          if (!inputRef.current?.value) {
            setFocused(false);
          } else {
            inputRef.current?.focus();
          }
        }}
      />
      <TextButton2
        size="small"
        icon="Search"
        onClick={handleClick}
        className={cn(cl.btn, buttonClassName)}
      />
    </div>
  );
};
