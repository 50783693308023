export type { ApiResponse2 as ApiResponse };
export interface ApiResponse2<Data = any, Null extends boolean = false> {
  status?: boolean;
  data: Null extends true ? Data | null : Data;
  message?: string;
}

const _DEV = process.env.NODE_ENV === 'development' ? { _$_stub: true } : undefined;
export const stubApiResponse2 = <Data>(
  data: Data,
): ApiResponse2<Data, null extends Data ? true : false> => ({
  status: true,
  data,
  ..._DEV,
});

export interface PaginationParams {
  per_page?: number;
  page?: number;
}
export interface PaginatedResponse2 {
  page: number;
  per_page: number;
  total: number;
}

export const simplifyPagination = (
  { page = 1, per_page }: PaginationParams,
  backendDefaultPerPage = 0,
): PaginationParams => ({
  page: page > 1 ? page : undefined,
  per_page: per_page === backendDefaultPerPage ? undefined : per_page,
});

/**
 * Чё-то в прошлые разы наигрался я уже на api2 с локальной отладкой АПИ, подменяя
 * аналогичный адрес на местах. Так будет проще как с точки зрения копи-пасты
 * реального урла, так и с точки зрения отладки отдельно взятых АПИ запросов.
 *
 * ```ts
 * export const apiFoo = () =>
 *   dataOnly.get<ApiResponseEmail<Foo>>(`${API_EMAIL_REMOTE}/v1/foo`);
 *
 * export const apiBar = () =>
 *   dataOnly.get<ApiResponseEmail<Bar>>(`${API_EMAIL_LOCAL}/v1/bar`);
 * ```
 */
export const API_EMAIL_REMOTE = '/api-email';
/** @deprecated Только для локальной отладки, не для деплоя куда-либо. */
export const API_EMAIL_LOCAL =
  process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8065/api' : API_EMAIL_REMOTE;

export const API_CORE_REMOTE = '/api';
/** @deprecated Только для локальной отладки, не для деплоя куда-либо. */
export const API_CORE_LOCAL =
  process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8166/api' : API_CORE_REMOTE;

export const API_TASK_REMOTE = '/api2';
/** @deprecated Только для локальной отладки, не для деплоя куда-либо. */
export const API_TASK_LOCAL =
  process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8080/api' : API_TASK_REMOTE;

export const API_INTGR_REMOTE = '/api3';
/** @deprecated Только для локальной отладки, не для деплоя куда-либо. */
export const API_INTGR_LOCAL =
  process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8081/api' : API_INTGR_REMOTE;
