import { createEffect, restore } from 'effector';
import { createGate, useGate, useStore } from 'effector-react';
import { gateOr } from './utils/multigate';

// https://trello.com/c/2L2EAzCN/1354

// const getVisibility = (_?: unknown) => window.document.visibilityState === 'visible';
const getHasFocus = (_?: unknown) => window.document.hasFocus();

// ------------------

const Gate = createGate();
export const useAppActiveTrack = () => useGate(Gate);

// const onVisibilityChangedFx = createEffect(getVisibility);
const onHasFocusChangedFx = createEffect(getHasFocus);
const orGate = gateOr(Gate);
orGate.open.watch(() => {
  // window.document.addEventListener('visibilitychange', onVisibilityChangedFx);
  window.addEventListener('focus', onHasFocusChangedFx);
  window.addEventListener('blur', onHasFocusChangedFx);
  // window.addEventListener('pageshow', _);
  // window.addEventListener('pagehide', _);
});
orGate.close.watch(() => {
  // window.document.removeEventListener('visibilitychange', onVisibilityChangedFx);
  window.removeEventListener('focus', onHasFocusChangedFx);
  window.removeEventListener('blur', onHasFocusChangedFx);
  // window.removeEventListener('pageshow', _);
  // window.removeEventListener('pagehide', _);
});

// ------------------

// export const isAppVisible: () => boolean = getVisibility;
// export const $isAppVisible = restore(onVisibilityChangedFx.doneData, getVisibility()).reset(
//   orGate.close,
// );
// export const useIsAppVisible = () => useStore($isAppVisible);
// // $isAppVisible.watch((p) => console.log('>> $isAppVisible', new Date().toISOString(), p));

// ------------------

export const isAppFocused: () => boolean = getHasFocus;
export const $isAppFocused = restore(onHasFocusChangedFx.doneData, getHasFocus()).reset(
  orGate.close,
);
export const useIsAppFocused = () => useStore($isAppFocused);
// $isAppFocused.watch((p) => console.log('>> $isAppFocused', new Date().toISOString(), p));

// =====================

// export const isAppActive = () => getHasFocus() && getVisibility();
// export const $isAppActive = and($isAppFocused, $isAppVisible);
// export const useIsAppActive = () => useStore($isAppActive);
// // $isAppActive.watch((p) => console.log('>> $isAppActive', new Date().toISOString(), p));
