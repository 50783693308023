import { RefObject } from 'react';
import { useElementClientSize } from 'ui/utils/useElementClientSize';
import { useElementScrollOffset } from 'ui/utils/useElementScrollOffset';

interface Options {
  viewport: RefObject<HTMLElement>;
  top: RefObject<HTMLElement>;
  left: RefObject<HTMLElement>;
  data: RefObject<HTMLElement>;
  dataCols: number;
  dataRows: number;
}

// Взято из sple почти в неизменном виде. Только здесь у нас добавляются
// фиксированные поля, которые закрывают часть viewport и уменьшают реальную
// видимую часть

export function useVisibleGridRect({ viewport, top, left, data, dataCols, dataRows }: Options) {
  // v_ - viewport
  // t_ - top (фиксированные верх)
  // l_ - left (фиксированные бок)
  // d_ - data
  //
  // _w,_h - width,height
  // _l,_t - left,top
  const [vw, vh] = useElementClientSize(viewport);
  const [vl, vt] = useElementScrollOffset(viewport);
  // когда нет фиксированных краёв, нет их элементов, нет и размеров (undefined)
  const [, th = 0] = useElementClientSize(top);
  const [lw = 0] = useElementClientSize(left);
  const [dw, dh] = useElementClientSize(data);

  // actual/available viewport - полезная площадь без фиксированных сторон
  const avw = vw && vw - lw;
  const avh = vh && vh - th;

  const [x, w] = getEdgeTiles(avw, dw, vl, dw && dw / dataCols, dataCols);
  const [y, h] = getEdgeTiles(avh, dh, vt, dh && dh / dataRows, dataRows);
  // useEffect(
  //   () =>
  //     console.log(
  //       '>>',
  //       { vw, vh },
  //       { vl, vt },
  //       { th },
  //       { lw },
  //       { dw, dh },
  //       { avw, avh },
  //       { x, w },
  //       { y, h },
  //     ),
  //   [vw, vh, vl, vt, th, lw, dw, dh, avw, avh, x, w, y, h],
  // );

  return {
    x,
    y,
    w,
    h,
    isXScrolled: Math.round(vl) >= 2,
    isYScrolled: Math.round(vt) >= 2,
  };
}

const VIEWPORT_PADDING = 8;

const getEdgeTiles = (
  viewportLength: number | undefined,
  canvasLength: number | undefined,
  scrollOffset: number,
  tileSize: number | undefined,
  maxTiles: number,
): [tileStart: number, tilesCount: number] => {
  if (!canvasLength || !viewportLength || viewportLength < 0 || !tileSize) {
    return [0, 0];
  }

  // viewport >= canvas:
  //
  // --------------------------------------------  viewport
  //           -------------------                 canvas
  if (viewportLength >= canvasLength) {
    return [0, maxTiles];
  }

  // viewport < canvas:
  //
  // scroll offset
  // >>>>>>>>>>
  //           -------------------                 viewport
  // --------------------------------------------  canvas
  // ...'''...'''...'''...'''...'''...'''...'''... tiles
  //
  //       [=== taking this tiles ===]
  //         ^                     ^
  //         '---------------------'----- VIEWPORT_PADDING + 0.25 * tileSize
  //
  // abs indices
  let startN = Math.floor((scrollOffset - VIEWPORT_PADDING) / tileSize - 0.25);
  let endN = Math.ceil((scrollOffset + viewportLength + VIEWPORT_PADDING) / tileSize + 0.25);
  // correct indices
  if (startN < 0) {
    startN = 0;
  }
  if (endN > maxTiles) {
    endN = maxTiles;
  }

  return [startN, Math.min(maxTiles, endN - startN)];
};
