import cn from 'classnames';
import type { NoInfer } from 'effector';
import { TFunction, TFunctionResult } from 'i18next';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IView } from '../../types';
import cl from './RadioButtons.module.scss';

// export interface RadioButtonChildProps<ValueType> extends IView {
//   value: ValueType;
// }

type TFn = (t: TFunction) => TFunctionResult;
type TextLike = TFn | ReactNode;

export interface RadioButtonExt<ValueType> {
  reactKey?: string | number;
  label: TextLike;
  value: ValueType;
  description?: TextLike;
  // TODO: если понадобится: childFC?: FC<RadioButtonChildProps<NoInfer<ValueType>>>;
  // TODO: если понадобится: children?: ReactNode;
}
export interface RadioButton<ValueType> extends RadioButtonExt<ValueType> {
  label: TFn;
  description?: TFn;
}

type Orientation = 'row' | 'column';
const CL_ORIENTATION: Record<Orientation, string> = {
  row: cl.row,
  column: cl.column,
};

type PointSize = 'default' | 'big';
const CL_POINT_SIZE: Record<PointSize, string> = {
  default: cl._defaultPoint,
  big: cl._bigPoint,
};

export interface RadioButtonsCustomizeProps extends IView {
  orientation?: Orientation;
  pointSize?: PointSize;
  disabled?: boolean;
}

export interface Props<ValueType> extends RadioButtonsCustomizeProps {
  buttons: RadioButtonExt<ValueType>[];
  value?: NoInfer<ValueType>;
  onChange: (value: NoInfer<ValueType>) => void;
  // REFACT: не очень красиво, но с хода лучше не придумал
  childrenMap?: ReadonlyMap<ValueType, ReactNode>;
}

export function RadioButtons<ValueType>({
  buttons,
  value,
  onChange,
  orientation = 'row',
  pointSize,
  className,
  disabled,
  childrenMap,
}: Props<ValueType>) {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        cl.root,
        CL_ORIENTATION[orientation],
        pointSize && CL_POINT_SIZE[pointSize],
        className,
      )}
    >
      {buttons.map((c) => {
        let children: ReactNode;
        let childrenWrap = true;
        // if (c.children !== undefined) {
        //   children = c.children;
        // } else if (c.childFC) {
        //   const C = c.childFC;
        //   children = <C value={c.value} className={cl.children} />;
        //   childrenWrap = false;
        // } else {
        children = childrenMap?.get(c.value);
        // }
        const hasChildren = children !== undefined;
        if (hasChildren && childrenWrap) {
          children = <div className={cl.children}>{children}</div>;
        }

        const key = c.reactKey ?? String(c.value);
        const clActive = c.value === value && cl.active;
        const button = (
          <button
            key={hasChildren ? undefined : key}
            type="button"
            className={cn(
              !hasChildren && cl.item,
              cl.button,
              clActive,
              c.description && cl._withDesc,
            )}
            onClick={() => onChange(c.value)}
            disabled={disabled}
          >
            <div className={cl.point} />
            <LabelAndDesc t={t} label={c.label} description={c.description} />
          </button>
        );
        if (!hasChildren) {
          return button;
        }
        return (
          <div key={key} className={cn(cl.item, cl.group, clActive)}>
            {button}
            {children}
          </div>
        );
      })}
    </div>
  );
}

const LabelAndDesc: FC<{ t: TFunction; label: TextLike; description: TextLike }> = ({
  label,
  description,
  t,
}) =>
  description ? (
    <>
      <div className={cl.label}>{renderText(label, t)}</div>
      <div className={cl.desc}>{renderText(description, t)}</div>
    </>
  ) : (
    // обратная совместимость: никаких обёрток
    <>{renderText(label, t)}</>
  );

const renderText = (text: TextLike, t: TFunction): ReactNode => {
  if (typeof text === 'function') {
    return text(t);
  }
  return text;
};
