import { createEvent, createStore, sample } from 'effector';
import { ChangeEvent } from 'react';
import * as RoSet from '@cubux/readonly-set';
import { EMPTY_SET } from 'constants/utils';
import { $customersLightActiveId } from 'models/customer/list-light';
import { CustomerId } from 'models/customer/types';

export const customerClicked = createEvent<ChangeEvent<HTMLInputElement>>();
export const customersUpdated = createEvent<CustomerId[]>();
export const selectAll = createEvent<any>();
export const unselectAll = createEvent<any>();
export const selectChangedTrigger = [customerClicked, selectAll, unselectAll];

export const filterChanged = createEvent<ChangeEvent<HTMLInputElement>>();

export const $selectedSet = createStore<ReadonlySet<CustomerId>>(EMPTY_SET).reset(unselectAll);

export const $filter = createStore<string>('');

$selectedSet
  .on(customerClicked, (s, { target: { name, checked } }) =>
    checked ? RoSet.add(s, name as CustomerId) : RoSet.remove(s, name as CustomerId),
  )
  .on(customersUpdated, (_, ids) => new Set(ids));
sample({
  clock: selectAll,
  source: $customersLightActiveId,
  target: $selectedSet,
});

$filter.on(filterChanged, (_, e) => e.target.value.toLowerCase());
