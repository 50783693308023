import { Optional } from '../types';

const defaultPredicate = (_: any, value: any) => value !== undefined;

export const filterObject = <T extends object, K extends keyof T = keyof T>(
  input: T,
  predicate: (key: K, value: T[K], source: T) => unknown = defaultPredicate,
): Optional<T, K> => {
  let out: Partial<T> | undefined = {};
  for (let key of Object.keys(input) as K[]) {
    const value = input[key];
    if (predicate(key, value, input)) {
      out[key] = value;
    }
  }
  return out as Optional<T, K>;
};
