/**
 * Сортировка с применением преобразования Шварца
 *
 * 1. На первом этапе `sortDecorate(to, from)` задаём, как трансформировать исходный
 *     элемент во внутренний и обратно.
 * 2. Далее `sortDecorate(...)(cmp)` задаём, как сравнивать два элемента
 *     внутреннего представления. Получаем настроенную функцию сортировки.
 *
 * Полученная функция сортировки всегда возвращает новый массив, не трогая
 * исходный (в отличае от `[].sort()`).
 *
 * ```ts
 * interface C {
 *   id: number;
 *   sort: number;
 *   title: string;
 * }
 *
 * const sortC = sortDecorate(
 *   (c: C) => [c, c.title.toUpperCase()] as const,
 *   ([c]) => c,
 * )(
 *   ([a, aT], [b, bT]) => a.sort - b.sort || strCmp(aT, bT) || a.id - b.id.
 * );
 * ```
 */
export const sortDecorate =
  <Value, Decorated>(decorate: (v: Value) => Decorated, undecorate: (d: Decorated) => Value) =>
  (cmp: (a: Decorated, b: Decorated) => number) =>
  (list: readonly Value[]): Value[] =>
    list.map(decorate).sort(cmp).map(undecorate);
