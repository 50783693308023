import * as RoMap from '@cubux/readonly-map';
import * as RoSet from '@cubux/readonly-set';
import {
  $channelExtendedMap,
  $channelsByGroup,
  $channelsCommonMap,
  $channelsList,
  $channelToGlobalIdMap,
  $channelTypingMap,
  $currentChannel,
  $globalToChannelIdMap,
  $hasNewPosts,
  $internalChannelsFetchInfo,
  $internalUsersChannels,
  $newPostsChannelsMap,
  $newPostsTotal,
  $showDeletedUsersChannels,
  $userAvatarMap,
  $userChannelList,
  addUserChannelFx,
  createChannelFx,
  CurrentChannelGate,
  deleteChannel,
  deleteChannelFx,
  deleteChannelPrompt,
  deleteUserChannel,
  deleteUserChannelFx,
  fetchChannels,
  getChannelsFx,
  getUserAvatarsFx,
  getUserChannelListFx,
  receiveTypingEnd,
  receiveTypingStart,
  receiveUpdateChannel,
  toggleShowDeletedUsersChannels,
  updateChannel,
  updateChannelFx,
  updateChannelId,
  updateChannelsByGroup,
  updateCurrentChannel,
} from './index';
import api from 'api/request/channel';
import apiUser from 'api/request/user';
import {
  Channel,
  ChannelCreateForm,
  ChannelGlobalId,
  ChannelGroup,
  ChannelId,
  ChannelUpdateParams,
  CustomerChannelsItem,
} from './types';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { COMPANY } from 'constants/config';
import { EMPTY_MAP, EMPTY_SET } from 'constants/utils';
import { $currentCompanyIdOrNull } from '../company';
import { CompanyId } from '../company/types';
import { isNotNull } from 'utils/fn';
import { history } from 'utils/history';
import { ObjectUrl, TimeoutId } from 'utils/types/primitive';
import { CommonUser, TypingData, UserId } from '../user/types';
import { $avatar, $user, $usersMap } from '../user';
import i18next from 'i18next';
import { promptFn } from 'ui/feedback';
import { toastErrorFx } from 'models/utils/messages';
import { logout } from '../auth';
import { mainChatData, seenPostFx } from '../post';

getChannelsFx.use(async (companyId: CompanyId) => await api.list2(companyId));
createChannelFx.use(async (form: ChannelCreateForm) => await api.create(form));

createChannelFx.done.watch(async ({ params: { companyId } }) => {
  await getChannelsFx(companyId);
});

$channelsByGroup
  .on(getChannelsFx.doneData, (_, { data }) => data)
  .on(seenPostFx.doneData, (s, { data }) => {
    const { channel_id, name, avatar, id, ...rest } = data;
    if (s) {
      return updateChannelsByGroup(s, channel_id!, rest);
    }
  });

//проверим, вдруг нас удалили из текущего канала
$channelExtendedMap.watch((e) => {
  const { channelId } = CurrentChannelGate.state.getState() || {};
  if (channelId && !e.get(channelId)) history.replace('/channels');
});

$internalChannelsFetchInfo
  .on(getChannelsFx.pending, (s, isFetching) => ({ ...s, isFetching }))
  .on(getChannelsFx.doneData, (s) => ({ ...s, didFetched: true }));

sample({
  clock: $channelsList,
  fn: (c) => new Map(c.map((c) => [c.id, c.channel_id])),
  target: $globalToChannelIdMap,
});
$globalToChannelIdMap.on(updateChannelId, (s, { channelId, channelGlobalId }) => {
  return new Map(s).set(channelGlobalId, channelId);
});

sample({
  clock: $channelsList,
  fn: (c) => new Map(c.filter((c) => !!c.channel_id).map((c) => [c.channel_id as ChannelId, c.id])),
  target: $channelToGlobalIdMap,
});
$channelToGlobalIdMap.on(updateChannelId, (s, { channelId, channelGlobalId }) => {
  return new Map(s).set(channelId, channelGlobalId);
});

/** $showDeletedUsersChannels */
$showDeletedUsersChannels.on(toggleShowDeletedUsersChannels, (s) => !s);

/** $globalChannelId id канала из урл */
sample({
  clock: [/*CurrentChannelGate.state,*/ $internalChannelsFetchInfo, mainChatData.ChatGate.open],
  source: {
    // f: $internalChannelsFetchInfo,
    m: $channelsCommonMap,
    c: mainChatData.ChatGate.state,
    channelId: mainChatData.$currentChannelGlobalId,
  },
  filter: ({ m, c, channelId }) =>
    /*f.didFetched &&*/ !!c.channelId && channelId !== c.channelId && m.has(c.channelId),
  fn: ({ m, c }) => m.get(c.channelId!)!,
  target: mainChatData.setCurrentChannel,
});

/** $currentChannel */
sample({
  source: [CurrentChannelGate.state, $channelExtendedMap] as const,
  target: $currentChannel,
  fn: ([props, map]) => (!props || !map ? null : map.get(props.channelId) ?? null),
});

/** $newPostsChannelsMap */
sample({
  clock: $channelsCommonMap,
  fn: (c) => {
    const map = new Map<ChannelGlobalId, number>();
    c.forEach(({ id, user_message_count, total_message_count }) =>
      map.set(id, (total_message_count || 0) - (user_message_count || 0)),
    );

    return map;
  },
  target: $newPostsChannelsMap,
});

/** $newPostsTotal */
//Отобразим в title страницы количество новых сообщений
$newPostsTotal.watch((cnt) => {
  if (!cnt) document.title = COMPANY;
  else document.title = `(${cnt > 99 ? '99+' : cnt}) ${COMPANY}`;
});

if (process.env.REACT_APP_ENV === 'electron') {
  $hasNewPosts.watch((hasPosts) => {
    // @ts-ignore
    console.log('window.ipcRenderer', window.ipcRenderer);
    // @ts-ignore
    if (!window.ipcRenderer) return;
    if (hasPosts) {
      // @ts-ignore
      window.ipcRenderer.send('hasPosts', 1);
    } else {
      // @ts-ignore
      window.ipcRenderer.send('hasPosts', 0);
    }
  });
}

/** $channelsCommonMap */
$channelsCommonMap.on($channelsByGroup, (_, data) => {
  if (!data) return EMPTY_MAP;

  const map = new Map<ChannelGlobalId, Channel>();
  Object.keys(data).forEach((k) =>
    data[k as ChannelGroup]?.forEach((c1) => {
      if (k === 'customers') {
        (c1 as CustomerChannelsItem).channels.forEach((c2) => {
          map.set(c2.id, c2);
        });
      } else {
        map.set(c1.id as ChannelGlobalId, c1 as Channel);
      }
    }),
  );

  return map;
});
// $channelsCommonMap.on(seenPostFx.doneData, (s, { data }) => {
//   const channelId = $channelToGlobalIdMap.getState().get(data.id)!;
//   // @ts-ignore
//   delete data.id;
//
//   return new Map(s).set(channelId, { ...s.get(channelId)!, ...(data as SeenData) });
// });

/** Channel's user list */
getUserChannelListFx.use(async (channelId) => await api.userList(channelId));
$userChannelList.on(getUserChannelListFx.doneData, (_, { data }) => data);

deleteUserChannelFx.use(async ({ channelId, userId }) => await api.deleteUser(channelId, userId));
sample({
  clock: deleteUserChannel,
  source: $currentChannel,
  fn: (channel, userId) => ({ channelId: channel!.channel_id!, userId }),
  target: deleteUserChannelFx,
});

addUserChannelFx.use(
  async ({ channelId, userId, form }) => await api.addUser(channelId, userId, form),
);

sample({
  clock: [receiveUpdateChannel, fetchChannels],
  source: $currentCompanyIdOrNull,
  filter: isNotNull,
  target: getChannelsFx,
});

/** Typing */

// Тот, кто печатает:
// - Отправляет несколько typingStart с интервалами
// - Отправляет один typingStop, когда захочет (но по идее это теперь не обязательно)
//
// Тот, кто наблюдает на другой стороне:
// - пришло typingStart:
//   - добавил в store
//   - отменил предыдущий setTimeout
//   - setTimeout, чтобы удалить из store
// - пришло typingStop:
//   - удалил из store
//   - отменил предыдущий setTimeout
//
// (так делал и в userhorn)

// никакой trottle/debounce тут не подходит, т.к. по каждому каналу+юзеру идёт
// свой собственный таймаут
const typingDataToStr = ({ channelId, userId }: TypingData) => `${channelId}:${userId}`;
const willStopTyping = createEvent<TypingData>();
const abortStopTyping = createEvent<TypingData>();
const stopTyping = createEvent<TypingData>();
createStore<ReadonlyMap<string, TimeoutId>>(EMPTY_MAP)
  .reset(logout)
  .on(willStopTyping, (m, d) =>
    RoMap.updateDefault(m, typingDataToStr(d), null, (prev) => {
      if (prev) clearTimeout(prev);
      return setTimeout(() => stopTyping(d), 5000);
    }),
  )
  .on(abortStopTyping, (m, d) => {
    const k = typingDataToStr(d);
    const prev = m.get(k);
    if (!prev) return m;
    clearTimeout(prev);
    return RoMap.remove(m, k);
  });

receiveTypingStart.watch(willStopTyping);
receiveTypingEnd.watch(stopTyping);
stopTyping.watch(abortStopTyping);

$channelTypingMap
  .on(
    sample({
      clock: receiveTypingStart,
      source: $usersMap,
      fn: (userMap, data) => ({ userMap, data }),
    }),
    (map, { userMap, data }) => {
      let set = map.get(data.channelId);
      if (set && Array.from(set).some((u) => u.id === data.userId)) {
        return map;
      }
      const user = userMap.get(data.userId);
      if (!user) {
        return map;
      }
      set ??= EMPTY_SET;
      return RoMap.set(map, data.channelId, RoSet.add(set, user));
    },
  )
  .on(stopTyping, (map, data) =>
    RoMap.update(map, data.channelId, (set) =>
      RoSet.remove(set, [...set].find((c) => c.id === data.userId)!),
    ),
  );

export const toTypingNames = (set?: ReadonlySet<CommonUser> | null): string =>
  set ? [...set].map((c) => c.firstname).join(',') : '';

/** $userAvatarMap */
getUserAvatarsFx.use(async (params) => {
  const ret = await Promise.all(params.map(({ avatarId }) => apiUser.avatar(avatarId)));
  return ret.map((blob, i) => ({
    userId: params[i].userId,
    url: URL.createObjectURL(blob) as ObjectUrl,
  }));
});

sample({
  clock: $internalUsersChannels,
  source: [$internalUsersChannels, $userAvatarMap] as const,
  target: getUserAvatarsFx,
  fn: ([channels, map]) => {
    return channels
      .filter((c) => !!c.avatar && !map.has(c.id as unknown as UserId))
      .map((c) => ({ userId: c.id as unknown as UserId, avatarId: c.avatar }));
  },
});
$userAvatarMap.on(getUserAvatarsFx.doneData, (s, urls) => {
  let newMap = s;
  urls.forEach(({ userId, url }) => {
    const oldUrl = newMap.get(userId);
    if (oldUrl) URL.revokeObjectURL(oldUrl);

    newMap = RoMap.set(newMap, userId, url);
  });
  return newMap;
});

$userAvatarMap.on($avatar, (s, url) => {
  const userId = $user.getState()?.id;
  if (!userId) {
    return s;
  }
  if (url) return RoMap.set(s, userId, url);
  else return RoMap.remove(s, userId);
});

/** Delete channel */
deleteChannelFx.use(async (channelId: ChannelId) => await api.delete(channelId));
sample({ clock: deleteChannel, target: deleteChannelFx });
sample({
  clock: deleteChannelPrompt,
  target: createEffect(async (channelId: ChannelId) => {
    if (await promptFn(i18next.t('channel:deletePrompt'))) {
      deleteChannel(channelId);
    }
  }),
});
deleteChannelFx.done.watch(() => {
  history.replace('/channels');
});
sample({
  clock: deleteChannelFx.fail,
  target: toastErrorFx,
});

/** Update channel */
updateChannelFx.use(async (params) => await api.update(params));
sample({ clock: updateChannel, target: updateChannelFx });
sample({
  clock: updateCurrentChannel,
  source: $currentChannel,
  target: updateChannel,
  fn: (channel, form): ChannelUpdateParams => ({ channelId: channel!.channel_id!, form }),
});
