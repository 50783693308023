import { createEffect, createEvent, restore, sample } from 'effector';
import { createGate } from 'effector-react';
import isequal from 'lodash.isequal';
import { and, not } from 'patronum';
import * as RoArray from '@cubux/readonly-array';
import { apiCustomersUsersAllLight } from 'api/request/customer';
import { WithCustomer } from 'api/types/request';
import { boolNot, isNotNull } from 'utils/fn';
import { logout } from '../auth';
import { $currentCompanyIdOrNull } from '../company';
import { withBookkeeperFx } from '../utils/withBookkeeperFx';
import {
  CustomerUserLight,
  CustomerUserLightUnscoped,
  sortCustomerUsersLight,
} from './customer-user';
import { CustomerEmployee, CustomerEmployeeId, CustomerId } from './types';
import { EMPTY_ARRAY, EMPTY_MAP } from '../../constants/utils';

const fetchFx = withBookkeeperFx(createEffect(apiCustomersUsersAllLight));

export const CustomersUsersAllGate = createGate();
export const reloadCustomersUsers = createEvent<any>();
export const customerUserDidCreate = createEvent<CustomerEmployee & WithCustomer>();
export const customerUserDidUpdate = createEvent<CustomerEmployee & WithCustomer>();
export const customerUserDidDelete = createEvent<CustomerEmployeeId>();

const full2light = ({
  id,
  name,
  email,
  team_id,
}: CustomerEmployee & WithCustomer): CustomerUserLightUnscoped => ({
  id,
  cId: team_id,
  name,
  email,
});

const $allRaw = restore(
  fetchFx.doneData.map(({ data }) => data),
  null,
)
  .reset($currentCompanyIdOrNull, logout)
  .on(
    customerUserDidCreate,
    (list, add) => list && sortCustomerUsersLight([...list, full2light(add)]),
  )
  .on(customerUserDidUpdate, (list, upd) => {
    if (list) {
      const nextItem = full2light(upd);
      const nextList = RoArray.updateMatch(
        list,
        (c) => c.id === upd.id,
        (prev) => (isequal(prev, nextItem) ? prev : nextItem),
      );
      if (nextList !== list) {
        return sortCustomerUsersLight(nextList);
      }
    }
    return list;
  })
  .on(customerUserDidDelete, (list, id) => list && RoArray.removeMatch(list, (c) => c.id === id));

const $inCompany = $currentCompanyIdOrNull.map(isNotNull);
const $notFetched = $allRaw.map(boolNot);
// const $isFetched = $allRaw.map(isNotNull);

sample({
  clock: reloadCustomersUsers,
  filter: not(fetchFx.pending),
  target: fetchFx,
});
sample({
  clock: CustomersUsersAllGate.open,
  filter: and($inCompany, $notFetched),
  target: reloadCustomersUsers,
});

const $allDerived = $allRaw.map((raw) => {
  let byCustomer: ReadonlyMap<CustomerId, readonly CustomerUserLight[]> = EMPTY_MAP;
  // let allNames: ReadonlyMap<CustomerEmployeeId, string> = EMPTY_MAP;
  let byId: ReadonlyMap<CustomerEmployeeId, CustomerUserLight> = EMPTY_MAP;
  if (raw) {
    const byC = new Map<CustomerId, CustomerUserLight[]>();
    // const allN = new Map<CustomerEmployeeId, string>();
    const byId_ = new Map<CustomerEmployeeId, CustomerUserLight>();
    byCustomer = byC;
    // allNames = allN;
    byId = byId_;
    for (const c of raw) {
      const { cId, id /*,name*/ } = c;
      let to = byC.get(cId);
      if (to) {
        to.push(c);
      } else {
        byC.set(cId, [c]);
      }
      // allN.set(id, name);
      byId_.set(id, c);
    }
  }

  return {
    byCustomer,
    // allNames,
    byId,
  };
});

export const $customerUsersLight = $allRaw.map((raw) => raw || EMPTY_ARRAY);
export const $customerUsersLightByCustomer = $allDerived.map(({ byCustomer }) => byCustomer);
// export const $customerUsersNames = $allDerived.map(({ allNames }) => allNames);
export const $customerUsersMap = $allDerived.map(({ byId }) => byId);
