import { ReactElement } from 'react';
import { Cell } from './Cell';
import { GridCellRender, GridCellStart } from './types';

interface Options extends GridCellStart {
  w: number;
  h: number;
  R?: GridCellRender;
}

export function renderCells({ section, x, y, w, h, R }: Options) {
  const nodes: ReactElement[] = [];
  for (let j = 0; j < h; j++) {
    for (let i = 0; i < w; i++) {
      const cx = x + i;
      const cy = y + j;
      nodes.push(<Cell key={`${cx}-${cy}`} x={cx} y={cy} section={section} R={R} />);
    }
  }
  return nodes.length ? <>{nodes}</> : null;
}
