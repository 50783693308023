import { FC, ReactElement, Suspense, useEffect, useRef, useState } from 'react';
import * as RoMap from '@cubux/readonly-map';

export type DialogContainerNode = {
  readonly index: number;
  render(node: ReactElement): void;
  delete(): void;
};

interface IContainer {
  add(): DialogContainerNode;
}

const containers = new Map<number, IContainer>();
const nextCId = (() => {
  let CId = 0;
  return () => ++CId;
})();

export const getPromptContainer = (): IContainer => {
  // поскольку по порядку добавления нельзя судить о вложенности,
  // берём пофиг какой из них - всё равно по факту он будет только один в App
  const [c] = containers.values();
  if (c) {
    return c;
  }
  throw new Error(`no PromptContainer mounted`);
};

export const PromptContainer: FC = () => {
  const [CId] = useState(nextCId);
  const refIId = useRef(0);
  const [instances, setInstances] = useState<ReadonlyMap<number, ReactElement>>(new Map());

  useEffect(() => {
    containers.set(CId, {
      add() {
        const id = ++refIId.current;

        return {
          index: id,
          render: (node) => setInstances((map) => RoMap.set(map, id, node)),
          delete: () => setInstances((map) => RoMap.remove(map, id)),
        };
      },
    });

    return () => {
      containers.delete(CId);
    };
  }, [CId]);

  return (
    <>
      {[...instances].map(([id, node]) => (
        // Любой из дочерних может использовать `React.lazy()`.
        <Suspense key={id} fallback={null}>
          {node}
        </Suspense>
      ))}
    </>
  );
};
