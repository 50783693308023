import { FC } from 'react';
import RSwitch, { ReactSwitchProps } from 'react-switch';
import Icon from 'ui/Icon';
import cl from './Switch.module.scss';

interface Props extends ReactSwitchProps {
  loading?: boolean;
}

export const Switch: FC<Props> = ({ loading, ...tail }) => {
  return (
    <RSwitch
      {...tail}
      uncheckedIcon={false}
      checkedIcon={false}
      height={20}
      handleDiameter={16}
      width={36}
      onColor="#00c938"
      offColor="#7C85A4"
      // todo стили лоадера, немного кривой
      uncheckedHandleIcon={loading ? <Icon type="Loader" className={cl.loader} /> : undefined}
      checkedHandleIcon={loading ? <Icon type="Loader" className={cl.loader} /> : undefined}
    />
  );
};
