import { dataOnly } from 'api/instance';
import { ApiResponse } from 'api/types';
import {
  Channel,
  ChannelByGroups,
  ChannelCreateForm,
  ChannelId,
  ChannelUpdateParams,
  ChannelUserRightsForm,
  TaskChannelForm,
  UserChannelData,
} from 'models/channel/types';
import { CompanyId } from 'models/company/types';
import { WithCustomer2 } from '../../types/request';

const api = {
  list2: (companyId: CompanyId) =>
    dataOnly.get<null, ApiResponse<ChannelByGroups>>(`/api/v2/channels/${companyId}`),

  create: (form: ChannelCreateForm) =>
    dataOnly.post<null, ApiResponse<Channel>>('/api/v1/channels', form),

  /** Delete channel */
  delete: (channelId: ChannelId) =>
    dataOnly.delete<null, ApiResponse<Channel>>(`/api/v2/channels/${channelId}`),

  /** update channel */
  update: ({ channelId, form }: ChannelUpdateParams) =>
    dataOnly.put<null, ApiResponse<Channel>>(`/api/v2/channels/${channelId}`, form),

  userList: (channelId: ChannelId) =>
    dataOnly.get<null, ApiResponse<UserChannelData[]>>(`/api/v2/channels/${channelId}/users`),

  deleteUser: (channelId: ChannelId, userId: string) =>
    dataOnly.delete<null, ApiResponse<UserChannelData>>(
      `/api/v2/channels/${channelId}/users/${userId}`,
    ),

  addUser: (channelId: ChannelId, userId: string, form: ChannelUserRightsForm) =>
    dataOnly.post<null, ApiResponse<UserChannelData>>(
      `/api/v2/channels/${channelId}/users/${userId}`,
      form,
    ),

  getTaskChannel: ({ taskId, companyId }: TaskChannelForm) =>
    dataOnly.get<null, ApiResponse<Channel>>(`/api/v1/channels/${companyId}/tasks/${taskId}`),

  getTaskTypicalChannel: ({ taskId, companyId, ...params }: TaskChannelForm & WithCustomer2) =>
    dataOnly.get<null, ApiResponse<Channel>>(
      `/api/v1/channels/${companyId}/tasks/typical/${taskId}`,
      { params },
    ),

  getTelegramChannels: (companyId: CompanyId) =>
    dataOnly.get<null, ApiResponse<Channel[]>>(`/api/v2/channels/${companyId}/telegram`),
};

export default api;
