import { createEffect, createEvent, restore, sample } from 'effector';
import isequal from 'lodash.isequal';
import * as RoArray from '@cubux/readonly-array';
import { apiCustomersListLight } from 'api/request/customer';
import { EMPTY_ARRAY } from 'constants/utils';
import { strCmp } from 'utils/fn';
import { $currentCompanyId } from '../company';
import { withBookkeeperFx } from '../utils/withBookkeeperFx';
import {
  Customer,
  CustomerId,
  CustomerItem,
  CustomerItemLight,
  CustomerStatus2,
  UpdateCustomerContractorRelationsResponse,
} from './types';

const fetchFx = withBookkeeperFx(createEffect(apiCustomersListLight));

export const reloadCustomers = createEvent<any>();
export const customerDidCreate = createEvent<Customer>();
export const customerDidUpdate = createEvent<Customer>();
export const customerStatusDidUpdate = createEvent<UpdateCustomerContractorRelationsResponse>();
export const customerDidDelete = createEvent<CustomerId>();

sample({
  source: $currentCompanyId,
  filter: Boolean,
  target: reloadCustomers,
});
sample({
  source: reloadCustomers,
  fn: () => {},
  target: fetchFx,
});

const mkLight = ({
  id,
  customerShortName,
  customerStatus,
}: Customer | CustomerItem): CustomerItemLight => ({
  id,
  name: customerShortName,
  status: customerStatus,
});
const cmpCustomersLight = (a: CustomerItemLight, b: CustomerItemLight) => strCmp(a.name, b.name);

export const $customersLightList = restore(
  fetchFx.doneData.map(({ data }) => data),
  EMPTY_ARRAY,
)
  .reset($currentCompanyId.updates)
  .on(customerDidCreate, (list, add) => [...list, mkLight(add)].sort(cmpCustomersLight))
  .on(customerDidUpdate, (list, customer) => {
    const { id } = customer;
    const index = list.findIndex((c) => c.id === id);
    if (index >= 0) {
      const next = mkLight(customer);
      if (!isequal(next, list[index])) {
        // пока похер на пересортировку - название меняют редко, наверно (не согласовано, сам придумал)
        return RoArray.set(list, index, next);
      }
    }
  })
  .on(customerStatusDidUpdate, (list, { customerid: id, status }) => {
    const index = list.findIndex((c) => c.id === id);
    if (index >= 0) {
      // пока похер на пересортировку - название меняют редко, наверно (не согласовано, сам придумал)
      return RoArray.update(list, index, (prev) =>
        prev.status === status ? prev : { ...prev, status },
      );
    }
  })
  .on(customerDidDelete, (list, id) => {
    const index = list.findIndex((c) => c.id === id);
    if (index >= 0) {
      return RoArray.remove(list, index);
    }
  });

// несколько ведОмых сторов вычисляем сразу вместе,
// чтобы цикл по компаниям прогонять только один раз,
// а не заново в каждом из ведОмых сторов
const _$derivedShared = $customersLightList.map((list) => {
  const all = new Map<CustomerId, CustomerItemLight>();
  const names = new Map<CustomerId, string>();
  const active: CustomerItemLight[] = [];
  const activeId = new Set<CustomerId>();
  // TODO: где-то уже понадобилось, но пока пропустил: ids = new Set<CustomerId>();

  for (const c of list) {
    const { id, name } = c;
    all.set(id, c);
    names.set(id, name);
    if (c.status === CustomerStatus2.ACTIVE) {
      active.push(c);
      activeId.add(id);
    }
  }

  return {
    all,
    active,
    activeId,
    names,
  };
});

export const $customersLightMap = _$derivedShared.map<ReadonlyMap<CustomerId, CustomerItemLight>>(
  ({ all }) => all,
);
export const $customersLightNames = _$derivedShared.map<ReadonlyMap<CustomerId, string>>(
  ({ names }) => names,
);
export const $customersLightActive = _$derivedShared.map<readonly CustomerItemLight[]>(
  ({ active }) => active,
);
export const $customersLightActiveId = _$derivedShared.map<ReadonlySet<CustomerId>>(
  ({ activeId }) => activeId,
);
