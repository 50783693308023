import { reEmail, reUrlHost } from '../regexp';

export interface Options {
  allowMailto?: boolean;
}

export interface Result {
  value: string;
  changed: boolean;
}

// a@b.c
// a@b.c?subject=Hello
const reEmailShort = new RegExp(`^${reEmail.source}(?:$|[?#])`, reEmail.flags);

// example.com
// example.com/...
// example.com?...
// example.com#...
// example.com:8080
// example.com:8080/...
// example.com:8080?...
// example.com:8080#...
const reHostWithoutSchema = new RegExp(`^${reUrlHost.source}(?:$|[/?#])`, reUrlHost.flags);

/**
 * Попытка дополнить короткий УРЛ в полный
 *
 * Предполагается, что короткий УРЛ приходит из ввода пользователя. Короткий УРЛ
 * не является валидным URL. Пользователь слишком умный, чтобы написать УРЛ
 * руками ("mail.ru"), но недостаточно умный, чтобы скопировать настоящий УРЛ.
 *
 * ```
 * | input                     | добавится префикс |
 * | ------------------------- | ----------------- |
 * | example.com               | http://           |
 * | example.com/path          | http://           |
 * | example.com?query         | http://           |
 * | example.com#fragment      | http://           |
 * | example.com:8080          | http://           |
 * | example.com:8080/path     | http://           |
 * | example.com:8080?query    | http://           |
 * | example.com:8080#fragment | http://           |
 * ```
 *
 * Также при `allowMailto` = `true`:
 *
 * ```
 * | input                      | добавится префикс |
 * | -------------------------- | ----------------- |
 * | login@example.com          | mailto:           |
 * | login@example.com?subject= | mailto:           |
 * ```
 */
export const completeShortUrl = (value: string, { allowMailto = false }: Options = {}): Result => {
  const v = value.trimStart();
  if (allowMailto && reEmailShort.test(v)) {
    return {
      value: 'mailto:' + v,
      changed: true,
    };
  }
  if (reHostWithoutSchema.test(v)) {
    return {
      value: 'http://' + v,
      changed: true,
    };
  }
  return {
    value,
    changed: false,
  };
};
