import { forwardRef, TextareaHTMLAttributes } from 'react';
import cn from 'classnames';
import cl from './Textarea.module.scss';

type InputSize = 'default' | 'small' | 'large';

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  inputSize?: InputSize;
}

const SIZES_CLS: Record<InputSize, string> = {
  default: '',
  large: cl.sizeLarge,
  small: cl.sizeSmall,
};

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ inputSize = 'default', className = '', ...tail }, ref) => (
    <textarea {...tail} ref={ref} className={cn(SIZES_CLS[inputSize], className)} />
  ),
);
