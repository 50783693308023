import dateFns_ru from 'date-fns/locale/ru';
// import { ReactComponent } from 'svg-country-flags/svg/ru.svg';
import { ILanguage } from '../language';

const ruLang: ILanguage = {
  language: 'ru',
  locale: 'ru-RU',
  title: 'Русский',
  dateFns: dateFns_ru,
  // flag: ReactComponent,
  parseNumber: {
    decimal: ',',
    group: /\s+/,
  },
};

export default ruLang;
