import { addDays, differenceInCalendarDays } from 'date-fns';
import { DateISO, DateTimeISO, IdNumberOf, IdOf, WritablePart } from 'utils/types';
import { CustomerId } from '../customer/types';
import { FileId } from '../filestorage/types';
import { TaskDisplayCore } from '../task/types';
import { UserId } from '../user/types';
import { WhatsAppPaymentState, WhatsAppPaymentStatus } from '../whatsapp-common/paid-state';

// https://greenapi.com/en/docs/api/account/GetStateInstance/
export enum WaGreenInstanceState {
  notAuthorized = 'notAuthorized',
  authorized = 'authorized',
  blocked = 'blocked',
  sleepMode = 'sleepMode',
  starting = 'starting',
  yellowCard = 'yellowCard',
}
export enum WaGreenMarkRead {
  NO = '',
  OnReceive = 'onReceive',
  OnSend = 'onSend',
}

const goodStates = new Set<WaGreenInstanceState | ''>([
  '',
  WaGreenInstanceState.authorized,
  WaGreenInstanceState.blocked,
  WaGreenInstanceState.sleepMode,
  // после создания инстанса через партнёрское АПИ:
  // - проходит около минуты
  // - приходит статус starting
  // - приходит статус notAuthorized
  // Поэтому starting всё-таки лучше не делать хорошим статусом
  // "Не хороший, не плохой - просто бедненький больной."
  // WaGreenInstanceState.starting,
  WaGreenInstanceState.yellowCard,
]);
// const stoppedStates = new Set<WaGreenInstanceState | ''>([WaGreenInstanceState.notAuthorized]);

export const isGoodState = (state: WaGreenInstanceState | '') => goodStates.has(state);
// export const isStoppedState = (state: WaGreenInstanceState | '') => stoppedStates.has(state);

export const enum WaGreenInstanceUserWhy {
  Config = '',
  AutoGroups = 'autoGroups',
}
export interface WaGreenInstanceUser {
  user_id: UserId;
  why: WaGreenInstanceUserWhy;
}

export type WaGreenInstanceId = IdOf<'WaGreenInstance'>;
/** instance id а green api */
export type WaGreenApiInstanceId = IdOf<'WaGreenApiInstance'>;
export interface WaGreenInstance {
  readonly id: WaGreenInstanceId;
  readonly instance_id: WaGreenApiInstanceId | null;
  readonly is_webhook_ok: boolean;
  readonly state: WaGreenInstanceState | '';
  readonly can_configure: boolean;
  readonly owner_user_id: UserId;
  readonly paid_end_date: DateISO;
  readonly phone: string;
  insert_employee_name: boolean;
  view_per_user: boolean;
  users_self_manage: boolean;
  mark_read: WaGreenMarkRead;
  group_auto_create: boolean;
  silence_outgoing: boolean;
  access_users?: WaGreenInstanceUser[];
}

export interface WithWaGreenInstanceId {
  instance_id: WaGreenInstanceId;
}

export interface WaGreenInstanceEditForm extends Partial<WritablePart<WaGreenInstance>> {}

export const isInstanceOk = (i: WaGreenInstance) => i.is_webhook_ok && isGoodState(i.state);

export const enum WaGreenAuthQrType {
  qrCode = 'qrCode',
  error = 'error',
  alreadyLogged = 'alreadyLogged',
}
export interface WaGreenAuthQr {
  type: WaGreenAuthQrType;
  /**
   * Результат, зависит от типа `type:
   *
   * | type | message |
   * |------|---------|
   * | `qrCode` | Base64 QR code image. Prepend `data:image/png;base64,` to display. |
   * | `error` | Error description `Instance has auth. You need to make log out` |
   * | `alreadyLogged` | Error description `instance account already authorized` |
   */
  message: string;
}

// --------------------------------------------------

export const urlDataImagePng = `data:image/png;base64,`;
export const urlDataImageJpg = `data:image/jpg;base64,`;

// --------------------------------------------------

export const enum WaGreenGroupType {
  user = 'user',
  group = 'group',
}

export interface WaGreenGroupCore {
  readonly type: WaGreenGroupType;
  readonly name: string;
  readonly phone: (string & {}) | '';
}

export type WaGreenGroupRemoteId = IdOf<'WaGreenGroupRemote'>;
export interface WaGreenGroupRemote extends WaGreenGroupCore {
  readonly remote_id: WaGreenGroupRemoteId;
}

export interface WaGreenGroupsSave {
  add_id?: WaGreenGroupRemoteId[];
}

export type WaGreenGroupId = IdNumberOf<'WaGreenGroupLocal'>;
export interface WaGreenGroupLocal {
  readonly id: WaGreenGroupId;
  readonly custom_name: string;
  //TODO:avatar
}
export interface WaGreenGroup extends WaGreenGroupLocal, WaGreenGroupCore {}
export interface WaGreenGroupAdmin extends WaGreenGroupLocal, WaGreenGroupRemote {}

export interface WithWaGreenGroupId {
  group_id: WaGreenGroupId;
}

export interface WaGreenGroupUpdateForm {
  custom_name?: string;
}

export interface WaGreenGroupForUser extends WaGreenGroupLocal, WaGreenGroupCore {
  connect_state: WaGreenInstanceState | '';
  for_customers: readonly CustomerId[] | null;
  instance_id: WaGreenInstanceId;
  work_instance_id: WaGreenApiInstanceId | null;
  instance_phone: (string & {}) | '';
  last_seen_message_time: DateTimeISO | null;
}

export interface WaGreenGroupAccessUsers {
  user_id: readonly UserId[];
}
interface WaGreenGroupAccessData extends WaGreenGroupAccessUsers {
  customers_id: readonly CustomerId[];
}

export interface WaGreenGroupAccess extends WaGreenGroupAccessData {
  chat_id: WaGreenGroupId;
}

export interface WaGreenGroupAccessBatch {
  /**
   * Список изменений
   *
   * ID групп внутри не должны повторяться во всём массиве
   */
  groups: readonly WaGreenGroupAccessSection[];
}
export interface WaGreenGroupAccessSection {
  /**
   * В каких чатах менять
   *
   * ID не должны повторяться
   */
  g: readonly WaGreenGroupId[];
  /** Как менять клиентов */
  c?: WaGreenGroupAccessOperations<CustomerId>;
  /** Как менять сотрудников   */
  u?: WaGreenGroupAccessOperationsUsers;
}
export type WaGreenGroupAccessOperationsUsers = WaGreenGroupAccessOperations<UserId>;
interface WaGreenGroupAccessOperations<ID extends string> {
  /** Удалить всех прежних */
  clear?: boolean;
  /**
   * Удалить указанные id
   *
   * Нельзя указывать, когда включён `clear`
   *
   * id не должны повторяться
   */
  del?: readonly ID[];
  /**
   * Добавить указанные id
   *
   * id не должны повторяться и не должны пересекаться с `del`
   */
  ins?: readonly ID[];
}

export interface WaGreenGroupAccessUsersSelf {
  add_user_id?: readonly UserId[];
  remove_user_id?: readonly UserId[];
}

// --------------------------------------------------

// https://greenapi.com/en/docs/api/receiving/notifications-format/outgoing-message/OutgoingMessageStatus/
export enum WaGreenMessageAck {
  // ack есть только у исходящих
  none = 0,
  // отправлено
  sent = 1,
  // доставлено
  delivered = 2,
  // прочитано
  read = 3,
  // ошибка при отправке на сервер WhatsApp
  failed = 501,
  // нет WhatsApp аккаунта у получателя
  noAccount = 401,
  // отправитель не состоит в чате, куда отправляет
  notInGroup = 402,
  // отправка задержана по подозрению в спам актиности
  yellowCard = 403,
}

// Простое текстовое сообщение
export interface WaGreenMessageData_Text {
  // Текст сообщения
  message?: string;
}
export interface WaGreenMessageData_ExtendedText {
  // Ссылка или обычный текст
  text?: string;
  // Описание ссылки
  description?: string;
  // Заголовок ссылки
  title?: string;
  // превью JPEG BASE64
  thumbJpegB64?: string;
}
// Файл
export interface WaGreenMessageData_File {
  // ссылка для скачивания
  url?: string;
  // имя файла
  filename?: string;
  // MIME тип файла
  mimeType?: string;
  // подпись/название
  caption?: string;
  // превью JPEG BASE64
  thumbJpegB64?: string;
}
// Геолокация
export interface WaGreenMessageData_Location {
  // Адрес
  address?: string;
  // Широта
  lat?: number;
  // Долгота
  lon?: number;
  // Название
  name?: string;
  // превью JPEG BASE64
  thumbJpegB64?: string;
}
// Контакт
export interface WaGreenMessageData_Contact {
  // Отображаемое имя
  name?: string;
  // VCARD
  vcard?: string;
}
// Список контактов
export interface WaGreenMessageData_Contacts {
  contacts?: readonly WaGreenMessageData_Contact[];
}
// Стикер
export interface WaGreenMessageData_Sticker {
  // ссылка для скачивания
  url?: string;
  // анимированный ли
  isAnimated?: boolean;
  // MIME тип файла
  mimeType?: string;
  // превью JPEG BASE64
  thumbJpegB64?: string;
}
// Реакция
export interface WaGreenMessageData_Reaction {
  // Эмоджи
  text?: string;
}

export enum WaGreenMessageType {
  text = 'text',
  extendedText = 'extendedText',
  image = 'image',
  video = 'video',
  document = 'document',
  audio = 'audio',
  location = 'location',
  contact = 'contact',
  contacts = 'contacts',
  sticker = 'sticker',
}

export interface WaGreenMessageContentCase<T, D> {
  type: T;
  data?: D;
}
export type WaGreenMessageContent =
  | WaGreenMessageContentCase<WaGreenMessageType.text, WaGreenMessageData_Text>
  | WaGreenMessageContentCase<WaGreenMessageType.extendedText, WaGreenMessageData_ExtendedText>
  | WaGreenMessageContentCase<
      | WaGreenMessageType.image
      | WaGreenMessageType.video
      | WaGreenMessageType.document
      | WaGreenMessageType.audio,
      WaGreenMessageData_File
    >
  | WaGreenMessageContentCase<WaGreenMessageType.location, WaGreenMessageData_Location>
  | WaGreenMessageContentCase<WaGreenMessageType.contact, WaGreenMessageData_Contact>
  | WaGreenMessageContentCase<WaGreenMessageType.contacts, WaGreenMessageData_Contacts>
  | WaGreenMessageContentCase<WaGreenMessageType.sticker, WaGreenMessageData_Sticker>;

export type WaGreenMessageId = IdNumberOf<'WaGreenMessage'>;
export interface WaGreenMessageCore {
  id: WaGreenMessageId;
  created_at: DateTimeISO;
  income: boolean;
  from_name: string;
  ack: WaGreenMessageAck | 0;
  // ID нашего пользователя отправителя
  local_sender?: UserId;
  // Внутреннее сообщение finkoper без задействования настоящего WhatsApp
  internal?: boolean;
}
export interface WaGreenMessageRelations {
  // Вложения после того, как мы их скачали к себе.
  // Пока у поставщика возможен только один файл в одном сообщении, мне пофиг
  // на связи между их урлом и нашим файлов. Сомневаюсь, что они это так просто
  // смогут и захотят изменить.
  files?: readonly WaGreenFile[];
  tasks?: readonly WaGreenMessageTask[];
  quote?: WaGreenMessageQuote;
  reactions?: readonly WaGreenMessageReaction[];
}
export interface WaGreenMessageReaction
  extends Pick<WaGreenMessageCore, 'id' | 'created_at' | 'from_name'> {
  from: string;
  data?: WaGreenMessageData_Reaction;
}
export type WaGreenMessageQuote = WaGreenMessageCore & WaGreenMessageContent;
export type WaGreenMessage = WaGreenMessageCore & WaGreenMessageRelations & WaGreenMessageContent;
export type WaGreenMessagePeek = WaGreenMessage & {
  group_id: WaGreenGroupId;
};

export interface WaGreenFile {
  id: FileId;
  name: string;
  type: string;
  size: number;
}
export interface WaGreenMessageTask extends TaskDisplayCore {}

export interface WaGreenMessageSendForm {
  message?: string;
  attachment?: File;
  file_caption?: string;
  quote_id?: WaGreenMessageId;
  // Внутреннее сообщение finkoper без задействования настоящего WhatsApp
  //
  // Если не указано, то по умолчанию внутренним будет цитирование внутреннего сообщения
  internal?: boolean;
}

// --------------------------------------------------

// ================================================================

//             конец оплаченного
//                   |
// --------------.---]--------.-----------> t
//               |            |
//            начало          когда
//        предупреждений      удалим
//         об окончании

export interface WaGreenConfig {
  /** сколько дней давать бесплатно после подключения */
  readonly free_on_connect_days: number;
  /** за сколько дней до окончания оплаченного периода предупреждать об окончании */
  readonly paid_notice_before_end_days: number;
  /** сколько дней после окончания оплаченного периода ожидать оплату и хранить подключение */
  readonly paid_wait_after_end_days: number;
}
// умолчания сервера до загрузки конфига с сервера
export const DEFAULT_CONFIG: WaGreenConfig = {
  free_on_connect_days: 3,
  paid_notice_before_end_days: 3,
  paid_wait_after_end_days: 7,
};

export interface WaGreenPaidStatus {
  instance_id: WaGreenInstanceId;
  paid_end_date: DateISO;
}

export const getPaidState = (
  today: Date,
  paidUntil: Date | null,
  hasWorkInstance: boolean,
  config: WaGreenConfig = DEFAULT_CONFIG,
): WhatsAppPaymentStatus => {
  if (!paidUntil) {
    return [WhatsAppPaymentState.EXPIRED_FAR, null];
  }
  // TODO: +1?
  const willExpireInDays = differenceInCalendarDays(paidUntil, today);
  if (willExpireInDays > config.paid_notice_before_end_days) {
    return [WhatsAppPaymentState.PAID_FAR, paidUntil];
  }
  if (willExpireInDays >= 0) {
    return [WhatsAppPaymentState.PAID_CLOSE, paidUntil];
  }
  const willDeleteAfter = addDays(paidUntil, config.paid_wait_after_end_days);
  if (hasWorkInstance || willExpireInDays >= -config.paid_wait_after_end_days) {
    return [WhatsAppPaymentState.EXPIRED_CLOSE, willDeleteAfter];
  }
  return [WhatsAppPaymentState.EXPIRED_FAR, willDeleteAfter];
};
