import { UserId } from 'models/user/types';
import { ChannelId } from 'models/channel/types';
import { DateISO, DateTimeISO, IdNumberOf, IdOf, Optional } from 'utils/types';
import { CustomerId, CustomerProperty, TaxbookItemId, VariantItemId } from 'models/customer/types';
import { CompanyId } from 'models/company/types';
import { Effect } from 'effector';
import { FileId, FileStorage } from 'models/filestorage/types';
import { EmailId } from 'models/mail/types';
import { Tag, TagId } from 'models/tags/types';
import { TaskChecklist } from '../task-checklist/types';
import { TimerToggleParams } from '../timers/types';
import { TaskChecklistItem } from '../task-checklist-item/types';
import { htmlFromPlainTextP, htmlFromRawFlow, htmlToPlainText } from 'utils/string';
import { IconRef } from 'ui/Icon';
import { TaskFinanceDataForm } from '../task-finance/types';
import { TaskTemplateId } from '../task-template/types';
import { WaGreenMessageId } from '../wagreen/types';
import { BulkSettingsId } from '../tasks-bulk-by-teams/types';

export type TaskId = IdNumberOf<'Task'>;
//Комплексный ид, так как ид, что приходит в задаче не обеспечивает уникальности, из-за пересечения типовых и обычных задач
export type TaskUniId = IdOf<'Task'>;

export type TaskRepeatableSettingsId = IdOf<'TaskRepeatableSettings'>;

export interface TaskAssignee {
  assigner_id: UserId;
  created_at: string;
  user_id: UserId;
}

export type TaskPriority = 'high' | 'mid' | 'low';
export type TaskStatus = 'open' | 'closed';
export type TaskState = 'open' | 'closed' | 'overdue';
export type TaskRepeatableMode = 'weekly' | 'monthly' | 'quarterly' | 'yearly' | 'dates';
export type TaskHolidaysMove = 'back' | 'noop' | 'forward';
// countable - расчитываемые задачи
// customer — клиентские задачи
// integration — из интеграции (см. integration_title)
// periodical— периодические (финансовые)
// typical— типовые
// unknown— что-то пошло не так, не удалось определить тип
// user — пользовательские задачи (от бухов)
// user-local— локальные
export type TaskType =
  | 'countable'
  | 'customer'
  | 'integration'
  | 'periodical'
  | 'typical'
  | 'unknown'
  | 'user'
  | 'user-local';

export interface TaskRepeatableSettings {
  id: TaskRepeatableSettingsId;
  /** Список конкретных дат*/
  dates: string[];
  /** Повторять поквартально*/
  dates_quarterly: string[];
  /** Повторять ежегодно*/
  dates_yearly: string[];
  /** Повторять по числам месяца 1 - 31*/
  days_monthly: number[];
  /** Повторять по дням недели.  0 — воскресенье .. 6 — суббота*/
  days_weekly: number[];
  /** Режим повторов */
  mode: TaskRepeatableMode;
  /** Праздничные дни */
  on_holidays_move: TaskHolidaysMove;

  period_end_date: string;
  period_start_date: string;

  // Перенос даты создания задачи
  date_calendar_delta_days?: number;
}

export interface TaskRepeatableSettingsInfo extends TaskRepeatableSettings {
  text: string;
}

export interface TaskTimer {
  readonly is_started: boolean;
  /** Присутствует если в запросе был указан параметр load[]=timer-started-anybody */
  readonly is_started_anybody?: boolean;
  /**
   * Формат: hhh:mm:ss
   * @deprecated Не использую на фронте и не обновляю в костыле
   */
  readonly total_time: string;
  readonly total_time_sec: number;
}

export type IntegrationStatusId = IdNumberOf<'IntegrationStatus'>;

export interface IntegrationStatus {
  id: IntegrationStatusId;
  title: string;
  created_at: DateTimeISO;
}

/**
 negative (документооборот завершен, но отрицательно, то есть налоговая не приняла)
 positive (завершен успешно)
 warning (успешно, но что то требуется дополнить)
 intermediate (в процессе, это треугольник сейчас, оставляем)
 */
export type KonturIntegrationState = 'negative' | 'positive' | 'warning' | 'intermediate';
// REFACT: как-то эту штуку^^^ ещё надо будет подружить с 1С. Я пока не стал,
//   т.к. хз, что там будет. Сергей в Легенде нарисовал одинаковый набор статусов
//   для интеграций Контура и 1С (только с разными логотипами, само собой).

export type TaskIntegrationSource = 'kontur' | '1c' | 'sbis';

export interface TaskDotCore {
  id: TaskId;
  date_end: string;
  text: string;
  status: TaskStatus;
}

export interface TaskKindProps {
  /** Типовая задача или нет*/
  is_typical: boolean;
  /** ID клиентской компании*/
  team_id: CustomerId | null;
}

export interface TaskDisplayCore extends TaskDotCore, TaskKindProps {}

export interface TaskFromApi extends TaskDisplayCore {
  /** Список идентификаторов пользователей, которые назначены ответственными за задачу */
  assignees: TaskAssignee[] | null;
  channel_id: ChannelId;
  date_begin: string;
  date_calendar: DateISO;
  /** Список идентификаторов файлов */
  files: FileStorage[] | null;
  message_id?: string;
  mail_post_id?: EmailId | null;
  wa_green_message_id?: WaGreenMessageId | null;
  priority: TaskPriority;
  status_last_user_id: UserId;
  status_updated_at: string;
  /** Автор задачи */
  user_id: UserId;
  tags: readonly Tag[] | null;
  /** Чеклисты к задаче, показываются только если в запросе был параметр load[]=checklists */
  checklists?: TaskChecklist[];
  /** Создать повторяемые задачи */
  repeatable?: boolean;
  repeatable_settings?: TaskRepeatableSettings;
  timer: TaskTimer;
  integration?: TaskIntegrationSource;
  integration_statuses?: IntegrationStatus[];
  integration_state?: KonturIntegrationState;
  is_readonly?: boolean;
  readonly_author?: UserId;
  type: TaskType;
  type_icon_custom: TaskIconType;
  deleted_at: DateTimeISO | null;
  deleted_by_user: UserId | null;
  reviewers: TaskAssignee[];
  need_reviewers_notice?: boolean;
  bulk_settings_id?: BulkSettingsId;

  // message:	string
  // page:	number
  // per_page:	number
  // success:	boolean
  // total:	number
}

export interface Task extends TaskFromApi {
  // REFACT: было бы логичнее оставить `text` без изменений, а добавить какой-нибудь `textPlain`
  originalText: string;
}

export interface TaskExt extends Task {
  isNew?: boolean;
}

export const isDeletedTask = (
  task: false | Task | null,
): task is Task & { deleted_at: DateTimeISO } => Boolean(task && task.deleted_at);

export const fromApiToTask = (task: TaskFromApi): Task => ({
  ...task,
  ...taskTextFromApi(task.text),
});

export const taskTextFromApi = (text: string) => {
  let html: string;
  let plain: string;

  // Задачи в БД бывают трёх типов:
  // 1.  text/plain:
  //    "Бла-бла-бла...n\бла-бла-бла <a@b.c>"
  // 2. text/html из древних времён или хз из какого источника:
  //    "Бла-бла-бла<br/>бла-бла-бла"
  // 3. text/html нормальный
  //    "<p>Бла-бла-бла</p><p>бла-бла-бла<br/>ку-ку</p>"
  const textNS = text.trimStart();
  if (textNS === '' || textNS.startsWith('<')) {
    // 3.
    html = text;
    plain = htmlToPlainText(text);
  } else if (/<(\/(p|div|b|i|strong|em|a)>|br\s*\/?>|(p|div|b|i|strong|em|a)[\s>])/i.test(text)) {
    // 2.
    html = htmlFromRawFlow(text);
    plain = htmlToPlainText(text);
  } else {
    // 1.
    html = htmlFromPlainTextP(text, { withLinks: true });
    plain = text;
  }

  return {
    // REFACT: было бы логичнее оставить `text` без изменений, а добавить какой-нибудь `textPlain`
    originalText: html,
    text: plain,
  };
};

export interface TaskChecklistItemSimple extends Pick<TaskChecklistItem, 'text' | 'state'> {}

export interface TaskChecklistSimple extends Pick<TaskChecklist, 'title'> {
  items: TaskChecklistItemSimple[];
}

export interface TaskCreateForm
  extends Optional<
    Omit<
      TaskFromApi,
      | 'id'
      | 'assignees'
      | 'is_typical'
      | 'status_last_user_id'
      | 'status_updated_at'
      | 'files'
      | 'tags'
      | 'repeatable_settings'
      | 'timer'
      | 'checklists'
      | 'type'
      | 'deleted_at'
      | 'deleted_by_user'
      | 'reviewers'
    >,
    'team_id' | 'user_id' | 'channel_id' | 'message_id' | 'type_icon_custom'
  > {
  bookkeeper_team_id: CompanyId;
  assignees?: UserId[];
  reviewers?: UserId[];
  files?: readonly string[];
  tags?: TagId[];
  repeatable_settings?: Omit<TaskRepeatableSettings, 'id'>;
  checklists?: TaskChecklistSimple[];
  /** Создание задачи для нескольких пользователей */
  bulk_for_teams?: boolean;
  /** ID клиентской компании (указываем в случае выбора одного клиента)*/
  /** Массив ID клиентских компаний (указываем в случае выбора нескольких клиентов)*/
  teams?: {
    by_team_id?: readonly CustomerId[];
    by_typical_attributes?: {
      query: [
        {
          id: TaxbookItemId;
          values: VariantItemId[] | [0 | 1];
        },
      ];
      team_ids: CustomerId[];
    };
  };
  finance?: TaskFinanceDataForm;
  /**
   * ID шаблона, который использован для создания задачи. Используется при
   * построении отчетов о времени выполнения задач.
   */
  template_id?: TaskTemplateId | null;
}

export interface TaskEditForm
  extends Omit<TaskCreateForm, 'channel_id' | 'message_id' | 'mail_post_id' | 'team_id'>,
    TimerToggleParams {
  /** Перезаписать дату изменения статуса */
  status_updated_date?: string;
}

export interface TaskTypicalEditForm extends TimerToggleParams {
  task_id: TaskId;
  date_calendar: DateISO;
  bookkeeper_team_id: CompanyId;
  team_id: CustomerId;
  status: TaskStatus;
  assignees: UserId[];
  status_updated_date?: string;
  tags: TagId[] | null;
  files?: readonly FileId[];
}

export interface TaskPatchParams {
  taskId: TaskId;
  form: Partial<TaskEditForm>;
}

export interface TaskTypicalUpdateParams {
  taskId: TaskId;
  form: TaskTypicalEditForm;
}

export interface TaskRepeatableUpdateForm extends Partial<TaskEditForm> {
  finance?: TaskFinanceDataForm;
  /** Применить изменения для текущей и всех последующих (по date_end) */
  repeatable_changes_apply_forward: boolean;
  for_teams?: CustomerId[];
}

export interface TaskRepeatableDeleteForm {
  task_repeatable_settings_id: TaskRepeatableSettingsId;
  for_teams?: CustomerId[];
}

export interface TaskToggleStatusParams<P extends TaskPatchParams | TaskTypicalUpdateParams> {
  params: P;
  effect: Effect<P, any>;
}

export const getTaskUniId = (task: Task): TaskUniId =>
  `${task.id}_${task.team_id}_${task.is_typical}` as TaskUniId;

export interface StatDay {
  closed: number;
  expired: number;
  open: number;
  deleted: number;
}

export interface StatWeek {
  week_date: string;
  closed: number;
  'expired-closed': number;
  open: number;
  'expired-open': number;
}

export interface TaskAssigneeUpdateForm {
  assignee: UserId;
  bookkeeper_team_id: CompanyId;
  operation: 'add' | 'del';
  team_id: CustomerId;
  // user_id: string;
}

interface TaskBulkItem {
  assignees: UserId[];
  task_id: TaskId;
}

interface TaskTypicalBulkItem {
  assignees: UserId[];
  task_id: TaskId;
  team_id: CustomerId;
}

export const toTaskTypicalBulkItems = (
  tasks: TaskFromApi[],
  team_id: CustomerId,
  assignees: UserId[],
): TaskTypicalBulkItem[] =>
  tasks.map((c) => ({
    task_id: c.id,
    team_id,
    assignees,
  }));

export interface AssigneesTasksBulkForm {
  bookkeeper_team_id: CompanyId;
  tasks: TaskBulkItem[];
  typical_tasks: TaskTypicalBulkItem[];
  // "user_id": "string"
}

export interface DeleteBulkForm {
  bookkeeper_team_id: CompanyId;
  tasks: TaskId[];
  typical_tasks: TaskTypicalBulkItem[];
}

export enum TaskIconType {
  default = 'null',
  userTask1 = 'userTask1',
  userTask2 = 'userTask2',
  userTask3 = 'userTask3',
  userTask4 = 'userTask4',
  userTask5 = 'userTask5',
  userTask6 = 'userTask6',
  userTask7 = 'userTask7',
  userTask8 = 'userTask8',
  userTask9 = 'userTask9',
}

export const taskIconTypeMap: Record<TaskIconType, IconRef> = {
  [TaskIconType.default]: 'UserTask',
  [TaskIconType.userTask1]: 'Rub',
  [TaskIconType.userTask2]: 'Connect',
  [TaskIconType.userTask3]: 'Exclamation',
  [TaskIconType.userTask4]: 'AtSign',
  [TaskIconType.userTask5]: 'KonturTask',
  [TaskIconType.userTask6]: 'StarCircle',
  [TaskIconType.userTask7]: 'Bank',
  [TaskIconType.userTask8]: 'WorkedTask',
  [TaskIconType.userTask9]: 'Cake',
};

export interface TaskTypicalSettings {
  createdDate: DateISO;
  customerProperties: CustomerProperty[];
  typicalTasksSinceDate: DateISO;
}

export interface TaskTypicalSettingsUpdateForm {
  bookkeeperTeamID: CompanyId;
  customerProperties: readonly CustomerProperty[] | null;
  teamID: CustomerId;
  typicalTasksSinceDate?: DateISO;
  userID?: UserId;
}
