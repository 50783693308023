import { dataOnly } from 'api/instance';
import { Currency } from 'models/misc/currency/types';
import { ApiResponse2 } from '../../types';

export interface GetHolidaysParams {
  date_from: string;
  date_to: string;
  //todo изменить тип, Region
  region: string;
}

const api = {
  currency: () => dataOnly.get<null, ApiResponse2<readonly Currency[]>>('api2/misc/currency'),

  holidays: (params: GetHolidaysParams, signal?: AbortSignal) =>
    dataOnly.get<null, string[]>('api2/misc/holidays', { params, signal }),
};

export default api;
