import { FC, useMemo } from 'react';
import { Picker, PickerProps } from './Picker';

interface Props extends Omit<PickerProps<number>, 'options'> {}

export const MonthDayPicker: FC<Props> = ({ ...tail }) => {
  const option = useMemo(
    () => Array.from({ length: 31 }).map((_, i) => ({ value: i + 1, label: i + 1 })),
    [],
  );
  return <Picker {...tail} options={option} />;
};
