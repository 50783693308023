import dateFns_de from 'date-fns/locale/de';
// import { ReactComponent } from 'svg-country-flags/svg/ru.svg';
import { ILanguage } from '../language';

const deLang: ILanguage = {
  language: 'de',
  locale: 'de-DE',
  title: 'Deutsch',
  dateFns: dateFns_de,
  // flag: ReactComponent,
  parseNumber: {
    decimal: ',',
    group: /\s+/,
  },
};

export default deLang;
