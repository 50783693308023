import { ApiResponse2, PaginatedResponse2 } from 'api/types';

export const defaultReducer = <Data, Null extends boolean>(
  state: any,
  { data }: ApiResponse2<Data, Null>,
) => data;

export const paginationResultPick = ({
  page,
  per_page,
  total,
}: PaginatedResponse2): PaginatedResponse2 => ({ page, per_page, total });

export const paginationReducer = (_: unknown, p: PaginatedResponse2): PaginatedResponse2 =>
  paginationResultPick(p);

export const defaultPagination = (per_page = 20): PaginatedResponse2 => ({
  page: 1,
  per_page,
  total: 0,
});
