import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { WithBookkeeper } from 'api/types/request';
import { UserPreference, UserPreferenceForm } from 'models/user-preferences/types';

export interface UserPreferenceListFilter {
  name_prefix?: string[];
}

const APP = '/api2' as const;

const TEAM_WIDE = `${APP}/team-preferences` as const;
const USER = `${APP}/user-preferences` as const;
const USER_IN_TEAM = `${USER}/team` as const;
const USER_OWN = `${USER}/user` as const;

export interface TeamPreferenceApis {
  list(p: WithBookkeeper & UserPreferenceListFilter): Promise<ApiResponse2<UserPreference[], true>>;
  item(p: WithBookkeeper & { name: string }): Promise<ApiResponse2<UserPreference>>;
  update(p: WithBookkeeper & UserPreferenceForm): Promise<ApiResponse2<UserPreference>>;
}

export const apiTeamPreference = {
  list: ({ bookkeeper_team_id, ...params }: WithBookkeeper & UserPreferenceListFilter) =>
    dataOnly.get<null, ApiResponse2<UserPreference[], true>>(`${TEAM_WIDE}/${bookkeeper_team_id}`, {
      params,
    }),

  item: ({ bookkeeper_team_id, name }: WithBookkeeper & { name: string }) =>
    dataOnly.get<null, ApiResponse2<UserPreference>>(
      `${TEAM_WIDE}/${bookkeeper_team_id}/${encodeURIComponent(name)}`,
    ),

  /**
   * Опции с именами `admin.*` может менять только админ.
   */
  update: ({ bookkeeper_team_id, ...form }: WithBookkeeper & UserPreferenceForm) =>
    dataOnly.patch<null, ApiResponse2<UserPreference>>(`${TEAM_WIDE}/${bookkeeper_team_id}`, {
      ...form,
    }),
};
export const apiUserPreferenceInTeam2 = {
  list: ({ bookkeeper_team_id, ...params }: WithBookkeeper & UserPreferenceListFilter) =>
    dataOnly.get<null, ApiResponse2<UserPreference[], true>>(
      `${USER_IN_TEAM}/${bookkeeper_team_id}`,
      {
        params,
      },
    ),

  item: ({ bookkeeper_team_id, name }: WithBookkeeper & { name: string }) =>
    dataOnly.get<null, ApiResponse2<UserPreference>>(
      `${USER_IN_TEAM}/${bookkeeper_team_id}/${encodeURIComponent(name)}`,
    ),

  update: ({ bookkeeper_team_id, ...form }: WithBookkeeper & UserPreferenceForm) =>
    dataOnly.patch<null, ApiResponse2<UserPreference>>(`${USER_IN_TEAM}/${bookkeeper_team_id}`, {
      ...form,
    }),
};

export const apiUserPreference = {
  list: (params?: UserPreferenceListFilter) =>
    dataOnly.get<null, ApiResponse2<UserPreference[], true>>(USER_OWN, {
      params,
    }),

  item: (name: string) =>
    dataOnly.get<null, ApiResponse2<UserPreference>>(`${USER_OWN}/${encodeURIComponent(name)}`),

  update: (form: UserPreferenceForm) =>
    dataOnly.patch<null, ApiResponse2<UserPreference>>(USER_OWN, {
      ...form,
    }),
};
