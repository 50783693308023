import { containerWithHtml } from '../dom';

// какие элементы полностью игнорировать
const SKIP_ELEMENTS = new Set(['SCRIPT', 'STYLE', 'NOSCRIPT']);
// блоковые элементы - влияют на появление переноса строки для дальнейшего текста
const BLOCK_ELEMENTS = new Set(['DIV', 'P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'BLOCKQUOTE']);

// inline span форматирование
const enum Span {
  B,
  I,
  S,
}
// какие входные элементы какому форматированию соответствуют
const SPAN_ELEMENTS = new Map([
  ['B', Span.B],
  ['STRONG', Span.B],
  ['I', Span.I],
  ['EM', Span.I],
  ['S', Span.S],
  ['DEL', Span.S],
]);
// каким символом обрамляется span на выходе
const SPAN_CHAR = new Map([
  [Span.B, '*'],
  [Span.I, '_'],
  [Span.S, '~'],
]);
// если текст заканчивается на ЭТО, то сразу после него можно открыть span элемент:
//
//     normal!*bold*
//     -------======
//
// иначе надо добавить пробел, чтобы форматирование сработало:
//
//     normal *bold*
//     ------^======
const reCanOpenSpan = /(^|[^\p{L}\p{N}\\])$/u;
// если текст сразу после закрывающего span символа начинается на ЭТО, то нормально:
//
//     *bold*!!!
//     ======---
//
// иначе надо добавить пробел, чтобы форматирование сработало:
//
//     *bold* norm!!!
//     ======^-------
const reTextAfterCloseSpanIsFine = /^($|[^\p{L}\p{N}\\])/u;

const reEscapeSpanChars = /(\s[*_~](?!\s))|(\S)([*_~](?=\s))/gu;
//-------------------------1^^^^^^^--------2^^-3^^^^^-------
function escapeSpanCharsCallback(_: string, ...args: (string | undefined)[]) {
  const [$1, $2, $3] = args;
  if ($2 && $3) {
    return $2 + ' ' + $3;
  }
  return $1 + ' ';
}
// TODO: хз, как поступить со спец символами `*`, `_` и `~`, если юзер их введёт
//  whatsapp не может сделать, как маркдаун `normal *x\*y* normal`.
function escapeSpanChars(text: string) {
  return text.replace(reEscapeSpanChars, escapeSpanCharsCallback);
}

const reLinkHrefOk = /^(mailto:|(https?|ftps?):\/\/)/;
function isLinkHrefOk(href: string | null) {
  if (!href) {
    return false;
  }
  return reLinkHrefOk.test(href);
}
function needAppendLinkHref(container: HTMLElement) {
  const href = container.getAttribute('href');
  if (!href || !isLinkHrefOk(href)) {
    return false;
  }
  if (container.firstElementChild) {
    return true;
  }
  // https://trello.com/c/RiPYLQKw/1052
  const innerText = container.innerText || container.textContent || '';
  if (
    innerText.split(/\s+/).some((s) => {
      try {
        return new URL(s).toString() === new URL(href).toString();
      } catch {
        return false;
      }
    })
  ) {
    return false;
  }
  if (href.startsWith('mailto:') && innerText.includes(href.substring(7))) {
    return false;
  }
  return true;
}

export const htmlToWhatsAppMessage = (html: string): string => {
  // результат (спасибо, кэп). добавляется блоками
  let result = '';
  // текущая выходная строка текста (блок/абзац)
  let line = '';
  // внутри каких span элементов находимся во входном потоке
  // однотипные могут повторяться: b > i > strong > em ==> [B, I, B, I]
  const inSpans: Span[] = [];
  // какие span элементы висят открытыми в выходном потоке
  // чтобы закрывать их в конце блока
  // чтобы открывать их только при необходимости
  const outSpans = new Set<Span>();
  // вспомогательный флаг для добавления пробела после закрывающего span символа
  // см. reTextAfterCloseSpanIsFine
  let spanJustClosed = false;

  function addText(inputText: string) {
    const text = escapeSpanChars(inputText.replace('\n', ' '));

    if (text) {
      // закрываем в выходном потоке span, которых сейчас нет во входном потоке
      // !!! .reverse() - закрываем с конца, как стек
      for (const s of Array.from(outSpans).reverse()) {
        if (!inSpans.includes(s)) {
          outSpans.delete(s);
          line += SPAN_CHAR.get(s)!;
          spanJustClosed = true;
        }
      }
      // открываем в выходном потоке span, которых там сейчас не хватает
      for (const s of inSpans) {
        if (!outSpans.has(s)) {
          outSpans.add(s);
          if (!reCanOpenSpan.test(line)) {
            line += ' ';
          }
          line += SPAN_CHAR.get(s)!;
          spanJustClosed = false;
        }
      }

      if (spanJustClosed) {
        spanJustClosed = false;
        if (!reTextAfterCloseSpanIsFine.test(text)) {
          line += ' ';
        }
      }

      line += text;
    }
  }

  function endOfLine() {
    // конец строки
    // закрываем в выходном потоке все открытые span
    // !!! .reverse() - закрываем с конца, как стек
    for (const s of Array.from(outSpans).reverse()) {
      outSpans.delete(s);
      line += SPAN_CHAR.get(s)!;
      spanJustClosed = true;
    }
  }

  function flushLine() {
    line = line.trimEnd();
    endOfLine();
    if (line) {
      result += line;
      result += '\n';
      line = '';
    }
    spanJustClosed = false;
  }
  function br() {
    line = line.trimEnd();
    endOfLine();
    result += line;
    result += '\n';
    line = '';
    spanJustClosed = false;
    // но заново открывать их обратно не надо - сами откроются по востребованию
  }
  function blockStart() {
    flushLine();
  }
  function blockEnd() {
    flushLine();
  }

  function read(container: HTMLElement) {
    if (SKIP_ELEMENTS.has(container.tagName)) {
      return;
    }
    if (container.tagName === 'BR') {
      br();
      return;
    }
    const isBlock = BLOCK_ELEMENTS.has(container.tagName);
    const span = SPAN_ELEMENTS.get(container.tagName);
    if (isBlock) {
      blockStart();
    } else if (span !== undefined) {
      inSpans.push(span);
    }
    for (let node = container.firstChild; node; node = node.nextSibling) {
      switch (node.nodeType) {
        case document.TEXT_NODE:
          addText((node as Text).wholeText);
          break;
        case document.ELEMENT_NODE:
          read(node as HTMLElement);
          break;
      }
    }
    if (isBlock) {
      blockEnd();
    } else if (span !== undefined) {
      inSpans.pop();
    } else if (container.tagName === 'A') {
      if (needAppendLinkHref(container)) {
        const href = container.getAttribute('href');
        addText(' <' + href + '>');
      }
    }
  }

  read(containerWithHtml(html));

  return result.trimEnd();
};
