import { combine, createEffect, createEvent, createStore, EffectParams, sample } from 'effector';
import { not } from 'patronum';
import { ApiForbiddenError } from 'api/errors';
import api, { apiTgGenUrl } from 'api/request/customer';
import taskApi from 'api/request/task';
import { apiRolesTeamUpdate, apiRolesTeamUpdateV1 } from 'api/request/roles';
import { apiTaskPeriodical } from 'api/request/tasks-periodical';
import { ApiResponse } from 'api/types';
import { EMPTY_ARRAY, EMPTY_MAP } from 'constants/utils';
import {
  Customer,
  CustomerCreateFormExt,
  CustomerEmployeeCreateFormParams,
  CustomerId,
  CustomerItem,
  CustomerStatus,
  CustomerUpdateFormExt,
  TaxbookItem,
  UpdateCustomerContractorRelationsEvent,
  UpdateCustomerContractorRelationsParams,
  UpdateCustomerContractorRelationsResponse,
} from './types';
import { createGate } from 'effector-react';
import { CompanyId } from '../company/types';
import { $currentCompanyId, $currentCompanyIdOrNull, $currentCompanyRegion } from '../company';
import { getCountriesFx } from 'models/country';
import { RegionCode } from 'utils/types';
import { promptFn, showToast, showToastError, ToastTypes } from 'ui/feedback';
import i18next from 'i18next';
import { createRequestEffect } from '../utils/createRquestEffect';
import {
  $channelCustomerMap,
  $channelsByGroup,
  $currentChannel,
  $globalChannelId,
  CurrentChannelGate,
} from '../channel';
import { ChannelByGroups, ChannelId, CustomerChannelsItemId } from '../channel/types';
import { createIsNeedFetchInfo } from '../utils';
import {
  customerDidCreate,
  customerDidDelete,
  customerDidUpdate,
  customerStatusDidUpdate,
} from './list-light';
import { CustomerTag } from '../customers-tags/types';
import { $customerTags, TAG_CUSTOMERS_CALLBACKS } from '../customers-tags';
import {
  customerUserDidCreate,
  customerUserDidDelete,
  customerUserDidUpdate,
} from './customers-users-all-light';
import { AssignUserToTasks, RoleId, RoleUsersUpdates } from '../roles/types';
import { UserId } from '../user/types';
import * as RoMap from '@cubux/readonly-map';

export const TaxbookGate = createGate();

export const customerInfoUpdated = createEvent();
export const fetchTaxbookIfNeed = createEvent();

//добавить пользователя к клиенту во время использования ролей с указанием необходимости обновлять ручные и автоматические задачи
export const addUserToRole = createEvent<{ roleId: RoleId; userId: UserId } & AssignUserToTasks>();

export const getTaxbookFx = createEffect<RegionCode | null, ApiResponse<TaxbookItem[]>>(
  async (regionCode) => {
    //todo if needed
    const { data: countries } = await getCountriesFx();
    const countryId = countries.find((c) => c.iso_code === regionCode)?.id ?? -1;
    return await api.taxbook(countryId);
  },
);
export const resetTaxbook = createEvent<void>();
const resetTaxbookTriggers = [resetTaxbook, TaxbookGate.open, $currentCompanyIdOrNull];
export const $taxbook = createStore<TaxbookItem[]>([]).reset(resetTaxbookTriggers);
const $needFetchTaxbook = createIsNeedFetchInfo(getTaxbookFx, resetTaxbookTriggers);
sample({
  source: $currentCompanyRegion,
  clock: fetchTaxbookIfNeed,
  filter: $needFetchTaxbook,
  target: getTaxbookFx,
});

$taxbook.on(getTaxbookFx.doneData, (_, { data }) => data);

export const $listsTaxbook = $taxbook.map((taxbook) => taxbook.filter((c) => c.isList));
export const $checkboxesTaxbook = $taxbook.map((taxbook) => taxbook.filter((c) => !c.isList));

/**
 * Customers
 * @deprecated Медленное АПИ, избавляемся
 */
export const CustomersGate = createGate();
/** @deprecated Медленное АПИ, избавляемся => заменено */
export const reloadCustomers = createEvent<any>();
/** @deprecated Медленное АПИ, избавляемся */
export const fetchCustomersIfNeeded = createEvent();
/** @deprecated REFACT: Gate? */
export const resetCurrentCustomer = createEvent();

/** selected customer in left sideBar*/
export const $selectedCustomerId = createStore<CustomerId | null>(null);
/** @deprecated Медленное АПИ, избавляемся */
export const $customers = createStore<CustomerItem[]>([]);
/** @deprecated Медленное АПИ, избавляемся */
export const $customersMap = $customers.map(
  (c) => new Map(c.map((customer) => [customer.id, customer])),
);
export const fetchCustomerInfoFx = createRequestEffect(api.show, true);
export const $customerInfo = createStore<Customer | null>(null).reset(CurrentChannelGate.close);
export const $currentCustomer = createStore<Customer | null>(null).reset(resetCurrentCustomer);
export const $currentAddedRoleUsers =
  createStore<ReadonlyMap<UserId, ReadonlyMap<RoleId, AssignUserToTasks>>>(EMPTY_MAP).reset(
    $currentCustomer,
  );
export const createCustomerFx = createEffect<CustomerCreateFormExt, ApiResponse<Customer>>(
  async ({ customerForm, taskPeriodicalItems, roleUsers }) => {
    //customerPropertyes обновляем отдельным запросом "typicalSettingsUpdate", тоже самое с typicalTasksSinceDate
    const customerData = await api.create(customerForm.contractorId, {
      ...customerForm,
      customerPropertyes: null,
      typicalTasksSinceDate: undefined,
    });
    await Promise.all([
      taskApi.typicalSettingsUpdate({
        customerProperties: customerForm.customerPropertyes,
        bookkeeperTeamID: customerForm.contractorId,
        teamID: customerData.data.id,
        typicalTasksSinceDate: customerForm.typicalTasksSinceDate,
      }),
      taskPeriodicalItems.length
        ? apiTaskPeriodical.settingsUpdate({
            settings: taskPeriodicalItems,
            bookkeeper_team_id: customerForm.contractorId,
            team_id: customerData.data.id,
          })
        : undefined,
      roleUsers
        ? apiRolesTeamUpdate({
            id: customerData.data.id,
            BookkeeperTeamID: customerForm.contractorId,
            roleUsers,
          })
        : undefined,
    ]);
    if (taskPeriodicalItems.length) {
      await apiTaskPeriodical.settingsUpdate({
        settings: taskPeriodicalItems,
        bookkeeper_team_id: customerForm.contractorId,
        team_id: customerData.data.id,
      });
    }

    return customerData;
  },
);

export const updateCustomerFx = createEffect<CustomerUpdateFormExt, ApiResponse<Customer>>(
  async ({ customerForm, taskPeriodicalItems, companyId, roleUsers }) => {
    const currentAddedRoleUsers = $currentAddedRoleUsers.getState();
    //Отправим запрос на обновление периодических настроек, только если есть что отправлять, тоже самое с Ролями
    const [customer] = await Promise.all([
      //customerPropertyes обновляем отдельным запросом "typicalSettingsUpdate", тоже самое с typicalTasksSinceDate
      api.update({
        ...customerForm,
        form: { ...customerForm.form, customerPropertyes: null, typicalTasksSinceDate: undefined },
      }),
      taskApi.typicalSettingsUpdate({
        customerProperties: customerForm.form.customerPropertyes,
        bookkeeperTeamID: companyId,
        teamID: customerForm.customerId,
        typicalTasksSinceDate: customerForm.form.typicalTasksSinceDate,
      }),
      taskPeriodicalItems.length
        ? apiTaskPeriodical.settingsUpdate({
            settings: taskPeriodicalItems,
            team_id: customerForm.customerId,
            bookkeeper_team_id: companyId,
          })
        : undefined,
      roleUsers
        ? apiRolesTeamUpdateV1({
            id: customerForm.customerId,
            BookkeeperTeamID: companyId,
            roleUsers: Object.keys(roleUsers).reduce<RoleUsersUpdates>((o, roleId: any) => {
              roleId = Number(roleId) as RoleId;
              o[roleId] =
                roleUsers[roleId]?.map((userId) => {
                  const assignUserToTasks = currentAddedRoleUsers.get(userId)?.get(roleId);
                  return {
                    assignTypical: assignUserToTasks?.assignTypical,
                    assignManual: assignUserToTasks?.assignManual,
                    user: userId,
                  };
                }) ?? [];
              return o;
            }, {}),
          })
        : undefined,
    ]);
    return customer;
  },
);

sample({
  source: createCustomerFx.doneData,
  fn: ({ data }) => data,
  target: customerDidCreate,
});
sample({
  source: updateCustomerFx.doneData,
  fn: ({ data }) => data,
  target: customerDidUpdate,
});

// TODO: $didFetched.reset($currentCompanyId)
/** @deprecated Медленное АПИ, избавляемся */
export const getCustomersFx = createEffect(
  async (companyId: CompanyId) => await api.list(companyId),
);

const resetCustomers = [$currentCompanyId, createCustomerFx.done, updateCustomerFx.done];
$customers.reset(resetCustomers);
const $needFetchCustomers = createIsNeedFetchInfo(getCustomersFx, resetCustomers);

createCustomerFx.failData.watch((e) => {
  if (e instanceof ApiForbiddenError) {
    showToast(ToastTypes.error, i18next.t('ui:errorApi.forbidden'));
  } else {
    showToastError(e);
  }
});

sample({
  clock: sample({
    clock: [CustomersGate.open, reloadCustomers],
    filter: not(getCustomersFx.pending),
  }),
  source: $currentCompanyIdOrNull,
  filter: Boolean,
  target: getCustomersFx,
});
sample({
  clock: fetchCustomersIfNeeded,
  source: { needFetch: $needFetchCustomers, companyId: $currentCompanyId },
  filter: ({ needFetch }) => needFetch,
  target: getCustomersFx,
  fn: ({ companyId }) => companyId as CompanyId,
});

$customers.on(getCustomersFx.doneData, (_, { data }) => data ?? EMPTY_ARRAY);

export const showCustomerFx = createEffect<CustomerId, Customer>(async (customerId) => {
  const [customer, settings] = await Promise.all([
    api.show(customerId),
    taskApi.typicalSettings(customerId),
  ]);

  return {
    ...customer.data,
    customerPropertyes: settings.data.customerProperties,
    typicalTasksSinceDate: settings.data.typicalTasksSinceDate,
  };
});

$currentCustomer.on(showCustomerFx.doneData, (_, data) => data);
sample({
  clock: $currentChannel,
  source: $channelCustomerMap,
  filter: (map, channel) => !!channel && channel.group === 'customers',
  target: $selectedCustomerId,
  fn: (map, channel): CustomerId =>
    map.get(channel!.id as unknown as ChannelId) as unknown as CustomerId,
});
// Без этого было так:
//
// 1. Заходим в канал Клиента1
// 2. Идём в его редактирование.
// 3. Заходим обратно в этого же клиента.
// => в итоге не грузились какие-то данные клиента в инфо панелях.
//
// Так теперь работает.
$selectedCustomerId.reset(CurrentChannelGate.close);

sample({
  clock: [$selectedCustomerId, customerInfoUpdated],
  source: $selectedCustomerId,
  filter: Boolean,
  target: fetchCustomerInfoFx,
});
$customerInfo.on(fetchCustomerInfoFx.doneData, (_, { data }) => data);

export const $notCustomerChannelOrCustomerHasTelegram = combine(
  $globalChannelId,
  $channelCustomerMap,
  $customerInfo,
  (channelId, map, info) => {
    if (!map.has(channelId as unknown as ChannelId)) {
      return true;
    }

    return !!info?.customerChannels[0].customerEmployees.some((employee) =>
      employee.communications?.find(
        ({ status, type }) => type === 'telegram' && status === 'active',
      ),
    );
  },
);

export const $isCustomerChannel = combine($globalChannelId, $channelCustomerMap, (channelId, map) =>
  map.has(channelId as unknown as ChannelId),
);

export const currentCustomerSeted = sample({
  clock: $currentCustomer,
  filter: Boolean,
});

$currentAddedRoleUsers.on(addUserToRole, (s, data) =>
  RoMap.set(
    s,
    data.userId,
    RoMap.set(s.get(data.userId) ?? EMPTY_MAP, data.roleId, {
      assignManual: data.assignManual,
      assignTypical: data.assignTypical,
    }),
  ),
);

/** Добавить представителя клиента*/
// передадим еще formId, чтобы после понять какую форму следуюет обновить
export const addCustomerEmployeeFx = createEffect(
  async ({ form, channelId, customerId }: CustomerEmployeeCreateFormParams & { formId: string }) =>
    await api.addCustomerEmployee({
      form,
      channelId,
      customerId,
    }),
);

/** Редактировать представителя клиента*/
export const updateCustomerEmployeeFx = createRequestEffect(api.updateCustomerEmployee);
/** Удалить представителя клиента*/
export const deleteCustomerEmployeeFx = createRequestEffect(api.deleteCustomerEmployee);

sample({
  clock: [addCustomerEmployeeFx.done, updateCustomerEmployeeFx.done, deleteCustomerEmployeeFx.done],
  target: customerInfoUpdated,
});
sample({
  clock: addCustomerEmployeeFx.done,
  fn: ({ params: { customerId }, result: { data } }) => ({ ...data, team_id: customerId }),
  target: customerUserDidCreate,
});
sample({
  clock: updateCustomerEmployeeFx.done,
  fn: ({ params: { customerId }, result: { data } }) => ({ ...data, team_id: customerId }),
  target: customerUserDidUpdate,
});
sample({
  clock: deleteCustomerEmployeeFx.done,
  fn: ({ params: { userId } }) => userId,
  target: customerUserDidDelete,
});

//Telegram
export const genTelegramUrl = createEffect(apiTgGenUrl);

/** updateCustomerContractorRelations Например - удаление */
export const updateCustomerContractorRelationsFx = createEffect(
  async (params: UpdateCustomerContractorRelationsParams) =>
    await api.updateCustomerContractorRelations(params),
);
export const updateCustomerContractorRelations = createEvent<{
  customerId: CustomerId;
  status: CustomerStatus;
}>();
export const updateCustomerContractorRelationsPrompt =
  createEvent<UpdateCustomerContractorRelationsEvent>();

sample({
  clock: updateCustomerContractorRelations,
  source: $currentCompanyIdOrNull,
  target: updateCustomerContractorRelationsFx,
  fn: (companyId, { customerId, status }): UpdateCustomerContractorRelationsParams => ({
    idcontractor: companyId!,
    idcustomer: customerId,
    status,
  }),
});

sample({
  clock: updateCustomerContractorRelationsPrompt,
  target: createEffect(async ({ customerId, status }: UpdateCustomerContractorRelationsEvent) => {
    let txt = '';
    switch (status) {
      case 'archive':
        txt = i18next.t('account:customer.toArchivePrompt');
        break;
      case 'delete':
        txt = i18next.t('account:customer.toDeletePrompt');
        break;
      case 'active':
        txt = i18next.t('account:customer.toActivePrompt');
        break;
    }
    if (await promptFn(txt)) updateCustomerContractorRelations({ customerId, status });
  }),
});

$customers.on(updateCustomerContractorRelationsFx.doneData, (s, { customerid, status }) =>
  s.map((c) => (c.id === customerid ? { ...c, customerStatus: status } : c)),
);

sample({
  source: updateCustomerContractorRelationsFx.doneData,
  filter: ({ status }) => status === 'delete',
  fn: ({ customerid }) => customerid,
  target: customerDidDelete,
});
sample({
  source: updateCustomerContractorRelationsFx.doneData,
  // После перемещения в архив, список не обновлялся
  // filter: ({ status }) => status === 'delete',
  target: customerStatusDidUpdate,
});

sample({
  clock: updateCustomerContractorRelationsFx.doneData,
  target: createEffect(async ({ status }: UpdateCustomerContractorRelationsResponse) => {
    let txt = '';
    switch (status) {
      case 'archive':
        txt = i18next.t('account:customer.doneResponse.archive');
        break;
      case 'delete':
        txt = i18next.t('account:customer.doneResponse.delete');
        break;
      case 'active':
        txt = i18next.t('account:customer.doneResponse.active');
        break;
    }
    showToast(ToastTypes.success, txt);
  }),
});
sample({
  clock: updateCustomerContractorRelationsFx.fail,
  target: createEffect(async () => {
    showToastError();
  }),
});

/* Customer tags*/
const tagToggled = createEvent<CustomerTag>();

export const attachCustomerTagsFx = createEffect(api.attachTags);

export const $currentCustomerTags = createStore<readonly CustomerTag[]>(EMPTY_ARRAY);

sample({
  clock: TAG_CUSTOMERS_CALLBACKS.select,
  target: tagToggled,
});

sample({
  clock: tagToggled,
  source: { customer: $currentCustomer, customerTags: $currentCustomerTags },
  filter: ({ customer }) => !!customer,
  target: attachCustomerTagsFx,
  fn: ({ customer, customerTags }, tag): EffectParams<typeof attachCustomerTagsFx> => {
    let customerTagIds = customerTags.map((c) => c.id);
    const idx = customerTagIds.indexOf(tag.id);
    if (idx < 0) {
      customerTagIds = [...customerTagIds, tag.id];
    } else {
      customerTagIds.splice(idx, 1);
    }
    return {
      team_id: customer!.id,
      tags: customerTagIds,
    };
  },
});

sample({
  clock: $currentCustomer.updates,
  source: $customerTags,
  target: $currentCustomerTags,
  fn: (tags, customer): CustomerTag[] =>
    customer?.tags
      ? customer.tags
          .filter((id) => tags.some((tag) => tag.id === id))
          .map((id) => tags.find((tag) => tag.id === id)!)
      : EMPTY_ARRAY,
});
sample({
  clock: attachCustomerTagsFx.doneData,
  source: $customerTags,
  target: $currentCustomerTags,
  fn: (tags, { data }) =>
    data
      ? data
          .filter((id) => tags.some((tag) => tag.id === id))
          .map((id) => tags.find((tag) => tag.id === id)!)
      : EMPTY_ARRAY,
});
sample({
  clock: attachCustomerTagsFx.done,
  source: $channelsByGroup,
  target: $channelsByGroup,
  fn: (channelsByGroup, { params: { tags, team_id } }): ChannelByGroups | null => {
    if (!channelsByGroup) return null;
    const customers = [...channelsByGroup.customers];
    const customer = customers.find(
      (c) => c.id === (team_id as unknown as CustomerChannelsItemId),
    )!;
    Object.assign(customer, { tags });

    return {
      ...channelsByGroup,
      customers,
    };
  },
});
