import cn from 'classnames';
import { FC } from 'react';
import { ButtonCoreProps } from '../core';
import { TextButton2 } from '../Button';
import cl from './PlusButton.module.scss';

interface Props extends ButtonCoreProps {}

// TODO: children, чтобы была "круглая" иконка с плюсом и текст рядом

export const PlusButton: FC<Props> = ({ className, uiColor = 'secondary', ...rest }) => {
  return <TextButton2 {...rest} icon="Plus" uiColor={uiColor} className={cn(cl.root, className)} />;
};
