import { useMemo } from 'react';
import { differenceInCalendarDays, format as formatOrig } from 'date-fns';
import useLanguageConfig from './useLanguageConfig';
import { useTranslation } from 'react-i18next';

export interface DateFormatRelativeOptions {
  /**
   * Обычный формат для далёкой даты
   * @see https://date-fns.org/v2.17.0/docs/format
   */
  farDateFormat?: string;
  /**
   * Добавить время
   * @see https://date-fns.org/v2.17.0/docs/format
   */
  withTimeFormat?: string;
}

export interface DateFormatRelativeFn {
  /**
   *
   * @param date
   * @param baseDate
   */
  (date: Date | number, baseDate: Date | number): string;
}

export const useDateFormatRelative = ({
  farDateFormat = 'P',
  withTimeFormat,
}: DateFormatRelativeOptions = {}): DateFormatRelativeFn => {
  const { dateFns } = useLanguageConfig();
  const { t } = useTranslation('ui');

  return useMemo(() => {
    const options = { locale: dateFns };

    // `formatRelative()` из `date-fns` - херня. Ну либо его надо настраивать
    // через изменение локали. В любом случае в ней нет опции для разных
    // случаев: с/без времени.

    return (date: Date | number, baseDate: Date | number) => {
      const strDate = (() => {
        switch (differenceInCalendarDays(date, baseDate)) {
          case -1:
            return t('ui:date.yesterday');
          case 0:
            return t('ui:date.today');
          case 1:
            return t('ui:date.tomorrow');
          default:
            return formatOrig(date, farDateFormat, options);
        }
      })();

      if (withTimeFormat === undefined) {
        return strDate;
      }

      return strDate + ', ' + formatOrig(date, withTimeFormat, options);
    };
  }, [t, dateFns, farDateFormat, withTimeFormat]);
};
