import { useEffect } from 'react';
import { createEvent, createStore, sample } from 'effector';
import { debounce } from 'patronum/debounce';

type NeedMouseEvent = Pick<MouseEvent, 'target' | 'relatedTarget' | 'currentTarget'>;

const setAnchorEl = createEvent<HTMLElement | null>();
const onMouseOver = createEvent<NeedMouseEvent>();
const onMouseOut = createEvent<NeedMouseEvent>();
/**
 * Свойства для контейнера, можно установить один раз на глобальный контейнер
 *
 * @deprecated События теперь назначается в `#root` после монтирования App,
 * так что эта хрень больше не нужна нигде.
 */
export const TOOLTIP_LISTEN_PROPS = {
  onMouseOver,
  onMouseOut,
};
const onMouseOverDebounced = debounce({ source: onMouseOver, timeout: 150 });

// TODO: добавить механику типа `update`
//   Ситуация: Чекбокс, в котором `data-tip` меняется при переключении checked.
//   Пока крыса остаётся внутри чекбокса, tooltip не меняется с каждым
//   переключением такого чекбокса, хотя `data-tip` меняется.
//   =>
//   new MutationObserver(fn)
//   mo.observe(element, {attributes: true, attributeFilter: ['data-tip'], subtree: true})
//   https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe
//   следить так за самим $anchorEl изнутри фактически отображенного тултипа,
//   только вынести всё сюда через react hook.
//   Когда где-то у кого-то меняется атрибут data-tip, затриггерить update.

export const $anchorEl = createStore<HTMLElement | null>(null);

sample({ clock: setAnchorEl, target: $anchorEl });

const isHTMLOrSVGElement = (el: any): el is HTMLElement | SVGElement =>
  el instanceof HTMLElement || el instanceof SVGElement;

onMouseOverDebounced.watch((e) => {
  const { target } = e;
  // const anchorEl = $anchorEl.getState();
  if (!isHTMLOrSVGElement(target)) {
    // console.log('over: not an element', target);
    return;
  }
  if (target.dataset.tip) {
    // console.log('over: set to', target);
    setAnchorEl(target as HTMLElement);
  } /*else if (!anchorEl)*/ else {
    const closest = target.closest('[data-tip]');
    if (!closest) {
      // console.log('over: no tip', target);
      return;
    }
    // console.log('over: set to closest', closest);
    setAnchorEl(closest as HTMLElement);
  }
});

onMouseOut.watch((e) => {
  const { relatedTarget } = e;
  const anchorEl = $anchorEl.getState();
  if (!anchorEl) {
    return;
  }
  if (anchorEl.contains(relatedTarget as Node)) {
    // console.log('out: from contained', relatedTarget);
    return;
  }
  // console.log('out: reset from', relatedTarget);
  setAnchorEl(null);
});

// onMouseOver.watch((e) => console.log('over >', e.target, e.relatedTarget));
// onMouseOut.watch((e) => console.log('< out', e.target, e.relatedTarget));

export const useTooltipMount = () =>
  useEffect(() => {
    const root = document.getElementById('root');
    if (!root) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('#root not found for tooltips');
      }
      return;
    }
    root.addEventListener('mouseover', onMouseOver);
    root.addEventListener('mouseout', onMouseOut);
    return () => {
      root.removeEventListener('mouseover', onMouseOver);
      root.removeEventListener('mouseout', onMouseOut);
    };
  }, []);
