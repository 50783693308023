import { dataOnly } from 'api/instance';
import { API_INTGR_REMOTE, ApiResponse2, PaginationParams } from 'api/types';
import { WithBookkeeper } from 'api/types/request';
import { EMPTY_ARRAY } from 'constants/utils';
import { CallNumberForm } from 'models/integrations/calls';
import {
  ZadarmaPeriodParams,
  ZadarmaSip,
  ZadarmaSipAssignForm,
  ZadarmaSipRaw,
  ZadarmaStat,
  ZadarmaUser,
  ZadarmaUserCreateForm,
  ZadarmaUserUpdateForm,
  ZadarmaWithUserId,
} from 'models/zadarma/types';

// https://api.dev.finkoper.com/api3/swagger/index.html#/zadarma

const BASE = `${API_INTGR_REMOTE}/zadarma` as const;

export const apiUsersList = (params: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<readonly ZadarmaUser[]>>(`${BASE}/users`, {
    params,
  });

export const apiUserCreate = (form: WithBookkeeper & ZadarmaUserCreateForm) =>
  dataOnly.post<null, ApiResponse2<ZadarmaUser>>(`${BASE}/users`, form);

export const apiUserUpdate = ({
  zadarma_user_id,
  ...form
}: ZadarmaWithUserId & ZadarmaUserUpdateForm) =>
  dataOnly.put<null, ApiResponse2<ZadarmaUser>>(`${BASE}/users/${zadarma_user_id}`, form);

export const apiUserDelete = ({ zadarma_user_id }: ZadarmaWithUserId) =>
  dataOnly.delete<null, void>(`${BASE}/users/${zadarma_user_id}`);

export const apiUserSync = ({ zadarma_user_id, ...form }: ZadarmaWithUserId) =>
  dataOnly.get<null, ApiResponse2<ZadarmaUser>>(`${BASE}/users/${zadarma_user_id}/sync`, form);

// -----------

const fixSipInline = (raw: ZadarmaSipRaw | ZadarmaSip) => {
  (raw as ZadarmaSip)._FIX_employees_ids = raw.employees?.map((u) => u.user_id) ?? EMPTY_ARRAY;
};

export const apiUserSips = async ({ zadarma_user_id }: ZadarmaWithUserId) => {
  const res = await dataOnly.get<null, ApiResponse2<readonly ZadarmaSip[]>>(
    `${BASE}/users/${zadarma_user_id}/sip`,
  );
  res.data.forEach(fixSipInline);
  return res;
};

export const apiUserSipAssign = async ({ sip_id, ...form }: ZadarmaSipAssignForm) => {
  const res = await dataOnly.put<null, ApiResponse2<ZadarmaSip>>(`${BASE}/sip/${sip_id}`, form);
  fixSipInline(res.data);
  return res;
};

// -----------

export const apiCallPhone = (form: CallNumberForm) =>
  dataOnly.post<null, unknown>(`${BASE}/calls/phone`, form);

// -----------

export const apiUserStats = ({
  zadarma_user_id,
  ...params
}: ZadarmaWithUserId & ZadarmaPeriodParams & PaginationParams) =>
  dataOnly.get<null, ApiResponse2<readonly ZadarmaStat[]>>(
    `${BASE}/users/${zadarma_user_id}/stats`,
    {
      params,
    },
  );
