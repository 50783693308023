import { dataOnly } from 'api/instance';
import { ApiResponse } from 'api/types';
import {
  AddReactionParams,
  Post,
  PostCreateForm,
  PostDetails,
  PostId,
  PostListOptions,
  PostsData,
  PostUpdateForm,
  Seen,
} from 'models/post/types';
import { ChannelId } from 'models/channel/types';

const api = {
  create: (form: PostCreateForm) => dataOnly.post<null, ApiResponse<Post>>('/api/v1/posts', form),

  listForChannel2: (
    { channelId, options }: { channelId: string; options?: PostListOptions },
    signal?: AbortSignal,
  ) =>
    dataOnly.get<null, ApiResponse<PostsData>>(`api/v2/posts/channel/${channelId}`, {
      params: options,
      signal,
    }),

  listForUser2: (
    {
      userId,
      options,
      companyId,
    }: {
      userId: string;
      companyId: string;
      options?: PostListOptions;
    },
    signal?: AbortSignal,
  ) =>
    dataOnly.get<null, ApiResponse<PostsData>>(`api/v2/posts/user/${userId}/company/${companyId}`, {
      params: options,
      signal,
    }),

  seen: (postId: string) => dataOnly.post<null, ApiResponse<Seen>>(`api/v2/posts/seen/${postId}`),

  delete: (postId: string) => dataOnly.delete<null, ApiResponse<Post>>(`api/v2/posts/${postId}`),

  update: (postId: string, form: PostUpdateForm) =>
    dataOnly.put<null, ApiResponse<Post>>(`api/v2/posts/${postId}`, form),

  pinned: (postId: string) =>
    dataOnly.post<null, ApiResponse<Post>>(`api/v2/posts/pinned/${postId}`),

  pinnedList: (channelId: ChannelId) =>
    dataOnly.get<null, ApiResponse<PostsData>>(`api/v2/posts/pinned/${channelId}`),

  copy: (channelId: ChannelId, posts: string[]) =>
    dataOnly.post<null, ApiResponse<Post[]>>(`api/v2/posts/copy/${channelId}`, { posts }),

  details: (postId: PostId, signal?: AbortSignal) =>
    dataOnly.get<null, ApiResponse<PostDetails>>(`api/v2/posts/${postId}/details`, { signal }),

  addReaction: ({ postId, form }: AddReactionParams) =>
    dataOnly.post<null, ApiResponse<unknown>>(`api/v2/posts/${postId}/reaction`, form),
};

export default api;
