import cn from 'classnames';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TextButton2 } from '../../button';
import { IconPosition, IconPositions } from '../../button/types';
import { showToast, ToastTypes } from '../../feedback';
import cl from './CopyBtnText.module.scss';

interface Props {
  noFeedback?: boolean;
  buttonPosition?: IconPosition;
  // TODO: Возможные пути апгрейда (пока в этом не было необходимости):
  // feedbackMessage?: ReactNode;
  // textElement?: keyof ReactHTML | FC;
  textClassName?: string;
  // copySelector?: string;
  copyText?: string;
  // wrapElement?: keyof ReactHTML | FC;
  // buttonProps?: ;
  //
  // Компоненты врапперы с предопределёнными настройками, стилями. Например, блок кода.
  className?: string;
}

const getTextNode = (button: HTMLElement, buttonPosition: IconPosition) =>
  buttonPosition === IconPositions.start
    ? button.nextElementSibling
    : button.previousElementSibling;

export const CopyBtnText: FC<Props> = ({
  children,
  noFeedback = false,
  buttonPosition = IconPositions.start,
  textClassName,
  copyText,
  className,
  ...rest
}) => {
  const { t } = useTranslation('ui');

  const btn = (
    <TextButton2
      size="small"
      uiColor="primary"
      icon="Copy"
      data-tip={t('ui:tips.copyToClipboard')}
      onClick={useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
          const text = copyText ?? getTextNode(e.currentTarget, buttonPosition)?.textContent ?? '';
          if (text) {
            const p = window.navigator.clipboard.writeText(text);
            if (!noFeedback) {
              p.then(() => showToast(ToastTypes.success, t('ui:messages.copySuccess')));
            }
          }
        },
        [t, buttonPosition, noFeedback, copyText],
      )}
    />
  );
  const text = children ? <span className={cn(cl.text, textClassName)}>{children}</span> : null;

  return (
    <div {...rest} className={cn(cl.root, text && cl._hasText, className)}>
      {buttonPosition === IconPositions.start ? (
        <>
          {btn}
          {text}
        </>
      ) : (
        <>
          {text}
          {btn}
        </>
      )}
    </div>
  );
};
