import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { CompanyId } from 'models/company/types';
import { SaveSettingsForm, TeamUserSettings } from 'models/teams/types';

export interface SaveSettingsParam {
  teamId: CompanyId;
  data: SaveSettingsForm;
}

/** Получение настроек сотрудников по бух. компании: */
export const apiMembersSettingsList = (teamId: CompanyId) =>
  dataOnly.get<null, ApiResponse2<TeamUserSettings[]>>(`/api/v1/teams/${teamId}/members-settings`);

/** Сохранение настройки сотрудника*/
export const apiMembersSettingsSave = ({ teamId, data }: SaveSettingsParam) =>
  dataOnly.post<null, ApiResponse2<TeamUserSettings>>(
    `/api/v1/teams/${teamId}/members-settings`,
    data,
  );
