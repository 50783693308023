import { createStore, Event, restore, sample, Store } from 'effector';
import { Gate } from 'effector-react';

// Суть наболевшей проблемы, которая постоянно висит в воздухе:
//
// ```ts
// FooGate.open.watch((p) => console.log('>> open', p));
// FooGate.close.watch((p) => console.log('>> close', p));
// FooGate.status.watch((p) => console.log('>> status', p));
// ```
// =>
// ```
// >> status false
// (смонтировали три штуки)
// >> open {}
// >> status true
// >> open {}
// >> open {}
// (отмонтировали их три)
// >> close {}
// >> status false
// >> close {}
// >> close {}
// ```
//
// Получается херня. Наверно, это даже не совсем ошибка дизайна - это, скорее,
// отсутствие дизайна. Не было расчёта на то, что кто-то будет открывать несколько
// инстансов гейта.

export interface GateLike<Props = {}> {
  open: Event<Props>;
  close: Event<Props>;
  status: Store<boolean>;
  state: Store<Props>;
}

/**
 * OR-мерж множественного открытия гейта
 *
 * Суть проблемы см. в комменте в файле, выше^^^.
 *
 * Теперь:
 * ```ts
 * const OrGate = gateOr(FooGate);
 * OrGate.open.watch((p) => console.log('>> open', p));
 * OrGate.close.watch((p) => console.log('>> close', p));
 * OrGate.status.watch((p) => console.log('>> status', p));
 * OrGate.state.watch((p) => console.log('>> state', p));
 * ```
 * =>
 * ```
 * >> status false
 * >> state {}
 * (смонтировали три штуки: `{x:1}`, `{y:2}` и `{z:3}`)
 * >> status true
 * >> open {x: 1}
 * >> state {x: 1}
 * (отмонтировали их три)
 * >> status false
 * >> close {z: 3}
 * >> state {}
 * ```
 *
 * @param gate
 */
export const gateOr = <P>(gate: Gate<P>): GateLike<P> => {
  const $count = createStore(0)
    .on(gate.open, (n) => n + 1)
    .on(gate.close, (n) => n - 1);

  const $isFirstOpen = $count.map((n) => n === 1);
  const $isLastClose = $count.map((n) => n === 0);

  const firstOpen = sample({ clock: gate.open, filter: $isFirstOpen });
  const lastClose = sample({ clock: gate.close, filter: $isLastClose });
  const $state = restore(firstOpen, {} as P).reset(lastClose);

  // В объединённом виде значения свойств мало пригодны, т.к. я здесь
  // не гарантирую, какой набор свойств когда куда будет передан.
  // Возможно, имеет смысл возвращать `GateLike<{}>`, и тогда нет смысла в $state вообще.

  return {
    open: firstOpen,
    close: lastClose,
    status: $count.map(Boolean),
    state: $state,
  };
};

// export const miltigateOr = (gates: readonly Gate<any>[]) => {};
// export const miltigateAnd = (gates: readonly Gate<any>[]) => {};
