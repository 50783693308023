import { FocusEventHandler, useCallback, useState } from 'react';

// https://reactjs.org/docs/events.html#detecting-focus-entering-and-leaving

interface Options<E extends HTMLElement> {
  onFocusEnter?: FocusEventHandler<E>;
  onFocusLeave?: FocusEventHandler<E>;
  onFocus?: FocusEventHandler<E>;
  onBlur?: FocusEventHandler<E>;
}

export const useFocusWithin = <E extends HTMLElement = HTMLElement>({
  onBlur,
  onFocus,
  onFocusEnter,
  onFocusLeave,
}: Options<E> = {}) => {
  const [hasFocus, setHasFocus] = useState(false);

  const handleFocus = useCallback<FocusEventHandler<E>>(
    (e) => {
      // if (e.currentTarget === e.target) {
      //   console.log('focused self');
      // } else {
      //   console.log('focused child', e.target);
      // }
      setHasFocus(true);
      if (!e.currentTarget.contains(e.relatedTarget)) {
        // Not triggered when swapping focus between children
        onFocusEnter?.(e);
      }
      onFocus?.(e);
    },
    [onFocus, onFocusEnter],
  );

  const handleBlur = useCallback<FocusEventHandler<E>>(
    (e) => {
      // if (e.currentTarget === e.target) {
      //   console.log('unfocused self');
      // } else {
      //   console.log('unfocused child', e.target);
      // }
      onBlur?.(e);
      if (!e.currentTarget.contains(e.relatedTarget)) {
        // Not triggered when swapping focus between children
        onFocusLeave?.(e);
      }
      setHasFocus(false);
    },
    [onBlur, onFocusLeave],
  );

  return {
    hasFocus,
    onFocus: handleFocus,
    onBlur: handleBlur,
  } as const;
};
