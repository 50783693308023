import { createEffect, createEvent, sample } from 'effector';
import { $curCompany, getCompaniesFx, setCurCompany } from 'models/company';
import { Company } from 'models/company/types';
import { getChannelsFx } from 'models/channel';
import { promptFn } from 'ui/feedback';
import { Trans } from 'react-i18next';
import { createElement } from 'react';

export const receiveUpdateCompany = createEvent();
sample({
  clock: receiveUpdateCompany,
  source: $curCompany,
  fn: (company) => ({ company }),
  target: createEffect<{ company: Company | null }, void>(async ({ company }) => {
    if (!company) return;
    const { data } = await getCompaniesFx();

    //проверим, выпили ли нас из текущей компании
    if (!data.some((c) => c.id === company.id)) {
      // t('ui:notAccessCurrentCompany')
      await promptFn(
        createElement(Trans, {
          i18nKey: 'ui:notAccessCurrentCompany',
          values: { name: company.displayname },
        }),
        { type: 'error' },
      );
      if (data.length) setCurCompany(data[0].id);

      return;
    }

    await getChannelsFx(company.id);
  }),
});
