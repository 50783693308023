import { createEvent, createStore, sample } from 'effector';
import api, { GetHolidaysParams } from 'api/request/misc';
import { $currentCompanyRegion } from 'models/company';
import { DateRangeValue } from 'ui/button';
import { createRequestEffect } from '../utils/createRquestEffect';

export const getHolidays = createEvent<Required<DateRangeValue>>();
export const getHolidaysFx = createRequestEffect(api.holidays, true);

export const $holidays = createStore<string[]>([]);
$holidays.on(getHolidaysFx.doneData, (_, data) => data);

sample({
  clock: getHolidays,
  source: $currentCompanyRegion,
  target: getHolidaysFx,
  fn: (region, params): GetHolidaysParams => ({
    date_from: params.dateStart,
    date_to: params.dateEnd,
    region: region!,
  }),
});
