import { FC, ReactNode, useMemo } from 'react';
import RSelect, {
  components,
  MultiValueProps,
  OptionProps,
  OptionTypeBase,
  Props as RSelectProps,
  StylesConfig,
} from 'react-select';
import cl from './Select.module.scss';
import { ControlProps } from 'react-select/src/components/Control';
import './Select.scss';
import cn from 'classnames';
import { InputSize } from '../Input/Input';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { useTranslation } from 'react-i18next';
import { COLOR_MAIN } from 'styles/constants/colors';
import { usePopupContainer } from '../../feedback';

// FIXME: Omit<Props, 'value'> - выдаёт хрень какую-то

export interface Props extends RSelectProps<OptionTypeBase, boolean> {
  optionClassName?: string;
  startWith?: ReactNode;
  affixClassName?: string;
  size?: InputSize;
  /* max showed selected options for multiSelect mode */
  maxShowedOptions?: number;
}

const isOptionDisabled = (o: OptionTypeBase) => o.disabled === true;

const stylesGlobal = ({ size }: Props): StylesConfig<OptionTypeBase, boolean> => ({
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    borderColor: isFocused ? COLOR_MAIN : '#97a5c9',
    borderWidth: '1px',
    borderRadius: 10,
    backgroundColor: isDisabled ? '#eceef1' : '#f8faff',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#444d78',
    fontSize: 16,
    fontWeight: 'normal',
  }),
  multiValue: (styles) => ({
    ...styles,
    color: '#F8FAFF',
    borderRadius: 10,
    height: size === 'small' ? 20 : 28,
    fontSize: size === 'small' ? 14 : 16,
    fontWeight: 700,
    background: '#5966B8',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#F8FAFF',
    fontSize: size === 'small' ? 14 : 16,
    lineHeight: size === 'small' ? '14px' : '20px',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    borderRadius: 16,
    ':hover': {
      transform: 'scale(1.2)',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#B7C9F8',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 1051,
  }),
  valueContainer: (base) => {
    return { ...base, minHeight: size === 'small' ? 25 : 43, paddingLeft: 13 };
  },
});

//todo дальнейшая стилизация согласно дизайну (options, focus, etc...)
//todo до делать темную тему
// TODO: generic `<Select<MyOptionType> attrs />`
export const Select: FC<Props> = ({
  placeholder = '',
  styles,
  className,
  size,
  noOptionsMessage,
  ...tail
}) => {
  const _styles = useMemo(
    () => (!!styles ? Object.assign(stylesGlobal({ size }), styles) : stylesGlobal({ size })),
    [size, styles],
  );

  const { t } = useTranslation();

  return (
    <RSelect
      isOptionDisabled={isOptionDisabled}
      {...tail}
      placeholder={placeholder}
      className={cn(cl.root, className)}
      styles={_styles}
      menuPortalTarget={usePopupContainer()()}
      menuPlacement="auto"
      classNamePrefix="r-select"
      noOptionsMessage={useMemo(
        () => noOptionsMessage ?? (() => t('ui:noOptions')),
        [noOptionsMessage, t],
      )}
      components={useMemo(
        () => ({
          Control,
          ValueContainer,
          MultiValue,
          Option,
          IndicatorSeparator: null,
          ...tail.components,
        }),
        [tail.components],
      )}
    />
  );
};

const Control: FC<ControlProps<OptionTypeBase, boolean>> = ({ children, selectProps, ...tail }) => {
  const { startWith, affixClassName } = selectProps as Props;
  return (
    <components.Control {...tail} selectProps={selectProps} className={cl.control}>
      {!!startWith && <span className={cn(cl.affix, affixClassName)}>{startWith}</span>}
      {children}
    </components.Control>
  );
};

const ValueContainer: FC<ValueContainerProps<OptionTypeBase, boolean>> = ({
  children,
  ...tail
}) => {
  const { hasValue, selectProps } = tail;
  const { maxShowedOptions, isMulti, value } = selectProps as Props;

  const { t } = useTranslation();

  if (isMulti && hasValue && maxShowedOptions && value?.length > maxShowedOptions) {
    return (
      <components.ValueContainer {...tail}>
        {children}
        <span className={cl.moreItems}>
          {t('ui:andMoreItems', { qty: value!.length - maxShowedOptions })}
        </span>
      </components.ValueContainer>
    );
  }

  return <components.ValueContainer {...tail}>{children}</components.ValueContainer>;
};

// в либе почему-то нет свойства index, по факту оно есть
type MultiValuePropsExtend = MultiValueProps<OptionTypeBase> & {
  index: number;
};

const MultiValue: FC<MultiValueProps<OptionTypeBase>> = (props) => {
  const { index } = props as MultiValuePropsExtend;
  const { maxShowedOptions } = props.selectProps as Props;

  if (maxShowedOptions && index > maxShowedOptions - 1) return null;

  return <components.MultiValue {...props} children={props.children} />;
};

const Option: FC<OptionProps<OptionTypeBase, boolean>> = (props) => {
  const { optionClassName } = props.selectProps;

  return <components.Option {...props} className={optionClassName} children={props.children} />;
};
