import { FC, useEffect, useState } from 'react';
import RcDialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import { IDialogPropTypes } from 'rc-dialog/lib/IDialogPropTypes';
import cn from 'classnames';
import { runAutofocus } from 'ui/input/runAutofocus';
import { usePopupContainer } from '../PopupContainer';
import cl from './Dialog.module.scss';

// TODO: 'small'?
type DialogSize = 'default' | 'large' | 'full';

export interface DialogProps extends IDialogPropTypes {
  size?: DialogSize;
  /**
   * Пытается установить фокус на что-либо первое попавшееся из контента.
   *
   * - Если работает некорректно, то ст0ит доработать селекторы.
   * - Приводит к дополнительному вложению контента в `<div>` ради `ref`, т.к.
   *   `rc-dialog` не сделал себе `forwardRef()`.
   */
  autoFocus?: boolean;
}

const SIZE_CLS: Partial<Record<DialogSize, string>> = {
  large: cl._large,
  full: cl._full,
};

export const Dialog: FC<DialogProps> = ({
  className,
  children,
  animation = 'zoom',
  maskAnimation = 'fade',
  size,
  autoFocus = false,
  // centered = true,
  // onClose,
  ...rest
}) => {
  const getContainer = usePopupContainer();
  // const handleClose = useCallback(() => {
  //   onClose?.();
  // }, [onClose]);

  // чё-то с ref'ом она не срабатывала в каких-то случаях, хз почему
  const [div, setDiv] = useState<HTMLDivElement | null>(null);
  const [done, setDone] = useState(false);
  useEffect(() => {
    if (div && !done && runAutofocus(div)) {
      setDone(true);
    }
  }, [div, done]);

  return (
    <RcDialog
      className={cn(cl.dialog, size && SIZE_CLS[size], className)}
      animation={animation}
      maskAnimation={maskAnimation}
      // onClose={handleClose}
      // wrapClassName={cn(centered && classes.wrapCenter)}
      destroyOnClose
      getContainer={getContainer}
      {...rest}
    >
      {autoFocus ? <div ref={setDiv}>{children}</div> : children}
    </RcDialog>
  );
};
