import {
  combine,
  createEffect,
  createEvent,
  createStore,
  Event,
  merge,
  restore,
  sample,
} from 'effector';
import { createGate } from 'effector-react';
import { useEffect } from 'react';
import * as RoArray from '@cubux/readonly-array';
import * as RoMap from '@cubux/readonly-map';
import { apiTaskFinanceGet, apiTaskFinanceSave } from 'api/request/task-finance';
import { showToastError } from 'ui/feedback';
import { filterObject, hasProperty } from 'utils/object';
import { DateISO } from 'utils/types';
import { logout } from '../auth';
import { $currentCompanyId } from '../company';
import { applyValidTemplate } from '../task-template/user';
import { taskTimerDidToggle } from '../timers';
import { defaultReducer } from '../utils/defaultReducer';
import { createRequestEffectWithCancel } from '../utils/createRquestEffect';
import { withBookkeeperFx } from '../utils/withBookkeeperFx';
import {
  isTaskRef,
  TaskFinance,
  TaskFinanceDataForm,
  TaskFinanceServiceForm,
  TaskRef,
} from './types';
import {
  applyFinanceForm,
  initServicesForms,
  isEmptyServiceCreateForm,
  newFinanceDataForm,
  newServiceForm,
  TaskFinanceDataFormErrors,
  updateServiceForm,
  validateFinanceDataForm,
} from './form';

const { request: fetchFx, cancel: cancelFetch } = createRequestEffectWithCancel(
  withBookkeeperFx(apiTaskFinanceGet),
);
const { request: saveFx, cancel: cancelSave } = createRequestEffectWithCancel(
  withBookkeeperFx(apiTaskFinanceSave),
);
merge([fetchFx.failData, saveFx.failData]).watch(showToastError);

export const TaskRefGate = createGate<TaskRef | {}>();
export const TaskFinanceTabGate = createGate();
export const saveTaskFinance = createEvent<any>();

const whenToReset = merge([TaskFinanceTabGate.close, $currentCompanyId.updates, logout]);
sample({
  source: whenToReset,
  target: [cancelFetch, cancelSave],
});

const fetchAndResetFx = createEffect(fetchFx);

// т.е. сначала надо монтировать TaskRefGate, а потом после него уже TaskFinanceTabGate
sample({
  clock: TaskFinanceTabGate.open,
  source: TaskRefGate.state,
  filter: isTaskRef,
  target: fetchAndResetFx,
});
sample({
  clock: taskTimerDidToggle,
  source: TaskRefGate.state,
  filter: isTaskRef,
  target: fetchFx,
});

export const $financeSource = createStore<TaskFinance | null>(null)
  .reset(whenToReset)
  .on([fetchFx.doneData, saveFx.doneData], defaultReducer);

export const $financeSourceServices = $financeSource.map((f) =>
  RoMap.fromArray(f?.services || [], (s) => s.id),
);

const changeForm = createEvent<Partial<TaskFinanceDataForm>>();
export const changeAddToDate = changeForm.prepend((add_to_date: boolean) => ({ add_to_date }));
export const changeDate = changeForm.prepend((date: DateISO) => ({ date }));
export const changePlanMinutes = changeForm.prepend((v: number | null) => ({
  plan_minutes: v ?? undefined,
}));

export type WithItemIndex = { index: number };
// https://gitlab.com/finkoper_front/slack-accounting-web/-/issues/97
// > 2. Делаем пока визуально 1 услугу, но на бэке запланируем больше одной
// > услуги к одной задаче
// Да наоборот, так больше переделываний

// если понадобится сортировка
// export const changeServicesList = changeForm.prepend(
//   (services: readonly TaskFinanceServiceForm[]) => ({ services }),
// );
export const serviceAppend = createEvent<Partial<TaskFinanceServiceForm> | undefined>();
export const serviceAppendDefault = serviceAppend.prepend((_: any) => undefined);
export const serviceRemove = createEvent<WithItemIndex>();
export const serviceChange = createEvent<WithItemIndex & Partial<TaskFinanceServiceForm>>();

const serviceSetFocus = createEvent<WithItemIndex>();
export const useServiceSetFocus = (index: number, set: () => void) =>
  useEffect(
    () =>
      serviceSetFocus.watch((p) => {
        if (p.index === index) {
          set();
        }
      }),
    [index, set],
  );
serviceAppend.watch(() => {
  const services = $financeForm.getState()?.services;
  if (services?.length) {
    const index = services.length - 1;
    // даём морде очухаться и отрендерить новую строчку
    setTimeout(() => serviceSetFocus({ index }), 50);
  }
});

const withSource = <T>(clock: Event<T>) =>
  sample({
    clock,
    source: $financeSource,
    fn: (src, payload) => ({ src, payload }),
  });

const getServicesForms = (form: TaskFinanceDataForm, src: TaskFinance | null) =>
  form.services ?? initServicesForms(src?.services ?? []);

const whenToResetForm = merge([whenToReset, fetchAndResetFx.done, saveFx.done]);

export const $financeForm = createStore<TaskFinanceDataForm>({})
  //.reset(whenToResetForm)
  .on(withSource(whenToResetForm), (_, { src }) => newFinanceDataForm(src))
  .on(changeForm, (form, update) => filterObject({ ...form, ...update }))
  .on(withSource(serviceAppend), (form, { src, payload: item }) => ({
    ...form,
    services: [...getServicesForms(form, src), newServiceForm(item)],
  }))
  .on(applyValidTemplate, (form, { add_to_date, plan_minutes, services }) => ({
    ...form,
    add_to_date,
    plan_minutes,
    services: services.map(newServiceForm),
  }))
  .on(withSource(serviceRemove), (form, { src, payload: { index } }) => {
    if (index >= 0) {
      let nextServices = getServicesForms(form, src);
      if (index < nextServices.length) {
        return {
          ...form,
          services: RoArray.remove(nextServices, index),
        };
      }
    }
  })
  .on(withSource(serviceChange), (form, { src, payload: { index, ...data } }) => {
    if (index >= 0) {
      let nextServices = getServicesForms(form, src);
      if (index < nextServices.length) {
        nextServices = RoArray.update(nextServices, index, (prev) => updateServiceForm(prev, data));
      } else {
        nextServices = [...nextServices, newServiceForm(data)];
      }
      return { ...form, services: nextServices };
    }
  });

export const $financeReplaceForm = combine($financeSource, $financeForm, applyFinanceForm);

const setFormErrors = createEvent<TaskFinanceDataFormErrors>();
export const $financeFormErrors = restore(setFormErrors, null).reset(
  whenToResetForm,
  serviceAppend,
  serviceRemove,
  serviceChange,
);

export const validateFinanceFormFx = createEffect(async () => {
  const form = $financeForm.getState();
  const errors = validateFinanceDataForm(form);
  setFormErrors(errors);
  if (errors) {
    return Promise.reject(new Error('validation not passed'));
  }
});

sample({
  clock: sample({
    source: saveTaskFinance,
    filter: TaskFinanceTabGate.status,
  }),
  source: { form: $financeForm, ref: TaskRefGate.state },
  filter: ({ ref }) => hasProperty(ref, 'task_id'),
  fn: ({ form, ref }) => ({
    ...(ref as TaskRef),
    ...form,
    services: form.services?.filter((s) => s.id || !isEmptyServiceCreateForm(s)),
  }),
  target: saveFx,
});
