import { AnyKey, IdGetter, TreeDataStruct } from './types';

export type MapParents<ID extends AnyKey, RootID extends AnyKey> = ReadonlyMap<ID, ID | RootID>;
export const buildMapParents = <Node, ID extends AnyKey, RootID extends AnyKey>(
  struct: TreeDataStruct<Node, ID, RootID>,
  getId: IdGetter<Node, ID>,
): MapParents<ID, RootID> => {
  const m = new Map<ID, ID | RootID>();
  for (const [parentId, children] of Array.from(struct)) {
    for (const node of children) {
      m.set(getId(node), parentId);
    }
  }
  return m;
};

export const getParents = <ID extends AnyKey, RootID extends AnyKey>(
  mapParents: MapParents<ID, RootID>,
  rootId: RootID,
  id?: ID,
): ID[] => {
  const parents: ID[] = [];
  if (id !== undefined) {
    const m: ReadonlyMap<ID | RootID, ID | RootID> = mapParents;
    let parent = m.get(id);
    while (true) {
      if (parent === undefined || parent === rootId) {
        break;
      }
      const p: ID = parent as Exclude<typeof parent, RootID>;
      parents.unshift(p);
      parent = m.get(parent);
    }
  }
  return parents;
};
