import { durationFormat, durationSplit } from 'utils/date';
import { TimerToggle } from '../timers/types';
import { Task } from './types';

export const reducerTaskTimerToggled = (
  task: Task | null | false | undefined,
  toggle: TimerToggle,
): Task | undefined => {
  if (task) {
    if (
      toggle.started &&
      [toggle.started.task_id, toggle.started.typical_task_id].includes(task.id)
    ) {
      if (task.timer.is_started) {
        return task;
      }
      return {
        ...task,
        timer: {
          ...task.timer,
          is_started: true,
        },
      };
    }

    const paused = toggle.paused?.find((timer) =>
      [timer.task_id, timer.typical_task_id].includes(task.id),
    );
    if (paused) {
      const total_time_sec = paused.total?.total_time_sec ?? 0;
      const total_time = durationFormat(durationSplit(total_time_sec));
      if (
        !task.timer.is_started &&
        task.timer.total_time_sec === total_time_sec &&
        task.timer.total_time === total_time
      ) {
        return task;
      }
      return {
        ...task,
        timer: {
          is_started: false,
          total_time_sec,
          total_time,
        },
      };
    }
  }
};

export const reduceTasksTimerToggled = (tasks: readonly Task[], toggle: TimerToggle) => {
  let changed = false;
  const next = tasks.map((task) => {
    const next = reducerTaskTimerToggled(task, toggle);
    if (next) {
      changed = true;
      return next;
    }
    return task;
  });
  if (changed) {
    return next;
  }
};
