import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { City, Country, CountryId } from 'models/country/types';

const api = {
  list2: () => dataOnly.get<null, ApiResponse2<Country[]>>(`/api2/countries`),

  countryCityList: (countryId: CountryId) =>
    dataOnly.get<null, ApiResponse2<City[]>>(`/api2/countries/${countryId}/cities`),
};

export default api;
