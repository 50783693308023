import { combine, createEffect, createEvent, createStore, restore } from 'effector';
import * as RoMap from '@cubux/readonly-map';
import { ApiResponse, ApiResponse2 } from 'api/types';
import { EMPTY_ARRAY } from 'constants/utils';
import { isNotNull, strCmp } from 'utils/fn';
import { ObjectUrl, UnixTimestamp } from 'utils/types/primitive';
import { ChannelId } from '../channel/types';
import {
  AllUsers,
  AvatarId,
  CommonUser,
  CustomerUser,
  IPInfo,
  IWithUserName,
  OwnerUser,
  UserId,
  UserInfoUpdateForm,
} from './types';

export const getUnknownUser = (id: UserId): CommonUser => ({
  id,
  firstname: '',
  lastname: '',
  avatar: '' as AvatarId,
  email: '',
  schemeadmin: false,
  schemeguest: false,
  schemeuser: false,
  deleteat: 0 as UnixTimestamp,
});

export const $user = createStore<OwnerUser | null>(null);
// здесь был `Store<UserID | undefined>`, а `undefined` нельзя в стор записать -
// он матюгается в крашится (было на dev как-то точно)
export const $userId = $user.map((c) => c?.id ?? null);

export const resetUser = createEvent<any>();
export const getUserFx = createEffect<void, ApiResponse<OwnerUser>>();
export const updateUserFx = createEffect<UserInfoUpdateForm, ApiResponse2<any>>();
//----------------------
export const setLocale = createEvent<string>();
export const $locale = restore(setLocale, null);
//----------------------

export const $ipInfo = createStore<IPInfo | null>(null);

export const getIpInfoFx = createEffect<void, ApiResponse<IPInfo>>();

/** $users - список пользователей организации */
export const $users = createStore<CommonUser[]>([]);
export const getUsersFx = createEffect<string, ApiResponse<AllUsers>>();
export const fetchUsers = createEvent();
export const resetUsers = createEvent<any>();

/** $deletedUsers - список удаленных пользователей организации */
export const $deletedUsers = createStore<CommonUser[]>([]);
export const $deletedUsersNames = $deletedUsers.map(
  (c) => new Map(c.map((c) => [c.id, getFio(c)])),
);
/** $employeeUsers - список пользователей организации, без текущего пользователя */
export const $employeeUsers = combine($user, $users, (user, users) =>
  users.filter((c) => c.id !== user?.id),
);
/** юзеры без админских прав */
export const $commonUsers = $users.map((users) => users.filter((user) => !user.schemeadmin));
/** юзеры с админскими правами */
export const $adminUsers = $users.map((users) => users.filter((user) => user.schemeadmin));
export const $adminUsersId = $adminUsers.map<ReadonlySet<UserId>>(
  (users) => new Set(users.map((u) => u.id)),
);
/** $usersMap - map пользователей организации */
export const $usersMap = $users.map((c) => new Map(c.map((c) => [c.id, c])));
export const $usersNames = $users.map((c) => new Map(c.map((c) => [c.id, getFio(c)])));
// const _sortUsersNames = sortDecorate<
//   [id: UserId, origName: string],
//   [src: [UserId, string], upperName: string]
// >(
//   (u) => [u, u[1].toUpperCase()],
//   ([u]) => u,
// )(([, a], [, b]) => strCmp(a, b));
// export const $usersNamesSorted = $usersNames.map<ReadonlyMap<UserId, string>>((map) =>
//   map.size ? new Map(_sortUsersNames(Array.from(map))) : EMPTY_MAP,
// );
export const $usersIdSortedByName = $usersNames.map<readonly UserId[]>((map) =>
  // map.size ? _sortUsersNames(Array.from(map)).map(([id]) => id) : EMPTY_ARRAY,
  map.size
    ? Array.from(map, ([id, name]) => [id, name.toUpperCase()] as const)
        .sort(([, a], [, b]) => strCmp(a, b))
        .map(([id]) => id)
    : EMPTY_ARRAY,
);
export const $usersCount = $users.map((m) => m.length);
export const $allUsersNames = combine($usersNames, $deletedUsersNames, RoMap.merge);
// TODO: когда данные юзеров ещё не прогружены, здесь также получается false,
//  отчего некоторые UI могут триггерить какие-то неадекватные действия (побочные
//  эффект типа редиректов). Надо как-то порешать систематически.
export const $userIsAdmin = combine(
  $usersMap,
  $user,
  (map, user) => !!map.get(user?.id!)?.schemeadmin,
);

export const $customerUsers = createStore<CustomerUser[]>(EMPTY_ARRAY);
export const $customerUsersMap = $customerUsers.map((c) => new Map(c.map((c) => [c.id, c])));
export const $customerDeletedUsers = createStore<CustomerUser[]>(EMPTY_ARRAY);
export const $customerDeletedUsersMap = $customerDeletedUsers.map(
  (c) => new Map(c.map((c) => [c.id, c])),
);

const _getFio = (data: IWithUserName): string =>
  `${data.lastname || ''} ${data.firstname || ''} ${data.patronymic || ''}`.trim();

export const getFio = (data?: IWithUserName): string => (data ? _getFio(data) : 'deleted');
export const getFioOrEmpty = (data?: IWithUserName): string | null =>
  (data && _getFio(data)) || null;

export const matchFioFilter = (data: IWithUserName, wordsLowerCase: readonly string[]): boolean => {
  const haystack = [data.lastname, data.firstname, data.patronymic].filter(isNotNull);
  return wordsLowerCase.every((word) => haystack.some((name) => name.toLowerCase().includes(word)));
};

/** Typing */
export const typingStartFx = createEffect<ChannelId, ApiResponse<''>>();
export const typingEndFx = createEffect<ChannelId, ApiResponse<''>>();
export const typing = createEvent<ChannelId>();
export const typingEnd = createEvent<ChannelId>();

/** Avatar */
export const setAvatarFx = createEffect<Blob, ApiResponse<{ avatarid: AvatarId }>>();
export const getAvatarFx = createEffect<AvatarId, ObjectUrl>();
export const deleteAvatarFx = createEffect<void, void>();
export const $avatar = createStore<ObjectUrl | ''>('');
