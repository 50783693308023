import { FC, ReactElement } from 'react';
import { DateFormatRelativeOptions, useDateFormatRelative } from 'hooks/i18n';

interface Props extends DateFormatRelativeOptions {
  date?: Date | null;
  baseDate: Date | number;
  empty?: ReactElement | string;
}

export const DateFormatRelative: FC<Props> = ({
  date,
  baseDate,
  empty = null,
  children,
  ...rest
}) => {
  const formatDateRelative = useDateFormatRelative(rest);
  return date ? (
    <>{formatDateRelative(date, baseDate)}</>
  ) : typeof empty === 'string' ? (
    <>{empty}</>
  ) : (
    empty
  );
};
