import { ChangeEvent, FocusEventHandler, forwardRef } from 'react';
import { Input, Props as InputProps } from '../Input/Input';
import { useInputChangeHandler } from '../valueInput';
import { useIntegerInput } from './useIntegreInput';

type _I = HTMLInputElement;

export interface IntegerInputProps extends Omit<InputProps, 'value' | 'onChange'> {
  value?: number | null;
  onChange?: (value: number | null, e: ChangeEvent<_I>) => void;
  triggerEveryChange?: boolean;
  onFocus?: FocusEventHandler<_I>;
  onBlur?: FocusEventHandler<_I>;
}

export const IntegerInput = forwardRef<_I, IntegerInputProps>(
  ({ value, onChange, triggerEveryChange, onFocus, onBlur, ...rest }, ref) => {
    {
      // иногда из некоторых АПИ приходит строка в цифрами
      const raw: unknown = value;
      if (raw !== null && raw !== undefined && typeof raw !== 'number') {
        const i = Number(raw);
        if (!isNaN(i) && Number.isInteger(Math.round(i))) {
          if (process.env.NODE_ENV === 'development') {
            console.warn('Not a number type passed to `value`:', raw, '; converted to number:', i);
          }
          value = i;
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.warn('Unexpected type passed to `value`:', raw);
          }
          value = null;
        }
      }
    }

    const attrs = useIntegerInput({
      value,
      onChange,
      triggerEveryChange,
      onFocus,
      onBlur,
    });

    return (
      <Input
        ref={ref}
        {...rest}
        {...attrs}
        onChange={useInputChangeHandler(attrs.onChange)}
        type="text"
        inputMode="numeric"
      />
    );
  },
);
