import { endOfMonth, startOfMonth } from 'date-fns';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  EffectParams,
  Event,
  merge,
  restore,
  sample,
} from 'effector';
import api, {
  AnalyticsMode,
  AnalyticsMonthParams,
  AnalyticsParams,
  TaskListParams,
  TaskTypicalDeleteParams,
} from 'api/request/task';
import { EMPTY_ARRAY } from 'constants/utils';
import {
  fromApiToTask,
  getTaskUniId,
  StatDay,
  StatWeek,
  Task,
  TaskId,
  TaskPatchParams,
  TaskToggleStatusParams,
  TaskTypicalUpdateParams,
} from 'models/task/types';
import { $currentCompanyId, $currentCompanyIdOrNull } from 'models/company';
import { $user } from 'models/user';
import { toISODateString } from 'utils/date';
import { fnVoid } from 'utils/fn';
import { promptFn, showToast, ToastTypes } from 'ui/feedback';
import { CompanyId } from 'models/company/types';
import i18next from 'i18next';
import { pending } from 'patronum';
import { getHolidaysFx } from 'models/misc';
import { TaskChecklist } from '../task-checklist/types';
import { tasksRepeatableDeleteFx, tasksRepeatableUpdateFx } from '../tasks-repeatable';
import { repeatableTaskPrompt } from 'components/task/RepeatableTaskPrompt';
import { toastErrorFx } from '../utils/messages';
import { createRequestEffect } from '../utils/createRquestEffect';
import { refreshActiveTimer, taskTimerDidToggle } from '../timers';
import { DateISO } from '../../utils/types';
import { $tagsLocal } from '../tags';
import { Tag } from '../tags/types';
import { reduceTasksTimerToggled } from './timerHelpers';
import { CustomerId } from '../customer/types';
import { getLsKeyByCompany, LSKey, LsKeyByCompany } from '../persistent';
import { WithBookkeeper } from 'api/types/request';
import { createGate } from 'effector-react';
import { history } from '../../utils/history';

export const TaskPageGate = createGate();

const TASK_PAGE_PER_PAGE = 30;

export interface Filter extends Omit<TaskListParams, 'bookkeeper_team_id' | 'user_id'> {}

export interface LsFiler {
  filter: Filter;
  lsKey: LSKey;
}

export interface LsPartialFiler {
  filter: Partial<Filter>;
  lsKey: LSKey;
  lsFields: Array<keyof Filter>;
}

const isLsPartialFilter = (filter: Partial<Filter> | LsPartialFiler): filter is LsPartialFiler =>
  Object.hasOwn(filter, 'lsKey');

export const resetTasks = createEvent();
export const taskCreateFx = createRequestEffect(api.create);
export const taskDidCreateLocal = taskCreateFx.done;
export const restoreTask = createEvent<TaskId>();
export const restoreTaskWithPrompt = createEvent<TaskId>();
export const setSearchText = createEvent<string>();

export const taskPageBottomScrolled = createEvent();

export const taskRestoreFx = createRequestEffect(api.restore);

export const getTasks = createEvent();
const getTasksFx = createRequestEffect(api.list, true);
export const $tasks = createStore<Task[]>([]).reset(resetTasks);
export const $tasksLoading = getTasksFx.pending;
export const $searchText = restore(
  sample({
    source: setSearchText,
    filter: TaskPageGate.status,
  }),
  '',
).reset(TaskPageGate.close);
const $currentTaskPage = createStore(0).reset(TaskPageGate.close);
const $isTaskListFinished = createStore(false).reset(TaskPageGate.close);

export const getDefaultFilter = (lsKey?: LsKeyByCompany): Filter => {
  let ret: Filter = {
    task_type: 'all',
    list_mode: 'assigned_to_me',
    status: 'open',
    date_from: toISODateString(startOfMonth(new Date())),
    date_to: toISODateString(endOfMonth(new Date())),
  };

  if (lsKey) {
    try {
      const s = JSON.parse(localStorage.getItem(lsKey) ?? '{}') as Partial<Filter>;
      ret = { ...ret, ...s };
    } catch (e) {}
  }

  return ret;
};

// На страницах tasks и calendar, к сожалению используется один и тот же стор $tasks,
// при переходе, например, с Календаря в Задачи, стор не успевается очищаться и выполняется один лишний рендер,
// который может быть очень продолжительным, если в сторе было много строк. Таким образом пришлось сделать этот костыль для предварительного
// очищения стора. Тоже самое в Аналитике
history.listen(({ pathname }) => {
  if (pathname.startsWith('/tasks') || pathname.startsWith('/calendar')) {
    resetTasks();
  }
});

export const resetFilter = createEvent<undefined | LSKey>();
// Как то заморочено получилось, но не хотелось все переделывать
// В общем если setFilter простая структура с полями фильтра, то все работает по старому,
// если же указаны дополнительные поля (кей для локалСтора и список полей которые туда сохранить), то эти поля сохранятся в локалСтор
// Соответственно если в updateFilter указан кей для стора, то эти поля будут загружены из сохранения
export const setFilter = createEvent<Partial<Filter> | LsPartialFiler>();
export const updateFilter = createEvent<Filter | LsFiler>();

export const updateLsFilterFx = createEffect<LsFiler & WithBookkeeper, Filter>(
  async ({ lsKey, filter, bookkeeper_team_id }) => {
    const key = `${lsKey}[${bookkeeper_team_id}]`;
    const s = localStorage.getItem(key);
    if (s !== null) {
      try {
        return Promise.resolve({ ...filter, ...JSON.parse(s) });
      } catch (e) {}
    }

    return Promise.resolve(filter);
  },
);
export const $filter = createStore<Filter>(getDefaultFilter());
$filter.on(setFilter, (s, data) =>
  !isLsPartialFilter(data) ? { ...s, ...data } : { ...s, ...data.filter },
);
sample({
  clock: setFilter,
  source: $currentCompanyId,
  filter: (_, filter) => isLsPartialFilter(filter),
  target: createEffect(
    ({ lsKey, filter, bookkeeper_team_id, lsFields }: LsPartialFiler & WithBookkeeper) => {
      const key = `${lsKey}[${bookkeeper_team_id}]`;
      try {
        const s = JSON.parse(localStorage.getItem(key) ?? '{}') as Partial<Filter>;
        (Object.keys(filter) as Array<keyof Partial<Filter>>).forEach((key) => {
          if (lsFields.includes(key)) {
            // @ts-ignore
            s[key] = filter[key];
          }
        });
        localStorage.setItem(key, JSON.stringify(s));
      } catch (e) {}
    },
  ),
  fn: (bookkeeper_team_id, data): LsPartialFiler & WithBookkeeper => ({
    ...(data as LsPartialFiler),
    bookkeeper_team_id: bookkeeper_team_id as CompanyId,
  }),
});

const filterUpdated = sample({
  clock: updateFilter,
  filter: (data: Filter | LsFiler): data is Filter => !Object.hasOwn(data, 'lsKey'),
});

sample({
  clock: updateFilter,
  source: $currentCompanyId,
  filter: (_, data: Filter | LsFiler): data is LsFiler => Object.hasOwn(data, 'lsKey'),
  target: updateLsFilterFx,
  fn: (bookkeeper_team_id, data): LsFiler & WithBookkeeper => ({
    ...(data as LsFiler),
    bookkeeper_team_id: bookkeeper_team_id as CompanyId,
  }),
});
$filter.on([updateLsFilterFx.doneData, filterUpdated], (_, data) => data);

sample({
  clock: resetFilter,
  source: $currentCompanyId,
  target: $filter,
  fn: (companyId, lsKey) =>
    getDefaultFilter(lsKey ? getLsKeyByCompany(lsKey, companyId as CompanyId) : undefined),
});

sample({
  clock: [
    getTasks,
    setFilter,
    filterUpdated,
    updateLsFilterFx.done,
    resetFilter,
    taskCreateFx.done,
    tasksRepeatableUpdateFx.done,
    setSearchText,
  ],
  source: [$user, $currentCompanyIdOrNull, $filter, TaskPageGate.status, $searchText] as const,
  target: getTasksFx,
  fn: ([user, companyId, filter, isTaskPage, searchText]): TaskListParams => ({
    bookkeeper_team_id: companyId!,
    user_id: user!.id,
    ...filter,
    search: searchText || undefined,
    page: isTaskPage ? 1 : undefined,
    per_page: isTaskPage ? TASK_PAGE_PER_PAGE : undefined,
  }),
});
sample({
  clock: taskPageBottomScrolled,
  source: {
    user: $user,
    companyId: $currentCompanyIdOrNull,
    filter: $filter,
    currentPage: $currentTaskPage,
    status: TaskPageGate.status,
    isTaskListFinished: $isTaskListFinished,
    searchText: $searchText,
  },
  filter: ({ status, isTaskListFinished }) => status && !isTaskListFinished,
  target: getTasksFx,
  fn: ({ companyId, user, filter, currentPage, searchText }) => ({
    bookkeeper_team_id: companyId!,
    user_id: user!.id,
    ...filter,
    search: searchText || undefined,
    page: currentPage + 1,
    per_page: TASK_PAGE_PER_PAGE,
  }),
});

$currentTaskPage.on(getTasksFx.doneData, (_, { page }) => page);
$isTaskListFinished.on(
  getTasksFx.doneData,
  (_, { data }) => !data || data.length === 0 || data.length < TASK_PAGE_PER_PAGE,
);
$tasks.on(getTasksFx.done, (s, { params, result }) => {
  const data = result.data?.map(fromApiToTask) ?? EMPTY_ARRAY;
  return params.page === undefined || params.page < 2 ? data : s.concat(data);
});

export const taskChangeDateCalendar = createEvent<{ task: Task; date: DateISO }>();
export const taskToggleStatus = createEvent<Task>();
export const taskPatchFx = createEffect(
  async ({ taskId, form }: TaskPatchParams) => await api.patch(taskId, form),
);
export const taskTypicalUpdateFx = createEffect(
  async ({ taskId, form }: TaskTypicalUpdateParams) => await api.updateTypical(taskId, form),
);
export const taskToggleStatusFx = createEffect<
  TaskToggleStatusParams<TaskPatchParams> | TaskToggleStatusParams<TaskTypicalUpdateParams>,
  any
>(async ({ params, effect }: TaskToggleStatusParams<any>) => await effect(params));

//событие для того чтобы обновить текущий стейт без доп.запроса. Если обновляем задачу с повторениями, то будем производить новый фетч
const taskDidUpdate = merge([taskPatchFx.doneData, taskTypicalUpdateFx.doneData]);
export const taskDidUpdate2 = taskDidUpdate.map(({ data }) => fromApiToTask(data));
//проверка, вдруг после того как изменили задачу, она стала не соответствовать текущему фильтру
const isShowAfterFilter = (task: Task) => {
  const { status, priority, date_from, date_to, assignee } = $filter.getState();
  if (status && status !== task.status) return false;
  else if (priority && priority !== task.priority) return false;
  else if (
    date_from &&
    date_from > task.date_end &&
    date_from > task.date_begin &&
    date_from > task.status_updated_at
  )
    return false;
  else if (
    date_to &&
    date_to < task.date_end &&
    date_to < task.date_begin &&
    date_to < task.status_updated_at
  )
    return false;
  else if (assignee && !task.assignees?.find((c) => c.user_id === assignee)) return false;

  return true;
};
$tasks.on(taskDidUpdate2, (s, task) => {
  const taskId = getTaskUniId(task);
  //поскольку некоторые параметры не приходят в ответе после изменения задачи (например чеклисты), то обновляем объект, а не заменяем
  if (isShowAfterFilter(task))
    return s.map((c) => (getTaskUniId(c) === taskId ? { ...c, ...task } : c));
  else return s.filter((c) => getTaskUniId(c) !== taskId);
});
//изменение статуса для ручной задачи
sample({
  clock: taskToggleStatus,
  filter: ({ is_typical }) => !is_typical,
  target: taskToggleStatusFx,
  fn: (task): TaskToggleStatusParams<TaskPatchParams> => ({
    params: {
      taskId: task.id,
      form: { status: task.status === 'open' ? 'closed' : 'open' },
    },
    effect: taskPatchFx,
  }),
});
//изменение статуса для типовой задачи
sample({
  clock: sample({ clock: taskToggleStatus, filter: ({ is_typical }) => is_typical }),
  source: $currentCompanyIdOrNull,
  target: taskToggleStatusFx,
  fn: (companyId, task): TaskToggleStatusParams<TaskTypicalUpdateParams> => ({
    params: {
      taskId: task.id,
      form: {
        status: task.status === 'open' ? 'closed' : 'open',
        bookkeeper_team_id: companyId!,
        date_calendar: task.date_calendar,
        team_id: task.team_id!,
        task_id: task.id,
        assignees: task.assignees?.map((c) => c.user_id) || [],
        tags: task.tags?.map((c) => c.id) ?? null,
      },
    },
    effect: taskTypicalUpdateFx,
  }),
});
sample({
  clock: taskChangeDateCalendar,
  filter: ({ task }) => !task.is_typical,
  target: taskPatchFx,
  fn: ({ task, date }): TaskPatchParams => ({ taskId: task.id, form: { date_calendar: date } }),
});

//Удаление
export const taskDeleteFx = createEffect(async (taskId: TaskId) => await api.delete(taskId));
// export const taskTypicalDelete = createEvent<Task>();
export const taskTypicalDeleteFx = createEffect(
  async (params: TaskTypicalDeleteParams) => await api.deleteTypical(params),
);
/*$tasks.on(taskDeleteFx.done, (s, { params: taskId }) => {
  const id = s.findIndex((c) => c.id === taskId && !c.is_typical);
  const newArr = [...s];
  newArr.splice(id, 1);
  return newArr;
});
$tasks.on(taskTypicalDeleteFx.done, (s, { params: { task_id, team_id } }) => {
  const id = s.findIndex((c) => c.id === task_id && c.team_id === team_id && c.is_typical);
  const newArr = [...s];
  newArr.splice(id, 1);
  return newArr;
});*/
$tasks.on(
  tasksRepeatableDeleteFx.done,
  (s, { params: { task_repeatable_settings_id, for_teams } }) => {
    return s.filter(
      (c) =>
        !(
          c.repeatable_settings &&
          c.repeatable_settings.id === task_repeatable_settings_id &&
          (!for_teams || for_teams.includes(c.team_id as CustomerId))
        ),
    );
  },
);
export const taskUniversalDelete = createEvent<Task>();
export const taskUniversalDeleteFx = createEffect<{ task: Task; companyId: CompanyId }, boolean>(
  async ({ task, companyId }) => {
    if (!!task.repeatable_settings) {
      // t('task:edit.repeatDelete.promptTitle')
      const answ = await repeatableTaskPrompt(task, 'task:edit.repeatDelete.promptTitle');

      if (!answ) return false;

      if (answ === 'forward' || answ === 'customerForward')
        await tasksRepeatableDeleteFx({
          task_repeatable_settings_id: task.repeatable_settings!.id,
          for_teams: answ === 'customerForward' && task.team_id ? [task.team_id!] : undefined,
        });
      else await taskDeleteFx(task.id);
    } else {
      if (await promptFn(i18next.t('task:deleteAnswer'))) {
        if (task.is_typical)
          await taskTypicalDeleteFx({
            bookkeeper_team_id: companyId,
            task_id: task.id,
            team_id: task.team_id!,
          });
        else {
          await taskDeleteFx(task.id);
        }
      } else {
        return false;
      }
    }
    return true;
  },
);
sample({
  clock: taskUniversalDelete,
  source: $currentCompanyIdOrNull,
  target: taskUniversalDeleteFx,
  fn: (companyId, task) => ({ task, companyId: companyId! }),
});
sample({
  clock: taskUniversalDeleteFx.done,
  target: getTasks,
});

export const getAnalyticsByDay = createEvent<{ date: string }>();
export const getAnalyticsByDayFx = createEffect(
  async (params: AnalyticsParams) => await api.analyticsByDay(params),
);
const $analyticsByDayParams = restore(getAnalyticsByDay, null);
export const $analyticsByDay = createStore<StatDay | null>(null);
sample({
  clock: getAnalyticsByDay,
  source: $currentCompanyIdOrNull,
  target: getAnalyticsByDayFx,
  fn: (companyId, { date }): AnalyticsParams => ({ bookkeeper_team_id: companyId!, date }),
});
$analyticsByDay.on(getAnalyticsByDayFx.doneData, (_, { data }) =>
  Object.keys(data).reduce((prev, key) => prev + data[key as keyof StatDay] || 0, 0) ? data : null,
);

export const getAnalyticsByMonth = createEvent<{ date: string; mode: AnalyticsMode }>();
export const getAnalyticsByMonthFx = createEffect(
  async (params: AnalyticsMonthParams) => await api.analyticsByMonth(params),
);
const $analyticsByMonthParams = restore(getAnalyticsByMonth, null);
export const $analyticsByMonth = createStore<StatWeek[]>([]);
sample({
  clock: getAnalyticsByMonth,
  source: $currentCompanyIdOrNull,
  target: getAnalyticsByMonthFx,
  fn: (companyId, { date, mode }): AnalyticsMonthParams => ({
    bookkeeper_team_id: companyId!,
    date,
    mode,
  }),
});
$analyticsByMonth.on(getAnalyticsByMonthFx.doneData, (_, { data }) => data || []);

export const refreshAnalytics = createEvent();
refreshAnalytics.watch(() => {
  const id = $currentCompanyIdOrNull.getState()!;
  const dayParams = $analyticsByDayParams.getState();
  if (dayParams)
    getAnalyticsByDayFx({ date: dayParams.date, bookkeeper_team_id: id }).catch(fnVoid);

  const monthParams = $analyticsByMonthParams.getState();
  if (monthParams)
    getAnalyticsByMonthFx({
      date: monthParams.date,
      bookkeeper_team_id: id,
      mode: monthParams.mode,
    }).catch(fnVoid);
});
sample({
  clock: [
    taskPatchFx.done,
    taskTypicalUpdateFx.done,
    taskCreateFx.done,
    taskDeleteFx.done,
    taskTypicalDeleteFx.done,
    tasksRepeatableDeleteFx.done,
    taskRestoreFx.done,
  ],
  target: refreshAnalytics,
});

/** calendar pending */
export const $calendarPending = pending({ effects: [getTasksFx, getHolidaysFx] });

/** external updating */
//todo пофиксить , бывают случаи что вызывается после создания задачи с пустым листом
export const taskChecklistDidChange = createEvent<{
  task: Task;
  checklist: TaskChecklist | null;
}>();
$tasks.on(taskChecklistDidChange, (s, { task, checklist }) =>
  s.map((c) => {
    const taskId = getTaskUniId(task);
    return getTaskUniId(c) === taskId
      ? { ...c, checklists: checklist ? [checklist] : undefined }
      : c;
  }),
);

/** Уведомления */
taskToggleStatusFx.done.watch(() => {
  showToast(ToastTypes.success, i18next.t('task:statusUpdated'));
});
sample({
  clock: [
    taskDeleteFx.fail,
    taskTypicalDeleteFx.fail,
    tasksRepeatableDeleteFx.fail,
    taskRestoreFx.fail,
  ],
  target: toastErrorFx,
});

sample({
  clock: [
    sample({
      source: merge([taskPatchFx.doneData, taskTypicalUpdateFx.doneData]),
      filter: ({ data }) => data?.status === 'closed',
    }),
    taskDeleteFx.done,
    taskTypicalDeleteFx.done,
    tasksRepeatableDeleteFx.done,
  ],
  target: refreshActiveTimer,
});
$tasks.on(taskTimerDidToggle, reduceTasksTimerToggled);

// теги в поиске

export const $filterTags = combine($filter, $tagsLocal, (f, tags): readonly Tag[] =>
  f.tags ? tags.filter((tag) => f.tags!.includes(tag.id)) : EMPTY_ARRAY,
);
export const setFilterTags = setFilter.prepend((tags: readonly Tag[]) => ({
  tags: tags.map((tag) => tag.id),
}));

//Восстановление задачи
sample({
  clock: restoreTask,
  target: taskRestoreFx,
});
sample({
  clock: restoreTaskWithPrompt,
  target: createEffect(async (taskId: TaskId) => {
    if (await promptFn(i18next.t('task:restoreAnswer'))) {
      restoreTask(taskId);
    }
  }),
});

//Calendar export
export const calendarExportClicked = createEvent<unknown>();

const fetchCalendarExportFx = createEffect(api.calendarExport);
sample({
  clock: calendarExportClicked,
  source: { filter: $filter, companyId: $currentCompanyIdOrNull },
  target: fetchCalendarExportFx,
  fn: ({ filter, companyId }): EffectParams<typeof fetchCalendarExportFx> => ({
    ...filter,
    bookkeeper_team_id: companyId!,
  }),
});
//Необходимость обновления данных на какой либо странице
export const RefreshAfterTaskUpdateGate = createGate<Event<any>>({ defaultState: createEvent() });
sample({
  clock: [taskPatchFx.done, tasksRepeatableUpdateFx.done],
  source: RefreshAfterTaskUpdateGate.status,
  filter: Boolean,
  target: createEffect(() => {
    RefreshAfterTaskUpdateGate.state.getState()();
  }),
});
