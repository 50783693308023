import { createEffect, createEvent, restore, sample } from 'effector';
import * as RoMap from '@cubux/readonly-map';
import { apiCallPhone, apiUsersList } from 'api/request/zadarma';
import { callModal } from 'components/integrations/phone/callModal/callModal';
import { EMPTY_MAP } from 'constants/utils';
import { notification } from 'models/utils/messages';
import { withBookkeeperFx } from 'models/utils/withBookkeeperFx';
import { ToastTypes } from 'ui/feedback';
import { boolNot, fnVoid } from 'utils/fn';

export const zadarmaCallActive = createEvent<boolean>();
const zadarmaCallInit = sample({ source: zadarmaCallActive, filter: Boolean });
const zadarmaCallReset = sample({ source: zadarmaCallActive, filter: boolNot });

const fetchUsersFx = withBookkeeperFx(createEffect(apiUsersList));
sample({
  clock: zadarmaCallInit,
  fn: fnVoid,
  target: fetchUsersFx,
});

const $zUsers = restore(
  fetchUsersFx.doneData.map(({ data }) => RoMap.fromArray(data, (u) => u.id)),
  EMPTY_MAP,
).reset(zadarmaCallReset);
const $hasAnyValidUser = $zUsers.map((m) => RoMap.some(m, (u) => u.user_key));
export const $zadarmaCanCall = $hasAnyValidUser;

// --------------

export const zadarmaCallPrepare = createEvent<any>();

// TODO: загрузка данных по сотрудникам и т.п. - что потом будет проверяться
//   в components/channels/channelInfo/ChannelInfo/model.ts

// --------------

export const callNumber = createEvent<string>();
const callFx = withBookkeeperFx(createEffect(apiCallPhone));
sample({
  source: callNumber,
  fn: (phone) => ({ phone }),
  target: callFx,
});
callFx.done.watch(({ params: { phone } }) => callModal(phone));

// t('ui:integrations.messages.phoneSettingError')
notification({
  clock: callFx.fail,
  mode: ToastTypes.error,
  tKey: 'ui:integrations.messages.phoneSettingError',
});
