import { Rule, ValidationResult } from 'effector-forms';
import i18next from 'i18next';

export const proxyRule = <Value>(rule: Rule<Value>) =>
  new Proxy(rule, {
    get(target: Rule<any>, p: keyof typeof target): any {
      if (p === 'errorText' && target.errorText) {
        return i18next.t(target.errorText);
      } else {
        return target[p];
      }
    },
  });

export const requiredRule = proxyRule({
  name: 'required',
  validator: (value) => Boolean(value),
  //t('validation:Required')
  errorText: 'validation:Required',
});

export const createConditionRule = <Value>(
  rule: Rule<Value>,
  validator: (form: any, source: any) => boolean,
) => {
  const newRule: Rule<Value> = {
    name: rule.name + '_condition',
    validator(value: any, form: any, source: any): boolean | ValidationResult {
      if (validator(form, source)) return rule.validator(value, form, source);

      return true;
    },
    errorText: rule.errorText,
  };
  return newRule;
};
