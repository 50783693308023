import { promptFn } from 'ui/feedback';
import i18next from 'i18next';
import Icon from 'ui/Icon';
import cl from './callModal.module.scss';

export const callModal = (phone: string) =>
  promptFn(
    <div className={cl.content}>
      <Icon type="PhoneCall" />
      <div>
        {i18next.t('ui:integrations.callModal.outComing')}: {phone}
      </div>
      <div className={cl.handSoftphone}>{i18next.t('ui:integrations.callModal.handSoftphone')}</div>
    </div>,
    { type: 'info', wrapClassName: cl.wrapClassName },
  );
