// Вот бы по-хорошему подключить какую-то либу для произвольного парсинга,
// скормить ей просто грамматику, а на выходе получить уже готовый результат.
//
// Я делал такую либу на PHP для LR(0), но это не так просто, чтобы раз, и готово.
// Да и грамматику составить будет не так просто, потому что оно скорее LR(1)
// или LR(2).
//
// Как делает приложение ватсапа:
//
// 1. Разбивает сообщение на строки по символам новой строки.
//    - При этом он ещё поддерживает inline ```code``` с переносами внутри. Это
//      единственный элемент форматирования, поддерживающий переносы внутри.
//      С учётом специфики проекта я полагаю, что его можно пока выкинуть из
//      рассмотрения, т.к. он выбивает остальную логику из колеи. Если
//      пользователи попросят, то тогда уж лучше будет попытаться привинтить
//      кастомизированный парсер markdown, который будет понимать только
//      ограниченный набор элементов.
// 2. Отдельно в каждой строке:
//    1. Тупо и примитивно выделает блоки _курсив_, *жирный*, ~зачёркнутый~.
//       - От первого попавшегося открывающегося до первого попавшегося закрывающего.
//       - Только корректная вложенность.
//       - Непарные маркеры остаются простым текстом
//    2. В конце в оставшихся текстовых частях оформляет возможные ссылки.

import { ObjectEntries } from '../types';
import { parseTextMessageToRender, TextNodeType } from './parseTextMessageToRender';

export const enum ElementType {
  BLOCK,
  INLINE,
}
interface ElementBase {
  element: ElementType;
}

export const enum InlineType {
  SPAN,
  LINK,
}
interface InlineElementBase extends ElementBase {
  element: ElementType.INLINE;
  inline: InlineType;
  content: InlineElements;
}

export const enum BlockType {
  PARA,
}
interface BlockElementBase extends ElementBase {
  element: ElementType.BLOCK;
  block: BlockType;
  content: BlockChildren;
}

export const enum SpanType {
  BOLD = 'b',
  ITALIC = 'i',
  STRIKE = 's',
}
interface InlineSpan extends InlineElementBase {
  inline: InlineType.SPAN;
  span: SpanType;
}

export const enum LinkType {
  WEB,
  EMAIL,
}
interface InlineLink extends InlineElementBase {
  inline: InlineType.LINK;
  link: LinkType;
  href: string;
}

export type InlineElement = InlineSpan | InlineLink | string;
export type InlineElements = readonly InlineElement[];

interface BlockPara extends BlockElementBase {
  block: BlockType.PARA;
}

export type BlockElement = BlockPara;
export type BlockElements = readonly BlockElement[];
export type BlockChildren = readonly (BlockElement | InlineElement)[];

const parseRestText = (text: string) =>
  parseTextMessageToRender(text).map<InlineElement>((el) => {
    switch (el.type) {
      case TextNodeType.TEXT:
        return el.content;
      case TextNodeType.URL_WEB:
        return {
          element: ElementType.INLINE,
          inline: InlineType.LINK,
          link: LinkType.WEB,
          href: el.href,
          content: [el.content],
        };
      case TextNodeType.URL_MAILTO:
        return {
          element: ElementType.INLINE,
          inline: InlineType.LINK,
          link: LinkType.EMAIL,
          href: el.href,
          content: [el.content],
        };
      default:
        return '';
    }
  });

const MARKS: Record<SpanType, string> = {
  [SpanType.BOLD]: '*',
  [SpanType.ITALIC]: '_',
  [SpanType.STRIKE]: '~',
};
const MARK_SPAN = Object.fromEntries(
  (Object.entries(MARKS) as ObjectEntries<typeof MARKS>).map(([k, v]) => [v, k] as const),
);
export const parseSpans = (line: string, parents?: ReadonlySet<SpanType>): InlineElements => {
  const allowMark = (Object.keys(MARKS) as SpanType[])
    .filter((k) => !parents?.has(k))
    .map((k) => MARKS[k])
    .join('');
  if (!allowMark) {
    return parseRestText(line);
  }
  // Safari `(?<=...)`, `(?<!...)` https://caniuse.com/js-regexp-lookbehind
  // `(?:^|(?<![\\p{L}\\p{N}\\\\]))([${allowMark}])(\\S.*?)\\1(?![\\p{L}\\p{N}])`,
  const re = new RegExp(
    `(^|[^\\p{L}\\p{N}\\\\])([${allowMark}])(\\S.*?)\\2(?![\\p{L}\\p{N}])`,
    'u',
  );
  const result: InlineElement[] = [];
  while (line) {
    const m = line.match(re);
    if (!m) {
      result.push(...parseRestText(line));
      break;
    }
    const markStart = m.index! + m[1].length;
    if (markStart > 0) {
      result.push(...parseRestText(line.substring(0, markStart)));
    }
    if (m.index! > 0) {
      line = line.substring(m.index!);
    }
    const mark = m[2];
    const span = MARK_SPAN[mark]!;
    result.push({
      element: ElementType.INLINE,
      inline: InlineType.SPAN,
      span,
      content: parseSpans(m[3], new Set(parents).add(span)),
    });
    line = line.substring(m[0].length);
  }
  return result;
};

export const parseWhatsAppMessageToRender = (input: string): BlockElements => {
  const blocks = input.split(/\r?\n/).map(
    (s): BlockElement => ({
      element: ElementType.BLOCK,
      block: BlockType.PARA,
      content: parseSpans(s.trimEnd()),
    }),
  );
  while (blocks.length && !blocks[blocks.length - 1].content.length) {
    blocks.pop();
  }
  while (blocks.length && !blocks[0].content.length) {
    blocks.shift();
  }
  return blocks;
};
