import { FC } from 'react';
import cn from 'classnames';
import cl from './Loader.module.scss';
import { IView } from 'ui/types';

interface Props extends IView {}

//Взято от сюда: https://codepen.io/ste-vg/pen/OEbYqZ
export const Loader: FC<Props> = ({ className }) => {
  return (
    <div className={cn(cl.container, className)}>
      <div className={cl.item} />
      <div className={cl.item} />
      <div className={cl.item} />
      <div className={cl.item} />
      <div className={cl.item} />
    </div>
  );
};
