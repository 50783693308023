import { ReactElement, ReactNode } from 'react';
import Icon from '../../Icon';
import { IconPositions } from '../types';
import { OwnContentProps } from './types';

export interface ContentOptions {
  loaderClassName?: string;
  iconClassName?: string;
  textClassName?: string;
}

export const renderContent = (
  { icon, iconPosition = IconPositions.start, isLoading = false }: OwnContentProps,
  children: ReactNode,
  { loaderClassName, iconClassName, textClassName }: ContentOptions,
): ReactElement => {
  const ic = icon && <Icon type={icon} className={iconClassName} />;
  const text = children ? (
    icon ? (
      <span className={textClassName}>{children}</span>
    ) : (
      children
    )
  ) : null;

  const [a, b] = iconPosition === IconPositions.start ? [ic, text] : [text, ic];

  return (
    <>
      {isLoading && <Icon type="Loader" className={loaderClassName} />}
      {a}
      {b}
    </>
  );
};
