import { durationFormat, durationParse2, durationSplitHM } from 'utils/date';
import { TimeValueHM } from './types';

export type HMValue = TimeValueHM | null;
export type HMInput = readonly [h: number | null, m: number | null];

export const EMPTY_INPUT = [null, null] as const;
export const v2i = (v: HMValue): HMInput => v || EMPTY_INPUT;
export const i2v = (i: HMInput): HMValue =>
  i[0] !== null && i[1] !== null ? (i as HMValue) : null;
export const vEQ = (a: HMValue, b: HMValue) =>
  a ? b !== null && a[0] === b[0] && a[1] === b[1] : !b;
export const iEQ = (a: HMInput, b: HMInput) => a[0] === b[0] && a[1] === b[1];

const reHM = /^\d+:\d+$/;
export const parseStrInput = (hm: HMValue) => (hm ? durationFormat(hm) : '');
export const formatStringValue = (value: string): HMValue =>
  value && reHM.test(value) ? durationParse2(value) : null;

export const parseIntInput = (hm: HMValue) => hm && hm[0] * 60 + hm[1];
export const formatIntValue = (value: number | null): HMValue =>
  value !== null ? durationSplitHM(value) : null;
