import { combine, createEffect, createEvent, createStore, merge, sample } from 'effector';
import { createGate } from 'effector-react';
import { apiTimersListActive, apiTimersListLatest, apiTimerToggle } from 'api/request/timers';
import { WithBookkeeper } from 'api/types/request';
import { EMPTY_ARRAY } from 'constants/utils';
import { showToast, ToastTypes } from 'ui/feedback';
import { $currentCompanyId } from '../company';
import { $userId } from '../user';
import { Task } from '../task/types';
import { getTimerToggleParams, stripTimerTaskText, Timer, TimerToggle } from './types';

export const UserTimerGate = createGate();
export const TaskTimerControlGate = createGate();
export const taskTimerDidToggle = createEvent<TimerToggle>();
export const refreshActiveTimer = createEvent<any>();

const fetchActiveFx = createEffect(
  async (params: WithBookkeeper) => (await apiTimersListActive(params)).data ?? EMPTY_ARRAY,
);
const fetchLatestFx = createEffect(
  async (params: WithBookkeeper) => (await apiTimersListLatest(params)).data ?? EMPTY_ARRAY,
);
export const toggleActiveFx = createEffect(
  async (task: Pick<Task, 'id' | 'team_id' | 'is_typical'>) => {
    const bookkeeper_team_id = $currentCompanyId.getState();
    if (bookkeeper_team_id) {
      return (
        await apiTimerToggle({
          bookkeeper_team_id,
          task_id: task.id,
          team_id: task.team_id ?? undefined,
          is_typical: task.is_typical,
          ...getTimerToggleParams(),
        })
      ).data;
    }
  },
);
sample({
  source: toggleActiveFx.doneData,
  filter: Boolean,
  target: taskTimerDidToggle,
});

const $activeTimers = createStore<readonly Timer[]>(EMPTY_ARRAY)
  .reset($userId, $currentCompanyId)
  .on(fetchActiveFx.doneData, (_, list) => list.map(stripTimerTaskText))
  .on(sample({ source: toggleActiveFx.doneData, filter: Boolean }), (_, data) =>
    (data.started ? [data.started] : data.paused).map(stripTimerTaskText),
  );

const $latestTimers = createStore<readonly Timer[]>(EMPTY_ARRAY)
  .reset($userId, $currentCompanyId)
  .on(fetchLatestFx.doneData, (_, list) => list.map(stripTimerTaskText))
  .on(sample({ source: toggleActiveFx.doneData, filter: Boolean }), (_, data) => {
    if (data.paused) {
      return data.paused.map(stripTimerTaskText);
    }
  });

// Пока решили, что у Сотрудника может быть только один активный таймер. Т.е.
// нельзя делать сразу несколько дел параллельно в одну каску.
// Когда Сотрудник с активным таймером начинает новый в другой задаче,
// предыдущий останавливается.
const _first = <T>(list: readonly T[]): T | null => list[0] ?? null;
export const $activeTimer1 = $activeTimers.map(_first);
export const $latestTimer1 = $latestTimers.map(_first);
export const $headTimer = combine($activeTimer1, $latestTimer1, (a, l) => a || l);

const fetchActive = createEvent<WithBookkeeper>();
const fetchLatest = createEvent<WithBookkeeper>();
const not = (b: any) => !b;
sample({
  source: fetchActive,
  filter: fetchActiveFx.pending.map(not),
  target: fetchActiveFx,
});
sample({
  source: fetchLatest,
  filter: fetchLatestFx.pending.map(not),
  target: fetchLatestFx,
});
sample({
  clock: [
    // toggleActiveFx.done, в его результате не было `total: {}`, а потом добавили
    TaskTimerControlGate.open,
    UserTimerGate.open,
    refreshActiveTimer,
    $currentCompanyId,
    $userId,
  ],
  source: {
    bookkeeper_team_id: $currentCompanyId,
  },
  filter: (s): s is WithBookkeeper => Boolean(s.bookkeeper_team_id),
  target: [fetchActive, fetchLatest],
});

merge([fetchActiveFx.failData, fetchLatestFx.failData, toggleActiveFx.failData]).watch((e) =>
  showToast(ToastTypes.error, e.message),
);
