import { boolNot } from '../fn';
import i18next, { TFunction } from 'i18next';

const punycode = require('punycode/');

export const isValid = (obj: any) => !Object.values(obj).some((c) => !!c);

export const normalizeErrors = <T extends Partial<Record<any, any>>>(errors: T): T =>
  Object.values(errors).some(boolNot)
    ? (Object.fromEntries(Object.entries(errors).filter(([, v]) => v)) as T)
    : errors;

// t('validation:WrongEmailFormat')
export const emailValidator = (email: string = '', required: boolean = false) => {
  let err;
  if (required && (err = requiredValidator(email))) return err;

  const input = document.createElement('input');
  input.type = 'email';
  input.value = punycode.toASCII(email);

  return input.validity.valid ? undefined : 'validation:WrongEmailFormat';
};

// t('validation:WrongUrlFormat')
export const urlValidator = (value: string, pattern?: string): string | undefined => {
  const i = document.createElement('input');
  i.setAttribute('type', 'url');
  if (pattern !== undefined) {
    i.setAttribute('pattern', pattern);
  }
  i.value = value;
  return i.checkValidity() ? undefined : 'validation:WrongUrlFormat';
};
export const urlValidatorT = (
  t: TFunction,
  ...rest: Parameters<typeof urlValidator>
): string | undefined => {
  const k = urlValidator(...rest);
  return k && t(k);
};

// t('validation:Required')
export const requiredValidator = (val: any) => (!val ? 'validation:Required' : undefined);

// export const dateValidator = (date: string | undefined, required: boolean = false, notFeature: boolean = false) => {
//   let err;
//   if (required && (err = requiredValidator(date))) return err;
//
//   if (!date) return undefined;
//
//   const mDate = moment(date, DATE_LOCAL, true);
//
//   if (!mDate.isValid() || mDate.year() < 1900 || (notFeature && mDate.isAfter(moment(), 'day')))
//     return 'Некорректная дата';
//
//   return undefined;
// };

export const onlyDigit = (val: string) => val.replace(/[^\d]+/g, '');

// t('validation:WrongFormat')
export const lengthValidator = (
  val: string | undefined,
  length: number,
  required: boolean = false,
) => {
  if (!val && !required) return undefined;
  const str = onlyDigit(val || '');

  let err;
  if (required && (err = requiredValidator(str))) return err;

  if (str.length !== length) return 'validation:WrongFormat';

  return undefined;
};

export const passportValidator = (val: string | undefined, required: boolean = false) =>
  lengthValidator(val, 10, required);

export const minValidator = (
  val: string | undefined = '',
  length: number,
  required: boolean = false,
) => {
  let err;
  if (required && (err = requiredValidator(val.trim()))) return err;

  //todo
  if (val.length < length) return 'Минимальное количество символов: ' + length;

  return undefined;
};
export const maxValidator = (
  val: string | undefined = '',
  length: number,
  required: boolean = false,
) => {
  let err;
  if (required && (err = requiredValidator(val.trim()))) return err;

  //todo
  if (val?.length > length) return 'Максимальное количество символов: ' + length;

  return undefined;
};

// t('validation:ConfirmError')
export const confirmValidation = (val1?: string, val2?: string) => {
  return val1 === val2 ? undefined : 'validation:ConfirmError';
};

export const numberRangeValidator = (num: number | string, min: number, max: number) => {
  const _num = Number(num);
  if (isNaN(_num) || !isFinite(_num) || _num < min || _num > max)
    return i18next.t('validation:numberRange', { min, max });
  return undefined;
};
