import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { Input, Props as InputProps } from '../Input/Input';

export interface Props extends Omit<InputProps, 'value' | 'onChange'> {
  value?: number | null;
  onChange?: (value: number | null, e: ChangeEvent<HTMLInputElement>) => void;
  /** Допустимое количество символов после запятой, если указано */
  decimal?: number;
  /** Дополнительное преобразование в число, может панадобиться для нормального форматирования, например,
   *  когда с сервера приходит число в виде строки. */
  strictNumber?: boolean;
  /**
   * См. также `<IntegerInput/>`
   *
   * @todo Если `<IntegerInput/>` работает нормально, то можно будет избавиться
   *   это этого свойства, после чего порефакторить этот инпут на ввод только
   *   дробных чисел по тому же принципу.
   */
  isInteger?: boolean;
}

const toInputValue = (value: number | undefined | null) =>
  value === 0 || value ? String(value).replace('.', ',') : value === null ? '' : undefined;

/** @deprecated #110 IntegerInput или NumberInput2 */
const NumberInput: FC<Props> = ({
  value,
  isInteger = false,
  onChange,
  decimal,
  strictNumber,
  ...tail
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>();

  // REFACT: `useEffect()`?
  useMemo(() => {
    setInputValue(toInputValue(strictNumber ? Number(value) : value));
  }, [value, strictNumber]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue } = e.currentTarget;
      if (inputValue === '') {
        setInputValue(inputValue);
        onChange?.(null, e);
        return;
      }

      const replacedValue = inputValue.replace(',', '.');
      if (isInteger && replacedValue.includes('.')) return;
      const value = Number(replacedValue);

      if (!isNaN(value)) {
        if (decimal !== undefined && String(value).split('.')[1]?.length > decimal) return;

        setInputValue(inputValue);
        onChange?.(value, e);
      }
    },
    [isInteger, decimal, onChange],
  );

  return <Input {...tail} onChange={handleChange} value={inputValue} />;
};

export default NumberInput;
