import { createEffect, createEvent, createStore, forward, merge, sample } from 'effector';
import { createGate } from 'effector-react';
import { apiDeleteEvents, apiListEvents } from 'api/request/kontur/notifications';
import { EMPTY_ARRAY } from 'constants/utils';
import { showToast, ToastTypes } from 'ui/feedback';
import { $currentCompanyId } from '../company';
import { $userPreferences, updateUserPreferenceItem } from '../user-preferences';
import { KonturAccountEvent, KonturEventsLitParams } from './types';

const PREF_HIDE = 'front.konturNotifyHide';
export const $enabled = $userPreferences.map((map) => !map.get(PREF_HIDE));

export const updatePreference = createEvent<boolean>();
forward({
  from: updatePreference.map((enabled) => ({ name: PREF_HIDE, value: enabled ? '' : '1' })),
  to: updateUserPreferenceItem,
});

export const KonturEventsGate = createGate();
export const deleteAccountEvents = createEvent<any>();
export const receivedAccountEvent = createEvent<any>();

const fetchEventsFx = createEffect(
  async (params: KonturEventsLitParams) => (await apiListEvents(params)).data ?? EMPTY_ARRAY,
);
const deleteEventsFx = createEffect(apiDeleteEvents);

sample({
  clock: [KonturEventsGate.open, deleteEventsFx.done],
  source: $currentCompanyId,
  filter: Boolean,
  fn: (id) => ({ bookkeeper_team_id: id }),
  target: fetchEventsFx,
});

sample({
  clock: deleteAccountEvents,
  source: $currentCompanyId,
  filter: Boolean,
  fn: (id) => ({ bookkeeper_team_id: id }),
  target: deleteEventsFx,
});

export const $eventsLoading = fetchEventsFx.pending;
export const $events = createStore<readonly KonturAccountEvent[]>(EMPTY_ARRAY)
  .reset($currentCompanyId.updates, deleteEventsFx.done)
  .on(fetchEventsFx.doneData, (_, list) => list)
  .on(receivedAccountEvent, (list, add) => [add, ...list]);

export const $eventsCount = $events.map((list) => list.length);

merge([
  sample({
    source: fetchEventsFx.failData,
    $enabled,
  }),
  deleteEventsFx.failData,
]).watch((e) => showToast(ToastTypes.error, e.message));
