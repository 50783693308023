import { callModal } from 'components/integrations/phone/callModal/callModal';
import { createRequestEffect } from 'models/utils/createRquestEffect';
import { toMapReducer } from 'models/utils/toMapReducer';
import { uisApi } from 'api/request/uis';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { $currentCompanyIdOrNull } from 'models/company';
import { UISCallNumberForm, UISEmployeeId, UISTokenItem } from './types';
import { UserId } from 'models/user/types';
import { notification } from 'models/utils/messages';
import { ToastTypes } from 'ui/feedback';
import { ApiResponse2 } from 'api/types';
import { IntegrationCanCall } from '../types';

export const uisCanCall: IntegrationCanCall = (i) => Boolean(i.api_key);

/** Список токенов компании */
export type UISTokenMap = Map<UserId, UISTokenItem>;

export const getUISTokenList = createEvent();
export const getUISTokenListFx = createRequestEffect(uisApi.tokenList);
export const resetTokenList = createEvent();
export const $UISTokensMap = createStore<UISTokenMap>(new Map<UserId, UISTokenItem>()).reset(
  resetTokenList,
);
export const $UISUserTokensMap = createStore(new Map<UISEmployeeId, UISTokenItem>()).reset(
  resetTokenList,
);
/** @deprecated  Схему с дефолтными значениями и uncontrolled component пришлось изменить. Удалить в последующем */
export const $UISTokensDefaultMap = createStore<UISTokenMap>(new Map<UserId, UISTokenItem>()).reset(
  resetTokenList,
);

sample({
  clock: getUISTokenList,
  source: $currentCompanyIdOrNull,
  filter: Boolean,
  target: getUISTokenListFx,
});
const mapReducer = (_: UISTokenMap, { data }: ApiResponse2<UISTokenItem[]>): UISTokenMap =>
  new Map(data.map((c) => [c.user_id, c]));

$UISTokensMap.on(getUISTokenListFx.doneData, mapReducer);
$UISUserTokensMap.on(getUISTokenListFx.doneData, toMapReducer('uis_user_id'));
$UISTokensDefaultMap.on(getUISTokenListFx.doneData, mapReducer);

// /** call */
// export const callUISFx = createRequestEffect(uisApi.call);

/** call number */
export const callNumber = createEvent<string>();
const _callNumberUISFx = createRequestEffect(uisApi.callNumber);
sample({
  clock: callNumber,
  source: $currentCompanyIdOrNull,
  target: _callNumberUISFx,
  fn: (companyId, phone): UISCallNumberForm => ({
    bookkeeper_team_id: companyId!,
    phone,
  }),
});
sample({
  clock: _callNumberUISFx.done,
  target: createEffect(async ({ params: { phone } }: { params: UISCallNumberForm }) => {
    await callModal(phone);
  }),
});

/** save token */
export const saveUISTokenFx = createRequestEffect(uisApi.saveToken);
$UISTokensMap.on(
  saveUISTokenFx.done,
  (s, { params: { token, user_id, uis_user_id, bookkeeper_team_id } }) => {
    const old = s.get(user_id);
    return new Map(s).set(
      user_id,
      old
        ? { ...old, token, uis_user_id }
        : {
            bookkeeper_team_id,
            token,
            user_id,
            uis_user_id,
            created_at: '',
            updated_at: '',
          },
    );
  },
);

/** delete token */
export const deleteUISTokenFx = createRequestEffect(uisApi.deleteToken);
$UISTokensMap.on(deleteUISTokenFx.done, (s, { params: { user_id } }) => {
  s.delete(user_id);
  return new Map(s);
});

/** notifications */
notification({
  clock: saveUISTokenFx.done,
  mode: ToastTypes.success,
  // t('account:integrations.uis.messages.saveTokenSuccess')
  tKey: 'account:integrations.uis.messages.saveTokenSuccess',
});
notification({
  clock: saveUISTokenFx.fail,
  mode: ToastTypes.error,
  // t('account:integrations.uis.messages.saveTokenError')
  tKey: 'account:integrations.uis.messages.saveTokenError',
});
notification({
  clock: deleteUISTokenFx.done,
  mode: ToastTypes.success,
  // t('account:integrations.uis.messages.deleteTokenSuccess')
  tKey: 'account:integrations.uis.messages.deleteTokenSuccess',
});
notification({
  clock: deleteUISTokenFx.fail,
  mode: ToastTypes.error,
  // t('account:integrations.uis.messages.deleteTokenError')
  tKey: 'account:integrations.uis.messages.deleteTokenError',
});
// t('ui:integrations.messages.phoneSettingError')
notification({
  clock: _callNumberUISFx.fail,
  mode: ToastTypes.error,
  tKey: 'ui:integrations.messages.phoneSettingError',
});
