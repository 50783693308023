import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { KonturAccountEvent, KonturEventsLitParams } from 'models/kontur-notifications/types';

export const apiListEvents = (params: KonturEventsLitParams) =>
  dataOnly.get<null, ApiResponse2<readonly KonturAccountEvent[], true>>('/api3/kontur/events', {
    params,
  });

export const apiDeleteEvents = (params: KonturEventsLitParams) =>
  dataOnly.delete<null, void>('/api3/kontur/events', { params });
