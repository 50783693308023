import { FC, PropsWithChildren, ReactNode } from 'react';
import { Dialog, DialogProps } from '../Dialog';
import { reactModal } from '../reactModal';
import cl from './SuccessDialog.module.scss';
import { Button2 } from 'ui/button';
import imgIco from 'assets/img/success.svg';

interface Options extends PropsWithChildren<{}> {
  img?: string;
  icon?: ReactNode;
  title?: ReactNode | string;
  subtitle?: ReactNode | string;
  endHelp?: ReactNode;
  okBtnLabel?: ReactNode;
  noOk?: boolean;
}
interface Props
  extends Options,
    Omit<
      DialogProps,
      // BUG: кто догадался туда `any` сунуть?
      'children'
    > {
  onClickOk?: () => void;
}

export const SuccessDialog: FC<Props> = ({
  children,
  img,
  icon,
  title,
  subtitle,
  endHelp,
  okBtnLabel = 'Закрыть',
  noOk,
  onClose,
  onClickOk,
  ...tail
}) => {
  return (
    <Dialog {...tail} className={cl.root} onClose={onClose}>
      {icon ? (
        <div className={cl.icon}>{icon}</div>
      ) : (
        <img src={img || imgIco} alt="" className={cl.img} />
      )}
      <div className={cl.content}>
        {!!title && <div className={cl.title}>{title}</div>}
        {!!subtitle && <div className={cl.subtitle}>{subtitle}</div>}

        <div className={cl.children}>{children}</div>
        {noOk || (
          <Button2 size="largeX" onClick={onClickOk} className={cl.btn}>
            {okBtnLabel}
          </Button2>
        )}

        {endHelp && <div className={cl.endHelp}>{endHelp}</div>}
      </div>
    </Dialog>
  );
};

export const promptSuccessDialog = (o?: Options) =>
  reactModal(({ show, onSubmit, onDismiss }) => (
    <SuccessDialog visible={show} onClose={onDismiss} onClickOk={() => onSubmit()} {...o} />
  ));
