export interface MailSubjectPrefixOptions {
  useCounter?: boolean;
}

export const mailSubjectPrefixRe = (subject: string, o?: MailSubjectPrefixOptions) =>
  mailSubjectPrefix(subject, 'Re', o);

export const mailSubjectPrefixFwd = (subject: string, o?: MailSubjectPrefixOptions) =>
  mailSubjectPrefix(subject, 'Fwd', o);

const reCounter = /^\[(\d+)]/;

export const mailSubjectPrefix = (
  subject: string,
  prefix: String,
  { useCounter = false }: MailSubjectPrefixOptions = {},
) => {
  // имеем другой префикс?
  if (prefix.toUpperCase() !== subject.substring(0, prefix.length).toUpperCase()) {
    return prefix + ': ' + subject;
  }
  // имеем такой же префикс

  let result = prefix;
  // Re[7]: bla bla bla
  // Re: bla bla bla
  // --^^^^^^^^^^^^^^^^
  let rest = subject.substring(prefix.length);
  const m = rest.match(reCounter);
  if (m) {
    if (useCounter) {
      result += `[${Number(m[1]) + 1}]`;
    }
    // [7]: bla bla bla
    // ---^^^^^^^^^^^^^
    rest = rest.substring(m[0].length);
  } else if (useCounter) {
    result += '[2]';
  }

  if (rest.startsWith(':')) {
    rest = rest.substring(1).trimStart();
  }
  return result + ': ' + rest;
};
