import dateFns_cs from 'date-fns/locale/cs';
// import { ReactComponent } from 'svg-country-flags/svg/ru.svg';
import { ILanguage } from '../language';

const csLang: ILanguage = {
  language: 'cs',
  locale: 'cs-CZ',
  title: 'Čeština',
  dateFns: dateFns_cs,
  // flag: ReactComponent,
  parseNumber: {
    decimal: ',',
    group: /\s+/,
  },
};

export default csLang;
