import { createGate } from 'effector-react';
import { AbstractFilter } from './types';
import { createStore, sample } from 'effector';

export const AbstractFilterGate = createGate<AbstractFilter>();

export const $abstractFilter = createStore<AbstractFilter>({}).reset(AbstractFilterGate.close);

sample({
  clock: AbstractFilterGate.state,
  target: $abstractFilter,
});
