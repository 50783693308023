import { ChangeEvent, FC, ReactNode, useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { Input, InputProps } from 'ui/input/Input';
import { Field } from 'ui/input/Field';

import { Prompt } from './Prompt';
import { reactModal, RenderPromptProps } from '../reactModal';

export interface PromptStringOptions
  extends Omit<InputProps, 'title' | 'value' | 'onChange' | 'disabled' | 'readOnly' | 'onSubmit'> {
  /** Заголовок диалога. По умолчанию: нет никакого. */
  title?: ReactNode;
  /** Метка над полем ввода. По умолчанию: нет никакой. */
  label?: ReactNode;
  okText?: ReactNode;

  /** Значение в поле по умолчанию. */
  defaultValue?: string;
  /** Надо ли делать `trim()` перед валидацией. Пол умолчанию: `true`. */
  trim?: boolean;
  /** Дополнительное преобразование после `trim()` перед валидацией. Например, конвертация регистра. */
  filter?: (input: string) => string;
  /** Альтернативная проверка пустоты после фильтрации. Используется только при `required`. */
  isEmpty?: (value: string) => boolean;
  /** Дополнительная валидация после `required`. */
  validate?: (value: string) => { error: ReactNode } | null;
  //validateEveryChange?:boolean=false; // и [OK] блокировать тогда можно заодно
  //textarea?:boolean=false;
}

interface Props extends PromptStringOptions, RenderPromptProps<string> {}

const isEmptyString = (s: string) => !s.length;

export const PromptString: FC<Props> = ({
  title,
  label,
  okText,

  defaultValue = '',
  trim = true,
  filter,
  isEmpty = isEmptyString,
  validate,

  show,
  onSubmit,
  onDismiss,

  children,
  ...rest
}) => {
  const { required = false } = rest;

  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState<ReactNode>();
  const handleInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setValue(value),
    [],
  );

  const realFilter = useCallback(
    (value: string) => {
      if (trim) {
        value = value.trim();
      }
      if (filter) {
        value = filter(value);
      }
      return value;
    },
    [trim, filter],
  );

  const handleOk = useCallback(() => {
    const actualValue = realFilter(value);
    setValue(actualValue);
    if (required && isEmpty(actualValue)) {
      setError(<Trans i18nKey="validation:Required" />);
      return;
    }
    const err = validate?.(actualValue);
    if (err) {
      setError(err.error ?? <Trans i18nKey="validation:InvalidValue" />);
      return;
    }
    setError(undefined);
    onSubmit(actualValue);
  }, [value, realFilter, required, isEmpty, validate, onSubmit]);

  return (
    <Prompt
      open={show}
      onOk={handleOk}
      onClose={onDismiss}
      title={title}
      okText={okText}
      autoFocus
      wrapForm
    >
      <Field label={label} error={error}>
        <Input {...rest} value={value} onChange={handleInputChange} />
      </Field>
    </Prompt>
  );
};

export const promptString = (options: PromptStringOptions) =>
  reactModal<string>((props) => {
    return <PromptString {...options} {...props} />;
  });
