import { IdNumberOf, WritablePart } from 'utils/types';
import { CustomerId } from '../customer/types';
import { UserId } from '../user/types';
import { TagId } from '../tags/types';

export type RoleId = IdNumberOf<'Role'>;

export interface Role {
  readonly id: RoleId;
  title: string;
}

type RoleFormBase = WritablePart<Role>;
export type RoleCreateForm = RoleFormBase;
export type RoleUpdateForm = RoleFormBase;

export interface WithRoleId {
  id: RoleId;
}

export interface WithCustomerId {
  id: CustomerId;
}

// --------------------

export const enum RolesAssignGroupBy {
  user = 'user',
  role = 'role',
}

export interface AssignGetParams {
  group_by: RolesAssignGroupBy;
}

type _AssignData<G extends keyof any, I> = { readonly [p in G]: readonly I[] };
export const toAssignMap = <K extends string, V>(data: { readonly [p in K]: V }): ReadonlyMap<
  K,
  V
> => new Map(Object.entries(data) as any);

export type AssignUserRoles = _AssignData<UserId, RoleId>;
export type AssignRoleUsers = _AssignData<RoleId, UserId>;

export interface AssignUserRolesUpdate {
  _targetUserId: UserId;
  roles: readonly RoleId[];
}

export interface RolesStateSet {
  state: boolean;
}

export interface RolesState extends RolesStateSet {
  isProcessing: boolean;
}

export type RoleUsers = Record<RoleId, UserId[] | null>;

export interface AssignUserToTasks {
  assignManual?: boolean;
  assignTypical?: boolean;
}

export interface RoleUsersUpdates
  extends Record<
    RoleId,
    Array<
      {
        user: UserId;
      } & AssignUserToTasks
    >
  > {}

export interface UsedRoleCustomersParams {
  _forUserId: UserId;
  role_id: RoleId;
}
export interface UsedRoleCustomer {
  team_id: CustomerId;
  displayname: string;
}

// ============================================================
// глобальные настройки: какому тегу (tax handbook)
// соответствует какая роль

export interface RoleTagGlobalAssignment {
  TagID: TagId;
  Roles: readonly RoleId[] | null;
  IsDisabled: boolean;
}

export interface RoleTagCustomerAssignment extends RoleTagGlobalAssignment {
  /**
   * 0..365
   *
   * #116
   */
  DateCalendarDeltaDays: number;
  IsExcluded: boolean;
  // по идее не нужно: // TeamId: CustomerId;
  Users: readonly UserId[];
}

// Dmitry Morozov:
// > в патчах, если не указать, то это поле не изменится
// > если надо обнулить, указываешь нулл

export interface RoleTagGlobalUpdate {
  TagID: TagId;
  Roles?: readonly RoleId[] | null;
  IsDisabled?: boolean;
}

export interface RoleTagCustomerUpdate extends RoleTagGlobalUpdate {
  /**
   * Заполняет поле date_calendar у задач, вычитая указанное кол-во дней из date_end, учитывая праздники
   *
   * 0..365
   *
   * #116
   */
  DateCalendarDeltaDays?: number;
  Users?: readonly UserId[];
}

export interface RoleTagBulkUpdate {
  TagID: TagId;
  Roles?: readonly RoleId[] | null;
  IsDisabled?: boolean;
  CustomerTeams?: readonly CustomerId[];
  Users?: readonly UserId[];
}

export interface WithTeamId {
  _teamId: CustomerId;
}
