import { createEffect, createEvent, createStore, sample } from 'effector';
import i18next from 'i18next';
import { apiTaskTemplateList } from 'api/request/task-template';
import { WithBookkeeper } from 'api/types/request';
import { EMPTY_ARRAY } from 'constants/utils';
import { showToast, ToastTypes } from 'ui/feedback';
import { $currentCompanyId } from '../company';
import { withBookkeeperFx } from '../utils/withBookkeeperFx';
import { TaskTemplate, TaskTemplateId, TaskTemplatesListMode } from './types';

export const applyTemplate = createEvent<TaskTemplateId>();
export const refreshTaskTemplates = createEvent<any>();

const fetchTaskTemplatesFx = withBookkeeperFx(
  createEffect((p: WithBookkeeper & {}) =>
    apiTaskTemplateList({ ...p, mode: TaskTemplatesListMode.USER }),
  ),
);
sample({
  source: refreshTaskTemplates,
  target: fetchTaskTemplatesFx,
});
fetchTaskTemplatesFx.failData.watch((e) =>
  showToast(
    ToastTypes.error,
    i18next.t('task:template.messages.CannotLoadTemplates') + '\n' + e.message,
  ),
);
export const $taskTemplates = createStore<readonly TaskTemplate[]>(EMPTY_ARRAY)
  .reset($currentCompanyId)
  .on(fetchTaskTemplatesFx.doneData, (_, { data }) => data);

export const applyValidTemplate = sample({
  source: sample({
    clock: applyTemplate,
    source: $taskTemplates,
    fn: (list, id) => list.find((o) => o.id === id) ?? null,
  }),
  filter: Boolean,
});
