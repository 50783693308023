import { FC, MouseEventHandler, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import cl from './Popover2.module.scss';
import { Panel } from 'ui/panels';
import ClickAwayListener from 'ui/utils/ClickAwayListener';
import cn from 'classnames';
import { Placement } from '@popperjs/core/lib/enums';
import * as PopperJS from '@popperjs/core';
import { createPortal } from 'react-dom';
import { OffsetsFunction } from '@popperjs/core/lib/modifiers/offset';
import { Modifier } from '@popperjs/core/lib/types';

export interface PopoverProps {
  anchorEl?: HTMLElement | null;
  isShow?: boolean;
  className?: string;
  wrapperClassName?: string;
  placement?: Placement;
  offset?: OffsetsFunction | [number?, number?];
  /** В случае false можно кликнуть по стороннему элементу при открытом попапе, также работает скрол*/
  withBackdrop?: boolean;
  withPortal?: boolean;
  withArrow?: boolean;
  flip?: boolean | readonly Placement[];
  /** Использовать про `div` с указанным здесь же классом вместо `Panel` с предопределённым оформлением */
  rawPopup?: boolean;
  onClickOutside: () => void;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
}

export const Popover2: FC<PopoverProps> = ({
  anchorEl,
  isShow,
  children,
  onClickOutside,
  className,
  withBackdrop = true,
  wrapperClassName,
  placement,
  withPortal,
  withArrow = false,
  flip,
  rawPopup = false,
  offset,
  onMouseLeave,
}) => {
  // const popperRef = useRef(null);
  // const arrowRef = useRef(null);
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowEl] = useState<HTMLDivElement | null>(null);

  const modifiers = useMemo<Array<Partial<Modifier<any, any>>>>(() => {
    const modifiers: Array<Partial<Modifier<any, any>>> = [
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
          // boundary: document.body,
        },
      },
    ];
    if (offset)
      modifiers.push({
        name: 'offset',
        options: {
          offset,
        },
      });
    if (withArrow) {
      modifiers.push({ name: 'arrow', options: { element: arrowElement } });
    }
    if (flip) {
      modifiers.push({
        name: 'flip',
        options: {
          ...(flip === true ? null : { fallbackPlacements: flip }),
        },
      });
    }
    return modifiers;
  }, [offset, withArrow, flip, arrowElement]);

  const options = useMemo<Partial<PopperJS.Options>>(
    () => ({
      placement,
      modifiers,
    }),
    [modifiers, placement],
  );

  // const { styles, attributes } = usePopper(anchorEl, popperRef.current, options);
  const { styles, attributes } = usePopper(
    // Получается, нет смысла что-либо вычислять, когда он скрыт.
    // А то я вывел на странице их кучу, и страница начала лагать при скролле.
    isShow ? anchorEl : null,
    popperEl,
    options,
  );
  // Ага! Но потом я добавил условие в `usePopper()`, и данная проблема перестала
  // быть актуальной. `update` возвращается из `usePopper()`
  // Отсюда ниже разве что ссылки на issue могут быть полезны,
  // если вдруг кто-то будет делать тут рефакторинг логики.
  // useEffect(() => {
  //   // Без этой штуки итог - кривость.
  //   // На new.cubux.net я делал `{isShow && <div ref={setPopperEl}>...</div>}`,
  //   // чтобы обойти баги:
  //   // - https://github.com/popperjs/popper-core/issues/1219
  //   // - https://github.com/popperjs/popper-core/issues/413
  //   // Так, при каждом появлении он заново инициировался/позиционировался.
  //   //
  //   // А тут `opacity` вместо перемонтирования. Значит, надо делать `update()`,
  //   // когда он появляется, иначе он появляется хз где.
  //   if (isShow && update) {
  //     setTimeout(update, 10);
  //   }
  // }, [update, isShow]);

  const popover = (
    <>
      {isShow && withBackdrop && <div className={cl.backdrop} />}
      <div
        // ref={popperRef}
        ref={setPopperEl}
        style={styles.popper}
        {...attributes.popper}
        className={cn(cl.wrapper, wrapperClassName, isShow && cl.isShow)}
      >
        {isShow && (
          <ClickAwayListener onClickAway={onClickOutside}>
            {((content) =>
              rawPopup ? (
                <div className={className} onMouseLeave={onMouseLeave}>
                  {content}
                </div>
              ) : (
                //Надо остановить всплытие, иначе бывают проблемы. Например когда попуп открывается в списке, элементы которого кликабельны.
                //Клик по попупу передается элементу списка.
                <Panel
                  className={cn(cl.root, className)}
                  onClick={(e) => e.stopPropagation()}
                  onMouseLeave={onMouseLeave}
                >
                  {content}
                </Panel>
              ))(
              <>
                {children}
                {withArrow && <div className={cl.arrow} style={styles.arrow} ref={setArrowEl} />}
              </>,
            )}
          </ClickAwayListener>
        )}
      </div>
    </>
  );

  // Но в итоге до меня дошло, наконец, что это и не нужно, т.к. контекст и так
  // передаётся сквозь порталы. Не знаю, чего я так затупил.
  //
  // // ButtonDropdown со вложенными меню криво позиционирует вложенный Popover,
  // //   потому что он позиционируется внутри границ родительского Popover. Как-то надо
  // //   дать ему понять, что место монтирования и viewport - это две разные вещи.
  // //   Потом разберусь, наверно.
  // //   [UPD: 2024-02-12]
  // //   Пробовал разные опции в модификатор 'preventOverflow' - ничего не помогло.
  // // const getContainer = usePopupContainer();
  // // return withPortal ? createPortal(popover, getContainer()) : popover;

  return withPortal ? createPortal(popover, window.document.body) : popover;
};
