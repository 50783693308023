export const getFileExtension = (name: string, withDot = false): string => {
  // ".name.tag.gz"
  //  ---------^
  let lastDotAt = name.lastIndexOf('.');
  // "no-extension" или ".no-extension"
  if (lastDotAt <= 0) return '';
  // "no-extension."
  if (lastDotAt === name.length - 1) return '';

  // ".name.tag.gz"
  //  ---------^^^   withDot
  //  ----------^^  !withDot
  return name.substring(withDot ? lastDotAt : lastDotAt + 1);
};

export default getFileExtension;
