import { dataOnly } from 'api/instance';
import { API_TASK_REMOTE, ApiResponse2 } from 'api/types';
import { WithBookkeeper } from 'api/types/request';
import { CustomerId } from 'models/customer/types';
import {
  WaGreenGroupAccessUsers,
  WaGreenGroupAccessUsersSelf,
  WaGreenGroupForUser,
  WaGreenMessage,
  WaGreenMessageId,
  WaGreenMessagePeek,
  WaGreenMessageSendForm,
  WithWaGreenGroupId,
} from 'models/wagreen/types';

export interface WaGreenGroupListUserParams {
  /** Опционально фильтрация по клиенту, к которому чаты были привязаны в настройках */
  for_customer?: CustomerId;
  /** Полный список для привелегированного пользователя для Рассылок */
  full_list?: boolean;
}
export interface WaGreenGroupLatestMessagesParams extends WaGreenGroupListUserParams {
  after_id?: WaGreenMessageId;
}

export const apiChatList = ({
  bookkeeper_team_id: team_id,
  ...params
}: WithBookkeeper & WaGreenGroupListUserParams) =>
  dataOnly.get<null, ApiResponse2<readonly WaGreenGroupForUser[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/chat`,
    { params },
  );

export const apiChatLatestMessages = ({
  bookkeeper_team_id: team_id,
  ...params
}: WithBookkeeper & WaGreenGroupLatestMessagesParams) =>
  dataOnly.get<null, ApiResponse2<readonly WaGreenMessagePeek[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/chat/latest-messages`,
    { params },
  );

export const apiChatMarkUnseen = ({
  bookkeeper_team_id: team_id,
  group_id,
  ...params
}: WithBookkeeper & WithWaGreenGroupId) =>
  dataOnly.post<null, void>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/chat/${group_id}/unseen`,
    params,
  );

// -----------------------------------------------------

export interface WaGreenMessagesListUserParams {
  per_page?: number;
  near_id?: WaGreenMessageId;
  after_id?: WaGreenMessageId;
  before_id?: WaGreenMessageId;
}
export interface WaGreenMessagesPickParams {
  id: readonly WaGreenMessageId[];
}
export interface WaGreenMessagesSeenParams {
  id: WaGreenMessageId;
}

export const apiMessagesList = ({
  bookkeeper_team_id: team_id,
  group_id,
  ...params
}: WithBookkeeper & WithWaGreenGroupId & WaGreenMessagesListUserParams) =>
  dataOnly.get<null, ApiResponse2<readonly WaGreenMessage[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/chat/${group_id}/messages/v2`,
    { params },
  );

export const apiMessagesPick = ({
  bookkeeper_team_id: team_id,
  group_id,
  ...params
}: WithBookkeeper & WithWaGreenGroupId & WaGreenMessagesPickParams) =>
  dataOnly.get<null, ApiResponse2<readonly WaGreenMessage[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/chat/${group_id}/messages/pick/v2`,
    { params },
  );

export const apiMessagesMarkSeen = ({
  bookkeeper_team_id: team_id,
  group_id,
  ...form
}: WithBookkeeper & WithWaGreenGroupId & WaGreenMessagesSeenParams) =>
  dataOnly.put<null, void>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/chat/${group_id}/seen`,
    form,
  );

export const apiMessageSend = ({
  bookkeeper_team_id: team_id,
  group_id,
  attachment,
  ...form
}: WithBookkeeper & WithWaGreenGroupId & WaGreenMessageSendForm) => {
  const fd = new FormData();
  for (const [name, value] of Object.entries(form)) {
    if (value === undefined) {
      continue;
    }
    switch (typeof value) {
      case 'string':
      case 'number':
      case 'boolean':
        fd.append(name, String(value));
        break;
      default:
        if (process.env.NODE_ENV === 'development') {
          console.error(`Unhandled data type in field`, { name }, '=', { value });
          throw new TypeError(`Unhandled data type in field "${name}"`);
        }
    }
  }
  if (attachment) {
    fd.append('attachment', attachment, attachment.name);
  }

  return dataOnly.post<null, ApiResponse2<void>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/chat/${group_id}/messages`,
    fd,
  );
};

// -----------------------------------------------------

export const apiChatUsersGet = ({
  bookkeeper_team_id: team_id,
  group_id,
  ...params
}: WithBookkeeper & WithWaGreenGroupId) =>
  dataOnly.get<null, ApiResponse2<WaGreenGroupAccessUsers>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/chat/${group_id}/users`,
    { params },
  );

export const apiChatUsersUpdate = ({
  bookkeeper_team_id: team_id,
  group_id,
  ...form
}: WithBookkeeper & WithWaGreenGroupId & WaGreenGroupAccessUsersSelf) =>
  dataOnly.patch<null, ApiResponse2<WaGreenGroupAccessUsers>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/chat/${group_id}/users`,
    form,
  );
