import { FC } from 'react';
import { Redirect, useLocation } from 'react-router';

const LoginRedirect: FC = () => {
  const { pathname } = useLocation();
  const redirect = pathname !== '/' ? `?redirect=${pathname}` : '';

  return <Redirect to={`/login${redirect}`} />;
};

export default LoginRedirect;
