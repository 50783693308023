import { FC } from 'react';
import { createComponent, useList } from 'effector-react';
import { $checkboxesTaxbook, $listsTaxbook } from 'models/customer';
import cl from './TaxbookPanel.module.scss';
import { Field, LabeledCheckbox, Select } from 'ui/input';
import { Col, Row } from 'ui/grid';
import { $form, handleChange, setFieldSelect } from './model';
import { TaxbookItem, TaxbookItemId } from 'models/customer/types';
import cn from 'classnames';

interface Props {
  compactMode?: boolean;
}

const CheckboxProperty = createComponent(
  $form,
  ({ id, label }: { id: TaxbookItemId; label: string }, form) => (
    <LabeledCheckbox
      name={String(id)}
      label={label}
      onChange={handleChange}
      checked={form.has(id)}
      className={cl.checkbox}
    />
  ),
);

const SelectProperty = createComponent(
  $form,
  ({ taxboxItem, compactMode }: { taxboxItem: TaxbookItem; compactMode?: boolean }, form) => {
    const { id, variant } = taxboxItem;
    const options = variant!.map((c) => ({ label: c.value, value: c.id }));
    return (
      <Select
        options={options}
        isMulti
        onChange={(value) => setFieldSelect({ id: id, value })}
        size={compactMode ? 'small' : undefined}
        value={
          form
            .get(id)
            ?.map((c) => ({ label: options.find(({ value }) => value === c)!.label, value: c })) ??
          null
        }
      />
    );
  },
);

export const TaxbookPanel: FC<Props> = ({ compactMode = false }) => {
  const listsTaxbook = useList($listsTaxbook, (c) => (
    <Field label={c.name} className={cl.select} withMargin={false}>
      <SelectProperty taxboxItem={c} compactMode={compactMode} />
    </Field>
  ));

  const checkboxesTaxbook = useList($checkboxesTaxbook, (c) => (
    <CheckboxProperty id={c.id} label={c.name} />
  ));

  return (
    <Row gutter className={cn(compactMode && cl.compactMode)}>
      <Col span={compactMode ? 12 : 5}>{listsTaxbook}</Col>
      <Col className={cl.checkboxesCol}>{checkboxesTaxbook}</Col>
    </Row>
  );
};
