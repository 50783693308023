import { forwardRef } from 'react';
import { ButtonCoreProps, buttonCoreRender } from '../core';
import { ButtonSize, UiColor, UiColors } from '../types';
import cl from './TextButton2.module.scss';

const COLORS_CLS: Partial<Record<UiColor, string>> = {
  [UiColors.default]: cl._default,
  [UiColors.primary]: cl._primary,
  [UiColors.danger]: cl._danger,
  [UiColors.success]: cl._success,
  [UiColors.accent]: cl._accent,
  [UiColors.secondary]: cl._secondary,
};
const SIZES_CLS: Partial<Record<ButtonSize, string>> = {
  default: cl._sizeDef,
  large: cl._sizeLarge,
  largeX: cl._sizeLargeX,
  small: cl._sizeSmall,
};

export const TextButton2 = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonCoreProps>(
  (props, ref) =>
    buttonCoreRender(props, ref, {
      colors: COLORS_CLS,
      sizes: SIZES_CLS,
      rootClassName: cl.root,
      withIconClassName: cl.withIcon,
      // TODO: когда disabled с кастомной иконкой, возникает неопределённость:
      //   - здесь обобщённо мы не можем сказать, используется ли иконка currentColor,
      //     поэтому не можем знать, копрасилась ли она в disable цвет
      //   - в то же время не можем сделать всем иконкам opacity, т.к. испортятся
      //     иконки, которые используют currentColor
    }),
);

if (process.env.NODE_ENV === 'development') {
  TextButton2.displayName = 'TextButton2';
}
