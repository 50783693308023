import cn from 'classnames';
import { FC } from 'react';
import { Loader } from 'ui/feedback';
import cl from './LoadingPage.module.scss';

interface Props {
  fullScreen?: boolean;
  cover?: boolean;
  compact?: boolean;
  include?: boolean;
  relCover?: boolean;
  relTop?: boolean;
  tint?: boolean;
  className?: string;
}

const LoadingPage: FC<Props> = ({
  fullScreen = false,
  className,
  cover = false,
  compact = false,
  include = false,
  relCover = false,
  relTop = false,
  tint = false,
}) => (
  <div
    className={cn(
      cl.root,
      className,
      fullScreen && cl.fullScreen,
      cover && cl.cover,
      compact && cl.compact,
      include && cl.include,
      relCover && cl.relCover,
      relTop && cl.relTop,
      tint && cl.tint,
    )}
  >
    <Loader />
  </div>
);

export default LoadingPage;
