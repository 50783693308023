import { createController, createRequestFx } from 'fry-fx';
import { createEffect, createEvent, Effect } from 'effector';
import { RequestEffect } from 'fry-fx/dist/types';

type Api<Params, Done> = (params: Params, signal?: AbortSignal) => Promise<Done>;
type ApiCancelable<Params, Done> = (params: Params, signal?: AbortSignal) => Promise<Done>;

export function createRequestEffect<Params, Done, Error, Cancel extends boolean = false>(
  api: Api<Params, Done>,
  cancelable?: Cancel,
): Cancel extends false ? Effect<Params, Done, Error> : RequestEffect<Params, Done, Error> {
  return cancelable
    ? createRequestFx<Params, Done, Error>(
        async (params: Params, controller) => await api(params, controller?.getSignal()),
      )
    : createEffect<Params, Done, Error>(async (params: Params) => await api(params));
}

export function createRequestEffectWithCancel<Params, Done, E = Error>(
  api: ApiCancelable<Params, Done>,
  /** Если false, то будет отменятся только вручную */
  cancelWithNewRequest: boolean = true,
) {
  if (cancelWithNewRequest) {
    const cancel = createEvent<any>();
    return {
      cancel,
      request: createRequestFx<Params, Done, E>({
        cancel,
        handler: (params, controller) =>
          new Promise<Done>((resolve, reject) => {
            controller?.onCancel(() => reject(new Error('Request cancelled')));
            resolve(api(params, controller?.getSignal()));
          }),
      }),
    };
  } else {
    const controller = createController();
    return {
      cancel: controller.cancel,
      request: createEffect<Params, Done, Error>(
        async (params: Params) => await api(params, controller.getSignal()),
      ),
    };
  }
}
