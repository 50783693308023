import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { COMPANY } from 'constants/config';
import { Button2 } from 'ui/button';
import cl from './ErrorPage.module.scss';

interface Props {}

const handleReload = () => {
  window.location.reload();
};

export const ErrorPage: FC<Props> = () => {
  const { t } = useTranslation();
  const handleSupport = useCallback(() => {
    window.open(t('ui:errorApi.supportBtn.href'), '_blank');
  }, [t]);

  return (
    <div className={cl.root}>
      <img src="/favicon120.png" className={cl.logo} alt={COMPANY} />
      <div className={cl.title}>{t('ui:errorApi.serviceUnavailable')}</div>
      <div className={cl.descr}>{t('ui:errorApi.tryAfter')}</div>
      <Button2 className={cl.btn} size="largeX" onClick={handleReload}>
        {t('ui:errorApi.reload')}
      </Button2>
      <Button2 className={cl.btn} size="largeX" onClick={handleSupport}>
        {t('ui:errorApi.supportBtn.label')}
      </Button2>
    </div>
  );
};
