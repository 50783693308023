import { isBefore, startOfDay } from 'date-fns';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  Event,
  EventPayload,
  merge,
  restore,
  sample,
  StoreValue,
} from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import isequal from 'lodash.isequal';
import { not, or, pending } from 'patronum';
import { ChangeEvent, MouseEvent } from 'react';
import apiTask, { TaskTypicalDeleteParams } from 'api/request/task';
import { TaskRepeatableEditParams } from 'api/request/tasks-repeatable';
import {
  customersSelectedId,
  promptCustomerMultiSelectModal,
} from 'components/customer/CustomerMultiSelectModal';
import {
  $selectedUsers,
  addUsers,
  replaceUsers,
  SelectEmployeeParams,
} from 'components/employee/SelectEmployee/model';
import { EMPTY_ARRAY } from 'constants/utils';
import { getDateFormat } from 'hooks/i18n';
import {
  $customersChannelsItems,
  fetchTaskChannelFx,
  fetchTaskTypicalChannelFx,
} from 'models/channel';
import { ChannelId, TaskChannelExt } from 'models/channel/types';
import { $currentCompanyId, $currentCompanyIdOrNull } from 'models/company';
import { CompanyId } from 'models/company/types';
import { Customer, CustomerId } from 'models/customer/types';
import { FileStorage } from 'models/filestorage/types';
import { fileshareFromFileStorage } from 'models/filestorage/v2/fn';
import { FileStorageService } from 'models/filestorage/v2/types';
import { createUploadingList } from 'models/filestorage/v2/uploading-list';
import { removeByChannelId, removeByTaskId } from 'models/notices';
import { taskChatData } from 'models/post';
import { Post } from 'models/post/types';
import { $tagsLocal, getTagsLocal, TAG_CALLBACKS, updateTagFx } from 'models/tags';
import { Tag, TagId } from 'models/tags/types';
import {
  taskChecklistDidChange,
  taskCreateFx,
  taskPatchFx,
  taskTypicalUpdateFx,
  taskUniversalDeleteFx,
} from 'models/task';
import { reducerTaskTimerToggled } from 'models/task/timerHelpers';
import {
  fromApiToTask,
  Task,
  TaskCreateForm,
  TaskIconType,
  TaskId,
  TaskPatchParams,
  TaskPriority,
  taskTextFromApi,
  TaskTypicalUpdateParams,
} from 'models/task/types';
import {
  $checklist,
  createTaskCheckListFx,
  deleteTaskCheckListFx,
  getTaskCheckList,
  getTaskCheckListFx,
  resetCheckList,
  updateSortingTaskCheckList,
} from 'models/task-checklist';
import {
  $taskChecklistItems,
  createTaskChecklistItem,
  createTaskChecklistItemFx,
  deleteTaskChecklistItem,
  deleteTaskChecklistItemFx,
  sortTaskChecklistItemFx,
  toggleTaskChecklistItem,
  updateTaskChecklistItem,
  updateTaskChecklistItemFx,
} from 'models/task-checklist-item';
import { TaskChecklistItem, TaskChecklistItemId } from 'models/task-checklist-item/types';
import { isFinanceDataFormEditChanged, toSubmitFinanceForm } from 'models/task-finance/form';
import {
  $financeForm,
  $financeReplaceForm,
  saveTaskFinance,
  validateFinanceFormFx,
} from 'models/task-finance/task-tab';
import { tasksRepeatableDeleteFx, tasksRepeatableUpdateFx } from 'models/tasks-repeatable';
import { TaskTemplateId } from 'models/task-template/types';
import { applyValidTemplate, refreshTaskTemplates } from 'models/task-template/user';
import { taskTimerDidToggle, toggleActiveFx } from 'models/timers';
import { $user, $userId } from 'models/user';
import { UserId } from 'models/user/types';
import { changeWatcher } from 'models/utils/changeWatcher';
import { promptFn, showToast, ToastTypes } from 'ui/feedback';
import { parseDate, parseDateStrict, toISODateString } from 'utils/date';
import { fnVoid } from 'utils/fn';
import { getUniqKeyRnd } from 'utils/helpers';
import { FormErrors } from 'utils/types/form';
import { isValid, requiredValidator } from 'utils/validation';
import {
  $checkListItems,
  $checkListItems as $checkListItemsLocal,
  checkListToggled,
  resetCheckListItems,
  TaskChecklistItemSimpleWithId,
} from './modelCheckList';
import {
  changeWatcherRepeatForm,
  convertTaskRepeatFormToRequest,
  form as taskRepeatForm,
} from './taskRepeatModel';
import { htmlToPlainText } from 'utils/string';
import { DateISO } from 'utils/types';
import { ForwardType, HTMLElementWithMeta } from './modelRepeatPrompt';
import { $abstractFilter } from 'models/abstractFilter';
import { $customerTagsMap } from 'models/customers-tags';
import apiCustomers from 'api/request/customer';

// export const SE_KEY = 'TaskEditModal' ;
// export const SE_REVIEWER_KEY = 'TaskEditModal_reviewer';

export enum EmployeeSelectKey {
  assigned = 'TaskEditModalAssigned',
  reviewer = 'TaskEditModalReviewer',
}

export const TaskEditModalGate = createGate();
export const taskFilesApi = createUploadingList({
  allowDeleteFreeFile: true,
  service: FileStorageService.tasks,
});

interface Form {
  customerIds: CustomerId[] | null;
  text: string;
  date_end: string;
  date_calendar: DateISO;
  priority: TaskPriority;
  dateStatusUpdated: string;
  isTaskClosed: boolean;
  isInternal: boolean;
  isReadOnly: boolean;
  need_reviewers_notice: boolean;
  readonlyAuthor?: UserId;
  type_icon_custom: TaskIconType;
  //Поле не представлено на форме. Костыль чтобы "назначать" файлы при создании задачи на основе чего-то (например из письма)
  externalFiles?: FileStorage[];
  // https://gitlab.com/finkoper_front/slack-accounting-web/-/issues/163
  // только при создании
  // да шаблоны вообще только при создании
  template_id?: TaskTemplateId | null;

  // https://gitlab.com/finkoper_front/slack-accounting-web/-/issues/70
  _extraOptions?: Partial<TaskCreateForm>;
}

interface Errors extends FormErrors<Form> {
  selectedUsers?: string;
}

export const changeEmployeeTab = createEvent<EmployeeSelectKey>();
export const $employeeTab = restore(changeEmployeeTab, EmployeeSelectKey.assigned).reset(
  TaskEditModalGate.close,
);

const initForm = (): Form => {
  const { customerId } = $abstractFilter.getState();
  const now = toISODateString(new Date());
  return {
    // customerId: null,
    customerIds: customerId ? [customerId] : EMPTY_ARRAY,
    text: '',
    date_end: now,
    date_calendar: now,
    priority: 'mid',
    dateStatusUpdated: '',
    isTaskClosed: false,
    isInternal: false,
    isReadOnly: false,
    need_reviewers_notice: true,
    type_icon_custom: TaskIconType.default,
  };
};

const fetchCurrentCustomerFx = createEffect(apiCustomers.show);

const validateAllFormsFx = createEffect<{ form: Form; isValidRepeatForm: boolean }, null>();

export const unmount = createEvent();
export const setCurrentTask = createEvent<Task | null>('setCurrentTask');
export const setCurrentTaskById = createEvent<{
  task_id: TaskId;
  is_typical: boolean;
  team_id: CustomerId | null;
}>();
export const $currentTask = createStore<Task | null | false>(false);
export const $isNewTask = $currentTask.map((c) => !c);
export const $showModal = $currentTask.map((c) => c !== false);

$currentTask.on(setCurrentTask, (_, task) => task);
$currentTask.on(taskTimerDidToggle, reducerTaskTimerToggled);

export const toggleDateEndEqualCalendar = createEvent<boolean>();
export const $isDateEndEqualCalendar = createStore<boolean>(false);

const fetchTaskByIdFx = createEffect(
  async ({ is_typical, team_id, ...params }: { is_typical: boolean } & TaskTypicalDeleteParams) =>
    (is_typical ? await apiTask.getTypical({ team_id, ...params }) : await apiTask.get(params))
      .data,
);
sample({
  clock: setCurrentTaskById,
  source: $currentCompanyIdOrNull,
  fn: (companyId, ref) => ({
    bookkeeper_team_id: companyId!,
    ...ref,
  }),
  target: fetchTaskByIdFx,
});
sample({ clock: fetchTaskByIdFx.doneData, fn: fromApiToTask, target: setCurrentTask });
fetchTaskByIdFx.failData.watch((e) => showToast(ToastTypes.error, e.message));

sample({
  source: $showModal.updates,
  filter: Boolean,
  target: refreshTaskTemplates,
});

export const createTask = createEvent<Partial<Form>>();
sample({ clock: createTask, fn: () => null, target: setCurrentTask });

export const multiCustomerClicked = createEvent<any>();
export const setCustomers = createEvent<CustomerId[] | null>();
export const setField = createEvent<ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>();
export const setTaskTypeIcon = createEvent<TaskIconType>();
export const setPriority = createEvent<TaskPriority>();
export const toggleTaskClosed = createEvent<ChangeEvent<HTMLInputElement>>();
export const isInternalClicked = createEvent<unknown>();
export const isReadOnlyClicked = createEvent<unknown>();
export const textChanged = createEvent<string>();
export const needReviewersNoticeClicked = createEvent<unknown>();
export const $form = createStore<Form>(initForm(), { name: 'mainForm' });
export const $customerIds = $form.map<readonly CustomerId[] | null>((c) => c.customerIds);
export const $isInternal = $form.map((c) => c.isInternal);
export const $needReviewersNotice = $form.map((c) => c.need_reviewers_notice);
const $currentCustomer = restore(
  fetchCurrentCustomerFx.doneData.map((c) => c.data),
  null,
).reset($customerIds.updates);
export const $isCurrentCustomerLoading = fetchCurrentCustomerFx.pending;

$currentCustomer.watch((c) => {
  console.log('$currentCustomer', c);
});

//Изменение задачи заблокировано для текущего юзера (он не автор задачи)
export const $isReadOnlyForUser = combine(
  $form,
  $user,
  (form, user) => form.isReadOnly && !!form.readonlyAuthor && form.readonlyAuthor !== user!.id,
);

const $isAddCheckList = createStore(false).reset(TaskEditModalGate.open);

const $currentSelectedUsers = $selectedUsers.map(
  (c) => c.get(EmployeeSelectKey.assigned) ?? EMPTY_ARRAY,
);
const currentSelectedUsersChangeWatcher = changeWatcher($currentSelectedUsers);

const $currentSelectedReviewers = $selectedUsers.map(
  (c) => c.get(EmployeeSelectKey.reviewer) ?? EMPTY_ARRAY,
);

//Будем запрашивать информации о текущем клиенте, только если выбран один
sample({
  clock: $customerIds.updates,
  filter: (ids) => ids?.length === 1,
  target: fetchCurrentCustomerFx,
  fn: (ids) => ids![0],
});
export const $currentCustomerTags = combine(
  $form.map((form) => form.customerIds),
  $currentCustomer,
  $customerTagsMap,
  (ids, customer, tagsMap) => {
    if (ids?.length !== 1 || !customer) {
      return EMPTY_ARRAY;
    }

    return (
      customer.tags?.filter((id) => tagsMap.has(id)).map((id) => tagsMap.get(id)!) ?? EMPTY_ARRAY
    );
  },
);

$form
  .on(applyValidTemplate, (s, { id, text, priority, auto_assign }) => ({
    ...s,
    text: taskTextFromApi(text).originalText,
    priority,
    // TODO: новые поля, которых тут ещё не было
    auto_assign,
    // TODO: assigned_user_roles,
    template_id: id,
  }))
  .on(setCustomers, (s, customerIds) => ({ ...s, customerIds }))
  .on(setField, (s, { target: { name, value } }) => ({ ...s, [name]: value }))
  .on(textChanged, (s, text) => (s.text === text ? s : { ...s, text }))
  .on(setTaskTypeIcon, (s, type_icon_custom) => ({ ...s, type_icon_custom }))
  .on(setPriority, (s, value) => ({ ...s, priority: value }))
  .on(toggleTaskClosed, (s) => ({
    ...s,
    isTaskClosed: !s.isTaskClosed,
    dateStatusUpdated: !s.isTaskClosed ? toISODateString(new Date()) : '',
  }))
  .on(isInternalClicked, (s) => ({ ...s, isInternal: !s.isInternal }))
  .on(isReadOnlyClicked, (s) => ({ ...s, isReadOnly: !s.isReadOnly }))
  .on(needReviewersNoticeClicked, (s) => ({
    ...s,
    need_reviewers_notice: !s.need_reviewers_notice,
  }))
  .on(toggleDateEndEqualCalendar, (s, isEqual) => ({
    ...s,
    date_calendar: isEqual ? (s.date_end as DateISO) : s.date_calendar,
  }));
// Когда меняем дату окончания, то вслед за ней нужно менять и дату календаря при включенной опции
sample({
  clock: setField,
  source: { form: $form, isDateEndEqualCalendar: $isDateEndEqualCalendar },
  filter: ({ isDateEndEqualCalendar }, { target: { name } }) =>
    name === 'date_end' && isDateEndEqualCalendar,
  target: $form,
  fn: ({ form }, { target }): Form => ({ ...form, date_calendar: target.value as DateISO }),
});

//Инициализация формы
const taskOpened = sample({
  clock: $showModal,
  source: $currentTask,
  filter: (_, isShowModal) => isShowModal,
}) as Event<Task | null>;
const existTaskOpened = sample({
  clock: $showModal,
  source: $currentTask,
  filter: (task, isShowModal) => isShowModal && !!task,
}) as Event<Task>;
const newTaskOpened = sample({
  clock: $showModal,
  source: $currentTask,
  filter: (task, isShowModal) => isShowModal && !task,
}) as Event<null>;

//reset after mount
sample({
  clock: taskOpened,
  //todo getTagsLocal if need
  target: [resetCheckList, getTagsLocal, taskRepeatForm.reset, changeWatcherRepeatForm.reset],
});

$form.on(setCurrentTask, (_, task) =>
  !task
    ? initForm()
    : {
        text: task.originalText,
        priority: task.priority,
        date_end: task.date_end,
        date_calendar: task.date_calendar,
        customerId: task.team_id,
        customerIds: task.team_id ? [task.team_id] : null,
        dateStatusUpdated:
          task.status === 'closed' ? toISODateString(parseDateStrict(task.status_updated_at)) : '',
        isTaskClosed: task.status === 'closed',
        repeatable: !!task.repeatable_settings,
        isInternal: !task.team_id,
        isReadOnly: !!task.is_readonly,
        need_reviewers_notice: !!task.need_reviewers_notice,
        readonlyAuthor: task.readonly_author,
        type_icon_custom: task.type_icon_custom,
      },
);
sample({
  clock: createTask,
  source: $form,
  target: $form,
  fn: (form, task) => ({ ...form, ...task }),
});

$isDateEndEqualCalendar
  .on(setCurrentTask, (_, task) => (task ? task.date_end === task.date_calendar : true))
  .on(toggleDateEndEqualCalendar, (s) => !s);

//Стор, который будет использоваться для определения есть ли изменения в основной форме
const $initForm = createStore<Form | null>(null, { name: 'initMainForm' });
sample({ clock: setCurrentTask, source: $form, target: $initForm });
//заполняем форму для повторений если есть чем
sample({
  clock: sample({ source: existTaskOpened, filter: (task) => !!task.repeatable_settings }),
  target: [taskRepeatForm.setForm, changeWatcherRepeatForm.reset],
  fn: (task) => (task as Task).repeatable_settings!,
});

//Добавление юзеров при инициализации
sample({
  clock: newTaskOpened,
  source: $form,
  filter: (form) => Boolean(form.customerIds?.length),
  target: setCustomers,
  fn: (form) => form.customerIds,
});
//ответственные
sample({
  clock: existTaskOpened,
  target: replaceUsers,
  fn: (task: Task): SelectEmployeeParams =>
    task.assignees
      ? {
          userIds: task.assignees.map((c) => c.user_id),
          id: EmployeeSelectKey.assigned,
        }
      : { userIds: EMPTY_ARRAY, id: EmployeeSelectKey.assigned },
});
//тоже самое с ревьюерами
sample({
  clock: existTaskOpened,
  target: replaceUsers,
  fn: (task: Task): SelectEmployeeParams =>
    task.reviewers
      ? {
          userIds: task.reviewers.map((c) => c.user_id),
          id: EmployeeSelectKey.reviewer,
        }
      : { userIds: EMPTY_ARRAY, id: EmployeeSelectKey.reviewer },
});
sample({
  clock: existTaskOpened,
  target: currentSelectedUsersChangeWatcher.reset,
});
// юзеры из шаблона
sample({
  source: applyValidTemplate,
  filter: ({ auto_assign }) => !auto_assign,
  fn: ({ assignees }): SelectEmployeeParams => ({
    id: EmployeeSelectKey.assigned,
    userIds: assignees ?? EMPTY_ARRAY,
  }),
  target: replaceUsers,
  // https://trello.com/c/W9i3xfd9/1115 - при редактировании хз, надо ли добавить или заменить
});

//Добавление файлов при инициализации
sample({
  clock: $currentTask,
  target: taskFilesApi.setFilesByFileshare,
  filter: (task) => !!task,
  fn: (task) => (task as Task).files?.map(fileshareFromFileStorage) || [],
});
sample({
  clock: $form,
  filter: ({ externalFiles }) => !!externalFiles,
  target: taskFilesApi.addFileshares,
  fn: ({ externalFiles }) => externalFiles!.map(fileshareFromFileStorage),
});
// файлы из шаблона
sample({
  clock: applyValidTemplate,
  source: $currentTask,
  filter: (task) => task !== false,
  fn: (task, { files }) => ({
    task,
    files: files ? files.map(fileshareFromFileStorage) : EMPTY_ARRAY,
  }),
}).watch(({ task, files }) => {
  if (task) {
    // https://trello.com/c/W9i3xfd9/1115
    // но получается херня: применяешь несколько шаблонов подряд - файлы добавляются ещё раз
    const hasIds = new Set(taskFilesApi.$filesId.getState());
    taskFilesApi.addFileshares(files.filter((f) => !hasIds.has(f.id)));
  } else {
    taskFilesApi.replaceFileshares(files);
  }
});

//Запрос чеклиста при инициализации
sample({ clock: existTaskOpened, target: getTaskCheckList });
//Будем здесь хранить состояние чеклиста после инициализации, будем сравнивать при закрытии окна, чтобы не делать лишних запросов на апдейт
const $initChecklist = createStore(null) as typeof $checklist;
sample({
  clock: getTaskCheckListFx.done,
  source: $checklist,
  target: $initChecklist,
});
// чеклист из шаблона
sample({
  source: applyValidTemplate,
  fn: ({ checklists }): TaskChecklistItemSimpleWithId[] =>
    checklists?.[0]?.items?.map((c) => ({ ...c, id: getUniqKeyRnd() })) ?? EMPTY_ARRAY,
  target: $checkListItems,
  // https://trello.com/c/W9i3xfd9/1115 - при редактировании хз, надо ли добавить или заменить
});

// multi customers
multiCustomerClicked.watch(() => {
  promptCustomerMultiSelectModal($customerIds.getState()).then(fnVoid);
});

//заполним исполнителей по тем сотрудникам, что привязаны к выбранному клиенту, и если выбран один клиент
sample({
  clock: $currentCustomer.updates,
  source: $isNewTask,
  filter: (isNewTask, currentCustomer: Customer | null): currentCustomer is Customer =>
    Boolean(currentCustomer) && isNewTask,
  target: replaceUsers,
  fn: (_, currentCustomer): SelectEmployeeParams => ({
    userIds:
      currentCustomer!.customerChannels[0].contractorEmployees.map((c) => c.id) ?? EMPTY_ARRAY,
    id: EmployeeSelectKey.assigned,
  }),
});
/* Список айди тех юзеров, которых можно выбрать для назначения к задаче. Обычно это те, что связаны с выбранным клиентом. */
export const $filteredUsersId = createStore<UserId[] | null>(null).reset(unmount);
//Выбираем клиента (только если 1шт), и определяем связанных сотрудников
sample({
  clock: $currentCustomer.updates,
  filter: (currentCustomer: Customer | null): currentCustomer is Customer =>
    Boolean(currentCustomer),
  target: $filteredUsersId,
  fn: (currentCustomer) =>
    currentCustomer!.customerChannels[0].contractorEmployees.map((c) => c.id) ?? EMPTY_ARRAY,
});
//Удалим ответственных если выбрано более 1 клиента, или вообще не выбрано
sample({
  clock: setCustomers,
  filter: (ids) => ids?.length !== 1,
  target: replaceUsers,
  fn: (): SelectEmployeeParams => ({
    userIds: EMPTY_ARRAY,
    id: EmployeeSelectKey.assigned,
  }),
});
sample({
  clock: setCustomers,
  filter: (ids) => ids?.length !== 1,
  target: $filteredUsersId,
  fn: () => null,
});
// multi select
sample({
  clock: customersSelectedId,
  target: setCustomers,
});

// назначить меня
export const assignMeClicked = createEvent<unknown>();
sample({
  clock: assignMeClicked,
  source: { user: $user, employeeTab: $employeeTab },
  target: addUsers,
  fn: ({ user, employeeTab }): SelectEmployeeParams => ({ userIds: [user!.id], id: employeeTab }),
});

export const $isShowAssignMe = combine(
  $currentSelectedUsers,
  $currentSelectedReviewers,
  $userId,
  $employeeTab,
  (users, reviewers, userId, employeeTab) => {
    const arr = employeeTab === EmployeeSelectKey.assigned ? users : reviewers;
    return !arr.some((c) => c.id === userId);
  },
);
//Удалить всех назначенных
export const deleteAllEmployeesClicked = createEvent<unknown>();
sample({
  clock: deleteAllEmployeesClicked,
  source: $employeeTab,
  target: replaceUsers,
  fn: (employeeTab): SelectEmployeeParams => ({ id: employeeTab, userIds: EMPTY_ARRAY }),
});

export const $isDateStatusDisabled = $form.map((f) => !f.isTaskClosed);

/** Tags */
export const taskTagDeleted = createEvent<TagId>();
const taskTagToggled = createEvent<Tag>();
export const resetTaskTags = createEvent();
export const $taskTags = createStore<readonly Tag[]>(EMPTY_ARRAY).reset(resetTaskTags);
export const $taskTagIds = $taskTags.map((tags) => tags.map((c) => c.id));
const $initTaskTagIds = createStore<TagId[]>(EMPTY_ARRAY).reset(resetTaskTags);
const $taskTagChanged = createStore<boolean>(false);

sample({ clock: TAG_CALLBACKS.select, target: taskTagToggled });
sample({
  clock: taskTagDeleted,
  source: { tags: $tagsLocal, taskIds: $taskTagIds },
  filter: ({ taskIds }, tagId) => taskIds.includes(tagId),
  target: taskTagToggled,
  fn: ({ tags }, tagId) => tags.find((tag) => tag.id === tagId)!,
});

$taskTags.on(existTaskOpened, (_, task) => task.tags?.filter((c) => !c.is_global) || EMPTY_ARRAY);
$taskTags.on(applyValidTemplate, (_, { tags }) => tags ?? EMPTY_ARRAY);
$taskTags.on(taskTagToggled, (s, tag) => {
  const idx = s.findIndex((c) => c.id === tag.id);
  const newArr = [...s];
  if (idx < 0) newArr.push(tag);
  else newArr.splice(idx, 1);

  return newArr;
});
$taskTags.on(updateTagFx.doneData, (s, { data }) => s.map((c) => (c.id === data.id ? data : c)));
sample({ clock: existTaskOpened, source: $taskTagIds, target: $initTaskTagIds });
sample({
  clock: $taskTagIds,
  source: $initTaskTagIds,
  target: $taskTagChanged,
  fn: (current, init) => !isequal(current, init),
});

//Получает форму, без определенных полей. Если на форме изменены только эти поля, то не надо показывать диалог для repeatForward
const toShowRepeatForwardForm = ({
  isTaskClosed,
  dateStatusUpdated,
  date_end,
  date_calendar,
  ...rest
}: Form) => rest;
const $showRepeatForwardInitForm = $initForm.map((form) =>
  form ? toShowRepeatForwardForm(form) : null,
);

//работа с повторениями
export const $isRepeatable = createStore<boolean>(false, { name: '$isRepeatable' });
$isRepeatable.on(setCurrentTask, (_, task) => (task ? !!task.repeatable_settings : false));
$isRepeatable.on(taskRepeatForm.$isDirty, (_, isDirty) => isDirty);

// https://trello.com/c/W9i3xfd9/1115
export const $showTemplatesPick = combine(
  $currentTask,
  $isRepeatable,
  $isReadOnlyForUser,
  (task, isRepeatable, isReadOnlyForUser) => {
    // в создании задачи
    if (!task) return true;
    // в ручной задачи
    return !task.is_typical && !isRepeatable && !isReadOnlyForUser;
  },
);

/** Submit */
interface SubmitOptions {
  // repeatableForward?: boolean;
  andStartTimer?: boolean;
  forwardType?: ForwardType;
}

export const submitClicked = createEvent<MouseEvent<HTMLButtonElement>>();
export const submit = createEvent<SubmitOptions | undefined>();
export const submitWithPrompt = createEvent<HTMLElementWithMeta>();
const $submitOptions = createStore<SubmitOptions | null>(null).on(submit, (_, p) => p ?? null);

const validateFx = createEffect<Form, null, Errors>((form: Form) => {
  const task = $currentTask.getState();
  const isRepeatable = $isRepeatable.getState();
  const dateStart = task ? parseDateStrict(task.date_begin) : startOfDay(new Date());
  const formatDate = getDateFormat();

  const dateEnd = parseDate(form.date_end);
  let dateEndError = undefined;
  if (task || !isRepeatable) {
    if (!dateEnd) {
      dateEndError = i18next.t('validation:WrongDateFormat');
    } else if (isBefore(parseDateStrict(form.date_end), dateStart)) {
      dateEndError = i18next.t('validation:dateAfter', { date: formatDate(dateStart) });
    }
  }

  const errors: Errors = {
    customerIds: !form.isInternal ? requiredValidator(form.customerIds?.length) : undefined,
    text: requiredValidator(htmlToPlainText(form.text).trim()),
    dateStatusUpdated: form.isTaskClosed ? requiredValidator(form.dateStatusUpdated) : undefined,
    date_end: dateEndError,
    selectedUsers: requiredValidator($currentSelectedUsers.getState().length),
  };

  if (!isValid(errors)) throw errors;
  return null;
});
//валидируем форму повторений только если на ней есть изменения
sample({
  clock: submit,
  filter: $isRepeatable,
  target: taskRepeatForm.validate,
});

validateAllFormsFx.use(async ({ form, isValidRepeatForm }) => {
  await Promise.all([validateFx(form), validateFinanceFormFx()]);

  if (!isValidRepeatForm) {
    return Promise.reject('Repeat form is not valid');
  }

  return null;
});

sample({
  clock: submit,
  source: { form: $form, isValidRepeatForm: taskRepeatForm.$isValid },
  target: validateAllFormsFx,
});

const allFormValidated = validateAllFormsFx.done;

const checkNeedShowRepeatForwardPrompt = ([
  task,
  form,
  initForm,
  taskTagChanged,
  isSelectedUsersChanged,
  financeForm,
  isRepeatFormChanged,
]: Readonly<
  [
    StoreValue<typeof $currentTask>,
    StoreValue<typeof $form>,
    StoreValue<typeof $showRepeatForwardInitForm>,
    StoreValue<typeof $taskTagChanged>,
    boolean,
    StoreValue<typeof $financeForm>,
    boolean,
  ]
>) =>
  Boolean(
    // есть задача
    task &&
      // повторяющаяся
      task.repeatable_settings &&
      // в ней изменено что-либо значимое для повторений
      (!isequal(toShowRepeatForwardForm(form), initForm) ||
        taskTagChanged ||
        isSelectedUsersChanged ||
        isRepeatFormChanged ||
        task.originalText !== form.text ||
        isFinanceDataFormEditChanged(financeForm)),
  );

// сабмит в задаче без повторений
sample({
  clock: submitClicked,
  source: [
    $currentTask,
    $form,
    $showRepeatForwardInitForm,
    $taskTagChanged,
    currentSelectedUsersChangeWatcher.$isChanged,
    $financeForm,
    changeWatcherRepeatForm.$isChanged,
  ] as const,
  filter: (args) => !checkNeedShowRepeatForwardPrompt(args),
  target: submit,
  fn: () => undefined,
});
// сабмит в задаче с повторениями
sample({
  clock: submitClicked,
  source: [
    $currentTask,
    $form,
    $showRepeatForwardInitForm,
    $taskTagChanged,
    currentSelectedUsersChangeWatcher.$isChanged,
    $financeForm,
    changeWatcherRepeatForm.$isChanged,
  ] as const,
  filter: checkNeedShowRepeatForwardPrompt,
  target: submitWithPrompt,
  fn: (_, e) => e.currentTarget,
});

//todo validate
// sample({
//   clock: submit,
//   source: [taskRepeatForm.$isValid, $additionalFormMode] as const,
//   target: createEffect(([isValid, mode]: [boolean, AdditionalFormMode]) => {
//     if (!isValid && mode !== 'repeat')
//       showToast(ToastTypes.error, i18next.t('task:edit.repeatForm.message.failValidate'));
//   }),
// });

const resetErrors = createEvent();
export const $errors = restore<Errors>(validateFx.failData, {})
  .on(setField, (s, { target: { name } }) => ({ ...s, [name]: undefined }))
  .on(textChanged, (s) => (s.text ? { ...s, text: undefined } : s))
  .on(toggleTaskClosed, (s) => ({ ...s, dateStatusUpdated: undefined }))
  .on([replaceUsers, addUsers], (s, { id }) =>
    id === EmployeeSelectKey.assigned ? { ...s, selectedUsers: undefined } : s,
  )
  .on(setCustomers, (s) => ({ ...s, customerId: undefined }));
$errors.reset(resetErrors);

export const $hasMainTabErrors = $errors.map((c) => !isValid(c));

//создание
sample({
  clock: sample({ clock: allFormValidated, filter: not($currentTask) }),
  source: [
    $form,
    $currentCompanyIdOrNull,
    $currentSelectedUsers,
    $currentSelectedReviewers,
    taskFilesApi.$filesId,
    $taskTagIds,
    taskRepeatForm.$values,
    $isRepeatable,
    $checkListItems,
    $financeForm,
  ] as const,
  target: taskCreateFx,
  fn: ([
    form,
    companyId,
    users,
    reviewers,
    files,
    taskTagIds,
    _taskRepeatForm,
    isRepeatable,
    checkListItems,
    financeForm,
  ]): TaskCreateForm => {
    const isBulkForTeams = form.customerIds && form.customerIds.length > 1;

    return {
      ...form._extraOptions,
      bookkeeper_team_id: companyId!,
      date_begin: toISODateString(new Date()),
      date_end: isRepeatable ? toISODateString(new Date()) : form.date_end,
      date_calendar: form.date_calendar,
      text: form.text,
      priority: form.priority,
      status: 'open',
      assignees: users.map((c) => c.id),
      team_id: form.customerIds?.length === 1 ? form.customerIds?.[0] : undefined,
      teams: isBulkForTeams ? { by_team_id: form.customerIds! } : undefined,
      bulk_for_teams: isBulkForTeams ? true : undefined,
      files,
      tags: taskTagIds,
      is_readonly: form.isReadOnly,
      //todo
      repeatable: isRepeatable,
      repeatable_settings:
        (isRepeatable && convertTaskRepeatFormToRequest(_taskRepeatForm)) || undefined,
      checklists: checkListItems?.length
        ? [
            {
              title: 'default',
              items: checkListItems.map(({ text, state }) => ({ text, state })),
            },
          ]
        : undefined,
      finance: toSubmitFinanceForm(financeForm),
      type_icon_custom: form.type_icon_custom,
      template_id: form.template_id,
      reviewers: reviewers.map((c) => c.id),
      need_reviewers_notice: form.need_reviewers_notice,
    };
  },
});
sample({
  clock: taskCreateFx.doneData,
  source: $submitOptions,
  filter: (o) => Boolean(o?.andStartTimer),
  fn: (_, resp): Task =>
    Array.isArray(resp.data) ? fromApiToTask(resp.data[0]) : fromApiToTask(resp.data),
  // направляю в `watch`, а не в `target`, чтобы сначала доработал `taskCreateFx.done`
  // в других местах и добавил задача в список, а потом только toggle, чтобы задача
  // в списке обновилась
}).watch(toggleActiveFx);
// редактирование НЕ типовой задачи, без повторений, без "только для чтения"
sample({
  clock: sample({
    clock: allFormValidated,
    source: [$currentTask, $isRepeatable, $isReadOnlyForUser] as const,
    filter: ([task, isRepeatable, isReadOnlyForUser]) =>
      !!task && !task.is_typical && !isRepeatable && !isReadOnlyForUser,
  }),
  source: [
    $form,
    $currentSelectedUsers,
    $currentSelectedReviewers,
    $currentTask,
    taskFilesApi.$filesId,
    $taskTagIds,
  ] as const,
  target: taskPatchFx,
  fn: ([form, users, reviewers, task, files, taskTagIds]): TaskPatchParams => ({
    taskId: (task as Task).id,
    form: {
      date_end: form.date_end,
      date_calendar: form.date_calendar,
      text: form.text,
      priority: form.priority,
      assignees: users.map((c) => c.id),
      files,
      status_updated_date: form.isTaskClosed ? form.dateStatusUpdated : undefined,
      status: form.isTaskClosed ? 'closed' : 'open',
      tags: taskTagIds,
      is_readonly: form.isReadOnly,
      type_icon_custom: form.type_icon_custom,
      reviewers: reviewers.map((c) => c.id),
      need_reviewers_notice: form.need_reviewers_notice,
    },
  }),
});
// редактирование НЕ типовой задачи, с повторениями, без "только для чтения"
sample({
  clock: sample({
    clock: allFormValidated,
    source: [$currentTask, $isRepeatable, $isReadOnlyForUser] as const,
    filter: ([task, isRepeatable, isReadOnlyForUser]) =>
      !!task && !task.is_typical && isRepeatable && !isReadOnlyForUser,
  }),
  source: [
    $form,
    $currentSelectedUsers,
    $currentSelectedReviewers,
    $currentTask,
    taskFilesApi.$filesId,
    $taskTagIds,
    taskRepeatForm.$values,
    $submitOptions,
    changeWatcherRepeatForm.$isChanged,
    $financeReplaceForm,
  ] as const,
  target: tasksRepeatableUpdateFx,
  fn: ([
    form,
    users,
    reviewers,
    task,
    files,
    taskTagIds,
    repeatForm,
    submitOptions,
    repeatFormChanged,
    financeReplaceForm,
  ]): TaskRepeatableEditParams => ({
    taskId: (task as Task).id,
    form: {
      date_end: form.date_end,
      //todo сейчас при указании date_calendar сохранить повторяющуюся задачу невозможно
      date_calendar: form.date_calendar,
      text: form.text,
      priority: form.priority,
      assignees: users.map((c) => c.id),
      files,
      status_updated_date: form.isTaskClosed ? form.dateStatusUpdated : undefined,
      status: form.isTaskClosed ? 'closed' : 'open',
      tags: taskTagIds,
      repeatable: repeatForm.mode !== null,
      repeatable_changes_apply_forward:
        (!!submitOptions &&
          (submitOptions.forwardType === 'customer' || submitOptions.forwardType === 'all')) ||
        repeatFormChanged,
      repeatable_settings: convertTaskRepeatFormToRequest(repeatForm) ?? undefined,
      is_readonly: form.isReadOnly,
      finance: toSubmitFinanceForm(financeReplaceForm),
      type_icon_custom: form.type_icon_custom,
      for_teams:
        submitOptions?.forwardType === 'customer' && (task as Task).team_id
          ? [(task as Task).team_id!]
          : undefined,
      reviewers: reviewers.map((c) => c.id),
      need_reviewers_notice: form.need_reviewers_notice,
    },
  }),
});

// редактирование НЕ типовой задачи, без повторений, "только для чтения"
sample({
  clock: allFormValidated,
  source: {
    task: $currentTask,
    form: $form,
    isRepeatable: $isRepeatable,
    isReadOnlyForUser: $isReadOnlyForUser,
  },
  filter: ({ task, isReadOnlyForUser, isRepeatable }) =>
    !!task && !task.is_typical && !isRepeatable && isReadOnlyForUser,
  target: taskPatchFx,
  fn: ({ task, form }): TaskPatchParams => ({
    taskId: (task as Task).id,
    form: {
      status: form.isTaskClosed ? 'closed' : 'open',
      status_updated_date: form.isTaskClosed ? form.dateStatusUpdated : undefined,
    },
  }),
});
// редактирование НЕ типовой задачи, с повторениями, "только для чтения"
sample({
  clock: allFormValidated,
  source: {
    task: $currentTask,
    form: $form,
    isRepeatable: $isRepeatable,
    isReadOnlyForUser: $isReadOnlyForUser,
  },
  filter: ({ task, isReadOnlyForUser, isRepeatable }) =>
    !!task && !task.is_typical && isRepeatable && isReadOnlyForUser,
  target: tasksRepeatableUpdateFx,
  fn: ({ task, form }): TaskRepeatableEditParams => ({
    taskId: (task as Task).id,
    form: {
      status: form.isTaskClosed ? 'closed' : 'open',
      status_updated_date: form.isTaskClosed ? form.dateStatusUpdated : undefined,
      repeatable_changes_apply_forward: false,
    },
  }),
});

// редактирование типовой задачи
sample({
  clock: sample({
    clock: allFormValidated,
    source: $currentTask,
    filter: (task) => !!task && task.is_typical,
  }),
  source: [
    $currentSelectedUsers,
    // $currentSelectedReviewers,
    $currentTask,
    $currentCompanyIdOrNull,
    $form,
    taskFilesApi.$filesId,
    $taskTagIds,
  ] as const,
  target: taskTypicalUpdateFx,
  fn: ([
    users,
    /*reviewers,*/ task,
    companyId,
    form,
    files,
    taskTagIds,
  ]): TaskTypicalUpdateParams => ({
    taskId: (task as Task).id,
    form: {
      task_id: (task as Task).id,
      team_id: (task as Task).team_id!,
      bookkeeper_team_id: companyId!,
      date_calendar: form.date_calendar,
      files,
      assignees: users.map((c) => c.id),
      status_updated_date: form.isTaskClosed ? form.dateStatusUpdated : undefined,
      status: form.isTaskClosed ? 'closed' : 'open',
      tags: taskTagIds,
      // reviewers: reviewers.map((c) => c.id)
    },
  }),
});

// сохранение финансов при редактировании любой задачи, кроме повторяющихся
sample({
  clock: allFormValidated,
  source: [$currentTask, $isRepeatable, $isReadOnlyForUser] as const,
  filter: ([task, isRepeatable, isReadOnlyForUser]) =>
    Boolean(task && !(!task.is_typical && isRepeatable) && !isReadOnlyForUser),
  target: saveTaskFinance,
});

//работа с чеклистами
export const addDeleteChecklist = createEvent<MouseEvent<HTMLButtonElement>>();

//В случае новой задачи используем TaskChecklistItemSimple, в случае редактирования используем сохраненный ранее
export const $localOrGlobalCheckListItems = combine(
  $checkListItemsLocal,
  $taskChecklistItems,
  $currentTask,
  (local, global, task) => (task ? global : local),
);
export const $isShowChecklist = combine(
  $checklist,
  $localOrGlobalCheckListItems,
  (checkList, items) => !!checkList || !!items,
);

//добавим чеклист в новую задачу
// добавление чеклистов в имеющуюся задачу смотри в modelRepeatPrompt.tsx
sample({
  clock: addDeleteChecklist,
  source: [$form, $currentTask] as const,
  filter: ([_, task]) => !task,
  target: checkListToggled,
});
// item чеклиста
// здесь работа с чеклистами для задач без повторений. С повторениями в modelRepeatPrompt.tsx
export const createTaskChecklistItemForCurrent = createEvent<{
  text: string;
  element: HTMLElement;
}>();
sample({
  clock: createTaskChecklistItemForCurrent,
  source: { task: $currentTask, isRepeatable: $isRepeatable },
  filter: ({ isRepeatable }) => !isRepeatable,
  target: createTaskChecklistItem,
  fn: ({ task }, { text }): EventPayload<typeof createTaskChecklistItem> => ({
    task: task as Task,
    text,
  }),
});
export const updateTaskChecklistItemForCurrent = createEvent<{
  item: TaskChecklistItem;
  element: HTMLElement;
}>();
sample({
  clock: updateTaskChecklistItemForCurrent,
  source: { task: $currentTask, isRepeatable: $isRepeatable },
  filter: ({ isRepeatable }) => !isRepeatable,
  target: updateTaskChecklistItem,
  fn: ({ task }, { item }): EventPayload<typeof updateTaskChecklistItem> => ({
    task: task as Task,
    item,
  }),
});
//Тут не будем спрашивать про повторяющиеся задачи
export const toggleTaskChecklistItemForCurrent = createEvent<{
  item: TaskChecklistItem;
  element: HTMLElement;
}>();
sample({
  clock: toggleTaskChecklistItemForCurrent,
  source: $currentTask,
  target: toggleTaskChecklistItem,
  fn: (task, { item }): EventPayload<typeof toggleTaskChecklistItem> => ({
    task: task as Task,
    item,
  }),
});
export const deleteTaskChecklistItemForCurrent = createEvent<{
  id: TaskChecklistItemId;
  element: HTMLElement;
}>();
sample({
  clock: deleteTaskChecklistItemForCurrent,
  source: { task: $currentTask, isRepeatable: $isRepeatable },
  filter: ({ isRepeatable }) => !isRepeatable,
  target: deleteTaskChecklistItem,
  fn: ({ task }, { id }): EventPayload<typeof deleteTaskChecklistItem> => ({
    task: task as Task,
    item_id: id,
  }),
});
export const sortTaskChecklistItemForCurrent = createEvent<{ sort: TaskChecklistItemId[] }>();

sample({
  clock: sortTaskChecklistItemForCurrent,
  target: updateSortingTaskCheckList,
  fn: ({ sort }) => sort,
});

/** Закрытие окна */
export const closeModalWithPrompt = createEvent<unknown>();
export const closeModal = createEvent<unknown>();
sample({
  clock: closeModalWithPrompt,
  source: { task: $currentTask, form: $form },
  target: createEffect(async ({ task, form }: { task: false | Task | null; form: Form }) => {
    if ((!!task && task.originalText !== form.text) || (!task && form.text)) {
      if (await promptFn(i18next.t('task:edit.promptClose'))) {
        closeModal();
      }
      return;
    }
    closeModal();
  }),
});
//обновим чеклист в списке для данной задачи (Будем делать это, если он действительно был изменен)
sample({
  clock: closeModal,
  source: [$currentTask, $checklist, $initChecklist] as const,
  filter: ([_, checklist, init]) => init !== checklist,
  target: taskChecklistDidChange,
  fn: ([task, checklist]) => {
    return {
      task: task as Task,
      checklist,
    };
  },
});
//закроем окно, но сначала очистим чат
sample({
  clock: closeModal,
  source: $currentTask,
  filter: Boolean,
  target: taskChatData.resetPosts,
});
sample({
  clock: closeModal,
  target: $currentTask,
  fn: (): false => false,
});

merge([
  sample({
    clock: taskCreateFx.done,
    filter: not($isAddCheckList),
  }),
  taskPatchFx.done,
  taskTypicalUpdateFx.done,
  tasksRepeatableUpdateFx.done,
  sample({ clock: taskUniversalDeleteFx.doneData, filter: Boolean }),
]).watch(() => {
  taskFilesApi.commit();
  closeModal();
});
//reset after unmount
sample({
  clock: $currentTask,
  filter: (task) => task === false,
  target: [
    resetErrors,
    resetTaskTags,
    resetCheckListItems,
    unmount,
    taskChatData.resetCurrentChannelData,
  ],
});

//создание задачи из поста
export const createTaskFromPost = createEvent<Post>();
sample({
  clock: createTaskFromPost,
  source: $customersChannelsItems,
  fn: (customersChannelsItems, post): EventPayload<typeof createTask> => {
    // Если канал был телеграмм, то заполним в задаче сразу клиента
    const customerItem = customersChannelsItems.find((item) =>
      item.channels.some((channel) => channel.channel_id === post.channelid),
    );
    return {
      text: post.message ?? '',
      customerIds: customerItem ? [customerItem.id as unknown as CustomerId] : undefined,
      externalFiles: (post.files as FileStorage[]) ?? undefined,
    };
  },
  target: createTask,
});

export const $pending = or(
  taskFilesApi.$pending,
  pending({
    effects: [
      taskCreateFx,
      taskPatchFx,
      taskTypicalUpdateFx,
      createTaskCheckListFx,
      deleteTaskCheckListFx,
      tasksRepeatableUpdateFx,
      tasksRepeatableDeleteFx,
      createTaskChecklistItemFx,
      updateTaskChecklistItemFx,
      deleteTaskChecklistItemFx,
      sortTaskChecklistItemFx,
    ],
  }),
);

// task chats
//================================================
// NOT typical
sample({
  clock: taskChatData.ChatGate.open,
  source: { companyId: $currentCompanyId, task: $currentTask },
  filter: ({ task }) => !!task && !task.is_typical,
  target: fetchTaskChannelFx,
  fn: ({ companyId, task }) => ({ taskId: (task as Task).id, companyId: companyId as CompanyId }),
});
// IS typical
sample({
  clock: taskChatData.ChatGate.open,
  source: { companyId: $currentCompanyId, task: $currentTask },
  filter: ({ task }) => !!task && task.is_typical,
  target: fetchTaskTypicalChannelFx,
  fn: ({ companyId, task }) => ({
    taskId: (task as Task).id,
    companyId: companyId as CompanyId,
    customer_team_id: (task as Task).team_id!,
  }),
});

const taskChannelFetched = [fetchTaskChannelFx.doneData, fetchTaskTypicalChannelFx.doneData];
sample({
  clock: taskChannelFetched,
  target: taskChatData.setCurrentChannel,
  fn: ({ data }): TaskChannelExt => ({
    ...data,
    channel_id: data.id as unknown as ChannelId,
    group: 'task',
  }),
});
//Удалим уведомления о новом сообщении в чате канала
sample({
  clock: taskChannelFetched,
  target: removeByChannelId,
  fn: ({ data }) => data.id as unknown as ChannelId,
});
//Удалим уведомления о новой задаче
sample({
  clock: existTaskOpened,
  target: removeByTaskId,
  fn: ({ id }) => id,
});
