import { useCallback, useEffect, useRef } from 'react';

/**
 * ```tsx
 * const isMounted = useIsMounted();
 * useEffect(() => {
 *   somethingAsync().then((result) => {
 *     if (!isMounted()) return;
 *     // use result
 *   });
 * }, [isMounted]);
 * ```
 */
export const useIsMounted = () => {
  const r = useRef(false);
  useEffect(() => {
    r.current = true;
    return () => {
      r.current = false;
    };
  }, []);
  return useCallback(() => r.current, []);
};
