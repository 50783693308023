import { authorize, unauthorize } from 'api';
import {
  $auth,
  $authType,
  $firstInitRequestInfo,
  $isFirstSign,
  $isTokenAuthFail,
  authLoginFx,
  firstInitFx,
  logout,
  resetIsFirstSign,
  setTokens,
  signUpFx,
  tokenAuth,
  tokenAuthFx,
} from './index';
import api from 'api/request/auth';
import { ApiResponse } from 'api/types';
import { Auth, AuthType } from './types';
import { createEffect, forward, guard, sample } from 'effector';
import { $companies, getCompaniesFx, resetCompanies } from '../company';
import { getUserFx, resetUser, resetUsers } from '../user';
//все таки пришлось специально подключить сюда, иначе иногда не подхватываются обработчики
import 'cent/model';
import { getCountriesFx } from '../country';
import { authKonturFx } from '../kontur';
import { LSKey } from '../persistent';
import { history } from 'utils/history';

authLoginFx.use(async (form) => await api.signin(form));
signUpFx.use(async (form) => await api.signup(form));

$auth
  .on(setTokens, (state, { accesstoken, refreshtoken, ctoken, clientId }) => ({
    ...state,
    accesstoken,
    refreshtoken,
    ctoken,
    clientId,
  }))
  .reset(logout);

const authDone = ({
  data: { accesstoken, refreshtoken, ctoken, clientId },
}: ApiResponse<Auth>): void => {
  authorize(accesstoken);
  localStorage.setItem(LSKey.accessToken, accesstoken);
  localStorage.setItem(LSKey.refreshToken, refreshtoken);
  localStorage.setItem(LSKey.ctoken, ctoken);
  localStorage.setItem(LSKey.clientId, clientId);
};

authLoginFx.doneData.watch(authDone);

signUpFx.doneData.watch(authDone);

// еще один обработчик в cent/model.ts Разнес для теоретического более правильного разбития на чанки при деплое
logout.watch(() => {
  unauthorize();
  for (const key of [
    LSKey.accessToken,
    LSKey.refreshToken,
    LSKey.ctoken,
    LSKey.clientId,
    //TODO: LSKey.company ?
  ]) {
    localStorage.removeItem(key);
  }
});

sample({
  clock: [
    authLoginFx.doneData,
    signUpFx.doneData,
    tokenAuthFx.doneData.map((c) => c.data),
    authKonturFx.doneData,
  ],
  target: setTokens,
  fn: ({ data }) => data,
});

// обработчик в cent/model.ts Разнес для теоретического более правильного разбития на чанки при деплое
firstInitFx.use(async () => await Promise.all([getUserFx(), getCompaniesFx(), getCountriesFx()]));

guard({
  clock: setTokens,
  source: $firstInitRequestInfo,
  filter: ({ pending, done }) => !pending && !done,
  target: firstInitFx,
});

$firstInitRequestInfo.reset(logout);
forward({
  from: logout,
  to: [resetCompanies, resetUsers, resetUser],
});

//--------------
$isFirstSign.on(
  guard({
    clock: firstInitFx.done,
    source: $companies,
    filter: (companies) => companies.length === 0,
  }),
  () => true,
);

$isFirstSign.reset([resetIsFirstSign, logout]);

sample({
  clock: $authType,
  target: createEffect((authType: AuthType | null) => {
    if (authType) localStorage.setItem(LSKey.authType, authType);
    else localStorage.removeItem(LSKey.authType);
  }),
});

$authType
  .on([authLoginFx.done, signUpFx.done, tokenAuthFx.done], () => 'normal')
  .on(authKonturFx.done, () => 'kontur')
  .on(logout, () => null);

/** tokenAuth */
// нужно было срочно реализовать функционал по логину через админку, поэтому сделал на коленке,
// но по идее надо весь этот модуль переписать
sample({
  clock: tokenAuth,
  target: tokenAuthFx,
});
tokenAuthFx.doneData.map((c) => c.data).watch(authDone);
$isTokenAuthFail.on(tokenAuthFx.fail, () => true);
//здесь также надо переписывать
authKonturFx.doneData.watch(authDone);
authKonturFx.doneData.watch(() => {
  history.push('/');
});
