import { PactCompanyConnectStatus, PactCompanyId, PactConversationId } from '../pact/types';
import { CompanyId } from '../company/types';
import { CentMessageDataV2 } from './internal';

export const enum CentPrivateEventTypePact {
  ConnectQR = 'pact.connectQr',
  Connected = 'pact.connected',
  ConnectStatus = 'pact.connectStatus',
  SyncDone = 'pact.syncDone',
  ConvChanged = 'pact.convChanged',
  ChatMessage = 'pact.message',
}

export interface CentPrivateEventPactCompany {
  companyId: PactCompanyId;
}

export interface CentPrivateEventPactQR extends CentPrivateEventPactCompany {
  /** data url */
  image: string;
  /** unix timestamp */
  expires: number;
}

export interface CentPrivateEventPactCompanyConnectStatus extends CentPrivateEventPactCompany {
  status: PactCompanyConnectStatus;
}

export interface CentPrivateEventPactChatMessage {
  teamId: CompanyId;
  convId: PactConversationId;
}

export type CentPrivateEventPact =
  | CentMessageDataV2<CentPrivateEventTypePact.ConnectQR, CentPrivateEventPactQR>
  | CentMessageDataV2<
      | CentPrivateEventTypePact.Connected
      | CentPrivateEventTypePact.SyncDone
      | CentPrivateEventTypePact.ConvChanged,
      CentPrivateEventPactCompany
    >
  | CentMessageDataV2<
      CentPrivateEventTypePact.ConnectStatus,
      CentPrivateEventPactCompanyConnectStatus
    >
  | CentMessageDataV2<CentPrivateEventTypePact.ChatMessage, CentPrivateEventPactChatMessage>;
