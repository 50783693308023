import { CompanyId } from '../company/types';
import { Optional } from '../../utils/types';

export type IntegrationType =
  | 'kontur'
  /** @deprecated Галка интеграции не используется, сама интеграция не используется */
  | 'pact-im'
  | 'uis'
  | 'yandex-disk'
  | 'zadarma';

export interface Integration {
  active: boolean;
  api_key: string;
  virtual_number: string;
  bookkeeper_team_id: CompanyId;
  service: IntegrationType;
  created_at: string;
  updated_at: string;
}

export interface IntegrationSaveForm
  extends Optional<Omit<Integration, 'created_at' | 'updated_at'>, 'api_key'> {}

export const INTEGRATIONS_PHONE: readonly IntegrationType[] = ['uis', 'zadarma'];
export type IntegrationCanCall = (i: Integration) => boolean;
