import { svgFlipH, svgFlipV, svgRot90L, svgRot90R } from '@cubux/react-utils';
import { ReactComponent as ArrowDownCircle } from './ArrowDownCircle.svg';
import { ReactComponent as ArrowRight } from './ArrowRight.svg';
import { ReactComponent as ArrowTurnLeft } from './ArrowTurnLeft.svg';
import { ReactComponent as ChevronLeft } from './ChevronLeft.svg';
import { ReactComponent as ChevronDoubleLeft } from './ChevronDoubleLeft.svg';
import { ReactComponent as DotsVertical } from './DotsVertical.svg';
import { ReactComponent as Down } from './Down.svg';
import { ReactComponent as DragVertical } from './DragVertical.svg';
import { ReactComponent as RotateLeft } from './RotateLeft.svg';

export { ReactComponent as AddReaction } from './AddReaction.svg';
export { ReactComponent as Alarm } from './Alarm.svg';
export { ReactComponent as Alert } from './Alert.svg';
export { ReactComponent as AlignJustify } from './AlignJustify.svg';
export { ReactComponent as AlignLeft } from './AlignLeft.svg';
export { ReactComponent as AlignMidH } from './AlignMidH.svg';
export { ReactComponent as AlignRight } from './AlignRight.svg';
export { ReactComponent as ArrowDownCircle } from './ArrowDownCircle.svg';
export const ArrowLeftCircle = svgRot90R(ArrowDownCircle);
export const ArrowRightCircle = svgRot90L(ArrowDownCircle);
export const ArrowUpCircle = svgFlipV(ArrowDownCircle);
export { ReactComponent as ArrowFrom } from './ArrowFrom.svg';
export { ReactComponent as ArrowRight } from './ArrowRight.svg';
export const ArrowDown = svgRot90R(ArrowRight);
export const ArrowLeft = svgFlipH(ArrowRight);
export const ArrowUp = svgRot90L(ArrowRight);
export { ReactComponent as ArrowTurnLeft } from './ArrowTurnLeft.svg';
export const ArrowTurnRight = svgFlipH(ArrowTurnLeft);
export { ReactComponent as ArrowUpLeft } from './ArrowUpLeft.svg';
export { ReactComponent as ArrowUpLeftDouble } from './ArrowUpLeftDouble.svg';
export { ReactComponent as ArrowUpRight } from './ArrowUpRight.svg';
export { ReactComponent as AtSign } from './AtSign.svg';
export { ReactComponent as Bank } from './Bank.svg';
export { ReactComponent as Bookmark } from './Bookmark.svg';
export { ReactComponent as Bookmark2 } from './Bookmark2.svg';
export { ReactComponent as BookWithMark } from './BookWithMark.svg';
export { ReactComponent as Building } from './Building.svg';
export { ReactComponent as Cake } from './Cake.svg';
export { ReactComponent as Calendar } from './Calendar.svg';
export { ReactComponent as Calendar2 } from './Calendar2.svg';
export { ReactComponent as CalendarClock } from './CalendarClock.svg';
export { ReactComponent as CalendarDate } from './CalendarDate.svg';
export { ReactComponent as CallIn } from './CallIn.svg';
export { ReactComponent as CallLost } from './CallLost.svg';
export { ReactComponent as CallOut } from './CallOut.svg';
export { ReactComponent as Camera } from './Camera.svg';
export { ReactComponent as Card1 } from './Card1.svg';
export { ReactComponent as Comment } from './Comment.svg';
export { ReactComponent as Connect } from './Connect.svg';
export { ReactComponent as ChartBarStand } from './ChartBarStand.svg';
export { ReactComponent as ChartBarTrendUp } from './ChartBarTrendUp.svg';
export { ReactComponent as ChartPie } from './ChartPie.svg';
export { ReactComponent as Chat } from './Chat.svg';
export { ReactComponent as Chat2 } from './Chat2.svg';
export { ReactComponent as Check } from './Check.svg';
export { ReactComponent as CheckCircles } from './CheckCircles.svg';
export { ReactComponent as CheckDouble } from './CheckDouble.svg';
export { ReactComponent as Checklist } from './Checklist.svg';
export { ReactComponent as CheckSquare } from './CheckSquare.svg';
export { ReactComponent as CheckSquareOff } from './CheckSquareOff.svg';
export { ReactComponent as ChevronLeft } from './ChevronLeft.svg';
export const ChevronDown = svgRot90L(ChevronLeft);
export const ChevronRight = svgFlipH(ChevronLeft);
export const ChevronUp = svgRot90R(ChevronLeft);
export { ReactComponent as ChevronDoubleLeft } from './ChevronDoubleLeft.svg';
export const ChevronDoubleDown = svgRot90L(ChevronDoubleLeft);
export const ChevronDoubleRight = svgFlipH(ChevronDoubleLeft);
export const ChevronDoubleUp = svgRot90R(ChevronDoubleLeft);
export { ReactComponent as ClaimCircle } from './ClaimCircle.svg';
export { ReactComponent as Clip } from './Clip.svg';
export { ReactComponent as Clock } from './Clock.svg';
export { ReactComponent as Clock2 } from './Clock2.svg';
export { ReactComponent as Contract } from './Contract.svg';
export { ReactComponent as Copy } from './Copy.svg';
export { ReactComponent as Copy1 } from './Copy1.svg';
export { ReactComponent as Cross } from './Cross.svg';
export { ReactComponent as CrossCircle } from './CrossCircle.svg';
export { ReactComponent as CustomerTask } from './CustomerTask.svg';
export { ReactComponent as DeleteLeft } from './DeleteLeft.svg';
export { ReactComponent as DotsVertical } from './DotsVertical.svg';
export const DotsHorizontal = svgRot90R(DotsVertical);
export { ReactComponent as Down } from './Down.svg';
export const Left = svgRot90R(Down);
export const Right = svgRot90L(Down);
export const Up = svgFlipV(Down);
export { ReactComponent as Download } from './Download.svg';
export { ReactComponent as DragVertical } from './DragVertical.svg';
export const DragHorizontal = svgRot90R(DragVertical);
export { ReactComponent as Edit } from './Edit.svg';
export { ReactComponent as Error } from './Error.svg';
export { ReactComponent as Exclamation } from './Exclamation.svg';
export { ReactComponent as ExternalLink } from './ExternalLink.svg';
export { ReactComponent as FieldCheckbox } from './FieldCheckbox.svg';
export { ReactComponent as FieldDate } from './FieldDate.svg';
export { ReactComponent as FieldDateTime } from './FieldDateTime.svg';
export { ReactComponent as FieldNumber } from './FieldNumber.svg';
export { ReactComponent as FieldSelect } from './FieldSelect.svg';
export { ReactComponent as FieldString } from './FieldString.svg';
export { ReactComponent as FieldUrl } from './FieldUrl.svg';
export { ReactComponent as FileBlank } from './FileBlank.svg';
export { ReactComponent as FileWithStamp } from './FileWithStamp.svg';
export { ReactComponent as Finance } from './Finance.svg';
export { ReactComponent as FinancePig } from './FinancePig.svg';
export { ReactComponent as Flag } from './Flag.svg';
export { ReactComponent as Folder } from './Folder.svg';
export { ReactComponent as Folder2 } from './Folder2.svg';
export { ReactComponent as FontHeight } from './FontHeight.svg';
export { ReactComponent as Grid } from './Grid.svg';
export { ReactComponent as GridCalc } from './GridCalc.svg';
export { ReactComponent as Group } from './Group.svg';
export { ReactComponent as Half } from './Half.svg';
export { ReactComponent as Help } from './Help.svg';
export { ReactComponent as Help2 } from './Help2.svg';
export { ReactComponent as Home } from './Home.svg';
export { ReactComponent as Info } from './Info.svg';
export { ReactComponent as KonturIco } from './KonturIco.svg';
export { ReactComponent as KonturTask } from './KonturTask.svg';
export { ReactComponent as Lang } from './Lang.svg';
export { ReactComponent as Like } from './Like.svg';
export { ReactComponent as Link } from './Link.svg';
export { ReactComponent as Loader } from './Loader.svg';
export { ReactComponent as Location } from './Location.svg';
export { ReactComponent as Mail } from './Mail.svg';
export { ReactComponent as Mail2 } from './Mail2.svg';
export { ReactComponent as Mail3 } from './Mail3.svg';
export { ReactComponent as MailInbox } from './MailInbox.svg';
export { ReactComponent as MailSend } from './MailSend.svg';
export { ReactComponent as Message } from './Message.svg';
export { ReactComponent as MessageNew } from './MessageNew.svg';
export { ReactComponent as Mic } from './Mic.svg';
export { ReactComponent as Minus } from './Minus.svg';
export { ReactComponent as NewTaskAlert } from './NewTaskAlert.svg';
export { ReactComponent as Notepad } from './Notepad.svg';
export { ReactComponent as OneS } from './OneS.svg';
export { ReactComponent as PauseCircle } from './PauseCircle.svg';
export { ReactComponent as Phone } from './Phone.svg';
export { ReactComponent as Phone2 } from './Phone2.svg';
export { ReactComponent as PhoneCall } from './PhoneCall.svg';
export { ReactComponent as PhoneOff } from './PhoneOff.svg';
export { ReactComponent as Picture } from './Picture.svg';
export { ReactComponent as PictureFloat } from './PictureFloat.svg';
export { ReactComponent as PictureFloatOff } from './PictureFloatOff.svg';
export { ReactComponent as Play } from './Play.svg';
export { ReactComponent as Plus } from './Plus.svg';
export { ReactComponent as PlusCircle } from './PlusCircle.svg';
export { ReactComponent as QrCode } from './QrCode.svg';
export { ReactComponent as Recovery } from './Recovery.svg';
export { ReactComponent as Refresh } from './Refresh.svg';
export { ReactComponent as Relations1 } from './Relations1.svg';
export { ReactComponent as Repeat } from './Repeat.svg';
export { ReactComponent as Rotate } from './RotateLeft.svg';
export { ReactComponent as RotateLeft } from './RotateLeft.svg';
export const RotateRight = svgFlipH(RotateLeft);
export { ReactComponent as Rub } from './Rub.svg';
export { ReactComponent as Sbis } from './Sbis.svg';
export { ReactComponent as Scale } from './Scale.svg';
export { ReactComponent as Search } from './Search.svg';
export { ReactComponent as Send } from './Send.svg';
export { ReactComponent as Send1 } from './Send1.svg';
export { ReactComponent as Send2 } from './Send2.svg';
export { ReactComponent as Settings } from './Settings.svg';
export { ReactComponent as Shield } from './Shield.svg';
export { ReactComponent as Sidebar } from './Sidebar.svg';
export { ReactComponent as Smile } from './Smile.svg';
export { ReactComponent as Star } from './Star.svg';
export { ReactComponent as StarCircle } from './StarCircle.svg';
export { ReactComponent as Stop } from './Stop.svg';
export { ReactComponent as Success } from './Success.svg';
export { ReactComponent as SumSigma } from './SumSigma.svg';
export { ReactComponent as Task } from './Task.svg';
export { ReactComponent as TaskAdd } from './TaskAdd.svg';
export { ReactComponent as TaskNew } from './TaskNew.svg';
export { ReactComponent as Tasks } from './Tasks.svg';
export { ReactComponent as Telegram } from './Telegram.svg';
export { ReactComponent as Telegram2 } from './Telegram2.svg';
export { ReactComponent as Timers } from './Timers.svg';
export { ReactComponent as Trash } from './Trash.svg';
export { ReactComponent as Typing } from './Typing.svg';
export { ReactComponent as Upload } from './Upload.svg';
export { ReactComponent as User1 } from './User1.svg';
export { ReactComponent as UserBlock } from './UserBlock.svg';
export { ReactComponent as UserCheck } from './UserCheck.svg';
export { ReactComponent as UserGroup } from './UserGroup.svg';
export { ReactComponent as UserTask } from './UserTask.svg';
export { ReactComponent as UserUnBlock } from './UserUnBlock.svg';
export { ReactComponent as Bold } from './Bold.svg';
export { ReactComponent as Italic } from './Italic.svg';
export { ReactComponent as Underline } from './Underline.svg';
export { ReactComponent as Unread } from './Unread.svg';
export { ReactComponent as Video } from './Video.svg';
export { ReactComponent as View } from './View.svg';
export { ReactComponent as ViewOff } from './ViewOff.svg';
export { ReactComponent as Whatsapp } from './Whatsapp.svg';
export { ReactComponent as Wizard1 } from './Wizard1.svg';
export { ReactComponent as WorkedTask } from './WorkedTask.svg';
