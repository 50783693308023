import { reIPv4 } from './ipv4';

// https://github.com/sindresorhus/ip-regex

const v4 = reIPv4.source;
const v6seg = '[a-fA-F\\d]{1,4}';

export const reIPv6 = new RegExp(
  `(?:${[
    // 1:2:3:4:5:6:7::  1:2:3:4:5:6:7:8
    `(?:${v6seg}:){7}(?:${v6seg}|:)`,

    // 1:2:3:4:5:6::    1:2:3:4:5:6::8   1:2:3:4:5:6::8  1:2:3:4:5:6::1.2.3.4
    `(?:${v6seg}:){6}(?:${v4}|:${v6seg}|:)`,

    // 1:2:3:4:5::      1:2:3:4:5::7:8   1:2:3:4:5::8    1:2:3:4:5::7:1.2.3.4
    `(?:${v6seg}:){5}(?::${v4}|(?::${v6seg}){1,2}|:)`,

    // 1:2:3:4::        1:2:3:4::6:7:8   1:2:3:4::8      1:2:3:4::6:7:1.2.3.4
    `(?:${v6seg}:){4}(?:(?::${v6seg}){0,1}:${v4}|(?::${v6seg}){1,3}|:)`,

    // 1:2:3::          1:2:3::5:6:7:8   1:2:3::8        1:2:3::5:6:7:1.2.3.4
    `(?:${v6seg}:){3}(?:(?::${v6seg}){0,2}:${v4}|(?::${v6seg}){1,4}|:)`,

    // 1:2::            1:2::4:5:6:7:8   1:2::8          1:2::4:5:6:7:1.2.3.4
    `(?:${v6seg}:){2}(?:(?::${v6seg}){0,3}:${v4}|(?::${v6seg}){1,5}|:)`,

    // 1::              1::3:4:5:6:7:8   1::8            1::3:4:5:6:7:1.2.3.4
    `(?:${v6seg}:){1}(?:(?::${v6seg}){0,4}:${v4}|(?::${v6seg}){1,6}|:)`,

    // ::2:3:4:5:6:7:8  ::2:3:4:5:6:7:8  ::8             ::1.2.3.4
    `(?::(?:(?::${v6seg}){0,5}:${v4}|(?::${v6seg}){1,7}|:))`,
  ].join('|')})`,
);
