/**
 * A not-undefined predicate
 *
 * ```ts
 * const input = ['a', 'b', null, undefined];
 * //    ^^^^^ (string | null | undefined)[]
 *
 * const output = input.filter(isDefined);
 * //    ^^^^^^ (string | null)[]
 * ```
 *
 * @param value
 */
export const isDefined = <T>(value: T | undefined): value is T => undefined !== value;
