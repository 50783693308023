import { FC, Suspense } from 'react';

import cl from './CustomerMultiSelectModal.module.scss';
import { LoadingPage } from 'components/page';
import { Dialog, reactModal, RenderPromptProps } from 'ui/feedback';
import { useGate, useStore } from 'effector-react';
import { Gate, modalSubmitted } from './model';
import { TaxbookPanel } from 'routes/main/routes/client/components/TaxbookPanel';
import { $selectedSet, CustomerList } from '../CustomerList';
import { useTranslation } from 'react-i18next';
import { Button2 } from 'ui/button';
import { CustomerId } from 'models/customer/types';

interface Props extends RenderPromptProps<any> {
  customerIds?: readonly CustomerId[] | null;
}

export const CustomerMultiSelectModal: FC<Props> = ({ show, customerIds, onDismiss, onSubmit }) => {
  const { t } = useTranslation('customers');
  useGate(Gate, { customerIds });
  const selectedSet = useStore($selectedSet);

  return (
    <Dialog visible={show} onClose={onDismiss} className={cl.root}>
      <div className={cl.title}>{t('ui:customerMultiselect.title')}</div>
      <div className={cl.content}>
        <div className={cl.left}>
          <div className={cl.blockTitle}>
            {t('ui:customerMultiselect.customerList', { qty: selectedSet.size })}
          </div>
          <CustomerList />
        </div>
        <div className={cl.right}>
          <div className={cl.blockTitle}>{t('ui:customerMultiselect.params')}</div>
          <TaxbookPanel compactMode />
        </div>
      </div>
      <div className={cl.footer}>
        <Button2 type="button" uiColor="secondary" onClick={onDismiss} size="small">
          {t('ui:cancel')}
        </Button2>
        <Button2 size="small" onClick={onSubmit}>
          {t('ui:ok')}
        </Button2>
      </div>
    </Dialog>
  );
};

export const promptCustomerMultiSelectModal = (customersIds?: readonly CustomerId[] | null) =>
  reactModal(({ onSubmit, ...props }) => {
    const handleSubmit = () => {
      modalSubmitted();
      onSubmit();
    };
    return (
      <Suspense fallback={<LoadingPage cover />}>
        <CustomerMultiSelectModal {...props} onSubmit={handleSubmit} customerIds={customersIds} />
      </Suspense>
    );
  });
