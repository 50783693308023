import { hasProperty } from 'utils/object';

export interface CentMessageDataV2<T extends string, D = unknown> {
  type: T;
  data: D;
}

export const isCentMessageDataV2 = (data: unknown): data is CentMessageDataV2<string> =>
  hasProperty(data, 'type') &&
  //hasProperty(data, 'data') &&
  typeof data.type === 'string';
