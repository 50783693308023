import { combine, createEffect, createEvent, createStore, sample } from 'effector';
import api from 'api/request/misc';
import { Currency, CurrencyCode } from './types';

export const getCurrencies = createEvent();
const getCurrenciesFx = createEffect(api.currency);
export const $currencies = createStore<readonly Currency[]>([]);
export const $currenciesMap = $currencies.map<ReadonlyMap<CurrencyCode, Currency>>(
  (list) => new Map(list.map((c) => [c.alphabetic_code, c])),
);

sample({
  source: getCurrencies,
  filter: combine(
    getCurrenciesFx.pending.map((b) => !b),
    $currencies.map((l) => !l.length),
    (...bs) => bs.every(Boolean),
  ),
  target: getCurrenciesFx,
});

sample({
  source: getCurrenciesFx.doneData.map(({ data }) => data),
  target: $currencies,
});
