import {
  $countries,
  $countryCityList,
  fetchCountriesIfNeeded,
  fetchCountryCityList,
  getCountriesFx,
  getCountryCityListFx,
  resetCountryCityList,
} from './index';
import api from 'api/request/country';
import { combine, guard, sample } from 'effector';
import { CountryId } from 'models/country/types';

getCountriesFx.use(async () => await api.list2());

$countries.on(getCountriesFx.doneData, (_, { data }) => data);

guard({
  source: sample({ source: $countries, clock: fetchCountriesIfNeeded }),
  filter: combine(
    getCountriesFx.pending.map((p) => !p),
    $countries.map((state) => state === null),
    (a, b) => a && b,
  ),
  target: getCountriesFx,
});

getCountryCityListFx.use(async (countryId: CountryId) => await api.countryCityList(countryId));
sample({
  clock: fetchCountryCityList,
  target: getCountryCityListFx,
});
$countryCityList.reset(resetCountryCityList);
$countryCityList.on(getCountryCityListFx.doneData, (_, { data }) => data);
