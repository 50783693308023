import { ChangeEvent, FC, InputHTMLAttributes, useCallback, useMemo, useState } from 'react';
import cl from './Upload.module.scss';
import cn from 'classnames';

export interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'accept'> {
  accept?: string[] | string;
  onChange?: (file: File[]) => void;
  /**
   * Когда `true`, `<input/>` будет перемонтирован после каждого выбора файла(-ов),
   * чтобы сбросить текущий выбор. Так, при выборе одного и того же файла подряд
   * событие будет вызываться каждый раз, а не только впервые для этого файла.
   */
  createMode?: boolean;
}

const Upload: FC<Props> = ({ accept, onChange, createMode = false, className, ...rest }) => {
  const acceptList = useMemo(
    () => (accept && Array.isArray(accept) ? accept.join(',') : accept),
    [accept],
  );

  const [instance, setInstance] = useState(0);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.target;
      if (!files) return;

      onChange?.(Array.from(files));
      if (createMode) {
        setInstance((n) => n + 1);
      }
    },
    [onChange, createMode],
  );

  return (
    <input
      {...rest}
      key={instance}
      type="file"
      onChange={handleChange}
      title=""
      accept={acceptList}
      className={cn(cl.root, className)}
    />
  );
};

export default Upload;
