import cn from 'classnames';
import { CSSProperties, FC, ReactHTML, ReactNode } from 'react';
import cl from './Field.module.scss';
import Icon from '../../Icon';

// TODO: Довольно сложная структура, нестабильная в зависимости от разных опций.
//  Когда надо снаружи что-то добавить в эту структуру, это не всегда
//  тривиально. Приходится пихать кучу классов в каждый элемент, когда тебе надо
//  было поменять только что-то одно.
//  Не все опции дружат на 100% друг с другом.
//  Надо сделать новый компонент, сделать его более стабильным и предсказуемым,
//  вывести все элементы линейно без лишней вложенности и без условной
//  вложенности. Назначить всем элементам `grid-area: <NAME>;`, применить grid.

type LabelPosition = 'top' | 'left';

interface Props {
  label?: string | ReactNode;
  // перед required "*"
  labelStart?: ReactNode;
  required?: boolean;
  error?: ReactNode;
  helper?: ReactNode;
  className?: string;
  classNameLabel?: string;
  classNameControl?: string;
  classNameHelp?: string;
  classNameError?: string;
  labelPosition?: LabelPosition;
  withMargin?: boolean;
  /** контрол который мы оборачиваем не является <input>-подобным, поэтому во избежание проблем верстки
   * не надо помещать его внутрь label.
   * А вообще это компонент надо давно переделать */
  notInput?: boolean;
  labelElement?: keyof ReactHTML;
  styles?: CSSProperties;
  helpIconText?: string;
}

const labelPositionClasses: Partial<Record<LabelPosition, string>> = {
  top: cl.labelTop,
  left: cl.labelLeft,
};

export const Field: FC<Props> = ({
  label,
  labelStart,
  required = false,
  error,
  helper,
  children,
  className,
  classNameControl,
  classNameHelp,
  classNameError,
  classNameLabel,
  labelPosition = 'top',
  withMargin = true,
  notInput = false,
  labelElement: LabelElement = 'label',
  styles,
  helpIconText,
}) => {
  const Control = (
    <div className={cn(cl.control, helpIconText && cl.withHelpIcon, classNameControl)}>
      {children}
      {!!helpIconText && <Icon type="Help2" className={cl.helpIcon} data-tip={helpIconText} />}
    </div>
  );

  return (
    <div className={cn(cl.root, className, !!error && cl.isError)} style={styles}>
      {label || labelStart ? (
        <>
          <LabelElement
            className={cn(
              cl.label,
              notInput && cl.notInput,
              classNameLabel,
              labelPositionClasses[labelPosition],
            )}
          >
            {labelStart}
            {required && <span className={cl.requiredMark}>* </span>}
            {label}
            {notInput || Control}
          </LabelElement>
          {notInput && Control}
        </>
      ) : (
        <>{Control}</>
      )}
      {/*{withMargin && <div className={cn(cl.error, classNameError)}>{error}</div>}*/}
      {!!helper && <div className={cn(cl.helper, classNameHelp)}>{helper}</div>}
      {withMargin && <FieldError className={classNameError}>{error}</FieldError>}
    </div>
  );
};

interface FieldErrorProps {
  className?: string;
}

export const FieldError: FC<FieldErrorProps> = ({ className, children }) => {
  return <div className={cn(cl.error, className)}>{children}</div>;
};
