import { FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { HTMLInputElementLike } from './types';

export interface InputDebounceOptions<
  V,
  I,
  Focus = HTMLInputElementLike<I>,
  CA extends any[] = [],
> {
  value?: V;
  onChange?: (value: V, ...args: CA) => void;
  onChangeEnd?: (value: V) => void;
  onBlur?: FocusEventHandler<Focus>;
  debounceTimeout?: number;
}

/**
 * triggers `onChangeEnd` with debounce when typing finished and right with
 * `onBlur`.
 */
export const useInputDebounce = <V, I, Focus = HTMLInputElementLike<I>, CA extends any[] = []>({
  value,
  onChange,
  onChangeEnd,
  onBlur,
  debounceTimeout = 750,
}: InputDebounceOptions<V, I, Focus, CA>) => {
  const latestV = useRef<V>();
  useEffect(() => {
    if (value !== undefined) {
      latestV.current = undefined;
    }
  }, [value]);

  const refChangeEnd = useRef(onChangeEnd);
  useEffect(() => {
    refChangeEnd.current = onChangeEnd;
  }, [onChangeEnd]);

  const flushRef = useRef(() => {
    const v = latestV.current;
    if (v !== undefined) {
      refChangeEnd.current?.(v);
      latestV.current = undefined;
    }
  });

  const [T, setT] = useState<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (T) {
      return () => {
        clearTimeout(T);
      };
    }
  }, [T]);

  const handleChange = useCallback(
    (value: V, ...args: CA) => {
      latestV.current = value;
      // if (debounceTimeout > 0) {
      setT(setTimeout(flushRef.current, debounceTimeout));
      // }
      // or fire it first and check `e.isDefaultPrevented()` and/or
      // `e.isPropagationStopped()`? Ignore cancelled input.
      onChange?.(value, ...args);
    },
    [onChange, debounceTimeout],
  );

  const handleBlur = useCallback<FocusEventHandler<Focus>>(
    (e) => {
      setT(null);
      flushRef.current();
      onBlur?.(e);
    },
    [onBlur],
  );

  return {
    value: latestV.current !== undefined ? latestV.current : value,
    onChange: handleChange,
    onBlur: handleBlur,
  } as const;
};
