import cn from 'classnames';
import { FC, ReactNode } from 'react';
import img from 'assets/img/createImg2.svg';
import Icon from 'ui/Icon';
import cl from './InfoBlock.module.scss';

interface Props {
  text: ReactNode;
  textClassName?: string;
}

export const InfoBlock: FC<Props> = ({ text, textClassName }) => {
  return (
    <div className={cl.root}>
      <div className={cl.textBlock}>
        <Icon type="Alert" className={cl.ico} />
        <div className={cn(cl.text, textClassName)}>{text}</div>
      </div>
      <img alt="" src={img} />
    </div>
  );
};
