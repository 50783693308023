export const enum WhatsAppPaymentState {
  /** оплачено достаточно надолго */
  PAID_FAR = 'PAID_FAR',
  /** оплачено, но срок подходит к концу */
  PAID_CLOSE = 'PAID_CLOSE',
  /** оплаченный период закончился недавно */
  EXPIRED_CLOSE = 'EXPIRED_CLOSE',
  /** оплаченный период закончился давно, ну т.е. считай, что не было */
  EXPIRED_FAR = 'EXPIRED_FAR',
}

const importantStatus = new Set([
  WhatsAppPaymentState.EXPIRED_CLOSE,
  WhatsAppPaymentState.PAID_CLOSE,
  WhatsAppPaymentState.EXPIRED_FAR,
]);
export const isImportant = (s: WhatsAppPaymentState) => importantStatus.has(s);

const WARN_ORDER: Partial<Record<WhatsAppPaymentState, number>> = {
  [WhatsAppPaymentState.EXPIRED_CLOSE]: 20,
  [WhatsAppPaymentState.PAID_CLOSE]: 10,
  [WhatsAppPaymentState.EXPIRED_FAR]: 5,
};

export type WhatsAppPaymentStatus =
  | readonly [state: WhatsAppPaymentState.PAID_FAR | WhatsAppPaymentState.PAID_CLOSE, until: Date]
  | readonly [state: WhatsAppPaymentState.EXPIRED_CLOSE, willDeleteAfter: Date]
  | readonly [state: WhatsAppPaymentState.EXPIRED_FAR, deletedAfter: Date | null];

export const cmpStatus = (
  [aState, aDate]: WhatsAppPaymentStatus,
  [bState, bDate]: WhatsAppPaymentStatus,
) =>
  // сначала по убыванию уровня важности
  (WARN_ORDER[bState] ?? 0) - (WARN_ORDER[aState] ?? 0) ||
  // затем по возрастанию даты
  (aDate ? aDate.getTime() : 0) - (bDate ? bDate.getTime() : 0);

// Как в анекдоте: "Нэт... Не прикотиллось..."
//
// export const sortPaymentStatuses = (list: readonly WhatsAppPaymentStatus[]) =>
//   [...list].sort(cmpStatus);
//
// export const getMostImportantStatus = (list: readonly WhatsAppPaymentStatus[]) =>
//   list.reduce<WhatsAppPaymentStatus | null>(
//     (max, o) => (max && cmpStatus(max, o) <= 0 ? max : o),
//     null,
//   );
