import { authorize, setLanguage, unauthorize } from './instance';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  authorize,
  unauthorize,
  setLanguage,
};

export { authorize, unauthorize, setLanguage };
