import { createEvent, createStore } from 'effector';
import { useStoreMap } from 'effector-react';
import { EMPTY_ARRAY } from 'constants/utils';

interface BaseEvent<T extends string, D = unknown> {
  type: T;
  data: D;
}

// или здесь надо было сделать ui|action типы,
// вроде "предложение-обновить-страницу(причина, строгость)"

type FrontUpgrade = BaseEvent<'frontUpgrade', Date>;

export type AnyEvent = FrontUpgrade;

//const uniqueEvent = (type: string): boolean => true;

export const addEvent = createEvent<AnyEvent>();
const removeEvent = createEvent<AnyEvent>();

const $events = createStore<readonly AnyEvent[]>(EMPTY_ARRAY)
  .on(addEvent, (list, v) => {
    //if (uniqueEvent(v.type)) {
    return [v, ...list.filter((c) => c.type !== v.type)];
    //}
    // return [v, ...list];
  })
  .on(removeEvent, (list, v) => list.filter((c) => c !== v));

export const useEvents = () =>
  useStoreMap({
    store: $events,
    keys: EMPTY_ARRAY,
    fn: (list) =>
      list.map((event) => ({
        event,
        onRemove: () => {
          removeEvent(event);
        },
      })),
  });
