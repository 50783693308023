import { createEvent, createStore } from 'effector';

interface Options {
  debugPrefix?: string;
}

export const instancesCounters = <T extends keyof any>({ debugPrefix }: Options = {}) => {
  // Gate чисто ради событий open, close тоже работал, но уж как-то кривовато
  // выглядит, ибо у него идея другая.
  //
  // Да, потому что `Gate<primitive>` вообще криво работает, как выяснилось
  // позже. И одновременное открытие нескольких инстансов одного гейта, вроде,
  // даёт кривые состояния `state` и `status`, но это по памяти, не точно.
  // В общем, не надо переделывать на Gate, даже если очень хочется.
  //
  // А вот на `watch` - было удобнее. Вместо
  //
  //     useEffect(() => {
  //       addInstance(id);
  //       return () => {
  //         removeInstance(id);
  //       };
  //     }, [id]);
  //
  // было бы
  //
  //     useEffect(() => watchInstance(id), [id]);
  //
  const addInstance = createEvent<T>();
  const removeInstance = createEvent<T>();

  const $instances = createStore<ReadonlyMap<T, number>>(new Map());
  $instances
    .on(addInstance, (map, id) => new Map(map).set(id, (map.get(id) ?? 0) + 1))
    .on(removeInstance, (map, id) => new Map(map).set(id, (map.get(id) ?? 0) - 1));

  if (process.env.NODE_ENV === 'development' && debugPrefix !== undefined) {
    $instances.watch((map) => console.log(debugPrefix, '$instances', map));
  }

  const $wantedNames = $instances.map<ReadonlySet<T>>((map, prevSet) => {
    const nextSet = new Set([...map.entries()].filter(([, count]) => count > 0).map(([id]) => id));
    if (prevSet && prevSet.size === nextSet.size && [...prevSet].every((id) => nextSet.has(id))) {
      return prevSet;
    }
    return nextSet;
  });

  return {
    addInstance,
    removeInstance,
    /**
     * ```ts
     * useEffect(() => watchInstance(id), [id]);
     * ```
     *
     * @param id
     */
    watchInstance: (id: T | null | undefined) => {
      if (id) {
        addInstance(id);
        return () => {
          removeInstance(id);
        };
      }
    },
    $wantedNames,
  };
};
