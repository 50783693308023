import { createEffect, createEvent, createStore } from 'effector';
import { City, Country, CountryId } from './types';
import { ApiResponse, ApiResponse2 } from 'api/types';

export const $countries = createStore<Country[] | null>(null);
export const getCountriesFx = createEffect<void, ApiResponse<Country[]>>();
export const fetchCountriesIfNeeded = createEvent();

export const $regionCountryMap = $countries.map((countries) =>
  countries ? new Map(countries.map((c) => [c.iso_code, c])) : countries,
);

export const resetCountryCityList = createEvent();
export const fetchCountryCityList = createEvent<CountryId>();
export const getCountryCityListFx = createEffect<CountryId, ApiResponse2<City[]>>();
export const $countryCityList = createStore<City[]>([]);
