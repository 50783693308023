import cn from 'classnames';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import cl from './Panel.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Panel = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ className, children, ...tail }, ref) => (
    <div ref={ref} {...tail} className={cn(cl.root, className)}>
      {children}
    </div>
  ),
);
