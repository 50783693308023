import { createStore, Effect, Store } from 'effector';
import { EMPTY_SET } from 'constants/utils';
import isequal from 'lodash.isequal';

/**
 * Создаёт и возвращает стор, содержащий ID выполняющихся эффектов
 *
 * ```tx
 * const fetchFx = createEffect<ID, _>(apiList);
 * const $pendingMap = pendingMap(fetchFx);
 * // => Store<ReadonlySet<ID>>
 *
 * const fetchFx = createEffect<Params, _>(apiList);
 * const $pendingMap = pendingMap(fetchFx, (params) => params.id);
 * const $pendingMap = pendingMap(fetchFx, (params) => new Set(params.ids));
 * // => Store<ReadonlySet<ID>>
 * ```
 */
export function pendingMap<P, K>(
  fx: Effect<P, any>,
  getKey: (params: P) => K | ReadonlySet<K>,
): Store<ReadonlySet<K>>;

/**
 * Создаёт и возвращает стор, содержащий ID выполняющихся эффектов
 *
 * ```tx
 * const fetchFx = createEffect<ID, _>(apiList);
 * const $pendingMap = pendingMap(fetchFx);
 * // => Store<ReadonlySet<ID>>
 *
 * const fetchFx = createEffect<Params, _>(apiList);
 * const $pendingMap = pendingMap(fetchFx, (params) => params.id);
 * const $pendingMap = pendingMap(fetchFx, (params) => new Set(params.ids));
 * // => Store<ReadonlySet<ID>>
 * ```
 */
export function pendingMap<K>(fx: Effect<K, any>): Store<ReadonlySet<K>>;

/**
 * Создаёт и возвращает стор, содержащий ID выполняющихся эффектов
 *
 * ```tx
 * const fetchFx = createEffect<ID, _>(apiList);
 * const $pendingMap = pendingMap(fetchFx);
 * // => Store<ReadonlySet<ID>>
 *
 * const fetchFx = createEffect<Params, _>(apiList);
 * const $pendingMap = pendingMap(fetchFx, (params) => params.id);
 * const $pendingMap = pendingMap(fetchFx, (params) => new Set(params.ids));
 * // => Store<ReadonlySet<ID>>
 * ```
 */
export function pendingMap<P, K>(fx: Effect<P, any>, getKey?: (params: P) => K | ReadonlySet<K>) {
  const paramsToKeys: (p: P) => ReadonlySet<K> = getKey
    ? (p) => {
        const k = getKey(p);
        if (isSet(k)) return k;
        return new Set([k]);
      }
    : (p) => new Set([p as any as K]);

  return createStore<ReadonlyMap<K, number>>(new Map())
    .on(fx.map(paramsToKeys), (map, ids) => {
      if (!ids.size) return map;
      const m = new Map(map);
      ids.forEach((id) => {
        m.set(id, (m.get(id) ?? 0) + 1);
      });
      return m;
    })
    .on(
      fx.finally.map(({ params }) => paramsToKeys(params)),
      (map, ids) => {
        if (!ids.size) return map;
        const m = new Map(map);
        ids.forEach((id) => {
          const prev = map.get(id);
          if (prev === 1) {
            m.delete(id);
          } else {
            m.set(id, (prev ?? 0) - 1);
          }
        });
        return m;
      },
    )
    .map((map, prev: ReadonlySet<K> = EMPTY_SET): ReadonlySet<K> => {
      const next = new Set(map.keys());
      if (next.size === 0) {
        return EMPTY_SET;
      }
      return isequal(prev, next) ? prev : next;
    });
}

const isSet = (p: unknown): p is Set<any> | ReadonlySet<any> => p instanceof Set;
