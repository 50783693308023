import { FC } from 'react';
import cl from './Progress.module.scss';
import cn from 'classnames';

interface Props {
  total: number;
  completed?: number;
  className?: string;
}

export const Progress: FC<Props> = ({ total, completed = 0, className }) => {
  const percent = total ? Math.round((completed / total) * 100) : 0;
  return (
    <div className={cn(cl.root, className)}>
      <div className={cl.barWrapper}>
        <div className={cl.completedTitle}>
          {completed}/{total}
        </div>
        <div className={cl.bar}>
          <span style={{ width: `${percent}%` }} />
        </div>
      </div>
      <div className={cl.percent}>{percent}%</div>
    </div>
  );
};
