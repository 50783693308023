/**
 * @see https://tc39.es/ecma262/#sec-toboolean
 */
type Falsy = false | null | undefined | '' | 0 /*| 0n*/;

interface BoolNot {
  (value: Falsy): true;

  (value: any): value is Falsy;
}

const boolNot = (value: any): value is Falsy => !value;

export default boolNot as BoolNot;
