import { AxiosInstance } from 'axios';
import { buildAcceptLanguage } from './buildAcceptLanguage';

export interface IAxiosInstancePool {
  readonly size: number;

  add<T extends AxiosInstance>(instance: T): T;

  authorize(accessToken: string): void;

  unauthorize(): void;

  setLanguage(language?: string): void;
}

export const createPool = (): IAxiosInstancePool => new AxiosInstancePool();

export class AxiosInstancePool implements IAxiosInstancePool {
  private instances: AxiosInstance[] = [];

  get size() {
    return this.instances.length;
  }

  add = <T extends AxiosInstance>(instance: T): T => {
    this.instances.push(instance);
    return instance;
  };

  authorize = (accessToken: string): void => {
    this.instances.forEach((instance) => {
      instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    });
  };

  unauthorize = (): void => {
    this.instances.forEach((instance) => {
      delete instance.defaults.headers.common['Authorization'];
    });
  };

  setLanguage = (language?: string): void => {
    this.instances.forEach((instance) => {
      const value = buildAcceptLanguage(language);
      // console.log('setLanguage', value);
      if (undefined === value) {
        delete instance.defaults.headers.common['Accept-Language'];
      } else {
        instance.defaults.headers.common['Accept-Language'] = value;
      }
    });
  };
}
