import { FC, memo } from 'react';
import match, { Matches, Options } from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

//import cl from './HighlightedString.module.scss';

interface MatchesProps {
  text: string;
  matches?: Matches | null;
}

const HighlightedMatchesRender: FC<MatchesProps> = ({ text, matches }) => (
  <>
    {matches?.length
      ? parse(text, matches).map((part, idx) =>
          part.highlight ? <span key={idx}>{part.text}</span> : part.text,
        )
      : text}
  </>
);

/** Wraps searched parts of string in a <span> */
interface Props extends Options {
  text: string;
  searchedText: string;
}

const HighlightedStringRender: FC<Props> = ({
  text,
  searchedText,
  children,
  insideWords = true,
  ...options
}) => (
  <HighlightedMatchesRender
    text={text}
    matches={match(text, searchedText, { ...options, insideWords })}
  />
);

export const HighlightedString = memo(HighlightedStringRender);
export const HighlightedMatches = memo(HighlightedMatchesRender);
