import { forwardRef } from 'react';
import { Input, InputProps } from '../Input';
import { useInputChangeHandler } from '../valueInput';
import { defaultAttrs, UrlInputOptions, useUrlInput } from './useUrlInput';

interface Props extends UrlInputOptions<HTMLInputElement>, Omit<InputProps, 'value' | 'onChange'> {}

export const UrlInput = forwardRef<HTMLInputElement, Props>(
  ({ allowMailto, onBlur, onChange, onFocus, triggerEveryChange, value, ...rest }, ref) => {
    const attrs = useUrlInput({
      allowMailto,
      value,
      onChange,
      onBlur,
      onFocus,
      triggerEveryChange,
    });
    return (
      <Input
        ref={ref}
        {...defaultAttrs({ allowMailto })}
        {...rest}
        {...attrs}
        onChange={useInputChangeHandler(attrs.onChange)}
        type="url"
        inputMode="url"
      />
    );
  },
);
