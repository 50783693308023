import cn from 'classnames';
import { TFunction, TFunctionResult } from 'i18next';
import {
  ComponentType,
  DragEvent,
  ReactElement,
  ReactHTML,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EMPTY_ARRAY } from 'constants/utils';
import { ButtonDropdown, ButtonDropdownItem } from '../../button';
import { Badge } from '../../display/Badge';
import Icon, { IconRef } from '../../Icon';
import { LinkProps } from '../../types';
import { useElementClientSize } from '../../utils/useElementClientSize';
import cl from './Tabs.module.scss';

export type TabKey = string | number;

export interface TabProps<T extends TabKey = TabKey> extends LinkProps {
  icon?: IconRef;
  // не ReactNode, потому что там тоже есть `typeof v === 'function'`
  label?: ReactElement | ((t: TFunction) => TFunctionResult);
  value: T;
  linkReplace?: boolean;
  withBadge?: boolean;
  qty?: number;
}

type Skip = boolean | null | undefined;
export type TabPropsOptional<K extends TabKey = TabKey> = TabProps<K> | Skip;
export const isTabProps = <K extends TabKey>(tab: TabPropsOptional<K>): tab is TabProps<K> =>
  typeof tab === 'object' && tab !== null;
export const filterTabsProps = <K extends TabKey>(
  tabs: readonly TabPropsOptional<K>[],
): TabProps<K>[] => tabs.filter(isTabProps);

interface Props<K extends TabKey> {
  className?: string;
  tabsClassName?: string;
  tabs: readonly TabProps<K>[];
  onChange?: (value: K) => void;
  onChangeOrder?: (keys: K[]) => void;
  current: K;
}

const getTabElement = (e: DragEvent) =>
  (e.target as HTMLElement).closest(`.${cl.tab}`) as HTMLElement;

export const Tabs = <T extends TabKey>({
  className,
  tabsClassName,
  tabs,
  onChange,
  onChangeOrder,
  current,
}: Props<T>) => {
  const { t } = useTranslation();
  const draggable = Boolean(onChangeOrder);

  const [_tabs, setTabs] = useState(tabs);
  const [draggedTab, setDraggedTab] = useState<T | null>(null);

  useEffect(() => {
    setTabs(tabs);
  }, [tabs]);

  const refCont = useRef<HTMLDivElement>(null);
  const refTabs = useRef<HTMLDivElement>(null);
  const refMore = useRef<HTMLDivElement>(null);
  const [contW] = useElementClientSize(refCont);
  const [moreW] = useElementClientSize(refMore);

  const [cutIndex, setCutIndex] = useState(-1);
  useEffect(() => {
    const parent = refTabs.current;
    if (!parent || !contW || !moreW) {
      return;
    }
    let cutIndex = -1;
    const L = parent.childElementCount;
    for (const [i, node] of Array.from(parent.childNodes).entries()) {
      const availWidth = i === L - 1 ? contW : contW - moreW;
      const { offsetLeft, offsetWidth } = node as HTMLElement;
      if (offsetLeft + offsetWidth > availWidth + 5) {
        cutIndex = i;
        break;
      }
    }
    setCutIndex(cutIndex);
  }, [contW, moreW, tabs, current, t, className]);

  const cutItems = useMemo(
    () => (cutIndex >= 0 ? tabs.slice(cutIndex) : EMPTY_ARRAY),
    [cutIndex, tabs],
  );

  // Написал на коленке Драг и Дроп, некрасиво, но пока работает
  const handleDragStart = (e: DragEvent, value: T) => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.dropEffect = 'move';

    setDraggedTab(value);
  };

  const handleDragOver = (e: DragEvent, value: T, idx: number) => {
    e.preventDefault();
    if (value === draggedTab) {
      return;
    }
    getTabElement(e).classList.add(cl.dragover);
    if (_tabs.findIndex((tab) => draggedTab === tab.value) < idx) {
      getTabElement(e).classList.add(cl.dragRight);
    }
  };

  const handleDragEndAndDragLeave = useCallback((e: DragEvent) => {
    getTabElement(e).classList.remove(cl.dragover, cl.dragRight);
  }, []);

  const handleDrop = (e: DragEvent, value: T) => {
    e.preventDefault();
    getTabElement(e).classList.remove(cl.dragover, cl.dragRight);
    if (value === draggedTab) {
      return;
    }
    let draggedIdx = 0;
    let targetIdx = 0;
    _tabs.forEach((tab, i) => {
      if (draggedTab === tab.value) {
        draggedIdx = i;
      }
      if (value === tab.value) {
        targetIdx = i;
      }
    });
    const newTabs = [...tabs];
    newTabs.splice(draggedIdx, 1);
    newTabs.splice(targetIdx, 0, tabs[draggedIdx]);

    setTabs(newTabs);
    onChangeOrder?.(newTabs.map((tab) => tab.value));
  };

  return (
    <div className={cn(cl.root, className)}>
      <div ref={refCont} className={cl.container}>
        <div ref={refTabs} className={cn(cl.tabs, tabsClassName)}>
          {_tabs.map(
            (
              { value, icon, label, href, linkReplace = false, withBadge, qty = 0, ...rest },
              idx,
            ) => {
              let E: ComponentType<any> | keyof ReactHTML;
              let attrs: object | undefined;
              [E, attrs] = href ? [Link, { to: href, replace: linkReplace }] : ['div'];
              const text = typeof label === 'function' ? label(t) : label || value;

              return (
                <E
                  {...rest}
                  {...attrs}
                  key={value}
                  draggable={draggable}
                  className={cn(cl.tab, value === current && cl.active, withBadge && cl.withBadge)}
                  onClick={onChange && (() => onChange(value))}
                  onDragStart={draggable ? (e) => handleDragStart(e, value) : undefined}
                  onDragOver={draggable ? (e) => handleDragOver(e, value, idx) : undefined}
                  onDragEnd={draggable ? handleDragEndAndDragLeave : undefined}
                  onDragLeave={draggable ? handleDragEndAndDragLeave : undefined}
                  onDrop={draggable ? (e) => handleDrop(e, value) : undefined}
                >
                  {icon && <Icon type={icon} className={cn(cl.icon, cl._withText)} />}
                  {text} {qty > 0 && <Badge qty={qty} className={cl.qty} />}
                  {/* Все :before, :after разобраны. Пришлось использовать дополнительный элемент для плейсхолдера при перетаскивании */}
                  {/*{draggable && <div className={cl.dragPlaceholder}></div>}*/}
                </E>
              );
            },
          )}
        </div>
        <div ref={refMore} className={cn(cl.more, cutIndex >= 0 || cl._notCut)}>
          <ButtonDropdown
            buttonIcon="DotsVertical"
            buttonSize="small"
            buttonIconRotate={false}
            buttonUiColor={cutItems.some((i) => i.value === current) ? undefined : 'secondary'}
            buttonClassName={cn(cl.moreTrigger)}
            popupPlacement="bottom-end"
            items={useMemo(
              () =>
                cutItems.map<ButtonDropdownItem>(({ value, icon, label, qty = 0, ...rest }) => ({
                  ...rest,
                  label: (
                    <>
                      {typeof label === 'function' ? label(t) : label || value}{' '}
                      {qty > 0 && <Badge qty={qty} />}
                    </>
                  ),
                  icon:
                    value === current
                      ? { type: 'icon', icon: 'Check' }
                      : icon
                        ? { type: 'icon', icon }
                        : undefined,
                })),
              [cutItems, current, t],
            )}
          />
        </div>
      </div>
    </div>
  );
};
