import cn from 'classnames';
import { FC, MouseEvent, ReactNode, useMemo } from 'react';
import { TextButton2 } from '../../button';
import Icon, { IconRef } from '../../Icon';
import IconContainer from '../../Icon/IconContainer';
import { IView } from '../../types';
import cl from './TagItem.module.scss';

interface Props extends IView {
  icon?: IconRef;
  label?: ReactNode;
  onRemove?: () => void;
}

export const TagItem: FC<Props> = ({ icon, label, onRemove, className, ...rest }) => {
  const handleRemove = useMemo(
    () =>
      onRemove &&
      ((e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onRemove();
      }),
    [onRemove],
  );

  return (
    <span {...rest} className={cn(cl.root, className)}>
      {icon && (
        <IconContainer className={cl.icon}>
          <Icon type={icon} />
        </IconContainer>
      )}
      {label !== undefined && <span className={cl.label}>{label}</span>}
      {handleRemove && (
        <TextButton2 size="small" icon="Cross" onClick={handleRemove} className={cl.remove} />
      )}
    </span>
  );
};
