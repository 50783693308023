import { $layout, closeLeftSide, toggleOpenLeftSide } from './index';

$layout
  .on(toggleOpenLeftSide, (state) => ({
    ...state,
    openLeftSide: !state.openLeftSide,
  }))
  .on(closeLeftSide, (state) => ({
    ...state,
    openLeftSide: false,
  }));
