import { FC } from 'react';
import { Button2 } from 'ui/button/Button';
import cn from 'classnames';
import cl from './LeftRightButton.module.scss';

export type SideClickType = 'left' | 'right';

interface Props {
  onClick: (side: SideClickType) => void;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
}

export const LeftRightButton: FC<Props> = ({ onClick, leftDisabled, rightDisabled }) => {
  return (
    <div className={cl.root}>
      <Button2
        icon="Left"
        className={cn(cl.btn, cl.left)}
        onClick={onClick.bind(null, 'left')}
        disabled={leftDisabled}
      />
      <Button2
        icon="Right"
        className={cn(cl.btn, cl.right)}
        onClick={onClick.bind(null, 'right')}
        disabled={rightDisabled}
      />
    </div>
  );
};
