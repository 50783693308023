import cn from 'classnames';
import { ElementType, FC, MouseEvent, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import * as H from 'history';
import Icon, { IconRef } from 'ui/Icon';
import { IView } from 'ui/types';
import { isExternalHref } from 'utils/string';
import cl from './MenuItem.module.scss';

interface Props extends IView {
  icon?: IconRef;
  href?: H.LocationDescriptor;
  checked?: boolean;
  useChecked?: boolean;
  disabled?: boolean;
  component?: ElementType;
  clickPreventDefault?: boolean;
}

export const MenuItem: FC<Props> = ({
  className,
  children,
  icon,
  href,
  onClick,
  checked,
  useChecked,
  component = 'div',
  clickPreventDefault = false,
  ...rest
}) => {
  let Component: ElementType = component;
  let props: { [p: string]: any; to?: H.LocationDescriptor } = {};
  if (rest.disabled) {
    Component = 'button';
    props['type'] = 'button';
  } else if (href) {
    // <Link> превращает абсолютные урлы в кривые относительные: /parent/http://origin...
    if (typeof href === 'string' && isExternalHref(href)) {
      Component = 'a';
      props.href = href;
    } else {
      Component = Link;
      const p = props as LinkProps;
      p.to = href;
    }
  } else if (component === 'button') {
    props['type'] = 'button';
  }

  const handleClick = useMemo(
    () =>
      clickPreventDefault
        ? (e: MouseEvent) => {
            e.preventDefault();
            onClick?.();
          }
        : onClick,
    [onClick, clickPreventDefault],
  );

  return (
    <Component
      {...rest}
      className={cn(cl.root, useChecked && cl.useChecked, checked && cl.checked, className)}
      onClick={handleClick}
      {...props}
    >
      {!!checked && <Icon type="Check" className={cl.checkIco} />}
      {!!icon && <Icon type={icon} className={cl.ico} />}
      {children}
    </Component>
  );
};
