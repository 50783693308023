import { LSKey } from '../persistent';
import { $meta, $theme, switchTheme } from './index';
import { Themes } from './types';

$theme.on(switchTheme, (state) => (state === Themes.dark ? Themes.light : Themes.dark));

$meta.on(switchTheme, (_, { currentTarget: { offsetTop, offsetLeft } }) => ({
  top: offsetTop,
  left: offsetLeft,
}));

switchTheme.watch(() => {
  localStorage.setItem(LSKey.theme, $theme.getState());
});
