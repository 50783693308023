/* Работа с чеклистами только для вновь создаваемой задачи */
import { createEvent, createStore, forward } from 'effector';
import { TaskChecklistItemSimple } from 'models/task/types';
import { EMPTY_ARRAY } from 'constants/utils';
import { getUniqKey } from 'utils/helpers';
import { TaskChecklistItemType } from 'models/task-checklist-item/types';

export interface TaskChecklistItemSimpleWithId extends TaskChecklistItemSimple {
  id: string | number;
}

export const resetCheckListItems = createEvent();
export const checkListToggled = createEvent();
export const addItem = createEvent<string>();
export const toggleItem = createEvent<TaskChecklistItemSimpleWithId>();
export const updateItem = createEvent<TaskChecklistItemSimpleWithId>();
export const deleteItem = createEvent<string>();
export const sortItem = createEvent<string[]>();

export const $checkListItems = createStore<Array<TaskChecklistItemSimpleWithId> | null>(null).reset(
  resetCheckListItems,
);

$checkListItems
  .on(checkListToggled, (s) => (s ? null : EMPTY_ARRAY))
  .on(addItem, (s, text) => [
    ...s!,
    { id: getUniqKey(s!.length.toString()), text, state: 'incomplete' },
  ])
  .on(deleteItem, (s, id) => s!.filter((c) => c.id !== id))
  .on(updateItem, (s, item) => s!.map((c) => (c.id === item.id ? { ...item } : c)))
  .on(sortItem, (s, sort) => (s ? sort.map((id) => s.find((item) => item.id === id)!) : null));

forward({
  from: toggleItem.map((item) => ({
    ...item,
    state: item.state === 'incomplete' ? 'complete' : ('incomplete' as TaskChecklistItemType),
  })),
  to: updateItem,
});
