import { createEffect, createEvent, createStore, sample } from 'effector';
import { Auth, AuthLoginForm, AuthType, SignUp, SignUpForm } from './types';
import { ApiResponse } from 'api/types';
import { createRequestInfo } from '../utils';
import { OwnerUser } from '../user/types';
import { Company } from '../company/types';
import { Country } from '../country/types';
import { LSKey } from '../persistent';
import { createRequestEffect } from '../utils/createRquestEffect';
import api from 'api/request/auth';

export const $auth = createStore<Auth>({
  accesstoken: '',
  refreshtoken: '',
  ctoken: '',
  clientId: '',
});

export const didLogin = sample({
  source: $auth.map((a) => Boolean(a.accesstoken)),
  filter: Boolean,
  fn: () => {},
});

export const authLoginFx = createEffect<AuthLoginForm, ApiResponse<Auth>>();

export const setTokens = createEvent<Auth>();

export const logout = createEvent();

export const signUpFx = createEffect<SignUpForm, ApiResponse<SignUp>>();
//--------------

export const firstInitFx = createEffect<
  void,
  [ApiResponse<OwnerUser>, ApiResponse<Company[]>, ApiResponse<Country[]>]
>();

export const $firstInitRequestInfo = createRequestInfo(firstInitFx);
//--------------

export const resetIsFirstSign = createEvent();
export const $isFirstSign = createStore<boolean>(false);

/** tokenAuth */
export const tokenAuth = createEvent<string>();
export const tokenAuthFx = createRequestEffect(api.tokenAuth);
export const $isTokenAuthFail = createStore(false);

export const $authType = createStore<AuthType | null>(
  (localStorage.getItem(LSKey.authType) as AuthType) || null,
);
