import { CompanyId } from '../company/types';
import { UserId } from '../user/types';

export const enum MemberSettings {
  RestrictCustomers = 'restrictCustomers',
  RestrictEmail = 'restrictEmail',
  /** #148 https://gitlab.com/finkoper_front/slack-accounting-web/-/issues/148 */
  AllowCreateCustomers = 'allowCreateCustomers',
  /** #258 https://trello.com/c/Hgmc9T5I/844 */
  BulkNotification = 'bulkNotification',
  RestrictCustomersInfo = 'restrictCustomersInfo',
}
export type MemberSettingsName = MemberSettings | (string & {});

export interface TeamUserSettings {
  bookkeeper_team_id: CompanyId;
  name: MemberSettingsName;
  user_id: UserId;
  value: 'true' | 'false'; // 'true' | 'false' //todo depend from MemberSettings
}

export interface SaveSettingsForm {
  user_id: UserId;
  name: MemberSettingsName;
  value: string; // 'true' | 'false' //todo depend from MemberSettings
}
