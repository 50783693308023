import { createStore, Effect, Store, Unit } from 'effector';
import { SetStateAction } from 'react';

export interface RequestInfo {
  pending: boolean;
  fail: boolean;
  done: boolean;
}

export function createRequestInfo<Params, Done, Fail = Error>(
  effect: Effect<Params, Done, Fail>,
): Store<RequestInfo> {
  return createStore<RequestInfo>({
    done: false,
    fail: false,
    pending: false,
  })
    .on(effect.pending, (s, pending) => ({ ...s, pending: pending }))
    .on(effect.failData, (s) => ({ ...s, fail: true, done: false }))
    .on(effect.doneData, (s) => ({ ...s, fail: false, done: true }));
}

export interface ILoadable {
  localId?: string;
  loading?: boolean;
  error?: boolean;
}

export const reducerSetState = <
  T extends object | number | string | boolean | symbol | null | undefined,
>(
  state: T,
  payload: SetStateAction<T>,
): T => (typeof payload === 'function' ? payload(state) : payload);

export function createIsNeedFetchInfo(effect: Effect<any, any, any>, reset?: Array<Unit<any>>) {
  const store = createStore<boolean>(true)
    .on(effect.pending, (s, pending) => (pending ? false : s))
    .on(effect.done, () => false)
    .on(effect.fail, () => true);

  if (reset) {
    store.reset(reset);
  }

  return store;
}
