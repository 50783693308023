import cn from 'classnames';
import {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useRef,
} from 'react';
import { useMergeRefs } from 'hooks/utils';
import { TextButton2 } from 'ui/button/Button';
import cl from './Input.module.scss';

export type InputSize = 'default' | 'small' | 'large';
export type TextAlign = 'left' | 'center' | 'right';

// Делаю `<input autoFocus/>`, т.е. `autoFocus={true}`.
// Нет атрибута `autofocus` в разметке! "Что за херня?" - думаю. Ну, думаю, точно
// костыли реакта - не иначе. И, действительно, так и вышло:
// https://stackoverflow.com/questions/60216787/react-autofocus-attribute-is-not-rendered
// В это время у меня открыта страница, там диалог, в нём `<input autoFocus/>`.
// Меняю на `autoFocus="true"`, заткнув TS `//@ts-ignore`.
// Атрибут `autofocus` hot refresh'ем появляется в разметке.
// Но у меня-то всё это было ради `runAutofocus()`, поэтому я закрываю диалог
// и открываю его опять. И что я вижу? Нет атрибута `autofocus` в разметке!
// Т.е. он всё равно не появляется в момент монтирования.
// Спасибо, реакт.
//
// (если не забуду, то проверю потом на последнем React 18)
// Проверил. Нифига не поменялось - всё так же.
//
// Ну, тогда держи:
const AUTOFOCUS_HACK = { 'data-autofocus': 'true' };

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  startWith?: ReactNode;
  endWith?: ReactNode;
  inputSize?: InputSize;
  textAlign?: TextAlign;
  affixClassName?: string;
  startClassName?: string;
  endClassName?: string;
  inputClassName?: string;
  clearClassName?: string;
  allowClear?: boolean;
  onClickClear?: () => void;
  /**
   * Не запрещать `pointer-events` при `disabled`.
   * Позволяет сделать интерактивные `startWith` и/или `endWith` при `disabled`
   * (например кликабельные или с `data-tip`). По умолчанию при `disabled`
   * запрещены все `pointer-events` в контейнере, поэтому было добавлено отдельно
   * данное свойство для обратной совместимости по умолчанию.
   */
  disabledKeepPointerEvents?: boolean;
}

const SIZES_CLS: Record<InputSize, string> = {
  default: '',
  large: cl.sizeLarge,
  small: cl.sizeSmall,
};
const TEXT_ALIGN_CLS: Record<TextAlign, string> = {
  left: cl.left,
  center: cl.center,
  right: cl.right,
};

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      startWith,
      endWith,
      inputSize = 'default',
      textAlign,
      className = '',
      affixClassName = '',
      startClassName,
      endClassName,
      inputClassName,
      clearClassName = '',
      allowClear = false,
      onClickClear,
      disabledKeepPointerEvents = false,
      ...tail
    },
    ref,
  ) => {
    const refInternal = useRef<HTMLInputElement | null>(null);
    const _ref = useMergeRefs(ref, refInternal);

    const handleClear = useCallback(
      (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const input = refInternal.current!;

        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        )!.set;
        nativeInputValueSetter!.call(input, '');

        input.dispatchEvent(new Event('input', { bubbles: true }));

        onClickClear?.();
      },
      [onClickClear],
    );

    // REFACT: при динамике эта условная структура приводит к перемонтированию
    //   и усложняет внешнюю верстку/логику лишними условностями
    if (!startWith && !endWith && !allowClear) {
      return (
        <input
          className={cn(
            SIZES_CLS[inputSize],
            textAlign && TEXT_ALIGN_CLS[textAlign],
            className,
            inputClassName,
          )}
          ref={_ref}
          {...tail}
          {...(tail.autoFocus && AUTOFOCUS_HACK)}
        />
      );
    }

    return (
      <span
        className={cn(
          cl.root,
          SIZES_CLS[inputSize],
          textAlign && TEXT_ALIGN_CLS[textAlign],
          tail.disabled && [cl.disabled, disabledKeepPointerEvents || cl.disabledPointer],
          className,
        )}
      >
        {!!startWith && (
          <span className={cn(cl.affix, affixClassName, startClassName)}>{startWith}</span>
        )}
        <input
          className={inputClassName}
          {...tail}
          {...(tail.autoFocus && AUTOFOCUS_HACK)}
          ref={_ref}
        />
        {allowClear && (
          <TextButton2
            type="button"
            size={inputSize}
            uiColor="secondary"
            icon="CrossCircle"
            className={cn(cl.btnClear, clearClassName)}
            onClick={handleClear}
          />
        )}
        {!!endWith && <span className={cn(cl.affix, affixClassName, endClassName)}>{endWith}</span>}
      </span>
    );
  },
);
