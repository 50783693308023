import { lazy, Suspense, useMemo } from 'react';
import { Route, Router, Switch } from 'react-router';
import { I18nextProvider } from 'react-i18next';
import { LoadingPage } from './components/page';
import { authRequired, guestOnly } from './components/auth';
import { authorize, setLanguage } from 'api';
import { useAppActiveTrack } from './models/app-active';
import { logout, setTokens } from 'models/auth';
import { windowSizeUpdated } from './models/layout';
import { onClearTokens, onGotTokens } from 'api/instance';
import { history } from 'utils/history';
import { ErrorBoundary } from 'react-error-boundary';
import { LSKey } from './models/persistent';
import { ErrorPage } from 'components/page/ErrorPage';
import { TechPanel } from './components/tech/TechPanel';
import { Tooltip } from './components/utils';
import { useTooltipMount } from './components/utils/display/Tooltip/model';
import { PopupContainer } from './ui/feedback/PopupContainer';
import { PromptContainer } from './ui/feedback/PromptContainer';
import i18n from 'i18n/';
// import 'effector-logger/inspector'
import 'simplebar/dist/simplebar.min.css';
import './models/init';

//prevent Conflicting order css for product build
import 'ui/input/Input';
// import { Tooltip } from 'ui/feedback';
// import { Tooltip as TooltipNew } from './components/utils';

const MainPage = authRequired(lazy(() => import(/* webpackPrefetch: true */ 'routes/main')));
const LoginPage = guestOnly(lazy(() => import(/* webpackPrefetch: true */ 'routes/login')));
const InvitePage = guestOnly(lazy(() => import(/* webpackPrefetch: true */ 'routes/invite')));
const TokenAuthPage = guestOnly(
  lazy(() => import(/* webpackPrefetch: true */ 'routes/token-auth')),
);
const LoginWithPage = lazy(() => import(/* webpackPrefetch: true */ 'routes/login-with'));
const RedirectPage = lazy(
  () => import(/* webpackPrefetch: true */ 'routes/redirect/components/RedirectPage'),
);

onGotTokens((tokens) => {
  setTokens(tokens);
});

onClearTokens(() => {
  logout();
});

setLanguage(i18n.language);
i18n.on('languageChanged', (lng) => {
  setLanguage(lng);
  document.documentElement.setAttribute('lang', lng);
});

window.addEventListener('resize', windowSizeUpdated);

function App() {
  useAppActiveTrack();
  useMemo(() => {
    if (history.location.pathname.split('/')[1] === 'redirect') {
      return;
    }
    const accessToken = localStorage.getItem(LSKey.accessToken) || '';
    const refreshToken = localStorage.getItem(LSKey.refreshToken) || '';
    const ctoken = localStorage.getItem(LSKey.ctoken) || '';
    const clientId = localStorage.getItem(LSKey.clientId) || '';
    if (accessToken) {
      authorize(accessToken);
      setTokens({ accesstoken: accessToken, refreshtoken: refreshToken, ctoken, clientId });
    }
  }, []);
  useTooltipMount();

  // #260
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Router history={history}>
        <I18nextProvider i18n={i18n}>
          <PopupContainer>
            <Suspense fallback={<LoadingPage fullScreen />}>
              <TechPanel className="tech-panel" />
              <Switch>
                <Route path="/login/:param?" component={LoginPage} />
                <Route path="/invite" component={InvitePage} />
                <Route path="/redirect/:param" component={RedirectPage} />
                <Route path="/token-auth/:token" component={TokenAuthPage} />
                <Route path="/login-with/:service/:param?" component={LoginWithPage} />
                <Route component={MainPage} />
              </Switch>
            </Suspense>
            <PromptContainer />
          </PopupContainer>

          {/* toastify-react: CTOptions.toastContainerID */}
          <PopupContainer id="ct-container" />

          {/* Tooltip пока здесь, а иначе возникает проблема при сборке: Conflicting order css. Сейчас нет времени разбираться */}
          {/* Tooltip в конце, чтобы быть поверх всех */}
          <Tooltip />
        </I18nextProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
