import { MutableRefObject, Ref, RefCallback, useEffect, useMemo, useRef } from 'react';

type AnyRef<T> = Ref<T> | MutableRefObject<T | null>;
type AnyRefOptional<T> = AnyRef<T> | undefined;

const setRef = <T>(ref: AnyRefOptional<T>, value: T | null) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    (ref as MutableRefObject<T | null>).current = value;
  }
};

/**
 * Слияние нескольких рефов в один
 *
 * !!! Количество аргументов должно быть постоянным !!!
 *
 * ```tsx
 * const C = forwardRef<T | null, P>((props, ref) => {
 *   const myRef = useRef<T | null>(null);
 *   const setRef = useMergeRefs(ref, myRef);
 *
 *   // useEffect(() => {
 *   //   myRef.current?. ...
 *   //}, []);
 *
 *   return <E ref={setRef} />;
 * });
 *
 * console.log(<C ref={...} />);
 * ```
 */
export function useMergeRefs<T>(ref1: AnyRef<T>, ...refs: AnyRef<T>[]): RefCallback<T>;
/**
 * Слияние нескольких рефов в один
 *
 * !!! Количество аргументов должно быть постоянным !!!
 *
 * ```tsx
 * const C = forwardRef<T | null, P>((props, ref) => {
 *   const myRef = useRef<T | null>(null);
 *   const setRef = useMergeRefs(ref, myRef);
 *
 *   // useEffect(() => {
 *   //   myRef.current?. ...
 *   //}, []);
 *
 *   return <E ref={setRef} />;
 * });
 *
 * console.log(<C ref={...} />);
 * ```
 */
export function useMergeRefs<T>(...refs: AnyRefOptional<T>[]): RefCallback<T> | null {
  useWarnDeps(refs);
  return useMemo(
    () =>
      refs.some(Boolean)
        ? (value: T | null) => {
            if (value === null || value === undefined) {
              // обратный порядок (хз, важно ли это, но кажется логичным)
              for (let i = refs.length; i-- > 0; ) {
                setRef(refs[i], value);
              }
            } else {
              for (let i = 0; i < refs.length; i++) {
                setRef(refs[i], value);
              }
            }
          }
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs,
  );
}

const useWarnDeps =
  process.env.NODE_ENV === 'development'
    ? (deps: readonly unknown[]) => {
        const n = deps.length;
        const ref = useRef(n);
        useEffect(() => {
          if (n !== ref.current) {
            console.warn(
              'Количество зависимостей должно быть постоянно: было',
              ref.current,
              'стало',
              n,
            );
          }
        }, [n]);
      }
    : () => {};
