import cn from 'classnames';
import { FC, useMemo, useRef, useState } from 'react';
import { IView } from 'ui/types';
import { cssFixedSizes } from './helpers';
import { FixedSizeDefinition, GridCellRender } from './types';
import { useVisibleGridRect } from './useVisibleGridRect';
import { renderCells } from './renderCells';
import cl from './DataGrid.module.scss';

// На new.cubux.net чё-то наигрался я уже с этими сторонними компонентами для grid.
// Плюс часто повторяющиеся общие проблемы любых сторонних компонентов.
// Также я уже делал у себя в проге grid-рисовалку.
//
// Поэтому я, не долго думая, уверенно решил сделать свой компонент, в котором
// я буду уверен, и буду знать в нём каждый чих.

// -----------------
// Этот грид рассчитан на заранее известный размер сетки в пикселях. Пикселы
// используются для того, чтобы отображать только реально видимые на экране
// ячейки. Невидимые отмонтируются.

interface Props extends IView {
  // кол-во столбцов данных без fixed left
  dataCols: number;
  // кол-во строк данных без fixed top
  dataRows: number;
  // кол-во фиксированных sticky столбцов слева
  leftFixedCols?: number;
  // кол-во фиксированных sticky строк сверху
  topFixedRows?: number;

  // ширина ячейки данных в пикселях
  dataCellWidth: number;
  // высота ячейки данных в пикселях
  dataCellHeight: number;
  // размеры фиксированных столбцов слева
  leftColsWidths?: FixedSizeDefinition;
  // размеры фиксированных строк сверху
  topRowsHeights?: FixedSizeDefinition;

  // рендер ячейки в фиксированном верхнем левом углу
  RenderCellCorner?: GridCellRender;
  // рендер ячейки в фиксированных верхних строках
  RenderCellTop?: GridCellRender;
  // рендер ячейки в фиксированных левых столбцах
  RenderCellLeft?: GridCellRender;
  // рендер ячейки данных
  RenderCellData?: GridCellRender;

  // TODO: mergeCells?: (c: GridCellStart) => MergeCellBounds | null | undefined;
  //       type MergeCellBounds = Partial<{ width: number; height: number }>;
  //       interface GridCellRenderProps extends ... { merge?: MergeCellBounds }

  // позже возникла мысль сделать возможность создавать несколько регионов
  // данных по горизонтали и вертикали, чтобы внутренние координары в каждом
  // регионе были свои
  //
  // corner | top 0             | top 1             |
  // -------|-------------------|-------------------|
  // left 0 | 0,0  | 1,0  | ... | 0,0  | 1,0  | ... |
  //        | 0,1  | 1,1  | ... | 0,1  | 1,1  | ... |
  //        | ...  | ...  | ... | ...  | ...  | ... |
  // -------|-------------------|-------------------|
  // left 1 | 0,0  | 1,0  | ... | 0,0  | 1,0  | ... |
  //        | 0,1  | 1,1  | ... | 0,1  | 1,1  | ... |
  //        | ...  | ...  | ... | ...  | ...  | ... |
  //
  // но такого, наверно, проще было бы добиться с помощью дополнительного
  // компонента-обёртки
}

export const DataGrid: FC<Props> = ({
  dataCols,
  dataRows,
  leftFixedCols = 0,
  topFixedRows = 0,

  dataCellWidth,
  dataCellHeight,
  leftColsWidths = dataCellWidth,
  topRowsHeights = dataCellHeight,

  RenderCellCorner,
  RenderCellTop,
  RenderCellLeft,
  RenderCellData,

  className,
  ...rest
}) => {
  const hasLeft = leftFixedCols > 0;
  const hasTop = topFixedRows > 0;
  const hasCorner = hasLeft && hasTop;
  // const hasData = dataCols > 0 && dataRows > 0;

  const fixedCols = useMemo(
    () => cssFixedSizes(leftFixedCols, leftColsWidths),
    [leftFixedCols, leftColsWidths],
  );
  const fixedRows = useMemo(
    () => cssFixedSizes(topFixedRows, topRowsHeights),
    [topFixedRows, topRowsHeights],
  );

  const refRoot = useRef<HTMLDivElement>(null);
  const [refTop, setRefTop] = useState<HTMLDivElement | null>(null);
  const [refLeft, setRefLeft] = useState<HTMLDivElement | null>(null);
  const refData = useRef<HTMLDivElement>(null);
  const see = useVisibleGridRect({
    viewport: refRoot,
    top: useMemo(() => ({ current: refTop }), [refTop]),
    left: useMemo(() => ({ current: refLeft }), [refLeft]),
    data: refData,
    dataCols,
    dataRows,
  });
  // useEffect(
  //   () => console.log('see rect at', [see.x, see.y], 'of', [see.w, see.h]),
  //   [see.x, see.y, see.w, see.h],
  // );

  const cornerCells = useMemo(
    () =>
      renderCells({
        section: 'c',
        x: 0,
        y: 0,
        w: leftFixedCols,
        h: topFixedRows,
        R: RenderCellCorner,
      }),
    [leftFixedCols, topFixedRows, RenderCellCorner],
  );
  const topCells = useMemo(
    () =>
      renderCells({
        section: 't',
        x: see.x,
        y: 0,
        w: see.w,
        h: topFixedRows,
        R: RenderCellTop,
      }),
    [see.x, see.w, topFixedRows, RenderCellTop],
  );
  const leftCells = useMemo(
    () =>
      renderCells({
        section: 'l',
        x: 0,
        y: see.y,
        w: leftFixedCols,
        h: see.h,
        R: RenderCellLeft,
      }),
    [see.y, see.h, leftFixedCols, RenderCellLeft],
  );
  const dataCells = useMemo(
    () =>
      renderCells({
        section: 'd',
        x: see.x,
        y: see.y,
        w: see.w,
        h: see.h,
        R: RenderCellData,
      }),
    [see.x, see.y, see.w, see.h, RenderCellData],
  );

  return (
    <div
      {...rest}
      ref={refRoot}
      className={cn(
        cl.root,
        className,
        see.isXScrolled && cl._xScrolled,
        see.isYScrolled && cl._yScrolled,
      )}
      style={useMemo(
        () =>
          ({
            ...(leftFixedCols > 0 && {
              '--grid-fixed-cols-count': leftFixedCols,
              '--grid-fixed-cols': fixedCols.values,
              '--grid-fixed-w': fixedCols.sumCss,
            }),
            ...(topFixedRows > 0 && {
              '--grid-fixed-rows-count': topFixedRows,
              '--grid-fixed-rows': fixedRows.values,
              '--grid-fixed-h': fixedRows.sumCss,
            }),
            '--grid-data-cols-count': dataCols,
            '--grid-data-rows-count': dataRows,
            '--grid-data-w': `${dataCellWidth}px`,
            '--grid-data-h': `${dataCellHeight}px`,
          }) as {},
        [
          fixedCols,
          fixedRows,
          leftFixedCols,
          topFixedRows,
          dataCols,
          dataRows,
          dataCellWidth,
          dataCellHeight,
        ],
      )}
    >
      <div className={cl.content}>
        {hasCorner && <div className={cl.corner}>{cornerCells}</div>}
        {hasTop && (
          <div ref={setRefTop} className={cl.top}>
            {topCells}
          </div>
        )}
        {hasLeft && (
          <div ref={setRefLeft} className={cl.left}>
            {leftCells}
          </div>
        )}
        <div ref={refData} className={cl.data}>
          {dataCells}
        </div>
      </div>
    </div>
  );
};
