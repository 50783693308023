import { ReactNode } from 'react';

export type NestedValidationErrorNode = Exclude<ReactNode, true>;
export type NestedValidationResultSimple = { $?: NestedValidationErrorNode };
export type NestedValidationResultArray<T extends readonly any[]> = NestedValidationResult<
  T[number]
>[];
export type NestedValidationResultArrayLike<T extends readonly any[]> = {
  [i: number]: NestedValidationResult<T[number]>;
};
export type NestedValidationResultObject<T extends object> = {
  [K in keyof T]?: NestedValidationResult<Required<T>[K]>;
};

export type NestedValidationResult<T> =
  | (NestedValidationResultSimple &
      (T extends readonly any[]
        ? NestedValidationResultArray<T> | NestedValidationResultArrayLike<T>
        : T extends object
        ? NestedValidationResultObject<T>
        : {}))
  | null
  | undefined;

export type NestedValidationResultAny = NestedValidationResult<any>;

export const isValidNested = <T extends NestedValidationResultAny>(errors: T): boolean => {
  if (!errors) {
    return true;
  }
  if (errors.$) {
    return false;
  }
  if (Array.isArray(errors)) {
    return errors.every(isValidNested);
  }
  let $;
  let rest: NestedValidationResultObject<any>;
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    $,
    ...rest
  } = errors);
  return Object.values(rest).every(isValidNested);
};

export const normalizeNestedErrors = <T extends NestedValidationResultAny>(
  errors: T | null | undefined,
): T | null => {
  if (!errors) {
    return null;
  }
  if (Array.isArray(errors)) {
    const own = errors.$ ? ({ $: errors.$ } as T & NestedValidationResultSimple) : null;
    const all = errors.map(normalizeNestedErrors) as T & NestedValidationResultArray<any>;
    const result = all.some(Boolean) ? all : ([] as any[] as typeof all);
    if (own) {
      return Object.assign(result, own);
    }
    return result.length ? result : null;
  }

  let $: NestedValidationErrorNode;
  let rest: NestedValidationResultObject<any>;
  ({ $, ...rest } = errors);
  const entries = Object.entries(rest)
    .map(([k, v]) => [k, normalizeNestedErrors(v)])
    .filter(([, v]) => v);
  if (entries.length) {
    const out = Object.fromEntries(entries) as T & NestedValidationResultObject<any>;
    if ($) {
      out.$ = $;
    }
    return out;
  }
  if ($) {
    return { $ } as T & NestedValidationResultSimple;
  }
  return null;
};
