import { ApiResponse, ApiResponse2 } from 'api/types';
import { dataOnly } from 'api/instance';
import { AllUsers, AvatarId, IPInfo, OwnerUser, UserInfoUpdateForm } from 'models/user/types';
import { ChannelId } from 'models/channel/types';

const api = {
  info: () => dataOnly.get<null, ApiResponse<OwnerUser>>(`/api/v1/users/info`),

  update: (form: UserInfoUpdateForm) =>
    dataOnly.put<null, ApiResponse2<any>>('/api2/users/info', form),

  ipInfo: () => dataOnly.get<null, ApiResponse<IPInfo>>('/api/v1/users/ipinfo'),

  // list: (companyId: string) =>
  //   dataOnly.get<null, ApiResponse<CommonUser[]>>(`/api/v1/users/${companyId}/company`),
  list: (companyId: string) =>
    dataOnly.get<null, ApiResponse<AllUsers>>(`/api/v2/users/company/${companyId}`),

  typingStart: (channelId: ChannelId) =>
    dataOnly.post<null, ApiResponse<''>>(`/api/v2/users/typing/channel/${channelId}`),

  typingEnd: (channelId: ChannelId) =>
    dataOnly.post<null, ApiResponse<''>>(`/api/v2/users/endtyping/channel/${channelId}`),

  setAvatar: (avatar: Blob) => {
    const formData = new FormData();
    formData.append('files', avatar);

    return dataOnly.put<null, ApiResponse<{ avatarid: AvatarId }>>(
      `/api/v2/users/avatar`,
      formData,
    );
  },

  avatar: (avatarId: AvatarId) =>
    dataOnly.get<null, Blob>(`/api/v2/users/avatar/${avatarId}`, { responseType: 'blob' }),

  deleteAvatar: () => dataOnly.delete<null, ApiResponse>(`/api/v2/users/avatar`),
};

export default api;
