import {
  AxiosRequestCustomConfig,
  dataOnly,
  downloadInstance,
  fileInstance,
  rawInstance,
} from 'api/instance';
import { API_INTGR_REMOTE, ApiResponse2, stubApiResponse2 } from 'api/types';
import { WithBookkeeper } from 'api/types/request';
import { FileId, FilesStatParams, FileStorageSummary } from 'models/filestorage/types';
import {
  Fileshare,
  FilesUpdateBatchForm,
  FilesUploadFormV2,
  FileUpdateFormWithId,
} from 'models/filestorage/v2/types';

export const apiFilesStatV2 = async (params: WithBookkeeper & FilesStatParams) =>
  params.f.length
    ? dataOnly.get<null, ApiResponse2<readonly Fileshare[]>>(`${API_INTGR_REMOTE}/files/stat`, {
        params,
      })
    : stubApiResponse2<readonly Fileshare[]>([]);

// TODO: customer_id?: CustomerId | '';
export const apiFilesSummary = (params: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<FileStorageSummary>>(`${API_INTGR_REMOTE}/files/summary`, {
    params,
  });

const isRoArray: (v: unknown) => v is readonly unknown[] = Array.isArray;

export const apiFilesUpload = async (
  { files, ...form }: FilesUploadFormV2 & WithBookkeeper,
  signal?: AbortSignal,
) => {
  if (!files.length) {
    return stubApiResponse2<readonly Fileshare[]>([]);
  }
  const formData = new FormData();
  files.forEach((f) => formData.append('files', f));
  for (const [k, v] of Object.entries(form)) {
    if (v === undefined) continue;
    if (isRoArray(v)) {
      v.forEach((vi) => formData.append(k, vi === null ? '' : String(vi)));
      continue;
    }
    formData.append(k, String(v));
  }
  return dataOnly.post<null, ApiResponse2<readonly Fileshare[]>>(
    `${API_INTGR_REMOTE}/files`,
    formData,
    { signal },
  );
};

export const apiFilesDownload = ({ fileId, fileName }: { fileId: FileId; fileName?: string }) =>
  downloadInstance.get<null, string>(`${API_INTGR_REMOTE}/files/${fileId}`, {
    _fileName: fileName,
  } as AxiosRequestCustomConfig);

export const apiFilesGetBlob = (fileId: FileId, signal?: AbortSignal) =>
  fileInstance.get<null, File>(`${API_INTGR_REMOTE}/files/${fileId}`, { signal });

export const apiFilesUpdateBatch = (form: FilesUpdateBatchForm & WithBookkeeper) =>
  dataOnly.patch<null, ApiResponse2<readonly Fileshare[]>>(`${API_INTGR_REMOTE}/files`, form);

export const apiFilesUpdateBatchVoid = (form: FilesUpdateBatchForm & WithBookkeeper) =>
  dataOnly.patch<null, void>(`${API_INTGR_REMOTE}/files`, { ...form, no_return: true });

export const apiFilesUpdate = ({ fileId, ...form }: FileUpdateFormWithId) =>
  dataOnly.patch<null, ApiResponse2<Fileshare>>(`${API_INTGR_REMOTE}/files/${fileId}`, form);

/**
 * Попытка удаления файла
 *
 * - 200 - файл не удалён, т.к. используется
 * - 204 - файл удалён
 */
export const apiFilesDelete = (fileId: FileId) =>
  rawInstance.delete<void>(`${API_INTGR_REMOTE}/files/${fileId}`);
