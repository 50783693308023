import axios from 'axios';

export const onRejectBlobJson = async (e: unknown) => {
  if (axios.isAxiosError(e) && e.response) {
    const { data } = e.response;
    if (data instanceof Blob && /^application\/json($|;)/.test(data.type)) {
      try {
        e.response = {
          ...e.response,
          data: JSON.parse(await data.text()),
        };
        if (process.env.NODE_ENV === 'development') {
          (e.response as any)._dataOrig = data;
        }
      } catch {}
    }
  }
  return Promise.reject(e);
};
