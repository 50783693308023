interface Options {
  selectors?: readonly string[];
}

const FOCUSABLE_SELECTORS = [
  '[autofocus]:enabled,',
  // см. AUTOFOCUS_HACK в <Input/>
  '[data-autofocus]:enabled',
  ':enabled',
] as const;

export const runAutofocus = (
  container: ParentNode,
  { selectors = FOCUSABLE_SELECTORS }: Options = {},
): boolean => {
  for (const selector of selectors) {
    try {
      const element = container.querySelector(selector);
      if (element && typeof (element as HTMLElement).focus === 'function') {
        if (process.env.NODE_ENV === 'development') {
          console.log('runAutofocus: found element', element, 'with selector', {
            selector,
          });
        }
        (element as HTMLElement).focus();
        return true;
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('runAutofocus: Selector', { selector }, 'cause', e);
      }
    }
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('runAutofocus: did not focus any element');
  }
  return false;
};
