import { createEvent, EffectParams, restore, sample } from 'effector';
import { apiTaskBulkTeams } from 'api/request/tasks-bulk-by-teams';
import { createGate } from 'effector-react';
import { $currentCompanyIdOrNull } from 'models/company';
import { createRequestEffect } from 'models/utils/createRquestEffect';
import { Task } from 'models/task/types';

export type PromptType = 'forward' | 'notForward' | 'customerForward';

export const ForwardCustomerListGate = createGate<{ task?: Task }>({
  defaultState: {},
});

export const promptTypeChanged = createEvent<PromptType>();

const fetchCustomerListFx = createRequestEffect(apiTaskBulkTeams.customerList, true);

export const $listPending = fetchCustomerListFx.pending;
export const $promptType = restore(promptTypeChanged, 'notForward').reset(
  ForwardCustomerListGate.close,
);
export const $forwardCustomerIds = restore(
  fetchCustomerListFx.doneData.map((c) => c.data),
  null,
).reset(ForwardCustomerListGate.close);

sample({
  clock: ForwardCustomerListGate.open,
  source: {
    companyId: $currentCompanyIdOrNull,
    state: ForwardCustomerListGate.state,
  },
  target: fetchCustomerListFx,
  fn: ({ companyId, state: { task } }): EffectParams<typeof fetchCustomerListFx> => ({
    bulk_settings_id: task!.bulk_settings_id!,
    params: {
      bookkeeper_team_id: companyId!,
      assignees: task!.assignees?.map((c) => c.user_id) ?? [],
    },
  }),
});
