import cn from 'classnames';
import i18next from 'i18next';
import { FC, FormEvent, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button2 } from 'ui/button';
import { ButtonSize } from 'ui/button/types';
import { Dialog, DialogProps } from '../Dialog';
import { reactModal } from '../reactModal';
import cl from './Prompt.module.scss';

export type DialogType = 'default' | 'error' | 'info';

interface SharedProps extends Pick<DialogProps, 'autoFocus' | 'keyboard' | 'size'> {
  title?: string | ReactNode;
  type?: DialogType;
  className?: string;
  wrapClassName?: string;
  wrapForm?: boolean;
  buttonsSize?: ButtonSize;
  buttonsCenter?: boolean;
  okText?: ReactNode;
  okClassName?: string;
}

// REFACT: повтор некоторых свойств в разных местах ниже
// TODO: кастомные свойства кнопкам, чтобы, например, указать uiColor для OK

interface Props extends SharedProps {
  open?: boolean;
  dialogTitle?: string | ReactNode;
  // REFACT: почему-то называется `title`, но при этом не является `title` диалога,
  //  и есть использование именно в таком виде. Поэтому я добавил отдельно `dialogTitle`.
  text?: string | ReactNode;
  onClose: () => void;
  /**
   * Если возвращает промис, то кнопка ok становится disabled, пока промис на
   * завершится любым способом.
   */
  onOk: (() => void) | (() => Promise<any>);
  okDisabled?: boolean;
  isLoading?: boolean;
}

export const Prompt: FC<Props> = ({
  open,
  onClose,
  dialogTitle,
  title,
  text,
  type = 'default',
  wrapClassName,
  onOk,
  children = text,
  buttonsSize = 'small',
  buttonsCenter,
  okDisabled = false,
  okText,
  okClassName,
  // Возможно, можно было бы сделать и `true` по умолчанию, но это приводит к
  // вложению в ещё один элемент
  wrapForm = false,
  isLoading = false,
  ...rest
}) => {
  const { t } = useTranslation('ui');
  const [okPending, setOkPending] = useState(false);
  const handleOk = useCallback(() => {
    const p = onOk();
    if (p) {
      setOkPending(true);
      p.finally(() => setOkPending(false));
    }
  }, [onOk]);

  const _isLoading = isLoading || okPending;

  return (
    <Dialog
      {...rest}
      visible={open}
      onClose={onClose}
      destroyOnClose
      rootClassName={cl.root}
      wrapClassName={wrapClassName}
      title={dialogTitle}
    >
      <FormWrapOptional wrap={wrapForm} onSubmit={handleOk}>
        {title}
        {children || text}

        <div className={cn(cl.footer, buttonsCenter && cl._center, cl[buttonsSize])}>
          {type === 'default' && (
            <Button2 type="button" uiColor="secondary" onClick={onClose} size={buttonsSize}>
              {t('ui:cancel')}
            </Button2>
          )}
          <Button2
            type="submit"
            size={buttonsSize}
            onClick={wrapForm ? undefined : handleOk}
            disabled={okDisabled || _isLoading}
            isLoading={okPending}
            className={okClassName}
          >
            {okText ?? t('ui:ok')}
          </Button2>
        </div>
      </FormWrapOptional>
    </Dialog>
  );
};

const FormWrapOptional: FC<{ wrap: boolean; onSubmit?: () => void }> = ({
  wrap,
  onSubmit,
  children,
}) => {
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit?.();
    },
    [onSubmit],
  );

  return wrap ? (
    <form target="#" onSubmit={handleSubmit}>
      {children}
    </form>
  ) : (
    <>{children}</>
  );
};

export interface PromptOptions extends SharedProps {
  /**
   * Когда указано, диалог закроется только после того, как промис резолвится с
   * любым `true`-подобным значением.
   */
  onOk?: () => Promise<boolean> | Promise<unknown>;
  onCancel?: () => void;
}

export const promptFn = (text: string | ReactNode, options?: PromptOptions) =>
  reactModal<boolean>(({ show, onSubmit }) => {
    const { onOk: onOkCustom, onCancel: onCancelCustom, ...rest } = options || {};
    const onCancel = () => {
      onCancelCustom?.();
      onSubmit(false);
    };
    const onOk = onOkCustom
      ? async () => {
          if (await onOkCustom()) {
            onSubmit(true);
          }
        }
      : () => onSubmit(true);
    return <Prompt {...rest} open={show} onClose={onCancel} text={text} onOk={onOk} />;
  }) as Promise<boolean>;

export const showFormError = (text?: string, title?: string) =>
  promptFn(text || i18next.t('ui:messages.checkData'), { title, type: 'error' });
