import { CompanyId } from 'models/company/types';
import { CustomerId } from 'models/customer/types';
import { IdNumberOf } from 'utils/types';
import { UserId } from 'models/user/types';
import { Task, TaskId } from 'models/task/types';
import { TaskChecklistItem } from 'models/task-checklist-item/types';
import { ForwardType } from '../../routes/main/routes/task/components/TaskEditModal/modelRepeatPrompt';

export type TaskChecklistId = IdNumberOf<'TaskChecklist'>;

export interface TaskChecklist {
  id: TaskChecklistId;
  items: TaskChecklistItem[] | null;
  task_id: TaskId | null;
  team_id: CustomerId;
  title: string;
  typical_task_id: TaskId | null;
}

export interface TaskChecklistParamForm {
  bookkeeper_team_id: CompanyId;
  team_id: CustomerId | null | undefined;
  is_typical: boolean;
  user_id?: UserId;
  apply_to_repeatable?: boolean;
  for_teams?: CustomerId[];
}

export interface TaskChecklistIdParams {
  taskId: TaskId;
  checklistId: TaskChecklistId;
}

export interface TaskChecklistCreateForm {
  bookkeeper_team_id: CompanyId;
  team_id: CustomerId | null;
  is_typical: boolean;
  title: string;
  user_id?: string;
  apply_to_repeatable?: boolean;
  for_teams?: CustomerId[];
}

export interface TaskChecklistOperate {
  task: Task;
  // apply_to_repeatable?: boolean;
  forwardType?: ForwardType;
}

export interface TaskChecklistUpdateForm extends TaskChecklistCreateForm {}

export interface RepeatableTaskChecklistAnswer {
  checklists: TaskChecklist[];
  tasks: Task[] | null;
  tasks_readonly: Task[] | null;
}

export const isRepeatableTaskChecklistAnswer = (
  data: TaskChecklist | RepeatableTaskChecklistAnswer,
): data is RepeatableTaskChecklistAnswer => 'checklists' in data;
