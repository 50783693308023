import { createEffect, createEvent, EffectParams, EventPayload, sample } from 'effector';
import api from 'api/request/post';
import { PostId, ReactionAddedNotify, ReactionId, ReactionRemovedNotify } from './types';
import { ChatData, chatDataList } from './index';
import { UserId } from '../user/types';

export const addReaction = createEvent<{
  postId: PostId;
  reaction: ReactionId;
  chatData: ChatData;
}>();
export const reactionAdded = createEvent<ReactionAddedNotify>();
export const reactionRemoved = createEvent<ReactionRemovedNotify>();

const addReactionFx = createEffect(api.addReaction);

sample({
  clock: addReaction,
  target: addReactionFx,
  fn: ({ postId, reaction }): EffectParams<typeof addReactionFx> => ({
    postId,
    form: {
      reaction,
    },
  }),
});
sample({
  clock: addReaction,
  target: createEffect(({ chatData, reaction, postId }: EventPayload<typeof addReaction>) => {
    chatData.toggleReaction({ postId, reaction });
  }),
});

sample({
  clock: reactionAdded,
  source: chatDataList.map((data) => data.$currentChannelId),
  target: createEffect(
    (
      params:
        | { chatDataIdx: number; postId: PostId; reaction: ReactionId; userId: UserId }
        | undefined,
    ) => {
      if (!params) {
        return;
      }
      const { chatDataIdx, postId, reaction, userId } = params;
      chatDataList[chatDataIdx].toggleReaction({ postId, reaction, userId });
    },
  ),
  fn: (currentChannelIds, { post_id, reaction, channel_id, user_id }) => {
    const chatDataIdx = currentChannelIds.findIndex((id) => id === channel_id);
    if (chatDataIdx < 0) {
      return undefined;
    }
    return {
      chatDataIdx,
      postId: post_id,
      userId: user_id,
      reaction,
    };
  },
});

sample({
  clock: reactionRemoved,
  source: chatDataList.map((data) => data.$currentChannelId),
  target: createEffect(
    (
      params:
        | { chatDataIdx: number; postId: PostId; reaction: ReactionId; userId: UserId }
        | undefined,
    ) => {
      if (!params) {
        return;
      }
      const { chatDataIdx, postId, reaction, userId } = params;
      chatDataList[chatDataIdx].removeReaction({ postId, reaction, userId });
    },
  ),
  fn: (currentChannelIds, { post_id, reaction, channel_id, user_id }) => {
    const chatDataIdx = currentChannelIds.findIndex((id) => id === channel_id);
    if (chatDataIdx < 0) {
      return undefined;
    }
    return {
      chatDataIdx,
      postId: post_id,
      userId: user_id,
      reaction,
    };
  },
});
