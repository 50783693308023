import { combine, createEvent, createStore, forward, merge, restore, sample } from 'effector';
import i18next from 'i18next';
import { pending } from 'patronum';
import * as RoArray from '@cubux/readonly-array';
import * as RoMap from '@cubux/readonly-map';
import * as RoSet from '@cubux/readonly-set';
import { EMPTY_ARRAY, EMPTY_MAP, EMPTY_SET } from 'constants/utils';
import { createRequestEffect } from '../utils/createRquestEffect';
import {
  apiMailCredentialsUsers,
  EmailListParams,
  ListParams,
  UpdateParams,
} from 'api/request/mail-credentials-users';
import { history } from 'utils/history';
import { $currentCompanyId } from '../company';
import { CompanyId } from '../company/types';
import { EmailFolderId, EmailId } from '../mail/types';
import { clearErrorMailCredentialsFx } from '../mail-credentials';
import { MailCredentialsId } from '../mail-credentials/types';
import { notifyPlaySound } from '../notify/sound';
import { UserId } from '../user/types';
import { notification, toastErrorFx } from '../utils/messages';
import { ToastTypes } from 'ui/feedback';
import { $myActualSettings_restrictEmail } from '../teams/my';
import { defaultReducer } from '../utils/defaultReducer';
import { MailCredentialsEmail } from './types';
import { newMailNoticeReceived } from '../notices';
import { NoticeAttribute, NoticeAttributeId, NoticeExt, NoticeId } from '../notices/types';
import { getUniqKeyRnd } from '../../utils/helpers';
import { toISODateTimeString } from '../../utils/date';
import { CustomerId } from '../customer/types';
import { $customers } from '../customer';
import mailIco from 'assets/img/mailInbox.svg';

export const resetCurrentList = createEvent();

export const fetchList = createEvent<MailCredentialsId>();
export const fetchListFx = createRequestEffect(apiMailCredentialsUsers.list);

export const updateCurrent = createEvent<UserId[]>();
export const updateCurrentFx = createRequestEffect(apiMailCredentialsUsers.update);

export const fetchEmailList = createEvent();
export const fetchEmailListFx = createRequestEffect(apiMailCredentialsUsers.emailList);

export const $currentList = createStore<UserId[] | null>(null).reset(resetCurrentList);
export const $currentMailCredentialsId = restore(fetchList, null).reset(resetCurrentList);
export const $pendingCurrentList = pending({ effects: [updateCurrentFx, fetchListFx] });
export const $emailList = createStore<readonly MailCredentialsEmail[] | null>(null).reset(
  $currentCompanyId,
);
// #213 дубль 2
export const $emailListReady = $emailList.map(Boolean);
const cmpMailAcc = (a: MailCredentialsEmail, b: MailCredentialsEmail) =>
  a.credential_id - b.credential_id;
export const $emailListSorted = $emailList.map((list) =>
  list ? Array.from(list).sort(cmpMailAcc) : EMPTY_ARRAY,
);
export const $hasConnectedEmails = $emailList.map((list) => !!list && !!list.length);
export const $showEmailPage = combine(
  $myActualSettings_restrictEmail,
  $hasConnectedEmails,
  (restrict, has) => has && !restrict,
);
// export const $hasNewMails = $emailList.map((c) => Boolean(c && c.some((c) => c.have_new)));
export const $emailMap = $emailList.map((list) =>
  RoMap.fromArray(list || [], (c) => c.credential_id),
);

$currentList.on(fetchListFx.doneData, (_, { data }) => data.users);
sample({
  clock: fetchList,
  source: $currentCompanyId,
  target: fetchListFx,
  fn: (companyId, id): ListParams => ({
    id,
    params: {
      bookkeeper_team_id: companyId as CompanyId,
    },
  }),
});
sample({
  clock: updateCurrent,
  source: { companyId: $currentCompanyId, id: $currentMailCredentialsId },
  target: updateCurrentFx,
  fn: ({ companyId, id }, users): UpdateParams => ({
    id: id!,
    data: {
      bookkeeper_team_id: companyId as CompanyId,
      users,
    },
  }),
});
forward({ from: updateCurrentFx.done, to: resetCurrentList });
forward({ from: [updateCurrentFx.done, clearErrorMailCredentialsFx.done], to: fetchEmailList });

$emailList.on(fetchEmailListFx.doneData, defaultReducer);

sample({
  clock: fetchEmailList,
  source: $currentCompanyId,
  target: fetchEmailListFx,
  fn: (companyId): EmailListParams => ({ bookkeeper_team_id: companyId as CompanyId }),
});

notification({
  clock: updateCurrentFx.done,
  mode: ToastTypes.success,
  // t('account:email.messages.updateUserListSuccess')
  tKey: 'account:email.messages.updateUserListSuccess',
});
sample({ clock: updateCurrentFx.fail, target: toastErrorFx });

export interface MailInFolderNotify {
  mailCredId: MailCredentialsId;
  mailboxId: EmailFolderId;
  customerIds?: CustomerId[] | null;
}

export interface MailNewNotify extends MailInFolderNotify {
  isSilent: boolean;
  updPostId?: EmailId;
}

// входной триггер для приёма из центрифуги
export const handleNewMailReceived = createEvent<MailNewNotify>();
// отфильтровано только прогруженными почтами
export const mailNewReceivedRaw = sample({
  clock: handleNewMailReceived,
  source: $emailList,
  filter: (list, n) => Boolean(list?.some((c) => c.credential_id === n.mailCredId)),
  fn: (_, n) => n,
});
export const noNewMailLeft = createEvent<MailCredentialsId>();
// отфильтровано, когда письма действительно новое
export const mailNewReceived = sample({
  source: mailNewReceivedRaw,
  filter: ({ isSilent }) => !isSilent,
  fn: (p): MailInFolderNotify => p,
});
$emailList.on(
  merge([
    mailNewReceived.map(({ mailCredId }) => ({ id: mailCredId, have_new: true })),
    noNewMailLeft.map((id) => ({ id, have_new: false })),
  ]),
  (list, { id, have_new }) => {
    if (list) {
      const index = list.findIndex((c) => c.credential_id === id);
      if (index >= 0 && list[index].have_new !== have_new) {
        return RoArray.update(list, index, (c) => ({ ...c, have_new }));
      }
    }
  },
);

export const newMailChecked = createEvent<MailInFolderNotify>();
export const $newMailReceivedIn = createStore<
  ReadonlyMap<MailCredentialsId, ReadonlySet<EmailFolderId>>
>(EMPTY_MAP)
  .on(mailNewReceived, (map, { mailCredId, mailboxId }) =>
    RoMap.updateDefault(map, mailCredId, EMPTY_SET, (s) => RoSet.add(s, mailboxId)),
  )
  .on(newMailChecked, (map, { mailCredId, mailboxId }) =>
    RoMap.filter(
      RoMap.update(map, mailCredId, (s) => RoSet.remove(s, mailboxId)),
      (s) => s.size,
    ),
  );
export const $hasNewMails = $newMailReceivedIn.map((c) => !!c.size);

sample({
  clock: mailNewReceived,
  target: newMailNoticeReceived,
  fn: ({ mailCredId, customerIds }): NoticeExt<'mail.new'> => {
    const notice_id = getUniqKeyRnd() as unknown as NoticeId;
    const now = new Date();
    const attributes: NoticeAttribute[] = [
      {
        id: getUniqKeyRnd() as unknown as NoticeAttributeId,
        value: String(mailCredId),
        name: 'mail_id',
        notice_id,
      },
      {
        id: getUniqKeyRnd() as unknown as NoticeAttributeId,
        name: 'customer_team_id',
        value: customerIds || [],
        notice_id,
      },
    ];

    return {
      attributes,
      attributesMap: new Map(attributes.map((attr) => [attr.name, attr])),
      id: notice_id,
      created_at: toISODateTimeString(now),
      its_read: false,
      message: '',
      notice_time: toISODateTimeString(now),
      notice_type: 'mail.new',
      title: '',
      updated_at: toISODateTimeString(now),
    };
  },
});

//desktop notification
mailNewReceived.watch(({ mailCredId, customerIds }) => {
  const mailBox = $emailList.getState()?.find((c) => c.credential_id === mailCredId);
  if (mailBox) {
    const customers = $customers.getState();
    const customerNames =
      customerIds
        ?.map((id) => customers.find((customer) => customer.id === id)?.customerShortName)
        .join(', ') ?? '';
    const n = new Notification(i18next.t('ui:notification.email.title'), {
      body: `${mailBox.email}\n${customerNames}`,
      tag: mailBox.email,
      icon: mailIco,
      timestamp: Date.now(),
    });
    n.onclick = () => {
      history.push(`/email/${mailCredId}`);
      n.close();
    };

    notifyPlaySound();
  }
});
