import { Link } from './Link';
import { Mailto } from './Mailto';

export const TransCommonComponents = {
  b: <strong />,
  i: <em />,
  code: <code />,
  kbd: <kbd />,
  // <mailto>a@b.c</mailto>
  // <mailto>a@b.c|link text</mailto>
  mailto: <Mailto />,

  // // <linkAuto>//...</linkAuto>      => linkExternal
  // // <linkAuto>http://...</linkAuto> => linkExternal
  // // <linkAuto>/path</linkAuto>      => linkLocal
  // TODO: linkAuto: <Link />,
  //  Просто <link> не работает (children всегда указан undefined). Это их баг,
  //  видимо, из-за того, что, что HTML <link/> не имеет дочерних элементов.

  // <linkExternal>//...</linkExternal>
  // <linkExternal>http://...</linkExternal>
  // <linkExternal>http://... link text</linkExternal>
  //   => <a href="http://..." target="_blank" rel="noopener noreferrer">link text</a>
  linkExternal: <Link external />,
  // <linkLocal>/path</linkLocal>
  // <linkLocal>/path link text</linkLocal>
  //   => <Link to="/path">link text</Link>
  linkLocal: <Link external={false} />,
};
