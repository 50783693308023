import { FC, ReactElement } from 'react';
import { useDateFormat } from 'hooks/i18n';

interface Props {
  date?: Date | null;
  /**
   * @see https://date-fns.org/v2.17.0/docs/format
   */
  format?: string;
  empty?: ReactElement | string;
  // TODO: time?: bool; - опция, чтобы выводить <time> элемент? или завести
  //   отдельный компонент для этого? Наверно, лучше этот переименовать в
  //   DateFormatStr, а новый с <time> обозвать DateFormat, и пройтись по всем
  //   существующим использованиям.
}

export const DateFormat: FC<Props> = ({ date, format, empty = null }) => {
  const formatDate = useDateFormat();
  return date ? <>{formatDate(date, format)}</> : typeof empty === 'string' ? <>{empty}</> : empty;
};
