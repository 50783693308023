import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { WithBookkeeper } from 'api/types/request';
import { Notice, NoticeId, NoticeType } from 'models/notices/types';
import { CustomerId } from 'models/customer/types';

export const noticesApi = {
  /** Получение непрочитанных уведомлений*/
  notices: (params: WithBookkeeper, signal?: AbortSignal) =>
    dataOnly.get<null, ApiResponse2<Notice[]>>(`api3/notices`, { params, signal }),

  removeAll: (
    params: WithBookkeeper & { notice_type?: NoticeType; customer_team_id?: CustomerId },
  ) => dataOnly.delete<null, ApiResponse2>(`api3/notices`, { params }),

  remove: (noticeId: NoticeId) => dataOnly.delete<null, ApiResponse2>(`api3/notices/${noticeId}`),
};
