import { FC, lazy, Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Area } from 'react-easy-crop/types';
import { Dialog } from 'ui/feedback';
import { getCroppedImg2 } from 'utils/image/canvasUtils';
import cl from './ImageUpload.module.scss';
import { Upload } from 'ui/input';
import { LoadingPage } from 'components/page';
import { Button2 } from 'ui/button';

const Cropper = lazy(() => import(/* webpackPreload: true */ 'react-easy-crop'));

interface Props {
  onUpload?: (file: Blob) => void;
}

const ACCEPT_LIST = ['.png', '.jpg', '.jpeg', '.gif'];

//todo rotate image from EXIF data

export const ImageUpload: FC<Props> = ({ onUpload }) => {
  const { t } = useTranslation('ui');
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState<string>();
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const handleUpload = useCallback((files: File[] | null) => {
    const file = files?.[0];
    if (!file) return;

    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setOpen(true);

    //todo Этот костыль, рендерим Кропер только после того, как будет показана модалка, иначе иногда при инициализации cropSize был равен 0
    setTimeout(() => {
      setSrc(URL.createObjectURL(file));
    }, 100);
  }, []);

  const handleCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
    if (src) URL.revokeObjectURL(src);
    setSrc(undefined);
  }, [src]);

  const handleClickOk = useCallback(async () => {
    try {
      // const croppedImage = await getCroppedImg(src!, croppedAreaPixels!, 0, 94);
      const croppedImage = await getCroppedImg2(src!, croppedAreaPixels!, 150);
      if (croppedImage) onUpload?.(croppedImage);

      if (src) URL.revokeObjectURL(src);

      setSrc(undefined);
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [src, croppedAreaPixels, onUpload]);

  return (
    <>
      <Upload onChange={handleUpload} accept={ACCEPT_LIST} key={src} />
      <Dialog visible={open} onClose={handleCancel} className={cl.dialog} destroyOnClose>
        {!!src && (
          <div className={cl.cropperContainer}>
            <Suspense fallback={<LoadingPage />}>
              <Cropper
                image={src}
                crop={crop}
                zoom={zoom}
                cropShape="round"
                showGrid={false}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            </Suspense>
          </div>
        )}
        <div className={cl.footer}>
          <Button2 onClick={handleClickOk}>{t('ui:ok')}</Button2>
        </div>
      </Dialog>
    </>
  );
};
