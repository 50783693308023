export const durationSplitHM = (totalMinutes: number): [h: number, m: number] => {
  const min = Math.floor(totalMinutes);
  return [Math.floor(min / 60), min % 60];
};

export const durationSplit = (totalSeconds: number): [h: number, m: number, s: number] => {
  let rest = Math.floor(totalSeconds);
  const s = rest % 60;
  rest = Math.floor(rest / 60);
  return [...durationSplitHM(rest), s];
};

export const durationSplitWithDays = (
  totalSeconds: number,
): [d: number, h: number, m: number, s: number] => {
  let [rest, m, s] = durationSplit(totalSeconds);
  const h = rest % 24;
  rest = Math.floor(rest / 24);
  return [rest, h, m, s];
};
