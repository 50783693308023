import { ApiResponse } from 'api/types';
import { dataOnly } from 'api/instance';
import { Company, CompanyCreateForm, CompanyId, CompanyUpdateParams } from 'models/company/types';
import { IRoleData } from 'models/utils/roles';
import { UserId } from 'models/user/types';

export interface AccessUpdateParams {
  form: IRoleData;
  companyId: CompanyId;
  userId: UserId;
}
export interface DeleteUserParams {
  companyId: CompanyId;
  userId: UserId;
}
const api = {
  list: () => dataOnly.get<null, ApiResponse<Company[]>>(`/api/v1/companys`),

  create: (form: CompanyCreateForm) =>
    dataOnly.post<null, ApiResponse<Company>>('/api/v1/companys', form),

  update: ({ companyId, form }: CompanyUpdateParams) =>
    dataOnly.put<null, ApiResponse<Company>>(`/api/v2/companys/${companyId}`, form),

  access: (params: AccessUpdateParams) =>
    dataOnly.patch<null, ApiResponse<IRoleData>>(
      `/api/v2/companys/useraccess/${params.companyId}/users/${params.userId}`,
      params.form,
    ),

  deleteUser: ({ userId, companyId }: DeleteUserParams) =>
    dataOnly.delete<null, ApiResponse<void>>(
      `/api/v2/companys/deleteuser/${companyId}/users/${userId}`,
    ),
};

export default api;
