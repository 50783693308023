import { CentMessageDataV2 } from './internal';
import { KonturAccountEvent } from '../kontur-notifications/types';

export const enum CentPrivateEventTypeKontur {
  EVENT = 'kontur.newEvent',
  SYNC_END = 'kontur.syncEnd',
}

export type CentPrivateEventKontur =
  | CentMessageDataV2<CentPrivateEventTypeKontur.EVENT, KonturAccountEvent>
  | CentMessageDataV2<CentPrivateEventTypeKontur.SYNC_END>;
