import { FC, ReactNode } from 'react';
import { IView } from '../../types';
import { ButtonDropdown } from '../ButtonDropdown';
import Icon, { IconRef } from '../../Icon';
import cn from 'classnames';
import cl from './HeaderNotification.module.scss';

interface Props extends IView {
  count?: number;
  icon: IconRef;
  tip?: string;
  popupContent: ReactNode;
}

export const HeaderNotification: FC<Props> = ({
  className,
  count = 0,
  icon,
  tip,
  popupContent,
}) => {
  return (
    <ButtonDropdown
      className={cn(cl.root, className)}
      buttonIcon={
        <>
          <Icon type={icon} />
          {count > 0 && <span className={cl.count}>{count}</span>}
        </>
      }
      buttonType="text"
      buttonSize="small"
      buttonClassName={cn(cl.button, count > 0 && cl._hasEvents)}
      buttonTip={tip}
      itemsHeader={popupContent}
      popupClassName={cl.popup}
      popupWrapperClassName={cl.popupWrapper}
      popupRaw
    />
  );
};
