import { FocusEvent, useCallback, useEffect, useState } from 'react';
import { BaseInputProps, HTMLInputElementLike, ValueInputWrapProps } from './types';

const opIdentity = (a: any, b: any) => a === b;

export const useValueInputWrap = <V, I, CA extends any[], Focus = HTMLInputElementLike<I>>({
  value,
  triggerEveryChange = false,
  onChange,
  parseInput,
  formatValue,
  emptyValue,
  isValueEqual = opIdentity,
  isInputEqual = opIdentity,
  onFocus,
  onBlur,
}: ValueInputWrapProps<V, I, Focus, CA>): Required<BaseInputProps<I, Focus, CA>> => {
  const [isFocused, setIsFocused] = useState(false);
  const [ownValue, setOwnValue] = useState(value === undefined ? emptyValue : value);
  const [input, setInput] = useState(formatValue(ownValue));
  const setInputFiltered = useCallback(
    (next: I) => setInput((prev) => (isInputEqual(prev, next) ? prev : next)),
    [isInputEqual],
  );

  const updateInput = useCallback(
    (value: V) => setInputFiltered(formatValue(value)),
    [formatValue, setInputFiltered],
  );

  useEffect(() => {
    if (undefined !== value) {
      setOwnValue(value);
      if (!isFocused) {
        updateInput(value);
      }
    }
  }, [value, updateInput, isFocused]);

  // update input on format change (by locale or props)
  useEffect(() => {
    // REFACT: does it work without warning?
    setOwnValue((ownValue) => {
      setInputFiltered(formatValue(ownValue));
      return ownValue;
    });
  }, [formatValue, setInputFiltered]);

  const handleChange = useCallback(
    (input: I, ...args: CA) => {
      const value = parseInput(input);
      setInputFiltered(input);
      if (!isValueEqual(value, ownValue) || triggerEveryChange) {
        setOwnValue(value);
        onChange?.(value, ...args);
      }
    },
    [ownValue, isValueEqual, parseInput, onChange, triggerEveryChange, setInputFiltered],
  );

  const handleFocus = useCallback(
    (e: FocusEvent<Focus>) => {
      setIsFocused(true);
      onFocus?.(e);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<Focus>) => {
      // const string = ownValue === null ? '' : nFormat.format(ownValue);
      // setInputFiltered(string);
      setIsFocused(false);
      updateInput(ownValue);

      onBlur?.(e);
    },
    [ownValue, onBlur, updateInput],
  );

  return {
    value: input,
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
  };
};
