import { createEffect, createEvent, sample } from 'effector';
import i18next from 'i18next';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import api from 'api/request/user';
import { EMPTY_ARRAY } from 'constants/utils';
import { toastErrorFx } from 'models/utils/messages';
import { showToast, ToastTypes } from 'ui/feedback';
import { fnVoid } from 'utils/fn';
import { ObjectUrl } from 'utils/types/primitive';
import { logout } from '../auth';
import { ChannelId } from '../channel/types';
import { $currentCompanyIdOrNull, deleteUserFx, updateAccessFx } from '../company';
import {
  $avatar,
  $customerDeletedUsers,
  $customerUsers,
  $deletedUsers,
  $ipInfo,
  $locale,
  $user,
  $users,
  deleteAvatarFx,
  fetchUsers,
  getAvatarFx,
  getIpInfoFx,
  getUserFx,
  getUsersFx,
  resetUser,
  resetUsers,
  setAvatarFx,
  setLocale,
  typing,
  typingEnd,
  typingEndFx,
  typingStartFx,
  updateUserFx,
} from './index';
import { AvatarId, UserInfoUpdateForm } from './types';

getUserFx.use(async () => await api.info());
updateUserFx.use(async (form: UserInfoUpdateForm) => await api.update(form));

$user
  .reset(resetUser)
  .on(getUserFx.doneData, (_, { data }) => data)
  .on(updateUserFx.doneData, (s, { data }) => {
    return s ? { ...s, ...data } : s;
  });

//вычислим, что язык был изменен
$user.watch((user) => {
  if (!!user && $locale.getState() !== user.locale) setLocale(user.locale);
});
setLocale.watch((locale) => {
  i18next.changeLanguage(locale).catch(console.log);
});

//------------------------

getIpInfoFx.use(async () => await api.ipInfo());

$ipInfo.on(getIpInfoFx.doneData, (_, { data }) => data);

/** $users - список пользователей организации */
getUsersFx.use(async (companyId: string) => await api.list(companyId));

$users.reset(resetUsers).on(getUsersFx.doneData, (_, { data }) => data.users);
$deletedUsers
  .reset(resetUsers)
  .on(getUsersFx.doneData, (_, { data }) => data.deletedUsers || EMPTY_ARRAY);
$customerUsers.on(getUsersFx.doneData, (_, { data }) => data.customerUsers || EMPTY_ARRAY);
$customerDeletedUsers.on(
  getUsersFx.doneData,
  (_, { data }) => data.deletedCustomerUsers || EMPTY_ARRAY,
);
sample({
  clock: fetchUsers,
  source: $currentCompanyIdOrNull,
  filter: Boolean,
  target: getUsersFx,
});
//изменение прав доступа
$users.on(updateAccessFx.done, (s, { params: { userId }, result: { data } }) =>
  s.map((c) => (c.id === userId ? { ...c, ...data } : c)),
);
//удаление пользователя
$users.on(deleteUserFx.done, (s, { params: { userId } }) => s.filter((c) => c.id !== userId));

/** Typing */
typingStartFx.use(async (channelId) => await api.typingStart(channelId));
typingEndFx.use(async (channelId) => await api.typingEnd(channelId));

// Тот, кто печатает:
// - Отправляет несколько typingStart с интервалами
// - Отправляет один typingStop, когда захочет (но по идее это теперь не обязательно)
//
// Тот, кто наблюдает на другой стороне:
// - пришло typingStart:
//   - добавил в store
//   - отменил предыдущий setTimeout
//   - setTimeout, чтобы удалить из store
// - пришло typingStop:
//   - удалил из store
//   - отменил предыдущий setTimeout
//
// (так делал и в userhorn)

const typingStart = createEvent<ChannelId>();
const typingStop = createEvent<ChannelId>();
const throttleTypingStart = throttle(typingStart, 4000, { trailing: false });
const debounceTypingEnd = debounce(typingStop, 10, { leading: false });

typing.watch(throttleTypingStart);
typingEnd.watch(debounceTypingEnd);

typingStop.watch((channelId) => {
  throttleTypingStart.cancel();
  if (!channelId) return;
  typingEndFx(channelId).catch(fnVoid);
});

typingStart.watch((channelId) => {
  if (!channelId) return;
  typingStartFx(channelId).catch(fnVoid);
});

/** Avatar */
setAvatarFx.use(async (avatar) => await api.setAvatar(avatar));
getAvatarFx.use(async (avatarId) => {
  const blob = await api.avatar(avatarId);
  return URL.createObjectURL(blob) as ObjectUrl;
});

//загрузим аватар при инициализации приложения, после получения инфо пользователя
sample({
  source: sample({
    source: getUserFx.doneData,
    fn: ({ data: { avatar } }) => avatar,
  }),
  filter: Boolean,
  target: getAvatarFx,
});

$avatar.on(getAvatarFx.doneData, (_, url) => url);
$user.on(setAvatarFx.doneData, (s, { data: { avatarid } }) => (s ? { ...s, avatar: avatarid } : s));
sample({
  clock: setAvatarFx.done,
  source: $user,
  target: getAvatarFx,
  fn: ($user) => $user!.avatar,
});

deleteAvatarFx.use(async () => {
  await api.deleteAvatar();
  URL.revokeObjectURL($avatar.getState());
});

$user.on(deleteAvatarFx.doneData, (s) => (s ? { ...s, avatar: '' as AvatarId } : s));
$avatar.reset([deleteAvatarFx.done, logout]);

/** Уведомления */
sample({
  clock: [updateUserFx.fail],
  target: toastErrorFx,
});

sample({
  clock: [updateUserFx.done],
  target: createEffect(() => {
    showToast(ToastTypes.success, i18next.t('profile:messages.successUserUpdate'));
  }),
});
