import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { TaskId } from 'models/task/types';
import {
  RepeatableTaskChecklistAnswer,
  TaskChecklist,
  TaskChecklistCreateForm,
  TaskChecklistIdParams,
  TaskChecklistParamForm,
  TaskChecklistUpdateForm,
} from 'models/task-checklist/types';

export interface TaskChecklistListParams {
  taskId: TaskId;
  params: TaskChecklistParamForm;
}

export interface TaskChecklistCreateParams {
  taskId: TaskId;
  form: TaskChecklistCreateForm;
}

export interface TaskChecklistDeleteParams extends TaskChecklistIdParams {
  params: TaskChecklistParamForm;
}

export interface TaskChecklistUpdateParams extends TaskChecklistIdParams {
  form: TaskChecklistUpdateForm;
}

export const apiTaskChecklist = {
  list: ({ taskId, params }: TaskChecklistListParams) =>
    dataOnly.get<null, ApiResponse2<TaskChecklist[]>>(`/api2/tasks/${taskId}/checklists`, {
      params,
    }),

  create: ({ taskId, form }: TaskChecklistCreateParams) =>
    dataOnly.post<null, ApiResponse2<TaskChecklist | RepeatableTaskChecklistAnswer>>(
      `/api2/tasks/${taskId}/checklists`,
      form,
    ),

  delete: ({ taskId, checklistId, params }: TaskChecklistDeleteParams) =>
    dataOnly.delete<null, ApiResponse2<unknown>>(
      `/api2/tasks/${taskId}/checklists/${checklistId}`,
      {
        data: params,
      },
    ),

  update: ({ taskId, checklistId, form }: TaskChecklistUpdateParams) =>
    dataOnly.patch<null, ApiResponse2<unknown>>(
      `/api2/tasks/${taskId}/checklists/${checklistId}`,
      form,
    ),
};
