import { ChangeEvent, useCallback } from 'react';
import round from 'utils/number/round';
import { HTMLInputElementLike, useValueInputWrap, ValueInputProps } from '../valueInput';
import { parseInteger } from './useIntegreInput';

type V = number | null;
type I = string;
type CA<Element> = [ChangeEvent<Element>];

export interface NumberInputOptions<Element> extends ValueInputProps<V, I, Element, CA<Element>> {
  decimals?: number;
}

const spaces = /\s+/g;
const re = /^[-+]?\d+(\.\d*)?$/;
export const parseNumber = (s: string, decimals: number = 0) => {
  if (decimals === 0) {
    return parseInteger(s);
  }
  const m = s.replace(spaces, '').replace(',', '.').match(re);
  if (m) {
    const n = parseFloat(m[0]);
    if (!isNaN(n)) {
      return round(n, decimals);
    }
  }
  return null;
};

export const useNumberInput = <Element extends HTMLInputElementLike<I> = HTMLInputElement>(
  props: NumberInputOptions<Element>,
) => {
  const { decimals = 0, ...rest } = props;
  if (process.env.NODE_ENV === 'development' && !Object.hasOwn(props, 'decimals')) {
    console.warn(
      'Свойство `decimals` не передано вообще. Поскольку по умолчанию 0, то в этом случае лучше использовать `<IntegerInput/>`.',
    );
  }

  return useValueInputWrap<V, I, CA<Element>, Element>({
    ...rest,
    emptyValue: null,
    formatValue: useCallback(
      (v: V): I => (v === null ? '' : round(v, decimals).toFixed(decimals).replace(/\.0*$/, '')),
      [decimals],
    ),
    parseInput: useCallback((i: I): V => parseNumber(i, decimals), [decimals]),
  });
};
