import { createEffect, createEvent, createStore, Effect, sample } from 'effector';
import { EMPTY_ARRAY } from 'constants/utils';
import { pendingMap } from './pendingMap';

type FxLikeTrigger = Effect<any, any> | ((p: any) => Promise<any>);
type ToEffect<F extends FxLikeTrigger> = F extends Effect<any, any>
  ? F
  : F extends (p: infer P) => Promise<infer R>
  ? Effect<P, R>
  : never;

type EffectsGroupBase = Record<string, FxLikeTrigger>;
type EffectsGroup<G extends EffectsGroupBase> = { [K in keyof G]: ToEffect<G[K]> };

interface Entry {
  readonly fx: FxLikeTrigger;
  readonly arg: any;
  readonly resolve: (result: any) => void;
  readonly reject: (reason: unknown) => void;
}
type Queue = readonly Entry[];

interface Options {
  /** Лимит параллельно выполняемых эффектов. По умолчанию 1. */
  limit?: number;
  // TODO: abort?: Event<any>;
}

/**
 * Ограничиваем кол-во параллельно выполняемых эффектов.
 *
 * Разрешаем выполняться не более `limit` эффектов параллельно. Ограничение
 * действует на всю группу целиком без разбора, какие именно эффекты запускаются.
 * Т.е. один и тот же эффект, будучи вызван несколько раз одновременно, может
 * занять весь лимит.
 *
 * Когда лимит достигнут, следующие вызовы эффектов ставятся в очередь и
 * фактически выполняются позже по мере завершения уже выполняющихся ранее
 * эффектов. Снаружи всё выглядит прозрачно, будто эффекты долго выполняются
 * друг за другом.
 *
 * Каждый из переданных эффектов заворачивается дополнительно в один эффект для
 * дополнительной обработки. Функционально каждый из эффектов остаётся таким же,
 * как соответствующий исходный эффект. Возвращаются обёрнутые эффекты.
 *
 * ```ts
 * const { fetchLoremFx, fetchIpsumFx, fetchDolorFx } = bottleneckFx(
 *   {
 *     fetchLoremFx: createEffect(apiGetLorem),
 *     fetchIpsumFx: createEffect(apiGetIpsum),
 *     fetchDolorFx: apiGetDolor,
 *   },
 *   {
 *     limit: 2,
 *   },
 * );
 * ```
 *
 * @param group
 * @param limit
 *
 * @see https://trello.com/c/3AVrUnvt/605
 */
export const bottleneckFx = <G extends EffectsGroupBase>(group: G, { limit = 1 }: Options = {}) => {
  const runFx = createEffect(async (o: Entry) => {
    const { fx, arg } = o;
    return await fx(arg);
  });
  runFx.done.watch(({ params: { resolve }, result }) => resolve(result));
  runFx.fail.watch(({ params: { reject }, error }) => reject(error));

  const enqueue = createEvent<Entry>();
  // В очереди происходит только лишь добавление и удаление. Иначе логика
  // запуска уже может не подходить.
  const $queue = createStore<Queue>(EMPTY_ARRAY)
    .on(enqueue, (a, v) => [...a, v])
    .on(runFx.finally, (a, { params }) => a.filter((o) => o !== params));

  // какие из записей сейчас выполняются
  const $pending = pendingMap(runFx);

  // Вроде, кажется, что такую штуку сделать просто, но вышло только
  // с третьей-четвёртой мини попытки.
  sample({
    source: sample({
      // Эти две штуки при завершении `runFx` должны обновляться за один раз
      // вместе (и в sample даже есть `greedy` против этого), и т.о. триггерить
      // только один очередной запуск.
      source: {
        q: $queue,
        p: $pending,
      },
      fn: ({ q, p }) => (p.size < limit && q.find((o) => !p.has(o))) || null,
    }),
    filter: Boolean,
    target: runFx,
  });

  // $queue.map((a) => a.length).watch((p) => console.log('>> queue length', p));
  // runFx.inFlight.watch((p) => console.log('>> inFlight', p));
  // runFx.watch(() => console.log('>> runFx()'));
  // runFx.done.watch(() => console.log('>> runFx done'));
  // runFx.fail.watch(() => console.log('>> runFx fail'));
  // runFx.finally.watch(() => console.log('>> runFx finally'));

  return Object.fromEntries(
    Object.entries(group).map(([k, fx]) => [
      k,
      createEffect(
        (arg: any) => new Promise(async (resolve, reject) => enqueue({ fx, arg, resolve, reject })),
      ),
    ]),
  ) as EffectsGroup<G>;
};
