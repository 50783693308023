import { combine, createEffect, createEvent, createStore } from 'effector';
import * as RoArray from '@cubux/readonly-array';
import { ApiResponse } from 'api/types';
import {
  Channel,
  ChannelByGroups,
  ChannelCreateForm,
  ChannelExt,
  ChannelGlobalId,
  ChannelGroup,
  ChannelGroups,
  ChannelId,
  ChannelUpdateForm,
  ChannelUpdateParams,
  ChannelUserForm,
  CustomerChannelsItem,
  TaskChannelExt,
  UserChannelData,
} from './types';
import { CompanyId } from '../company/types';
import { CustomerId } from '../customer/types';
import { AvatarId, CommonUser, TypingData, UserId } from '../user/types';
import { ObjectUrl, UnixTimestampMilli } from 'utils/types/primitive';
import { EMPTY_MAP } from '../../constants/utils';
import { createGate } from 'effector-react';
import { createRequestEffect } from '../utils/createRquestEffect';
import api from 'api/request/channel';
import { $userIsAdmin } from '../user';

export const getNewMsgQty = (channel: Channel) =>
  (channel.total_message_count || 0) - (channel.user_message_count || 0);

const isCustomerGroup = (key: ChannelGroup): key is 'customers' => key === 'customers';

export const updateChannelsByGroup = (
  channelsByGroup: ChannelByGroups,
  channelId: ChannelId,
  data: ((channel: Channel) => Partial<Channel>) | Partial<Channel>,
) => {
  for (const key in channelsByGroup) {
    if (isCustomerGroup(key as ChannelGroup)) {
      let isInner = false;
      const item = channelsByGroup.customers.find((c) => {
        if (c.channels[0].channel_id === channelId) {
          return true;
        }
        if (c.inner_channel?.channel_id === channelId) {
          isInner = true;
          return true;
        }
        return false;
      });
      if (item) {
        const channel = isInner ? item.inner_channel! : item.channels[0];
        if (typeof data === 'function') {
          data = data(channel);
        }
        Object.assign(channel, data);
        return { ...channelsByGroup, customers: [...channelsByGroup.customers] };
      }
    } else {
      const channel = channelsByGroup[key as 'internal_channels' | 'internal_users_channels'].find(
        (c) => c.channel_id === channelId,
      );
      if (channel) {
        if (typeof data === 'function') {
          data = data(channel);
        }
        Object.assign(channel, data);
        return {
          ...channelsByGroup,
          [key]: [...channelsByGroup[key as 'internal_channels' | 'internal_users_channels']],
        };
      }
    }
  }

  return channelsByGroup;
};
/** $channelsByGroup Стор содержит оригинальные данные из запроса */
export const $channelsByGroup = createStore<ChannelByGroups | null>(null);
export const $channelsList = $channelsByGroup.map((c) => {
  let arr: Channel[] = [];
  if (!c) return arr;
  Object.keys(c).forEach((k) => {
    const group = c[k as ChannelGroup];
    if (!group) return;

    if (k === 'customers') {
      (group as CustomerChannelsItem[]).forEach((c) => {
        arr = arr.concat(c.channels);
      });
    } else {
      arr = arr.concat(group as Channel[]);
    }
  });
  return arr;
});

export const updateCustomersChannelLastNotice =
  createEvent<ReadonlyMap<CustomerId, UnixTimestampMilli>>();
$channelsByGroup.on(updateCustomersChannelLastNotice, (cg, update) => {
  if (!cg || !update.size) {
    return cg;
  }
  let customers: readonly CustomerChannelsItem[] = cg.customers;
  update.forEach((last_notice_at, customerId: string) => {
    customers = RoArray.updateMatch(
      customers,
      (c) => c.id === customerId,
      // TODO: или здесь надо сравнивать, что больше - старое или новое?
      //  (c) =>
      //    (c.channels[0].last_notice_at ?? 0) > last_notice_at
      //      ? c
      //      : {
      //          ...c,
      //          channels: RoArray.update(c.channels, 0, (ch) => ({ ...ch, last_notice_at })),
      //        },
      (c) => ({
        ...c,
        channels: RoArray.update(c.channels, 0, (ch) => ({
          ...ch,
          last_notice_at: last_notice_at,
        })),
      }),
    );
  });
  if (customers === cg.customers) {
    return cg;
  }
  return { ...cg, customers: [...customers] };
});

export const $customersChannelsItems = $channelsByGroup.map((c) => (c ? c.customers || [] : []));
export const $channelCustomerMap = $customersChannelsItems.map(
  (c) => new Map(c.map((c) => [c.channels[0].channel_id, c.id])),
);
export const $customerChannelsMap = $customersChannelsItems.map(
  (c) => new Map(c.map((c) => [c.id, c.channels[0]])),
);
export const $internalChannels = $channelsByGroup.map((c) => (c ? c.internal_channels : []));
export const $internalUsersChannels = $channelsByGroup.map((c) =>
  c ? c.internal_users_channels : [],
);
export const $channelExtendedList = combine<
  Channel[],
  Channel[],
  CustomerChannelsItem[],
  ChannelExt[]
>(
  $internalChannels,
  $internalUsersChannels,
  $customersChannelsItems,
  (a, b, customerChannelsItems) =>
    a
      .map<ChannelExt>((c) => ({
        ...c,
        group: ChannelGroups.internal_channels,
      }))
      // FIXME? в каналах юзеров id !== channel_id - возможно, это является проблемой - я не знаю
      //  - либо тут всё нормально, и тогда $channelsNameMap остаётся в силе, и надо упростить этот коммент
      //  - либо тут косяк, и надо исправить тут, и упростить $channelsNameMap
      .concat(b.map((c) => ({ ...c, group: ChannelGroups.internal_users_channels })))
      .concat(
        customerChannelsItems.reduce(
          (prev: ChannelExt[], item) =>
            prev.concat(
              item.channels.map((c) => ({
                ...c,
                name: item.name,
                group: ChannelGroups.customers,
              })),
            ),
          [],
        ),
      )
      .concat(
        customerChannelsItems
          .filter((c) => !!c.inner_channel)
          .map((c) => ({
            ...c.inner_channel!,
            group: ChannelGroups.customers,
          })),
      ),
);

export const $channelExtendedMap = $channelExtendedList.map(
  (c) => new Map(c.map((c) => [c.id, c])),
);

export const $channelExtendedMapByChannelId = $channelExtendedList.map(
  (c) => new Map(c.map((channel) => [channel.channel_id, channel])),
);

export const $channelsNameMap = $channelExtendedList.map(
  (a) =>
    new Map<ChannelGlobalId | ChannelId, string>([
      ...a.map((c) => [c.id, c.name] as const),
      // в каналах юзеров id !== channel_id, поэтому в $channelExtendedList
      // нельзя найти такой канал
      ...a
        .filter((c) => c.group === ChannelGroups.internal_users_channels)
        .map((c) => [c.channel_id!, c.name] as const),
    ]),
);

/** $showDeletedUsersChannels */
export const $showDeletedUsersChannels = createStore(false);
export const toggleShowDeletedUsersChannels = createEvent();

const getActualList = (list: Channel[]) => list.filter((c) => !c.delete_at);

export const $customersChannelsItemsActual = $customersChannelsItems.map((items) =>
  items.filter((c) => !c.channels[0].delete_at),
);
export const $customersChannelsItemsActualMap = $customersChannelsItemsActual.map(
  (items) => new Map(items.map((item) => [item.id, item])),
);
export const $internalChannelsActual = $internalChannels.map(getActualList);
export const $internalUsersChannelsActual = combine(
  $internalUsersChannels,
  $showDeletedUsersChannels,
  (channels, show) => (show ? channels : getActualList(channels)),
);

/** $channelsCommonMap */
export const $channelsCommonMap = createStore<ReadonlyMap<ChannelGlobalId, Channel>>(EMPTY_MAP);

export const $internalChannelsMap = $internalChannels.map((c) => new Map(c.map((c) => [c.id, c])));
export const $internalUsersChannelsMap = $internalUsersChannels.map(
  (c) => new Map(c.map((c) => [c.id, c])),
);

export const $internalChannelsFetchInfo = createStore<{ didFetched: boolean; isFetching: boolean }>(
  { didFetched: false, isFetching: false },
);

export const getChannelsFx = createEffect<CompanyId, ApiResponse<ChannelByGroups>>();
export const createChannelFx = createEffect<ChannelCreateForm, ApiResponse<Channel>>();

/** $globalToChannelIdMap Позволяет получить ChannelId по GlobalId*/
export const $globalToChannelIdMap = createStore<Map<ChannelGlobalId, ChannelId | null>>(new Map());

/** $ChannelToGlobalIdMap Позволяет получить GlobalId по ChannelId*/
export const $channelToGlobalIdMap = createStore<Map<ChannelId, ChannelGlobalId>>(new Map());

export const updateChannelId = createEvent<{
  channelGlobalId: ChannelGlobalId;
  channelId: ChannelId;
}>();

export const CurrentChannelGate = createGate<{ channelId: ChannelGlobalId }>();

export const $currentChannel = createStore<ChannelExt | TaskChannelExt | null>(null);
export const $currentChannelId = $currentChannel.map(
  (currentChannel) => currentChannel?.channel_id,
);

/** $globalChannelId id канала из урл */
export const $globalChannelId = CurrentChannelGate.state.map<ChannelGlobalId | null>(
  (c) => c?.channelId ?? null,
);

/** $postId */
// export const $postId = CurrentChannelPostGate.state.map((c) => (c ? c.postId : null));

/** $newPostsChannelsMap */
//todo после перехода на сортировку каналов и для сотрудников избавится от этого стора и всех связанных с ним
export const $newPostsChannelsMap = createStore<Map<ChannelGlobalId, number>>(new Map());

const getTotalNewMsg = (channels: Channel[]) =>
  channels.reduce(
    (total, channel) => (channel.delete_at ? total : getNewMsgQty(channel) + total),
    0,
  );

export const $newPostUserChannelsTotal = $channelsByGroup.map((c) =>
  c ? getTotalNewMsg(c.internal_channels) + getTotalNewMsg(c.internal_users_channels) : 0,
);

/** $newPostsChannels */
export const $newPostsChannels = $newPostsChannelsMap.map((c) =>
  Array.from(c, ([_, item]) => item),
);

/** $newPostsTotal */
export const $newPostsTotal = $newPostsChannelsMap.map<number>((c) => {
  let total = 0;
  c.forEach((c) => (total += c));
  return total;
});
/** $hasNewPosts */
export const $hasNewPosts = $newPostsTotal.map((c) => c > 0);

export const $currentChannelCustomerId = combine(
  $currentChannel,
  $customersChannelsItems,
  (ch, cu) => (ch && cu.find((cu) => cu.channels.some((c) => c.id === ch.id))?.id) || null,
);

export const $useTabsForUserChat = combine(
  $userIsAdmin,
  $globalChannelId,
  $internalUsersChannelsMap,
  (isAdmin, channelId, map) => isAdmin && Boolean(map.get(channelId as ChannelGlobalId) ?? false),
);

/** Channel's user list */
/** юзеры для выбранного канала*/
export const getUserChannelListFx = createEffect<ChannelId, ApiResponse<UserChannelData[]>>();
export const resetUserChannelList = createEvent();

export const deleteUserChannel = createEvent<UserId>();
export const deleteUserChannelFx = createEffect<
  { channelId: ChannelId; userId: UserId },
  ApiResponse<UserChannelData>
>();

export const addUserChannel = createEvent<string>();
export const addUserChannelFx = createEffect<ChannelUserForm, ApiResponse<UserChannelData>>();

export const $userChannelList = createStore<UserChannelData[]>([]).reset(resetUserChannelList);

export const fetchChannels = createEvent();
export const receiveUpdateChannel = createEvent();

/** Typing */
export const receiveTypingStart = createEvent<TypingData>();
export const receiveTypingEnd = createEvent<TypingData>();
export const $channelTypingMap =
  createStore<ReadonlyMap<ChannelId, ReadonlySet<CommonUser>>>(EMPTY_MAP);

/** $userAvatarMap */
export const $userAvatarMap = createStore<ReadonlyMap<UserId, ObjectUrl>>(EMPTY_MAP);
export const getUserAvatarsFx = createEffect<
  { userId: UserId; avatarId: AvatarId }[],
  { userId: UserId; url: ObjectUrl }[]
>();

/** Delete channel */
export const deleteChannel = createEvent<ChannelId>();
export const deleteChannelPrompt = createEvent<ChannelId>();
export const deleteChannelFx = createEffect<ChannelId, ApiResponse<Channel>>();

/** Update channel */
export const updateChannel = createEvent<ChannelUpdateParams>();
export const updateCurrentChannel = createEvent<ChannelUpdateForm>();
export const updateChannelFx = createEffect<ChannelUpdateParams, ApiResponse<Channel>>();

/** Task channels */
export const fetchTaskChannelFx = createRequestEffect(api.getTaskChannel);
export const fetchTaskTypicalChannelFx = createRequestEffect(api.getTaskTypicalChannel);

/** Telegram channels */
