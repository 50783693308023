import { AxiosResponse } from 'axios';
import ApiClientError from './4xx';
import { isAlias422Fields } from 'api/utils/alias422Fields';
import { hasProperty } from 'utils/object';

export interface ValidationError {
  failed_field: string;
  message: string;
  tag: string;
  value: string;
}

export type ValidationErrors = ReadonlyArray<ValidationError>;

export type ValidationErrorsMap<K extends keyof any> = Partial<Record<K, string>>;

const isValidationErrors = (data: any): data is ValidationErrors =>
  Array.isArray(data) &&
  data.every(
    (item) =>
      Boolean(item) &&
      typeof item.message === 'string' &&
      // (
      // !Object.hasOwn(item, 'failed_field') ||
      typeof item.failed_field === 'string',
    // ),
  );

const tryDecodeOtherErrors = (body: unknown): ValidationErrors | null => {
  // <webapi>
  // /api/v1/users/...
  // =>
  // {
  //   "errors": [
  //     {
  //       "field": "...",
  //       "message": "..."
  //     }
  //   ]
  // }
  if (hasProperty(body, 'errors') && Array.isArray(body.errors)) {
    interface E {
      field: string;
      message: string;
    }

    const isE = (v: unknown): v is E =>
      hasProperty(v, 'field') &&
      hasProperty(v, 'message') &&
      typeof v.field === 'string' &&
      typeof v.message === 'string';

    if (body.errors.every(isE)) {
      return body.errors.map(({ field, message }) => ({
        failed_field: field,
        message,
        tag: '',
        value: '',
      }));
    }
  }

  return null;
};

export default class ApiUnprocessableError extends ApiClientError {
  readonly errors: ValidationErrors;

  constructor(response: AxiosResponse) {
    super(response);

    const { data, config } = response;
    this.errors =
      hasProperty(data, 'data') && isValidationErrors(data.data)
        ? data.data
        : isValidationErrors(data)
          ? data
          : tryDecodeOtherErrors(data) || [];

    if (isAlias422Fields(config)) {
      const aliases = config._$app_422Aliases;
      const newErrors: ValidationError[] = [];
      for (const { failed_field, ...rest } of this.errors) {
        newErrors.push({
          failed_field:
            failed_field && Object.hasOwn(aliases, failed_field)
              ? aliases[failed_field]
              : failed_field,
          ...rest,
        });
      }

      this.errors = newErrors;
    }
  }

  getErrorsMap<K extends keyof any>(): ValidationErrorsMap<K> {
    const map: Partial<Record<keyof any, string>> = {};
    for (const { failed_field, message } of this.errors) {
      map[failed_field] = message;
    }
    return map;
  }
}
