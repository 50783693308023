import { AxiosResponse } from 'axios';
import { parseContentDispositionFilename } from 'utils/string';
import { AxiosRequestCustomConfig } from '../instance';

const parseName = (input: string | undefined): string | undefined =>
  input && parseContentDispositionFilename(input);

export const onFulfillFile = async ({ headers, data, config }: AxiosResponse<Blob>) => {
  const { _fileName } = config as AxiosRequestCustomConfig;
  const fileName =
    _fileName ||
    parseName(headers['content-disposition']) ||
    parseName(headers['content-type']) ||
    'unknownFile';

  return new File([data], fileName, {
    type: headers['content-type'] || undefined,
  });
};
