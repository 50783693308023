import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import Icon from 'ui/Icon';
import cl from './Selector.module.scss';
import cn from 'classnames';
import { Popover2 } from '../Popover2';

interface Props {
  className?: string;
  label: ReactNode;
  onOpen?: () => void;
}

/** @deprecated Используется лишь в одном месте, о при этом по своей сути повторяет ButtonDropdown */
export const Selector: FC<Props> = ({ children, label, onOpen, className }) => {
  const anchor = useRef<HTMLDivElement>(null);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  useEffect(() => {
    if (isOpenPopover) {
      onOpen?.();
    }
  }, [isOpenPopover, onOpen]);

  useEffect(() => {
    // Prevent: Can't perform a React state update on an unmounted component
    return () => {};
  }, []);

  return (
    <>
      <div
        ref={anchor}
        className={cn(cl.root, className, isOpenPopover && cl.open)}
        onClick={setIsOpenPopover.bind(null, !isOpenPopover)}
      >
        <span>{label}</span>
        <Icon type="Down" className={cl.ico} />
      </div>
      <Popover2
        isShow={isOpenPopover}
        anchorEl={anchor.current}
        placement="bottom-start"
        onClickOutside={setIsOpenPopover.bind(null, false)}
        className={cl.container}
        rawPopup
        withPortal
      >
        <span onClick={setIsOpenPopover.bind(null, false)}>{children}</span>
      </Popover2>
    </>
  );
};
