import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import {
  TaskFinance,
  TaskFinanceForm,
  TaskFinanceReportMonthItem,
  TaskFinanceReportMonthParams,
  TaskFinanceTarget,
} from 'models/task-finance/types';

const URL = `/api2/tasks/finance`;

export const apiTaskFinanceGet = (params: TaskFinanceTarget, signal?: AbortSignal) =>
  dataOnly.get<null, ApiResponse2<TaskFinance, true>>(URL, { params, signal });

export const apiTaskFinanceSave = (form: TaskFinanceForm, signal?: AbortSignal) =>
  dataOnly.patch<null, ApiResponse2<TaskFinance, true>>(URL, form, { signal });

export const apiTaskFinanceReportMonth = (
  params: TaskFinanceReportMonthParams,
  signal?: AbortSignal,
) =>
  dataOnly.get<null, ApiResponse2<readonly TaskFinanceReportMonthItem[]>>(`${URL}/report-month`, {
    params,
    signal,
  });
