export enum Roles {
  admin = 'admin',
  user = 'user',
  guest = 'guest',
  blocked = 'blocked',
}
export interface IRoleData {
  schemeuser: boolean;
  schemeadmin: boolean;
  schemeguest: boolean;
}

export const toRoleData = (role: Roles): IRoleData => {
  switch (role) {
    case Roles.admin:
      return { schemeuser: false, schemeadmin: true, schemeguest: false };
    case Roles.user:
      return { schemeuser: true, schemeadmin: false, schemeguest: false };
    case Roles.guest:
      return { schemeuser: false, schemeadmin: false, schemeguest: true };
    default:
      return { schemeuser: false, schemeadmin: false, schemeguest: false };
  }
};

export const toRole = (roleData: IRoleData): Roles => {
  if (roleData.schemeadmin) return Roles.admin;
  else if (roleData.schemeuser) return Roles.user;
  else if (roleData.schemeguest) return Roles.guest;

  return Roles.blocked;
};
