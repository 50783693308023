import cn from 'classnames';
import { FC } from 'react';
import { PaginatedResponse2 } from 'api/types';
import { IView } from '../../types';
import { Button2 } from '../Button';
import cl from './PagerClassic.module.scss';

interface Props extends Partial<PaginatedResponse2>, IView {
  siblings?: number;
  showTheOnly?: boolean;
  onPageChange: (page: number) => void;
  disabled?: boolean;
}

// чтобы отличать абсолютное значение 1 от дельты ±1
const FIRST = 1;

export const PagerClassic: FC<Props> = ({
  page = 1,
  per_page = 0,
  total = 0,
  siblings = 2,
  showTheOnly = false,
  onPageChange,
  className,
  disabled = false,
  ...rest
}) => {
  const maxPage = per_page > 0 ? Math.ceil(total / per_page) : total > 0 ? 1 : 0;
  if (maxPage <= 1 && !showTheOnly) {
    return null;
  }

  let start = Math.max(1, page - siblings);
  let end = Math.min(maxPage, page + siblings);
  // чтобы не скрывать в "..." одну кнопку: [1]...[3][4][5]...[7]
  // и не делать условий ещё и для показа "..." : [1]...[2][3][4]...[7]
  if (start <= FIRST + 2) {
    start = FIRST;
  }
  if (end >= maxPage - 2) {
    end = maxPage;
  }
  const pages = Array.from({ length: end - start + 1 }).map((_, i) => start + i);

  return (
    <div {...rest} className={cn(cl.root, className)}>
      {start > FIRST && (
        <>
          <Button2
            size="small"
            disabled={disabled || page === 1}
            onClick={() => onPageChange(FIRST)}
          >
            1
          </Button2>
          ...
        </>
      )}
      {pages.map((n) => (
        <Button2
          key={n}
          size="small"
          disabled={disabled || page === n}
          onClick={() => onPageChange(n)}
        >
          {n}
        </Button2>
      ))}
      {end < maxPage && (
        <>
          ...
          <Button2
            size="small"
            disabled={disabled || page === maxPage}
            onClick={() => onPageChange(maxPage)}
          >
            {maxPage}
          </Button2>
        </>
      )}
    </div>
  );
};
