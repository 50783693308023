import { AxiosResponse } from 'axios';
import { parseDate } from 'utils/date';
import { hasProperty } from 'utils/object';
import ApiServerError from './5xx';

const h = 'retry-after';

export default class ApiServiceUnavailable<TResult = any> extends ApiServerError<TResult> {
  readonly retryAfter?: Date;

  constructor(response: AxiosResponse) {
    super(response);

    try {
      const s = response.headers[h];
      if (s) {
        this.retryAfter = new Date(s);
      } else {
        const d: unknown = response.data;
        if (hasProperty(d, 'data') && hasProperty(d.data, 'retry_after')) {
          const after = d.data.retry_after;
          if (typeof after === 'string') {
            this.retryAfter = parseDate(after) || undefined;
          }
        }
      }
    } catch {}
  }
}
