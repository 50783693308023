import { AxiosResponse } from 'axios';
import { hasProperty } from 'utils/object';

const CT = 'content-type';

export class ApiErrorBase<TResult = any> extends Error {
  response: AxiosResponse<TResult>;

  constructor(
    response: AxiosResponse<TResult>,
    message: string = hasProperty(response.data, 'message') &&
    typeof response.data.message === 'string'
      ? response.data.message
      : typeof response.data === 'string' &&
          hasProperty(response.headers, CT) &&
          typeof response.headers[CT] === 'string' &&
          /^text\/plain($|;)/.test(response.headers[CT])
        ? response.data.substring(0, 200)
        : response.statusText,
  ) {
    super(message);

    this.response = response;
  }
}
