import { CustomerEmployeeId, CustomerId } from './types';
import { sortDecorate, strCmp } from '../../utils/fn';

export interface CustomerUserLight {
  id: CustomerEmployeeId;
  name: string;
  email: string;
}

export interface CustomerUserLightUnscoped extends CustomerUserLight {
  cId: CustomerId;
}

export const sortCustomerUsersLight: <C extends CustomerUserLight>(list: readonly C[]) => C[] =
  sortDecorate(
    <C extends CustomerUserLight>(c: C) => [c, c.name.toUpperCase()] as const,
    ([c]) => c,
  )(([, aN], [, bN]) => strCmp(aN, bN));
