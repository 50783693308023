import { FC } from 'react';
import cl from './Divider.module.scss';
import cn from 'classnames';

interface Props {
  className?: string;
}

export const Divider: FC<Props> = ({ className }) => {
  return <div className={cn(cl.root, className)} />;
};
