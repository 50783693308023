import { WithBookkeeper } from 'api/types/request';
import { htmlToPlainText } from 'utils/string';
import { DateISO, DateTimeISO, IdOf } from 'utils/types';
import { CompanyId } from '../company/types';
import { CustomerId } from '../customer/types';
import { Task, TaskId } from '../task/types';
import { TimerSubcategoryId } from '../timer-categories/types';
import { UserId } from '../user/types';

export interface TimersListParams extends WithBookkeeper {
  /** Дата выборки */
  date: DateISO;
  filter_by_bookkeeper?: boolean;
  for_user_id: UserId;
  is_typical?: boolean;
}
export interface TimersExportOwn {
  /** Дата, за которую нужно получить информацию */
  date: string;
  for_user_id: UserId;
}

export interface TimerTotals {
  total_time: string;
  total_time_sec: number;
}

export type TimerId = IdOf<'Timer'>;
export interface Timer {
  readonly id: TimerId;
  bookkeeper_team_id: CompanyId;
  date: DateTimeISO;
  spent_sec: number;
  /** Признак, что таймер запущен */
  started_at: string | null;

  team_id: CustomerId | null;
  time_start: string;
  time_end: string;
  title: string;
  subcategories?: readonly TimerSubcategoryId[];
  task_id?: TaskId;
  typical_task_id?: TaskId;
  task?: Pick<Task, 'id' | 'text' | 'status'>;
  total?: TimerTotals;
}

export const stripTimerTaskText = (timer: Timer) =>
  timer.task
    ? {
        ...timer,
        task: {
          ...timer.task,
          text: htmlToPlainText(timer.task.text),
        },
      }
    : timer;

export interface WithTimerId {
  timer_id: TimerId;
}
export interface WithTZ {
  /**
   * Оффсет в минутах.
   *
   * - minimum: -840
   * - maximum: 840
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
   */
  client_timezone_offset?: number;
}
export const getTZParams = (): Required<WithTZ> => ({
  client_timezone_offset: new Date().getTimezoneOffset(),
});
export interface TimerCreateForm extends TimerUpdateForm {
  date: string;
}
export interface TimerCreateParams extends TimerCreateForm, WithBookkeeper, WithTZ {}
export interface TimerUpdateForm {
  is_typical?: boolean;
  /** Привязка к задаче */
  task_id?: TaskId;
  /**
   * ID клиентской компании, для которой учитывается затраченное время
   * 🔸можно менять значение только вместе с task_id, либо если task_id пусто в бд
   */
  team_id?: CustomerId | null;
  time_start?: string;
  time_end?: string;
  title?: string;
  subcategories?: readonly TimerSubcategoryId[];
}
export interface TimerUpdateParams extends TimerUpdateForm, WithBookkeeper {}
export interface TimerToggleParams extends WithTZ {}
export const getTimerToggleParams = (): Required<TimerToggleParams> => getTZParams();

export interface TimerStartToggle extends TimerToggleParams, WithBookkeeper {
  is_typical?: boolean;
  task_id: TaskId;
  /** ID клиентской компании, для которой учитывается затраченное время */
  team_id?: CustomerId;
}
interface ToggleBase {
  started: Timer | null;
  paused: readonly Timer[] | null;
}
export interface TimerToggleStarted extends ToggleBase {
  started: Timer;
}
export interface TimerTogglePaused extends ToggleBase {
  started: null;
  paused: readonly Timer[];
}
export type TimerToggle = TimerToggleStarted | TimerTogglePaused;
