import { ILanguage, languagesMap } from 'i18n';
import useLanguage from './useLanguage';

interface LanguageConfigSelector<T> {
  //todo tmp make optional
  (config?: ILanguage): T;
}

interface UseLanguageConfigHook {
  (selector?: undefined): ILanguage;

  <T>(selector: LanguageConfigSelector<T>): T;
}

const useLanguageConfig: UseLanguageConfigHook = (selector?: LanguageConfigSelector<any>) => {
  const lang = useLanguage();
  const language = languagesMap.get(lang);
  if (undefined === selector) {
    return language;
  }
  return selector(language);
};

export default useLanguageConfig;
