import { CSSProperties, FC, MouseEventHandler } from 'react';
import cl from './TagItem2.module.scss';
import { CommonTag } from 'models/tags/types';
import cn from 'classnames';
import { TextButton2 } from '../../button';

// REFACT: если связано с моделями, то должно быть в /components/...?

interface Props {
  tag: CommonTag;
  className?: string;
  style?: CSSProperties;
  isHoverForDelete?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDeleteItem?: () => void;
}

export const TagItem2: FC<Props> = ({
  tag,
  className,
  style,
  isHoverForDelete = false,
  onClick,
  onDeleteItem,
}) => {
  return (
    <div
      className={cn(cl.root, isHoverForDelete && cl.isHoverForDelete, className)}
      style={{ backgroundColor: tag.color, ...style }}
      onClick={onClick}
      title={tag.tag}
    >
      <span>{tag.tag}</span>
      {onDeleteItem && (
        <TextButton2 icon="Cross" size="small" className={cl.btnDelete} onClick={onDeleteItem} />
      )}
    </div>
  );
};
