import { MouseEvent } from 'react';
import { createEvent, createStore } from 'effector';
import { LSKey } from '../persistent';
import { Theme } from './types';

export const $theme = createStore<Theme | ''>((localStorage.getItem(LSKey.theme) as Theme) || '');

//тут хранятся координаты клика по кнопке, чтобы можно было использовать для анимаций
export const $meta = createStore<{ top: number; left: number }>({ top: 0, left: 0 });

export const switchTheme = createEvent<MouseEvent<HTMLButtonElement>>();
export const handleSwitchTheme = switchTheme.prepend((e: MouseEvent<HTMLButtonElement>) => e);
