import { createRequestEffect } from 'models/utils/createRquestEffect';
import { uisApi } from 'api/request/uis';
import { createEvent, createStore, sample } from 'effector';
import { $currentCompanyIdOrNull } from 'models/company';
import { UISDataFetchForm, UISEmployee } from './types';
import { EMPTY_ARRAY } from 'constants/utils';

// export const UISEmployeesGate = createGate();

export const fetchUISEmployees = createEvent();
const fetchUISEmployeesFx = createRequestEffect(uisApi.employees);
export const $pending = fetchUISEmployeesFx.pending;
// forward({ from: UISEmployeesGate.open, to: fetchUISEmployees });

export const $UISEmployees = createStore<UISEmployee[]>(EMPTY_ARRAY);
export const $UISEmployeesMap = $UISEmployees.map((c) => new Map(c.map((c) => [c.id, c])));
sample({
  clock: fetchUISEmployees,
  source: $currentCompanyIdOrNull,
  target: fetchUISEmployeesFx,
  fn: (companyId): UISDataFetchForm => ({
    bookkeeper_team_id: companyId!,
  }),
});
$UISEmployees.on(fetchUISEmployeesFx.doneData, (_, { data }) => data);
