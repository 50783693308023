import { combine, createEvent, restore, sample } from 'effector';
import { apiMembersSettingsList } from 'api/request/teams';
import { EMPTY_MAP } from 'constants/utils';
import { $currentCompanyId } from '../company';
import { $userId, $userIsAdmin } from '../user';
import { createRequestEffect } from '../utils/createRquestEffect';
import { MemberSettings, MemberSettingsName } from './types';

export const fetchMySettings = createEvent();

const fetchTeamSettingsFx = createRequestEffect(apiMembersSettingsList);

sample({
  clock: [fetchMySettings, $currentCompanyId.updates],
  source: $currentCompanyId,
  filter: Boolean,
  target: fetchTeamSettingsFx,
});

type _V = boolean; //todo depend from MemberSettings

const $ownSettings = restore(
  sample({
    clock: fetchTeamSettingsFx.doneData,
    source: $userId,
    filter: Boolean,
    fn: (userId, { data }): ReadonlyMap<MemberSettingsName, _V> =>
      new Map(
        data
          // для админа возвращаются все юзеры
          .filter((c) => c.user_id === userId)
          .map(({ name, value }) => [name, value === 'true']),
      ),
  }),
  EMPTY_MAP,
).reset($userId.updates, $currentCompanyId.updates);

const ADMIN_SETTINGS = new Map<MemberSettingsName, _V>([
  [MemberSettings.RestrictCustomers, false],
  [MemberSettings.RestrictEmail, false],
  [MemberSettings.AllowCreateCustomers, true],
]);

export const $myActualSettings = combine($userIsAdmin, $ownSettings, (isAdmin, ownSettings) =>
  isAdmin ? ADMIN_SETTINGS : ownSettings,
);

export const $myActualSettings_restrictCustomers = $myActualSettings.map(
  (m) => m.get(MemberSettings.RestrictCustomers) ?? false,
);
export const $myActualSettings_restrictEmail = $myActualSettings.map(
  (m) => m.get(MemberSettings.RestrictEmail) ?? false,
);
export const $myActualSettings_allowCreateCustomers = $myActualSettings.map(
  (m) => m.get(MemberSettings.AllowCreateCustomers) ?? false,
);
export const $myActualSettings_bulkNotification = $myActualSettings.map(
  (m) => m.get(MemberSettings.BulkNotification) ?? false,
);
export const $myActualSettings_restrictCustomersInfo = $myActualSettings.map(
  (m) => m.get(MemberSettings.RestrictCustomersInfo) ?? false,
);
