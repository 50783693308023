type Source = ReadonlyMap<number | string, readonly (number | string)[]>;
// type OK = CustomersTypicalFilter extends Source ? true : false;
// => true

interface Fallback {
  append(name: string, value: string | Blob, fileName?: string): void;
}

type Params = URLSearchParams | FormData | Fallback;

// мне понадобилось в другом месте, поэтому я вынес, предварительно написав
// тесты для старого кода, где был `Array.from().filter().join()`

interface Options<P extends Params> {
  /** default `new URLSearchParams()` */
  to?: P;
  /** default `query` */
  param?: string;
  /** default `id` */
  paramId?: string;
  /** default `values` */
  paramValues?: string;
}
interface OptionsTo<P extends Params> extends Options<P> {
  to: P;
}

export function serializeFieldsFilter<P extends Params>(f: Source, o: OptionsTo<P>): P;
export function serializeFieldsFilter(f: Source, o?: Options<never>): URLSearchParams;
export function serializeFieldsFilter(
  f: Source,
  {
    to = new URLSearchParams(),
    param = 'query',
    paramId = 'id',
    paramValues = 'values',
  }: Options<Params> = {},
) {
  // ?query[0][id]=1
  // &query[0][values][]=1
  // &query[0][values][]=2
  // &query[1][id]=7
  // &query[1][values][]=7
  let i = 0;
  f.forEach((value, key) => {
    if (!value.length) return;
    const prefix = `${param}[${i}]`;
    i++;

    to.append(`${prefix}[${paramId}]`, String(key));
    for (const v of value) {
      to.append(`${prefix}[${paramValues}][]`, String(v));
    }
  });
  return to;
}
