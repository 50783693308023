import {
  $companies,
  $currentCompanyIdOrNull,
  createCompanyFx,
  deleteUser,
  deleteUserFx,
  getCompaniesFx,
  initAttachCompanyDataFx,
  resetCompanies,
  revertAccess,
  setCurCompany,
  toggleAccess,
  toggleBlockAccess,
  updateAccess,
  updateAccessFx,
  updateCompanyFx,
} from './index';
import api, { AccessUpdateParams, DeleteUserParams } from 'api/request/company';
import { getChannelsFx } from '../channel';
import { fnVoid } from 'utils/fn';
import { fetchUsers, getUsersFx } from '../user';
import { history } from 'utils/history';
import { createEffect, sample } from 'effector';
import { Roles, toRole, toRoleData } from 'models/utils/roles';
import { CommonUser } from 'models/user/types';
import { promptFn, showToast, showToastError, ToastTypes } from 'ui/feedback';
import i18next from 'i18next';
import { fetchIntegrationListFx } from '../integrations';
import { fetchEmailListFx } from '../mail-credentials-users';
import { LSKey } from '../persistent';

import './cent';
import { fetchNoticesFx } from '../notices';
import { fetchCustomerTagsFx } from '../customers-tags';

getCompaniesFx.use(async () => await api.list());
createCompanyFx.use(async (form) => await api.create(form));

$companies
  .reset(resetCompanies)
  .on(getCompaniesFx.doneData, (_, { data }) => data)
  .on(createCompanyFx.doneData, (state, { data }) => [...state, data]);

//---
/** $curCompany */
initAttachCompanyDataFx.use(async (id) => {
  return await Promise.all([
    getChannelsFx(id),
    getUsersFx(id),
    fetchIntegrationListFx(id),
    fetchEmailListFx({ bookkeeper_team_id: id }),
    fetchNoticesFx({ bookkeeper_team_id: id }),
    fetchCustomerTagsFx(id),
  ]);
});
$currentCompanyIdOrNull.watch((id) => {
  if (id) initAttachCompanyDataFx(id).catch(fnVoid);
});
setCurCompany.watch((companyId) => {
  if (history.location.pathname !== '/wizard') history.replace('/channels');
  // resetPosts();
  localStorage.setItem(LSKey.company, companyId);
});

/** Update */
//todo после апдейта компании происходят связанные запросы, надо избавится от этого
updateCompanyFx.use(async (params) => await api.update(params));
$companies.on(updateCompanyFx.doneData, (_s, { data }) =>
  _s.map((c) => (c.id === data.id ? data : c)),
);

/** Access */
updateAccessFx.use(async (params: AccessUpdateParams) => await api.access(params));
sample({
  clock: updateAccess,
  source: $currentCompanyIdOrNull,
  target: updateAccessFx,
  fn: (companyId, { userId, role }): AccessUpdateParams => ({
    companyId: companyId!,
    userId,
    form: toRoleData(role),
  }),
});
sample({
  clock: toggleAccess,
  target: createEffect(async (user: CommonUser) => {
    if (await promptFn(i18next.t('account:employee.promptToggleAccess'))) {
      const role = toRole(user) === Roles.admin ? Roles.user : Roles.admin;
      updateAccess({ userId: user.id, role });
    }
  }),
});
sample({
  clock: toggleBlockAccess,
  target: createEffect(async (user: CommonUser) => {
    let promptTxt, role;
    if (toRole(user) === Roles.blocked) {
      promptTxt = i18next.t('account:employee.promptUnBlockAccess');
      role = Roles.user;
    } else {
      promptTxt = i18next.t('account:employee.promptBlockAccess');
      role = Roles.blocked;
    }
    if (await promptFn(promptTxt)) {
      updateAccess({ userId: user.id, role });
    }
  }),
});
sample({
  clock: revertAccess,
  target: createEffect(async (user: CommonUser) => {
    if (await promptFn(i18next.t('account:employee.revertAccess'))) {
      updateAccess({ userId: user.id, role: Roles.user });
    }
  }),
});
sample({
  clock: [updateAccessFx.done, deleteUserFx.done],
  target: fetchUsers,
});
//Сообщения об упехе
sample({
  clock: updateAccessFx.done,
  target: createEffect(() => {
    showToast(ToastTypes.success, i18next.t('account:employee.messageUpdateAccess'));
  }),
});

/** Delete User*/
deleteUserFx.use(async (params) => await api.deleteUser(params));
sample({
  clock: deleteUser,
  source: $currentCompanyIdOrNull,
  target: createEffect(async (params: DeleteUserParams) => {
    if (await promptFn(i18next.t('account:employee.promptDeleteUser'))) {
      return await deleteUserFx(params);
    }
  }),
  fn: (companyId, userId): DeleteUserParams => ({ companyId: companyId!, userId }),
});
sample({
  clock: deleteUserFx.done,
  target: createEffect(() => {
    showToast(ToastTypes.success, i18next.t('account:employee.messageDeleteUser'));
  }),
});

//Сообщения об фэйле
sample({
  clock: [updateAccessFx.fail, deleteUserFx.fail],
  target: createEffect(() => {
    showToastError();
  }),
});
