import { TFunction } from 'i18next';
import { ValidationResult } from '../interfaces';

interface ValidationFunction<T> {
  (current: T): boolean;
}

const isValidationFunction = <T>(
  fn: (v: T, ...rest: any[]) => boolean,
): fn is ValidationFunction<T> => fn.length <= 1;

interface CompareFunction<T> {
  (current: T, against: T): boolean;
}

type CompareOperator = '===' | '!==' | '>' | '>=' | '<' | '<=';

const stdCmp: Record<CompareOperator, CompareFunction<any>> = {
  '===': (a, b) => a === b,
  '!==': (a, b) => a !== b,
  '>': (a, b) => a > b,
  '>=': (a, b) => a >= b,
  '<': (a, b) => a < b,
  '<=': (a, b) => a <= b,
};

export class CompareValidator<T> {
  private message: string | undefined;
  private compareValue: T | undefined;
  private comparison: CompareFunction<T> | ValidationFunction<T> | CompareOperator = '===';
  private whenUndef: boolean | undefined;

  constructor(private readonly t?: TFunction) {}

  setMessage = (message: string | undefined) => {
    this.message = message;
    return this;
  };

  getMessage = () => this.message ?? (this.t ? this.t('validation:InvalidValue') : 'Invalid value');

  setCompareValue = (value: T | undefined) => {
    this.compareValue = value;
    return this;
  };

  setComparison = (fn: CompareFunction<T> | ValidationFunction<T> | CompareOperator) => {
    this.comparison = fn;
    return this;
  };

  setWhenUndef = (isValid: boolean | undefined) => {
    this.whenUndef = isValid;
    return this;
  };

  isValid = (current: T, against = this.compareValue) => {
    const callback =
      typeof this.comparison === 'string' ? stdCmp[this.comparison] : this.comparison;

    if (isValidationFunction(callback)) {
      return callback(current);
    }

    if (against === undefined) {
      if (this.whenUndef === undefined) {
        throw new TypeError('The `against` value is undefined and `whenUndef` is undefined too');
      }
      return this.whenUndef;
    }

    return callback(current, against);
  };

  validate = (current: T, against?: T): ValidationResult =>
    this.isValid(current, against) ? undefined : this.getMessage();
}
