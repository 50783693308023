import { createEffect, createEvent, createStore, sample } from 'effector';
import * as RoMap from '@cubux/readonly-map';
import { EMPTY_MAP } from 'constants/utils';
import {
  apiTeamPreference,
  apiUserPreferenceInTeam2,
  TeamPreferenceApis,
} from 'api/request/user/preferences';
import { $currentCompanyId } from '../company';
import { withBookkeeperFx } from '../utils/withBookkeeperFx';
import { UserPreferenceForm } from './types';
import { createPreferenceCore, SourceOpt } from './createPreference';

const createTeamPreferences = (apis: TeamPreferenceApis): SourceOpt => {
  const reloadAll = createEvent();
  const reloadOne = createEvent<string>();
  const updateOne = createEvent<UserPreferenceForm>();

  const fetchListFx = withBookkeeperFx(createEffect(apis.list));
  const fetchItemFx = withBookkeeperFx(createEffect(apis.item));
  const updateItemFx = withBookkeeperFx(createEffect(apis.update));

  sample({
    clock: [reloadAll, $currentCompanyId.updates],
    fn: () => ({}),
    target: fetchListFx,
  });

  sample({
    source: reloadOne,
    fn: (name) => ({ name }),
    target: fetchItemFx,
  });

  sample({
    source: updateOne,
    target: updateItemFx,
  });

  const $values = createStore<ReadonlyMap<string, string>>(EMPTY_MAP)
    .reset($currentCompanyId)
    .on(fetchListFx.doneData, (_, { data }) =>
      data ? new Map(data.map((o) => [o.name, o.value])) : EMPTY_MAP,
    )
    .on([fetchItemFx.doneData, updateItemFx.doneData], (map, { data: { name, value } }) =>
      value ? RoMap.set(map, name, value) : RoMap.remove(map, name),
    );

  return {
    reloadAll,
    reloadOne,
    updateOne,
    $values,
  };
};

const userInTeam = createTeamPreferences(apiUserPreferenceInTeam2);
export const {
  reloadAll: reloadUserPreferencesInTeam,
  reloadOne: reloadUserPreferenceItemInTeam,
  updateOne: updateUserPreferenceItemInTeam,
  $values: $userPreferencesInTeam,
} = userInTeam;
export const createUserPreferenceInTeam = createPreferenceCore(userInTeam);

const teamWide = createTeamPreferences(apiTeamPreference);
export const {
  reloadAll: reloadTeamPreferences,
  reloadOne: reloadTeamPreferenceItem,
  updateOne: updateTeamPreferenceItem,
  $values: $teamPreferences,
} = teamWide;
/**
 * Опции с именами `admin.*` может изменять только админ.
 */
export const createTeamWidePreference = createPreferenceCore(teamWide);
