import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { durationSplitHM } from 'utils/date';

interface Props {
  minutes: number;
  zeroContent?: ReactNode;
}

export const DurationHM: FC<Props> = ({ minutes, zeroContent = '\u2014' }) => {
  const { t } = useTranslation(['task', 'ui']);

  const [h, m] = durationSplitHM(minutes);

  return (
    <>
      {h > 0 && t('ui:date.cntHours', { N: h })} {m > 0 && t('ui:date.cntMinutes', { N: m })}
      {!h && !m && zeroContent}
    </>
  );
};
