export const getUniqKey = (prefix: string = 'new_') => `${prefix}${new Date().getTime()}`;

export const getUniqKeyRnd = (prefix: string = 'new_') =>
  `${getUniqKey(prefix)}_${Math.floor(Math.random() * 0xffffff).toString(16)}`;

/**
 * UUID v4
 *
 * ```ts
 * type FooId = IdOf<'Foo'>;
 * const id = randomUUID<FooId>();
 * // => "eb0eee45-0438-4100-818e-fd9c18bdcdf7"
 * ```
 * @see crypto.randomUUID
 */
export const randomUUID = <T extends string = string>() => window.crypto.randomUUID() as T;

/**
 * Рандомный ключ/id из символов BASE64, кроме "="
 *
 * ```ts
 * type FooId = IdOf<'Foo'>;
 * const id = randomKey<FooId>(10);
 * // => "tdX3P5oWTm"
 * ```
 *
 * @param len Длина
 * @see crypto.getRandomValues
 */
export const randomKey = <T extends string = string>(len: number) =>
  window
    .btoa(
      String.fromCharCode(
        ...window.crypto.getRandomValues(new Uint8Array(Math.ceil((len / 4) * 3))),
      ),
    )
    .substring(0, len) as T;
