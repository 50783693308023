import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { ReactComponent as SvgSuccess } from 'assets/img/success2.svg';
import { TimeoutId } from 'utils/types';
import Icon, { IconRef } from '../../Icon';
import { IView } from '../../types';
import { Dialog } from '../Dialog';
import { reactModal } from '../reactModal';
import { ToastDuration } from '../Toast';
import cl from './PendingDialog.module.scss';

interface Options {
  /** По умолчанию `success2.svg`. Чтобы убрать, используйте `null`. */
  icon?: IconRef | null;
  message?: ReactNode;
  showLoader?: boolean;
}

interface Props extends IView, Options {
  open: boolean;
}

export const PendingDialog: FC<Props> = ({
  open,
  icon = <SvgSuccess />,
  message,
  showLoader = true,
  className,
}) => (
  <Dialog visible={open} className={cn(cl.root, className)} wrapClassName={cl.wrap}>
    <div className={cl.content}>
      {icon && <Icon type={icon} className={cl.icon} />}
      {message && <div className={cl.message}>{message}</div>}
      {showLoader && <Icon type="Loader" className={cl.loader} />}
    </div>
  </Dialog>
);

interface FnOptions extends Options {
  /** Показывать диалог, пока не завершится промис. */
  pending?: Promise<any>;
  /**
   * Принудительно скрывать диалог после такого интервала.
   *
   * По умолчанию:
   * - если промис `pending` указан, то нет ограничения по времени;
   * - если промис `pending` НЕ указан, то `ToastDuration.DEFAULT`.
   */
  maxDuration?: ToastDuration;
}

const DURATION: Record<ToastDuration, number> = {
  [ToastDuration.DEFAULT]: 4000,
  [ToastDuration.LONGER]: 7000,
};

export const showPendingDialog = async ({ pending, maxDuration, ...options }: FnOptions = {}) => {
  let tid: TimeoutId;
  try {
    return await reactModal<void>(({ show, onSubmit }) => {
      if (pending) {
        pending.finally(() => onSubmit());
      } else if (!maxDuration) {
        // а то чё это он - вечно что ли будет тут торчать?
        maxDuration = ToastDuration.DEFAULT;
      }
      if (maxDuration) {
        tid = setTimeout(() => onSubmit(), DURATION[maxDuration]);
      }
      return <PendingDialog {...options} open={show} />;
    });
  } finally {
    clearTimeout(tid!);
  }
};
