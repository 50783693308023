import linkifyIt from 'linkify-it';
import { containerWithHtml } from '../dom/containerWithHtml';

const linkify = linkifyIt().set({ fuzzyEmail: true });
export { linkify as linkifyEditorContent };

interface CommonOptions {
  onDidChange?: () => void;
}
interface TextOptions extends CommonOptions {
  onLinkCreated?: (a: HTMLElement) => void;
}
interface ElementOptions extends TextOptions {
  // с какими элементами что ещё делать
  // { selector: (el, work) => el, }
  // work - рекурсивная обработка дочерних элементов
  // возвращать должен элемент, после которого обработка пойдёт дальше
  onElement?: Record<string, (el: HTMLElement, work: (parent: HTMLElement) => void) => HTMLElement>;
}
interface HtmlLinkifyOptions extends ElementOptions {}

export function linkifyTextNode(node: Text, o: TextOptions = {}): ChildNode {
  const { onDidChange, onLinkCreated } = o;

  // мерж соседних text node, которые случаются в редакторе в процессе редактирования
  // while (true) {
  //   const next = node.nextSibling;
  //   if (!(next instanceof Text)) break;
  //   const data = next.nodeValue;
  //   if (data?.length) {
  //     console.log('>> merge text nodes: append', { data, next });
  //     node.appendData(data);
  //     // хз, надо ли здесь делать onDidChange?.();
  //   }
  //   next.parentNode!.removeChild(next);
  // }
  const matches = linkify.match(node.wholeText) || [];
  let last: ChildNode | undefined = undefined;
  // благодаря Text.splitText() можем удобно разбивать Text на части прямо на
  // месте, делая это с конца
  while (matches.length) {
    const m = matches.pop()!;
    // node:    text match text
    //          -----^^^^^-----
    //               match
    // =>
    // node:    ^^^^^
    // matched:      ^^^^^
    // after:             ^^^^^
    const after = node.splitText(m.lastIndex);
    const matched = node.splitText(m.index);
    last ??= after;
    // затем matched запихиваем в <a>.
    // try-catch - добавление дочернего элемента в принципе может фейлить
    // в определённых случаях, и я не удивлюсь, если где-то прямо строго нельзя
    // добавлять <a> куда угодно
    const link = node.ownerDocument.createElement('a');
    try {
      link.setAttribute('href', m.url);
      link.setAttribute('rel', 'noopener noreferrer');
      link.setAttribute('target', '_blank');
      node.parentNode!.insertBefore(link, matched);
      link.append(matched);
      onLinkCreated?.(link);
    } catch {
      try {
        link.parentNode?.removeChild(link);
      } catch {}
    }
  }
  if (last) {
    onDidChange?.();
  }
  return last ?? node;
}

function workElement(el: HTMLElement, o: HtmlLinkifyOptions): HTMLElement {
  if (o.onElement) {
    const w = (e: HTMLElement) => work(e, o);
    for (const [selector, handler] of Object.entries(o.onElement)) {
      if (el.matches(selector)) {
        return handler(el, w);
      }
    }
  }
  if (!el.matches(':link,:visited,:enabled,:disabled')) {
    work(el, o);
  }
  return el;
}

function work(parent: HTMLElement, o: HtmlLinkifyOptions) {
  for (let ch = parent.firstChild; ch; ch = ch.nextSibling) {
    switch (ch.nodeType) {
      case document.TEXT_NODE:
        ch = linkifyTextNode(ch as Text, o);
        break;

      case document.ELEMENT_NODE:
        ch = workElement(ch as HTMLElement, o);
        break;
    }
  }
}

export const htmlLinkifyContainer = (parent: HTMLElement, o: HtmlLinkifyOptions = {}) => {
  // мерж соседних text node, которые случаются в редакторе в процессе редактирования
  parent.normalize();
  work(parent, o);
};

export const htmlLinkify = (html: string, o: HtmlLinkifyOptions = {}) => {
  if (!html.trim()) {
    return html;
  }
  const root = containerWithHtml(html);
  work(root, o);
  return root.innerHTML;
};
