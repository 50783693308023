import {
  ClipboardEvent,
  DragEvent,
  FC,
  HTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import cl from './DragUploader.module.scss';
import cn from 'classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
  onSelectFiles: (files: File[]) => void;
  disabled?: boolean;
  useClipboard?: boolean;
}

export const DragUploader: FC<Props> = ({
  children,
  onSelectFiles,
  disabled = false,
  className,
  useClipboard,
  ...rest
}) => {
  const { t } = useTranslation('ui');
  const ref = useRef<HTMLDivElement>(null);
  const [isDrag, setIsDrag] = useState(false);

  const handleDragEnter = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDrag(true);
  }, []);

  const handleDragLeave = useCallback((e: DragEvent) => {
    if (ref.current) {
      // const { isDrag } = this.state;
      const { clientX, clientY } = e;
      const { left, top, width, height } = ref.current.getBoundingClientRect();
      if (
        // isDrag &&
        clientX >= left &&
        clientX <= left + width &&
        clientY >= top &&
        clientY <= top + height
      )
        return;
    }
    setIsDrag(false);
  }, []);

  const handleDragover = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      if (e.dataTransfer.files.length && isDrag) onSelectFiles(Array.from(e.dataTransfer.files));
      setIsDrag(false);
    },
    [isDrag, onSelectFiles],
  );

  const handleDragStart = useCallback((e: DragEvent) => {
    e.preventDefault();
  }, []);

  const handlePasteCapture = (e: ClipboardEvent<HTMLDivElement>) => {
    if (e.isDefaultPrevented()) return;
    if (e.clipboardData.files.length) {
      onSelectFiles(Array.from(e.clipboardData.files));
      e.preventDefault();
    }
  };

  return (
    <div
      {...rest}
      className={cn(cl.root, isDrag && cl.isDrag, className)}
      ref={ref}
      onDragEnter={disabled ? undefined : handleDragEnter}
      onDragLeave={disabled ? undefined : handleDragLeave}
      onDrop={disabled ? undefined : handleDrop}
      onDragOver={disabled ? undefined : handleDragover}
      onDragStart={disabled ? undefined : handleDragStart}
      draggable={false}
      onPaste={useClipboard && !disabled ? handlePasteCapture : undefined}
    >
      {children}
      {disabled || <div className={cl.cover}>{t('ui:placeFilesForUpload')}</div>}
    </div>
  );
};
