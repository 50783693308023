import { linkifyTextNode } from './htmlLinkify';

interface Options {
  withLinks?: boolean;
}

export const htmlFromPlainTextP = (plain: string, o: Options = {}) => {
  const html = htmlFromPlainText(plain, o);
  if (!html) return html;
  return '<p>' + html + '</p>';
};

// TODO #129: может, можно как-то улучшить
export const htmlFromPlainText = (plain: string, o: Options = {}) =>
  plain
    .split('\n')
    .map((s) => _htmlText(s, o))
    .join('<br/>');

const _htmlText = (plain: string, { withLinks }: Options) => {
  const div = document.createElement('div');
  const tx = document.createTextNode(plain);
  div.appendChild(tx);
  if (withLinks) {
    linkifyTextNode(tx);
  }
  return div.innerHTML;
};
