import delay from 'delay';
import { createEffect, createEvent, sample } from 'effector';
// @ts-ignore
import notifSound from 'assets/sound/notif.mp3';

const OK_COOLDOWN = 750;
const DEFAULT_COOLDOWN = 1200 + OK_COOLDOWN;

export const notifyPlaySound = createEvent<any>();

const playFx = createEffect(async () => {
  const m = new Audio(notifSound);
  try {
    const d = m.duration;
    // Промис старта резолвится, когда успешно **стартовал**
    await m.play();
    // Добавляем задержку, чтобы эффект длился чуть дольше, чем воспроизводится звук
    // Там, можем запретить эффекту запускаться параллельно в несколько копий,
    // чтобы не играло несколько звуков параллельно.
    //
    // А то бывает, что из whatsapp приходит сразу пачка сообщений (они валятся
    // по одному от сервиса), и у тебя играет сразу куча копий звука, наложенных
    // друг на друга с задержкой в доли секунды.
    await delay(!isNaN(d) && isFinite(d) ? d * 1000 + OK_COOLDOWN : DEFAULT_COOLDOWN);
    // Либо надо было с debounce и кулдауном останавливать уже играющий звук,
    // а потом запускать новый, но это сложнее придумать
  } catch {
    delay(DEFAULT_COOLDOWN);
  }
});
sample({
  source: notifyPlaySound,
  filter: playFx.pending.map((b) => !b),
  target: playFx,
});
