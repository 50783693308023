import { ComponentType, FC } from 'react';
import LoginRedirect from '../LoginRedirect';
import { useStore } from 'effector-react';
import { $auth } from 'models/auth';

const authRequired =
  <P,>(Component: ComponentType<P>): FC<P> =>
  (props) => {
    const { accesstoken } = useStore($auth);
    const isAuthenticated = Boolean(accesstoken);

    if (!isAuthenticated) {
      return <LoginRedirect />;
    }

    return <Component {...props} />;
  };

export default authRequired;
