import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { onFulfillFile } from './onFulfillFile';

// REFACT: заменить на два: onFulfillFile и onFulfillSaveAs
export const onFulfillDownload = async (res: AxiosResponse<Blob>) => {
  const file = await onFulfillFile(res);
  saveAs(file, file.name);
  return Promise.resolve(file.name);
};
