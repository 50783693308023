import cn from 'classnames';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { TextButton2 } from 'ui/button';
import { DateFormat, TransCommonComponents } from 'ui/display';
import Icon from 'ui/Icon';
import { IView } from 'ui/types';
import { AnyEvent, useEvents } from './model';
import cl from './TechPanel.module.scss';

interface Props extends IView {}

export const TechPanel: FC<Props> = ({ className }) => {
  const events = useEvents();
  return (
    <div className={cn(cl.root, className)}>
      {events.map((e, i) => (
        <div key={i} className={cl.event}>
          <div className={cl.info}>
            <Icon type="Exclamation" className={cl.icon} /> <Data event={e.event} />
          </div>
          <TextButton2 size="small" uiColor="secondary" icon="Cross" onClick={e.onRemove} />
        </div>
      ))}
    </div>
  );
};

const Data: FC<{ event: AnyEvent }> = ({ event }) => {
  switch (event.type) {
    case 'frontUpgrade':
      return (
        <Trans
          i18nKey="ui:techPanel.FrontUpgradeAvailable"
          defaults="... <DeployAt/> ... <RefreshButton>...</RefreshButton>"
          components={{
            ...TransCommonComponents,
            DeployAt: <DateFormat date={event.data} format="Pp" />,
            RefreshButton: <RefreshButton />,
          }}
        />
      );
  }
  return event.type;
};

const refresh = () => window.location.reload();

const RefreshButton: FC = ({ children }) => (
  <TextButton2 size="small" uiColor="primary" onClick={refresh}>
    {children}
  </TextButton2>
);
