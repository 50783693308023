import { FC, ReactNode } from 'react';
import cl from './DataEmpty.module.scss';
import imgSrc from 'assets/img/createImg2.svg';
import { COMPANY } from 'constants/config';
import cn from 'classnames';

interface Props {
  title?: string | ReactNode;
  message?: string | ReactNode;
  center?: boolean;
}

export const DataEmpty: FC<Props> = ({ title, message, center }) => {
  return (
    <div className={cn(cl.root, center && cl.center)}>
      <img src={imgSrc} alt={COMPANY} className={cl.img} />
      {title && <div className={cl.title}>{title}</div>}
      {message && <div className={cl.message}>{message}</div>}
    </div>
  );
};
