import {
  combine,
  createEffect,
  createEvent,
  createStore,
  EffectParams,
  guard,
  restore,
  sample,
} from 'effector';
import { createGate } from 'effector-react';
import api from 'api/request/post';
import { ApiResponse } from 'api/types';
import noPhotoImg from 'assets/img/noPhoto.png';
import { createChatScrollPosition } from 'components/channels/Chat/model';
import { history } from 'utils/history';
import { fnVoid } from 'utils/fn';
import { htmlToPlainText, htmlToTelegramFix } from 'utils/string';
import { UnixTimestampMilli } from 'utils/types';
import {
  $channelExtendedMap,
  $channelExtendedMapByChannelId,
  $channelsByGroup,
  $channelsCommonMap,
  $channelToGlobalIdMap,
  $channelTypingMap,
  $customerChannelsMap,
  $globalToChannelIdMap,
  $internalChannelsMap,
  $internalUsersChannelsMap,
  $newPostsChannelsMap,
  $userChannelList,
  updateChannelId,
  updateChannelsByGroup,
} from '../channel';
import {
  Channel,
  ChannelByGroups,
  ChannelGlobalId,
  ChannelId,
  ChatType,
  CurrentChannelScrollState,
  CustomerChannelsItemId,
  TaskChannelExt,
} from '../channel/types';
import { $curCompany, $currentCompanyId } from '../company';
import { $customerInfo } from '../customer';
import { FileStorageService } from '../filestorage/v2/types';
import { createUploadingList } from '../filestorage/v2/uploading-list';
import { notifyPlaySound } from '../notify/sound';
import { $user, $userId, $users, $usersMap, getFio } from '../user';
import { createRequestEffect, createRequestEffectWithCancel } from '../utils/createRquestEffect';
import {
  CurrentChannelData,
  Post,
  PostId,
  PostListOptions,
  PostListParams,
  PostsData,
  PostsDataExt,
  PostUpdateForm,
  ReactionId,
  ReceivedPost,
  Seen,
} from './types';
import { EMPTY_ARRAY } from 'constants/utils';
import { CompanyId } from '../company/types';
import { notification } from '../utils/messages';
import { ToastTypes } from 'ui/feedback';
import ApiForbiddenError from 'api/errors/403';
import { UserId } from '../user/types';
import { $customersLightNames } from '../customer/list-light';
import i18next from 'i18next';

export const receivePost = createEvent<ReceivedPost>();
export const receiveDeletePost = createEvent<Post>();
export const receiveUpdatePost = createEvent<Post>();

export const seenPostFx = createEffect<string, ApiResponse<Seen>>(api.seen);

/*interface ChatDataOptions {
  // Эта мап используется, например, для обычных чатов "regular". Проблема идет корнями из того, что у нас существует два id канала.
  // Из-за того, что изначально id тет-а-тет каналов не известно, и используется id юзера. Эта мапа устанавливает связь между этими двумя id
  $_channelIdToGlobalIdMap?: Store<Map<ChannelId, ChannelGlobalId>>;
}*/
interface ChannelWithOptions {
  channel: Channel;
  isNewChanel?: boolean;
}

const isChannelWithOptions = (
  channel: Channel | TaskChannelExt | ChannelWithOptions,
): channel is ChannelWithOptions => Object.hasOwn(channel, 'channel');

export const createChatData = (name: ChatType /*, options?: ChatDataOptions*/) => {
  // const { $_channelIdToGlobalIdMap = createStore<Map<ChannelId, ChannelGlobalId> | null>(null) } =
  //   options || {};

  const ChatGate = createGate<{ postId?: string; channelId?: ChannelGlobalId }>();

  const filesApi = createUploadingList({
    // если будет редактирование сообщений, то обязательно это включить
    // allowDeleteFreeFile: true,
    // TODO: или надо было брать из `options`, чтобы в задачах были `tasks`?
    service: FileStorageService.telegram,
    // DEBUG: `chat ${name}`,
    // DEBUG: name === 'regular' ? `chat ${name}` : undefined,
    checkUnique: true,
  });

  const resetPosts = createEvent();
  const fetchChannelPosts = createEvent<PostListOptions>();
  const resetCurrentChannelData = createEvent();
  const setCurrentChannelData = createEvent<CurrentChannelData>();
  //Замороченная костыльная логика. Пришлось добавить возможность передать ChannelWithOptions,
  //там в опциях можно указать, что канал был только что создан, и тогда при переключении на этот канал, не будет вызываться фетч имеющихся постов
  const setCurrentChannel = createEvent<Channel | TaskChannelExt | ChannelWithOptions>();
  const postReceived = createEvent<ReceivedPost>();
  const newChannelCreated = createEvent<Channel>();
  const toggleReaction = createEvent<{ reaction: ReactionId; postId: PostId; userId?: UserId }>();
  const removeReaction = createEvent<{ reaction: ReactionId; postId: PostId; userId: UserId }>();

  const $currentChannelData = restore<CurrentChannelData>(setCurrentChannelData, {
    channel: null,
  }).reset([resetCurrentChannelData, ChatGate.close]);
  const $currentChannel = $currentChannelData.map((data) => data.channel);
  const $currentChannelGlobalId = $currentChannel.map((channel) => channel?.id ?? null);
  const $currentChannelId = $currentChannel.map((channel) => channel?.channel_id ?? null);
  const $currentPostId = $currentChannelData.map((data) => data.postId ?? null);

  const $isCreatingNewChannel = createStore(false);

  const $postsData = createStore<PostsDataExt | null>(null).reset([
    resetPosts,
    $currentChannelGlobalId,
    ChatGate.close,
  ]);

  // Прешлось пока сюда перетащить. $newPostsChannelsMap - этот стор здесь совсем не нужен.
  // Но нужно было быстрей закрыть проблему
  const $currentChannelNewPosts = combine(
    $newPostsChannelsMap,
    $currentChannelGlobalId,
    (map, channelGlobalId) => (channelGlobalId ? map.get(channelGlobalId) || 0 : 0),
  );

  const $currentChannelUsersCnt = combine(
    $currentChannel,
    $users,
    $channelExtendedMap,
    $userChannelList,
    $customerInfo,
    (currentChannel, users, channelExtendedMap, userChannelList, customerInfo) => {
      if (!currentChannel) {
        return 1;
      }
      if ('group' in currentChannel && currentChannel.group === 'task') {
        return users.length;
      }
      const extendedChannel = channelExtendedMap.get(currentChannel.id);
      if (extendedChannel) {
        if (extendedChannel.group === 'customers') {
          return customerInfo?.customerChannels[0].contractorEmployees.length ?? 1;
        } else if (extendedChannel.group === 'internal_users_channels') {
          return 2;
        } else if (extendedChannel.group === 'internal_channels') {
          if (extendedChannel.type === 'public') {
            return users.length;
          } else {
            return userChannelList.length;
          }
        }
      }

      return 1;
    },
  );

  $currentChannelData.on(setCurrentChannel, (_, channel) =>
    isChannelWithOptions(channel) ? { channel: channel.channel } : { channel },
  );
  $isCreatingNewChannel.on(setCurrentChannel, (_, channel) =>
    isChannelWithOptions(channel) ? Boolean(channel.isNewChanel) : false,
  );

  sample({
    clock: /*[$currentChannel /!*ChatGate.open*!/]*/ $currentChannelId.updates,
    source: {
      postId: $currentPostId,
      channel: $currentChannel,
      status: ChatGate.status,
      isCreatingNewChannel: $isCreatingNewChannel,
    },
    filter: ({ channel, status, isCreatingNewChannel }) =>
      !!channel && status && !isCreatingNewChannel,
    target: fetchChannelPosts,
    fn: ({ postId, channel }): PostListOptions => ({
      atid: postId || channel!.last_id,
    }),
  });
  //Достаточно запутанная велосипедная логика. Если мы устанавливали новый канал с опцией isCreatingNewChannel,
  //то нам не надо было делать фетч, мы вручную создаем пустую $postsData, и далее сбрасываем $isCreatingNewChannel
  sample({
    clock: $currentChannel.updates,
    source: $isCreatingNewChannel,
    filter: (isCreatingNewChannel, channel) => isCreatingNewChannel && !!channel,
    target: newChannelCreated,
    fn: (_, channel) => channel as Channel,
  });
  sample({
    clock: newChannelCreated,
    filter: (channel) => !!channel,
    target: $postsData,
    fn: (channel): PostsDataExt => ({
      channelId: channel!.channel_id!,
      posts: EMPTY_ARRAY,
      lastChangeType: null,
      lastPostId: null,
    }),
  });
  sample({
    clock: newChannelCreated,
    target: $isCreatingNewChannel,
    fn: () => false,
  });

  const { request: _getUserPostsFx, cancel: cancelGetUserPosts } = createRequestEffectWithCancel(
    api.listForUser2,
    false,
  );
  const getUserPostsFx = createEffect<
    EffectParams<typeof _getUserPostsFx> & { needUpdateChannelId?: boolean },
    ApiResponse<PostsData>
  >(async ({ needUpdateChannelId, ...apiParams }) => {
    const ret = await _getUserPostsFx(apiParams);
    //Если пользователь новый и он еще не общался с данным пользователем, то в канале не указан channelId, исправим это
    // обновим $globalToChannelIdMap и $channelToGlobalIdMap
    if (needUpdateChannelId)
      updateChannelId({
        channelId: ret.data.channelId,
        channelGlobalId: apiParams.userId as ChannelGlobalId,
      });

    return ret;
  });

  const { request: getChannelPostsFx, cancel: cancelGetChannelPosts } =
    createRequestEffectWithCancel(api.listForChannel2, false);

  sample({
    clock: $currentChannelId.updates,
    target: [cancelGetChannelPosts, cancelGetUserPosts],
  });
  sample({
    clock: fetchChannelPosts,
    source: [$internalUsersChannelsMap, $currentChannelGlobalId, $currentCompanyId] as const,
    filter: ([map, channelId]) => !!channelId && !!map.get(channelId),
    target: getUserPostsFx,
    fn: ([_, channelId, companyId], options) => ({
      userId: channelId!,
      companyId: companyId as CompanyId,
      options,
    }),
  });
  sample({
    clock: fetchChannelPosts,
    source: [$internalUsersChannelsMap, $currentChannelGlobalId] as const,
    filter: ([map, channelId]) => !!channelId && !map.get(channelId),
    target: getChannelPostsFx,
    fn: ([_, channelId], options) => ({ channelId: channelId!, options }),
  });

  const $isPostsLoading = combine(
    getChannelPostsFx.pending,
    getUserPostsFx.pending,
    (a, b) => a || b,
  );

  //Событие срабатывает когда нажали кнопку "Отправить", до createPost
  const postSent = createEvent<{ message: string; quotedPostId?: string }>();
  const createPost = createEvent<Post>();
  const createPostFx = createRequestEffect(api.create);

  const updatePost = createEvent<Post>();
  const updatePostFx = createEffect<{ postId: string; form: PostUpdateForm }, ApiResponse<Post>>();

  const deletePostFx = createEffect<string, ApiResponse<Post>>();

  /** $curChannelId */
  const $curPostsChannelId = $postsData.map((s) => (s ? s.channelId : ''));

  const seenPost = createEvent<{ post: Post; postChannelId: string }>();

  /** pinned */
  const resetPinnedPosts = createEvent();
  const $pinnedPosts = createStore<Post[]>([]).reset(resetPinnedPosts);

  const fetchPinnedPosts = createEvent();
  const pinnedPostFx = createEffect<string, ApiResponse<Post>>();
  const getPinnedPostsFx = createEffect<ChannelId, ApiResponse<PostsData>>();

  /** copy */
  const copyPostsFx = createEffect<
    { channelId: ChannelGlobalId; posts: string[] },
    ApiResponse<Post[]>
  >();

  sample({
    clock: toggleReaction,
    source: { postsData: $postsData, userId: $userId },
    target: $postsData,
    fn: ({ postsData, userId: myUserId }, { postId, reaction, userId }): PostsDataExt | null =>
      postsData
        ? {
            ...postsData,
            lastChangeType: 'update',
            posts: postsData.posts.map((post) => {
              if (post.id !== postId) {
                return post;
              }

              let { reactions = EMPTY_ARRAY, my_reaction } = post;
              const addReactionToGroup = () => {
                const group = reactions.find((group) => group.reaction === reaction);
                if (group) {
                  group.count++;
                } else {
                  reactions = [...reactions, { reaction, count: 1 }];
                }
                return reactions;
              };

              if (!userId || myUserId === userId) {
                const myGroup = reactions.find((group) => group.reaction === post.my_reaction);
                if (myGroup) {
                  myGroup.count--;
                  my_reaction = null;
                  if (myGroup.count <= 0) {
                    reactions = reactions.filter((group) => group.reaction !== post.my_reaction);
                  }
                }
                if (reaction !== post.my_reaction) {
                  my_reaction = reaction;
                  reactions = addReactionToGroup();
                }
              } else {
                reactions = addReactionToGroup();
              }

              return {
                ...post,
                my_reaction,
                reactions,
              };
            }),
          }
        : null,
  });

  sample({
    clock: removeReaction,
    source: { postsData: $postsData, userId: $userId },
    target: $postsData,
    fn: ({ postsData, userId: myUserId }, { postId, reaction, userId }): PostsDataExt | null =>
      postsData
        ? {
            ...postsData,
            lastChangeType: 'update',
            posts: postsData.posts.map((post) => {
              if (post.id !== postId) {
                return post;
              }

              let { reactions = EMPTY_ARRAY, my_reaction } = post;
              const group = reactions.find((item) => item.reaction === reaction);
              if (group) {
                if (post.my_reaction === reaction && myUserId === userId) {
                  my_reaction = null;
                }
                group.count--;
                if (group.count <= 0) {
                  reactions = reactions.filter((group) => group.reaction !== reaction);
                } else {
                  reactions = [...reactions];
                }
              }

              return { ...post, my_reaction, reactions };
            }),
          }
        : null,
  });

  /////
  // getUserPostsFx.use(async ({ userId, options, needUpdateChannelId }) => {
  //   const ret = await api.listForUser2(userId, $curCompany.getState()!.id, options);
  //   //Если пользователь новый и он еще не общался с данным пользователем, то в канале не указан channelId, исправим это
  //   // обновим $globalToChannelIdMap и $channelToGlobalIdMap
  //   if (needUpdateChannelId)
  //     updateChannelId({ channelId: ret.data.channelId, channelGlobalId: userId as ChannelGlobalId });
  //
  //   return ret;
  // });
  // getChannelPostsFx.use(
  //   async ({ channelId, options }) => await api.listForChannel2(channelId, options),
  // );

  // createPostFx.use(async (form) => await api.create(form));

  // t('channel:chat.forbiddenError')
  // t('channel:chat.postError')
  notification({
    clock: createPostFx.fail,
    mode: ToastTypes.error,
    tKey: ({ error }) =>
      error instanceof ApiForbiddenError ? 'channel:chat.forbiddenError' : 'channel:chat.postError',
  });
  sample({
    clock: createPostFx.fail,
    source: $postsData,
    filter: (postData, { params }) => !!postData && postData.channelId === params.channelId,
    target: $postsData,
    fn: (postData, { params }): PostsDataExt => {
      return {
        ...postData!,
        posts: postData!.posts.filter((post) => post.localId !== params.localId),
      };
    },
  });

  sample({
    clock: postSent,
    source: {
      user: $user,
      files: filesApi.$filesOld,
      curPostsChannelId: $curPostsChannelId,
    },
    filter: ({ curPostsChannelId }) => !!curPostsChannelId,
    target: createPost,
    fn: ({ user, files, curPostsChannelId }, { message, quotedPostId }): Post => {
      const time = new Date().getTime();
      return {
        localId: String(time),
        createat: 0 as UnixTimestampMilli,
        updateat: 0,
        editat: 0,
        deleteat: 0,
        ispinned: null,
        userid: user!.id,
        channelid: curPostsChannelId as ChannelId,
        rootid: null,
        parentid: null,
        originalid: null,
        message: message,
        type: null,
        props: null,
        hashtags: null,
        files,
        hasreactions: null,
        id: `local_${time}`,
        localDate: time,
        quotedPostId: quotedPostId,
        relatedPosts: null,
        nickname: null,
        users_read_count: 1,
      };
    },
  });

  $postsData.on<{
    params: Partial<PostListParams<'channelId'>> & Partial<PostListParams<'userId'>>;
    result: ApiResponse<PostsData>;
  }>(
    [getUserPostsFx.done, getChannelPostsFx.done],
    (s, { params: { userId, channelId, options }, result: { data } }) =>
      //  Добавили в текущий канал
      s && s.posts.length
        ? //Добавили в конец
          options?.afterid
          ? {
              ...s,
              posts: [...s.posts, ...data.posts],
              lastChangeType: 'appendFetch',
            }
          : //Добавили в начало
            {
              ...s,
              posts: [...data.posts, ...s.posts],
              lastChangeType: 'prependFetch',
            }
        : // Сменили канал
          {
            ...data,
            lastChangeType: null,
            lastPostId:
              options?.atid ||
              $channelsCommonMap.getState().get((channelId || userId!) as ChannelGlobalId)
                ?.last_id ||
              null,
          },
  );

  $postsData.on(createPostFx.doneData, (s, { data }) =>
    s
      ? {
          ...s,
          posts: s.posts.map((c) => (data.localId && c.localId === data.localId ? data : c)),
          lastChangeType: 'append',
        }
      : null,
  );

  $postsData.on(createPost, (s, data) => {
    return s && s.channelId === data.channelid
      ? { ...s, posts: [...s.posts, data], lastChangeType: 'append' }
      : s;
  });

  $postsData.on(deletePostFx.doneData, (s, { data }) => {
    return s
      ? {
          ...s,
          posts: [...s.posts.map((c) => (c.id === data.id ? { ...data } : c))],
          lastChangeType: 'deletePost',
        }
      : s;
  });

  /** createPost */
  createPost.watch((e) => {
    //todo отправка в цикле
    createPostFx({
      channelId: e.channelid,
      message: e.message ? htmlToTelegramFix(e.message) : '',
      localId: e.localId,
      files: e.files?.map((c) => c.fileId),
      quotedPostId: e.quotedPostId,
    }).catch(fnVoid);
  });

  /** updatePost */
  updatePostFx.use(async ({ postId, form }) => await api.update(postId, form));
  $postsData.on(updatePost, (s, data) =>
    s && s.channelId === data.channelid
      ? { ...s, posts: s.posts.map((c) => (c.id === data.id ? data : c)), lastChangeType: 'update' }
      : s,
  );
  updatePost.watch((e) => {
    //todo отправка в цикле
    updatePostFx({
      postId: e.id,
      form: {
        channelId: e.channelid,
        message: e.message ? htmlToTelegramFix(e.message) : '',
        localId: e.localId,
      },
    }).catch(fnVoid);
  });

  // в ответе от сервера приходит какаято хрень, поэтому проапдейтим только время
  $postsData.on(updatePostFx.doneData, (s, { data }) =>
    s
      ? {
          ...s,
          posts: s.posts.map((post) =>
            post.id === data.id ? { ...post, editat: data.editat, updateat: data.updateat } : post,
          ),
          lastChangeType: 'update',
        }
      : null,
  );

  deletePostFx.use(async (postId) => await api.delete(postId));

  seenPost.watch(({ post, postChannelId }) => {
    if ($curPostsChannelId.getState() !== postChannelId) return;

    const { last_viewed_at, last_id } = $currentChannel.getState() || {};

    if (
      post.createat > (last_viewed_at || 0) ||
      (post.createat === last_viewed_at && post.id !== last_id)
    ) {
      seenPostFx(post.id).catch(fnVoid);
    }
  });

  /** pinned */
  pinnedPostFx.use(async (postId) => await api.pinned(postId));
  getPinnedPostsFx.use(async (channelId) => await api.pinnedList(channelId));
  $pinnedPosts.on(getPinnedPostsFx.doneData, (_, { data: { posts } }) => posts);

  //todo  в последствии надо будет переделать. Сейчас завязано на id из урл чата в котором разговаривали.
  //а надо завязать на улр из страницы Закладки. Также надо сделать запрос на получение channelId, если чат новый, и channelId у него еще пустой
  //   const $currentChannelId = sample({
  //     source: [$globalChannelId, $globalToChannelIdMap] as const,
  //     fn([globalId, map]) {
  //       return globalId ? map.get(globalId) ?? null : null;
  //     },
  //   });

  guard({
    clock: fetchPinnedPosts,
    source: $currentChannelGlobalId,
    filter: (channelId) => !!channelId,
    // @ts-ignore
    target: getPinnedPostsFx,
  });

  //тут пока только удалим
  $pinnedPosts.on(pinnedPostFx.doneData, (s, { data }) =>
    !data.ispinned ? s.filter((c) => c.id !== data.id) : s,
  );

  /** copy */
  copyPostsFx.use(async ({ channelId, posts }) => {
    //todo _channelId может быть неизвестным в случае нового канала. Необходимо сделать дополнительно запрос для получения channelId
    const _channelId = $globalToChannelIdMap.getState().get(channelId)!;
    return await api.copy(_channelId, posts);
  });

  //files
  createPostFx.done.watch(() => {
    filesApi.commit();
    filesApi.reopen();
  });

  //reset
  sample({
    clock: $curCompany,
    target: resetPosts,
  });

  /** $currentChannelScrollState */
  const resetCurrentChannelScrollState = createEvent();
  const $currentChannelScrollState = createStore<CurrentChannelScrollState>({
    hasTop: true,
    hasBottom: true,
  }).reset(resetCurrentChannelScrollState);
  sample({
    clock: ChatGate.state,
    target: resetCurrentChannelScrollState,
  });
  $currentChannelScrollState.on<{
    params: Partial<PostListParams<'channelId'>> & Partial<PostListParams<'userId'>>;
    result: ApiResponse<PostsData>;
  }>(
    [getUserPostsFx.done, getChannelPostsFx.done],
    (s, { params: { options }, result: { data } }) => {
      if (!data.posts.length) {
        if (!options || 'atid' in options) {
          return { hasTop: false, hasBottom: false };
        }
        const fld: keyof CurrentChannelScrollState = options?.afterid ? 'hasBottom' : 'hasTop';

        return { ...s, [fld]: false };
      }

      return s;
    },
  );

  /*const isGlobalChannelId = (
    channelId: ChannelId | ChannelGlobalId,
    map: StoreValue<typeof $_channelIdToGlobalIdMap>,
  ): channelId is ChannelGlobalId => !map;*/

  //receivePost
  sample({
    clock: receivePost,
    source: { currentChannelId: $currentChannelId, postsData: $postsData },
    filter: ({ currentChannelId, postsData }, post) =>
      currentChannelId === post.channelid && !!postsData,
    target: $postsData,
    fn: ({ postsData }, post): PostsDataExt => ({
      ...postsData!,
      posts: [...postsData!.posts, post],
      lastChangeType: 'append',
    }),
  });

  sample({
    clock: receiveDeletePost,
    source: { currentChannelId: $currentChannelId, postsData: $postsData },
    filter: ({ currentChannelId, postsData }, post) =>
      currentChannelId === post.channelid && !!postsData,
    target: $postsData,
    fn: ({ postsData, currentChannelId }, post): PostsDataExt | null => ({
      ...postsData!,
      posts: [...postsData!.posts.map((c) => (c.id === post.id ? { ...post } : c))],
      lastChangeType: 'deletePost',
    }),
  });

  sample({
    clock: receiveUpdatePost,
    source: { currentChannelId: $currentChannelId, postsData: $postsData },
    filter: ({ currentChannelId, postsData }, post) =>
      currentChannelId === post.channelid && !!postsData,
    target: $postsData,
    fn: ({ postsData }, post): PostsDataExt | null => ({
      ...postsData!,
      posts: [...postsData!.posts.map((c) => (c.id === post.id ? { ...post } : c))],
      lastChangeType: 'update',
    }),
  });

  const $currentChannelTyping = combine($channelTypingMap, $currentChannel, (typingMap, channel) =>
    channel?.channel_id ? typingMap.get(channel.channel_id) : null,
  );

  const chatScrollPosition = createChatScrollPosition({
    $postsData,
    fetchChannelPosts,
    $currentChannelScrollState,
  });
  sample({
    clock: ChatGate.close,
    target: chatScrollPosition.resetScrollPosition,
  });

  return {
    ChatGate,
    name,
    filesApi,
    setCurrentChannel,
    resetCurrentChannelData,
    $currentChannelGlobalId,
    $currentChannelId,
    resetPosts,
    $postsData,
    getUserPostsFx,
    getChannelPostsFx,
    $isPostsLoading,
    newChannelCreated,
    toggleReaction,
    removeReaction,

    postSent,
    createPost,
    createPostFx,

    updatePost,
    updatePostFx,

    receivePost,
    receiveDeletePost,
    receiveUpdatePost,

    deletePostFx,

    $curPostsChannelId,

    seenPostFx,
    seenPost,

    resetPinnedPosts,
    $pinnedPosts,

    fetchPinnedPosts,
    pinnedPostFx,
    getPinnedPostsFx,

    copyPostsFx,

    chatScrollPosition,
    $currentChannelScrollState,
    $currentChannelTyping,
    $currentChannelUsersCnt,
    $currentChannelNewPosts,
    $isReadOnly: $currentChannel.map((c) => c?.schemeguest === true),
    postReceived,
  };
};

export type ChatData = ReturnType<typeof createChatData>;

export const mainChatData = createChatData(
  'regular' /*{
  $_channelIdToGlobalIdMap: $channelToGlobalIdMap,
}*/,
);
/** Чат в задачах */
export const taskChatData = createChatData('task');

/** "Внутренний чат по клиенту" */
export const internalChatData = createChatData('inner');

export const chatDataList = [mainChatData, taskChatData, internalChatData];

//todo отдельный файл
// после перехода на сортировку каналов возможно этот стор станет не нужным, т.к. все будет ссылаться на $channelsByGroup
// $channelsCommonMap.on(receivePost, (s, { channelid }) => {
//   const _channelid = $channelToGlobalIdMap.getState().get(channelid);
//   if (!_channelid) {
//     return s;
//   }
//   const channel = s.get(_channelid);
//   if (channel) {
//     return new Map(s).set(_channelid, {
//       ...channel,
//       total_message_count: (channel.total_message_count ?? 0) + 1,
//     });
//   }
// });
sample({
  clock: receivePost,
  source: { map: $channelToGlobalIdMap, channelsByGroup: $channelsByGroup },
  filter: ({ channelsByGroup }) => !!channelsByGroup,
  target: $channelsByGroup,
  fn: ({ map, channelsByGroup }, post): ChannelByGroups => {
    if (
      (map.has(post.channelid) && post.channel_type === 'regular') ||
      post.channel_type === 'inner'
    ) {
      return updateChannelsByGroup(channelsByGroup!, post.channelid, (channel) => ({
        last_post_at: post.createat,
        last_notice_at: post.createat as UnixTimestampMilli,
        total_message_count: (channel.total_message_count || 0) + 1,
      }));
    }

    return channelsByGroup!;
  },
});

//desktop notification
//for regular chat
receivePost.watch((post) => {
  if (
    (post.channel_type !== 'regular' && post.channel_type !== 'inner') ||
    (mainChatData.$curPostsChannelId.getState() === post.channelid && document.hasFocus()) ||
    //не нашли канал среди списка всех регулярных каналов, наверное это внутренний канал клиента или еще что.
    !$channelExtendedMapByChannelId.getState().has(post.channelid)
  )
    return;

  const userFio = post.nickname ? post.nickname : getFio($usersMap.getState().get(post.userid)!);
  const channel = $internalChannelsMap.getState().get(post.channelid as unknown as ChannelGlobalId);

  //todo refact
  let currentMessage = post.message;
  if (!post.message && post.relatedPosts) currentMessage = post.relatedPosts[0].message;

  let title;
  if (post.channel_type === 'inner' && post.team_id) {
    title = `${$customersLightNames.getState().get(post.team_id) ?? ''} - ${i18next.t('ui:internalChat')}`;
  } else if (channel) {
    title = channel.name;
  } else {
    title = userFio;
  }

  const message = htmlToPlainText(currentMessage ?? '');

  const n = new Notification(title, {
    body: channel ? `${userFio}: ${message}` : message,
    icon: noPhotoImg,
    tag: post.channelid,
    timestamp: post.createat || undefined,
  });
  //todo refact
  n.onclick = () => {
    let id;
    let type = '';
    if (post.channel_type === 'inner') {
      id = $customerChannelsMap
        .getState()
        .get(post.team_id as unknown as CustomerChannelsItemId)?.channel_id;
      type = 'internal';
    } else if (channel) {
      id = post.channelid;
    } else {
      id = post.userid;
    }
    history.push(`/channels/${id}/${type}`);
    n.close();
  };

  notifyPlaySound();
});
