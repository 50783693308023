import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { serializeFieldsFilter } from 'models/customer/fn';
import { CustomerId, CustomersTypicalFilter } from 'models/customer/types';
import { CompanyId } from 'models/company/types';
import { BulkSettingsId } from 'models/tasks-bulk-by-teams/types';
import { WithBookkeeper } from '../../types/request';
import { UserId } from 'models/user/types';

// export interface TeamsListParams extends ImmutableMap<TaxbookItemId, VariantItemId[] | [0 | 1]> {}
export interface TeamsListParams {
  bookkeeper_team_id: CompanyId;
  filter: CustomersTypicalFilter;
}

interface CustomerListForm {
  bulk_settings_id: BulkSettingsId;
  params: { assignees: UserId[] } & WithBookkeeper;
}

export const apiTaskBulkTeams = {
  /** Получить список клиентских компаний, подпадающих под условия*/
  teamsList: (params: TeamsListParams) => {
    const { filter, bookkeeper_team_id } = params;
    // ?query[0][id]=1
    // &query[0][values][]=1
    // &query[0][values][]=2
    // &query[1][id]=7
    // &query[1][values][]=7

    // мне понадобилось в другом месте, поэтому я вынес

    const q = serializeFieldsFilter(filter);
    q.append('bookkeeper_team_id', bookkeeper_team_id);
    return dataOnly.get<null, ApiResponse2<CustomerId[]>>(
      `/api2/tasks/bulk-teams/filters/typical-fields/teams`,
      { params: q },
    );
  },

  //todo
  /** Получить настройки массовых задач по task_bulk_settings_id*/
  settingsList: () =>
    dataOnly.get<null, ApiResponse2<unknown>>(`/api2/tasks/bulk-teams/settings/{bulk_settings_id}`),

  /** Удалить незакрытые задачи по заданному bulk_settings_id*/
  remove: () =>
    dataOnly.delete<null, ApiResponse2<unknown>>(`/api2/tasks/bulk-teams/{bulk_settings_id}`),

  /** Обновить все задачи, которые соответствуют bulk_settings_id*/
  update: () =>
    dataOnly.patch<null, ApiResponse2<unknown>>(`/api2/tasks/bulk-teams/{bulk_settings_id}`),

  /** Получить список клиентских компаний, для которых созданы групповые задачи */
  customerList: ({ bulk_settings_id, params }: CustomerListForm, signal?: AbortSignal) =>
    dataOnly.get<null, ApiResponse2<CustomerId[]>>(
      `/api2/tasks/bulk-teams/settings/${bulk_settings_id}/teams`,
      { params, signal },
    ),
};
