import { AxiosResponse } from 'axios';
import { ApiErrorBase } from './apiError';
import { hasProperty } from '../../utils/object';

export interface ErrorDetails {
  readonly result: false;
  readonly message: string;
}

const isErrorDetails = (data: unknown): data is ErrorDetails =>
  hasProperty(data, 'message') &&
  hasProperty(data, 'result') &&
  data.result === false &&
  typeof data.message === 'string';

export default class ApiClientError<TResult = any> extends ApiErrorBase<TResult> {
  readonly details: ErrorDetails | null;

  constructor(response: AxiosResponse<TResult>, message?: string) {
    if (process.env.NODE_ENV !== 'test') {
      console.log('>> api error', response);
    }
    super(response, message);

    this.details = isErrorDetails(response.data) ? response.data : null;
  }

  get data(): TResult {
    return this.response.data;
  }
}
