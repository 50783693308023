import { createEffect, createEvent, createStore, sample, StoreValue } from 'effector';
import {
  apiTaskChecklist,
  TaskChecklistCreateParams,
  TaskChecklistDeleteParams,
  TaskChecklistListParams,
} from 'api/request/task-checklist';
import { Task } from 'models/task/types';
import { $currentCompanyIdOrNull } from 'models/company';
import {
  isRepeatableTaskChecklistAnswer,
  TaskChecklist,
  TaskChecklistOperate,
} from 'models/task-checklist/types';
import { toastErrorFx } from 'models/utils/messages';
import { showToast, ToastTypes } from 'ui/feedback';
import i18next from 'i18next';
import { createRequestEffect } from '../utils/createRquestEffect';
import { TaskChecklistItemId } from '../task-checklist-item/types';

export const resetCheckList = createEvent();
/** List */
export const getTaskCheckList = createEvent<Task>();
export const getTaskCheckListFx = createEffect(
  async (params: TaskChecklistListParams) => await apiTaskChecklist.list(params),
);
export const $checklist = createStore<TaskChecklist | null>(null);
$checklist.reset(resetCheckList);
sample({ clock: getTaskCheckList, target: resetCheckList });
sample({
  clock: getTaskCheckList,
  source: $currentCompanyIdOrNull,
  target: getTaskCheckListFx,
  fn: (companyId, task): TaskChecklistListParams => ({
    taskId: task.id,
    params: {
      bookkeeper_team_id: companyId!,
      // Если задача внутренняя, то не отправляем team_id
      team_id: task.team_id ?? undefined,
      is_typical: task.is_typical,
    },
  }),
});
$checklist.on(getTaskCheckListFx.doneData, (_, { data }) => data[0] ?? null);

/** Create */
export const createTaskCheckList = createEvent<TaskChecklistOperate>();
export const createTaskCheckListFx = createRequestEffect(apiTaskChecklist.create);
sample({
  clock: createTaskCheckList,
  source: $currentCompanyIdOrNull,
  target: createTaskCheckListFx,
  fn: (companyId, { task, forwardType }): TaskChecklistCreateParams => ({
    taskId: task.id,
    form: {
      bookkeeper_team_id: companyId!,
      team_id: task.team_id,
      is_typical: task.is_typical,
      title: 'default',
      apply_to_repeatable: forwardType === 'customer' || forwardType === 'all',
      for_teams:
        forwardType === 'customer' && (task as Task).team_id
          ? [(task as Task).team_id!]
          : undefined,
    },
  }),
});
$checklist.on(createTaskCheckListFx.doneData, (_, { data }) =>
  isRepeatableTaskChecklistAnswer(data) ? data.checklists[0] : data,
);

/** Delete */
export const deleteTaskCheckList = createEvent<TaskChecklistOperate>();
export const deleteTaskCheckListFx = createEffect(
  async (params: TaskChecklistDeleteParams) => await apiTaskChecklist.delete(params),
);
sample({
  clock: deleteTaskCheckList,
  source: [$currentCompanyIdOrNull, $checklist] as const,
  target: deleteTaskCheckListFx,
  fn: ([companyId, list], { task, forwardType }): TaskChecklistDeleteParams => ({
    taskId: task.id,
    checklistId: list!.id,
    params: {
      apply_to_repeatable: forwardType === 'customer' || forwardType === 'all',
      for_teams:
        forwardType === 'customer' && (task as Task).team_id
          ? [(task as Task).team_id!]
          : undefined,
      bookkeeper_team_id: companyId!,
      team_id: task.team_id,
      is_typical: task.is_typical,
    },
  }),
});
$checklist.on(deleteTaskCheckListFx.doneData, () => null);

/** Sort */

export const updateSortingTaskCheckList = createEvent<TaskChecklistItemId[]>();
export const restoreChecklist = createEvent();

const $prevChecklist = createStore<TaskChecklist | null>(null);

sample({
  clock: updateSortingTaskCheckList,
  source: $checklist,
  target: $prevChecklist,
});
sample({
  clock: updateSortingTaskCheckList,
  source: $checklist,
  target: $checklist,
  fn: (checklist, sorted): StoreValue<typeof $checklist> =>
    checklist
      ? {
          ...checklist,
          items: checklist.items
            ? sorted.map((id) => checklist.items!.find((item) => item.id === id)!)
            : null,
        }
      : null,
});

sample({
  clock: restoreChecklist,
  source: $prevChecklist,
  target: $checklist,
});

/** Уведомления */
sample({
  clock: [createTaskCheckListFx.fail, deleteTaskCheckListFx.fail],
  target: toastErrorFx,
});
sample({
  clock: createTaskCheckListFx.done,
  target: createEffect(() => {
    showToast(ToastTypes.success, i18next.t('task:edit.checklist.message.successCreate'));
  }),
});
sample({
  clock: deleteTaskCheckListFx.done,
  target: createEffect(() => {
    showToast(ToastTypes.success, i18next.t('task:edit.checklist.message.successDelete'));
  }),
});
