import { createEffect, createEvent, createStore, sample } from 'effector';
import { not } from 'patronum';
import ApiUnprocessableError from 'api/errors/422';
import api, { apiMailCredentialsCheck2 } from 'api/request/mail-credentials';
import { CompanyId } from 'models/company/types';
import {
  MailCredentials,
  MailCredentialsClearErrorParams,
  MailCredentialsCreateForm,
  MailCredentialsDeleteParams,
  MailCredentialsId,
  MailCredentialsUpdateForm,
  MailCredentialsUpdateParams,
  MailService,
} from 'models/mail-credentials/types';
import { createGate, useGate, useStoreMap } from 'effector-react';
import { $currentCompanyIdOrNull, $currentCompanyRegion } from 'models/company';
import { showToast, ToastDuration, ToastTypes } from 'ui/feedback';
import { isNotNull } from 'utils/fn';

export const MailCredentialsGate = createGate();

export const $mailCredentials = createStore<MailCredentials[]>([]);

export const getMailCredentials = createEvent();
export const getMailCredentialsFx = createEffect(
  async (companyId: CompanyId) => await api.list(companyId),
);
sample({ clock: MailCredentialsGate.open, target: getMailCredentials });
sample({
  clock: sample({
    clock: getMailCredentials,
    source: $currentCompanyIdOrNull,
    filter: Boolean,
  }),
  filter: not(getMailCredentialsFx.pending),
  target: getMailCredentialsFx,
});
$mailCredentials.on(getMailCredentialsFx.doneData, (_, { data }) => data);

export const mailCredentialsUpdated = createEvent<MailCredentials>();
$mailCredentials.on(mailCredentialsUpdated, (list, upd) =>
  list.map((c) => (c.id === upd.id ? upd : c)),
);

type _MailCredentialsCreateForm = Pick<
  MailCredentialsCreateForm,
  | 'email'
  | 'password'
  | 'token'
  | 'incoming_server_host'
  | 'incoming_server_port'
  | 'outgoing_server_host'
  | 'outgoing_server_port'
>;
export const checkRawMailCredentialsFx = createEffect(apiMailCredentialsCheck2);
export const checkMailCredentialsFx = createEffect(async (form: _MailCredentialsCreateForm) => {
  try {
    await checkRawMailCredentialsFx({
      login: form.email,
      password: form.password,
      token: form.token,
      imap: {
        host: form.incoming_server_host,
        port: form.incoming_server_port,
      },
      smtp: {
        host: form.outgoing_server_host,
        port: form.outgoing_server_port,
      },
    });
  } catch (e) {
    if (e instanceof ApiUnprocessableError) {
      throw new Error(
        'Ошибки:' + e.errors.map((ef) => `\n\n* ${ef.failed_field}: ${ef.message}`).join(''),
      );
    }
    throw e;
  }
});

/** Create */
export const createMailCredentials =
  createEvent<Omit<MailCredentialsCreateForm, 'bookkeeper_team_id'>>();
export const createMailCredentialsFx = createEffect(async (form: MailCredentialsCreateForm) => {
  await checkMailCredentialsFx(form);
  return await api.create(form);
});
createMailCredentialsFx.failData.watch((e) =>
  showToast(ToastTypes.error, e.message, ToastDuration.LONGER),
);
$mailCredentials.on(createMailCredentialsFx.doneData, (list, { data }) => [...list, data]);

sample({
  clock: createMailCredentials,
  source: $currentCompanyIdOrNull,
  target: createMailCredentialsFx,
  fn: (companyId, params): MailCredentialsCreateForm => ({
    ...params,
    bookkeeper_team_id: companyId!,
  }),
});
/** Update */
export const updateMailCredentials = createEvent<{
  id: MailCredentialsId;
  form: Omit<MailCredentialsUpdateForm, 'bookkeeper_team_id'>;
}>();
export const updateMailCredentialsFx = createEffect(async (params: MailCredentialsUpdateParams) => {
  await checkMailCredentialsFx(
    // REFACT: по факту все поля всегда указаны - см. `target: updateMailCredentials`
    params.form as MailCredentialsCreateForm,
  );
  return await api.update(params);
});
sample({
  clock: updateMailCredentials,
  source: $currentCompanyIdOrNull,
  target: updateMailCredentialsFx,
  fn: (companyId, { id, form }): MailCredentialsUpdateParams => ({
    id,
    form: {
      bookkeeper_team_id: companyId!,
      ...form,
      // comment: form.comment,
      // email: form.email,
      // incoming_server_host: form.incoming_server_host,
      // incoming_server_port: form.incoming_server_port,
      // outgoing_server_host: form.outgoing_server_host,
      // outgoing_server_port: form.outgoing_server_port,
      // password: form.password,
      // token: form.token,
      // service_id: form.service_id
    },
  }),
});
sample({
  source: updateMailCredentialsFx.doneData,
  fn: ({ data }) => data,
  target: mailCredentialsUpdated,
});

export const clearErrorMailCredentials = createEvent<MailCredentialsId>();
export const clearErrorMailCredentialsFx = createEffect(api.clearError);
sample({
  clock: clearErrorMailCredentials,
  source: $currentCompanyIdOrNull,
  fn: (companyId, id): MailCredentialsDeleteParams => ({
    id,
    form: {
      bookkeeper_team_id: companyId!,
    },
  }),
  target: clearErrorMailCredentialsFx,
});
clearErrorMailCredentialsFx.failData.watch((e) => showToast(ToastTypes.error, e.message));

/** Delete */
export const deleteMailCredentials = createEvent<MailCredentialsId>();
export const deleteMailCredentialsFx = createEffect(
  async (params: MailCredentialsDeleteParams) => await api.delete(params),
);
sample({
  clock: deleteMailCredentials,
  source: $currentCompanyIdOrNull,
  target: deleteMailCredentialsFx,
  fn: (companyId, id): MailCredentialsClearErrorParams => ({
    id,
    form: {
      bookkeeper_team_id: companyId!,
    },
  }),
});

sample({
  clock: [
    deleteMailCredentialsFx.done,
    clearErrorMailCredentialsFx.done,
    createMailCredentialsFx.done,
  ],
  source: MailCredentialsGate.status,
  filter: (state) => state,
  target: getMailCredentials,
});

/** servicesList */
export const MailServicesListGate = createGate();
//todo if needed
export const getMailServicesList = createEvent();
export const getMailServicesListFx = createEffect(
  async (region: string) => await api.servicesList(region),
);
export const $mailServicesList = createStore<MailService[]>([]);

sample({ clock: MailServicesListGate.open, target: getMailServicesList });
sample({
  clock: getMailServicesList,
  source: $currentCompanyRegion,
  filter: isNotNull,
  target: getMailServicesListFx,
});
$mailServicesList.on(getMailServicesListFx.doneData, (_, { data }) => data);

export const useMailCredential = (id?: MailCredentialsId | null) => {
  useGate(MailCredentialsGate);
  return useStoreMap({
    store: $mailCredentials,
    keys: [id],
    fn: (list, [id]) => (id && list.find((c) => c.id === id)) || null,
  });
};
