const samePendingPromise = <T>(issuer: () => Promise<T>) => {
  let pending: Promise<T> | null = null;

  return (): Promise<T> => {
    if (pending) {
      return pending;
    }

    return (pending = issuer().finally(() => {
      pending = null;
    }));
  };
};

export default samePendingPromise;
