import { createEffect, Event, sample } from 'effector';
import { showToast, showToastError, ToastTypes } from 'ui/feedback';
import i18next, { TOptions } from 'i18next';
import { TFuncKey } from 'react-i18next';

export const messageErrorFx = createEffect((message?: string) => {
  if (message) showToast(ToastTypes.error, message);
  else showToastError();
});

export const messageSuccessFx = createEffect((message?: string) => {
  if (message) showToast(ToastTypes.success, message);
  else showToast(ToastTypes.success, i18next.t('ui:messages.success'));
});

export const toastErrorFx = createEffect(() => {
  showToastError();
});

interface Notification {
  mode: ToastTypes;
  tKey: TFuncKey;
  tOptions?: TOptions;
}

const displayNotificationFx = createEffect(({ mode, tKey, tOptions }: Notification) => {
  showToast(mode, i18next.t(tKey, tOptions));
});

export const notification = <T>(config: {
  clock: Event<T>;
  mode: ToastTypes;
  tKey: ((event: T) => TFuncKey) | TFuncKey;
  optionsFn?: (clock: T) => TOptions;
}) => {
  sample({
    clock: config.clock,
    target: displayNotificationFx,
    fn: (clock): Notification => ({
      mode: config.mode,
      tKey: typeof config.tKey === 'function' ? config.tKey(clock) : config.tKey,
      tOptions: config.optionsFn?.(clock),
    }),
  });
};
