import { createEffect, createEvent, createStore, sample } from 'effector';
import { fromApiToTask, Task, TaskId } from './types';
import * as RoMap from '@cubux/readonly-map';
import i18next from 'i18next';
import { notifyPlaySound } from '../notify/sound';
import { createRequestEffect } from '../utils/createRquestEffect';
import api from 'api/request/task';
import { $currentCompanyId } from '../company';
import { CompanyId } from '../company/types';
import { toISODateTimeString } from 'utils/date';
import { getUniqKeyRnd } from 'utils/helpers';
import { stripTags } from 'utils/string/stripTags';
import { DateTimeISO } from 'utils/types';
import { setCurrentTask } from 'routes/main/routes/task/components/TaskEditModal/model';
import { $user } from '../user';
import { EMPTY_MAP } from 'constants/utils';
import { newTaskNoticeReceived } from '../notices';
import { NoticeAttribute, NoticeAttributeId, NoticeExt, NoticeId } from '../notices/types';
import { $customers } from '../customer';
import taskNewIco from 'assets/img/taskNew.svg';

export interface TaskCreatedEvent {
  task: Task;
  date: DateTimeISO;
}

// 1. Приходит массив TaskId
// 2. Проверяем какие задачи наши, делая запрос по id

export const tasksCreatedReceived = createEvent<TaskId[]>();
export const removeTaskEvent = createEvent<TaskId>();
export const removeAllTaskEvent = createEvent<unknown>();

const fetchUserTaskFx = createRequestEffect(api.get);

export const $newTaskEventsMap = createStore<ReadonlyMap<TaskId, TaskCreatedEvent>>(
  EMPTY_MAP,
).reset($currentCompanyId.updates);
export const $hasNewTasks = $newTaskEventsMap.map((c) => !!c.size);
export const $newTaskEvents = $newTaskEventsMap.map((c) => [...c.values()]);

sample({
  clock: tasksCreatedReceived,
  source: $currentCompanyId,
  target: createEffect(({ taskIds, companyId }: { taskIds: TaskId[]; companyId: CompanyId }) => {
    // Вместо обхода всего массива, запросим только первую задачу, иначе для повторяющихся задач будет очень много запросов
    // taskIds.forEach((task_id) => fetchUserTaskFx({ task_id, bookkeeper_team_id: companyId }));
    return fetchUserTaskFx({ task_id: taskIds[0], bookkeeper_team_id: companyId });
  }),
  fn: (companyId, taskIds) => ({
    companyId: companyId as CompanyId,
    taskIds,
  }),
});
sample({
  clock: fetchUserTaskFx.doneData,
  source: { user: $user, map: $newTaskEventsMap },
  filter: ({ user }, { data }) => user?.id !== data.user_id,
  target: $newTaskEventsMap,
  fn: ({ map }, { data }) =>
    RoMap.set(map, data.id, {
      task: fromApiToTask(data),
      date: toISODateTimeString(new Date()),
    }),
});
// Вместо добавления нового сообщения в ручную, просто сделаем рефетч, т.к. не хватает данных в событии, а конкретно нет notice id
sample({
  clock: fetchUserTaskFx.doneData,
  target: newTaskNoticeReceived,
  fn: ({ data }): NoticeExt<'tasks-created'> => {
    const notice_id = getUniqKeyRnd() as unknown as NoticeId;
    const now = new Date();
    const attributes: NoticeAttribute[] = [
      {
        id: getUniqKeyRnd() as unknown as NoticeAttributeId,
        value: `[${data.id}]`,
        name: 'tasks',
        notice_id,
      },
      {
        id: getUniqKeyRnd() as unknown as NoticeAttributeId,
        value: [data.team_id],
        name: 'customer_team_id',
        notice_id,
      },
    ];

    return {
      attributes,
      attributesMap: new Map(attributes.map((attr) => [attr.name, attr])),
      id: notice_id,
      created_at: toISODateTimeString(now),
      its_read: false,
      message: '',
      notice_time: toISODateTimeString(now),
      notice_type: 'tasks-created',
      title: stripTags(data.text),
      updated_at: toISODateTimeString(now),
    };
  },
});
sample({
  clock: fetchUserTaskFx.doneData,
});
$newTaskEventsMap
  .on(removeTaskEvent, (s, taskId) => RoMap.remove(s, taskId))
  .on(removeAllTaskEvent, () => EMPTY_MAP);

//todo create factory
// desktop notification
fetchUserTaskFx.doneData.watch(({ data }) => {
  const user = $user.getState();
  if (user?.id === data.user_id) {
    return;
  }
  const customers = $customers.getState();
  const customerName = data.team_id
    ? customers.find((customer) => customer.id === data.team_id)?.customerShortName ?? ''
    : i18next.t('ui:notification.task.internalTask');
  const n = new Notification(`${i18next.t('ui:notification.task.title')}: ${customerName}`, {
    body: stripTags(data.text),
    icon: taskNewIco,
    tag: String(data.id),
    timestamp: Date.now(),
  });
  n.onclick = () => {
    window.focus();
    setCurrentTask(fromApiToTask(data));
    n.close();
  };

  notifyPlaySound();
});
