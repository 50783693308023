import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { IView } from '../../types';
import cl from './LinearFormula.module.scss';

// TODO: сейчас выравнивание формулы достигается за счёт того, что все `title`
//  и `content` имеют одинаковую высоту. В `title` везде всё в одну строку одним
//  шрифтом без иконок/картинок, а в `content` просто по факту инпуты одинакового
//  размера.

export interface FormulaVar {
  title?: ReactNode;
  content: ReactNode;
  help?: ReactNode;
  before?: ReactNode;
  after?: ReactNode;
}

type Skip = boolean | null | undefined;
const skip = (v: any): v is Skip => v === true || v === false || v === null || v === undefined;

interface Props extends IView {
  formula: readonly (FormulaVar | Skip)[];
}

export const LinearFormula: FC<Props> = ({ formula, className }) => (
  <div className={cn(cl.root, className)}>
    {formula.map(
      (f, i) =>
        skip(f) || (
          <div key={i} className={cl.var}>
            {f.before !== undefined && <div className={cl.before}>{f.before}</div>}
            {f.title !== undefined && <div className={cl.title}>{f.title}</div>}
            <div className={cl.content}>{f.content}</div>
            {f.help !== undefined && <div className={cl.help}>{f.help}</div>}
            {f.after !== undefined && <div className={cl.after}>{f.after}</div>}
          </div>
        ),
    )}
  </div>
);
