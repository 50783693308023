import { format } from 'date-fns';
import { DateISO, DateTime, DateTimeISO } from '../types';

// https://date-fns.org/v2.17.0/docs/format

export const toISODateString = (date: Date): DateISO => format(date, 'yyyy-MM-dd') as DateISO;
export const toISODateTimeString = (date: Date) =>
  format(date, "yyyy-MM-dd'T'HH:mm:ssXXX") as DateTimeISO;

export const toDateTimeString = (date: Date) => format(date, 'yyyy-MM-dd HH:mm:ss') as DateTime;
