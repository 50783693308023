import { createEffect, createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import api, { apiInviteDelete } from 'api/request/invite';
import { promptFn, showToastError } from 'ui/feedback';
import { $currentCompanyIdOrNull } from '../company';
import { CompanyId } from '../company/types';
import {
  $inviteList,
  confirmInviteFx,
  createInviteFx,
  getInviteCompanyList,
  getInviteListFx,
} from './index';
import { InviteCreateForm } from './types';

export const InviteListGate = createGate();

/** $inviteList */
const resetInviteList = createEvent();
getInviteListFx.use(async (companyId?: CompanyId) => await api.list(companyId));
sample({
  clock: getInviteCompanyList,
  source: $currentCompanyIdOrNull,
  filter: Boolean,
  target: getInviteListFx,
});
sample({
  clock: createInviteFx.done,
  filter: InviteListGate.status,
  target: getInviteCompanyList,
});

createInviteFx.use(async (form: InviteCreateForm) => {
  return await api.create(form);
});

$inviteList.on(getInviteListFx.doneData, (_, { data }) => data);
$inviteList.reset([resetInviteList, InviteListGate.close]);

confirmInviteFx.use(async (id: string) => await api.confirm(id));

const deleteFx = createEffect(apiInviteDelete);
export const deleteInviteFx = createEffect(async (id: string) => {
  if (await promptFn(i18next.t('account:employee.promptDeleteInvite'))) {
    return deleteFx(id);
  }
});
$inviteList.on(deleteFx.done, (list, { params: id }) => list.filter((c) => c.id !== id));
deleteFx.failData.watch(showToastError);
