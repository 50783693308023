import cn from 'classnames';
import { FC, ReactNode } from 'react';
import Icon, { IconRef } from 'ui/Icon';
import { IView } from 'ui/types';
import { Panel } from '../Panel';
import cl from './PanelWithTitle.module.scss';

interface Props extends IView {
  title: ReactNode | string;
  titleEnd?: ReactNode;
  headClassName?: string;
  titleClassName?: string;
  iconClassName?: string;
  icon?: IconRef;
}

export const PanelWithTitle: FC<Props> = ({
  title,
  titleEnd = null,
  headClassName,
  titleClassName,
  iconClassName,
  icon,
  children,
  className,
}) => {
  return (
    <Panel className={cn(cl.root, className)}>
      <div className={cn(cl.header, headClassName)}>
        {icon !== undefined && <Icon type={icon} className={cn(cl.icon, iconClassName)} />}
        <div className={cn(cl.title, titleClassName)}>{title}</div>
        {null !== titleEnd && <div className={cl.titleEnd}>{titleEnd}</div>}
      </div>
      {children}
    </Panel>
  );
};
