import { FC } from 'react';
import cl from './Badge.module.scss';
import cn from 'classnames';

interface Props {
  qty?: number;
  className?: string;
}

export const Badge: FC<Props> = ({ qty, className }) => {
  if (!qty) return null;
  return <div className={cn(cl.root, className)}>{qty}</div>;
};
