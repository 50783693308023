import { Color, IdNumberOf } from 'utils/types/primitive';
import { CompanyId } from 'models/company/types';

export interface CommonTag<Id = number> {
  id: Id;
  tag: string;
  color: Color;
  icon: string;
  sort: number;
}

export type TagId = IdNumberOf<'Tag'>;

export interface Tag extends CommonTag<TagId> {
  is_global?: true;
  // https://trello.com/c/yXFYTynX/1344
  // api2#228 https://gitlab.com/finkoper/finwork-tasks/-/issues/228
  alias?: string | null;
}

export interface TagCreateForm {
  bookkeeper_team_id: CompanyId;
  color?: string;
  icon?: string;
  sort?: number;
  text: string;
  // https://trello.com/c/yXFYTynX/1344
  // api2#228 https://gitlab.com/finkoper/finwork-tasks/-/issues/228
  alias?: string;
}

export interface TagForm extends Omit<TagCreateForm, 'bookkeeper_team_id'> {}

export interface TagDeleteForm {
  bookkeeper_team_id: CompanyId;
}

export interface TagUpdateForm extends Partial<TagCreateForm> {
  bookkeeper_team_id: CompanyId;
}

export const cmpTags = (a: CommonTag<any>, b: CommonTag<any>) => a.sort - b.sort || a.id - b.id;

// https://trello.com/c/yXFYTynX/1344
// https://api.dev.finkoper.com/api2/docs/index.html#/tags/tag-list
// https://gitlab.com/finkoper/finwork-tasks/-/issues/228
// Создать или обновить алиас. Если пустое значение, алиас будет удален
export interface TagAliasForm {
  _tagId: TagId;
  title?: string;
}
