import { dataOnly } from 'api/instance';
import { ApiResponse2, PaginatedResponse2 } from 'api/types';
import { CompanyId } from 'models/company/types';
import {
  KonturAuthForm,
  KonturAuthTokens,
  KonturCompany,
  KonturCompanyId,
  KonturCompanyInfo,
  KonturCompanyUpdateForm,
  KonturCustomer,
  KonturCustomerSyncForm,
  KonturUnlinkParams,
  KonturUser,
  KonturUserId,
} from 'models/kontur/types';

export interface KonturCustomerSyncParams {
  konturCompanyId: KonturCompanyId;
  data: KonturCustomerSyncForm;
}

export const apiUsersList = (bookkeeper_team_id: CompanyId) =>
  dataOnly.get<null, ApiResponse2<readonly KonturUser[]>>('/api3/kontur/users', {
    params: { bookkeeper_team_id },
  });

export const apiUserDelete = (userId: KonturUserId, form: KonturUnlinkParams) =>
  dataOnly.delete<null, void>(`/api3/kontur/users/${Number(userId)}`, { data: form });

export const apiUserSync = (userId: KonturUserId) =>
  dataOnly.post<null, ApiResponse2<KonturUser>>(`/api3/kontur/users/${Number(userId)}/sync`);

export interface OrgsListParams {
  search?: string;
  per_page?: number;
  page?: number;
}
export const apiOrgsList = (userId: KonturUserId, params?: OrgsListParams) =>
  dataOnly.get<null, ApiResponse2<readonly KonturCompany[]> & PaginatedResponse2>(
    `/api3/kontur/users/${Number(userId)}/organizations`,
    { params },
  );

export const apiOrgUpdate = (
  userId: KonturUserId,
  orgId: KonturCompanyId,
  form: KonturCompanyUpdateForm,
) =>
  dataOnly.put<null, ApiResponse2<KonturCompany>>(
    `/api3/kontur/users/${Number(userId)}/organizations/${Number(orgId)}`,
    form,
  );

export const apiOrgCreateCustomerFrom = (userId: KonturUserId, orgId: KonturCompanyId) =>
  dataOnly.post<null, ApiResponse2<KonturCompany>>(
    `/api3/kontur/users/${Number(userId)}/organizations/${Number(orgId)}`,
  );

/** Получение ссылки на переадресацию в контур */
export const apiGetOpenid = () => dataOnly.get<null, ApiResponse2<string>>(`/api3/kontur/openid`);

/** Авторизация после обратного редиректа из Контур */
export const apiAuth = (data: KonturAuthForm) =>
  dataOnly.post<null, ApiResponse2<KonturAuthTokens>>(`/api3/kontur/openid`, data);

/** Информация о бух. компании контура */
export const apiCompanyInfo = () =>
  dataOnly.get<null, ApiResponse2<KonturCompanyInfo>>(`/api3/kontur/openid/user`);

/** Информация о бух. компаниях контура */
export const apiCompaniesInfo = () =>
  dataOnly.get<null, ApiResponse2<KonturCompanyInfo[]>>(`/api3/kontur/openid/users`);

/** Получение клиентов из Контура */
export const apiCustomers = () =>
  dataOnly.get<null, ApiResponse2<KonturCustomer[]>>(`/api3/kontur/openid/users/clients`);

/** Синхронизация клиентов из Контура c Finkoper */
export const apiCustomersSync = ({ data }: KonturCustomerSyncParams) =>
  dataOnly.post<null, ApiResponse2<unknown>>(`/api3/kontur/openid/users/clients`, data);

/** Получение ссылки на переадресацию в Контур, для подключения пользователя */
export const apiGetUserLink = (bookkeeper_team_id: CompanyId) =>
  dataOnly.get<null, ApiResponse2<string>>(`/api3/kontur/openid/connect`, {
    params: { bookkeeper_team_id },
  });

/** Авторизация пользователя Контур, после обратного редиректа */
export const apiAuthUser = (data: KonturAuthForm) =>
  dataOnly.post<null, ApiResponse2<unknown>>(`/api3/kontur/openid/connect`, data);
