import { dataOnly } from 'api/instance';
import { Integration, IntegrationSaveForm } from 'models/integrations/types';
import { ApiResponse2 } from 'api/types';
import { CompanyId } from 'models/company/types';

export const integrationsApi = {
  /** Список интеграций */
  list: (companyId: CompanyId) =>
    dataOnly.get<null, ApiResponse2<Integration[]>>(`/api3/integrations/${companyId}`),

  /** Сохранить настройки интеграций */
  save: (params: IntegrationSaveForm) =>
    dataOnly.post<null, ApiResponse2<unknown>>(`/api3/integrations`, params),
};
