import { EMPTY_ARRAY } from 'constants/utils';
import { FixedSizeDefinition } from './types';

export function cssFixedSizes(count: number, defined: FixedSizeDefinition) {
  const sizes = fullFixedSizes(count, defined);
  const sum = sizes.reduce((a, b) => a + b, 0);
  return {
    values: sizes.map((n) => `${n}px`).join(' '),
    sumCss: `${sum}px`,
    sum,
  };
}

function fullFixedSizes(count: number, defined: FixedSizeDefinition): readonly number[] {
  if (!count) return EMPTY_ARRAY;
  if (typeof defined === 'number') return new Array(count).fill(defined);
  if (defined.length > count) return defined.slice(0, count);
  if (defined.length === count) return defined;
  return defined.concat(new Array(count - defined.length).fill(defined[defined.length - 1]));
}
