import { createEvent, createStore, sample } from 'effector';
import { centSubscribeCompany } from '../../cent';
import { $currentCompanyId } from './index';

const setSubTeam = createEvent<(() => void) | null>();
const unsubTeam = createEvent<any>();
const $subTeam = createStore<(() => void) | null>(null).on(setSubTeam, (_, f) => f);
sample({
  clock: unsubTeam,
  source: $subTeam,
}).watch((f) => {
  f?.();
  setSubTeam(null);
});

$currentCompanyId.updates.watch((id) => {
  if (id) {
    setSubTeam(centSubscribeCompany(id));
  } else {
    unsubTeam();
  }
});
