import { CSSProperties, FC } from 'react';
import cl from './Avatar.module.scss';
import cn from 'classnames';
import noPhotoSrc from 'assets/img/noPhoto.svg';

interface Props {
  className?: string;
  src?: string | null;
  useShadow?: boolean;
  size?: number;
  name?: string;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const Avatar: FC<Props> = ({ src, useShadow = false, className, size, name }) => {
  const withName = !src && !!name;

  let style: CSSProperties | undefined;
  if (size || withName) {
    style = {};
    if (size) {
      style.width = size;
      style.height = size;
    }
    if (withName) {
      style.backgroundColor = stringToColor(name);
      if (size) {
        style.lineHeight = size + 'px';
        style.fontSize = size / 1.75;
      }
    }
  }

  return (
    <div
      className={cn(cl.root, useShadow && cl.useShadow, withName && cl.withName, className)}
      style={style}
    >
      {!withName ? <img src={src || noPhotoSrc} alt="" /> : name[0].toUpperCase()}
    </div>
  );
};
