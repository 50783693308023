import { noticesApi } from '../../api/request/notices';
import { createRequestEffect } from '../utils/createRquestEffect';
import { createEvent, EffectParams, restore, sample } from 'effector';
import {
  Notice,
  NoticeAttribute,
  NoticeAttributeType,
  NoticeExt,
  NoticeItsRead,
  NoticeType,
} from './types';
import { EMPTY_ARRAY } from '../../constants/utils';
import { ChannelByGroups, ChannelId, CustomerChannelsItemId } from '../channel/types';
import { $currentCompanyId } from '../company';
import { CompanyId } from '../company/types';
import { TaskId } from '../task/types';
import { $channelsByGroup, $currentChannelCustomerId } from '../channel';
import { EmailId } from '../mail/types';
import { UnixTimestamp } from '../../utils/types';
import { CustomerId } from '../customer/types';

export const removeAll = createEvent<NoticeType | undefined>();
export const removeAllByCurrentCustomer = createEvent<NoticeType | undefined>();
export const removeByTaskId = createEvent<TaskId>();
export const removeByChannelId = createEvent<ChannelId>();
export const removeByMailId = createEvent<EmailId>();
export const addNotice = createEvent<NoticeExt>();
export const newTaskNoticeReceived = createEvent<NoticeExt<'tasks-created'>>();
export const newMailNoticeReceived = createEvent<NoticeExt<'mail.new'>>();
export const receivedNoticeNewEvent = createEvent<Notice>();
export const receivedNoticeItsReadEvent = createEvent<NoticeItsRead>();

export const fetchNoticesFx = createRequestEffect(noticesApi.notices, true);
const removeNoticeFx = createRequestEffect(noticesApi.remove);
const removeAllNoticesFx = createRequestEffect(noticesApi.removeAll);

export const $notices = restore<NoticeExt[]>(
  fetchNoticesFx.doneData.map(({ data }) =>
    data.map((notice) => ({
      ...notice,
      attributesMap: new Map<NoticeAttributeType, NoticeAttribute>(
        notice.attributes.map((attr) => [attr.name, attr]),
      ),
    })),
  ),
  EMPTY_ARRAY,
);
// на общей вкладке как ни странно показываются уведомления о новых сообщениях в чатах задач
export const $commonNotices = $notices.map((notices) =>
  notices.filter((c) => c.notice_type === 'tasks.new-message'),
);
// на вкладке "задачи" пока показывается только уведомление о новой задаче
export const $taskNotices = $notices.map((notices) =>
  notices.filter((c) => c.notice_type === 'tasks-created'),
);
// в "колокольчике" посчитаем только сообщения связанные с задачами
export const $taskNoticesQty = $notices.map((notices) =>
  notices.reduce(
    (total, { notice_type }) =>
      notice_type === 'tasks.new-message' || notice_type === 'tasks-created' ? total + 1 : total,
    0,
  ),
);

sample({
  clock: sample({
    clock: removeByTaskId,
    source: $notices,
    fn: (notices, taskId) =>
      notices.find(
        (c) =>
          c.notice_type === 'tasks-created' &&
          (c.attributesMap.get('tasks')!.value as TaskId[]).indexOf(taskId) > -1,
      )?.id,
  }),
  filter: Boolean,
  target: removeNoticeFx,
});
sample({
  clock: sample({
    clock: removeByChannelId,
    source: $notices,
    fn: (notices, channelId) =>
      notices.find((c) => c.attributesMap.get('channel_id')?.value === channelId)?.id,
  }),
  filter: Boolean,
  target: removeNoticeFx,
});

sample({
  clock: removeByMailId,
  source: $notices,
  target: $notices,
  fn: (notices, emailId) =>
    notices.filter(
      (notice) =>
        notice.notice_type !== 'mail.new' || notice.attributesMap.get('post_id')?.value !== emailId,
    ),
});
sample({
  clock: removeAll,
  source: $currentCompanyId,
  target: removeAllNoticesFx,
  fn: (companyId, notice_type): EffectParams<typeof removeAllNoticesFx> => ({
    bookkeeper_team_id: companyId as CompanyId,
    notice_type,
  }),
});
sample({
  clock: removeAllByCurrentCustomer,
  source: { companyId: $currentCompanyId, customerId: $currentChannelCustomerId },
  filter: ({ customerId }) => !!customerId,
  target: removeAllNoticesFx,
  fn: ({ companyId, customerId }, notice_type): EffectParams<typeof removeAllNoticesFx> => ({
    bookkeeper_team_id: companyId as CompanyId,
    customer_team_id: customerId as unknown as CustomerId,
    notice_type,
  }),
});

$notices
  .on(removeNoticeFx.done, (s, { params }) => s.filter((c) => c.id !== params))
  .on(receivedNoticeItsReadEvent, (s, { notice_id }) => s.filter((c) => c.id !== notice_id))
  .on(addNotice, (s, notice) => [...s, notice])
  .on(receivedNoticeNewEvent, (s, notice) => {
    const noticeExt = {
      ...notice,
      attributesMap: new Map<NoticeAttributeType, NoticeAttribute>(
        notice.attributes.map((attr) => [attr.name, attr]),
      ),
    };
    if (notice.notice_type === 'tasks.new-message') {
      const idx = s.findIndex(
        (_notice) =>
          _notice.attributesMap.get('channel_id')?.value ===
          noticeExt.attributesMap.get('channel_id')?.value,
      );
      if (idx > -1) {
        s.splice(idx, 1);
      }
    }
    return [...s, noticeExt];
  });

sample({
  clock: removeAllNoticesFx.done,
  source: $currentCompanyId,
  target: fetchNoticesFx,
  fn: (companyId) => ({
    bookkeeper_team_id: companyId as CompanyId,
  }),
});

// Обновим время последний нотификации по оставшимся сообщениям из центрифуги
sample({
  clock: [newTaskNoticeReceived, newMailNoticeReceived],
  source: { channelsByGroup: $channelsByGroup },
  filter: ({ channelsByGroup }) => !!channelsByGroup,
  target: $channelsByGroup,
  fn: ({ channelsByGroup }, notice): ChannelByGroups => {
    let customerIds = notice.attributesMap.get('customer_team_id')?.value ?? [];
    if (!Array.isArray(customerIds)) {
      customerIds = [customerIds];
    }
    customerIds.forEach((customerId: CustomerChannelsItemId) => {
      const customerChannelItem = channelsByGroup!.customers.find(
        (channelItem) => channelItem.id === customerId,
      );
      if (customerChannelItem) {
        Object.assign(customerChannelItem.channels[0], {
          last_notice_at: new Date(notice.created_at).getTime() as UnixTimestamp,
        });
      }
    });
    return { ...channelsByGroup!, customers: [...channelsByGroup!.customers] };
  },
});
