import cn from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { TextButton2 } from '../../button';
import {
  AnyKey,
  RenderBranchBaseProps,
  TreeViewBranchProps,
  TreeViewRenderBranchProps,
  TreeViewRenderButtonProps,
  TreeViewRenderChildrenProps,
  TreeViewRenderTitleProps,
} from './types';
import cl from './Branch.module.scss';

export const Branch = <Node, ID extends AnyKey, RootID extends AnyKey, Extra>({
  parents,
  id,
  node,
  currentPath,
  common,
}: TreeViewBranchProps<Node, ID, RootID, Extra>) => {
  const level = parents.length;
  // const parent = level ? parents[level - 1] : common.rootId;
  const childrenNodes = common.dataStruct.get(id);
  const isExpanded = common.expanded.has(id);
  const isLoading = common.loading.has(id);
  const isCurrentDirect = level === currentPath.length - 1 && currentPath[level] === id;
  const isCurrentParent = level < currentPath.length - 1 && currentPath[level] === id;
  const isCurrent = isCurrentDirect || isCurrentParent;
  const canToggle = !common.isLeafFn?.(node);
  const hasChildren = !canToggle ? false : childrenNodes ? childrenNodes.length > 0 : null;

  const {
    idGetter,
    classes,
    RenderBranch = BranchDefaultRender,
    RenderButton = BranchDefaultRenderButton,
    RenderTitle = BranchDefaultRenderTitle,
    RenderTitleContent,
    RenderChildren = BranchDefaultRenderChildren,
    onNavigate,
    onToggle,
    onLoadChildren,
  } = common;
  const renderBaseProps: RenderBranchBaseProps<Extra> = {
    state: {
      level,
      hasChildren,
      isExpanded,
      isLoading,
      isCurrent,
      isCurrentDirect,
      isCurrentParent,
    },
    className: cn(
      classes?.branch,
      isLoading && classes?.branchLoading,
      isExpanded ? classes?.branchExpanded : classes?.branchCollapsed,
      hasChildren === null
        ? classes?.branchUnknownChildren
        : hasChildren
        ? classes?.branchHasChildren
        : classes?.branchWithoutChildren,
      isCurrent && classes?.branchIsCurrent,
      isCurrentDirect && classes?.branchIsCurrentDirect,
      isCurrentParent && classes?.branchIsCurrentParent,
    ),
    extra: common.extra,
  };

  const title = (
    <RenderTitle
      {...renderBaseProps}
      Default={BranchDefaultRenderTitle}
      className={cn(renderBaseProps.className, classes?.title)}
      parents={parents}
      node={node}
      onClick={useMemo(
        () => onNavigate && (() => onNavigate(id, parents)),
        [onNavigate, id, parents],
      )}
    >
      {RenderTitleContent ? (
        <RenderTitleContent {...renderBaseProps} parents={parents} node={node} />
      ) : (
        id
      )}
    </RenderTitle>
  );

  const triggerLoadChildren = hasChildren == null ? onLoadChildren : undefined;
  const handleButtonClick = useCallback(() => {
    const expand = !isExpanded;
    onToggle?.(id, expand);
    if (expand) {
      triggerLoadChildren?.(id, parents);
    }
  }, [onToggle, isExpanded, id, parents, triggerLoadChildren]);
  const button = canToggle ? (
    <RenderButton
      {...renderBaseProps}
      Default={BranchDefaultRenderButton}
      className={cn(renderBaseProps.className, classes?.button)}
      onClick={handleButtonClick}
    />
  ) : null;

  const childrenNode =
    hasChildren === true
      ? isExpanded
        ? childrenNodes!.map((node, _1, _2, nid = idGetter(node)) => (
            <Branch
              key={nid}
              parents={[...parents, id]}
              id={nid}
              node={node}
              currentPath={currentPath}
              common={common}
            />
          ))
        : null
      : hasChildren === false
      ? canToggle
        ? common.childrenAbsent
        : null
      : isLoading
      ? common.childrenLoading
      : common.childrenFailed;

  return (
    <RenderBranch
      {...renderBaseProps}
      Default={BranchDefaultRender}
      button={button}
      title={title}
      childrenE={
        childrenNode !== null && childrenNode !== undefined ? (
          <RenderChildren
            {...renderBaseProps}
            parents={parents}
            inNode={node}
            Default={BranchDefaultRenderChildren}
            className={classes?.children}
          >
            {childrenNode}
          </RenderChildren>
        ) : null
      }
    />
  );
};

const BranchDefaultRender: FC<TreeViewRenderBranchProps<any>> = ({
  button,
  title,
  childrenE,
  className,
  state: { isCurrentDirect, isCurrentParent },
}) => (
  <div
    className={cn(
      cl.root,
      childrenE === null ? cl._sureNoChildren : cl._maybeChildren,
      isCurrentDirect ? cl._currentDirect : isCurrentParent && cl._currentParent,
      className,
    )}
  >
    {/* В стилях по умолчанию (cl.root) дочерние элементы таргетируются */}
    {/* с помощью `:nth-child()`, поэтому можно менять рендеринг отдельных */}
    {/* элементов, не ломая логику родителя. */}
    <span
    // Контейнер для отрисовки линий дерева.
    // Контейнер растянут по сетке grid, т.к. высота title может быть произвольной:
    // - на всю высоту branch по умолчанию;
    // - только на ячейку кнопки для последней ветки в списке.
    // Внутри контейнера выводятся линии (`::before`) через `position: absolute`
    // и позиционирование с %-ами.
    />
    {button ?? (
      // потому что `:nth-child()`
      <span className={cl.btnStub} />
    )}
    {title}
    {childrenE}
  </div>
);

const BranchDefaultRenderButton: FC<TreeViewRenderButtonProps<any>> = ({
  onClick,
  className,
  state: { isExpanded },
}) => (
  <TextButton2
    size="small"
    icon={isExpanded ? 'Minus' : 'Plus'}
    onClick={onClick}
    className={cn(cl.button, className)}
  />
);

const BranchDefaultRenderTitle = ({
  onClick,
  children,
  className,
}: TreeViewRenderTitleProps<any, any, any>) => (
  <TextButton2 size="small" onClick={onClick} className={cn(cl.title, className)}>
    {children}
  </TextButton2>
);

const BranchDefaultRenderChildren = ({
  children,
  className,
}: TreeViewRenderChildrenProps<any, any, any>) => (
  <div className={cn(cl.children, className)}>{children}</div>
);
