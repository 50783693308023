import { FC, ReactElement } from 'react';
import { CellWrapProps, GridCellRender, GridCellStart } from './types';
import cl from './Cell.module.scss';

interface Props extends GridCellStart {
  R?: GridCellRender;
}

export const Cell: FC<Props> = ({ section, x, y, R }) => {
  const cellProps: CellWrapProps = {
    className: cl.root,
    style: {
      '--x': x,
      '--y': y,
    } as {},
  };
  const contentProps = { section, x, y };

  let child: ReactElement | undefined;
  if (R) {
    if (R.Cell) return <R.Cell {...contentProps} {...cellProps} />;
    child = <R.Content {...contentProps} />;
  }
  return <div {...cellProps}>{child}</div>;
};
