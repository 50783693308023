import {
  ChangeEvent,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  useCallback,
} from 'react';
import { parseInteger } from '../NumberInput/useIntegreInput';
import { useInputChangeHandler, useValueInputWrap } from '../valueInput';

type _I = HTMLInputElement;
const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLInputElement.prototype,
  'value',
)!.set;

interface Props {
  value: number | null;
  onChange: (value: number | null, e: ChangeEvent<_I>) => void;
  max?: number;
  triggerEveryChange?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onFocus?: FocusEventHandler<_I>;
  onBlur?: FocusEventHandler<_I>;
}

export const NumInput = forwardRef<_I, Props>(
  ({ value, onChange, max, triggerEveryChange, onFocus, onBlur, children, ...rest }, ref) => {
    const maxLength = max ? max.toFixed(0).length : undefined;

    const attrs = useValueInputWrap({
      value,
      formatValue: useCallback(
        (v: number | null) => {
          if (v === null) {
            return '';
          }
          const s = Number(v).toFixed(0);
          if (maxLength) {
            return s.padStart(maxLength, '0');
          }
          return s;
        },
        [maxLength],
      ),
      parseInput: useCallback(
        (input: string) => {
          const n = parseInteger(input);
          return n !== null && n >= 0 && (!max || n <= max) ? n : null;
        },
        [max],
      ),
      emptyValue: null,
      triggerEveryChange,
      onChange,
      onFocus,
      onBlur,
    });

    const handleKeyDown = useCallback<KeyboardEventHandler<_I>>(
      (e) => {
        if (isBadKey(e.key)) {
          e.preventDefault();
        } else if (isDigit(e.key) && maxLength) {
          const i = e.target as _I;
          // В поле уже введено максимум цифр:
          //   например "12" при максимуме в `23`
          //   например "48" при максимуме в `59`.
          // Нажимаем <7>.
          // Поскольку больше цифр не влезет, то нативно это нажатие ничего не
          // изменит.
          //
          // А мы берём, выпиливаем первую цифру и добавляем новую в конец.
          // Так, из примеров выше:
          //   "12" превращается в "27"
          //   "48" превращается в "87".
          // Т.о., постоянно набираемые цифры сменяют друг друга по кругу.
          // В нативном `<input type="time">` в последнем компоненте (в секундах,
          // когда они есть, или в минутах в противном случае) ввод работает также.
          if (
            i.value.length === maxLength &&
            // И когда в поле не выделен текст
            (i.selectionStart === null || i.selectionStart === i.selectionEnd)
          ) {
            e.preventDefault();
            const newValue = i.value.substring(1) + e.key;
            nativeInputValueSetter!.call(i, newValue);
            i.dispatchEvent(new Event('input', { bubbles: true }));
          }
        }
      },
      [maxLength],
    );

    return (
      <input
        ref={ref}
        {...attrs}
        {...rest}
        onChange={useInputChangeHandler(attrs.onChange)}
        type="text"
        inputMode="numeric"
        maxLength={maxLength ?? undefined}
        onKeyDown={handleKeyDown}
      />
    );
  },
);

/**
 * See the [DOM Level 3 Events spec](https://www.w3.org/TR/uievents-key/#named-key-attribute-values). for possible values
 */
const isBadKey = (key: string) => key.length === 1 && (key < '0' || key > '9');
const isDigit = (key: string) => key.length === 1 && key >= '0' && key <= '9';
