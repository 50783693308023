import { FC } from 'react';
import cl from './TagsInline.module.scss';
import { CommonTag } from 'models/tags/types';
import cn from 'classnames';
import Icon from 'ui/Icon';

const MAX_TAGS = 2;

interface Props {
  className?: string;
  tags: CommonTag[];
  maxCnt?: number;
  isLoading?: boolean;
}

export const TagsInline: FC<Props> = ({
  className,
  tags,
  maxCnt = MAX_TAGS,
  isLoading = false,
}) => {
  return (
    <div className={cn(cl.tags, isLoading && cl.isLoading, className)}>
      {isLoading && <Icon type="Loader" />}
      {tags.map((tag, idx) =>
        idx < maxCnt ? (
          <div key={tag.id} className={cl.tag} style={{ backgroundColor: tag.color }}>
            {tag.tag}
          </div>
        ) : null,
      )}
      {tags.length > maxCnt && <div className={cl.tagsQty}>+{tags.length - maxCnt}</div>}
    </div>
  );
};
