import cn from 'classnames';
import { FC, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Placement } from '@popperjs/core/lib/enums';
import { useDateFormat } from 'hooks/i18n';
import { getDatePart, parseDateStrict, toISODateString } from 'utils/date';
import { DateISO, DateTime, DateTimeISO } from 'utils/types';
import { ButtonCoreProps, TextButton2 } from '../../button';
import { DateFormat, Popover2 } from '../../display';
import cl from './MonthPicker.module.scss';

type ExternalValue = Date | DateISO | DateTimeISO | DateTime;
type InternalValue = string & { _type: 'yyyy-mm' };

type TriggerRenderProps = Pick<
  ButtonCoreProps,
  'size' | 'uiColor' | 'icon' | 'iconPosition' | 'className' | 'style' | 'onClick'
>;
const FLIP_DEFAULT: readonly Placement[] = ['auto'];

interface Props {
  value?: ExternalValue | null;
  // onChange?: (month: DateISO | null) => void;
  onPick?: (month: DateISO) => void;
  // onClear?: (_: undefined) => void;
  // canClear?: boolean; // хз, куда её впихнуть, да и не нужна она было пока
  disabled?: boolean;
  displayFormat?: string;
  displayEmpty?: ReactNode;

  buttonSize?: TriggerRenderProps['size'];
  buttonUiColor?: TriggerRenderProps['uiColor'];
  buttonIcon?: TriggerRenderProps['icon'] | null;
  buttonIconPosition?: TriggerRenderProps['iconPosition'] | null;
  // buttonIconRotate?: boolean;
  buttonTip?: string;
  buttonClassName?: TriggerRenderProps['className'];
  buttonStyle?: TriggerRenderProps['style'];
}

export const MonthPicker: FC<Props> = ({
  value,
  // onChange,
  onPick,
  // onClear,
  // canClear = false,
  disabled = false,
  displayFormat = 'LLL yyyy',
  displayEmpty = 'М гггг',

  buttonSize,
  buttonUiColor = 'primary',
  buttonIcon,
  buttonIconPosition,
  // buttonIconRotate,
  buttonTip,
  buttonClassName,
  buttonStyle,
}) => {
  const parsedValue = parseExternalValue(value);
  const [internalValue, setInternalValue] = useState<InternalValue | null>(null);
  useEffect(() => {
    if (parsedValue !== undefined) {
      setInternalValue(parsedValue);
    }
  }, [parsedValue]);

  const [tRef, setTRef] = useState<HTMLElement | null>(null);
  const [isOpenPopover, setOpenPopover] = useState(false);
  useEffect(() => {
    if (disabled) {
      setOpenPopover(false);
    }
  }, [disabled]);

  const handleOpen = useCallback<MouseEventHandler<HTMLElement>>((e) => {
    setTRef(e.currentTarget);
    setOpenPopover(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpenPopover(false);
    setTRef(null);
  }, []);

  const handlePick = useCallback(
    (v: InternalValue) => {
      setInternalValue(v);
      setOpenPopover(false);
      setTRef(null);

      onPick?.(exportInternalValue(v));
    },
    [onPick],
  );

  return (
    <>
      <TextButton2
        type="button"
        size={buttonSize}
        uiColor={buttonUiColor}
        icon={buttonIcon ?? undefined}
        iconPosition={buttonIconPosition ?? undefined}
        className={cn(cl.button, buttonClassName)}
        style={buttonStyle}
        disabled={disabled}
        onClick={isOpenPopover ? handleClose : handleOpen}
        // возможно, надо показывать только при !open
        data-tip={buttonTip}
      >
        {internalValue ? (
          <DateFormat
            date={parseDateStrict(exportInternalValue(internalValue))}
            format={displayFormat}
          />
        ) : (
          displayEmpty
        )}
      </TextButton2>
      <Popover2
        isShow={isOpenPopover}
        onClickOutside={handleClose}
        anchorEl={tRef}
        placement="bottom-start"
        flip={FLIP_DEFAULT}
        // withPortal
        // скопировал из ButtonDropdown
        withBackdrop={false}
        className={cl.popup}
      >
        {isOpenPopover && <PopupContent value={internalValue} onPick={handlePick} />}
      </Popover2>
    </>
  );
};

const PopupContent: FC<{ value: InternalValue | null; onPick: (v: InternalValue) => void }> = ({
  value,
  onPick,
}) => {
  const [year, month] = splitInternalValue(value);
  const [y, setY] = useState(year ?? new Date().getFullYear());
  const formatDate = useDateFormat();

  return (
    <div className={cl.popupContent}>
      <div className={cl.yearRow}>
        <TextButton2
          size="small"
          icon="ChevronDoubleLeft"
          onClick={useCallback(() => setY(yBF), [])}
          data-tip="-10"
        />
        <TextButton2 size="small" icon="ChevronLeft" onClick={useCallback(() => setY(yBS), [])} />
        <span className={cl.year}>{y}</span>
        <TextButton2 size="small" icon="ChevronRight" onClick={useCallback(() => setY(yFS), [])} />
        <TextButton2
          size="small"
          icon="ChevronDoubleRight"
          onClick={useCallback(() => setY(yFF), [])}
          data-tip="+10"
        />
      </div>
      <div className={cl.months}>
        {useMemo(
          () =>
            Array.from({ length: 12 }, (_, i) => ({
              date: new Date(y, i, 1, 0, 0, 0, 0),
              onClick: () => onPick(buildInternalValue(y, i + 1)),
            })),
          [y, onPick],
        ).map(({ date, onClick }, i) => (
          <TextButton2
            size="small"
            onClick={onClick}
            uiColor={year === y && month === i + 1 ? 'primary' : undefined}
          >
            {formatDate(date, 'LLL')}
          </TextButton2>
        ))}
      </div>
    </div>
  );
};

const yBF = (n: number) => n - 10;
const yFF = (n: number) => n + 10;
const yBS = (n: number) => n - 1;
const yFS = (n: number) => n + 1;

const parseExternalValue = (value: ExternalValue | null | undefined) => {
  switch (value) {
    case undefined:
    case null:
      return value;
  }
  const date = typeof value === 'string' ? getDatePart(value) : toISODateString(value);
  return date.substring(0, 7) as InternalValue;
};

const stubSplit = [null, null] as const;
const splitInternalValue = (v: InternalValue | null) => {
  if (!v) {
    return stubSplit;
  }
  const [y, m] = v.split('-').map(Number);
  return [y, m] as const;
};
const buildInternalValue = (y: number, m: number) =>
  (y.toFixed(0).padStart(4, '0') + '-' + m.toFixed(0).padStart(2, '0')) as InternalValue;

const exportInternalValue = <V extends InternalValue | null | undefined>(v: V) =>
  v ? ((v + '-01') as DateISO) : (v as Exclude<V, InternalValue>);
