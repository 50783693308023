import { dataOnly, instanceNoAutoRefresh } from 'api/instance';
import { Auth, AuthLoginForm, SignUpForm } from 'models/auth/types';
import { ApiResponse } from 'api/types';
import {
  PwdResetCheckForm,
  PwdResetConfirmForm,
  PwdResetRequestForm,
} from 'models/auth/pwd-reset/types';
import axios, { AxiosInstance } from 'axios';
import { API_BASE_URL } from 'constants/config';

const api = {
  signin: (form: AuthLoginForm) =>
    instanceNoAutoRefresh.post<null, ApiResponse<Auth>>('/api/v1/users/signin', form),

  signup: (params: SignUpForm) =>
    dataOnly.post<null, ApiResponse<Auth>>(`/api/v1/users/signup`, params),

  refresh: (axios: Pick<AxiosInstance, 'put'>, refreshtoken: string) =>
    axios.put<any, ApiResponse<Auth>>(`/api/v2/users/refreshtoken/${refreshtoken}`),

  tokenAuth: (token: string) =>
    axios.post(`${API_BASE_URL}/api/v1/admin-signin`, undefined, {
      headers: { Authorization: `Bearer ${token}` },
    }),
};

export default api;

const URL_PWD_RESET = '/api3/auth/forgot-password';
const URL_PWD_RESET_CHECK = '/api3/auth/check-code';
export const apiPwdResetRequest = (form: PwdResetRequestForm) =>
  instanceNoAutoRefresh.post<null, void>(URL_PWD_RESET, form);
export const apiPwdResetCheck = (form: PwdResetCheckForm) =>
  instanceNoAutoRefresh.post<null, void>(URL_PWD_RESET_CHECK, form);
export const apiPwdResetConfirm = (form: PwdResetConfirmForm) =>
  instanceNoAutoRefresh.put<null, void>(URL_PWD_RESET, form);
