import { isImage } from 'utils/image/isImage';
import { isAudioFilename } from 'utils/string';
import { FileStorage } from '../types';
import { Fileshare, FileshareStat, FilesSetProperties } from './types';

export const fileshareToFileStorage = ({
  id,
  file_type,
  size,
  original_name,
  local_id,
}: Fileshare): FileStorage => ({
  fileId: id,
  fileType: file_type,
  fileSize: size,
  originalFileName: original_name,
  localId: local_id,
});
export const fileshareFromFileStorage = ({
  fileId,
  fileType,
  fileSize,
  originalFileName,
}: FileStorage): Fileshare => ({
  id: fileId,
  customers: null,
  file_type: fileType,
  size: fileSize,
  original_name: originalFileName,
});

export const filesharePropertiesMatch = (fs: Fileshare, p: FilesSetProperties) => {
  const { service, customers } = p;
  if (fs.service !== service) return false;

  const myCust = Array.from(new Set(fs.customers?.map((c) => c.customer_team_id)));
  const theirsCust = new Set(customers);
  if (myCust.length !== theirsCust.size) return false;
  if (!myCust.every((id) => theirsCust.has(id))) return false;

  // что-то ещё, может, тут будет, так что нефиг мне тут ?: советовать

  return true;
};

export const isFileshareImage = (
  fs: FileshareStat | Pick<FileshareStat, 'original_name' | 'file_type'>,
) => isImage(fs.original_name) || fs.file_type.startsWith('image/');

export const isFileshareAudio = (fs: FileshareStat) =>
  isAudioFilename(fs.original_name) || fs.file_type.startsWith('audio/');
