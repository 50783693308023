import dateFns_en from 'date-fns/locale/en-US';
// import { ReactComponent } from 'svg-country-flags/svg/gb.svg';
import { ILanguage } from '../language';

const enLang: ILanguage = {
  language: 'en',
  locale: 'en-US',
  title: 'English',
  dateFns: dateFns_en,
  // flag: ReactComponent,
  parseNumber: {
    group: /,/,
  },
};

export default enLang;
