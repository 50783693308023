import { createEffect } from 'effector';
import { TaskRepeatableEditParams, tasksRepeatableApi } from 'api/request/tasks-repeatable';
import { TaskRepeatableDeleteForm } from '../task/types';

/** Update */
export const tasksRepeatableUpdateFx = createEffect(
  async (params: TaskRepeatableEditParams) => await tasksRepeatableApi.update(params),
);

/** Delete */
export const tasksRepeatableDeleteFx = createEffect(
  async (params: TaskRepeatableDeleteForm) => await tasksRepeatableApi.delete(params),
);
