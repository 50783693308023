import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import cl from './TaskStatusSwitch.module.scss';
import { TaskState } from 'models/task/types';
import * as RoSet from '@cubux/readonly-set';

interface Props {
  value: ReadonlySet<TaskState>;
  onChange: (states: ReadonlySet<TaskState>) => void;
}

const TASK_STATUSES: Array<{ id: TaskState; tip: string; className: string }> = [
  // t('ui:task.status.open')
  { id: 'open', tip: 'ui:task.status.open', className: cl.open },
  // t('ui:task.status.expired')
  { id: 'overdue', tip: 'ui:task.status.expired', className: cl.expired },
  // t('ui:task.status.closed')
  { id: 'closed', tip: 'ui:task.status.closed', className: cl.closed },
];

export const DEFAULT_FILTER_TASK_STATES: ReadonlySet<TaskState> = new Set([
  'open',
  'closed',
  'overdue',
]);

export const TaskStatusSwitch: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <div className={cl.root}>
      {TASK_STATUSES.map((c) => (
        <div
          key={c.id}
          className={cn(cl.status, value.has(c.id) && cl.active, c.className)}
          onClick={() => onChange(RoSet.toggle(value, c.id))}
          data-tip={t(c.tip)}
        />
      ))}
    </div>
  );
};
