import { createValueInputHook } from '../valueInput';

export const formatInteger = (v: number | null) => (v === null ? '' : Number(v).toFixed(0));

const spaces = /\s+/g;
const re = /^[-+]?\d+$/;
export const parseInteger = (s: string) => {
  const m = s.replace(spaces, '').match(re);
  if (m) {
    const n = parseInt(m[0]);
    if (Number.isInteger(n)) {
      return n;
    }
  }
  return null;
};

export const useIntegerInput = createValueInputHook<number | null>({
  emptyValue: null,
  formatValue: formatInteger,
  parseInput: parseInteger,
});
