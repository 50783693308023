import { CentMessageDataV2 } from './internal';
import { Notice, NoticeItsRead } from '../notices/types';

export const enum CentPrivateEventTypeNotice {
  New = 'notices.new',
  ItsRead = 'notices.itsRead',
}

export type CentPrivateEventNotice =
  | CentMessageDataV2<CentPrivateEventTypeNotice.New, Notice>
  | CentMessageDataV2<CentPrivateEventTypeNotice.ItsRead, NoticeItsRead>;
