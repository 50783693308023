import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { IView } from '../../types';
import cl from './FormControlLabel.module.scss';

interface Props extends IView {
  label: string | ReactNode;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  disabled?: boolean;
  startWith?: ReactNode;
  affixClassName?: string;
  helper?: ReactNode;
  classNameHelp?: string;
}

// Label for `Radio`, `Switch` and `Checkbox` component.
export const FormControlLabel: FC<Props> = ({
  label,
  labelPlacement = 'end',
  children,
  className,
  disabled,
  startWith,
  affixClassName,
  helper,
  classNameHelp,
  ...rest
}) => (
  <>
    <label
      {...rest}
      className={cn(cl.root, cl[labelPlacement], className, disabled && cl.disabled)}
    >
      {startWith && <span className={cn(cl.affix, affixClassName)}>{startWith}</span>}
      {children}
      <span className={cl.label}>{label}</span>
    </label>
    {Boolean(helper) && <div className={cn(cl.helper, classNameHelp)}>{helper}</div>}
  </>
);
