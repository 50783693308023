import cn from 'classnames';
import { ReactElement, useMemo } from 'react';
import { EMPTY_ARRAY, EMPTY_SET } from 'constants/utils';
import { RequirePropsStrict } from 'utils/types';
import { Branch } from './Branch';
import { buildMapParents, getParents } from './internal';
import { AnyKey, ChildrenLoading, TreeViewProps, TreeViewStrictProps } from './types';
import cl from './TreeView.module.scss';

export const TreeViewStrict = <Node, ID extends AnyKey, RootID extends AnyKey, Extra>({
  current,
  className,
  ...common
}: TreeViewStrictProps<Node, ID, RootID, Extra>) => {
  const { dataStruct, idGetter, rootId } = common;

  const currentPath = useMemo(() => {
    const mapParents = buildMapParents(dataStruct, idGetter);
    const parents = getParents(mapParents, rootId, current);
    if (current !== undefined) {
      parents.push(current);
    }
    return parents;
  }, [dataStruct, idGetter, rootId, current]);

  const childrenNodes = dataStruct.get(rootId);
  const loading: ChildrenLoading<ID | RootID> = common.loading;

  return (
    <div className={cn(cl.root, className)}>
      {/* TODO: loading, error */}
      {childrenNodes
        ? childrenNodes.length > 0
          ? childrenNodes.map((node, _1, _2, id = idGetter(node)) => (
              <Branch
                key={id}
                parents={EMPTY_ARRAY}
                id={id}
                node={node}
                currentPath={currentPath}
                common={common}
              />
            ))
          : common.childrenAbsent
        : loading.has(common.rootId)
        ? common.childrenLoading
        : common.childrenFailed}
    </div>
  );
};

interface TreeViewFC {
  <Node, ID extends AnyKey, RootID extends AnyKey, Extra>(
    props: RequirePropsStrict<TreeViewProps<Node, ID, RootID, Extra>, 'rootId'>,
  ): ReactElement;

  <Node, ID extends AnyKey, Extra>(props: TreeViewProps<Node, ID, '', Extra>): ReactElement;

  <Node, ID extends AnyKey, RootID extends AnyKey>(
    props: RequirePropsStrict<TreeViewProps<Node, ID, RootID, undefined>, 'rootId'>,
  ): ReactElement;

  <Node, ID extends AnyKey>(props: TreeViewProps<Node, ID, '', undefined>): ReactElement;
}
export const TreeView: TreeViewFC = <Node, ID extends AnyKey, RootID extends AnyKey, Extra>({
  rootId = '' as RootID,
  loading = EMPTY_SET,
  expanded = EMPTY_SET,
  extra = undefined as Extra,
  ...rest
}: TreeViewProps<Node, ID, RootID, Extra>) => (
  <TreeViewStrict {...rest} rootId={rootId} loading={loading} expanded={expanded} extra={extra} />
);
