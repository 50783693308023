import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import copyToClipboard from 'copy-to-clipboard';
import { TextButton2 } from 'ui/button';
import { showToast, ToastTypes } from 'ui/feedback';
import { Input } from 'ui/input/Input';
import Icon from 'ui/Icon';

// import cl from './TelegramLinkInput.module.scss';

interface TelegramLinkInputProps {
  value?: string;
  className?: string;
}

export const TelegramLinkInput: FC<TelegramLinkInputProps> = ({ value, className }) => {
  const { t } = useTranslation('ui');
  const handleCopy = useCallback(() => {
    if (value) {
      copyToClipboard(value);
      showToast(ToastTypes.success, t('ui:messages.copyLinkSuccess'));
    }
  }, [t, value]);

  return (
    <Input
      startWith={<Icon type="Telegram" />}
      className={className}
      value={value || ''}
      disabled={!value}
      endWith={
        <>
          <TextButton2
            size="small"
            uiColor="secondary"
            icon="Copy"
            data-tip={t('ui:tips.copyLink')}
            onClick={handleCopy}
          />
          <TextButton2
            size="small"
            icon="ExternalLink"
            href={value}
            target="_blank"
            rel="noreferrer"
            data-tip={t('ui:tips.goLink')}
          />
        </>
      }
    />
  );
};
