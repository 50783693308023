import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { DenyOverlappedProps } from 'utils/types';
import { adaptiveClasses, AdaptiveProps } from '../../adaptive';
import { AlignItem, ColSpan, JustifyItems } from '../types';
import classesFix from '../Grid.module.scss';
import classes from './Col.module.scss';

type BaseProps = HTMLAttributes<HTMLDivElement>;

export interface ColCaseProps {
  span?: ColSpan;
  align?: AlignItem;
  justify?: JustifyItems;
}

export interface ColOwnProps extends AdaptiveProps<ColCaseProps> {}

export type ColProps = DenyOverlappedProps<ColOwnProps, BaseProps>;

// console.warn('>>>> ', classesFix, classes);

const cx = adaptiveClasses((tag: string, { span, align, justify }: ColCaseProps) =>
  cn(
    span && classesFix[`span${tag}_${span}`],
    align && classes[`align${tag}_${align}`],
    justify && classes[`justify${tag}_${justify}`],
  ),
);

export const Col: FC<ColProps> = ({ span, align, justify, adaptive, className, ...rest }) => (
  <div
    {...rest}
    className={cn(classesFix.col, cx({ span, align, justify, adaptive }), className)}
  />
);
