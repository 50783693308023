import Centrifuge, { PublicationContext, Subscription } from 'centrifuge';
import { createEvent } from 'effector';
import { CENT_URL } from 'constants/config';
import {
  CentMessage,
  CentPrivateEventKnown,
  CentPrivateMessage,
  CentTeamMessageDataV2,
  isCentMessageDataV1,
} from '../models/cent/types';
import { isCentMessageDataV2 } from '../models/cent/internal';

// #59 temp s/^http/ws/
export const cent = new Centrifuge(`${CENT_URL.replace(/^http/, 'ws')}/connection/websocket`);

cent.on('connect', function (e) {
  console.log('[cent] connected', e);
});

cent.on('disconnect', function (e) {
  console.log('[cent] disconnected', e);
});

cent.on('error', function (e) {
  console.log('[cent] error', e);
});

export const received = createEvent<CentMessage>();
export const receivedV2 = createEvent<CentTeamMessageDataV2>();
export const receivedPrivate = createEvent<CentPrivateEventKnown>();

type Unsubscriber = () => void;

const makeBatchUnsub = (subs: Subscription[]): Unsubscriber => {
  return () => {
    for (const sub of subs) {
      sub.unsubscribe();
    }
  };
};

export const centSubscribe = (userId: string): Unsubscriber => {
  const subs = [
    cent.subscribe(userId, (msg: PublicationContext) => {
      console.log('[cent][user] msg: ', msg.data?.events?.[0]?.type + ':', msg);
      if (isCentMessageDataV1(msg.data)) {
        received(msg);
      } else if (isCentMessageDataV2(msg.data)) {
        receivedV2(msg.data as CentTeamMessageDataV2);
      }
    }),

    cent.subscribe(`private#${userId}`, (msg: CentPrivateMessage) => {
      console.log('[cent][user] private:', msg);
      const event = msg.data;
      if (isCentMessageDataV2(event)) {
        receivedPrivate(event);
      }
    }),
  ];

  return makeBatchUnsub(subs);
};

export const centSubscribeCompany = (teamId: string): Unsubscriber => {
  const subs = [
    cent.subscribe(teamId, (msg: PublicationContext) => {
      console.log('[cent][team] msg: ', msg.data?.events?.[0]?.type + ':', msg);
      if (isCentMessageDataV1(msg.data)) {
        received(msg);
      } else if (isCentMessageDataV2(msg.data)) {
        receivedV2(msg.data as CentTeamMessageDataV2);
      }
    }),
  ];

  return makeBatchUnsub(subs);
};
