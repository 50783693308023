import { createEvent, Event, merge, sample, Store } from 'effector';
import { createGate, Gate } from 'effector-react';
import { UserPreferenceForm } from './types';

export interface SourceOpt {
  $values: Store<ReadonlyMap<string, string>>;
  reloadAll: Event<any>;
  reloadOne: Event<string>;
  updateOne: Event<UserPreferenceForm>;
}
interface CoreOpt {
  prefName: string;
}

interface Default<T> {
  default: T;
}
interface Serialization<T> {
  serialize: (v: T) => string;
  unserialize: (s: string) => T;
}

interface PreferenceApi<T> {
  Gate: Gate;
  reload: Event<any>;
  setValue: Event<T>;
  $value: Store<T>;
}

interface CreatePreferenceFn {
  <T>(o: CoreOpt & Default<T> & Serialization<T>): PreferenceApi<T>;
  (o: CoreOpt & Partial<Default<string>> & Partial<Serialization<string>>): PreferenceApi<string>;
  <T extends string>(
    o: CoreOpt & Partial<Default<T>> & Partial<Serialization<T>>,
  ): PreferenceApi<T>;
}

export const createPreferenceCore =
  ({ $values, reloadOne, updateOne }: SourceOpt): CreatePreferenceFn =>
  <T>({
    prefName,
    default: defaultValue = '' as T,
    serialize = String,
    unserialize,
  }: CoreOpt & Partial<Default<T>> & Partial<Serialization<T>>) => {
    const Gate = createGate<{}>();
    const reload = createEvent<any>();
    const setValue = createEvent<T>();
    const $value = $values.map((map) => {
      const s = map.get(prefName);
      if (s === undefined) {
        return defaultValue;
      }
      if (unserialize) {
        return unserialize(s);
      }
      return s as T;
    });

    sample({
      source: merge([Gate.open, reload]),
      fn: () => prefName,
      target: reloadOne,
    });
    sample({
      source: setValue,
      fn: (v): UserPreferenceForm => ({ name: prefName, value: serialize(v) }),
      target: updateOne,
    });

    return {
      Gate,
      reload,
      setValue,
      $value,
    } as const;
  };

interface TypedOptions<T> extends CoreOpt, Partial<Default<T>> {
  factory: CreatePreferenceFn;
}

export const createBooleanPreference = ({ factory, ...rest }: TypedOptions<boolean>) => {
  const result = factory({
    ...rest,
    default: rest.default ?? false,
    serialize: (v) => (v ? '1' : ''),
    unserialize: Boolean,
  });
  const { $value, setValue } = result;
  const toggle = createEvent<unknown>();
  sample({
    clock: toggle,
    source: $value,
    fn: (v) => !v,
    target: setValue,
  });
  return {
    ...result,
    /**
     * Переключение в противоположное значение
     *
     * Аргумент игнорируется, чтобы можно было сунуть прямо в `onClick`, и туда
     * спокойно попадёт `Event`, ничего не ломая.
     */
    toggle,
  };
};
