import { FC, PropsWithChildren, ReactNode } from 'react';
import * as r from 'react-router-dom';
import { useParseString } from './hooks';

interface Props {
  external?: boolean | null;
}

const useParseChildren = (children?: ReactNode) => {
  const str = useParseString(children);
  if (str) {
    let href = '';
    let text = '';
    const m = str.trim().match(/^(\S+)(?:\s+(.*))?/);
    if (m) {
      href = m[1];
      text = m[2] || href;
    }
    return { href, text };
  }
};

const reAbs = /^(\/\/|mailto:|tel:|\w+:\/\/)/;

export const Link: FC<PropsWithChildren<Props>> = ({ external = null, children }) => {
  const { href, text = children } = useParseChildren(children) || {};
  if (!href) {
    return <a href={'#-bad-'}>{text}</a>;
  }
  if (external ?? reAbs.test(href)) {
    return (
      <a href={href} target="_blank" rel="noreferrer noopener">
        {text}
      </a>
    );
  }
  return <r.Link to={href}>{text}</r.Link>;
};
