import { createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
import { fetchTaxbookIfNeed } from 'models/customer';
import { $customersLightActive, $customersLightActiveId } from 'models/customer/list-light';
import { $taxbookForm } from 'routes/main/routes/client/components/TaxbookPanel';
import { createRequestEffect } from 'models/utils/createRquestEffect';
import { apiTaskBulkTeams, TeamsListParams } from 'api/request/tasks-bulk-by-teams';
import { CustomerId } from 'models/customer/types';
import { EMPTY_ARRAY } from 'constants/utils';
import { $selectedSet, customersUpdated, selectChangedTrigger } from '../../CustomerList';
import { reset } from 'patronum';
import { $currentCompanyId } from 'models/company';
import { CompanyId } from 'models/company/types';

interface GateState {
  customerIds?: readonly CustomerId[] | null;
}

export const Gate = createGate<GateState>();

export const modalSubmitted = createEvent();
export const customersSelectedId = createEvent<CustomerId[]>();

const teamsListFx = createRequestEffect(apiTaskBulkTeams.teamsList);

sample({ clock: Gate.open, target: fetchTaxbookIfNeed });

sample({
  clock: Gate.open,
  filter: (state: GateState): state is { customerIds: CustomerId[] } => !!state.customerIds,
  fn: ({ customerIds }) => customerIds,
  target: customersUpdated,
});

sample({
  clock: $taxbookForm,
  source: { status: Gate.status, formSet: $taxbookForm, companyId: $currentCompanyId },
  filter: ({ status, formSet }) => status && !!formSet.size,
  target: teamsListFx,
  fn: ({ companyId }, form): TeamsListParams => ({
    bookkeeper_team_id: companyId as CompanyId,
    filter: form,
  }),
});
sample({
  clock: teamsListFx.doneData,
  source: $customersLightActiveId,
  target: customersUpdated,
  fn: (activeId, { data }) => data?.filter((id) => activeId.has(id)) ?? EMPTY_ARRAY,
});

sample({
  clock: modalSubmitted,
  source: { ids: $selectedSet, customers: $customersLightActive },
  target: customersSelectedId,
  fn: ({ ids, customers }) => customers.filter((c) => ids.has(c.id)).map((c) => c.id),
});

reset({ clock: selectChangedTrigger, target: $taxbookForm });

//unmount
reset({ clock: Gate.close, target: [$taxbookForm, $selectedSet] });
