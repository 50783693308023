/**
 *
 * @see styles/constants/_breakpoints.scss
 */
export const LAYOUT_BREAKPOINT = {
  XS: 0,
  SM: 768,
  MD: 1024,
  LG: 1200,
  XL: 1600,
} as const;

export const layoutBreakpoints = Object.values(LAYOUT_BREAKPOINT);
