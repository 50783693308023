import cn from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';
import { Panel } from 'ui/panels';
import cl from './IntegrationItem.module.scss';

interface Props {
  descr: string;
  cost?: ReactNode;
  logo?: string;
  logoClassName?: string;
  logoSize?: CSSProperties['backgroundSize'];
  onClick?: () => void;
  hidden?: boolean;
}

const stHide: CSSProperties = { display: 'none' };

export const IntegrationItem: FC<Props> = ({
  descr,
  cost,
  onClick,
  logo,
  logoClassName,
  logoSize,
  hidden,
}) => {
  return (
    <Panel className={cl.root} onClick={onClick} style={hidden ? stHide : undefined}>
      <div
        className={cn(cl.logo, logoClassName)}
        style={{ backgroundImage: logo ? `url(${logo})` : undefined, backgroundSize: logoSize }}
      ></div>
      <div className={cl.descr}>
        <div>{descr}</div>
        {cost !== undefined && <div className={cl.cost}>{cost}</div>}
      </div>
    </Panel>
  );
};
