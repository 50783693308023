import i18next, { TFunction } from 'i18next';
import { toISODateString } from 'utils/date';
import { getUniqKeyRnd } from 'utils/helpers';
import round from 'utils/number/round';
import { filterObject, hasProperty, isEmptyObject } from 'utils/object';
import { Validator } from 'utils/validation';
import { NestedValidationResult, normalizeNestedErrors } from 'utils/validation/nested';
import { TariffAdditionalServiceUnit } from '../tariffs/types';
import {
  TaskFinance,
  TaskFinanceData,
  TaskFinanceDataForm,
  TaskFinanceService,
  TaskFinanceServiceDataForm,
  TaskFinanceServiceForm,
} from './types';

// TODO: currency
const _DECIMALS = 2;

export const calcServiceCost = (
  price: number,
  unit: TariffAdditionalServiceUnit,
  quantity: number,
): number => {
  switch (unit) {
    case TariffAdditionalServiceUnit.fixed:
      return round(price * quantity, _DECIMALS);
    case TariffAdditionalServiceUnit.hour:
      return round(price * (quantity / 60), _DECIMALS);
  }
};

export const isTaskFinanceServiceForm = (o: object): o is TaskFinanceServiceForm =>
  hasProperty(o, '_key');

export const newServiceForm = ({
  id,
  title = '',
  unit_code = TariffAdditionalServiceUnit.fixed,
  price = 0,
  quantity = unit_code === TariffAdditionalServiceUnit.fixed ? 1 : 0,
  cost = calcServiceCost(price ?? 0, unit_code, quantity),
  ...rest
}: TaskFinanceServiceDataForm = {}): TaskFinanceServiceForm => ({
  _key: id ?? getUniqKeyRnd(),
  ...filterObject({
    ...rest,
    title,
    unit_code,
    price,
    quantity,
    cost,
  }),
});

export const updateServiceForm = (
  prev: TaskFinanceServiceForm,
  { id: _, ...next }: TaskFinanceServiceDataForm,
): TaskFinanceServiceForm => ({
  ...filterObject({
    ...prev,
    ...next,
  }),
  _key: prev._key,
});

export const initServicesForm = (s: TaskFinanceService): TaskFinanceServiceForm => ({
  _key: s.id,
  id: s.id,
});
export const initServicesForms = (services: readonly TaskFinanceService[]) =>
  services.map(initServicesForm);

// -------------

export type TaskFinanceServiceFormErrors = NestedValidationResult<
  Omit<TaskFinanceServiceDataForm, 'id'>
>;

const validateServiceUpdateForm = (
  { title, unit_code, price, quantity, cost }: TaskFinanceServiceForm,
  t: TFunction,
): TaskFinanceServiceFormErrors => {
  const { requiredValidator } = new Validator(t);
  const errors: TaskFinanceServiceFormErrors = {
    title: { $: title !== undefined && requiredValidator(title) },
    unit_code: { $: unit_code !== undefined && requiredValidator(unit_code) },
    // price: { $: price !== undefined && requiredValidator(price) },
    // quantity: { $: quantity !== undefined && requiredValidator(quantity) },
    // cost: { $: cost !== undefined && requiredValidator(cost) },
  };
  return normalizeNestedErrors(errors);
};

// если это не заполнено, то будем считать строку пустой и отправлять не будем
export const isEmptyServiceCreateForm = (form: TaskFinanceServiceForm) =>
  !form.title &&
  !form.price &&
  (form.unit_code === TariffAdditionalServiceUnit.fixed || !form.quantity) &&
  !form.cost;

const validateServiceCreateForm = (form: TaskFinanceServiceForm, t: TFunction) => {
  if (isEmptyServiceCreateForm(form)) {
    return null;
  }
  return validateServiceUpdateForm(newServiceForm(form), t);
};

export const validateServiceForm = (form: TaskFinanceServiceForm, t: TFunction = i18next.t) =>
  form.id ? validateServiceUpdateForm(form, t) : validateServiceCreateForm(form, t);

// -------------

const newFinanceDataFormCreate = (): TaskFinanceDataForm => ({
  add_to_date: true,
  date: toISODateString(new Date()),
  services: [newServiceForm()],
});

export const newFinanceDataForm = (src?: TaskFinanceData | null): TaskFinanceDataForm =>
  src ? {} : newFinanceDataFormCreate();

export const isFinanceDataFormEditChanged = (form: TaskFinanceDataForm) =>
  !isEmptyObject(filterObject(form));

export type TaskFinanceDataFormErrors = NestedValidationResult<TaskFinanceDataForm>;

export const validateFinanceDataForm = (
  { services }: TaskFinanceDataForm,
  t: TFunction = i18next.t,
): TaskFinanceDataFormErrors => {
  // const { requiredValidator } = new Validator(t);
  const errors: TaskFinanceDataFormErrors = {
    // date: ,
    // plan_minutes: ,
    services: services?.map((form) => validateServiceForm(form, t)),
  };
  return normalizeNestedErrors(errors);
};

// -------------

export const applyServiceForm = (
  src: TaskFinanceService | null | undefined,
  form: TaskFinanceServiceForm,
): TaskFinanceServiceForm => {
  if (!src) {
    return form;
  }
  const { id, _key, ...rest } = form;
  return {
    ...src,
    ...filterObject(rest),
    _key,
  };
};

export const applyFinanceForm = (
  src: TaskFinance | null,
  form: TaskFinanceDataForm,
): TaskFinanceDataForm => {
  if (!src) {
    return form;
  }
  const { team_id, task_id, is_typical, ...restSrc } = src;
  let { services, ...rest } = form;
  if (services) {
    const srcMap = new Map(src.services?.map((s) => [s.id, s]));
    services = services.map((form) => applyServiceForm(form.id && srcMap.get(form.id), form));
  }
  return {
    ...restSrc,
    ...filterObject(rest),
    services: services ?? src.services?.map((src) => ({ _key: src.id, ...src })),
  };
};

export const toSubmitFinanceForm = (form: TaskFinanceDataForm) => ({
  ...form,
  services: form.services?.filter((s) => !isEmptyServiceCreateForm(s)),
});
