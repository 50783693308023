import { ExplicitProps, IfEquals } from '../types';

// every single item in K union exists separately, since `key` can be equal to only one case of K
type KnownShape<K extends keyof any> = K extends any ? Record<K, unknown> : never;

type UnknownShape<K extends keyof any> = KnownShape<K> & MayBeOtherProps;

// `RemoveIndex<T>` may be useful:
// https://stackoverflow.com/questions/51465182/how-to-remove-index-signature-using-mapped-types/51956054#51956054
type MayBeOtherProps = {
  [P in keyof any]?: unknown;
};

export function hasProperty(objectN: null | undefined, key: any): false;

/**
 * Does object have the property
 *
 * Required props do always exist
 */
export function hasProperty<T extends Record<K, any>, K extends keyof T>(
  objectR: T | null | undefined,
  key: K,
): objectR is T;

// /**
//  * Does object have the property
//  *
//  * TODO: ...
//  */
// export function hasProperty<P extends keyof any,T extends UnknownShape<P>, K extends keyof any>(
//   objectUM: T | null | undefined,
//   key: K,
// ): objectUM is T&UnknownShape<P|K>;

/**
 * Does object have the property
 *
 * Optional props does type narrow, so all of properties given by type `K`
 * become explicit.
 */
export function hasProperty<T extends object, K extends T extends any ? keyof T : never>(
  objectO1: T | null | undefined,
  key: K,
): objectO1 is string extends keyof T
  ? T & UnknownShape<K>
  : number extends keyof T
    ? T & UnknownShape<K>
    : T & ExplicitProps<T, K>;

/**
 * Does object have the property
 *
 * Foreign properties do never exist
 */
export function hasProperty<T extends object, K extends keyof any>(
  objectO2: T | null | undefined,
  key: K,
): T extends any ? (K extends keyof T ? boolean : IfEquals<T, object, boolean, false>) : never;

/**
 * Does object have the property
 *
 * An `unknown` will cast to be an object with all of properties given by type
 * `K` to be also `unknown`.
 *
 * **Notice:** When `object` is `any`, the result is still `any`. This is
 * TypeScript feature/bug since 2.x. <https://github.com/Microsoft/TypeScript/issues/6015>
 */
export function hasProperty<K extends keyof any>(
  objectU: unknown,
  key: K,
): objectU is UnknownShape<K>;

// мерж `hasProperty(o:unknown, 'a') && hasProperty(o, 'b')` в `UnknownShape<'a' | 'b'>`, но слишком сложно
// вроде, получилось в objectO1
//   возможно `hasProperty(o:unknown, 'a', 'b')`?

export function hasProperty(object: any, key: keyof any) {
  if (!object) return false;
  switch (typeof object) {
    case 'object':
    case 'function':
      return Object.hasOwn(object, key);
  }
  return false;
}
