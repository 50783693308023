import { ChangeEvent, ChangeEventHandler, useMemo } from 'react';
import { InputDebounceOptions, useInputChangeHandler, useInputDebounce } from './valueInput';

type _V = string;
type _I = string;
type _Input = HTMLInputElement | HTMLTextAreaElement;
type _CA = [e: ChangeEvent<_Input>];

/**
 * Options
 * @see useInputDebounce
 */
interface Options extends Omit<InputDebounceOptions<_V, _I, _Input, _CA>, 'onChange'> {
  onChange?: ChangeEventHandler<_Input>;
}

/**
 * @see useInputDebounce
 */
export const useTextInputDebounce = ({ onChange, ...rest }: Options) => {
  const d = useInputDebounce<_V, _I, _Input, _CA>({
    ...rest,
    onChange: useMemo(() => onChange && ((value, e) => onChange(e)), [onChange]),
  });
  return {
    ...d,
    onChange: useInputChangeHandler(d.onChange),
  };
};
