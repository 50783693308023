// В конфиге webpack в react-scripts:
// - node_modules/react-scripts/config/webpack.config.js
// - node_modules/react-scripts/config/env.js
//
// прописано по сути следующее:
//
//     new webpack.DefinePlugin({
//       'process.env': {
//         'VAR_NAME': JSON.stringify("value"),
//         ...
//       },
//     })
//
// Насколько я понял из документации <https://webpack.js.org/plugins/define-plugin/>,
// и фактически получаемого в билде кода, это работает так:
//
//     console.log(process.env);
//     console.log(process.env.VAR_NAME);
//
// В билде:
//
//     console.log({/* тут всё понятно, тут всё целиком */});
//     console.log("value");
//
// Но вот когда мы используем переменную, которая фактически не была определена
// в ENV в момент билда (например, `SOME_UNDEFINED_VAR`), и т.о. отсутствует
// в `'process.env': {...}`, получается следующая хрень:
//
//     console.log(({/* тут весь env целиком */}.SOME_UNDEFINED_VAR));
//
// т.е. копипаста огромной партянки ради run-time вычисления const undefined.
//
// >>>> ПОЭТОМУ ВАЖНО <<<<
// Все используемые переменные должны быть явно определены в .env даже с пустыми
// значениями:
//
//     REACT_APP_FOO_BAR=

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://www.net';
export const APP_BASE_URL = process.env.REACT_APP_BASE_URL || 'http://finwork.imboss.cz';
export const CENT_URL = process.env.REACT_APP_CENT_URL || 'wss://ws.finkoper.com';
export const CENT_TECH_CHANNEL = process.env.REACT_APP_CENT_TECH_CHANNEL || 'tech1';
export const COMPANY = process.env.REACT_APP_COMPANY || 'Finkoper';
export const APP_VERSION = process.env.REACT_APP_VERSION || '0.1.0';
export const APP_VERSION_TAG = process.env.REACT_APP_VERSION_TAG;
export const DEVICE_ID = process.env.REACT_APP_DEVICE_ID || '7d3ac0c4-73e0-4698-b2a3-4235cba32545';
export const YANDEX_CLIENT_ID =
  process.env.REACT_APP_YANDEX_CLIENT_ID || 'f36ce4a3bfad4fd1b18a3bdf868ddb59';
export const YANDEX_DISK_CLIENT_ID = process.env.REACT_APP_YANDEX_DISK_CLIENT_ID || '';
export const CATALOG_URL = process.env.REACT_APP_CATALOG_URL || 'https://prof.finkoper.com';
