import cn from 'classnames';
import { ForwardedRef, ReactElement, Ref } from 'react';
import { Link } from 'react-router-dom';
import { isExternalHref } from 'utils/string';
import { ButtonSize, UiColor, UiColors } from '../types';
import { ContentOptions, renderContent } from './renderContent';
import { ButtonCoreProps } from './types';
import cl from './ButtonCore.module.scss';

interface Options extends ContentOptions {
  colors?: Partial<Record<UiColor, string>>;
  sizes: Partial<Record<ButtonSize, string>>;
  rootClassName?: string;
  withIconClassName?: string;
  isLoadingClassName?: string;
}

export const buttonCoreRender = (
  {
    uiColor = UiColors.default,
    size = 'default',
    icon,
    iconPosition,
    withIcon,
    isLoading,

    children,

    href,
    notRoute = isExternalHref(href),
    name,
    disabled,
    className,
    ...rest
  }: ButtonCoreProps,
  ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
  {
    colors,
    sizes,
    rootClassName,
    withIconClassName,
    isLoadingClassName,
    loaderClassName,
    textClassName,
    iconClassName,
  }: Options,
): ReactElement => {
  const classes = cn(
    cl.root,
    rootClassName,
    colors?.[uiColor],
    sizes[size],
    (icon || withIcon) && [cl.withIcon, withIconClassName],
    isLoading && [cl._loading, isLoadingClassName],
    className,
  );
  const content = renderContent({ icon, iconPosition, isLoading }, children, {
    loaderClassName: cn(cl.loaderCover, loaderClassName),
    iconClassName: cn(cl.icon, iconClassName),
    textClassName: cn(cl.text, textClassName),
  });

  return href ? (
    notRoute ? (
      <a ref={ref as Ref<HTMLAnchorElement>} {...rest} className={classes} href={href}>
        {content}
      </a>
    ) : (
      <Link ref={ref as Ref<HTMLAnchorElement>} {...rest} className={classes} to={href}>
        {content}
      </Link>
    )
  ) : (
    <button
      ref={ref as Ref<HTMLButtonElement>}
      {...rest}
      className={classes}
      name={name}
      disabled={disabled}
    >
      {content}
    </button>
  );
};
