import { DateISO, IdNumberOf, IdOf, UnixTimestamp } from 'utils/types/primitive';
import { CompanyId } from '../company/types';
import { UserId } from '../user/types';
import { TaskPeriodicalSettingItemForm } from 'models/tasks-periodical/types';
import { RoleUsers } from '../roles/types';
import { CustomerTagId } from '../customers-tags/types';
import { CustomerPropId } from '../customer-props/props/types';
import { CustomerUserLight } from './customer-user';

export type TaxbookItemId = IdNumberOf<'TaxbookItem'>;
export type VariantItemId = IdNumberOf<'VariantItem'>;
export type CustomerId = IdOf<'Customer'>;
export type CustomerEmployeeId = IdOf<'CustomerEmployee'>;
export type CustomerChannelId = IdOf<'CustomerChannel'>;

export interface VariantItem {
  id: VariantItemId;
  value: string;
}

export interface TaxbookItem {
  id: TaxbookItemId;
  isList: boolean;
  name: string;
  variant: VariantItem[] | null;
}

// Убираем блок с Email. Навсегда. https://trello.com/c/ndfxTB5I/545
export type CommunicationType = 'telegram';
export type CommunicationStatus = 'active' | 'unactive';

export interface Communication {
  type: CommunicationType;
  property: string;
  status: CommunicationStatus;
  for_group?: boolean;
  show_employee_name?: boolean;
  solved_task_notice?: boolean;
  is_tasks_disabled?: boolean;
}

export interface CustomerEmployee {
  id: CustomerEmployeeId;
  name: string;
  phone?: string;
  email: string;
  position?: string;
  role?: string;
  communications: Communication[] | null;
}

export interface CustomerProperty {
  id: TaxbookItemId;
  propertyValues: readonly VariantItemId[] | readonly [0 | 1];
}

export type CustomersTypicalFilter = ReadonlyMap<
  TaxbookItemId,
  readonly VariantItemId[] | readonly [0 | 1]
>;
export type CustomersPropFilter = ReadonlyMap<CustomerPropId, readonly (string | number)[]>;

export interface ContractorEmployee {
  id: UserId;
  //todo
  role: string;
}

export interface CustomerChannel {
  id?: CustomerChannelId;
  name: string;
  customerEmployees: CustomerEmployee[];
  contractorEmployees: ContractorEmployee[];
}

export interface CustomerCreateForm {
  customerName: string;
  customerShortName: string;
  customerAdress?: string;
  customerPhone?: string;
  customerEmail?: string;
  customerRegion: string;
  customerPropertyes: readonly CustomerProperty[] | null;
  customerComment?: string;
  contractorId: CompanyId;
  customerChannels: CustomerChannel[];
  customerStatus: CustomerStatus;
  typicalTasksSinceDate?: DateISO;
}

export interface CustomerCreateFormExt {
  customerForm: CustomerCreateForm;
  taskPeriodicalItems: TaskPeriodicalSettingItemForm[];
  roleUsers?: RoleUsers;
}

export interface Customer extends CustomerCreateForm {
  id: CustomerId;
  tags: CustomerTagId[];
}

export interface CustomerUpdateForm {
  customerId: CustomerId;
  form: CustomerCreateForm;
}

//with Periodical Tasks and Roles
export interface CustomerUpdateFormExt {
  customerForm: CustomerUpdateForm;
  taskPeriodicalItems: TaskPeriodicalSettingItemForm[];
  companyId: CompanyId;
  roleUsers?: RoleUsers;
}

export const enum CustomerStatus2 {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
  /** @deprecated убрал из новых ответов сервера */
  DELETE = 'delete',
}
/** @deprecated */
export type CustomerStatus = 'active' | 'archive' | 'delete' | CustomerStatus2;
export interface CustomersListFilter {
  name_filter?: string;
  status?: CustomerStatus2;
  employees?: readonly UserId[];
  typical?: CustomersTypicalFilter;
  tags?: CustomerTagId[];
  /**
   * По той же схеме:
   *
   * ```
   * ?props[0][id]=1
   * &props[0][values][]=1
   * &props[0][values][]=2
   * &props[1][id]=7
   * &props[1][values][]=7
   * ```
   *
   * Типы свойств:
   *
   * - boolean,
   * - date,
   * - datetime,
   * - number,
   * - select:
   *   - проверка равенства
   *
   * - string,
   * - url:
   *   - проверка вхождения
   */
  props?: CustomersPropFilter;
}
export interface CustomersListOptions {
  with_cu?: boolean;
}

export interface CustomerItemLight {
  id: CustomerId;
  name: string;
  status: CustomerStatus;
  // employeesId?: readonly UserId[]; - неее, тормозит очень: больше минуты вместо 1-3 сек
}
export interface CustomerItem {
  id: CustomerId;
  customerShortName: string;
  customerStatus: CustomerStatus;
  contractorEmployees?: ContractorEmployee[];
  /**
   * Представители кратко
   * Если включён `with_cu` в запросе
   */
  customerUsers?: readonly CustomerUserLight[];
}

export interface CustomersEmployeesParams {
  /** Не более 100 штук за раз */
  c: readonly CustomerId[];
}
export type CustomersEmployeesAssignment = Record<CustomerId, readonly UserId[]>;

export const isCustomerUpdateForm = (
  form: CustomerUpdateFormExt | CustomerCreateFormExt,
): form is CustomerUpdateFormExt => (form as any).customerForm?.customerId;

export interface CustomerEmployeeCreateForm extends Omit<CustomerEmployee, 'id'> {}

export interface CustomerEmployeeCreateFormParams {
  form: CustomerEmployeeCreateForm;
  customerId: CustomerId;
  channelId: CustomerChannelId;
}

export interface CustomerEmployeeUpdateForm extends Partial<CustomerEmployeeCreateForm> {
  id: CustomerEmployeeId;
}

export interface CustomerEmployeeUpdateFormParams {
  form: CustomerEmployeeUpdateForm;
  customerId: CustomerId;
  channelId: CustomerChannelId;
}

export interface CustomerEmployeeDeleteParams {
  userId: CustomerEmployeeId;
  channelId: CustomerChannelId;
}

export interface UpdateCustomerContractorRelationsParams {
  idcontractor: CompanyId;
  idcustomer: CustomerId;
  status: CustomerStatus;
}

export interface UpdateCustomerContractorRelationsEvent {
  customerId: CustomerId;
  status: CustomerStatus;
}

export interface UpdateCustomerContractorRelationsResponse {
  archiveat: UnixTimestamp;
  contractorid: CompanyId;
  createat: UnixTimestamp;
  customerid: CustomerId;
  deleteat: UnixTimestamp;
  status: CustomerStatus;
}

export interface TelegramUrlData {
  url: string;
}

export interface CustomerAttachTagForm {
  tags: CustomerTagId[];
}
