import { ChangeEvent, useCallback } from 'react';
import * as c from 'utils/string/completeShortUrl';
import { InputProps } from '../Input';
import { HTMLInputElementLike, useValueInputWrap, ValueInputProps } from '../valueInput';

type V = string;
type I = string;
type CA<Element> = [ChangeEvent<Element>];

const v2i = (v: V): I => v;

export interface UrlInputOptions<Element>
  extends ValueInputProps<V, I, Element, CA<Element>>,
    c.Options {}

export const useUrlInput = <Element extends HTMLInputElementLike<I> = HTMLInputElement>({
  allowMailto,
  ...rest
}: UrlInputOptions<Element>) =>
  useValueInputWrap<V, I, CA<Element>, Element>({
    ...rest,
    emptyValue: '',
    formatValue: v2i,
    parseInput: useCallback(
      (i: I): V => c.completeShortUrl(i, { allowMailto }).value.trim(),
      [allowMailto],
    ),
  });

const BASE_URL_PATTERN = '(https?|s?ftp)://.*';
const _OR_MAILTO = '|mailto:.*';
export var getUrlInputPattern = ({ allowMailto }: c.Options = {}) =>
  allowMailto ? BASE_URL_PATTERN + _OR_MAILTO : BASE_URL_PATTERN;

export const defaultAttrs = (o?: c.Options): InputProps => ({
  pattern: getUrlInputPattern(o),
});
