const alpha = /\p{L}/u.source;
const alnum = /[\p{L}\p{N}]/u.source;
const alnumdash = /[-_\p{L}\p{N}]/u.source;

export const reDomainLabel = new RegExp(`(?:${alnum}(?:${alnumdash}*${alnum})?)`, 'u');
const reTopDomainLabel = new RegExp(`(?:${alpha}(?:${alnumdash}*${alnum})?)`, 'u');

export const reDomain = new RegExp(
  `(?:(?:${reDomainLabel.source}\\.)+(?!\\d)${reTopDomainLabel.source})\\.?`,
  'ui',
);
