import { createEffect, Effect } from 'effector';
import { WithBookkeeper, WithBookkeeperPascal } from 'api/types/request';
import { $currentCompanyId } from '../company';

// REFACT: переделать на attach()

// TODO: остаётся непокрытым случай, когда T - сам по себе `infer T`,
//   потому что исходит из внешнего `<T>(p: T) => {}` параметра.
type WO<T, X> =
  // T extends any ? F<T> : never
  // сначала расщипляем union, чтобы `(A|B)&X`, которое на самом деле
  // становится `(A&X)|(B&X)`, обрабатывалось отдельно по каждой альтернативе
  T extends any
    ? // тупой случай, когда наоборот T является частью X
      // тогда `{}`, нефиг делать там
      keyof T extends keyof X
      ? {}
      : // Когда T есть `U & X`, это может быть как буквально `U & X`,
        // так и `interface _ extends X {}`
        T extends infer U & X
        ? // U всё ещё включает X, значит T был `interface _ extends X {}`
          U extends X
          ? // тогда убирает поля X из T традиционным способом
            Omit<T, keyof X>
          : // нет, T был `U & X`, X отщипился
            U
        : // Нет, T не включает в себя X.
          // Есть ли у них вообще общие имена полей?
          keyof T & keyof X extends never
          ? // нет общих поле в T и X, значит T и так норм
            T
          : // убираем лишние поля традиционным способом
            Omit<T, keyof X>
    : never;

// interface A {
//   x: number;
// }
// interface B {
//   y: string;
// }
// interface C extends A, B {}
// type D = A & B;
// type TA = WO<A, B>;
// type TB = WO<B, B>;
// type TC = WO<C, B>;
// type TD = WO<D, B>;

type Api<P, R> = (params: P) => Promise<R>;

interface WithBookkeeperFxFactory {
  <R>(fx1: Api<WithBookkeeper, R>): Effect<void | undefined | null, R>;

  <P, R>(fx2: Api<P & WithBookkeeper, R>): Effect<WO<P, WithBookkeeper>, R>;
  // а это старая версия
  // <P, R>(fx: Api<P & WithBookkeeper, R>): Effect<P, R>;
}
interface WithBookkeeperPascalFxFactory {
  <R>(fx: Api<WithBookkeeperPascal, R>): Effect<void | undefined | null, R>;

  <P, R>(fx2: Api<P & WithBookkeeperPascal, R>): Effect<WO<P, WithBookkeeperPascal>, R>;
  // а это старая версия
  // <P, R>(fx: Api<P & WithBookkeeperPascal, R>): Effect<P, R>;
}

export const withBookkeeperFx: WithBookkeeperFxFactory = <P, R>(
  fx: Api<P & WithBookkeeper, R>,
): Effect<P, R> =>
  createEffect((params: P) => {
    const bookkeeper_team_id = $currentCompanyId.getState();
    if (bookkeeper_team_id) {
      return fx({
        bookkeeper_team_id,
        // user_id: $userId.getState(),
        ...params,
      });
    }
    report?.('withBookkeeperFx', { fx });
    return Promise.reject<never>(new Error('no bookkeeper team'));
  });

export const withBookkeeperPascalFx: WithBookkeeperPascalFxFactory = <P, R>(
  fx: Api<P & WithBookkeeperPascal, R>,
): Effect<P, R> =>
  createEffect((params: P) => {
    const BookkeeperTeamID = $currentCompanyId.getState();
    if (BookkeeperTeamID) {
      return fx({
        BookkeeperTeamID,
        // UserID: $userId.getState(),
        ...params,
      });
    }
    report?.('withBookkeeperPascalFx', { fx });
    return Promise.reject<never>(new Error('no bookkeeper team'));
  });

const report =
  process.env.NODE_ENV === 'development'
    ? (fname: string, ...args: any[]) =>
        console.error(fname, '=> no bookkeeper', ...args, 'см. fx.defaultConfig.handler')
    : undefined;
