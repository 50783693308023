import { createEffect, createEvent, createStore, sample } from 'effector';
import * as RoMap from '@cubux/readonly-map';
import { CommonUser, UserId } from 'models/user/types';
import { $usersMap } from 'models/user';
import { isDefined } from 'utils/fn';
import { ObjectUrl } from 'utils/types/primitive';
import { $userAvatarMap } from 'models/channel';
import { createGate } from 'effector-react';
import { SelectEmployeeKey } from 'components/employee/SelectEmployee/SelectEmployee';
import { EMPTY_ARRAY, EMPTY_MAP } from 'constants/utils';

//Получилось так, что в некоторых местах, мы не можем доверить Гейту очищение стейта при монтировании/размонтировании.
//Например в задачах появились вкладки, если переключиться на другую, а потом вернуться снова на главную <SelectEmployee /> будет очищен,
//чего нам не нужно.
//Поэтому появился параметр resetMountUnmount:boolean . Выглядит конечно ужасно, но это первое что пришло в голову.
export const SelectEmployeeGate = createGate<{
  id: SelectEmployeeKey;
  resetMountUnmount: boolean;
}>({ defaultState: { id: '', resetMountUnmount: true } });

interface CommonUserAvatarLink extends CommonUser {
  avatarLink?: ObjectUrl;
}

///////// Version 2
export interface SelectEmployeeParams {
  id: SelectEmployeeKey;
  userIds: UserId[];
}

export const addUsers = createEvent<SelectEmployeeParams>();
export const replaceUsers = createEvent<SelectEmployeeParams>();
export const deleteUser = createEvent<{ id: SelectEmployeeKey; userId: UserId }>();
export const $selectedUsers =
  createStore<ReadonlyMap<SelectEmployeeKey, CommonUserAvatarLink[]>>(EMPTY_MAP);

$selectedUsers
  .on(SelectEmployeeGate.close, (s, { id, resetMountUnmount }) =>
    resetMountUnmount ? RoMap.remove(s, id) : s,
  )
  .on(SelectEmployeeGate.open, (s, { id, resetMountUnmount }) =>
    s.has(id) || !resetMountUnmount ? s : RoMap.set(s, id, EMPTY_ARRAY),
  )
  .on(deleteUser, (s, { id, userId }) =>
    RoMap.updateDefault(s, id, EMPTY_ARRAY, (list) => list.filter((c) => c.id !== userId)),
  );

sample({
  clock: addUsers,
  source: [$selectedUsers, $usersMap, $userAvatarMap] as const,
  target: $selectedUsers,
  fn: ([users, usersMap, avatarMap], { userIds, id: key }) =>
    RoMap.updateDefault(users, key, EMPTY_ARRAY as CommonUserAvatarLink[], (list) =>
      list.concat(
        userIds
          .map((userId) => {
            const user = usersMap.get(userId);
            return (
              user && {
                ...user,
                avatarLink: avatarMap.get(userId),
              }
            );
          })
          .filter(isDefined),
      ),
    ),
});

sample({
  clock: replaceUsers,
  source: [$usersMap, $userAvatarMap, $selectedUsers] as const,
  target: $selectedUsers,
  fn: ([usersMap, avatarMap, selectedUsers], { userIds, id: key }) => {
    return RoMap.set(
      selectedUsers,
      key,
      userIds
        .map((userId) => {
          const user = usersMap.get(userId);
          return (
            user && {
              ...user,
              avatarLink: avatarMap.get(userId),
            }
          );
        })
        .filter(isDefined),
    );
  },
});

const keyChanged = sample({
  clock: $selectedUsers,
  source: SelectEmployeeGate.state,
  fn: (state): SelectEmployeeKey => state?.id,
});

export const validateSelectedUsersFx = createEffect<SelectEmployeeKey, null>((key) => {
  if (!$selectedUsers.getState().get(key)?.length) throw new Error();
  return null;
});
export const $hasErrors = createStore<ReadonlyMap<SelectEmployeeKey, boolean>>(EMPTY_MAP)
  // .reset(resetCurrentCustomer)
  .on(validateSelectedUsersFx.fail, (s, { params: key }) => RoMap.set(s, key, true))
  .on(SelectEmployeeGate.close, (s, { id, resetMountUnmount }) =>
    resetMountUnmount ? RoMap.remove(s, id) : s,
  )
  .on(keyChanged, (s, key) => RoMap.remove(s, key));
// .on($selectedUsers, () => false);
