import { ReactElement } from 'react';
import { fnVoid } from 'utils/fn';
import { getPromptContainer } from './PromptContainer';

type CReq<V> = (value: V) => void;
type COpt<V> = (value?: V) => void;
type DEFAULT = true | undefined;

export interface RenderPromptProps<V = DEFAULT> {
  show: boolean;
  onSubmit: undefined extends V ? COpt<V> : true extends V ? COpt<V> : CReq<V>;
  onDismiss: () => void;
}

export interface RenderPromptCallback<V> {
  (props: RenderPromptProps<V>): ReactElement;
}

export interface RenderPromptOptions {
  destructionDelay?: number;
}

const noop = fnVoid;

/**
 * Пример
 *
 * ```ts
 * interface Options {
 *   x?: number;
 * }
 * interface Props extends Options, RenderPromptProps<string> {}
 *
 * const Foo: FC<Props> = ({show, onSubmit, onDismiss, x = 0}) => {...};
 *
 * export const promptFoo = (o: Options = {}) =>
 *   reactModal<string>(props => <Foo {...props} {...o}/>);
 * ```
 */
export const reactModal = async <V = DEFAULT>(
  renderPrompt: RenderPromptCallback<V>,
  { destructionDelay = 300 }: RenderPromptOptions = {},
): Promise<undefined extends V ? V | true | undefined : V | undefined> => {
  const container = getPromptContainer().add();
  try {
    return await new Promise((resolve) => {
      container.render(
        renderPrompt({
          show: true,
          onSubmit: (value = true as const as any) => resolve(value),
          onDismiss: () => resolve(undefined),
        }),
      );
    });
  } finally {
    try {
      container.render(
        renderPrompt({
          show: false,
          onSubmit: noop,
          onDismiss: noop,
        }),
      );
    } catch {}
    setTimeout(container.delete, destructionDelay);
  }
};
