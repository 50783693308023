import cn from 'classnames';
import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Icon, { IconRef } from '../../Icon';
import { FormControlLabel } from '../FormControlLabel';
import cl from './Checkbox.module.scss';

export interface CheckBoxProps {
  name?: string;
  className?: string;
  checked?: boolean;
  color?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onToggle?: (checked: boolean) => void;
  onClick?: MouseEventHandler<HTMLInputElement>;
  defaultChecked?: boolean;
  disabled?: boolean;
  /** Иконка вместо галки для обоих состояний */
  icon?: IconRef;
  /** Иконка вместо галки для включённого состояния. По умолчанию `icon`. */
  iconChecked?: IconRef | null;
  /** Иконка вместо галки для отключённого состояния. По умолчанию `icon`. */
  iconUnchecked?: IconRef | null;
}

export const Checkbox: FC<CheckBoxProps> = ({
  checked,
  onChange,
  onToggle,
  defaultChecked,
  disabled,
  className,
  color,
  name,
  icon,
  iconChecked = icon,
  iconUnchecked = icon,
  onClick,
  ...rest
}) => {
  const [localChecked, setLocalChecked] = useState(checked);
  useEffect(() => {
    if (checked !== undefined) {
      setLocalChecked(checked);
    }
  }, [checked]);
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onChange?.(e);
      onToggle?.(e.target.checked);
      if (!e.isDefaultPrevented()) {
        setLocalChecked(e.target.checked);
      }
    },
    [onChange, onToggle],
  );

  const _icon = localChecked ? iconChecked : iconUnchecked;
  return (
    <div {...rest} className={cn(cl.control, className)}>
      <input
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        name={name}
        onChange={handleChange}
        onClick={onClick}
      />
      {_icon ? (
        <Icon
          type={_icon}
          className={cn(cl.indicator, cl.indicatorIcon)}
          style={color && checked ? { backgroundColor: color } : undefined}
        />
      ) : (
        <div
          className={cn(cl.indicator, cl.indicatorAuto)}
          style={color && checked ? { backgroundColor: color } : undefined}
        />
      )}
    </div>
  );
};

export interface LabeledCheckboxProps extends CheckBoxProps {
  label: ReactNode | string;
  helper?: ReactNode;
  className?: string;
  checkboxClassName?: string;
}

export const LabeledCheckbox: FC<LabeledCheckboxProps> = ({
  className,
  checkboxClassName,
  label,
  helper,
  disabled,
  ...tail
}) => {
  return (
    <FormControlLabel className={className} label={label} helper={helper} disabled={disabled}>
      <Checkbox className={checkboxClassName} disabled={disabled} {...tail} />
    </FormControlLabel>
  );
};
