import { forwardRef } from 'react';
import { Input, Props as InputProps } from '../Input/Input';
import { useInputChangeHandler } from '../valueInput';
import { NumberInputOptions, useNumberInput } from './useNumberInput';

export interface NumberInput2Props
  extends NumberInputOptions<HTMLInputElement>,
    Omit<InputProps, 'value' | 'onChange'> {}

export const NumberInput2 = forwardRef<HTMLInputElement, NumberInput2Props>((props, ref) => {
  let { decimals, value, onChange, onBlur, onFocus, triggerEveryChange, ...rest } = props;

  {
    // иногда из некоторых АПИ приходит строка в цифрами
    const raw: unknown = value;
    if (raw !== null && raw !== undefined && typeof raw !== 'number') {
      const i = Number(raw);
      if (!isNaN(i)) {
        if (process.env.NODE_ENV === 'development') {
          console.warn('Not a number type passed to `value`:', raw, '; converted to number:', i);
        }
        value = i;
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.warn('Unexpected type passed to `value`:', raw);
        }
        value = null;
      }
    }
  }

  const attrs = useNumberInput({ ...props, value });

  return (
    <Input
      ref={ref}
      {...rest}
      {...attrs}
      onChange={useInputChangeHandler(attrs.onChange)}
      type="text"
      inputMode="numeric"
    />
  );
});
