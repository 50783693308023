import { TFunction } from 'i18next';
import { boolNot } from 'utils/fn';
import { DenyProps } from 'utils/types';
import { CompareValidator } from './core';
import { ValidationResult } from './interfaces';

// можно черпать идеи, например, из https://github.com/yiisoft/validator

// TODO: валидация целого объекта формы по заранее подготовленным правилам для
//   заданных полей.

// TODO: При валидации полей объекта возможна ситуация, когда поле в объекте
//   отсутствует, потому что не было изменено. Не было изменено, значит и
//   не будет отправлено на сервер, значит нечего и валидировать в этом поле.
//   Реальный пример: Настройки -> Команда -> Название. Поле обязательно для
//   заполнения, но его отсутствие в изменённых данных не является ошибкой.

interface ValidatorInstance {
  isValid(obj: object): boolean;
  requiredValidator(val: any): ValidationResult;
  compareValidator<T = any>(): CompareValidator<T>;
}

type ValidatorPrivates = 't';
type ValidatorInstanceOwnProps = keyof ValidatorInstance | ValidatorPrivates;

interface ValidatorConstructor {
  new <T extends object & DenyProps<T, ValidatorInstanceOwnProps>>(
    t: TFunction,
    extras: T,
  ): ValidatorInstance & T;
  new (t: TFunction): ValidatorInstance;
}

export const Validator: ValidatorConstructor = class Validator implements ValidatorInstance {
  // TODO: TS>=4: ES private field `#t`, `this.#t`, eliminate `ValidatorPrivates`
  private readonly t: TFunction;

  constructor(t: TFunction, extras?: object) {
    this.t = t;

    if (extras) {
      if (process.env.NODE_ENV !== 'production') {
        Object.keys(extras).forEach((key) => {
          if (Object.hasOwn(this, key)) {
            throw new TypeError(`Property \`${key}\` of Validator cannot be shadowed`);
          }
        });
      }
      Object.assign(this, extras);
    }
  }

  isValid = (obj: object) => Object.values(obj).every(boolNot);

  requiredValidator = (val: any): ValidationResult =>
    !val ? this.t('validation:Required') : undefined;

  compareValidator = <T = any>() => new CompareValidator<T>(this.t);
};
