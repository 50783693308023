import { DateTimeISO } from 'utils/types';
import { CompanyId } from '../company/types';
import {
  WaGreenApiInstanceId,
  WaGreenFile,
  WaGreenGroupId,
  WaGreenInstanceId,
  WaGreenInstanceState,
  WaGreenMessageAck,
  WaGreenMessageData_Reaction,
  WaGreenMessageId,
} from '../wagreen/types';
import { CentMessageDataV2 } from './internal';

export const enum CentPrivateEventTypeWaGreen {
  InstanceState = 'wagreen.instanceState',
  Chats = 'wagreen.chats',
  Message = 'wagreen.chatMessage',
  MessageAck = 'wagreen.chatMessageAck',
  MessageReaction = 'wagreen.chatMessageReaction',
  ChatSeen = 'wagreen.chatSeen',
  ChatAttachment = 'wagreen.chatAttachment',
  UserChats = 'wagreen.userChats',
}

interface WaGreenInstanceRef {
  instanceId: WaGreenInstanceId;
}
export interface CentPrivateEventWaGreenInstanceState extends WaGreenInstanceRef {
  workInstanceId: WaGreenApiInstanceId;
  state: WaGreenInstanceState;
}
export interface CentPrivateEventWaGreenChats extends WaGreenInstanceRef {
  // chatId: WaGreenGroupId;
}
export interface CentPrivateEventWaGreenChatMessages {
  teamId: CompanyId;
  chatId: WaGreenGroupId;
  msgId: WaGreenMessageId;
  ack: WaGreenMessageAck;
  silent?: boolean;
  at?: DateTimeISO;
}
export interface CentPrivateEventWaGreenChatMessagesReaction {
  teamId: CompanyId;
  chatId: WaGreenGroupId;
  msgId: WaGreenMessageId;
  reaction: {
    id: WaGreenMessageId;
    created_at: DateTimeISO;
    from: string;
    from_name: string;
    data: WaGreenMessageData_Reaction;
  };
}
export interface CentPrivateEventWaGreenChatSeen {
  teamId: CompanyId;
  chatId: WaGreenGroupId;
  seen: DateTimeISO | '';
}
export interface CentPrivateEventWaGreenChatAttachment {
  teamId: CompanyId;
  chatId: WaGreenGroupId;
  msgId: WaGreenMessageId;
  file: WaGreenFile;
}

export type CentPrivateEventWaGreen =
  | CentMessageDataV2<
      CentPrivateEventTypeWaGreen.InstanceState,
      CentPrivateEventWaGreenInstanceState
    >
  | CentMessageDataV2<CentPrivateEventTypeWaGreen.Chats, CentPrivateEventWaGreenChats>
  | CentMessageDataV2<CentPrivateEventTypeWaGreen.Message, CentPrivateEventWaGreenChatMessages>
  | CentMessageDataV2<CentPrivateEventTypeWaGreen.MessageAck, CentPrivateEventWaGreenChatMessages>
  | CentMessageDataV2<
      CentPrivateEventTypeWaGreen.MessageReaction,
      CentPrivateEventWaGreenChatMessagesReaction
    >
  | CentMessageDataV2<CentPrivateEventTypeWaGreen.ChatSeen, CentPrivateEventWaGreenChatSeen>
  | CentMessageDataV2<
      CentPrivateEventTypeWaGreen.ChatAttachment,
      CentPrivateEventWaGreenChatAttachment
    >
  | CentMessageDataV2<CentPrivateEventTypeWaGreen.UserChats, void>;
