import { IdOf } from '../utils/types';
import { CompanyId } from './company/types';

export const enum LSKey {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  ctoken = 'ctoken',
  clientId = 'clientId',
  authType = 'authType',
  company = 'company',
  theme = 'theme',
  customerChannelsOrder = 'channelsOrder',
  employeeChannelsOrder = 'employeeChannelsOrder',
  tasksPage = 'tasksPage',
  customerAndEmployeeTasks = 'customerAndEmployeeTasks', //используется в карточке клиента и сотрудника (выбор в списке слева)
}

export type LsKeyByCompany = IdOf<'LSKeyByCompany'>;

export const enum LSKeyPrefix {
  calendar = 'calendar',
  analytics = 'analytics',
  whatsappSendInternal = 'waSendInternal',
  customerInfoState = 'customerInfoState',
}

export const getLsKeyByCompany = (lsKey: LSKey, companyId: CompanyId): LsKeyByCompany =>
  `${lsKey}[${companyId}]` as LsKeyByCompany;

export const getLsKeyWithPrefix = (lsPrefix: LSKeyPrefix, key: string | number) =>
  `${lsPrefix}[${key}]`;
