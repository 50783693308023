export enum IconPositions {
  start = 'start',
  end = 'end',
}

export type IconPosition = keyof typeof IconPositions;

export type ButtonSize = 'default' | 'large' | 'largeX' | 'small';

export enum UiColors {
  'default' = 'default',
  primary = 'primary',
  danger = 'danger',
  success = 'success',
  accent = 'accent',
  secondary = 'secondary',
}
export type UiColor = keyof typeof UiColors;
