import { createEffect, createEvent, restore, sample } from 'effector';
import { SetStateAction } from 'react';
import * as RoArray from '@cubux/readonly-array';
import api, { TagDeleteParam, TagListParams, TagUpdateParam } from 'api/request/tags';
import { EMPTY_ARRAY } from 'constants/utils';
import { Callbacks } from 'components/tag';
import { $currentCompanyIdOrNull } from 'models/company';
import { CompanyId } from 'models/company/types';
import { cmpTags, Tag, TagCreateForm, TagForm, TagId } from 'models/tags/types';
import { isNotNull } from 'utils/fn';

export const selectTag = createEvent<Tag>();
/** List */
export const getTags = createEvent<{ is_local?: boolean } | void>();
export const tagDidUpdate = createEvent<{ id: TagId; upd: SetStateAction<Tag> }>();
export const getTagsFx = createEffect(api.list);
export const $tagsLoading = getTagsFx.pending;
export const $tags = restore<readonly Tag[]>(
  getTagsFx.doneData.map(({ data }) => data || EMPTY_ARRAY),
  EMPTY_ARRAY,
).on(tagDidUpdate, (tags, { id, upd }) =>
  RoArray.updateMatch(tags, (tag) => tag.id === id, typeof upd === 'function' ? upd : () => upd),
);
sample({
  clock: getTags,
  source: $currentCompanyIdOrNull,
  target: getTagsFx,
  filter: Boolean,
  fn: (companyId, params): TagListParams => ({
    bookkeeper_team_id: companyId,
    is_local: params?.is_local,
  }),
});

export const getTagsLocal = createEvent();
const getTagsLocalFx = createEffect(
  async (companyId: CompanyId) =>
    await api.list({
      is_local: true,
      bookkeeper_team_id: companyId,
    }),
);
export const $tagsLocal = restore<readonly Tag[]>(
  getTagsLocalFx.doneData.map(({ data }) => data || EMPTY_ARRAY),
  EMPTY_ARRAY,
);
const $tagsLocalNextSort = $tagsLocal.map((list) =>
  list.length ? list.reduce((r, v) => Math.max(r, v.sort), 0) + 1 : 0,
);
export const $tagsLocalMap = $tagsLocal.map((tags) => new Map(tags.map((tag) => [tag.id, tag])));
sample({
  clock: getTagsLocal,
  source: $currentCompanyIdOrNull,
  filter: Boolean,
  target: getTagsLocalFx,
});

/** Create */
export const createTag = createEvent<TagForm>();
export const createTagFx = createEffect(async (params: TagCreateForm) => await api.create(params));
sample({
  clock: createTag,
  source: {
    bk: $currentCompanyIdOrNull,
    sort: $tagsLocalNextSort,
  },
  target: createTagFx,
  filter: ({ bk }) => Boolean(bk),
  fn: ({ bk, sort }, form): TagCreateForm => ({
    bookkeeper_team_id: bk!,
    ...form,
    sort: form.sort ?? sort,
  }),
});
$tagsLocal.on(createTagFx.doneData, (s, { data }) => [...s, data]);

/** Delete */
export const deleteTag = createEvent<TagId>();
export const deleteTagFx = createEffect(async (params: TagDeleteParam) => await api.delete(params));
sample({
  clock: deleteTag,
  source: $currentCompanyIdOrNull,
  target: deleteTagFx,
  filter: Boolean,
  fn: (companyId, tagId): TagDeleteParam => ({
    tagId,
    form: {
      bookkeeper_team_id: companyId,
    },
  }),
});
$tagsLocal.on(deleteTagFx.done, (s, { params: { tagId } }) => s.filter((c) => c.id !== tagId));

/** Update */
export const updateTag = createEvent<TagUpdateParam<TagId>>();
export const updateTagFx = createEffect(api.update);
sample({ clock: updateTag, target: updateTagFx });
$tagsLocal.on(updateTagFx.doneData, (s, { data }) =>
  s.map((c) => (c.id === data.id ? data : c)).sort(cmpTags),
);

// REFACT: копипаста пошла в теги клиентов
/** Сортировка */
export const sortTags = createEvent<readonly Tag[]>();
type _SortItem = [TagId, number];
sample({
  clock: sample({
    clock: sortTags,
    source: $tagsLocal,
    fn: (prev, next) => {
      const prevMap = new Map(prev.map((o) => [o.id, o.sort]));
      return next
        .map<_SortItem | null>((o, i) => (prevMap.get(o.id) === i ? null : [o.id, i]))
        .filter(isNotNull);
    },
  }),
  source: $currentCompanyIdOrNull,
  filter: Boolean,
  fn: (bk, order) => ({ bk, order }),
}).watch(({ bk, order }) => {
  for (const [id, sort] of order) {
    updateTagFx({
      tagId: id,
      form: {
        bookkeeper_team_id: bk,
        sort,
      },
    }).catch(console.warn);
  }
});

export const TAG_CALLBACKS: Callbacks<TagId> = {
  create: createTag,
  update: updateTag,
  delete: deleteTag,
  select: selectTag,
  sort: sortTags,
};
