import { ChangeEvent, FC, FocusEventHandler, useCallback, useRef } from 'react';
import cn from 'classnames';
import { IView } from '../../types';
import { useFocusWithin } from '../../utils/useFocusWithin';
import { InputSize } from '../Input/Input';
import { useValueInputWrap } from '../valueInput';
import {
  formatIntValue,
  formatStringValue,
  HMInput,
  HMValue,
  i2v,
  iEQ,
  parseIntInput,
  parseStrInput,
  v2i,
  vEQ,
} from './internal';
import { NumInput } from './NumInput';
import cl from './TimeInput.module.scss';

interface Props extends IView {
  value?: HMValue;
  onChange?: (value: HMValue) => void;
  size?: InputSize;
  disabled?: boolean;
  readOnly?: boolean;
  onFocus?: FocusEventHandler<HTMLSpanElement>;
  onBlur?: FocusEventHandler<HTMLSpanElement>;
}

const CL_SIZE: Partial<Record<InputSize, string>> = {
  small: cl._small,
};

/**
 * Время по часам на стене: от 00:00 до 23:59.
 * Если нужна _длительность_ ч:мм, см. LongTimeInput
 */
export const TimeInput: FC<Props> = ({
  value,
  onChange,
  size = 'default',
  disabled = false,
  readOnly = false,
  className,
  onFocus,
  onBlur,
  ...rest
}) => {
  const d = useValueInputWrap<HMValue, HMInput, [], HTMLSpanElement>({
    parseInput: i2v,
    formatValue: v2i,
    emptyValue: null,
    isInputEqual: iEQ,
    isValueEqual: vEQ,
    value,
    onChange,
    // onFocus,
    // onBlur,
  });

  const containerFocus = useFocusWithin<HTMLSpanElement>({
    onFocusEnter: useCallback(
      (e) => {
        d.onFocus.call(null, e);
        onFocus?.(e);
      },
      [d.onFocus, onFocus],
    ),
    onFocusLeave: useCallback(
      (e) => {
        d.onBlur.call(null, e);
        onBlur?.(e);
      },
      [d.onBlur, onBlur],
    ),
  });

  // const refHours = useRef<HTMLInputElement | null>(null);
  const refMinutes = useRef<HTMLInputElement | null>(null);

  const { value: input, onChange: setInput } = d;
  const [hour, min] = input;

  const handleHourChange = useCallback(
    (hour: number | null, e: ChangeEvent<HTMLInputElement>) => {
      setInput([hour, min]);
      if (hour !== null) {
        // Когда в "часы" (в НЕпоследний компонент ввода) ввели такое значение, что
        // ввод следующей цифры технически возможен, но фактически приветёд к
        // некорректному значению:
        //   В часах уже введено `5`. Добавление любой цифры приведёт к некорректному
        //   значению для часов.
        // мы отправляем фокус в следующее поле.
        //
        // Та же история, когда введено макс. кол-во цифр.
        if ((hour >= 3 && hour <= 9) || e.target.value.length === 2) {
          const u = refMinutes.current;
          if (u) {
            u.focus();
            u.select();
          }
        }

        // Но эта херня не срабатывала бы без `triggerEveryChange`, когда вводим
        // `00`, т.к. при вводе второго нуля числовое значение не изменяется,
        // отчего наш кастомный `onChange` не триггерится.
      }
    },
    [setInput, min],
  );

  const handleMinChange = useCallback(
    (min: number | null) => setInput([hour, min]),
    [setInput, hour],
  );

  return (
    <span
      {...rest}
      onFocus={containerFocus.onFocus}
      onBlur={containerFocus.onBlur}
      className={cn(cl.root, CL_SIZE[size], disabled && cl._disabled, className)}
    >
      <NumInput
        // ref={refHours}
        value={hour}
        onChange={handleHourChange}
        triggerEveryChange
        className={cl.hh}
        disabled={disabled}
        readOnly={readOnly}
        max={23}
        placeholder="--"
      />
      <span className={cl.colon} />
      <NumInput
        ref={refMinutes}
        value={min}
        onChange={handleMinChange}
        className={cl.mm}
        disabled={disabled}
        readOnly={readOnly}
        max={59}
        placeholder="--"
      />
    </span>
  );
};

interface PropsStr extends Omit<Props, 'value' | 'onChange'> {
  /** "hh:mm" */
  value?: string;
  /** "hh:mm" */
  onChange?: (value: string) => void;
}
/** Ввод-вывод в виде строки `"hh:mm"` совместимо с `<input type="time">` */
export const TimeInputStr: FC<PropsStr> = ({ value, onChange, onFocus, onBlur, ...rest }) => (
  <TimeInput
    {...rest}
    {...useValueInputWrap({
      parseInput: parseStrInput,
      formatValue: formatStringValue,
      isInputEqual: vEQ,
      emptyValue: '',
      onFocus,
      onBlur,
      value,
      onChange,
    })}
  />
);

interface PropsNum extends Omit<Props, 'value' | 'onChange'> {
  /** кол-во минут */
  value?: number | null;
  /** кол-во минут */
  onChange?: (value: number | null) => void;
}
/** Ввод-вывод в виде кол-ва минут */
export const TimeInputNum: FC<PropsNum> = ({ value, onChange, onFocus, onBlur, ...rest }) => (
  <TimeInput
    {...rest}
    {...useValueInputWrap({
      parseInput: parseIntInput,
      formatValue: formatIntValue,
      isInputEqual: vEQ,
      emptyValue: null,
      onFocus,
      onBlur,
      value,
      onChange,
    })}
  />
);
