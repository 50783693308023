import { FC, HTMLAttributes, ReactElement } from 'react';
import * as svgs from './icons';
import IconContainer from './IconContainer';

// Строковые имена иконок вынуждают импортировать и билдить все доступные
// иконки. Если вместо `icon="FooBar"` использовать `icon={<svg.FooBar/>}`,
// то по идее это должно сократить размер билда.
//
// Испытал отдельно принудительную замену прямо всех `icon="FooBar"` на
// `icon={<svg.FooBar/>}`. Из main чанка ушло 12 иконок, сократив их кол-во там
// с 89 до 77. Минус 3 КБ из 140 - не критично. Поэтому принудительная замена
// всех строк на `<svg/>` - не оправдана.
//
// Неудобно только, что приходится поддерживать руками этот список имён.

const _tempSvgsMap = {
  AddReaction: svgs.AddReaction,
  Alarm: svgs.Alarm,
  Alert: svgs.Alert,
  AlignJustify: svgs.AlignJustify,
  AlignLeft: svgs.AlignLeft,
  AlignMidH: svgs.AlignMidH,
  AlignRight: svgs.AlignRight,
  ArrowDownCircle: svgs.ArrowDownCircle,
  ArrowLeftCircle: svgs.ArrowLeftCircle,
  ArrowRightCircle: svgs.ArrowRightCircle,
  ArrowUpCircle: svgs.ArrowUpCircle,
  ArrowFrom: svgs.ArrowFrom,
  ArrowDown: svgs.ArrowDown,
  ArrowLeft: svgs.ArrowLeft,
  ArrowRight: svgs.ArrowRight,
  ArrowTurnLeft: svgs.ArrowTurnLeft,
  ArrowTurnRight: svgs.ArrowTurnRight,
  ArrowUp: svgs.ArrowUp,
  ArrowUpLeft: svgs.ArrowUpLeft,
  ArrowUpLeftDouble: svgs.ArrowUpLeftDouble,
  ArrowUpRight: svgs.ArrowUpRight,
  AtSign: svgs.AtSign,
  Bank: svgs.Bank,
  Bookmark: svgs.Bookmark,
  Bookmark2: svgs.Bookmark2,
  BookWithMark: svgs.BookWithMark,
  Building: svgs.Building,
  Cake: svgs.Cake,
  Calendar: svgs.Calendar,
  Calendar2: svgs.Calendar2,
  CalendarClock: svgs.CalendarClock,
  CalendarDate: svgs.CalendarDate,
  CallIn: svgs.CallIn,
  CallLost: svgs.CallLost,
  CallOut: svgs.CallOut,
  Camera: svgs.Camera,
  Card1: svgs.Card1,
  Comment: svgs.Comment,
  Connect: svgs.Connect,
  ChartBarStand: svgs.ChartBarStand,
  ChartBarTrendUp: svgs.ChartBarTrendUp,
  ChartPie: svgs.ChartPie,
  Chat: svgs.Chat,
  Chat2: svgs.Chat2,
  Check: svgs.Check,
  CheckCircles: svgs.CheckCircles,
  CheckDouble: svgs.CheckDouble,
  Checklist: svgs.Checklist,
  CheckSquare: svgs.CheckSquare,
  CheckSquareOff: svgs.CheckSquareOff,
  ChevronDown: svgs.ChevronDown,
  ChevronLeft: svgs.ChevronLeft,
  ChevronRight: svgs.ChevronRight,
  ChevronUp: svgs.ChevronUp,
  ChevronDoubleDown: svgs.ChevronDoubleDown,
  ChevronDoubleLeft: svgs.ChevronDoubleLeft,
  ChevronDoubleRight: svgs.ChevronDoubleRight,
  ChevronDoubleUp: svgs.ChevronDoubleUp,
  ClaimCircle: svgs.ClaimCircle,
  Clip: svgs.Clip,
  Clock: svgs.Clock,
  Clock2: svgs.Clock2,
  Contract: svgs.Contract,
  Copy: svgs.Copy,
  Copy1: svgs.Copy1,
  Cross: svgs.Cross,
  CrossCircle: svgs.CrossCircle,
  CustomerTask: svgs.CustomerTask,
  DeleteLeft: svgs.DeleteLeft,
  DotsHorizontal: svgs.DotsHorizontal,
  DotsVertical: svgs.DotsVertical,
  Down: svgs.Down,
  Left: svgs.Left,
  Right: svgs.Right,
  Up: svgs.Up,
  Download: svgs.Download,
  DragHorizontal: svgs.DragHorizontal,
  DragVertical: svgs.DragVertical,
  Edit: svgs.Edit,
  Error: svgs.Error,
  Exclamation: svgs.Exclamation,
  ExternalLink: svgs.ExternalLink,
  FieldCheckbox: svgs.FieldCheckbox,
  FieldDate: svgs.FieldDate,
  FieldDateTime: svgs.FieldDateTime,
  FieldNumber: svgs.FieldNumber,
  FieldSelect: svgs.FieldSelect,
  FieldString: svgs.FieldString,
  FieldUrl: svgs.FieldUrl,
  FileBlank: svgs.FileBlank,
  FileWithStamp: svgs.FileWithStamp,
  Finance: svgs.Finance,
  FinancePig: svgs.FinancePig,
  Flag: svgs.Flag,
  Folder: svgs.Folder,
  Folder2: svgs.Folder2,
  FontHeight: svgs.FontHeight,
  Grid: svgs.Grid,
  GridCalc: svgs.GridCalc,
  Group: svgs.Group,
  Half: svgs.Half,
  Help: svgs.Help,
  Help2: svgs.Help2,
  Home: svgs.Home,
  Info: svgs.Info,
  KonturIco: svgs.KonturIco,
  KonturTask: svgs.KonturTask,
  Lang: svgs.Lang,
  Like: svgs.Like,
  Link: svgs.Link,
  Loader: svgs.Loader,
  Location: svgs.Location,
  Mail: svgs.Mail,
  Mail2: svgs.Mail2,
  Mail3: svgs.Mail3,
  MailInbox: svgs.MailInbox,
  MailSend: svgs.MailSend,
  Message: svgs.Message,
  MessageNew: svgs.MessageNew,
  Mic: svgs.Mic,
  Minus: svgs.Minus,
  NewTaskAlert: svgs.NewTaskAlert,
  Notepad: svgs.Notepad,
  OneS: svgs.OneS,
  PauseCircle: svgs.PauseCircle,
  Phone: svgs.Phone,
  Phone2: svgs.Phone2,
  PhoneCall: svgs.PhoneCall,
  PhoneOff: svgs.PhoneOff,
  Picture: svgs.Picture,
  PictureFloat: svgs.PictureFloat,
  PictureFloatOff: svgs.PictureFloatOff,
  Play: svgs.Play,
  Plus: svgs.Plus,
  PlusCircle: svgs.PlusCircle,
  QrCode: svgs.QrCode,
  Recovery: svgs.Recovery,
  Refresh: svgs.Refresh,
  Relations1: svgs.Relations1,
  Repeat: svgs.Repeat,
  Rotate: svgs.Rotate,
  RotateLeft: svgs.RotateLeft,
  RotateRight: svgs.RotateRight,
  Rub: svgs.Rub,
  Sbis: svgs.Sbis,
  Scale: svgs.Scale,
  Search: svgs.Search,
  Send: svgs.Send,
  Send1: svgs.Send1,
  Send2: svgs.Send2,
  Settings: svgs.Settings,
  Shield: svgs.Shield,
  Sidebar: svgs.Sidebar,
  Smile: svgs.Smile,
  Star: svgs.Star,
  StarCircle: svgs.StarCircle,
  Stop: svgs.Stop,
  Success: svgs.Success,
  SumSigma: svgs.SumSigma,
  Task: svgs.Task,
  TaskAdd: svgs.TaskAdd,
  TaskNew: svgs.TaskNew,
  Tasks: svgs.Tasks,
  Telegram: svgs.Telegram,
  Telegram2: svgs.Telegram2,
  Timers: svgs.Timers,
  Trash: svgs.Trash,
  Typing: svgs.Typing,
  Upload: svgs.Upload,
  User1: svgs.User1,
  UserBlock: svgs.UserBlock,
  UserCheck: svgs.UserCheck,
  UserGroup: svgs.UserGroup,
  UserTask: svgs.UserTask,
  UserUnBlock: svgs.UserUnBlock,
  Bold: svgs.Bold,
  Italic: svgs.Italic,
  Underline: svgs.Underline,
  Unread: svgs.Unread,
  Video: svgs.Video,
  View: svgs.View,
  ViewOff: svgs.ViewOff,
  Whatsapp: svgs.Whatsapp,
  Wizard1: svgs.Wizard1,
  WorkedTask: svgs.WorkedTask,
} as const;

type IconName = keyof typeof _tempSvgsMap;

export type IconRef = IconName | ReactElement;

export interface IIconProps extends HTMLAttributes<HTMLElement> {
  type: IconRef;
}

const Icon: FC<IIconProps> = ({ type, ...rest }) => {
  let el: ReactElement | boolean;
  if (typeof type === 'string') {
    const C = _tempSvgsMap[type];
    el = C ? <C /> : process.env.NODE_ENV === 'development' && <code>{type}</code>;
  } else {
    el = type;
  }

  return <IconContainer {...rest}>{el}</IconContainer>;
};

export default Icon;
