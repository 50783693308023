import { ComponentType, FC, Suspense, useMemo } from 'react';
import cl from './SettingsPage.module.scss';
import { TabProps, Tabs } from 'ui/tabs';
import { Redirect } from 'react-router-dom';
import LoadingPage from '../LoadingPage';
import { useTranslation } from 'react-i18next';

export interface SettingsPageProps extends TabProps {
  component: ComponentType;
}

interface Props {
  tabs: SettingsPageProps[];
  tab?: string;
  defaultTab: string;
  route: string;
}

export const SettingsPage: FC<Props> = ({ tabs, tab, route, defaultTab }) => {
  /*const { t } =*/ useTranslation(['profile', 'account']);
  const current = tabs.find((c) => c.value === tab);
  const _tabs = useMemo(() => tabs.map(({ component, ...rest }): TabProps => rest), [tabs]);

  if (!current) return <Redirect to={`${route}/${defaultTab}`} />;

  return (
    <div className={cl.root}>
      <Tabs className={cl.tabs} tabs={_tabs} current={current.value} />
      <Suspense fallback={<LoadingPage />}>
        <current.component />
      </Suspense>
    </div>
  );
};
