import { dataOnly } from 'api/instance';
import { CompanyId } from 'models/company/types';
import { ApiResponse2 } from 'api/types';
import { UserId } from 'models/user/types';
import { MailCredentialsId } from 'models/mail-credentials/types';
import { MailCredentialsEmail, MailCredentialsUsers } from 'models/mail-credentials-users/types';

export interface ListParams {
  id: MailCredentialsId;
  params: {
    bookkeeper_team_id: CompanyId;
  };
}

export interface UpdateParams {
  id: MailCredentialsId;
  data: {
    bookkeeper_team_id: CompanyId;
    users: UserId[];
  };
}

export interface EmailListParams {
  bookkeeper_team_id: CompanyId;
}

export const apiMailCredentialsUsers = {
  /** Список пользователей, имеющих доступ к почте*/
  list: ({ id, params }: ListParams) =>
    dataOnly.get<null, ApiResponse2<MailCredentialsUsers>>(`/api2/mail-credentials/${id}/users`, {
      params,
    }),
  /** Обновить*/
  update: ({ id, data }: UpdateParams) =>
    dataOnly.put<null, ApiResponse2<unknown>>(`/api2/mail-credentials/${id}/users`, data),
  /** Список ящиков, которые разрешено читать пользователю */
  emailList: (params: EmailListParams) =>
    dataOnly.get<null, ApiResponse2<MailCredentialsEmail[] | null>>(
      `/api2/mail-credentials/for-user`,
      {
        params,
      },
    ),
};
