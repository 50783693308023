import {
  combine,
  createEffect,
  createEvent,
  createStore,
  EffectParams,
  merge,
  restore,
  sample,
} from 'effector';
import { createGate, useStoreMap } from 'effector-react';
import i18next from 'i18next';
import isequal from 'lodash.isequal';
import { debounce } from 'patronum';
import { Trans } from 'react-i18next';
import * as RoArray from '@cubux/readonly-array';
import * as RoMap from '@cubux/readonly-map';
import * as RoSet from '@cubux/readonly-set';
import ApiServiceUnavailable from 'api/errors/503';
import {
  apiChatAccessGet,
  apiChatAccessUpdateV2,
  apiChatDelete,
  apiChatFetch,
  apiChatList,
  apiChatsSave,
  apiChatUpdate,
  apiInstanceCreate,
  apiInstanceDelete,
  apiInstanceList,
  apiInstanceLogout,
  apiInstanceRefresh,
  apiInstanceRestart,
  apiInstanceUpdate,
  isConflictRetryAfter,
} from 'api/request/wagreen/admin';
import { EMPTY_ARRAY, EMPTY_MAP, EMPTY_SET } from 'constants/utils';
import { getDateFormat } from 'hooks/i18n';
import { DateFormat } from 'ui/display';
import { promptFn, showToast, showToastError, ToastDuration, ToastTypes } from 'ui/feedback';
import {
  CentPrivateEventWaGreenChats,
  CentPrivateEventWaGreenInstanceState,
} from '../cent/types-wagreen';
import { $currentCompanyId } from '../company';
import { $customersMap } from '../customer';
import { CustomerId } from '../customer/types';
import { UserId } from '../user/types';
import { pendingMap } from '../utils/pendingMap';
import { withBookkeeperFx } from '../utils/withBookkeeperFx';
import {
  isGoodState,
  WaGreenGroupAccess,
  WaGreenGroupAdmin,
  WaGreenGroupId,
  WaGreenGroupRemoteId,
  WaGreenInstance,
  WaGreenInstanceEditForm,
  WaGreenInstanceId,
  WithWaGreenGroupId,
  WithWaGreenInstanceId,
} from './types';

export const WaGreenSetupGate = createGate<{ keepAfterClose?: boolean }>();
const resetData = sample({
  source: WaGreenSetupGate.close,
  filter: (p) => !p?.keepAfterClose,
});

export const editInstance = createEvent<WithWaGreenInstanceId & WaGreenInstanceEditForm>();
export const logoutInstance = createEvent<WaGreenInstanceId>();
export const refreshInstance = createEvent<WaGreenInstanceId>();
export const restartInstance = createEvent<WaGreenInstanceId>();
export const deleteInstance = createEvent<WaGreenInstanceId>();
export const toggleInstance = createEvent<WithWaGreenInstanceId & { opened: boolean }>();
export const receivedWaGreenInstanceState = createEvent<CentPrivateEventWaGreenInstanceState>();
export const receivedWaGreenChatChanged = createEvent<CentPrivateEventWaGreenChats>();
// export const updateChatAccess = createEvent<WithWaGreenInstanceId & WaGreenGroupAccessForm>();
export const changeGroupAccessCustomers = createEvent<
  WithWaGreenInstanceId & WithWaGreenGroupId & { c: readonly CustomerId[] }
>();
export const changeGroupAccessUsers = createEvent<
  WithWaGreenInstanceId & WithWaGreenGroupId & { u: readonly UserId[] }
>();
export const instanceAuthModalClose = createEvent<WithWaGreenInstanceId>();

const fetchGroups = createEvent<WithWaGreenInstanceId>();

// ===============================================================

const fetchInstancesFx = withBookkeeperFx(createEffect(apiInstanceList));
const _createInstanceFx = createEffect(apiInstanceCreate);
export const createInstanceFx = withBookkeeperFx(_createInstanceFx);
export const updateInstanceFx = withBookkeeperFx(createEffect(apiInstanceUpdate));
const logoutInstanceFx = withBookkeeperFx(createEffect(apiInstanceLogout));
const refreshInstanceFx = withBookkeeperFx(createEffect(apiInstanceRefresh));
const restartInstanceFx = withBookkeeperFx(createEffect(apiInstanceRestart));
const deleteInstanceFx = withBookkeeperFx(createEffect(apiInstanceDelete));

sample({
  clock: [WaGreenSetupGate.open, $currentCompanyId, receivedWaGreenInstanceState],
  filter: WaGreenSetupGate.status,
  fn: () => {},
  target: fetchInstancesFx,
});
sample({
  source: editInstance,
  filter: WaGreenSetupGate.status,
  target: updateInstanceFx,
});
sample({
  source: refreshInstance,
  filter: WaGreenSetupGate.status,
  fn: (instance_id) => ({ instance_id }),
  target: refreshInstanceFx,
});
sample({
  source: restartInstance,
  filter: WaGreenSetupGate.status,
  fn: (instance_id) => ({ instance_id }),
  target: restartInstanceFx,
});
sample({
  source: logoutInstance,
  filter: WaGreenSetupGate.status,
}).watch(async (id) => {
  if (await promptFn(<Trans i18nKey="account:integrations.whatsapp.message.ConfirmToLogout" />)) {
    logoutInstanceFx({ instance_id: id });
  }
});
sample({
  source: deleteInstance,
  filter: WaGreenSetupGate.status,
}).watch(async (id) => {
  if (
    await promptFn(
      <Trans i18nKey="account:integrations.whatsapp.message.ConfirmToDeleteConnection" />,
    )
  ) {
    deleteInstanceFx({ instance_id: id });
  }
});

const _instanceCreated = sample({
  clock: _createInstanceFx.done,
  source: { id: $currentCompanyId, open: WaGreenSetupGate.status },
  filter: ({ id, open }, { params: { bookkeeper_team_id } }) =>
    open && Boolean(id) && id === bookkeeper_team_id,
  fn: (_, c) => c.result.data,
});

export const $instancesLoading = fetchInstancesFx.pending;
export const $instanceCreatePending = createInstanceFx.pending;
export const $instanceUpdatePending = updateInstanceFx.pending;
export const $instanceLogoutPending = logoutInstanceFx.pending;
export const $instanceRefreshPending = refreshInstanceFx.pending;
export const $instanceRestartPending = restartInstanceFx.pending;
export const $instanceDeletePendingMap = pendingMap(deleteInstanceFx, (p) => p.instance_id);

merge([
  fetchInstancesFx.failData,
  updateInstanceFx.failData,
  logoutInstanceFx.failData,
  refreshInstanceFx.failData,
  refreshInstanceFx.failData,
  deleteInstanceFx.failData,
]).watch(showToastError);
createInstanceFx.failData.watch((e) => {
  if (e instanceof ApiServiceUnavailable) {
    const fmt = getDateFormat();
    showToast(
      ToastTypes.error,
      e.retryAfter ? (
        <Trans
          i18nKey="account:integrations.whatsapp.message.ErrorCreateRetryAfter"
          defaultValue="Cannot create, try after {after}"
          values={{ after: fmt(e.retryAfter, 'p') }}
        />
      ) : (
        <Trans i18nKey="account:integrations.whatsapp.message.ErrorCreateRetryLater" />
      ),
      ToastDuration.LONGER,
    );
  }
});

restartInstanceFx.failData.watch((e) => {
  const retryAt = isConflictRetryAfter(e);
  if (retryAt) {
    showToast(
      ToastTypes.error,
      <>
        {i18next.t('account:integrations.whatsapp.message.ErrorRestartAfter')}{' '}
        <DateFormat date={retryAt} format="Ppp" />
      </>,
    );
  } else {
    showToastError(e);
  }
});

export const $instances = createStore<readonly WaGreenInstance[]>(
  EMPTY_ARRAY,
  //[],
)
  .reset($currentCompanyId, resetData)
  .on(fetchInstancesFx.doneData, (_, { data }) => data)
  .on(_instanceCreated, (list, inst) => [...list, inst])
  .on(merge([updateInstanceFx.doneData, refreshInstanceFx.doneData]), (list, { data }) =>
    list.map((i) => (i.id === data.id ? data : i)),
  )
  .on(deleteInstanceFx.done, (list, { params: { instance_id } }) =>
    RoArray.removeMatch(list, (i) => i.id === instance_id),
  )
  .on(receivedWaGreenInstanceState, (list, { instanceId, ...upd }) =>
    RoArray.updateMatch(
      list,
      (i) => i.id === instanceId,
      (i) => {
        const next = { ...i, ...upd };
        return isequal(i, next) ? i : next;
      },
    ),
  );

const _$instancesIds = $instances.map((list) => new Set(list.map((i) => i.id)));

export const $instanceOpened = createStore<ReadonlySet<WaGreenInstanceId>>(EMPTY_SET)
  .on(_$instancesIds, RoSet.intersection)
  .on(
    sample({
      clock: toggleInstance,
      source: _$instancesIds,
      filter: (existing, p) => existing.has(p.instance_id),
      fn: (_, p) => p,
    }),
    (set, { instance_id, opened }) => RoSet.toggle(set, instance_id, opened),
  )
  .on(_instanceCreated, (set, i) => RoSet.add(set, i.id));

// ===============================================================

// созданные НЕавторизованные инстансы, которые надо будет
// удалить при закрытии (= отмене) диалога с QR кодом
const _$instancesAuth = createStore<ReadonlySet<WaGreenInstanceId>>(EMPTY_SET)
  .on(_$instancesIds, RoSet.intersection)
  .on(_instanceCreated, (set, { id }) => RoSet.add(set, id))
  .on(receivedWaGreenInstanceState, (set, { instanceId, state }) => {
    if (isGoodState(state)) {
      return RoSet.remove(set, instanceId);
    }
  });

sample({
  clock: instanceAuthModalClose,
  source: _$instancesAuth,
  filter: (auths, { instance_id }) => auths.has(instance_id),
  fn: (_, c) => c,
  target: deleteInstanceFx,
});

export const $authId = combine(
  _$instancesAuth,
  $instances,
  (set, instances): WaGreenInstanceId | null => {
    if (set.size) {
      for (const instanceId of set) {
        if (instances.some((i) => i.id === instanceId)) {
          return instanceId;
        }
      }
    }
    return null;
  },
);

export const setReconnectInstanceId = createEvent<WaGreenInstanceId | null>();
export const resetReconnectInstanceId = createEvent<any>();
export const $reconnectInstanceId = restore(setReconnectInstanceId, null).reset(
  resetData,
  resetReconnectInstanceId,
);

// ===============================================================

export const contactsConfigOpen = createEvent<WaGreenInstanceId>();
export const contactsConfigReload = createEvent<any>();
export const contactsConfigCancel = createEvent<any>();
export const contactsConfigSave = createEvent<any>();
export const $contactsConfigOpenedId = restore(contactsConfigOpen, null).reset(
  contactsConfigCancel,
);
export const contactsConfigToggle = createEvent<{
  remote_id: WaGreenGroupRemoteId;
  toggle?: boolean;
}>();

const fetchContactsFx = withBookkeeperFx(createEffect(apiChatFetch));
const saveContactsFx = withBookkeeperFx(createEffect(apiChatsSave));
export const $contactsSavePending = saveContactsFx.pending;
export const $contactsRemoteLoading = fetchContactsFx.pending;
export const $contactsRemote = restore(
  fetchContactsFx.doneData.map(({ data }) => data),
  EMPTY_ARRAY,
).reset($contactsConfigOpenedId);

export const $contactsToAdd = createStore<ReadonlySet<WaGreenGroupRemoteId>>(EMPTY_SET)
  .reset($contactsConfigOpenedId)
  .on(contactsConfigToggle, (set, { remote_id, toggle }) => RoSet.toggle(set, remote_id, toggle));

sample({
  clock: [
    contactsConfigOpen,
    sample({
      clock: contactsConfigReload,
      source: $contactsConfigOpenedId,
      filter: Boolean,
    }),
  ],
  fn: (instance_id) => ({ instance_id }),
  target: fetchContactsFx,
});

sample({
  clock: contactsConfigSave,
  source: {
    instance_id: $contactsConfigOpenedId,
    add: $contactsToAdd,
  },
  filter: (p) => Boolean(p.instance_id),
  fn: ({ instance_id, add }) => ({ instance_id: instance_id!, add_id: Array.from(add) }),
  target: saveContactsFx,
});

sample({ source: saveContactsFx.done, target: contactsConfigCancel });

merge([fetchContactsFx.failData, saveContactsFx.failData]).watch(showToastError);

// ===============================================================

sample({
  clock: [
    merge([receivedWaGreenInstanceState, receivedWaGreenChatChanged]).map((p) => ({
      instance_id: p.instanceId,
    })),
    sample({ source: toggleInstance, filter: (p) => p.opened }),
    merge([
      updateInstanceFx.done,
      refreshInstanceFx.done,
      // _replaceInstanceFx.done,
    ]).map(({ params }) => params),
  ],
  filter: WaGreenSetupGate.status,
  fn: ({ instance_id }) => ({ instance_id }),
  target: fetchGroups,
});

const fetchGroupsFx = withBookkeeperFx(createEffect(apiChatList));
const fetchGroupsAccessFx = withBookkeeperFx(createEffect(apiChatAccessGet));
export const updateGroupFx = withBookkeeperFx(createEffect(apiChatUpdate));
export const updateGroupAccessV2Fx = withBookkeeperFx(createEffect(apiChatAccessUpdateV2));
const deleteGroupFx = withBookkeeperFx(createEffect(apiChatDelete));
export const deleteGroup = createEvent<EffectParams<typeof deleteGroupFx>>();

merge([
  fetchGroupsFx.failData,
  fetchGroupsAccessFx.failData,
  updateGroupFx.failData,
  deleteGroupFx.failData,
  updateGroupAccessV2Fx.failData,
]).watch(showToastError);
merge([updateGroupFx.done, updateGroupAccessV2Fx.done]).watch(() =>
  showToast(ToastTypes.success, <Trans i18nKey="ui:messages.successSavedChanges" />),
);

export const $deleteGroupPending = pendingMap(deleteGroupFx, (p) => p.group_id);
deleteGroup.watch(async (p) => {
  if (await promptFn(i18next.t('account:integrations.whatsapp.message.ConfirmToDeleteContact'))) {
    await deleteGroupFx(p);
  }
});

const _getInstanceId = ({ instance_id }: WithWaGreenInstanceId) => instance_id;
const _loadingGroups = pendingMap(fetchGroupsFx, _getInstanceId);
const _loadingGroupsAccess = pendingMap(fetchGroupsAccessFx, _getInstanceId);
export const $groupsLoading = combine(_loadingGroups, _loadingGroupsAccess, (a, b) =>
  RoSet.union(a, b),
);

sample({
  source: debounce({
    source: sample({
      clock: fetchGroups,
      source: $groupsLoading,
      filter: (p, f) => !p.has(f.instance_id),
      fn: (_, c) => c,
    }),
    timeout: 200,
  }),
  target: [fetchGroupsFx, fetchGroupsAccessFx],
});

sample({
  clock: [
    sample({
      clock: changeGroupAccessCustomers,
      source: $customersMap,
      fn: (custMap, { group_id, c, ...i }) => ({
        ...i,
        groups: [
          {
            g: [group_id],
            c: { clear: true, ins: c },
            u: {
              clear: true,
              ins: Array.from(
                c.reduce((s, cId) => {
                  custMap.get(cId)?.contractorEmployees?.forEach((uId) => {
                    s.add(uId.id);
                  });
                  return s;
                }, new Set<UserId>()),
              ),
            },
          },
        ],
      }),
    }),
    sample({
      clock: changeGroupAccessUsers,
      fn: ({ group_id, u, ...i }) => ({
        ...i,
        groups: [{ g: [group_id], u: { clear: true, ins: u } }],
      }),
    }),
  ],
  target: updateGroupAccessV2Fx,
});

export const $groupsOf = createStore<ReadonlyMap<WaGreenInstanceId, readonly WaGreenGroupAdmin[]>>(
  EMPTY_MAP,
)
  .reset(resetData)
  .on($instanceOpened.updates, (map, opened) => RoMap.filter(map, (_, k) => opened.has(k)))
  .on(
    [fetchGroupsFx.done, saveContactsFx.done],
    (map, { params: { instance_id }, result: { data } }) => RoMap.set(map, instance_id, data),
  )
  .on(updateGroupFx.done, (map, { params: { instance_id }, result: { data } }) =>
    RoMap.update(map, instance_id, (list) =>
      RoArray.updateMatch(
        list,
        (g) => g.id === data.id,
        () => data,
      ),
    ),
  )
  .on(deleteGroupFx.done, (map, { params: { instance_id, group_id } }) =>
    RoMap.update(map, instance_id, (list) => RoArray.removeMatch(list, (g) => g.id === group_id)),
  );

export type WaGreenGroupsAccessMap = ReadonlyMap<WaGreenGroupId, WaGreenGroupAccess>;
export const $groupsAccessOf = createStore<ReadonlyMap<WaGreenInstanceId, WaGreenGroupsAccessMap>>(
  EMPTY_MAP,
)
  .reset(resetData)
  .on(_$instancesIds, (map, existing) => RoMap.filter(map, (_, k) => existing.has(k)))
  .on(fetchGroupsAccessFx.done, (map, { params: { instance_id }, result: { data } }) =>
    RoMap.set(
      map,
      instance_id,
      RoMap.fromArray(data, (g) => g.chat_id),
    ),
  )
  .on(updateGroupAccessV2Fx.done, (map, { params: { instance_id, groups }, result: { data } }) => {
    groups.forEach(({ g }) => {
      g.forEach((gId) => {
        map = RoMap.removeDeep(map, [instance_id, gId]);
      });
    });
    data.forEach((f) => {
      map = RoMap.setDeep(map, [instance_id, f.chat_id], f);
    });
    return map;
  });

export const $groupsUpdating = updateGroupFx.pending;
export const $groupsAccessUpdatingMap = pendingMap(
  updateGroupAccessV2Fx,
  (p) => new Set(p.groups.map((g) => g.g).flat()),
);

export const useInstanceChatsWithAccess = (iid: WaGreenInstanceId) => ({
  chats: useStoreMap({
    store: $groupsOf,
    keys: [iid],
    fn: (m, [id]) => m.get(id) ?? EMPTY_ARRAY,
  }),

  access: useStoreMap({
    store: $groupsAccessOf,
    keys: [iid],
    fn: (m, [id]) => m.get(id) ?? (EMPTY_MAP as never),
  }),

  loading: useStoreMap({
    store: $groupsLoading,
    keys: [iid],
    fn: (m, [id]) => m.has(id),
  }),
});
