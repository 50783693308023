import { containerWithHtml } from '../dom';

// ------------------------------------------------------------------------
// скопировано из соседнего htmlToWhatsAppMessage, который был сделан ранее
// ------------------------------------------------------------------------

// какие элементы полностью игнорировать
const SKIP_ELEMENTS = new Set(['SCRIPT', 'STYLE', 'NOSCRIPT']);
// блоковые элементы - влияют на появление переноса строки для дальнейшего текста
const BLOCK_ELEMENTS = new Set(['DIV', 'P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'BLOCKQUOTE']);

export const htmlToPlainText = (html: string): string => {
  // результат (спасибо, кэп). добавляется блоками
  let result = '';
  // текущая выходная строка текста (блок/абзац)
  let line = '';

  function addText(inputText: string) {
    const text = inputText.replace('\n', ' ');
    line += text;
  }

  // function endOfLine() {}

  function flushLine() {
    line = line.trimEnd();
    // endOfLine();
    if (line) {
      result += line;
      result += '\n';
      line = '';
    }
  }
  function br() {
    line = line.trimEnd();
    // endOfLine();
    result += line;
    result += '\n';
    line = '';
  }
  function blockStart() {
    flushLine();
  }
  function blockEnd() {
    flushLine();
  }

  function read(container: Element) {
    if (SKIP_ELEMENTS.has(container.tagName)) {
      return;
    }
    if (container.tagName === 'BR') {
      br();
      return;
    }
    const isBlock = BLOCK_ELEMENTS.has(container.tagName);
    if (isBlock) {
      blockStart();
    }
    for (let node = container.firstChild; node; node = node.nextSibling) {
      switch (node.nodeType) {
        case document.TEXT_NODE:
          addText((node as Text).wholeText);
          break;
        case document.ELEMENT_NODE:
          read(node as Element);
          break;
      }
    }
    if (isBlock) {
      blockEnd();
      // } else if (container.tagName === 'A') {
      //   if (needAppendLinkHref(container)) {
      //     const href = container.getAttribute('href');
      //     addText(' <' + href + '>');
      //   }
    }
  }

  read(containerWithHtml(html));

  return result.trimEnd();
};
