import { FC, useCallback } from 'react';
import cl from './LangSwitcher.module.scss';
import flagCZ from 'assets/img/flags/cz.svg';
import flagGB from 'assets/img/flags/gb.svg';
import flagRU from 'assets/img/flags/ru.svg';
import flagDE from 'assets/img/flags/de.svg';
import cn from 'classnames';
import { useLanguage } from 'hooks/i18n';
import { languages } from 'i18n';
import i18next from 'i18next';

interface Props {}

const FLAGS_SRS: any = {
  cs: flagCZ,
  en: flagGB,
  ru: flagRU,
  de: flagDE,
};

export const LangSwitcher: FC<Props> = () => {
  const language = useLanguage();

  const handleClick = useCallback(
    (lang: string) => () => {
      i18next.changeLanguage(lang).catch(console.log);
    },
    [],
  );

  return (
    <div className={cl.root}>
      {languages.map((c) => (
        <img
          key={c.language}
          src={FLAGS_SRS[c.language]}
          alt=""
          className={cn(c.language === language && cl.active)}
          onClick={handleClick(c.language)}
        />
      ))}
    </div>
  );
};
