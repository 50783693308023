import { dataOnly } from 'api/instance';
import { API_TASK_REMOTE, ApiResponse2 } from 'api/types';
import { WithBookkeeper } from 'api/types/request';
import {
  TaskTemplate,
  TaskTemplateCreateForm,
  TaskTemplatesListMode,
  TaskTemplateUpdateForm,
  WithTaskTemplateId,
} from 'models/task-template/types';

export interface TaskTemplateListParams {
  mode: TaskTemplatesListMode;
}

export const apiTaskTemplateList = (params: WithBookkeeper & TaskTemplateListParams) =>
  dataOnly.get<null, ApiResponse2<readonly TaskTemplate[]>>(`${API_TASK_REMOTE}/tasks/templates`, {
    params,
  });

export const apiTaskTemplateCreate = ({
  tags,
  files,
  ...form
}: WithBookkeeper & TaskTemplateCreateForm) =>
  dataOnly.post<null, ApiResponse2<TaskTemplate>>(`${API_TASK_REMOTE}/tasks/templates`, {
    ...form,
    tags: tags?.map((o) => o.id),
    files: files?.map((f) => f.fileId),
  });

export const apiTaskTemplateUpdate = ({
  _id,
  tags,
  files,
  ...form
}: WithTaskTemplateId & WithBookkeeper & TaskTemplateUpdateForm) =>
  dataOnly.patch<null, ApiResponse2<TaskTemplate>>(`${API_TASK_REMOTE}/tasks/templates/${_id}`, {
    ...form,
    tags: tags?.map((o) => o.id),
    files: files?.map((f) => f.fileId),
  });

export const apiTaskTemplateDelete = ({ _id, ...data }: WithTaskTemplateId & WithBookkeeper) =>
  dataOnly.delete<null, void>(`${API_TASK_REMOTE}/tasks/templates/${_id}`, { data });
