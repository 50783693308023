import cn from 'classnames';
import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIsMounted, useRefHandlers } from 'hooks/utils';
import { durationFormat, durationSplit } from 'utils/date';
import { hasProperty } from 'utils/object';
import { TextButton2 } from '../../button';
import { ButtonCorePropsOnlyButton } from '../../button/core';
import { showToast, showToastError, ToastDuration, ToastTypes } from '../../feedback';
import Icon from '../../Icon';
import cl from './AudioRecordButton.module.scss';

const errAborted = new Error('Recording aborted');
const errEmpty = new Error('Nothing was recorded');
const errFailed = new Error('Recording failed');
const wantAudio: MediaStreamConstraints = {
  audio: true,
};

interface Props extends Omit<ButtonCorePropsOnlyButton, 'icon'> {
  onRecordDone?: (record: Blob) => void;
}

export const AudioRecordButton: FC<Props> = ({ onRecordDone, ...rest }) => {
  const { onClick } = rest;
  const { t } = useTranslation('ui');

  const isMounted = useIsMounted();
  const [stop, setStop] = useState<() => void>();
  const [abort, setAbort] = useState<() => void>();
  const [pBlob, setPBlob] = useState<Promise<Blob>>();
  const [startedAt, setStartedAt] = useState(0);
  useEffect(() => abort, [abort]);

  const refOnDone = useRefHandlers(onRecordDone);
  useEffect(() => {
    if (!pBlob) return;
    pBlob
      .then((b) => {
        if (!isMounted()) return;
        setPBlob(undefined);
        setAbort(undefined);
        setStop(undefined);
        setStartedAt(0);
        refOnDone.current?.(b);
      })
      .catch((e) => {
        // console.log('>> pBlob.catch', e);
        if (e === errAborted) return;
        if (!isMounted()) return;
        if (e instanceof DOMException) {
          return showToast(
            ToastTypes.error,
            <Trans
              i18nKey="ui:recordVoice.errorDOM"
              defaults="Cannot record: {error}"
              values={{ error: e.name }}
            />,
            ToastDuration.LONGER,
          );
        }
        showToastError(e);
      });
  }, [pBlob, refOnDone, isMounted]);

  const handleRecord = useCallback<MouseEventHandler<HTMLElement>>(
    (ce) => {
      if (onClick && !ce.isPropagationStopped()) onClick(ce);
      if (ce.isDefaultPrevented()) return;

      setAbort(undefined);
      setStop(undefined);
      const pb = (async () => {
        const stream = await window.navigator.mediaDevices.getUserMedia(wantAudio);
        if (!isMounted()) return Promise.reject(errAborted);

        return new Promise<Blob>((resolve, reject) => {
          const chunks: Blob[] = [];
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorder.onerror = (e) => {
            // console.log('>> mediaRecorder.onerror', e);
            const em: unknown = e;
            if (hasProperty(em, 'error')) {
              return reject(em.error);
            }
            console.error('recording failed', e);
            reject(errFailed);
          };
          mediaRecorder.ondataavailable = (e) => {
            // console.log('>> mediaRecorder.ondataavailable', e);
            chunks.push(e.data);
          };
          mediaRecorder.onstop = (/*e*/) => {
            // console.log('>> mediaRecorder.onstop', e);
            if (!isMounted()) return reject(errAborted);
            if (!chunks.length) return reject(errEmpty);
            resolve(new Blob(chunks, { type: chunks[0].type }));
          };
          mediaRecorder.onstart = (/*e*/) => {
            // console.log('>> mediaRecorder.onstart', e);
            if (!isMounted()) return;
            setStartedAt(Date.now());
          };
          mediaRecorder.start();

          setAbort(() => {
            return () => {
              reject(errAborted);
              mediaRecorder.stop();
            };
          });
          setStop(() => () => {
            mediaRecorder.stop();
          });
        });
      })();
      setPBlob(pb);
    },
    [onClick, isMounted],
  );

  const handleStop = useMemo(() => stop && (() => stop()), [stop]);

  return (
    <TextButton2
      {...rest}
      className={cn(rest.className, cl.root)}
      type="button"
      icon={
        handleStop ? (
          <>
            <Icon type="Mic" />
            <Icon type="Stop" />
          </>
        ) : (
          'Mic'
        )
      }
      uiColor={handleStop ? 'danger' : rest.uiColor}
      onClick={handleStop || handleRecord}
      data-tip={
        handleStop
          ? t('ui:recordVoice.StopDone')
          : rest['data-tip'] ?? t('ui:recordVoice.StartMessage')
      }
    >
      {handleStop && <RecordingDuration since={startedAt} />}
    </TextButton2>
  );
};

const RecordingDuration: FC<{ since: number }> = ({ since }) => {
  const [duration, setDuration] = useState(0);
  useEffect(() => {
    if (!since) return;

    const id = setInterval(() => {
      setDuration(Math.round((Date.now() - since) / 1000));
    }, 1000);

    return () => {
      clearInterval(id);
      setDuration(0);
    };
  }, [since]);

  if (duration >= 3600) {
    return <>{durationFormat(durationSplit(duration))}</>;
  }
  const [, m, s] = durationSplit(duration);
  return <>{durationFormat([m, s])}</>;
};
