import { dataOnly } from 'api/instance';
import {
  UISCallForm,
  UISTokenSaveForm,
  UISTokenDeleteForm,
  UISTokenItem,
  UISCallNumberForm,
  UISDataFetchForm,
  UISEmployee,
  VNumberData,
} from 'models/integrations/uis/types';
import { ApiResponse2 } from 'api/types';
import { CompanyId } from 'models/company/types';

export const uisApi = {
  /** Список токенов компании */
  tokenList: (companyId: CompanyId) =>
    dataOnly.get<null, ApiResponse2<UISTokenItem[]>>(`/api3/tokens/${companyId}`),

  /** Звонок клиенту*/
  call: (data: UISCallForm) =>
    dataOnly.post<null, ApiResponse2<unknown>>(`/api3/calls/client`, data),

  /** Звонок на произвольный номер */
  callNumber: (data: UISCallNumberForm) =>
    dataOnly.post<null, ApiResponse2<unknown>>(`/api3/calls/phone`, data),

  /** Сохранить токен бухгалтера*/
  saveToken: (data: UISTokenSaveForm) =>
    dataOnly.post<null, ApiResponse2<unknown>>(`/api3/tokens`, data),

  /** Удалить токен бухгалтера*/
  deleteToken: (data: UISTokenDeleteForm) =>
    dataOnly.delete<null, ApiResponse2<unknown>>(`/api3/tokens`, { data }),

  /** Список сотрудников в UIS*/
  employees: (params: UISDataFetchForm) =>
    dataOnly.get<null, ApiResponse2<UISEmployee[]>>(`/api3/uis/employees`, { params }),

  /** Список виртуальных номеров UIS */
  vnumbers: (params: UISDataFetchForm) =>
    dataOnly.get<null, ApiResponse2<VNumberData[]>>(`/api3/uis/vnumbers`, { params }),
};
