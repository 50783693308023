import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';

const NO_SIZE = [undefined, undefined] as const;
type NoSize = typeof NO_SIZE;
type Size = readonly [width: number, height: number];

interface Options {}

export const useElementClientSize = (ref: RefObject<HTMLElement>, _?: Options): Size | NoSize => {
  const [size, setSize] = useState<Size>();

  const update = useCallback((w: number, h: number) => {
    // именно floor, потому что с round в пустом элементе с border дробные
    // пикселы 0.6 округляются до 1, и получается хрень (все 10к ячеек grid
    // влезают в 1 пиксель, да, давай страницу повесим)
    w = Math.floor(w);
    h = Math.floor(h);
    setSize((prev) => (prev && prev[0] === w && prev[1] === h ? prev : [w, h]));
  }, []);

  const ob = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (const en of entries) {
          // const r = en.borderBoxSize?.[0];
          // if (r) {
          //   // TODO: это на привычный вертикальный поток блоков, горизонтальный
          //   //   поток текста, так что в иной ситуации будет глупость
          //   //
          //   update(r.inlineSize, r.blockSize);
          // } else {
          const r = en.target.getBoundingClientRect();
          update(r.width, r.height);
          // }
        }
      }),
    [update],
  );

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    const r = el.getBoundingClientRect();
    update(r.width, r.height);

    // важен именно content-box
    //
    // я хотел дать возможность подавать сюда что-то иное через опции, но
    // в entries странное API, и я забил пока до востребования
    ob.observe(el, { box: 'content-box' });

    return () => {
      ob.unobserve(el);
      setSize(undefined);
    };
  }, [ref, update, ob]);

  // важно отличать отсутствие элемента от нулей
  return size || NO_SIZE;
};
