import { DateISO } from 'utils/types';

export const EMPTY_ARRAY = [];
export const EMPTY_MAP: ReadonlyMap<any, never> = new Map<any, never>();
export const EMPTY_SET: ReadonlySet<never> = new Set();

export const INVALID_DATE_111 = '0001-01-01' as DateISO;
export const ZERO_UUID = '00000000-0000-0000-0000-000000000000';

/**
 * Цвета для "категорий" на графиках
 *
 * В каких-то случаях надо показать просто значения по категориям
 * без ассоциативной привязки конкретного цвета к конкретной категории (как в
 * случае "выполнено" - зелёное, а "просрочено" - красное). Но в либе графиков,
 * видимо, нет своих встроенных цветов для этого. А по умолчанию без указания
 * цветов все категории получаются одного цвета (светло-серого), и такой график
 * не очень понятен, когда категорий больше 2.
 *
 * Цвета сгенерированы случайным образом:
 *
 * ```sh
 * php -r 'function c() { return mt_rand(0x4, 0xD); } for ($i=40; $i-- > 0; ) printf("\"#%01X%01X%01X\",\n", c(), c(), c());'
 * ```
 *
 * после чего руками (на глаз по квадратикам в IDE) удалены слишком яркие,
 * слишком светлые и схожие цвета.
 */
export const CHART_DATA_COLORS: readonly string[] = [
  '#B4D',
  '#797',
  '#977',
  '#56A',
  '#49D',
  '#C5A',
  '#C94',
  '#4B4',
  '#C46',
  '#DC7',
  '#C9A',
  '#76D',
  '#BBA',
  '#B76',
  '#5C6',
  '#9A5',
  '#BB4',
  '#465',
];

export const cycleChartColors = () => {
  let i = -1;
  return () => CHART_DATA_COLORS[(i = (i + 1) % CHART_DATA_COLORS.length)];
};
