import { useEffect } from 'react';
import { useStoreMap } from 'effector-react';
import { $currentDatesMap, Unit, watchUnit } from 'models/misc/currentTime';

export const useCurrentTime = (unit: Unit = 'd') => {
  useEffect(() => watchUnit(unit), [unit]);

  return useStoreMap({
    store: $currentDatesMap,
    keys: [unit],
    fn: (dates, [unit]) => dates.get(unit),
    defaultValue: new Date(),
  });
};
