import { format as formatOrig, Locale } from 'date-fns';
import i18next from 'i18next';
import { useMemo } from 'react';
import { languagesMap } from 'i18n';
import { parseDateStrict } from 'utils/date';
import { DateISO, DateTimeISO } from 'utils/types';
import useLanguageConfig from './useLanguageConfig';

export interface DateFormatFn {
  /**
   *
   * @param date
   * @param format
   * @see https://date-fns.org/v2.17.0/docs/format
   */
  (date: Date | number | DateISO | DateTimeISO, format?: string): string;
}

const mkDateFormat = (locale: Locale): DateFormatFn => {
  const options = { locale };
  return (date: Date | number | DateISO | DateTimeISO, format = 'P') =>
    formatOrig(typeof date === 'string' ? parseDateStrict(date) : date, format, options);
};

export const useDateFormat = (): DateFormatFn => {
  const { dateFns } = useLanguageConfig();
  return useMemo(() => mkDateFormat(dateFns), [dateFns]);
};

export const getDateFormat = () => mkDateFormat(languagesMap.get(i18next.language)!.dateFns);
