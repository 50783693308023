import { dataOnly, downloadInstance } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { WithBookkeeper } from 'api/types/request';
import {
  Timer,
  TimerCreateParams,
  TimersExportOwn,
  TimersListParams,
  TimerStartToggle,
  TimerToggle,
  TimerUpdateParams,
  WithTimerId,
} from 'models/timers/types';

const e = encodeURIComponent;

// Пока решили, что у Сотрудника может быть только один активный таймер. Т.е.
// нельзя делать сразу несколько дел параллельно в одну каску.
// Когда Сотрудник с активным таймером начинает новый в другой задаче,
// предыдущий останавливается.
export const apiTimersListActive = (params: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<readonly Timer[], true>>(`/api2/tasks/timers/active`, { params });

export const apiTimersListLatest = (params: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<readonly Timer[], true>>(`/api2/tasks/timers/latest`, { params });

export const apiTimersList = (params: TimersListParams) =>
  dataOnly.get<null, ApiResponse2<readonly Timer[], true>>('/api2/tasks/timers', { params });

export const apiTimersCreate = (form: TimerCreateParams) =>
  dataOnly.post<null, ApiResponse2<Timer>>('/api2/tasks/timers', form);

export const apiTimersExportPdf = (params: TimersExportOwn & WithBookkeeper) =>
  downloadInstance.get<null, string>(`/api2/tasks/timers/export`, { params });

export const apiTimerToggle = (form: TimerStartToggle) =>
  dataOnly.put<null, ApiResponse2<TimerToggle>>(`/api2/tasks/timers/toggle`, form);

export const apiTimerDelete = ({ timer_id, ...form }: WithBookkeeper & WithTimerId) =>
  dataOnly.delete<null, void>(`/api2/tasks/timers/${e(timer_id)}`, { data: form });

export const apiTimerUpdate = ({ timer_id, ...form }: TimerUpdateParams & WithTimerId) =>
  dataOnly.patch<null, ApiResponse2<Timer>>(`/api2/tasks/timers/${e(timer_id)}`, form);
