import { WithBookkeeper, WithCustomer, WithCustomerOptional } from 'api/types/request';
import { hasProperty } from 'utils/object';
import { DateISO, IdNumberOf } from 'utils/types';
import { TariffAdditionalServiceUnit } from '../tariffs/types';
import { Task, TaskId } from '../task/types';

export type TaskFinanceServiceId = IdNumberOf<'TaskFinanceService'>;
export interface TaskFinanceService {
  readonly id: TaskFinanceServiceId;
  title: string;
  price: number;
  unit_code: TariffAdditionalServiceUnit;
  quantity: number;
  cost: number;
}

export interface TaskIdentity {
  task_id: TaskId;
  is_typical: boolean;
}
export type TaskRef = WithCustomerOptional & TaskIdentity;
export type TaskFinanceTarget = WithBookkeeper & TaskRef;

export const isTaskRef = (o: unknown): o is TaskRef =>
  hasProperty(o, 'task_id') && typeof o.task_id === 'number';

export const newTaskRef = ({ id, is_typical, team_id }: Task): TaskRef => ({
  team_id,
  task_id: id,
  is_typical,
});

export interface TaskFinanceData {
  add_to_date: boolean;
  date: DateISO | null;
  plan_minutes: number;
  services: readonly TaskFinanceService[] | null;
}
export interface TaskFinance extends TaskRef, TaskFinanceData {}

// -----------

/**
 * Создание или обновление происходит прозрачно, опираясь на `id`.
 * Если `id` есть, значит обновление, иначе - создание.
 *
 * Для создания обязателен `title` и `unit_code`.
 */
export interface TaskFinanceServiceDataForm extends Partial<TaskFinanceService> {}
export interface TaskFinanceServiceForm extends TaskFinanceServiceDataForm {
  _key: number | string;
}

/**
 * Создание, обновление или удаление происходит прозрачно.
 * Когда все поля пусты, то нечего и хранить это, поэтому происходит удаление.
 *
 * Для создания обязательных полей нет.
 *
 * В обновлении можно указывать только то, изменилось.
 */
export interface TaskFinanceDataForm extends Partial<Omit<TaskFinanceData, 'services'>> {
  /**
   * Когда не указано, записи не изменяются.
   *
   * Когда указано, будут удалены те, чьих id не было передано, обновлены те,
   * где есть id, и созданы остальные, где нет id. Неактуальные id приведут к 422.
   */
  services?: readonly TaskFinanceServiceForm[];
}
export type TaskFinanceForm = TaskFinanceTarget & TaskFinanceDataForm;

// -----------

export type TaskFinanceReportMonthParams = { month: DateISO } & WithBookkeeper & WithCustomer;

export interface TaskFinanceReportMonthItem {
  readonly task_id: TaskId;
  readonly is_typical: boolean;
  // readonly task_title: string;
  readonly date_end: DateISO;
  readonly title: string;
  readonly price: number;
  readonly unit_code: TariffAdditionalServiceUnit;
  readonly quantity: number;
  readonly cost: number;
}
