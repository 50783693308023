import { dataOnly } from 'api/instance';
import { API_EMAIL_REMOTE, ApiResponse, ApiResponse2 } from 'api/types';
import { CompanyId } from 'models/company/types';
import {
  MailCredentials,
  MailCredentialsCheckForm2,
  MailCredentialsClearErrorParams,
  MailCredentialsCreateForm,
  MailCredentialsDeleteParams,
  MailCredentialsUpdateParams,
  MailService,
} from 'models/mail-credentials/types';

const api = {
  list: (companyId: CompanyId) =>
    dataOnly.get<null, ApiResponse2<MailCredentials[]>>(`/api2/mail-credentials`, {
      params: { bookkeeper_team_id: companyId },
    }),

  create: (form: MailCredentialsCreateForm) =>
    dataOnly.post<null, ApiResponse2<MailCredentials>>(`/api2/mail-credentials`, form),

  delete: ({ id, form }: MailCredentialsDeleteParams) =>
    dataOnly.delete<null, ApiResponse2<void>>(`/api2/mail-credentials/${id}`, { data: form }),

  servicesList: (region: string) =>
    dataOnly.get<null, ApiResponse2<MailService[]>>(`/api2/mail-credentials/services-list`, {
      params: { region },
    }),

  update: ({ id, form }: MailCredentialsUpdateParams) =>
    dataOnly.patch<null, ApiResponse2<MailCredentials>>(`/api2/mail-credentials/${id}`, form),

  clearError: ({ id, form }: MailCredentialsClearErrorParams) =>
    dataOnly.patch<null, ApiResponse2<MailCredentials>>(
      `/api2/mail-credentials/${id}/clear-error`,
      form,
    ),
};

export default api;

export const apiMailCredentialsCheck2 = (form: MailCredentialsCheckForm2) =>
  dataOnly.post<ApiResponse<void>>(`${API_EMAIL_REMOTE}/v1/email/creds/check`, form);
