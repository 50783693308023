import { FC, useEffect, useRef } from 'react';

interface Props {
  onClickAway: () => void;
  clickAwayId?: string;
  className?: string;
}

const ClickAwayListener: FC<Props> = ({ children, onClickAway, clickAwayId, className }) => {
  let myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const eventListener = (event: any) => {
      const preventClickaway = event.target.dataset?.preventClickaway;
      const isValidRef = myRef?.current;
      if (preventClickaway && preventClickaway === clickAwayId) {
        return;
      }

      if (isValidRef && !isValidRef.contains(event.target)) {
        onClickAway();
      }
    };

    document.addEventListener('mousedown', eventListener);

    return () => {
      document.removeEventListener('mousedown', eventListener);
    };
  }, [onClickAway, clickAwayId]);

  return (
    <div
      ref={myRef}
      className={className}
      // а то бывает не понятно, что это за лишний блок без опознавательных знаков
      {...(process.env.NODE_ENV === 'development' ? { 'data-dev': 'click-away' } : undefined)}
    >
      {children}
    </div>
  );
};

export default ClickAwayListener;
