import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { TaskChecklistIdParams, TaskChecklistParamForm } from 'models/task-checklist/types';
import {
  TaskChecklistItem,
  TaskChecklistItemCreateForm,
  TaskChecklistItemIdParams,
  TaskChecklistItemSortForm,
  TaskChecklistItemUpdateForm,
} from 'models/task-checklist-item/types';

export interface TaskChecklistItemListParams extends TaskChecklistIdParams {
  params: TaskChecklistParamForm;
}

export interface TaskChecklistItemCreateParams extends TaskChecklistIdParams {
  form: TaskChecklistItemCreateForm;
}

export interface TaskChecklistItemSortParams extends TaskChecklistIdParams {
  form: TaskChecklistItemSortForm;
}

export interface TaskChecklistItemDeleteParams extends TaskChecklistItemIdParams {
  params: TaskChecklistParamForm;
}

export interface TaskChecklistItemUpdateParams extends TaskChecklistItemIdParams {
  form: TaskChecklistItemUpdateForm;
}

export const apiTaskChecklistItem = {
  list: ({ taskId, checklistId, params }: TaskChecklistItemListParams) =>
    dataOnly.get<null, ApiResponse2<unknown>>(
      `/api2/tasks/${taskId}/checklists/${checklistId}/items`,
      {
        params,
      },
    ),

  create: ({ taskId, checklistId, form }: TaskChecklistItemCreateParams) =>
    dataOnly.post<null, ApiResponse2<TaskChecklistItem | TaskChecklistItem[]>>(
      `/api2/tasks/${taskId}/checklists/${checklistId}/items`,
      form,
    ),

  /** Изменить сортировку списка */
  sort: ({ taskId, checklistId, form }: TaskChecklistItemSortParams) =>
    dataOnly.put<null, ApiResponse2<unknown>>(
      `/api2/tasks/${taskId}/checklists/${checklistId}/items/sort`,
      form,
    ),

  delete: ({ taskId, checklistId, item_id, params }: TaskChecklistItemDeleteParams) =>
    dataOnly.delete<null, ApiResponse2<unknown>>(
      `/api2/tasks/${taskId}/checklists/${checklistId}/items/${item_id}`,
      { params, data: params },
    ),

  update: ({ taskId, checklistId, item_id, form }: TaskChecklistItemUpdateParams) =>
    dataOnly.patch<null, ApiResponse2<TaskChecklistItem>>(
      `/api2/tasks/${taskId}/checklists/${checklistId}/items/${item_id}`,
      form,
    ),
};
