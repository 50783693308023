import { dataOnly } from 'api/instance';
import { CompanyId } from 'models/company/types';
import { ApiResponse } from '../../types';
import { CustomerTag, CustomerTagId } from 'models/customers-tags/types';
import { TagUpdateParam } from '../tags';
import { TagCreateForm } from 'models/tags/types';

export const customersTagsApi = {
  list: (companyId: CompanyId) =>
    dataOnly.get<null, ApiResponse<CustomerTag[]>>(`api/v4/customers-tags/${companyId}`),

  create: ({ bookkeeper_team_id, ...form }: TagCreateForm) =>
    dataOnly.post(`api/v4/customers-tags/${bookkeeper_team_id}`, form),

  update: ({ tagId, form }: TagUpdateParam<CustomerTagId>) =>
    dataOnly.patch(`api/v4/customers-tags/${tagId}`, form),

  delete: (tagId: CustomerTagId) => dataOnly.delete(`api/v4/customers-tags/${tagId}`),
};
