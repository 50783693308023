import { createEvent, createStore, restore, Store, Event, sample } from 'effector';
import { PostListOptions, PostsDataExt } from 'models/post/types';
import { CurrentChannelScrollState } from 'models/channel/types';

export type ScrollPosition = 'top' | 'bottom' | 'middle';

export interface ChatScrollPosition {
  $postsData: Store<PostsDataExt | null>;
  fetchChannelPosts: Event<PostListOptions>;
  $currentChannelScrollState: Store<CurrentChannelScrollState>;
}

export const createChatScrollPosition = ({
  $postsData,
  fetchChannelPosts,
  $currentChannelScrollState,
}: ChatScrollPosition) => {
  const resetScrollPosition = createEvent();
  const changeScrollPosition = createEvent<ScrollPosition>();
  const $scrollPosition = restore(changeScrollPosition, 'middle').reset(resetScrollPosition);
  const $showBottomButton = $scrollPosition.map((c) => c !== 'bottom');

  /*  $scrollPosition.watch((scrollPosition) => {
    // const channelId = $globalChannelId.getState();
    const posts = $postsData.getState()?.posts;

    if (/!*!channelId ||*!/ !posts) return;

    //todo
    const { hasTop, hasBottom } = $currentChannelScrollState.getState();
    let options: PostListOptions = {};
    if (scrollPosition === 'top') {
      if (!hasTop) {
        return;
      }
      options.beforeid = posts[0].id;
    } else {
      if (!hasBottom) {
        return;
      }
      options.afterid = posts[posts.length - 1].id;
    }

    /!*!//проверим если id канала совпадает с id юзера, то директ-канал и вызываем соответствующий метод
    if ($internalUsersChannelsMap.getState().get(channelId))
      getUserPostsFx({ userId: channelId, options }).catch(fnVoid);
    else getChannelPostsFx({ channelId, options }).catch(fnVoid);*!/
    fetchChannelPosts(options);
  });*/
  sample({
    clock: $scrollPosition,
    source: { postsData: $postsData, scrollState: $currentChannelScrollState },
    filter: ({ postsData, scrollState }, scrollPosition) => {
      const { hasTop, hasBottom } = scrollState;
      if (!postsData || scrollPosition === 'middle') {
        return false;
      }
      return (scrollPosition === 'top' && hasTop) || hasBottom;
    },
    target: fetchChannelPosts,
    fn: ({ scrollState, postsData }, scrollPosition): PostListOptions => {
      let options: PostListOptions = {};
      const posts = postsData!.posts;
      if (scrollPosition === 'top') {
        options.beforeid = posts[0].id;
      } else {
        options.afterid = posts[posts.length - 1].id;
      }
      return options;
    },
  });

  /** $lastOpenPostDate на момент открытия канала. Используется для определения положения дивайдера "Новые сообщения" */
  const $lastOpenPostDate = createStore<number | null>(null);
  //нужно чтобы событие срабатывало только если мы получаем от других сообщение.
  //todo !!!!!!
  // const postReceived = guard({
  //   clock: receivePost,
  //   source: $postsData,
  //   filter: (data, post) => !!data && post.channelid === data.channelId,
  // });
  // sample({
  //   clock: [$globalChannelId, postReceived],
  //   source: [$globalChannelId, $channelsCommonMap] as const,
  //   target: $lastOpenPostDate,
  //   fn: ([id, map]) => (id ? map.get(id)?.lastViewedAt || null : null),
  // });

  return {
    resetScrollPosition,
    changeScrollPosition,
    $showBottomButton,
    $lastOpenPostDate,
  };
};
