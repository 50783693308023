import { AnyFormValues, createForm, FormValues } from 'effector-forms';
import { TaskHolidaysMove, TaskRepeatableMode, TaskRepeatableSettings } from 'models/task/types';
import { proxyRule, requiredRule } from 'utils/validation/effector-forms/validators';
import { changeWatcher } from 'models/utils/changeWatcher';
import { addDays, differenceInYears, isValid as isValidDate } from 'date-fns';
import { forward } from 'effector';
import { EMPTY_ARRAY } from 'constants/utils';
import { toISODateString } from 'utils/date';
import { TFuncKey } from 'react-i18next';

interface Form extends AnyFormValues {
  mode: TaskRepeatableMode | null;
  days_weekly: number[];
  days_monthly: number[];
  dates: string[];
  dates_quarterly: string[];
  dates_yearly: string[];
  period_end_date: string;
  period_start_date: string;
  on_holidays_move: TaskHolidaysMove;
  date_calendar_delta_days: number | null;
}

const requirePickerRule = proxyRule<number[]>({
  name: 'requirePicker',
  validator: (value) => value.length > 0,
  // t('task:edit.repeatForm.error.require')
  errorText: 'task:edit.repeatForm.error.require',
});

export const form = createForm<Form>({
  fields: {
    mode: {
      init: null,
    },
    days_weekly: {
      init: [],
      rules: (_, form: FormValues<Form>) => (form.mode === 'weekly' ? [requirePickerRule] : []),
    },
    days_monthly: {
      init: [],
      rules: (_, form: FormValues<Form>) => (form.mode === 'monthly' ? [requirePickerRule] : []),
    },
    dates: {
      init: [''],
      rules: (_, form: FormValues<Form>) =>
        form.mode !== 'dates'
          ? []
          : [
              proxyRule({
                name: 'validDates',
                validator: (value) => value.every((date) => isValidDate(new Date(date))),
                // t('task:edit.repeatForm.error.require')
                errorText: 'task:edit.repeatForm.error.require',
              }),
            ],
    },
    dates_quarterly: {
      init: [],
    },
    dates_yearly: {
      init: [''],
      rules: (_, form: FormValues<Form>) =>
        form.mode !== 'yearly'
          ? []
          : [
              proxyRule({
                name: 'validDates',
                validator: (value) => value.every((date) => isValidDate(new Date(date))),
                // t('task:edit.repeatForm.error.dayMonth')
                errorText: 'task:edit.repeatForm.error.dayMonth',
              }),
            ],
    },
    period_start_date: {
      init: '',
      rules: (_, form: FormValues<Form>) => (form.mode !== 'dates' ? [requiredRule] : []),
    },
    period_end_date: {
      init: '',
      rules: (_, form: FormValues<Form>) =>
        form.mode !== 'dates'
          ? [
              proxyRule({
                name: 'validPeriodEnd',
                validator: (value, form) =>
                  form.period_start_date &&
                  form.period_end_date &&
                  differenceInYears(
                    new Date(form.period_end_date),
                    addDays(new Date(form.period_start_date), 1),
                  ) <= 2,
                // t('task:edit.repeatForm.error.maxPeriod')
                errorText: 'task:edit.repeatForm.error.maxPeriod',
              }),
            ]
          : [],
    },
    on_holidays_move: {
      init: 'noop',
    },
    date_calendar_delta_days: {
      init: null,
    },
  },
});

export const changeWatcherRepeatForm = changeWatcher(form.$values);

const validDateReducer = ({ value, isValid }: { value: string[]; isValid: boolean }) =>
  isValid ? EMPTY_ARRAY : value.map((date) => !isValidDate(new Date(date)));
export const $yearlyIdxErrors = form.fields.dates_yearly.$field.map(validDateReducer);
export const $datesIdxErrors = form.fields.dates.$field.map(validDateReducer);

forward({ from: form.fields.mode.changed, to: form.resetErrors });

export const convertTaskRepeatFormToRequest = (
  form: Form,
): Omit<TaskRepeatableSettings, 'id'> | null =>
  form.mode && {
    ...form,
    mode: form.mode, // ну, не тупи ты
    days_weekly: form.mode === 'weekly' ? form.days_weekly : [],
    days_monthly: form.mode === 'monthly' ? form.days_monthly : [],
    dates: form.mode === 'dates' ? form.dates : [],
    dates_quarterly: form.mode === 'quarterly' ? form.dates_quarterly : [],
    dates_yearly:
      form.mode === 'yearly'
        ? form.dates_yearly.map((date) => toISODateString(new Date(date)))
        : [],
    date_calendar_delta_days: form.date_calendar_delta_days ?? undefined,
  };

export const repeatableModeLabels: Record<TaskRepeatableMode, TFuncKey> = {
  // t('ui:dateRepeatable.weekly')
  weekly: 'ui:dateRepeatable.weekly',
  // t('ui:dateRepeatable.monthly')
  monthly: 'ui:dateRepeatable.monthly',
  // t('ui:dateRepeatable.quarterly')
  quarterly: 'ui:dateRepeatable.quarterly',
  // t('ui:dateRepeatable.yearly')
  yearly: 'ui:dateRepeatable.yearly',
  // t('ui:dateRepeatable.dates')
  dates: 'ui:dateRepeatable.dates',
};
