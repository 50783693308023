import { createEvent } from 'effector';
import { MailCredentialsId } from '../mail-credentials/types';
import { EmailFolderId, EmailId } from './types';
import { MailInFolderNotify } from '../mail-credentials-users';

export interface MailPostMovedNotify {
  mailCredId: MailCredentialsId;
  fromMailboxId: EmailFolderId;
  // `null` - письмо было удалено, т.к. его уже не было в папке
  toMailboxId: EmailFolderId | null;
  postIds?: readonly EmailId[];
}

export interface MailPostDeleted extends MailInFolderNotify {
  postIds: readonly EmailId[];
}

export const mailPostMoved = createEvent<MailPostMovedNotify>();
export const mailPostDeleted = createEvent<MailPostDeleted>();
