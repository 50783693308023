import { addDays } from 'date-fns';
import { FC, useMemo } from 'react';
import { useDateFormat } from 'hooks/i18n';
import { Picker, PickerProps } from './Picker';

interface Props extends Omit<PickerProps<number>, 'options'> {}

const MON = new Date(2022, 7, 1, 0, 0, 0, 0);
export const WeekDayPicker: FC<Props> = ({ ...tail }) => {
  const formatDate = useDateFormat();
  const option = useMemo(
    () =>
      Array.from({ length: 7 }).map((_, i) => {
        const day = addDays(MON, i);

        return { value: day.getDay(), label: formatDate(day, 'cccccc') };
      }),
    [formatDate],
  );
  return <Picker {...tail} options={option} />;
};
