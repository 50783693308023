import { differenceInSeconds } from 'date-fns';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentTime } from 'hooks/utils';
import { durationFormat, durationSplit, durationSplitWithDays, parseDateStrict } from 'utils/date';
import { DateTimeISO } from 'utils/types';

interface Props {
  prevSec?: number;
  withDays?: boolean;
}
interface PropsRunning extends Props {
  startedAt: string | DateTimeISO;
}

export const TimerStatic: FC<Props> = ({ prevSec = 0, withDays = false }) => {
  let hms: [number, number, number];
  if (withDays) {
    let days: number;
    [days, ...hms] = durationSplitWithDays(prevSec);
    if (days > 0) {
      return (
        <>
          <WithDays days={days} />
          {', '}
          {durationFormat(hms)}
        </>
      );
    }
  } else {
    hms = durationSplit(prevSec);
  }

  return <>{durationFormat(hms)}</>;
};

export const TimerRunning: FC<PropsRunning> = ({ startedAt, prevSec = 0, withDays = false }) => {
  const started = useMemo(() => parseDateStrict(startedAt), [startedAt]);
  const now = useCurrentTime('s');
  // Время на сервере может не совпадать со временем юзера, поэтому
  // в первые секунды работы таймера разница может быть отрицательной: потрачено "-3 сек".
  const duration = Math.max(0, differenceInSeconds(now, started)) + prevSec;

  return <TimerStatic prevSec={duration} withDays={withDays} />;
};

const WithDays: FC<{ days: number }> = ({ days }) => {
  const { t } = useTranslation('ui');
  return <>{t('ui:date.cntDays', { N: days })}</>;
};
