import { dataOnly } from 'api/instance';
import { ApiResponse } from 'api/types';
import { Invite, InviteConfirm, InviteCreateForm } from 'models/invite/types';
import { CompanyId } from 'models/company/types';

const api = {
  list: (companyId?: CompanyId) =>
    dataOnly.get<null, ApiResponse<Invite[]>>(`/api/v1/invites`, {
      params: { companyid: companyId },
    }),

  create: (form: InviteCreateForm) =>
    dataOnly.post<null, ApiResponse<Invite>>('/api/v1/invites', form),

  confirm: (id: string) =>
    dataOnly.get<null, ApiResponse<InviteConfirm>>(`/api/v1/invites/${id}/confirm`),
};

export default api;

export const apiInviteDelete = (id: string) => dataOnly.delete<null, void>(`/api/v1/invites/${id}`);
