import { dataOnly } from 'api/instance';
import { ApiResponse2, PaginatedResponse2, PaginationParams } from 'api/types';
import {
  Task,
  TaskId,
  TaskRepeatableDeleteForm,
  TaskRepeatableSettingsInfo,
  TaskRepeatableUpdateForm,
} from 'models/task/types';
import { TimerToggleParams } from 'models/timers/types';
import { WithBookkeeper, WithCustomer } from '../../types/request';

export interface TaskRepeatableEditParams extends TimerToggleParams {
  taskId: TaskId;
  form: TaskRepeatableUpdateForm;
}

export const tasksRepeatableApi = {
  list: (params: WithBookkeeper & WithCustomer & PaginationParams) =>
    dataOnly.get<null, ApiResponse2<TaskRepeatableSettingsInfo[]> & PaginatedResponse2>(
      `/api2/tasks/repeatable`,
      {
        params,
      },
    ),

  update: ({ taskId, form }: TaskRepeatableEditParams) =>
    dataOnly.patch<null, ApiResponse2<Task[]>>(`/api2/v1/tasks/repeatable/${taskId}`, form),

  /** Удалить повторяющиеся*/
  delete: (form: TaskRepeatableDeleteForm) =>
    dataOnly.delete<null, ApiResponse2<Task[]>>(`/api2/v1/tasks/repeatable`, { data: form }),
};
