interface Options<T> {
  fields: (v: T) => Iterable<string | false | null | undefined>;
}

// TODO: возможно, было бы полезно возвращать инфу о том, в каких полях есть
//  совпадение, но это нужно не для всем проверяемых полей. Например, ищем по
//  названию и описанию, но отображается только название, и поэтому нет смысла
//  вычислять подсветку в описании.
//  Также, проверяемые поля могут быть вложенными, и в том числе в массивах, что
//  существенно осложняет задачу. Например: `task.checklists[i].items[i].text`.

const _fnTrue = () => true;

/**
 *
 * ```ts
 * const matchFields = (f: T) => [f.title, f.description, ...f.checklists.map(c => c.items.map(i => i.text)).flat()];
 * const filterT = (list: readonly T[], [isMatch]: [(f: T) => boolean]) => list.filter(isMatch);
 *
 * const C: FC = () => {
 *   const [filter, setFilter] = useState('');
 *   const isTMatch = useMemo(() => wordsMatchExt(filter, { fields: matchFields }), [filter]);
 *   const filtered = useStoreMap({
 *     store: $list,
 *     keys: [isTMatch],
 *     fn: filterT,
 *   });
 *
 *   ...
 * };
 * ```
 */
export const wordsMatchExt = <T>(search: string, { fields }: Options<T>) => {
  const words = search
    .split(/\s+/)
    .filter(Boolean)
    .map((s) => s.toUpperCase());
  if (words.length) {
    // Было: одно из полей должно содержать все искомые слова.
    // Такое себе.
    // return (v: T): boolean =>
    //   Array.from(fields(v)).some((name) => {
    //     if (name) {
    //       name = name.toUpperCase();
    //       return words.every((w) => name.includes(w));
    //     }
    //     return false;
    //   });

    // Теперь: каждое слово должно быть в любом из полей.
    // Так лучше.
    return (v: T): boolean => {
      const values = Array.from(fields(v));
      return words.every((w) => values.some((value) => value && value.toUpperCase().includes(w)));
    };
  }
  return _fnTrue;
};
