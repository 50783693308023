import { ReactNode, useEffect } from 'react';

const useWarn =
  process.env.NODE_ENV === 'development'
    ? (p?: { value: unknown; message: string }) => {
        const on = p !== undefined;
        const { value, message } = p || {};
        useEffect(() => {
          if (on) {
            console.warn(message, value);
          }
        }, [on, value, message]);
      }
    : undefined;

export const useParseString = (children?: ReactNode) => {
  if (Array.isArray(children)) {
    [children] = children;
  }
  useWarn?.(
    typeof children === 'string'
      ? undefined
      : { message: 'Неожиданный тип children', value: children },
  );
  if (typeof children === 'string') {
    return children;
  }
};
