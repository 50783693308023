import { dataOnly } from 'api/instance';
import { CustomerId } from 'models/customer/types';
import { CompanyId } from 'models/company/types';
import { RegionCode } from 'utils/types';
import { ApiResponse2 } from 'api/types';
import {
  TaskPeriodical,
  TaskPeriodicalSetting,
  TaskPeriodicalSettingsUpdateForm,
} from 'models/tasks-periodical/types';

export interface TaskPeriodicalSettingsParams {
  team_id?: CustomerId;
  bookkeeper_team_id: CompanyId;
  region: RegionCode;
}

export const apiTaskPeriodical = {
  list: (region?: RegionCode) =>
    dataOnly.get<null, ApiResponse2<TaskPeriodical[]>>(`/api2/tasks/periodical`, {
      params: { region },
    }),

  settings: (params: TaskPeriodicalSettingsParams) =>
    dataOnly.get<null, ApiResponse2<TaskPeriodicalSetting[] | null>>(
      `/api2/tasks/periodical/settings`,
      { params },
    ),

  settingsUpdate: (params: TaskPeriodicalSettingsUpdateForm) =>
    dataOnly.put<null, ApiResponse2<TaskPeriodicalSetting[] | null>>(
      `/api2/tasks/periodical/settings`,
      params,
    ),
};
