export default function round(x: number, decimals: number = 0): number {
  if (0 === decimals) {
    return Math.round(x);
  }
  if (decimals > 0) {
    const scale = Math.pow(10, decimals);
    return Math.round(Number(x) * scale) / scale;
  }
  throw new RangeError('Negative decimals - no support for integer rounding like 12345 => 12300');
}
