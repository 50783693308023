import { CustomerId } from '../../customer/types';
import { FileId } from '../types';
import { FileshareDirectoryId } from '../dirs/types';

// https://api.dev.finkoper.com/api3/swagger/index.html#/file-internal-v2/file-internal-upload
// Произвольная строка для АПИ
export const enum FileStorageService {
  bulkNotifications = 'bulkNotifications',
  files = 'files',
  mail = 'mail',
  tasks = 'tasks',
  telegram = 'telegram',
  whatsapp = 'whatsapp',
  wiki = 'wiki',
  // ...
}
export type FileStorageServiceString = FileStorageService | (string & {});

export interface FilesUploadFormV2 {
  files: readonly File[];
  // Local ID по порядку в соответствии с files
  local_id?: string[];
  service?: FileStorageServiceString;
  is_standalone?: boolean;
  customers?: readonly CustomerId[];
  // Dirs ID в соответствии с customers по порядку, 0 для пропуска
  dirs_id?: readonly (FileshareDirectoryId | 0 | null)[];
}
/** Передавать достаточно только те поля, которые необходимо изменить */
export interface FilesUpdateBatchForm {
  files_id: readonly FileId[];
  /** Если `""`, то не изменяется */
  service?: FileStorageServiceString;
  /** Если не указано, или `null`, то не изменяется. Иначе заменяет список клиентов. */
  customers?: readonly CustomerId[];

  // Если true, то не нужно возвращать итоговый список моделей
  // no_return?: boolean;
}
export type FilesSetProperties = Pick<
  FilesUploadFormV2,
  keyof FilesUploadFormV2 & keyof FilesUpdateBatchForm
>;

export interface FileUpdateForm {
  filename?: string;
}
export type FileUpdateFormWithId = FileUpdateForm & { fileId: FileId };

export interface FileshareCustomer {
  customer_team_id: CustomerId;
}

export interface FileshareStat {
  /**
   * Content-Type , может быть с параметрами
   *
   * - `image/png`
   * - `image/png; filename=370.png`
   * - `text/plain`
   * - `text/plain; charset=Windows-1251`
   */
  file_type: string;
  size: number;
  // .ext , но может быть и пуст
  // extension: string;
  // name: string; // зачем он здесь?

  original_name: string;
}
export interface Fileshare extends FileshareStat {
  id: FileId;
  local_id?: string;
  service?: FileStorageServiceString;
  is_standalone?: true;
  customers: readonly FileshareCustomer[] | null;
  // is_public?: boolean;
  // Если загружали файл с расшариванием, и заполнено только по результату загрузки
  // public_location?: string;

  // created_at: UnixTimestampMilli;
  // updated_at: UnixTimestampMilli;
}
