import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import languages from './languages';

// https://react.i18next.com/latest/using-with-hooks
// https://react.i18next.com/misc/using-with-icu-format

i18n
  .use(
    new ICU({
      parseErrorHandler: (err, key, res, options) => {
        if (process.env.NODE_ENV === 'development') {
          console.warn('Error in ICU message:', { err, key, res, options });
        }
        return res;
      },
    }),
  )
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    supportedLngs: languages.map(({ language }) => language),
    // fallbackLng: defaultLanguageCode,
    fallbackLng: false,
    ns: ['ui'],
    defaultNS: 'ui',

    interpolation: {
      escapeValue: false,
    },
    // returnEmptyString: process.env.NODE_ENV === 'production',
    returnEmptyString: false,
    appendNamespaceToMissingKey: true,
  });

export default i18n;
