import { combine, createEvent, createStore, sample, Store } from 'effector';
import isequal from 'lodash.isequal';

export const changeWatcher = <T>($store: Store<T>) => {
  const reset = createEvent();
  const $init = createStore<any>(null);
  sample({
    clock: reset,
    source: $store,
    target: $init,
    fn: (obj): T => obj,
  });

  reset();

  const $isChanged = combine($store, $init, (obj, init) => !isequal(obj, init));

  return { $isChanged, reset };
};
