import { useStore } from 'effector-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextButton2 } from 'ui/button';
import { Input, LabeledCheckbox } from 'ui/input';
import { useCustomersLightFiltered } from '../useCustomersLightFiltered';
import {
  $filter,
  $selectedSet,
  customerClicked,
  filterChanged,
  selectAll,
  unselectAll,
} from './model';
import cl from './CustomerList.module.scss';

interface Props {}

export const CustomerList: FC<Props> = () => {
  const { t } = useTranslation('');
  const filter = useStore($filter);
  const customersFiltered = useCustomersLightFiltered(filter);
  const selectedSet = useStore($selectedSet);

  return (
    <div>
      <div className={cl.toolbox}>
        <Input inputSize="small" placeholder={t('ui:search')} onChange={filterChanged} allowClear />
        <TextButton2 size="small" onClick={selectAll}>
          {t('ui:selectAll')}
        </TextButton2>
        <TextButton2 size="small" onClick={unselectAll}>
          {t('ui:unselect')}
        </TextButton2>
      </div>
      {customersFiltered.map((c) => (
        <LabeledCheckbox
          key={c.id}
          name={c.id}
          className={cl.customer}
          label={c.name}
          onChange={customerClicked}
          checked={selectedSet.has(c.id)}
        />
      ))}
    </div>
  );
};
