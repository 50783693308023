const isEmptyObject = (object: {}): boolean => {
  if (!object || typeof object !== 'object') {
    return false;
  }
  for (let k in object) {
    if (Object.hasOwn(object, k)) {
      return false;
    }
  }
  return true;
};

export default isEmptyObject;
