import { ApiConflictError } from 'api/errors';
import { dataOnly } from 'api/instance';
import { API_TASK_REMOTE, ApiResponse2 } from 'api/types';
import { WithBookkeeper } from 'api/types/request';
import {
  WaGreenAuthQr,
  WaGreenConfig,
  WaGreenGroupAccess,
  WaGreenGroupAccessBatch,
  WaGreenGroupAdmin,
  WaGreenGroupRemote,
  WaGreenGroupsSave,
  WaGreenGroupUpdateForm,
  WaGreenInstance,
  WaGreenInstanceEditForm,
  WaGreenPaidStatus,
  WithWaGreenGroupId,
  WithWaGreenInstanceId,
} from 'models/wagreen/types';
import { parseDate } from 'utils/date';
import { hasProperty } from 'utils/object';

type PInst = WithBookkeeper & WithWaGreenInstanceId;
type PCh = PInst & WithWaGreenGroupId;

//-------------------------------------------------

export const apiConfig = () =>
  dataOnly.get<null, ApiResponse2<WaGreenConfig>>(`${API_TASK_REMOTE}/wagreen/config`);

//-------------------------------------------------

export const apiPaidStatus = ({ bookkeeper_team_id: team_id, ...params }: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<readonly WaGreenPaidStatus[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/paid-status`,
    { params },
  );

export const apiInstanceList = ({ bookkeeper_team_id: team_id, ...params }: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<readonly WaGreenInstance[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}`,
    { params },
  );

export const apiInstanceGet = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...params
}: PInst) =>
  dataOnly.get<null, ApiResponse2<WaGreenInstance>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}`,
    { params },
  );

/**
 * - 503
 */
export const apiInstanceCreate = ({ bookkeeper_team_id: team_id, ...form }: WithBookkeeper) =>
  dataOnly.post<null, ApiResponse2<WaGreenInstance>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}`,
    form,
  );

export const apiInstanceUpdate = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...form
}: PInst & WaGreenInstanceEditForm) =>
  dataOnly.put<null, ApiResponse2<WaGreenInstance>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}`,
    form,
  );

export const apiInstanceDelete = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...params
}: PInst) =>
  dataOnly.delete<null, void>(`${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}`, { params });

export const apiInstanceGetQr = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...params
}: PInst) =>
  dataOnly.post<null, ApiResponse2<WaGreenAuthQr>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/qr`,
    params,
  );

export const apiInstanceLogout = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...params
}: PInst) =>
  dataOnly.patch<null, ApiResponse2<WaGreenInstance>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/logout`,
    params,
  );

export const apiInstanceRefresh = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...params
}: PInst) =>
  dataOnly.patch<null, ApiResponse2<WaGreenInstance>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/refresh`,
    params,
  );

/**
 * - 409: `{ retry_after?: DateTimeISO; }`
 * @see isConflictRetryAfter
 */
export const apiInstanceRestart = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...params
}: PInst) =>
  dataOnly.patch<null, ApiResponse2<void>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/restart`,
    params,
  );

export const isConflictRetryAfter = (e: unknown) => {
  if (e instanceof ApiConflictError) {
    // ApiResponse2<{ retry_after: DateTimeISO; }>
    const resp: unknown = e.response.data;
    if (hasProperty(resp, 'data') && hasProperty(resp.data, 'retry_after')) {
      const after = resp.data.retry_after;
      if (typeof after === 'string') {
        return parseDate(after);
      }
    }
  }
  return null;
};

//-------------------------------------------------

export const apiChatList = ({ bookkeeper_team_id: team_id, instance_id: iid, ...params }: PInst) =>
  dataOnly.get<null, ApiResponse2<readonly WaGreenGroupAdmin[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/chat`,
    { params },
  );

export const apiChatFetch = ({ bookkeeper_team_id: team_id, instance_id: iid, ...params }: PInst) =>
  dataOnly.get<null, ApiResponse2<readonly WaGreenGroupRemote[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/chat/fetch`,
    { params },
  );

export const apiChatsSave = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...form
}: PInst & WaGreenGroupsSave) =>
  dataOnly.patch<null, ApiResponse2<readonly WaGreenGroupAdmin[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/chat/save`,
    form,
  );

export const apiChatUpdate = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  group_id: id,
  ...form
}: PCh & WaGreenGroupUpdateForm) =>
  dataOnly.patch<null, ApiResponse2<WaGreenGroupAdmin>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/chat/${id}`,
    form,
  );

export const apiChatDelete = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  group_id: id,
  ...params
}: PCh) =>
  dataOnly.delete<null, void>(`${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/chat/${id}`, {
    params,
  });

export const apiChatAccessGet = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...params
}: PInst) =>
  dataOnly.get<null, ApiResponse2<readonly WaGreenGroupAccess[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/chat/access`,
    { params },
  );

export const apiChatAccessUpdateV2 = ({
  bookkeeper_team_id: team_id,
  instance_id: iid,
  ...form
}: PInst & WaGreenGroupAccessBatch) =>
  dataOnly.patch<null, ApiResponse2<readonly WaGreenGroupAccess[]>>(
    `${API_TASK_REMOTE}/wagreen/team/${team_id}/${iid}/chat/access/v2`,
    form,
  );
