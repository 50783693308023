import { CentMessageDataV2 } from './internal';
import { BulkNotificationSimple } from '../bulk-notifications/types';

export const enum CentCompanyEventTypeBN {
  update = 'bulkNotification.update',
}

export type CentCompanyEventBN = CentMessageDataV2<
  CentCompanyEventTypeBN.update,
  readonly BulkNotificationSimple[]
>;
