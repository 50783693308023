import { IdOf, UnixTimestampMilli } from 'utils/types/primitive';
import { CompanyId } from '../company/types';
import { AvatarId, UserId } from '../user/types';
import { $channelExtendedMap } from 'models/channel';
import { TaskId } from '../task/types';
import { PostId } from '../post/types';
import { CustomerTagId } from '../customers-tags/types';

export type ChannelGlobalId = IdOf<'ChannelGlobalId'>;
export type ChannelId = IdOf<'Channel'>;
export type CustomerChannelsItemId = IdOf<'CustomerChannelsItem'>;

export enum ChannelGroups {
  customers = 'customers',
  internal_channels = 'internal_channels',
  internal_users_channels = 'internal_users_channels',
}

export type ChannelGroup = keyof typeof ChannelGroups;

export enum ChannelTypes {
  /** канал по приглашениям */
  private = 'private',
  /** приватный канал невидимый */
  invisible = 'invisible',
  /** общедоступный */
  public = 'public',
}

export type ChannelType = keyof typeof ChannelTypes;

export interface Channel extends ChannelUserRightsForm {
  id: ChannelGlobalId;
  channel_id: ChannelId | null;
  avatar: AvatarId;
  last_id: PostId | '';
  last_post_at: UnixTimestampMilli | null;
  last_update_at: UnixTimestampMilli | null;
  last_viewed_at: UnixTimestampMilli | null;
  last_notice_at: UnixTimestampMilli | null;
  name: string;
  total_message_count: number | null;
  type: ChannelType;
  user_message_count: number | null;
  delete_at: UnixTimestampMilli;
}

export interface ChannelExt extends Channel {
  group: ChannelGroup;
}

export const isChatChannel = (channel: ChannelExt | TaskChannelExt | null): channel is ChannelExt =>
  !!channel &&
  (channel.group === ChannelGroups.customers ||
    channel.group === ChannelGroups.internal_users_channels ||
    channel.group === ChannelGroups.internal_channels);

// export type ChannelByGroups = {
//   [x in ChannelGroup]: Channel[];
// };
//todo
export interface ChannelByGroups {
  customers: CustomerChannelsItem[];
  internal_channels: Channel[];
  internal_users_channels: Channel[];
}

export interface CustomerChannelsItem {
  id: CustomerChannelsItemId;
  name: string;
  avatar: AvatarId | '';
  channels: ReadonlyArray<Channel>;
  inner_channel: Channel | null;
  tags: CustomerTagId[];
}

export interface ChannelCreateForm {
  name: string;
  description?: string;
  companyId: CompanyId;
  groupId?: string;
  type: ChannelType;
  users: string[];
}

export interface ChannelUpdateForm {
  name: string;
  description: string;
  type: ChannelType;
}

export interface ChannelUpdateParams {
  channelId: ChannelId;
  form: ChannelUpdateForm;
}

export interface CurrentChannelScrollState {
  hasTop: boolean;
  hasBottom: boolean;
}

/** @deprecated разделить */
export interface CurrentChannelProps {
  channelId: ChannelGlobalId;
  postId?: string;
}

export interface UserChannelData extends ChannelUserRightsForm {
  channelid: ChannelId;
  userid: UserId;
  roles: '';
  lastviewedat: number;
  msgcount: number;
  mentioncount: null;
  notifyprops: null;
  lastupdateat: number;
}

// export const isChannelGlobalId = (
//   id: any,
//   map: Map<ChannelGlobalId, Channel>,
// ): id is ChannelGlobalId => map.has(id);

export const isChannelGlobalId = (id: any): id is ChannelGlobalId => {
  const map = $channelExtendedMap.getState();
  const channel = map.get(id);
  return !!channel && (channel.group === 'internal_channels' || channel.group === 'customers');
};

// export const isChannelGlobalId = (id: any, channel: ChannelExt): id is ChannelGlobalId => {
//   return channel.group === 'internalChannels' || channel.group === 'customers';
// };

export interface ChannelUserForm {
  channelId: ChannelId;
  userId: string;
  form: ChannelUserRightsForm;
}

//todo refact from IRoleData
export enum ChannelUserRights {
  admin = 'admin',
  user = 'user',
  guest = 'guest',
}

export type ChannelUserRight = keyof typeof ChannelUserRights;

export interface ChannelUserRightsForm {
  schemeuser: boolean;
  schemeadmin: boolean;
  schemeguest: boolean;
}

export const toChannelUserRight = (data: ChannelUserRightsForm): ChannelUserRight => {
  if (data.schemeadmin) return ChannelUserRights.admin;
  else if (data.schemeuser) return ChannelUserRights.user;
  else return ChannelUserRights.guest;
};

export const toChannelUserRightForm = (data: ChannelUserRight): ChannelUserRightsForm => {
  switch (data) {
    case ChannelUserRights.admin:
      return { schemeuser: false, schemeadmin: true, schemeguest: false };
    case ChannelUserRights.user:
      return { schemeuser: true, schemeadmin: false, schemeguest: false };
    default:
      return { schemeuser: false, schemeadmin: false, schemeguest: true };
  }
};

export interface TaskChannelForm {
  companyId: CompanyId;
  taskId: TaskId;
}

export interface TaskChannel extends Channel {}

export interface TaskChannelExt extends TaskChannel {
  channel_id: ChannelId;
  group: 'task';
}

export type ChatType = 'regular' | 'task' | 'inner';
