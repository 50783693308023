import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { WithBookkeeperPascal } from 'api/types/request';
import {
  Tag,
  TagAliasForm,
  TagCreateForm,
  TagDeleteForm,
  TagId,
  TagUpdateForm,
} from 'models/tags/types';
import { CompanyId } from 'models/company/types';

export interface TagListParams {
  bookkeeper_team_id: CompanyId;
  is_local?: boolean;
}

export interface TagDeleteParam {
  tagId: TagId;
  form: TagDeleteForm;
}
export interface TagUpdateParam<Id> {
  tagId: Id;
  form: TagUpdateForm;
}

const api = {
  list: (params: TagListParams) =>
    dataOnly.get<null, ApiResponse2<readonly Tag[]>>('/api2/tags', {
      params,
    }),

  create: (form: TagCreateForm) => dataOnly.post<null, ApiResponse2<Tag>>(`/api2/tags`, form),

  delete: ({ tagId, form }: TagDeleteParam) =>
    dataOnly.delete<null, ApiResponse2<Tag>>(`/api2/tags/${tagId}`, { data: form }),

  update: ({ tagId, form }: TagUpdateParam<TagId>) =>
    dataOnly.patch<null, ApiResponse2<Tag>>(`/api2/tags/${tagId}`, form),
};

export default api;

// https://trello.com/c/yXFYTynX/1344
// https://api.dev.finkoper.com/api2/docs/index.html#/tags/tag-list
// https://gitlab.com/finkoper/finwork-tasks/-/issues/228
// Создать или обновить алиас. Если пустое значение, алиас будет удален
export const apiTagsGlobalAliasSet = ({ _tagId, ...form }: TagAliasForm & WithBookkeeperPascal) =>
  dataOnly.patch<null, ApiResponse2<unknown>>(
    `/api2/tags/customer-settings/global/aliases/${_tagId}`,
    form,
  );
