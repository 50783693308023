import { ILanguage } from '../language';

import en from './en';
import ru from './ru';
import cs from './cs';
import de from './de';

const languages = [cs, ru, en, de];

export default languages;

export const defaultLanguageCode = en.language;

export const languagesMap: ReadonlyMap<string, ILanguage> = new Map(
  languages.map((l) => [l.language, l]),
);
