import { createEffect, createEvent, createStore, forward, merge, sample } from 'effector';
import { EMPTY_ARRAY } from 'constants/utils';
import { apiUserPreference } from 'api/request/user/preferences';
import { UserPreference, UserPreferenceForm } from './types';
import { $userId } from '../user';
import { showToast, ToastTypes } from '../../ui/feedback';
import { Trans } from 'react-i18next';
import { createPreferenceCore } from './createPreference';

const $inUserList = createStore<UserPreference[]>(EMPTY_ARRAY);
export const $userPreferences = $inUserList.map<ReadonlyMap<string, string>>(
  (list) => new Map(list.map((p) => [p.name, p.value])),
);
// $inUserList.watch((v) => console.log('>> preferences list', v));
// $userPreferences.watch((v) => console.log('>> preferences map', v));
export const reloadUserPreferences = createEvent();

export const reloadUserPreferenceItem = createEvent<string>();
export const updateUserPreferenceItem = createEvent<UserPreferenceForm>();

const fetchListFx = createEffect(async () => (await apiUserPreference.list()).data ?? EMPTY_ARRAY);

sample({
  // когда меняем компанию ИЛИ принудительно хотим перезагрузить
  source: merge([reloadUserPreferences, $userId]),
  // только если компания выбрана И сейчас по ней нет запроса
  filter: fetchListFx.pending.map((b) => !b),
  // тогда уже отправляем id компании в запрос
  target: fetchListFx,
});

$inUserList.reset($userId);
forward({
  from: fetchListFx.doneData,
  to: $inUserList,
});

interface GetItemParams {
  name: string;
}

const fetchItemFx = createEffect(
  async ({ name }: GetItemParams) => (await apiUserPreference.item(name)).data,
);
forward({
  from: reloadUserPreferenceItem.map((name): GetItemParams => ({ name })),
  to: fetchItemFx,
});

const updateItemFx = createEffect(
  async (form: UserPreferenceForm) => (await apiUserPreference.update(form)).data,
);
forward({
  from: updateUserPreferenceItem,
  to: updateItemFx,
});
updateItemFx.done.watch(() =>
  showToast(ToastTypes.success, <Trans i18nKey="ui:messages.successSavedChanges" />),
);

$inUserList.on([fetchItemFx.doneData, updateItemFx.doneData], (prev, result) => {
  const without = prev.filter((o) => o.name !== result.name);
  if (result.value) {
    return [...without, result];
  }
  return without;
});

export const createUserOwnPreference = createPreferenceCore({
  $values: $userPreferences,
  reloadAll: reloadUserPreferences,
  reloadOne: reloadUserPreferenceItem,
  updateOne: updateUserPreferenceItem,
});
