import { Store } from 'effector';
import { useStoreMap } from 'effector-react';
import { useMemo } from 'react';
import { $customersLightActive } from 'models/customer/list-light';
import { CustomerItemLight } from 'models/customer/types';
import { wordsMatchExt } from 'utils/string';

const matchCustomerFields = (c: CustomerItemLight) => [c.name];

const filterCustomers = (
  list: readonly CustomerItemLight[],
  [isMatch]: [(f: CustomerItemLight) => boolean],
) => list.filter(isMatch);

interface Options {
  /**
   * По умолчанию активные клиенты
   * @see $customersLightActive
   */
  store?: Store<readonly CustomerItemLight[]>;
}

// #296
export const useCustomersLightFiltered = (
  filter: string,
  { store = $customersLightActive }: Options = {},
) => {
  const isCustomerMatch = useMemo(
    () => wordsMatchExt(filter, { fields: matchCustomerFields }),
    [filter],
  );
  return useStoreMap({
    store,
    keys: [isCustomerMatch],
    fn: filterCustomers,
  });
};
