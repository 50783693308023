import { createEvent, createStore } from 'effector';
import { Layout } from './types';
import { layoutBreakpoints } from 'styles/_constants';

export const $layout = createStore<Layout>({ openLeftSide: false });

export const toggleOpenLeftSide = createEvent();
export const closeLeftSide = createEvent();
//-----------
// Попытка перенести часть функционала по обеспечению адаптивности на JS. Пока не понятно на сколько это может создать проблем. Но удобства есть.

// см. также `useMediaQuery()` и `useMediaQueryState()` в `ui/adaptive`

export const getBreakpoint = () => {
  for (let i = layoutBreakpoints.length - 1; i >= 0; i--) {
    if (window.innerWidth >= layoutBreakpoints[i]) {
      return layoutBreakpoints[i];
    }
  }
  return layoutBreakpoints[0];
};
export const windowSizeUpdated = createEvent<unknown>();
export const $breakpoint = createStore(getBreakpoint());
$breakpoint.on(windowSizeUpdated, getBreakpoint);
