import { createEvent, createStore, sample } from 'effector';
import { $auth, firstInitFx, logout } from 'models/auth';
import { receiveUpdateCompany } from 'models/cent';
import { updateList } from 'models/bulk-notifications/list-cent';
import {
  CentCompanyEventTypeBN,
  CentEventTypes,
  CentPrivateEventTypeKontur,
  CentPrivateEventTypeNotice,
  CentPrivateEventTypeWaGreen,
  CentTeamEventV2,
} from 'models/cent/types';
import { receiveTypingEnd, receiveTypingStart, receiveUpdateChannel } from 'models/channel';
import { $currentCompanyIdOrNull } from 'models/company';
import { receivedSyncEndEvent } from 'models/kontur';
import { receivedAccountEvent } from 'models/kontur-notifications';
import {
  MailPostDeleted,
  mailPostDeleted,
  mailPostMoved,
  MailPostMovedNotify,
} from 'models/mail/cent-list';
import { handleNewMailReceived, MailNewNotify } from 'models/mail-credentials-users';
import { mailDraftUpdated } from 'models/mail-drafts/cent';
import { MailDraftCentUpdated } from 'models/mail-drafts/types';
import { receivedNoticeItsReadEvent, receivedNoticeNewEvent } from 'models/notices';
import { receiveDeletePost, receivePost, receiveUpdatePost } from 'models/post';
import { Post, ReactionAddedNotify, ReactionRemovedNotify, ReceivedPost } from 'models/post/types';
import { tasksCreatedReceived } from 'models/task/cent';
import { TypingData } from 'models/user/types';
import {
  receivedWaGreenChatAttachment,
  receivedWaGreenChatMessage,
  receivedWaGreenChatMessageAck,
  receivedWaGreenChatMessageReaction,
  receivedWaGreenChatSeen,
} from 'models/wagreen/common';
import { receiveWaGreenUserChatsUpdate } from 'models/wagreen/for-user';
import { receivedWaGreenChatChanged, receivedWaGreenInstanceState } from 'models/wagreen/setup';
import { cent, centSubscribe, received, receivedPrivate, receivedV2 } from './index';
import { reactionAdded, reactionRemoved } from '../models/post/reaction';

const setSubUser = createEvent<(() => void) | null>();
const unsubUser = createEvent<any>();
const $subUser = createStore<(() => void) | null>(null).on(setSubUser, (_, f) => f);
sample({
  clock: unsubUser,
  source: $subUser,
}).watch((f) => {
  f?.();
  setSubUser(null);
});

// подключим центрифугу
firstInitFx.doneData.watch(
  ([
    {
      data: { id: userId },
    },
  ]) => {
    const { ctoken } = $auth.getState();
    cent.setToken(ctoken);

    if (!cent.isConnected()) cent.connect();

    setSubUser(centSubscribe(userId));
  },
);

logout.watch(() => {
  unsubUser();
  cent.disconnect();
});

received.watch((e) => {
  if (e.data.clientId === $auth.getState().clientId) return;
  //события, которые не зависят от выбранной компании
  e.data.events.forEach((c) => {
    switch (c.type) {
      case CentEventTypes.updateCompany:
        receiveUpdateCompany();
        break;
    }
  });
  if (e.data.companyId !== $currentCompanyIdOrNull.getState()) return;
  //события, которые зависят от выбранной компании
  e.data.events.forEach((c) => {
    switch (c.type) {
      case CentEventTypes.message:
        const post = c.data as ReceivedPost;
        receivePost(post);
        break;
      case CentEventTypes.deletePost:
        receiveDeletePost(c.data as Post);
        break;
      case CentEventTypes.updatePost:
        receiveUpdatePost(c.data as Post);
        break;
      case CentEventTypes.updateChannel:
        receiveUpdateChannel();
        break;
      case CentEventTypes.userTyping:
        receiveTypingStart(c.data as TypingData);
        break;
      case CentEventTypes.userEndTyping:
        receiveTypingEnd(c.data as TypingData);
        break;
      case CentEventTypes.updateCompany:
        break;
      case CentEventTypes.newMail2:
        handleNewMailReceived(c.data as MailNewNotify);
        break;
      case CentEventTypes.moveMail:
        mailPostMoved(c.data as MailPostMovedNotify);
        break;
      case CentEventTypes.deleteMail2:
        mailPostDeleted(c.data as MailPostDeleted);
        break;
      case CentEventTypes.mailDraftUpdate:
        mailDraftUpdated(c.data as MailDraftCentUpdated);
        break;
      case CentEventTypes.postReaction:
        reactionAdded(c.data as ReactionAddedNotify);
        break;
      case CentEventTypes.postRemoveReaction:
        reactionRemoved(c.data as ReactionRemovedNotify);
        break;
      default:
        console.warn('unknown cent event');
    }
  });
});

receivedV2.watch((data) => {
  switch (data.type) {
    case CentTeamEventV2.tasksCreated:
      tasksCreatedReceived(data.data.tasksId);
      break;

    case CentCompanyEventTypeBN.update:
      updateList(data.data);
      break;

    default:
      console.warn('[v2] unknown cent event');
  }
});

receivedPrivate.watch((e) => {
  if (process.env.NODE_ENV === 'development') {
    console.info('cent private', e);
  }
  switch (e.type) {
    // case CentPrivateEventTypePact.ConnectQR:
    //   receivedPactQR(e.data);
    //   break;
    // case CentPrivateEventTypePact.ConnectStatus:
    //   receivedPactConnectStatus(e.data);
    //   break;
    // case CentPrivateEventTypePact.SyncDone:
    //   receivedPactSyncDone(e.data);
    //   break;
    // case CentPrivateEventTypePact.ConvChanged:
    //   receivedPactConvChanged(e.data);
    //   break;
    // case CentPrivateEventTypePact.ChatMessage:
    //   receivedPactChatMessage(e.data);
    //   break;

    case CentPrivateEventTypeWaGreen.InstanceState:
      receivedWaGreenInstanceState(e.data);
      break;
    case CentPrivateEventTypeWaGreen.Chats:
      receivedWaGreenChatChanged(e.data);
      break;
    case CentPrivateEventTypeWaGreen.UserChats:
      receiveWaGreenUserChatsUpdate();
      break;
    case CentPrivateEventTypeWaGreen.ChatSeen:
      receivedWaGreenChatSeen(e.data);
      break;
    case CentPrivateEventTypeWaGreen.Message:
      receivedWaGreenChatMessage(e.data);
      break;
    case CentPrivateEventTypeWaGreen.MessageAck:
      receivedWaGreenChatMessageAck(e.data);
      break;
    case CentPrivateEventTypeWaGreen.MessageReaction:
      receivedWaGreenChatMessageReaction(e.data);
      break;
    case CentPrivateEventTypeWaGreen.ChatAttachment:
      receivedWaGreenChatAttachment(e.data);
      break;

    case CentPrivateEventTypeKontur.EVENT:
      receivedAccountEvent(e.data);
      break;
    case CentPrivateEventTypeKontur.SYNC_END:
      receivedSyncEndEvent(e.data);
      break;

    case CentPrivateEventTypeNotice.New:
      receivedNoticeNewEvent(e.data);
      break;
    case CentPrivateEventTypeNotice.ItsRead:
      receivedNoticeItsReadEvent(e.data);
      break;

    default:
      console.warn('unknown cent event', e);
  }
});
