import {
  ApiBadRequestError,
  ApiClientError,
  ApiConflictError,
  ApiForbiddenError,
  ApiGoneError,
  ApiNoMoneyError,
  ApiNotFoundError,
  ApiServerError,
  ApiServiceUnavailable,
  ApiUnauthorizedError,
  ApiUnprocessableError,
} from '../errors';
import convertResponseError from './convertResponseError';

export default function onRejectedConverter(error: any) {
  return Promise.reject(
    convertResponseError(error, {
      400: (r) => new ApiBadRequestError(r),
      401: (r) => new ApiUnauthorizedError(r),
      402: (r) => new ApiNoMoneyError(r),
      403: (r) => new ApiForbiddenError(r),
      404: (r) => new ApiNotFoundError(r),
      409: (r) => new ApiConflictError(r),
      410: (r) => new ApiGoneError(r),
      422: (r) => new ApiUnprocessableError(r),
      '4xx': (r) => new ApiClientError(r),
      503: (r) => new ApiServiceUnavailable(r),
      '5xx': (r) => new ApiServerError(r),
    }),
  );
}
