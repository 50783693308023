import { createEffect, createEvent, createStore, merge, sample } from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import * as RoMap from '@cubux/readonly-map';
import * as RoSet from '@cubux/readonly-set';
import {
  apiAuth,
  apiAuthUser,
  apiCompaniesInfo,
  apiCustomers,
  apiCustomersSync,
  apiGetOpenid,
  apiGetUserLink,
  apiOrgCreateCustomerFrom,
  apiOrgsList,
  apiOrgUpdate,
  apiUserDelete,
  apiUsersList,
  apiUserSync,
  OrgsListParams,
} from 'api/request/kontur';
import { PaginatedResponse2 } from 'api/types';
import { EMPTY_ARRAY, EMPTY_MAP, EMPTY_SET } from 'constants/utils';
import { showToast, ToastTypes } from 'ui/feedback';
import { $currentCompanyId } from '../company';
import { CompanyId } from '../company/types';
import { reloadCustomers } from '../customer';
import {
  KonturAuthForm,
  KonturAuthTokens,
  KonturCompany,
  KonturCompanyId,
  KonturCompanyInfo,
  KonturCompanyUpdateForm,
  KonturUnlinkParams,
  KonturUser,
  KonturUserId,
} from './types';
import { createRequestEffect } from '../utils/createRquestEffect';
import { paginationResultPick } from '../utils/defaultReducer';
import { pendingMap } from '../utils/pendingMap';
import { logout } from '../auth';
import { getChannelsFx } from '../channel';
import { history } from '../../utils/history';

export const receivedSyncEndEvent = createEvent<any>();

// export const $isActive = $integrationMap.map((map) => Boolean(map.get('kontur')?.active));

export const KonturGate = createGate();

// /** @deprecated dev stub */
// const DEV_USERS: readonly KonturUser[] = [
//   {
//     id: 9999999910 as any,
//     login: 'foo-bar' as any,
//     need_update: false,
//     organizations_related_count: 15,
//     organizations_count: 20,
//     created_at: '2022-09-20',
//     updated_at: '',
//     last_sync: '2022-10-05T12:34:56Z',
//   },
//   {
//     id: 9999999920 as any,
//     login: 'lorem-ipsum-dolor-sit-amet-consectepture' as any,
//     need_update: true,
//     organizations_related_count: 2,
//     organizations_count: 10,
//     created_at: '2022-08-14',
//     updated_at: '',
//     last_sync: '2022-10-05T12:34:56Z',
//   },
// ];
const getUsersFx = createEffect(
  async (teamId: CompanyId) => (await apiUsersList(teamId)).data ?? EMPTY_ARRAY,
  // DEV_USERS,
);
export const deleteUserFx = createEffect(
  ({ userId, ...params }: KonturUnlinkParams & { userId: KonturUserId }) =>
    apiUserDelete(userId, params),
);
export const syncUserFx = createEffect(
  async (userId: KonturUserId) => (await apiUserSync(userId)).data,
);

sample({
  clock: KonturGate.open,
  source: $currentCompanyId,
  filter: Boolean,
  target: getUsersFx,
});

export const $konturUsersLoading = getUsersFx.pending;
export const $konturUsers = createStore<readonly KonturUser[]>(EMPTY_ARRAY)
  .reset(KonturGate.close)
  .on(
    sample({
      clock: getUsersFx.done,
      source: $currentCompanyId,
      filter: (curId, { params: wasId }) => wasId === curId,
      fn: (_, { result }) => result,
    }),
    (_, list) => list,
  )
  .on(syncUserFx.doneData, (list, user) =>
    list.some(({ id }) => id === user.id)
      ? list.map((u) => (u.id === user.id ? user : u))
      : undefined,
  )
  .on(deleteUserFx.done, (list, { params: { userId } }) =>
    list.some(({ id }) => id === userId) ? list.filter((u) => u.id !== userId) : undefined,
  );
export const $konturUsersHasProblem = $konturUsers.map((list) => list.some((u) => u.need_update));

// ---------------

export const closeUserDetails = createEvent<KonturUserId>();
export const openUserDetails = createEvent<KonturUserId>();

export const $openedUserIds = createStore<ReadonlySet<KonturUserId>>(EMPTY_SET)
  .on(openUserDetails, RoSet.add)
  .on(closeUserDetails, RoSet.remove);

export const setOrgsPage = createEvent<{ userId: KonturUserId; page: number }>();

export const setUserOrgsFilter = createEvent<{ userId: KonturUserId; filter: string }>();
const _setOrgsFilter = sample({
  clock: setUserOrgsFilter,
  source: $openedUserIds,
  filter: (opened, { userId }) => opened.has(userId),
  fn: (_, c) => c,
});
export const $userOrgsFilter = createStore<ReadonlyMap<KonturUserId, string>>(EMPTY_MAP)
  .on(closeUserDetails, RoMap.remove)
  .on(_setOrgsFilter, (map, { userId, filter }) =>
    filter ? RoMap.set(map, userId, filter) : RoMap.remove(map, userId),
  );
sample({
  source: _setOrgsFilter,
  fn: ({ userId }) => ({ userId, page: 1 }),
  target: setOrgsPage,
});

// /** @deprecated dev stub */
// const DEV_COMPANIES: readonly KonturCompany[] = [
//   {
//     id: 88888810 as any,
//     name: 'ООО "Рога и Копыта"',
//     inn: '1234567890',
//     kpp: '33333333',
//     customer_team_id: null,
//   } as any,
//   {
//     id: 88888820 as any,
//     name: 'ИП "Водкин А.Б."',
//     inn: '987654321012',
//     kpp: '66666666',
//     customer_team_id: 'gt7g4w5fw3rhjr6wgwabc9xodw',
//   } as any,
// ];
type _OrgsListParams = { userId: KonturUserId } & OrgsListParams;
const getOrgsPagedFx = createEffect(
  async ({ userId, page, ...params }: _OrgsListParams) =>
    apiOrgsList(userId, {
      per_page: 100,
      page: page === 1 ? undefined : page,
      search: $userOrgsFilter.getState().get(userId),
      ...params,
    }),
  // ({ data: DEV_COMPANIES, page: 1, per_page: 100, total: DEV_COMPANIES.length }),
);
export const updateOrgFx = createEffect(
  async ({
    userId,
    orgId,
    ...form
  }: KonturCompanyUpdateForm & { userId: KonturUserId; orgId: KonturCompanyId }) =>
    (await apiOrgUpdate(userId, orgId, form)).data,
);
export const createCustomerFromOrgFx = createEffect(
  async ({ userId, orgId }: { userId: KonturUserId; orgId: KonturCompanyId }) =>
    (await apiOrgCreateCustomerFrom(userId, orgId)).data,
);
sample({ clock: createCustomerFromOrgFx.done, target: reloadCustomers });

sample({
  source: openUserDetails,
  fn: (id): _OrgsListParams => ({ userId: id }),
  target: getOrgsPagedFx,
});
sample({
  clock: setOrgsPage,
  source: $openedUserIds,
  filter: (opened, { userId }) => opened.has(userId),
  fn: (_, c) => c,
  target: getOrgsPagedFx,
});

const fetchOrgsDone = sample({
  clock: getOrgsPagedFx.done,
  source: $openedUserIds,
  filter: (opened, { params: { userId } }) => opened.has(userId),
  fn: (_, c) => c,
});
export const $userOrgsLoading = pendingMap(getOrgsPagedFx, ({ userId }) => userId);
export const $userOrgsPages = createStore<ReadonlyMap<KonturUserId, PaginatedResponse2>>(EMPTY_MAP)
  .on(closeUserDetails, RoMap.remove)
  .on(fetchOrgsDone, (map, { params: { userId }, result }) =>
    RoMap.set(map, userId, paginationResultPick(result)),
  );
export const $userOrgs = createStore<ReadonlyMap<KonturUserId, readonly KonturCompany[]>>(EMPTY_MAP)
  .on(closeUserDetails, RoMap.remove)
  .on(fetchOrgsDone, (map, { params: { userId }, result: { data } }) =>
    RoMap.set(map, userId, data ?? EMPTY_ARRAY),
  )
  .on(
    sample({
      clock: [updateOrgFx.done, createCustomerFromOrgFx.done],
      source: $openedUserIds,
      filter: (opened, { params: { userId } }) => opened.has(userId),
      fn: (_, c) => c,
    }),
    (map, { params: { userId }, result }) =>
      RoMap.update(map, userId, (list) => list.map((c) => (c.id === result.id ? result : c))),
  );

merge([
  getUsersFx.failData,
  deleteUserFx.failData,
  syncUserFx.failData,
  getOrgsPagedFx.failData,
  updateOrgFx.failData,
  createCustomerFromOrgFx.failData,
]).watch((e) => showToast(ToastTypes.error, e.message));

syncUserFx.done.watch(() =>
  showToast(ToastTypes.success, i18next.t('account:integrations.kontur.user.SyncStarted')),
);
updateOrgFx.done.watch(({ params: { customer_team_id } }) => {
  if (customer_team_id) {
    showToast(
      ToastTypes.success,
      i18next.t('account:integrations.kontur.companies.AssignmentSaved'),
    );
  }
});
createCustomerFromOrgFx.done.watch(() =>
  showToast(
    ToastTypes.success,
    i18next.t('account:integrations.kontur.companies.CreateCustomerFromKonturSuccess'),
  ),
);

receivedSyncEndEvent.watch(async () => {
  await getChannelsFx($currentCompanyId.getState() as CompanyId);

  showToast(ToastTypes.success, i18next.t('account:integrations.kontur.companies.SyncEnd'));
});

// ---------------
export const getOpenId = createEvent();
const getOpenIdFx = createRequestEffect(apiGetOpenid);
sample({ clock: getOpenId, target: getOpenIdFx });

getOpenIdFx.doneData.watch(({ data }) => {
  // https://test-app.finkoper.com/login?code=af7575e917c02c8267ee263eff90036fa23859a615e29cafa98d2a98ebd2c244&scope=openid%20profile%20email%20phone%20offline_access&state=TKgil0v7onIpDAPmRGCfpw&session_state=0Dwjsj27GEuapXYev6OwPICp5WZUcwi1j8ZtwBQ6pN4.74d15621af55b16a8360940918160d0b
  // http://localhost:3033/login-with/kontur?code=af7575e917c02c8267ee263eff90036fa23859a615e29cafa98d2a98ebd2c244&scope=openid%20profile%20email%20phone%20offline_access&state=TKgil0v7onIpDAPmRGCfpw&session_state=0Dwjsj27GEuapXYev6OwPICp5WZUcwi1j8ZtwBQ6pN4.74d15621af55b16a8360940918160d0b
  // login-with/kontur?code=c9c82171ad2947a483a6a952b00b524c3efe104014f0d93ec6a234a87b7a7a45&scope=openid%20profile%20email%20phone%20offline_access&state=nL5lQX65LQv6Wfc7UcVMUw&session_state=QyfwoKLgj1oPNByG0vH_bcbkOBm0V5ik8OEwqQkXzq0.1539191cb91b4548da3f29e184ac35da
  // http://localhost:3033/login-with/kontur?code=798e985b282e4d34687ab9d1f550826f43443eb888603912c0de836820e34333&scope=openid%20profile%20email%20phone%20extern.api%20offline_access&state=H2v8HcpjDcjYI63F5qlJsA&session_state=PD9KI4TrnyQ9WFJDwqJXBuaDCyiqNCe6GqXRvkA1AZ8.05ffafcf6c34015f93b29c836fd56f03
  //todo сделать функцию для открытия
  window.location.href = data;
  // window.open(
  //   data,
  //   '',
  //   `width=800,height=600,left=${(window.innerWidth - 800) / 2},top=${
  //     (window.innerHeight - 600) / 2
  //   }`,
  // );
});

// ---------------
export const authKontur = createEvent<KonturAuthForm>();
export const authKonturFx = createRequestEffect(apiAuth);
export const tokensReceived = createEvent<KonturAuthTokens>();
sample({ clock: authKontur, target: authKonturFx });

// ---------------
export const fetchCompaniesInfoFx = createRequestEffect(apiCompaniesInfo);
export const fetchCustomersFx = createRequestEffect(apiCustomers);
export const syncCustomersFx = createRequestEffect(apiCustomersSync);
export const setCurrentKonturCompanyId = createEvent<KonturCompanyId>();

export const $konturCompaniesInfo = createStore<KonturCompanyInfo[]>(EMPTY_ARRAY).reset(logout);
export const $currentKonturCompanyId = createStore<KonturCompanyId | null>(null).reset(logout);

$konturCompaniesInfo.on(fetchCompaniesInfoFx.doneData, (_, { data }) => data);
sample({ clock: setCurrentKonturCompanyId, target: $currentKonturCompanyId });
$currentKonturCompanyId.on(fetchCompaniesInfoFx.doneData, (_, { data }) => data[0].id);

/** authKonturUserFx */
// ---------------
export const loginWithCertClicked = createEvent<any>();
export const authKonturUser = createEvent<KonturAuthForm>();

export const getUserLinkFx = createRequestEffect(apiGetUserLink);
export const authKonturUserFx = createRequestEffect(apiAuthUser);
sample({ clock: authKonturUser, target: authKonturUserFx });

sample({
  clock: loginWithCertClicked,
  source: $currentCompanyId,
  target: getUserLinkFx,
  fn: (companyId) => companyId as CompanyId,
});
getUserLinkFx.doneData.watch(({ data }) => {
  window.location.href = data;
});
authKonturUserFx.done.watch(() => {
  history.push(`/settings/account/integrations/kontur`);
});
// ---------------

export const $isAuthFail = createStore(false);
$isAuthFail.on([authKonturFx.fail, authKonturUserFx.fail], () => true);
