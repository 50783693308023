import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { DenyOverlappedProps } from 'utils/types';
import { adaptiveClasses, AdaptiveProps } from '../../adaptive';
import { AlignItem, ColSpan, JustifyContent, JustifyItems } from '../types';
import classesFix from '../Grid.module.scss';
import classes from './Row.module.scss';

type BaseProps = HTMLAttributes<HTMLDivElement>;

export interface RowCaseProps {
  colsSpan?: ColSpan;
  gutter?: boolean;
  wrap?: boolean;
  alignItems?: AlignItem;
  justifyItems?: JustifyItems;
  justifyContent?: JustifyContent;
  // TODO: flex-direction: column; seems like less useful and not designed to use with `colsSpan`
}

export interface RowOwnProps extends AdaptiveProps<RowCaseProps> {}

export type RowProps = DenyOverlappedProps<RowOwnProps, BaseProps>;

// console.warn('>>>> ', classesFix, classes);

const cx = adaptiveClasses(
  (
    tag: string,
    { gutter, wrap, colsSpan, alignItems, justifyItems, justifyContent }: RowCaseProps,
  ) =>
    cn(
      colsSpan && classesFix[`colsSpan${tag}_${colsSpan}`],
      undefined !== gutter && classes[`gutter${tag}${gutter ? '' : '_off'}`],
      undefined !== wrap && classes[`wrap${tag}${wrap ? '' : '_off'}`],
      alignItems && classes[`alignItems${tag}_${alignItems}`],
      justifyItems && classes[`justifyItems${tag}_${justifyItems}`],
      justifyContent && classes[`justifyContent${tag}_${justifyContent}`],
    ),
);

export const Row: FC<RowProps> = ({
  gutter = false,
  wrap = true,
  colsSpan,
  alignItems,
  justifyItems,
  justifyContent,
  adaptive,
  className,
  ...rest
}) => (
  <div
    {...rest}
    className={cn(
      classesFix.row,
      cx({
        gutter,
        wrap,
        colsSpan,
        alignItems,
        justifyItems,
        justifyContent,
        adaptive,
      }),
      className,
    )}
  />
);
