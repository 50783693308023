import { combine, createEffect, createEvent, createStore, sample } from 'effector';
import { customersTagsApi } from 'api/request/customers-tags';
import { TagUpdateParam } from 'api/request/tags';
import { Callbacks } from 'components/tag';
import { isNotNull } from 'utils/fn';
import { $currentCompanyId, $currentCompanyIdOrNull } from '../company';
import { cmpTags, TagCreateForm, TagForm } from '../tags/types';
import { CustomerTag, CustomerTagId } from './types';
import { $customersChannelsItemsActual } from '../channel';

export const createCustomerTag = createEvent<TagForm>();
export const updateCustomerTag = createEvent<TagUpdateParam<CustomerTagId>>();
export const deleteCustomerTag = createEvent<CustomerTagId>();
export const selectCustomerTag = createEvent<CustomerTag>();
export const sortTags = createEvent<readonly CustomerTag[]>();

export const fetchCustomerTagsFx = createEffect(customersTagsApi.list);
export const createCustomerTagFx = createEffect(customersTagsApi.create);
export const updateCustomerTagFx = createEffect(customersTagsApi.update);
export const deleteCustomerTagFx = createEffect(customersTagsApi.delete);

export const $customerTags = createStore<readonly CustomerTag[]>([]).reset(
  $currentCompanyId.updates,
);
export const $customerTagsMap = $customerTags.map(
  (tags) => new Map(tags.map((tag) => [tag.id, tag])),
);
export const $usedCustomerTags = combine(
  $customerTags,
  $customersChannelsItemsActual,
  (tags, channels) => tags.filter((tag) => channels.some((item) => item.tags.includes(tag.id))),
);

export const TAG_CUSTOMERS_CALLBACKS: Callbacks<CustomerTagId> = {
  create: createCustomerTag,
  update: updateCustomerTag,
  delete: deleteCustomerTag,
  select: selectCustomerTag,
  sort: sortTags,
};

$customerTags
  .on(fetchCustomerTagsFx.doneData, (_, { data }) => data)
  .on(createCustomerTagFx.doneData, (s, { data }) => [...s, data])
  .on(updateCustomerTagFx.doneData, (s, { data }) =>
    s.map((c) => (c.id === data.id ? { ...c, ...data } : c)).sort(cmpTags),
  )
  .on(deleteCustomerTagFx.done, (s, { params }) => s.filter((c) => c.id !== params));

const $tagsNextSort = $customerTags.map((list) =>
  list.length ? list.reduce((r, v) => Math.max(r, v.sort), 0) + 1 : 0,
);

sample({
  clock: createCustomerTag,
  source: {
    bk: $currentCompanyIdOrNull,
    sort: $tagsNextSort,
  },
  target: createCustomerTagFx,
  filter: ({ bk }) => Boolean(bk),
  fn: ({ bk, sort }, form): TagCreateForm => ({
    bookkeeper_team_id: bk!,
    ...form,
    sort: form.sort ?? sort,
  }),
});

sample({
  clock: updateCustomerTag,
  target: updateCustomerTagFx,
});

sample({
  clock: deleteCustomerTag,
  target: deleteCustomerTagFx,
});

// REFACT: копипаста из тегов для задач
/** Сортировка */
type _SortItem = [CustomerTagId, number];
sample({
  clock: sample({
    clock: sortTags,
    source: $customerTags,
    fn: (prev, next) => {
      const prevMap = new Map(prev.map((o) => [o.id, o.sort]));
      return next
        .map<_SortItem | null>((o, i) => (prevMap.get(o.id) === i ? null : [o.id, i]))
        .filter(isNotNull);
    },
  }),
  source: $currentCompanyIdOrNull,
  filter: Boolean,
  fn: (bk, order) => ({ bk, order }),
}).watch(({ bk, order }) => {
  for (const [id, sort] of order) {
    updateCustomerTagFx({
      tagId: id,
      form: {
        bookkeeper_team_id: bk,
        sort,
      },
    }).catch(console.warn);
  }
});
