import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import delay from 'delay';
// import { getItem } from 'utils/persistent';
// import { API_CLIENT_ID } from 'constants/config';
// import { getItem } from 'store/persistent';
import { LSKey } from 'models/persistent';
import { samePendingPromise } from 'utils/promise';
import { ApiClientError, ApiUnauthorizedError } from './errors';
// import apiToken from './request/oauth';
import apiToken from './request/auth';
import {
  createAxios,
  onFulfillGetData,
  onRejectBlobJson,
  onRejectCatch,
  onRejectConverter,
} from './utils';
import { createPool } from './utils/AxiosInstancePool';
import { Auth } from '../models/auth/types';
import { onFulfillDownload } from './utils/onFulfillDownload';
import { onFulfillFile } from './utils/onFulfillFile';
// import { OAuthTokens } from './types/oauth';

// //todo
// const API_CLIENT_ID = ''
// const apiToken: any = {};

// import onFulfillDownload from './utils/on-fulfill-download';

interface GotTokensCallback {
  (tokens: Auth, requestedAt: Date): void;
}

interface ClearTokensCallback {
  (): void;
}

export interface AxiosRequestCustomConfig extends AxiosRequestConfig {
  _fileName?: string;
}

let handleGotTokens: GotTokensCallback | null = null;
let handleClearTokens: ClearTokensCallback | null = null;

export const onGotTokens = (handler: GotTokensCallback | null) => {
  handleGotTokens = handler;
};
export const onClearTokens = (handler: ClearTokensCallback | null) => {
  handleClearTokens = handler;
};

const pool = createPool();

export const authorize = pool.authorize;
export const unauthorize = pool.unauthorize;
export const setLanguage = pool.setLanguage;

const createAndRegister = (config?: AxiosRequestConfig): AxiosInstance =>
  pool.add(createAxios(config));

export const instanceNoAutoRefresh = createAxios();
instanceNoAutoRefresh.interceptors.response.use(onFulfillGetData, onRejectConverter);
instanceNoAutoRefresh.interceptors.response.use(undefined, onRejectCatch);

export const instanceDownloadNoAutoRefresh = createAxios();
instanceDownloadNoAutoRefresh.interceptors.response.use(
  // axios@^1: с типами наврали, но, судя по тестам, всё нормально, так можно по-прежнему
  onFulfillDownload /* axios@^1: as any*/,
  onRejectConverter,
);

/**
 * Реальное обновление токенов
 *
 * @param successAsyncDelay Интервал ожидание в мс. после обновления токенов перед следующим запросом
 * @param requestedAt
 * @return Промис резолвится со значением true, когда можно продолжать.
 */
export async function refreshTokensReally(
  successAsyncDelay = 500,
  requestedAt = new Date(),
): Promise<boolean> {
  //todo создать объкт в локалсторе
  // const refreshToken = getItem('refreshToken');
  const refreshToken = localStorage.getItem(LSKey.refreshToken);

  // данные есть и актуальны?
  if (undefined !== refreshToken) {
    try {
      // пытаемся обновить токен
      const { data } = await apiToken.refresh(instanceNoAutoRefresh, refreshToken!);
      if (!data.accesstoken || !data.refreshtoken)
        throw new ApiClientError({} as AxiosResponse, 'empty tokens');

      handleGotTokens?.(data, requestedAt);
      pool.authorize(data.accesstoken);
      // ожидание на возможную асинхронность токенов
      await delay(successAsyncDelay);

      return true;
    } catch (e) {
      if (process.env.NODE_ENV !== 'test') {
        console.log('API Refresh Token failed:', e);
      }
      if (e instanceof ApiClientError) {
        pool.unauthorize();
        handleClearTokens?.();
      }
    }
  } else {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Cannot refresh tokens', { refreshToken });
    }
    pool.unauthorize();
    handleClearTokens?.();
  }

  return false;
}

const refreshTokens = samePendingPromise(refreshTokensReally);

const onRejectedForRefresh =
  (instance: AxiosInstance, noAutoRefreshInstance: AxiosInstance) => async (error: any) => {
    if (error instanceof ApiUnauthorizedError) {
      if (await refreshTokens()) {
        const config = error.response.config;
        const headers = { ...config.headers };
        delete headers['Authorization'];
        // повторяем оригинальный запрос, но уже без повторных попыток обновить токен
        return await noAutoRefreshInstance.request({
          ...config,
          headers: {
            ...headers,
            ...instance.defaults.headers.common,
          },
        });
      }
    }

    return Promise.reject(error);
  };

export const dataOnly = createAndRegister();
// dataOnly.interceptors.response.use(onFulfillDeprecatedOnDev);
dataOnly.interceptors.response.use(onFulfillGetData, onRejectBlobJson);
dataOnly.interceptors.response.use(undefined, onRejectConverter);
dataOnly.interceptors.response.use(
  undefined,
  onRejectedForRefresh(dataOnly, instanceNoAutoRefresh),
);

export const downloadInstance = createAndRegister({ responseType: 'blob' });
downloadInstance.interceptors.response.use(
  // axios@^1: с типами наврали, но, судя по тестам, всё нормально, так можно по-прежнему
  onFulfillDownload /* axios@^1: as any*/,
  onRejectBlobJson,
);
downloadInstance.interceptors.response.use(undefined, onRejectConverter);
downloadInstance.interceptors.response.use(
  undefined,
  onRejectedForRefresh(downloadInstance, instanceDownloadNoAutoRefresh),
);

export const fileInstance = createAndRegister({ responseType: 'blob' });
fileInstance.interceptors.response.use(
  // axios@^1: с типами наврали, но, судя по тестам, всё нормально, так можно по-прежнему
  onFulfillFile /* axios@^1: as any*/,
  onRejectBlobJson,
);
fileInstance.interceptors.response.use(undefined, onRejectConverter);
fileInstance.interceptors.response.use(
  undefined,
  onRejectedForRefresh(fileInstance, instanceDownloadNoAutoRefresh),
);

export const rawInstance = createAndRegister();
rawInstance.interceptors.response.use(undefined, onRejectConverter);
rawInstance.interceptors.response.use(
  undefined,
  onRejectedForRefresh(rawInstance, instanceNoAutoRefresh),
);
