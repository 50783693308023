import { FC, ReactNode } from 'react';
import { Dialog, DialogProps } from '../Dialog';
import Icon, { IconRef } from '../../Icon';
import { useTranslation } from 'react-i18next';
import { Button2, TextButton2 } from '../../button';
import cl from './DescriptionPrompt.module.scss';
import cn from 'classnames';
import { reactModal } from '../reactModal';

export interface DescriptionPromptOptions {
  title: string | ReactNode;
  color: 'red' | 'green';
  lineIcon: IconRef;
  lines: string[] | ReactNode[];
  okLabel?: string;
}

interface Props extends Omit<DialogProps, 'title'>, DescriptionPromptOptions {
  onSubmit: (res: boolean) => void;
}

export const DescriptionPrompt: FC<Props> = ({
  title,
  color,
  lineIcon,
  lines,
  okLabel,
  onClose,
  onSubmit,
  ...rest
}) => {
  const { t } = useTranslation('');
  return (
    <Dialog className={cn(cl.dialog, cl[color])} onClose={onClose} {...rest}>
      <div className={cl.title}>{title}</div>
      <div className={cl.lines}>
        {lines.map((line, i) => (
          <div key={i} className={cl.line}>
            <Icon type={lineIcon} />
            <span>{line}</span>
          </div>
        ))}
      </div>
      <div className={cl.footer}>
        <Button2 size="large" onClick={() => onSubmit(true)}>
          {okLabel || t('ui:ok')}
        </Button2>
        <TextButton2 onClick={onClose}>{t('ui:cancel')}</TextButton2>
      </div>
    </Dialog>
  );
};

export const promptDescriptionPrompt = (options: DescriptionPromptOptions) =>
  reactModal<boolean>(({ show, onSubmit, onDismiss }) => (
    <DescriptionPrompt visible={show} {...options} onClose={onDismiss} onSubmit={onSubmit} />
  ));
