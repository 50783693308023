import type { NoInfer } from 'effector';
import { useEffect, useRef } from 'react';

interface Options<T extends object> {
  on?: <K extends keyof T>(k: K, v: NonNullable<T[K]>) => void;
  off?: <K extends keyof T>(k: K, v: NonNullable<T[K]>) => void;
}

/**
 * Вызывает `on(k,v)` при "появлении" `[k]===v` в `all`,
 * и соответственно `off(k,v)` перед "исчезновением" `[k]===v` в `all`.
 *
 * При изменении on или off выполняется старый off и новый on для всех имеющихся
 * данных в `all`.
 *
 * Назначение: куча событий, чтобы вызывать on/off только для изменившихся
 * обработчиков, а не для всех при каждом изменении `all`.
 */
export const useMapDiff = <O extends Record<string, any>>(
  all: O | null = null,
  options: Options<NoInfer<O>>,
) => {
  const r = useRef<Record<string, any>>();
  const io = useRef<Pick<typeof options, 'on' | 'off'>>();

  // при монтировании:
  // 1. io init (skip empty r)
  // 2. r init
  // 3. init r, all on
  //
  // при изменении on,off:
  // 1. all off для старого off
  // 2. all on для нового on
  //
  // при изменении all
  // 1. off r, которых нет в новом all
  // 2. on all, которых нет в r
  //
  // при отмонтировании:
  // 1. off r
  // 2. free io
  // 3. free r

  const { on, off } = options;
  useEffect(() => {
    io.current = { on, off };
    if (on && r.current) {
      for (const [k, v] of Object.entries(r.current)) {
        on(k, v);
      }
    }

    return () => {
      io.current = undefined;
      if (off && r.current) {
        for (const [k, v] of Object.entries(r.current)) {
          off(k, v);
        }
      }
    };
  }, [on, off]);

  useEffect(() => {
    r.current = {};
    return () => {
      r.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (!io.current) return;
    const { on, off } = io.current;
    const o = r.current;
    if (!o) return;

    for (const [k, v] of Object.entries(o)) {
      if (all && Object.hasOwn(all, k) && (all[k] ?? null) === v) continue;
      delete o[k];
      off?.(k, v);
    }
    if (all) {
      for (const [k, v = null] of Object.entries(all)) {
        if (v === null) continue;
        if (Object.hasOwn(o, k) && o[k] === v) continue;
        on?.(k, v);
        o[k] = v;
      }
    }
  }, [all]);
};
