import { containerWithHtml } from '../dom';

export const htmlFromRawFlow = (htmlRaw: string) => {
  const src = containerWithHtml(htmlRaw);
  const dst = document.createElement('div');
  let p: HTMLElement | null = null;
  while (src.firstChild) {
    const node = src.removeChild(src.firstChild);
    if (isInline(node)) {
      if (!p) {
        p = dst.appendChild(document.createElement('p'));
      }
      p.appendChild(node);
      continue;
    }
    p = null;
    dst.appendChild(node);
  }
  return dst.innerHTML;
};

const INLINE_ELEMENTS = new Set(['BR', 'B', 'I', 'STRONG', 'EM', 'A', 'CODE', 'IMG']);

function isInline(node: ChildNode) {
  switch (node.nodeType) {
    case document.TEXT_NODE:
      return true;
    case document.ELEMENT_NODE:
      if (node instanceof HTMLElement) {
        return INLINE_ELEMENTS.has(node.tagName);
      }
  }
  return false;
}
