import { AxiosError, CanceledError } from 'axios';
import i18next from 'i18next';
import toaster, { CTOptions } from 'toastify-react';
import { MouseEventHandler, ReactNode } from 'react';
import { htmlToPlainText } from 'utils/string';
import 'toastify-react/dist/index.css';
import './Toast.scss';
import cl from './Toast.module.scss';

export enum ToastTypes {
  success = 'success',
  warn = 'warn',
  info = 'info',
  error = 'error',
  loading = 'loading',
}

export const enum ToastDuration {
  DEFAULT = 'def',
  LONGER = 'longer',
  //VERY_LONG='very-long',
}

const onClick: MouseEventHandler = (e) => {
  if (e.isDefaultPrevented() || e.isPropagationStopped()) return;

  const g = e.currentTarget.querySelector('.ct-text-group');
  if (!g) return;
  let text = htmlToPlainText(g.innerHTML);

  const d = g.querySelector('.' + cl.details);
  if (d instanceof HTMLElement) {
    const details = d.dataset['details'];
    if (details) {
      text += '\n----\n' + details;
    }
  }

  if (!text) return;
  try {
    navigator.clipboard
      .writeText(text + '\n====\n' + navigator.userAgent)
      .then(() => showToast(ToastTypes.success, i18next.t('ui:messages.copySuccess')))
      .catch((e) => console.error('navigator.clipboard.writeText:', e));
  } catch (e) {
    console.error('navigator.clipboard.writeText:', e);
  }
};

const OPTIONS: CTOptions = {
  position: 'top-right',
  // hideAfter: 1000000,
  // heading:'dsfdfddffd',
  onClick,
};
const OPT_LONGER: CTOptions = {
  ...OPTIONS,
  hideAfter: 7,
};

const OPTS: Record<ToastDuration, CTOptions> = {
  [ToastDuration.DEFAULT]: OPTIONS,
  [ToastDuration.LONGER]: OPT_LONGER,
};

export const showToast = (
  type: ToastTypes,
  message: string | ReactNode,
  duration: ToastDuration = ToastDuration.DEFAULT,
) => {
  const opts = OPTS[duration];
  switch (type) {
    case ToastTypes.success:
      toaster.success(message, opts);
      break;
    case ToastTypes.warn:
      toaster.warn(message, opts);
      break;
    case ToastTypes.info:
      toaster.info(message, opts);
      break;
    case ToastTypes.error:
      toaster.error(message, opts);
      break;
    case ToastTypes.loading:
      toaster.loading(message, opts);
      break;
  }
};

export const showToastError = (e?: unknown) => {
  if (e instanceof CanceledError) {
    return;
  }
  let msg: string | undefined;
  if (e instanceof DOMException) {
    msg = e.name;
  } else if (e instanceof AxiosError) {
    msg = e.code;
  }
  if (e) {
    console.log('Toast Error:', e);
  }
  showToast(
    ToastTypes.error,
    <>
      {i18next.t('ui:messages.commonError')}
      {msg && (
        <>
          <br />
          {'\n'}({msg})
        </>
      )}
      {e instanceof Error && (
        <span className={cl.details} data-details={`${e.message}:\n${e.stack}`} />
      )}
    </>,
  );
};
