import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { Input, LabeledCheckbox } from '../../input';
import { HighlightedString, Popover2 } from '../../display';

import cl from './SelectButton.module.scss';
import Icon from 'ui/Icon';
import { useTranslation } from 'react-i18next';
import * as RoSet from '@cubux/readonly-set';
import cn from 'classnames';
import isequal from 'lodash.isequal';
import { TextButton2 } from '../Button';

export interface SelectButtonItem<ID> {
  id: ID;
  label: string;
}

interface Props<ID = string> {
  emptyLabel?: string;
  items: SelectButtonItem<ID>[];
  selected?: ID[];
  onSelect: (ids: ID[]) => void;
  className?: string;
}

// t('ui:showAll')
// t('ui:showSelected')

// Не стал наследовать от DropDownButton, чтобы не усложнять реализацию последней.
export function SelectButton<ID>({
  items,
  selected,
  emptyLabel = '',
  onSelect,
  className,
}: Props<ID>) {
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false);
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [filter, setFilter] = useState('');
  const [_selected, setSelected] = useState<ReadonlySet<ID>>(() => new Set(selected));
  useEffect(() => {
    if (selected !== undefined) {
      setSelected(new Set(selected));
    }
  }, [selected]);
  const [isShowSelected, setIsShowSelected] = useState(false);

  const filteredItems = useMemo(() => {
    const _filter = filter.toLowerCase();
    return items.filter(
      (c) =>
        c.label.toLowerCase().includes(_filter) && (isShowSelected ? _selected.has(c.id) : true),
    );
  }, [_selected, filter, isShowSelected, items]);

  const handleClick = (e: MouseEvent) => {
    setIsShow(true);
    e.stopPropagation();
  };

  const closePopover = () => {
    setIsShow(false);
    setFilter('');

    const newSelected = [..._selected];
    if (!isequal(selected, newSelected)) {
      onSelect(newSelected);
    }
  };

  const handleChangeSelected = (id: ID) => () => {
    setSelected(RoSet.toggle(_selected, id));
  };

  const handleToggleShowSelected = () => {
    setIsShowSelected((isShow) => !isShow);
  };

  const isUnselect = _selected.size || _selected.size === items.length;
  const handleSelectAll = () => {
    if (isUnselect) {
      setSelected(new Set());
    } else {
      setSelected(new Set(items.map((c) => c.id)));
    }
  };

  const selectedLen = selected?.length ?? 0;

  return (
    <>
      <button
        onClick={handleClick}
        ref={setRef}
        className={cn(cl.button, !selectedLen && cl.empty, className)}
        data-tip={
          selected?.length
            ? selected.map((id) => items.find((c) => c.id === id)?.label ?? 'N/A').join('\n')
            : undefined
        }
      >
        {selectedLen ? (
          <>
            <span className={cl.label}>
              {items.find((c) => c.id === selected![0])?.label ?? 'N/A'}
            </span>
            {selectedLen > 1 && <span className={cl.qty}>+{selectedLen - 1}</span>}
          </>
        ) : (
          emptyLabel
        )}
      </button>
      <Popover2 onClickOutside={closePopover} isShow={isShow} anchorEl={ref} withPortal>
        {isShow && (
          <div className={cl.content}>
            <div className={cl.header}>
              <Input
                inputSize="small"
                startWith={<Icon type="Search" />}
                allowClear
                autoFocus
                onChange={(e) => setFilter(e.target.value)}
              />
              <div className={cl.info}>
                <div>{t('ui:selected', { N: _selected.size })}</div>
                <TextButton2 size="small" className={cl.blue} onClick={handleSelectAll}>
                  {t(isUnselect ? 'ui:unselect' : 'ui:selectAll')}
                </TextButton2>
              </div>
            </div>
            <div className={cl.items}>
              {filteredItems.map((c) => (
                <LabeledCheckbox
                  key={String(c.id)}
                  className={cl.item}
                  label={<HighlightedString text={c.label} searchedText={filter} />}
                  checked={_selected.has(c.id)}
                  onChange={handleChangeSelected(c.id)}
                />
              ))}
            </div>
            <div className={cl.footer}>
              <TextButton2 size="small" onClick={handleToggleShowSelected} className={cl.blue}>
                {t(isShowSelected ? 'ui:showAll' : 'ui:showSelected')}
              </TextButton2>
              <TextButton2 size="small" onClick={closePopover} className={cl.blue}>
                {t('ui:close')}
              </TextButton2>
            </div>
          </div>
        )}
      </Popover2>
    </>
  );
}
