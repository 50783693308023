import { FC, ReactNode } from 'react';
import { useParseString } from './hooks';

const useParseChildren = (children?: ReactNode) => {
  const str = useParseString(children);
  if (str) {
    const [email, text = email] = str.split('|', 2);
    return { email, text };
  }
};

export const Mailto: FC = ({ children }) => {
  const { email, text = children } = useParseChildren(children) || {};
  return <a href={`mailto:${email || '-bad-'}`}>{text}</a>;
};
