import i18next from 'i18next';
import { EMPTY_ARRAY } from 'constants/utils';
import { IdNumberOf, OptOutRestProps, WritablePart } from 'utils/types';
import { maxValidator, requiredValidator } from 'utils/validation';
import {
  NestedValidationResult,
  NestedValidationResultSimple,
  normalizeNestedErrors,
} from 'utils/validation/nested';
import { CompanyId } from '../company/types';

// ------------------------------------------------------------------

export const enum TariffWorkAreaName {
  primary_processing = 'primary_processing',
  salary_and_staff = 'salary_and_staff',
  reporting_taxes = 'reporting_taxes',
  extra_services = 'extra_services',
}
// возможно, тут можно было бы сделать `keyof Pick<Tariff, ...>`, но хз, так ли
// они совпадают по именам
export const enum TariffWorkAreaTarget {
  type_of_ownership = 'type_of_ownership',
  taxation_system = 'taxation_system',
  number_of_operations = 'number_of_operations',
  number_of_employees = 'number_of_employees',
  additional_services = 'additional_services',
}
export type TariffWorkAreaMapRaw = Readonly<
  Record<TariffWorkAreaName, readonly TariffWorkAreaTarget[]>
>;
export type TariffWorkAreaMap = ReadonlyMap<TariffWorkAreaName, ReadonlySet<TariffWorkAreaTarget>>;

// ------------------------------------------------------------------

const tOrNull = (msgReference: string | null | undefined): NestedValidationResultSimple | null =>
  msgReference ? i18next.t(msgReference) : null;

const _validateQty = (q: number, min = -Infinity, max = Infinity) =>
  q < min ? 'Минимальное значение ' + min : q > max ? 'Максимальное значение ' + max : null;

const _validateIntQty = (q: number, min = -Infinity, max = Infinity) =>
  q !== Math.round(q) ? 'Укажите целое число' : _validateQty(q, min, max);

const _validateCost = (v: number, canBeZero = false) =>
  canBeZero ? (v >= 0 ? null : 'Укажите сумму от 0') : v > 0 ? null : 'Укажите сумму больше 0';

// ----------------

export interface TariffFieldValue {
  title: string;
  cost: number;
}
type ETariffFieldValue = NestedValidationResult<TariffFieldValue>;
type ETariffFieldValues = NestedValidationResult<TariffFieldValue[]>;
const validateTariffFieldValue = (v: TariffFieldValue) =>
  normalizeNestedErrors<ETariffFieldValue>({
    title: { $: tOrNull(requiredValidator(v.title.trim())) },
    cost: { $: _validateCost(v.cost, true) },
  });
const validateTariffFieldValues = (values: readonly TariffFieldValue[]) =>
  normalizeNestedErrors<ETariffFieldValues>(values.map(validateTariffFieldValue));

// ----------------

export interface NumberOfOperationsValue {
  from: number;
  to: number;
  cost: number;
  mode: TariffsCostMode;
}
type ENumberOfOperationsValue = NestedValidationResult<NumberOfOperationsValue>;
const validateNumberOfOperationsValue = (v: NumberOfOperationsValue) =>
  normalizeNestedErrors<ENumberOfOperationsValue>({
    from: { $: _validateIntQty(v.from, 0) },
    to: { $: tOrNull(requiredValidator(v.to)) || _validateIntQty(v.to, Math.max(1, v.from)) },
    cost: { $: _validateCost(v.cost, true) },
  });

export const enum TariffsOperationsMode {
  CALC = 'calc',
  EACH = 'each',
}

export interface NumberOfOperations {
  mode: TariffsOperationsMode;
  values: readonly NumberOfOperationsValue[];
}
type ENumberOfOperations = NestedValidationResult<NumberOfOperations>;
const validateNumberOfOperations = (v: NumberOfOperations) =>
  normalizeNestedErrors<ENumberOfOperations>({
    values: v.values.map(validateNumberOfOperationsValue),
  });

// ----------------

export const enum TariffsCostMode {
  ALL = 'all',
  EACH = 'each',
}

export interface NumberOfEmployees {
  from: number;
  to: number;
  cost: number;
  mode: TariffsCostMode;
  is_resident: boolean;
}
type ENumberOfEmployees = NestedValidationResult<NumberOfEmployees>;
type ENumberOfEmployeesList = NestedValidationResult<NumberOfEmployees[]>;
const validateNumberOfEmployees = (v: NumberOfEmployees) =>
  normalizeNestedErrors<ENumberOfEmployees>({
    from: { $: _validateIntQty(v.from, 0) },
    to: { $: tOrNull(requiredValidator(v.to)) || _validateIntQty(v.to, Math.max(1, v.from)) },
    cost: { $: _validateCost(v.cost) },
  });
const validateNumberOfEmployeesList = (v: readonly NumberOfEmployees[]) =>
  normalizeNestedErrors<ENumberOfEmployeesList>(v.map(validateNumberOfEmployees));

// ----------------

export const enum TariffAdditionalServiceUnit {
  fixed = 'fixed',
  hour = 'hour',
}

export interface TariffAdditionalServiceRaw extends TariffFieldValue {
  unit?: TariffAdditionalServiceUnit | '';
}
export interface TariffAdditionalService extends TariffFieldValue {
  unit: TariffAdditionalServiceUnit;
}
const isTariffAdditionalService = (s: TariffAdditionalServiceRaw): s is TariffAdditionalService =>
  Boolean(s.unit);
const fixTariffAdditionalService = (s: TariffAdditionalServiceRaw): TariffAdditionalService =>
  isTariffAdditionalService(s)
    ? s
    : {
        ...s,
        // unit: s.unit || TariffAdditionalServiceUnit.fixed,
        unit: TariffAdditionalServiceUnit.fixed,
      };

type ETariffAdditionalService = NestedValidationResult<TariffAdditionalService>;
type ETariffAdditionalServices = NestedValidationResult<TariffAdditionalService[]>;
const validateTariffAdditionalService = (v: TariffAdditionalService) =>
  normalizeNestedErrors<ETariffAdditionalService>({
    ...validateTariffFieldValue(v),
    unit: { $: tOrNull(requiredValidator(v.unit)) },
  });
const validateTariffAdditionalServices = (values: readonly TariffAdditionalService[]) =>
  normalizeNestedErrors<ETariffAdditionalServices>(values.map(validateTariffAdditionalService));

// ----------------

export const enum TariffsDiscountMode {
  percent = 'percent',
  sum = 'sum',
}

export interface TariffDiscount {
  title: string;
  cost: number;
  mode: TariffsDiscountMode;
  work_area: TariffWorkAreaName;
}

type ETariffDiscount = NestedValidationResult<TariffDiscount>;
const validateTariffDiscount = (v: TariffDiscount) =>
  normalizeNestedErrors<ETariffDiscount>({
    title: { $: tOrNull(requiredValidator(v.title.trim())) },
    cost: {
      $:
        v.mode === TariffsDiscountMode.percent
          ? _validateQty(v.cost, 0, 100)
          : _validateCost(v.cost, true),
    },
  });
type ETariffDiscountList = NestedValidationResult<readonly TariffDiscount[]>;
const validateTariffDiscountList = (values: readonly TariffDiscount[]) =>
  normalizeNestedErrors<ETariffDiscountList>(values.map(validateTariffDiscount));

// ----------------

export interface TariffAdditionalServicesHandbook {
  services: readonly TariffAdditionalService[];
  defaultPricePerHour: number;
}

// ----------------

export type TariffId = IdNumberOf<'Tariff'>;

export interface _TariffRaw {
  readonly id: TariffId;
  readonly bookkeeper_team_id: CompanyId;
  title: string;
  type_of_ownership: readonly TariffFieldValue[] | null;
  taxation_system: readonly TariffFieldValue[] | null;
  number_of_operations: {
    mode: TariffsOperationsMode;
    values: readonly NumberOfOperationsValue[] | null;
  };
  number_of_employees: readonly NumberOfEmployees[] | null;
  additional_services: readonly TariffAdditionalServiceRaw[] | null;
  increments: readonly TariffDiscount[] | null;
  discounts: readonly TariffDiscount[] | null;
  cost_per_hour: number;
}
export interface Tariff extends _TariffRaw {
  type_of_ownership: readonly TariffFieldValue[];
  taxation_system: readonly TariffFieldValue[];
  number_of_operations: NumberOfOperations;
  number_of_employees: readonly NumberOfEmployees[];
  additional_services: readonly TariffAdditionalService[];
  increments: readonly TariffDiscount[];
  discounts: readonly TariffDiscount[];
}
export const TARIFF_TITLE_MAX_LENGTH = 31;
export type ETariff = NestedValidationResult<Tariff>;
export const validateTariff = (v: Tariff) =>
  normalizeNestedErrors<ETariff>({
    title: { $: tOrNull(maxValidator(v.title.trim(), TARIFF_TITLE_MAX_LENGTH, true)) },
    type_of_ownership: validateTariffFieldValues(v.type_of_ownership),
    taxation_system: validateTariffFieldValues(v.taxation_system),
    number_of_operations: validateNumberOfOperations(v.number_of_operations),
    number_of_employees: validateNumberOfEmployeesList(v.number_of_employees),
    additional_services: validateTariffAdditionalServices(v.additional_services),
    increments: validateTariffDiscountList(v.increments),
    discounts: validateTariffDiscountList(v.discounts),
    cost_per_hour: { $: _validateCost(v.cost_per_hour, true) },
  });

export const fixRawTariff = ({
  type_of_ownership,
  taxation_system,
  number_of_operations,
  number_of_employees,
  additional_services,
  increments,
  discounts,
  ...rest
}: _TariffRaw): Tariff => ({
  ...rest,
  type_of_ownership: type_of_ownership ?? EMPTY_ARRAY,
  taxation_system: taxation_system ?? EMPTY_ARRAY,
  number_of_operations: {
    ...number_of_operations,
    values: number_of_operations.values ?? EMPTY_ARRAY,
  },
  number_of_employees: number_of_employees ?? EMPTY_ARRAY,
  additional_services: additional_services
    ? additional_services.map(fixTariffAdditionalService)
    : EMPTY_ARRAY,
  increments: increments ?? EMPTY_ARRAY,
  discounts: discounts ?? EMPTY_ARRAY,
});

export interface TariffCreateForm
  extends Omit<OptOutRestProps<WritablePart<Tariff>, 'title'>, 'number_of_operations'> {
  number_of_operations: OptOutRestProps<NumberOfOperations, 'mode'>;
}

// export interface TariffUpdateForm extends Partial<Omit<TariffCreateForm, 'number_of_operations'>> {
//   number_of_operations?: Partial<NumberOfOperations>;
// }
export interface TariffUpdateForm extends TariffCreateForm {}

export interface TariffMinimal extends Readonly<Pick<Tariff, 'id' | 'title'>> {}

export const newCreateForm = (): TariffCreateForm => ({
  title: 'Новый тариф',
  // type_of_ownership: [],
  // taxation_system: [],
  number_of_operations: {
    mode: TariffsOperationsMode.CALC,
    // values: [],
  },
  // number_of_employees: [],
  // additional_services: [],
  // increments: [],
  // discounts: [],
  // cost_per_hour: 0,
});

// export const applyUpdate = (prev: Tariff, update?: TariffUpdateForm): Tariff => {
//   if (!update) {
//     return prev;
//   }
//   let next: Tariff | undefined;
//
//   let { number_of_operations, cost_per_hour, ...rest } = update;
//
//   if (number_of_operations) {
//     type T = typeof number_of_operations;
//     type K = keyof T;
//     for (const key of keysTuple<K>()(['mode', 'values'])) {
//       const value = number_of_operations[key];
//       if (value !== undefined && value !== prev.number_of_operations[key]) {
//         (
//           (next ??= {
//             ...prev,
//             number_of_operations: {
//               ...prev.number_of_operations,
//             },
//           }).number_of_operations as any
//         )[key] = value;
//       }
//     }
//   }
//   if (cost_per_hour !== undefined && cost_per_hour > 0) {
//     (next ??= { ...prev }).cost_per_hour = cost_per_hour;
//   }
//
//   for (const [key, value] of Object.entries(rest) as ObjectEntries<typeof rest>) {
//     if (value !== undefined && value !== prev[key]) {
//       ((next ??= { ...prev }) as any)[key] = value;
//     }
//   }
//
//   return next ?? prev;
// };
