import { FC } from 'react';
import Icon from 'ui/Icon';
import { Color } from 'utils/types';
import { TextButton2 } from '../../button';
import cl from './ColorSelector.module.scss';

interface Props {
  value?: Color | null;
  canClear?: boolean;
  onChange?: (color: Color) => void;
  onChangeAny?: (color: Color | null) => void;
  onClear?: (color: null) => void;
  colors?: Color[];
}

const DEFAULT_COLORS: Color[] = [
  '#F44D4D',
  '#4DA4F4',
  '#8CF44D',
  '#4DF4B8',
  '#654DF4',
  '#C94DF4',
  '#F44D9E',
  '#F4A74D',
  '#F4E44D',
  '#000000',
  '#5EA405',
  '#B56E1B',
  '#ED5A04',
  '#CDBA0A',
  '#0778E1',
  '#9F0A0A',
  '#7F08A8',
  '#049012',
  '#CD0365',
  '#16047E',
  '#0086B0',
  '#766B05',
  '#024358',
  '#04AD0A',
  '#F13107',
] as Color[];

export const DEFAULT_COLORS_LIGHT: Color[] = [
  '#ACE2DF',
  '#ACD5E2',
  '#ACC1E2',
  '#D4ACE2',
  '#E2ACAC',
  '#E36161',
  '#EF9191',
  '#EFAD91',
  '#EFBE91',
  '#E39861',
  '#9261E3',
  '#AA61E3',
  '#E361D6',
  '#E3B761',
  '#E3D661',
  '#91B2EF',
  '#61B4E3',
  '#61DBE3',
  '#61E37E',
  '#9AE361',
  '#6BA09D',
  '#6CA06B',
  '#7C6BA0',
  '#A06B85',
  '#A06B6B',
] as Color[];

export const ColorSelector: FC<Props> = ({
  value,
  onChange,
  onChangeAny,
  onClear,
  canClear = Boolean(onChangeAny || onClear),
  colors = DEFAULT_COLORS,
}) => (
  <div className={cl.root}>
    {colors.map((c, _1, _2, isCurrent = value === c) => (
      <div
        key={c}
        className={cl.colorItem}
        style={{ backgroundColor: c }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (canClear && isCurrent) {
            onClear?.(null);
            onChangeAny?.(null);
          } else {
            onChange?.(c);
            onChangeAny?.(c);
          }
        }}
      >
        {isCurrent && <Icon type="Check" />}
        {canClear && isCurrent && (
          // импровизация, чтобы юзеру на глаз было ясно, что выбор можно снять
          <TextButton2
            type="button"
            size="small"
            icon="Cross"
            className={cl.clear}
            onClick={
              (onClear || onChangeAny) &&
              (() => {
                onClear?.(null);
                onChangeAny?.(null);
              })
            }
          />
        )}
      </div>
    ))}
  </div>
);
