import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cl from './DatesList.module.scss';
import { Input } from 'ui/input';
import { TextButton2 } from 'ui/button';
import { getUniqKey } from 'utils/helpers';
import cn from 'classnames';

interface Props {
  dates?: string[];
  errorIdxs?: Array<boolean>;
  onChange?: (dates: string[]) => void;
}
interface Option {
  date: string;
  key: string;
}

//todo переделать этот компонент - унаследовать от OptionList

export const DatesList: FC<Props> = ({ dates, onChange, errorIdxs = [] }) => {
  const { t } = useTranslation('ui');
  const [options, setOptions] = useState<Array<Option>>(
    () =>
      dates?.map((date, i) => ({
        date,
        key: i.toString(),
      })) ?? [],
  );

  const onChanged = (options: Option[]) => {
    setOptions(options);
    onChange?.(options.map((c) => c.date));
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    const key = e.currentTarget.dataset.key;
    const newOptions = options.filter((c) => c.key !== key);
    onChanged(newOptions);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: {
        value,
        dataset: { key },
      },
    } = e;

    const newOptions = options.map((c) => (c.key === key ? { ...c, date: value } : c));
    onChanged(newOptions);
  };

  const handleAdd = () => {
    onChanged([...options, { date: '', key: getUniqKey(options.length.toString()) }]);
  };

  return (
    <div className={cl.root}>
      {options.map(({ date, key }, i) => (
        <div key={key} className={cn(cl.item, errorIdxs[i] && cl.error)}>
          <Input type="date" data-key={key} value={date} onChange={handleChange} />
          <TextButton2
            size="small"
            className={cn(options.length < 2 && cl.hide)}
            icon="Trash"
            uiColor="danger"
            data-key={key}
            onClick={handleRemove}
          />
        </div>
      ))}
      <TextButton2 icon="PlusCircle" className={cl.btnAdd} onClick={handleAdd} size="small">
        {t('ui:add')}
      </TextButton2>
    </div>
  );
};
