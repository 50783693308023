import { createEffect, createEvent, EffectParams, EventPayload, sample } from 'effector';
import { pending } from 'patronum';
import {
  apiTaskChecklistItem,
  TaskChecklistItemCreateParams,
  TaskChecklistItemDeleteParams,
  TaskChecklistItemListParams,
  TaskChecklistItemUpdateParams,
} from 'api/request/task-checklist-item';
import { Task } from 'models/task/types';
import { $checklist } from 'models/task-checklist';
import { $currentCompanyIdOrNull } from 'models/company';
import { toastErrorFx } from 'models/utils/messages';
import { showToast, ToastTypes } from 'ui/feedback';
import i18next from 'i18next';
import { TaskChecklistItem, TaskChecklistItemId } from 'models/task-checklist-item/types';
import { createRequestEffect } from '../utils/createRquestEffect';
import { ForwardType } from 'routes/main/routes/task/components/TaskEditModal/modelRepeatPrompt';

export const fetchTaskChecklistItemFx = createEffect(
  async (params: TaskChecklistItemListParams) => await apiTaskChecklistItem.list(params),
);
export const $taskChecklistItems = $checklist.map((c) => (c ? c.items : c));

/** Create */
export const createTaskChecklistItem = createEvent<{
  task: Task;
  text: string;
  // apply_to_repeatable?: boolean;
  forwardType?: ForwardType;
}>();
export const createTaskChecklistItemFx = createRequestEffect(apiTaskChecklistItem.create);
sample({
  clock: createTaskChecklistItem,
  source: [$checklist, $currentCompanyIdOrNull] as const,
  target: createTaskChecklistItemFx,
  fn: ([checklist, companyId], { task, text, forwardType }): TaskChecklistItemCreateParams => ({
    taskId: task.id,
    checklistId: checklist!.id,
    form: {
      bookkeeper_team_id: companyId!,
      team_id: task.team_id,
      is_typical: task.is_typical,
      state: 'incomplete',
      text,
      apply_to_repeatable: forwardType === 'customer' || forwardType === 'all',
      for_teams:
        forwardType === 'customer' && (task as Task).team_id
          ? [(task as Task).team_id!]
          : undefined,
    },
  }),
});

$checklist.on(createTaskChecklistItemFx.doneData, (s, { data }) =>
  s
    ? {
        ...s,
        items: [
          ...(s.items || []),
          Array.isArray(data) ? data.find((c) => c.task_checklist_id === s.id)! : data,
        ],
      }
    : s,
);

/** Update */
export const updateTaskChecklistItem = createEvent<{
  task: Task;
  item: TaskChecklistItem;
  // apply_to_repeatable?: boolean;
  forwardType?: ForwardType;
}>();
export const updateTaskChecklistItemFx = createRequestEffect(apiTaskChecklistItem.update);
sample({
  clock: updateTaskChecklistItem,
  source: [$checklist, $currentCompanyIdOrNull] as const,
  target: updateTaskChecklistItemFx,
  fn: ([checklist, companyId], { task, item, forwardType }): TaskChecklistItemUpdateParams => ({
    taskId: task.id,
    checklistId: checklist!.id,
    item_id: item.id,
    form: {
      bookkeeper_team_id: companyId!,
      team_id: task.team_id,
      is_typical: task.is_typical,
      state: item.state,
      text: item.text,
      apply_to_repeatable: forwardType === 'customer' || forwardType === 'all',
      for_teams:
        forwardType === 'customer' && (task as Task).team_id
          ? [(task as Task).team_id!]
          : undefined,
    },
  }),
});

$checklist.on(updateTaskChecklistItemFx.doneData, (s, { data }) =>
  s ? { ...s, items: (s.items || []).map((c) => (c.id === data.id ? data : c)) } : s,
);
export const toggleTaskChecklistItem = createEvent<{
  task: Task;
  item: TaskChecklistItem;
  apply_to_repeatable?: boolean;
}>();
sample({
  clock: toggleTaskChecklistItem,
  target: updateTaskChecklistItem,
  fn: ({ item, task, apply_to_repeatable }): EventPayload<typeof toggleTaskChecklistItem> => ({
    task,
    item: {
      ...item,
      state: item.state === 'incomplete' ? 'complete' : 'incomplete',
    },
    apply_to_repeatable,
  }),
});

/** Delete */
export const deleteTaskChecklistItem = createEvent<{
  task: Task;
  item_id: TaskChecklistItemId;
  // apply_to_repeatable?: boolean;
  forwardType?: ForwardType;
}>();
export const deleteTaskChecklistItemFx = createEffect(
  async (params: TaskChecklistItemDeleteParams) => await apiTaskChecklistItem.delete(params),
);
sample({
  clock: deleteTaskChecklistItem,
  source: [$checklist, $currentCompanyIdOrNull] as const,
  target: deleteTaskChecklistItemFx,
  fn: ([checklist, companyId], { item_id, task, forwardType }): TaskChecklistItemDeleteParams => ({
    taskId: task.id,
    checklistId: checklist!.id,
    item_id,
    params: {
      bookkeeper_team_id: companyId!,
      team_id: task.team_id,
      is_typical: task.is_typical,
      apply_to_repeatable: forwardType === 'customer' || forwardType === 'all',
      for_teams:
        forwardType === 'customer' && (task as Task).team_id
          ? [(task as Task).team_id!]
          : undefined,
    },
  }),
});

$checklist.on(deleteTaskChecklistItemFx.done, (s, { params: { item_id } }) =>
  s ? { ...s, items: (s.items || []).filter((c) => c.id !== item_id) } : s,
);

/** Sort */
export const sortTaskChecklistItem = createEvent<{
  task: Task;
  sort: TaskChecklistItemId[];
  forwardType?: ForwardType;
}>();
export const sortTaskChecklistItemFx = createEffect(apiTaskChecklistItem.sort);
sample({
  clock: sortTaskChecklistItem,
  source: { companyId: $currentCompanyIdOrNull, checklist: $checklist },
  filter: ({ checklist }) => !!checklist,
  target: sortTaskChecklistItemFx,
  fn: (
    { companyId, checklist },
    { task, sort, forwardType },
  ): EffectParams<typeof sortTaskChecklistItemFx> => ({
    taskId: task.id,
    checklistId: checklist!.id,
    form: {
      bookkeeper_team_id: companyId!,
      sort,
      apply_to_repeatable: forwardType === 'customer' || forwardType === 'all',
      for_teams:
        forwardType === 'customer' && (task as Task).team_id
          ? [(task as Task).team_id!]
          : undefined,
    },
  }),
});

/** Уведомления */
sample({
  clock: [
    createTaskChecklistItemFx.fail,
    updateTaskChecklistItemFx.fail,
    deleteTaskChecklistItemFx.fail,
    sortTaskChecklistItemFx.fail,
  ],
  target: toastErrorFx,
});
sample({
  clock: [
    createTaskChecklistItemFx.done,
    deleteTaskChecklistItemFx.done,
    updateTaskChecklistItemFx.done,
  ],
  target: createEffect(() => {
    showToast(ToastTypes.success, i18next.t('task:edit.checklist.message.successUpdate'));
  }),
});

export const $pending = pending({
  effects: [
    createTaskChecklistItemFx,
    updateTaskChecklistItemFx,
    deleteTaskChecklistItemFx,
    sortTaskChecklistItemFx,
  ],
});
