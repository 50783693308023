import { FC, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';
import { IView } from '../../types';
import cl from './NoContent.module.scss';

type HeightMode = '100%';
type Direction = 'V' | 'H';

interface Props extends IView, Partial<RouteComponentProps> {
  image?: ReactNode;
  statement?: ReactNode;
  advice?: ReactNode;
  heightMode?: HeightMode | null;
  direction?: Direction;
}

const heightClass: Record<HeightMode, string | undefined> = {
  '100%': cl._100,
};
const directionClass: Record<Direction, string | undefined> = {
  V: cl._vert,
  H: cl._horz,
};

export const NoContent: FC<Props> = ({
  image,
  statement = <Trans i18nKey="ui:nothingHereYet" />,
  advice,
  heightMode,
  direction,
  className,
  // remove:
  history,
  location,
  match,
  staticContext,
  ...rest
}) => (
  <div
    {...rest}
    className={cn(
      cl.root,
      heightMode && heightClass[heightMode],
      direction && directionClass[direction],
      className,
    )}
  >
    {image !== undefined && <div className={cl.image}>{image}</div>}
    <div className={cl.text}>
      {statement} {advice}
    </div>
  </div>
);
