import { RefObject, useEffect, useState } from 'react';

type Size = readonly [left: number, top: number];
const STUB: Size = [0, 0];

export const useElementScrollOffset = (ref: RefObject<HTMLElement>) => {
  const [size, setSize] = useState<Size>();

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    const handler = () => {
      let { scrollLeft: x, scrollTop: y } = el;
      x = Math.round(x);
      y = Math.round(y);
      setSize((prev) => (prev && prev[0] === x && prev[1] === y ? prev : [x, y]));
    };

    handler();
    el.addEventListener('scroll', handler);
    return () => {
      el.removeEventListener('scroll', handler);
    };
  }, [ref]);

  return size || STUB;
};
