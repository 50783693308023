import { FC, Suspense, useCallback, useMemo } from 'react';
import { Prompt, reactModal, RenderPromptProps } from 'ui/feedback';
import { TFuncKey, useTranslation } from 'react-i18next';
import { RadioButton, RadioButtons } from 'ui/input';
import { TFunction } from 'i18next';
import { LoadingPage } from '../../page';
import cl from './RepeatableTaskPrompt.module.scss';
import { Task } from 'models/task/types';
import { isNotNull } from 'utils/fn';
import {
  $forwardCustomerIds,
  $promptType,
  PromptType,
  promptTypeChanged,
  ForwardCustomerListGate,
  $listPending,
} from './model';
import { useGate, useStore } from 'effector-react';
import { $customersLightMap } from 'models/customer/list-light';

interface Props extends RenderPromptProps<PromptType> {
  task: Task;
  title: TFuncKey;
}

export const RepeatableTaskPrompt: FC<Props> = ({ show, task, onSubmit, onDismiss, title }) => {
  const { t } = useTranslation('task');
  const promptType = useStore($promptType);

  const { bulk_settings_id } = task;

  const buttons = useMemo<RadioButton<PromptType>[]>(
    () =>
      (
        [
          {
            value: 'notForward',
            label: (t: TFunction) => t('task:edit.repeatSubmit.notForward'),
          },
          {
            value: 'customerForward',
            label: (t: TFunction) => t('task:edit.repeatSubmit.onlyCustomerForward'),
          },
          bulk_settings_id
            ? {
                value: 'forward',
                label: (t: TFunction) => t('task:edit.repeatSubmit.forward'),
              }
            : undefined,
        ] as Array<RadioButton<PromptType> | undefined>
      ).filter(isNotNull),
    [bulk_settings_id],
  );

  const handleOk = useCallback(() => onSubmit(promptType), [onSubmit, promptType]);

  return (
    <Prompt open={show} onOk={handleOk} onClose={onDismiss} title={t(title)}>
      <RadioButtons
        buttons={buttons}
        value={promptType}
        onChange={promptTypeChanged}
        className={cl.radioButtons}
        orientation="column"
      />
      {promptType === 'forward' && <ForwardCustomerList task={task} />}
    </Prompt>
  );
};

interface ForwardCustomerListProps {
  task: Task;
}

export const ForwardCustomerList: FC<ForwardCustomerListProps> = ({ task }) => {
  const { t } = useTranslation('task');
  useGate(ForwardCustomerListGate, { task });

  const forwardCustomerIds = useStore($forwardCustomerIds);
  const customersMap = useStore($customersLightMap);
  const pending = useStore($listPending);

  return (
    <div className={cl.customerList}>
      <div className={cl.customerListTitle}>{t('task:edit.repeatDelete.customerListTitle')}:</div>
      {pending ? (
        <LoadingPage tint />
      ) : (
        forwardCustomerIds?.map((id) => <div key={id}>{customersMap.get(id)?.name}</div>)
      )}
    </div>
  );
};

export const repeatableTaskPrompt = (task: Task, title: TFuncKey) =>
  reactModal<PromptType>((props) => (
    <Suspense fallback={<LoadingPage cover />}>
      <RepeatableTaskPrompt {...props} task={task} title={title} />
    </Suspense>
  ));
