import cn from 'classnames';
import { DenyProps } from 'utils/types';
import { AdaptiveCases } from './AdaptiveCases';
import { AdaptiveRange, AdaptiveRangeClassTags } from './AdaptiveRange';

interface WithAdaptiveProp<P extends object> {
  adaptive?: AdaptiveCases<P>;
}

export type AdaptiveProps<P extends object & DenyProps<P, keyof WithAdaptiveProp<any>>> = P &
  WithAdaptiveProp<P>;

export const adaptiveClasses =
  <P extends object & DenyProps<P, keyof WithAdaptiveProp<any>>>(
    cl: (classTag: string, opts: P) => string,
  ) =>
  ({ adaptive, ...rest }: AdaptiveProps<P>): string =>
    cn(
      cl('', rest as P),
      adaptive &&
        (Object.keys(adaptive) as AdaptiveRange[]).map((range) => {
          const opts = adaptive[range];
          if (opts) {
            return cl(AdaptiveRangeClassTags[range], opts);
          }
          return null;
        }),
    );
