import cn from 'classnames';
import { ComponentProps, FC, useCallback, useState } from 'react';
import { Context } from './context';
import clD from './PopupContainer.dev.module.scss';

const clDev = process.env.NODE_ENV === 'development' ? clD : undefined;

type Props = ComponentProps<'div'>;

const nextIndex = (() => {
  let lastIndex = 0;
  return () => ++lastIndex;
})();

/** #260 */
export const PopupContainer: FC<Props> = ({ children, id, ...rest }) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const getElement = useCallback((): HTMLElement => element || window.document.body, [element]);
  const [index] = useState(nextIndex);

  return (
    <>
      {element && <Context.Provider value={getElement}>{children}</Context.Provider>}
      <div
        {...rest}
        ref={setElement}
        // for using with portals
        id={id ?? `popup-container_${index}`}
        {...(clDev && { className: cn(clDev.makeExpand, rest.className) })}
      />
    </>
  );
};
