import { createEvent, sample, split } from 'effector';
import { createGate } from 'effector-react';
import {
  CentPrivateEventWaGreenChatAttachment,
  CentPrivateEventWaGreenChatMessages,
  CentPrivateEventWaGreenChatMessagesReaction,
  CentPrivateEventWaGreenChatSeen,
} from '../cent/types-wagreen';
import { $currentCompanyId } from '../company';
import { notifyPlaySound } from '../notify/sound';
import { WithWaGreenGroupId } from './types';

export const WaGreenChatGate = createGate<WithWaGreenGroupId>();
export const $wagreenUserCurrentChatId = WaGreenChatGate.state.map(
  ({ group_id }) => group_id || null,
);

export const receivedWaGreenChatMessage = createEvent<CentPrivateEventWaGreenChatMessages>();
export const receivedWaGreenChatMessageAck = createEvent<CentPrivateEventWaGreenChatMessages>();
export const receivedWaGreenChatMessageReaction =
  createEvent<CentPrivateEventWaGreenChatMessagesReaction>();
export const receivedWaGreenChatSeen = createEvent<CentPrivateEventWaGreenChatSeen>();
export const receivedWaGreenChatAttachment = createEvent<CentPrivateEventWaGreenChatAttachment>();

export const receiverActiveChatMessage = createEvent<CentPrivateEventWaGreenChatMessages>();
export const receivedActiveChatMessageReaction =
  createEvent<CentPrivateEventWaGreenChatMessagesReaction>();
export const receivedActiveChatAttachment = createEvent<CentPrivateEventWaGreenChatAttachment>();
export const receiverInactiveChatMessage = createEvent<CentPrivateEventWaGreenChatMessages>();
sample({
  source: receiverInactiveChatMessage,
  filter: ({ silent }) => !silent,
  target: notifyPlaySound,
});
split({
  source: sample({
    clock: receivedWaGreenChatMessage,
    source: {
      teamId: $currentCompanyId,
      groupId: $wagreenUserCurrentChatId,
    },
    filter: (cur, rcv) => rcv.teamId === cur.teamId,
    fn: (cur, rcv) => ({ ...rcv, inActiveChat: rcv.chatId === cur.groupId }),
  }),
  match: {
    active: (p) => p.inActiveChat,
  },
  cases: {
    active: receiverActiveChatMessage,
    __: receiverInactiveChatMessage,
  },
});
sample({
  clock: receivedWaGreenChatAttachment,
  source: {
    teamId: $currentCompanyId,
    groupId: $wagreenUserCurrentChatId,
  },
  filter: (cur, rcv) => rcv.teamId === cur.teamId && rcv.chatId === cur.groupId,
  fn: (_, rcv) => rcv,
  target: receivedActiveChatAttachment,
});
sample({
  clock: receivedWaGreenChatMessageReaction,
  source: {
    teamId: $currentCompanyId,
    groupId: $wagreenUserCurrentChatId,
  },
  filter: (cur, rcv) => rcv.teamId === cur.teamId && rcv.chatId === cur.groupId,
  fn: (_, rcv) => rcv,
  target: receivedActiveChatMessageReaction,
});
