import { createBrowserHistory } from 'history';
import { promptFn } from 'ui/feedback';
import i18next from 'i18next';

export const history = createBrowserHistory({
  getUserConfirmation: async (message, callback) => {
    const done = await promptFn(message || i18next.t('ui:routerPrompt'));
    callback(done);
  },
});
