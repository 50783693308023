import { combine, createEffect, createEvent, createStore, restore, sample } from 'effector';
import { Company, CompanyCreateForm, CompanyId, CompanyUpdateParams } from './types';
import { ApiResponse } from 'api/types';
import { AccessUpdateParams, DeleteUserParams } from 'api/request/company';
import { CommonUser, UserId } from 'models/user/types';
import { IRoleData, Roles } from 'models/utils/roles';
import { LSKey } from '../persistent';

export const $companies = createStore<Company[]>([]);
export const $hasOneCompany = $companies.map((c) => c.length < 2);

export const resetCompanies = createEvent<any>();
export const getCompaniesFx = createEffect<void, ApiResponse<Company[]>>();
export const createCompanyFx = createEffect<CompanyCreateForm, ApiResponse<Company>>();

export const setCurCompany = createEvent<CompanyId | ''>();
// REFACT: по идее по умолчанию должно быть пусто, а значение из стора должно устанавливаться отдельно
const _$currentCompanyIdRaw = restore(
  setCurCompany,
  (localStorage.getItem(LSKey.company) as CompanyId) || '',
);

export const $curCompany = combine($companies, _$currentCompanyIdRaw, (companies, id) =>
  companies.length > 0 ? companies.find((c) => c.id === id) || companies[0] : null,
);
export const $currentCompanyId = $curCompany.map((c) => (c ? c.id : ('' as const)));
export const $currentCompanyIdOrNull = $curCompany.map((c) => (c ? c.id : null));
export const $currentCompanyRegion = $curCompany.map((c) => (c && c.region) || null);

export const currentCompanyIdWasSet = sample({
  clock: $currentCompanyIdOrNull.updates,
  filter: Boolean,
  fn: (): unknown => undefined,
});

export const initAttachCompanyDataFx = createEffect<CompanyId, Promise<any>>();

/** Update */
export const updateCompanyFx = createEffect<CompanyUpdateParams, ApiResponse<Company>>();

/** Access */
export const updateAccess = createEvent<{ userId: UserId; role: Roles }>();
export const toggleAccess = createEvent<CommonUser>();
export const toggleBlockAccess = createEvent<CommonUser>();
export const revertAccess = createEvent<CommonUser>();
export const updateAccessFx = createEffect<AccessUpdateParams, ApiResponse<IRoleData>>();

/** Delete User*/
export const deleteUser = createEvent<UserId>();
export const deleteUserFx = createEffect<DeleteUserParams, ApiResponse<void>>();
