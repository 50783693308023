import { FC, useMemo } from 'react';
import {
  BlockChildren,
  BlockElement,
  BlockType,
  ElementType,
  InlineElement,
  InlineType,
  LinkType,
  parseWhatsAppMessageToRender,
  SpanType,
} from 'utils/string/parseWhatsAppMessageToRender';

const RenderInline: FC<{ node: InlineElement }> = ({ node }) => {
  if (typeof node === 'string') {
    return <>{node}</>;
  }
  const content = node.content.map((node, i) => <RenderInline key={i} node={node} />);
  switch (node.inline) {
    case InlineType.SPAN:
      switch (node.span) {
        case SpanType.BOLD:
          return <strong>{content}</strong>;
        case SpanType.ITALIC:
          return <em>{content}</em>;
        case SpanType.STRIKE:
          return <del>{content}</del>;
        default:
          if (process.env.NODE_ENV === 'development') {
            return <code data-tip={JSON.stringify(node)}>inline:span:{(node as any)?.span}</code>;
          }
          return null;
      }

    case InlineType.LINK:
      switch (node.link) {
        case LinkType.WEB:
          return (
            <a href={node.href} target="_blank" rel="noopener noreferrer">
              {content}
            </a>
          );
        case LinkType.EMAIL:
          return <a href={node.href}>{node.content}</a>;
        default:
          if (process.env.NODE_ENV === 'development') {
            return <code data-tip={JSON.stringify(node)}>inline:link:{(node as any)?.link}</code>;
          }
          return null;
      }

    default:
      if (process.env.NODE_ENV === 'development') {
        return <code data-tip={JSON.stringify(node)}>inline:{(node as any)?.inline}</code>;
      }
      return null;
  }
};

const RenderBlock: FC<{ node: BlockElement }> = ({ node: block }) => {
  const content = block.content.map((node, i) => <RenderBlockChildren key={i} node={node} />);

  switch (block.block) {
    case BlockType.PARA:
      return <p>{content}</p>;

    default:
      if (process.env.NODE_ENV === 'development') {
        return <code data-tip={JSON.stringify(block)}>block:{(block as any)?.block}</code>;
      }
      return null;
  }
};

const RenderBlockChildren: FC<{ node: BlockChildren[number] }> = ({ node }) =>
  typeof node === 'string' || node.element === ElementType.INLINE ? (
    <RenderInline node={node} />
  ) : (
    <RenderBlock node={node} />
  );

const useWhatsAppMessageRender = (message: string) =>
  useMemo(
    () =>
      parseWhatsAppMessageToRender(message).map((block, i) => <RenderBlock key={i} node={block} />),
    [message],
  );

interface Props {
  message?: string;
}

export const WhatsAppMessageRender: FC<Props> = ({ message }) => (
  <>{useWhatsAppMessageRender(message ?? '')}</>
);
