import { useEffect, useRef } from 'react';
import { isDefined } from 'utils/fn';

/**
 * Returns constant handler to trigger all given handlers
 *
 * The result is consistent by ref to be useful with promises in callbacks.
 *
 * @param handlers
 */
export const useRefHandlers = <H extends (...args: any[]) => void>(
  ...handlers: (H | undefined)[]
) => {
  const refResult = useRef<H>();

  useEffect(
    () => {
      const given = handlers.filter(isDefined);
      switch (given.length) {
        case 0:
          refResult.current = undefined;
          break;

        case 1:
          [refResult.current] = given;
          break;

        default:
          refResult.current = ((...args) => {
            for (let handler of given) {
              handler(...args);
            }
          }) as H;
          break;
      }

      return () => {
        refResult.current = undefined;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handlers,
  );

  return refResult as Readonly<typeof refResult>;
};
