import { useCallback, useState } from 'react';
import * as RoSet from '@cubux/readonly-set';
import { EMPTY_SET } from 'constants/utils';
import { AnyKey, TreeViewDataProps } from './types';

/**
 * Реализация разворачивания `expanded={}` и `onToggle={}` через React state, когда
 * не хочется или нет необходимости делать это через эффектор.
 *
 * ```ts
 * <TreeView
 *   idGetter={idGetter}
 *   isLeafFn={isLeaf}
 *   dataStruct={treeData}
 *   {...useTreeViewToggleState<ID>()}
 * />
 * ```
 */
export const useTreeViewToggleState = <ID extends AnyKey>(): Required<
  Pick<TreeViewDataProps<any, ID, any, any>, 'expanded' | 'onToggle'>
> => {
  const [expanded, setExpanded] = useState<ReadonlySet<ID>>(EMPTY_SET);
  return {
    expanded,
    onToggle: useCallback(
      (id: ID, expand: boolean) => setExpanded((s) => RoSet.toggle(s, id, expand)),
      [],
    ),
  };
};
