import { ReactNode } from 'react';
import cl from './Picker.module.scss';
import cn from 'classnames';

export interface PickerOption<T> {
  value: T;
  label: ReactNode | string;
}

export interface PickerProps<T> {
  options: PickerOption<T>[];
  selected: T[];
  onChange: (values: T[]) => void;
}

export function Picker<T>({ options, selected, onChange }: PickerProps<T>) {
  const handleClick = (value: T) => {
    const ret = [...selected];
    const idx = ret.indexOf(value);
    if (idx < 0) ret.push(value);
    else ret.splice(idx, 1);

    onChange(ret);
  };

  return (
    <div className={cl.root}>
      <div className={cl.options}>
        {options.map((c, i) => (
          <button
            key={i}
            className={cn(cl.button, selected.includes(c.value) && cl.selected)}
            onClick={handleClick.bind(null, c.value)}
          >
            {c.label}
          </button>
        ))}
      </div>
    </div>
  );
}
