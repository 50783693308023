import { attach, combine, createEffect, createEvent, createStore, sample } from 'effector';
import { and } from 'patronum';
import { integrationsApi } from 'api/request/integrations';
import { ToastTypes } from 'ui/feedback';
import { toISODateTimeString } from 'utils/date';
import { ObjectEntries } from 'utils/types';
import { $currentCompanyId, $currentCompanyIdOrNull } from '../company';
import { notification, toastErrorFx } from '../utils/messages';
import {
  Integration,
  IntegrationCanCall,
  INTEGRATIONS_PHONE,
  IntegrationSaveForm,
  IntegrationType,
} from './types';
import { callNumber as UIS_callNumber, getUISTokenList, uisCanCall } from './uis';
import { fetchUISEmployees } from './uis/employees';
import {
  $zadarmaCanCall,
  callNumber as zadarma_callNumber,
  zadarmaCallActive,
  zadarmaCallPrepare,
} from './zadarma';

/** Fetch list*/
export const fetchIntegrationListFx = createEffect(integrationsApi.list);
export const $integrationList = createStore<Integration[]>([]).on(
  fetchIntegrationListFx.doneData,
  (_, { data }) => data,
);
export const $integrationMap = $integrationList.map(
  (list) => new Map(list.map((c) => [c.service, c])),
);

/** Save */
export const saveIntegrationFx = createEffect(integrationsApi.save);
export const toggleActive = createEvent<IntegrationType>();
export const saveApiKey = createEvent<{ api_key: string; service: IntegrationType }>();
export const saveApiKeyFx = attach({ effect: saveIntegrationFx });
//номер разрешается сохранять только при включенной интеграции
export const saveVirtualNumber = createEvent<{
  virtual_number: string;
  service: IntegrationType;
}>();
export const saveVirtualNumberFx = attach({ effect: saveIntegrationFx });
sample({
  clock: toggleActive,
  source: [$currentCompanyIdOrNull, $integrationMap] as const,
  target: saveIntegrationFx,
  fn: ([companyId, integrationMap], service): IntegrationSaveForm => {
    const integration = integrationMap.get(service);
    return {
      service,
      bookkeeper_team_id: companyId!,
      active: !integration?.active,
      api_key: integration?.api_key ?? '',
      virtual_number: integration?.virtual_number ?? '',
    };
  },
});
sample({
  clock: saveApiKey,
  source: [$currentCompanyIdOrNull, $integrationMap] as const,
  target: saveApiKeyFx,
  fn: ([companyId, integrationMap], { api_key, service }): IntegrationSaveForm => {
    const integration = integrationMap.get(service);
    return {
      api_key,
      service,
      bookkeeper_team_id: companyId!,
      active: !!integration?.active,
      virtual_number: integration?.virtual_number ?? '',
    };
  },
});
sample({
  clock: saveVirtualNumber,
  source: $integrationMap,
  target: saveVirtualNumberFx,
  fn: (integrationMap, { virtual_number, service }): IntegrationSaveForm => {
    const integration = integrationMap.get(service)!;
    return {
      ...integration,
      virtual_number,
    };
  },
});
$integrationList.on(saveIntegrationFx.done, (s, { params }) => {
  if (s.some((c) => c.service === params.service)) {
    return s.map((c) => (c.service === params.service ? { ...c, ...params } : c));
  } else {
    const now = toISODateTimeString(new Date());
    return [...s, { ...params, created_at: now, updated_at: now, api_key: '' }];
  }
});

const $isZadarmaActive = $integrationMap.map((m) => Boolean(m.get('zadarma')?.active));
sample({
  clock: [$isZadarmaActive, $currentCompanyId],
  source: and($isZadarmaActive, $currentCompanyId),
  target: zadarmaCallActive,
});

const CAN_CALL_FN: Partial<Record<IntegrationType, IntegrationCanCall>> = {
  uis: uisCanCall,
};
const $canCall = combine<Partial<Record<IntegrationType, boolean>>>({
  zadarma: $zadarmaCanCall,
});
//Какие интеграции звонков включены.
export const $hasPhoneIntegrations = combine(
  $integrationMap,
  $canCall,
  (m, canCall): ReadonlySet<IntegrationType> =>
    new Set(
      INTEGRATIONS_PHONE.filter((type) => {
        const int = m.get(type);
        return int?.active && (canCall[type] || CAN_CALL_FN[type]?.(int));
      }),
    ),
);
//Включена ли интеграция для звонков.
export const $hasPhoneIntegration = $hasPhoneIntegrations.map((s) => s.size > 0);
// При включении UIS инеграции, запросим список токенов
const $hasUisIntegration = $hasPhoneIntegrations.map((s) => s.has('uis'));
sample({
  clock: [$hasUisIntegration, $currentCompanyId],
  source: and($hasUisIntegration, $currentCompanyId),
  filter: Boolean,
  target: [getUISTokenList, fetchUISEmployees],
});
const $hasZadarmaIntegration = $hasPhoneIntegrations.map((s) => s.has('zadarma'));
sample({
  clock: [$hasZadarmaIntegration, $currentCompanyId],
  source: and($hasZadarmaIntegration, $currentCompanyId),
  filter: Boolean,
  target: zadarmaCallPrepare,
});

const CALL_FN: Partial<Record<IntegrationType, (number: string) => any>> = {
  uis: UIS_callNumber,
  zadarma: zadarma_callNumber,
};
// Звонок на номер через что угодно
export const callNumberWhatever = createEvent<string>();
sample({
  clock: callNumberWhatever,
  source: $hasPhoneIntegrations,
  fn: (i, number) => ({ i, number }),
}).watch(({ i, number }) => {
  for (const [service, call] of Object.entries(CALL_FN) as ObjectEntries<typeof CALL_FN>) {
    if (call && i.has(service)) {
      return call(number);
    }
  }
});
// Звонок на номер через конкретный сервис
export const callNumberVia = createEvent<{ number: string; service: IntegrationType }>();
sample({
  clock: callNumberVia,
  source: $hasPhoneIntegrations,
  filter: (i, { service }) => i.has(service),
  fn: (_, c) => c,
}).watch(({ service, number }) => CALL_FN[service]?.(number));

/** Notifications */
sample({
  clock: saveIntegrationFx.fail,
  target: toastErrorFx,
});
// t('account:integrations.apiKeySaveSuccess')
notification({
  clock: saveApiKeyFx.done,
  mode: ToastTypes.success,
  tKey: 'account:integrations.apiKeySaveSuccess',
});
// t('account:integrations.virtualNumberSaveSuccess')
notification({
  clock: saveVirtualNumberFx.done,
  mode: ToastTypes.success,
  tKey: 'account:integrations.virtualNumberSaveSuccess',
});
