import { createEffect, createEvent, createStore } from 'effector';
import { ApiResponse } from 'api/types';
import { Invite, InviteConfirm, InviteCreateForm } from './types';
import { CompanyId } from '../company/types';

/** $inviteList */
export const $inviteList = createStore<Invite[]>([]);

export const getInviteListFx = createEffect<CompanyId | undefined, ApiResponse<Invite[]>>();
export const getInviteCompanyList = createEvent();

export const createInviteFx = createEffect<InviteCreateForm, ApiResponse<Invite>>();

export const confirmInviteFx = createEffect<string, ApiResponse<InviteConfirm>>();
