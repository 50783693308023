import { render } from 'react-dom';
import './index.scss';
import App from './App';

// #278
const root = document.getElementById('root')!;
while (root.firstChild) {
  root.removeChild(root.firstChild);
}

// не надо react StrictMode - с ним одни проблемы
// - effector Gate триггерится дважды
// - axios запросы запускает дважды и резолвит дважды
render(<App />, root);
