import { dataOnly, downloadInstance } from 'api/instance';
import {
  API_CORE_REMOTE,
  ApiResponse,
  ApiResponse2,
  PaginatedResponse2,
  PaginationParams,
} from 'api/types';
import { WithBookkeeper, WithCustomer } from 'api/types/request';
import {
  Customer,
  CustomerAttachTagForm,
  CustomerCreateForm,
  CustomerEmployee,
  CustomerEmployeeCreateFormParams,
  CustomerEmployeeDeleteParams,
  CustomerEmployeeUpdateFormParams,
  CustomerId,
  CustomerItem,
  CustomerItemLight,
  CustomersEmployeesAssignment,
  CustomersEmployeesParams,
  CustomersListFilter,
  CustomersListOptions,
  CustomerUpdateForm,
  TaxbookItem,
  TelegramUrlData,
  UpdateCustomerContractorRelationsParams,
  UpdateCustomerContractorRelationsResponse,
} from 'models/customer/types';
import { CompanyId } from 'models/company/types';
import { Channel } from 'models/channel/types';
import { CustomerUserLightUnscoped } from 'models/customer/customer-user';
import { serializeFieldsFilter } from 'models/customer/fn';
import { CustomerTagId } from 'models/customers-tags/types';

export const apiCustomersListLight = ({ bookkeeper_team_id }: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse<readonly CustomerItemLight[]>>(
    `/api/v2/customers/light/${bookkeeper_team_id}`,
  );

export const apiCustomersEmployees = ({
  bookkeeper_team_id,
  ...params
}: WithBookkeeper & CustomersEmployeesParams) =>
  dataOnly.get<null, ApiResponse<CustomersEmployeesAssignment>>(
    `/api/v2/customers/employees/${bookkeeper_team_id}`,
    { params },
  );

const serializeCustomersListPagedParams = <P extends WithBookkeeper & CustomersListFilter>({
  bookkeeper_team_id,
  typical,
  props,
  ...rest
}: P) => {
  const q = new URLSearchParams();
  if (typical?.size) {
    serializeFieldsFilter(typical, {
      to: q,
      param: 'typical',
    });
  }
  if (props?.size) {
    serializeFieldsFilter(props, {
      to: q,
      param: 'props',
    });
  }
  for (const [k, v] of Object.entries(rest)) {
    if (v === undefined) continue;
    (Array.isArray(v) ? v : [v]).forEach((v) => {
      switch (typeof v) {
        case 'string':
        case 'number':
        case 'boolean':
          q.append(k, String(v));
          break;
        default:
          q.append(k, String(v));
          console.warn('qs value type', { k, v });
      }
    });
  }
  return q;
};

export const apiCustomersListPaged = (
  p: WithBookkeeper & PaginationParams & CustomersListFilter & CustomersListOptions,
) => {
  const { bookkeeper_team_id } = p;
  return dataOnly.get<null, ApiResponse<CustomerItem[]> & PaginatedResponse2>(
    `/api/v2/customers/paged/${bookkeeper_team_id}`,
    { params: serializeCustomersListPagedParams({ ...p, with_cu: true }) },
  );
};
export const apiCustomersListExport = (p: WithBookkeeper & CustomersListFilter) => {
  const { bookkeeper_team_id } = p;
  return downloadInstance.get<null, ApiResponse<CustomerItem[]> & PaginatedResponse2>(
    `/api/v2/customers/paged/${bookkeeper_team_id}`,
    { params: serializeCustomersListPagedParams({ ...p, export: true }) },
  );
};

const api = {
  /**
   * https://trello.com/c/1pYzpD0X/568
   * Добавился `?employees=employeeID1,employeeIDn` , но не пригодился
   *
   * @deprecated Полный список заменить на `apiCustomersListLight()`,
   * а в настройках клиентов заменить на `apiCustomersListPaged()`.
   * Затем избавиться от этого запроса.
   */
  list: (companyId: CompanyId) =>
    dataOnly.get<null, ApiResponse<CustomerItem[], true>>(`/api/v2/customers/${companyId}`),

  taxbook: (countryId: number) =>
    dataOnly.get<null, ApiResponse<TaxbookItem[]>>(`/api/v2/customers/taxbook/${countryId}`),

  show: (customerId: CustomerId, signal?: AbortSignal) =>
    dataOnly.get<null, ApiResponse<Customer>>(`/api/v4/customers/${customerId}`, { signal }),

  create: (companyId: CompanyId, form: CustomerCreateForm) =>
    dataOnly.post<null, ApiResponse<Customer>>(`/api/v4/customers/${companyId}`, form),

  update: ({ form, customerId }: CustomerUpdateForm) =>
    dataOnly.patch<null, ApiResponse<Customer>>(`/api/v4/customers/${customerId}`, form),

  /** Добавить представителя клиента*/
  addCustomerEmployee: (params: CustomerEmployeeCreateFormParams) =>
    dataOnly.post<null, ApiResponse<CustomerEmployee>>(
      `/api/v4/customers/user/company/${params.customerId}/channel/${params.channelId}`,
      params.form,
    ),
  /** Редактировать представителя клиента*/
  updateCustomerEmployee: (params: CustomerEmployeeUpdateFormParams) =>
    dataOnly.patch<null, ApiResponse<CustomerEmployee>>(
      `/api/v4/customers/user/company/${params.customerId}/channel/${params.channelId}`,
      params.form,
    ),
  /** Удалить представителя клиента*/
  deleteCustomerEmployee: (params: CustomerEmployeeDeleteParams) =>
    dataOnly.delete<null, ApiResponse<CustomerEmployee>>(
      `/api/v2/channels/${params.channelId}/users/${params.userId}`,
    ),

  /** updateCustomerContractorRelations Например - удаление */
  updateCustomerContractorRelations: (params: UpdateCustomerContractorRelationsParams) =>
    dataOnly.patch<null, UpdateCustomerContractorRelationsResponse>(
      `/api/v4/customers/companystatus`,
      params,
    ),

  /** Получить информацию о внутреннем чате, связанном с клиентом */
  getInternalChannel: (customerId: CustomerId, signal?: AbortSignal) =>
    dataOnly.get<null, ApiResponse<Channel>>(`/api/v4/customers/${customerId}/inner-channel`, {
      signal,
    }),
  /** Создать внутренней канал клиента */
  createInternalChannel: (customerId: CustomerId) =>
    dataOnly.post<null, ApiResponse<Channel>>(`/api/v4/customers/${customerId}/inner-channel`),

  /** Связка тегов с клиентами */
  attachTags: ({ team_id, ...form }: CustomerAttachTagForm & WithCustomer) =>
    dataOnly.put<null, ApiResponse<CustomerTagId[] | null>>(
      `api/v4/customers/${team_id}/tags`,
      form,
    ),
};

export default api;

export const apiTgGenUrl = (group?: boolean) =>
  group
    ? dataOnly.get<null, ApiResponse<TelegramUrlData>>(`/api/v3/tg/genurl/group`)
    : dataOnly.get<null, ApiResponse<TelegramUrlData>>(`/api/v3/tg/genurl`);

// на беке в конфиге - id свойства "нулевая отчетность"
export const apiCountCustomersToPay = ({ bookkeeper_team_id }: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<{ customers_count: number }>>(
    `/api/v4/customers/count-to-pay/${bookkeeper_team_id}`,
  );

export const apiCustomersUsersAllLight = ({ bookkeeper_team_id }: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse<readonly CustomerUserLightUnscoped[]>>(
    `${API_CORE_REMOTE}/v4/customers/user/company/${bookkeeper_team_id}/all-light`,
  );
