import { createEvent, restore, sample } from 'effector';
import { ChangeEvent } from 'react';
import { OptionTypeBase, ValueType } from 'react-select/src/types';
import * as RoMap from '@cubux/readonly-map';
import { EMPTY_MAP } from 'constants/utils';
import { CustomersTypicalFilter, TaxbookItemId, VariantItemId } from 'models/customer/types';
import { currentCustomerSeted, resetCurrentCustomer } from 'models/customer';

type Form = CustomersTypicalFilter;

const setField = createEvent<{ name: TaxbookItemId; value: string | boolean }>();
export const handleChange = setField.prepend(
  ({ target: { name, value, type, checked } }: ChangeEvent<HTMLInputElement>) => {
    return {
      name: +name as TaxbookItemId,
      value: type === 'checkbox' ? checked : value,
    };
  },
);
export const setFieldSelect = createEvent<{
  id: TaxbookItemId;
  value: ValueType<OptionTypeBase, boolean>;
}>();
export const setForm = createEvent<Form>();
export const resetForm = createEvent<unknown>();

export const $form = restore(setForm, EMPTY_MAP);
$form.reset(resetCurrentCustomer, resetForm);
$form
  .on(setField, (s, { name, value }) => (value ? RoMap.set(s, name, [1]) : RoMap.remove(s, name)))
  .on(setFieldSelect, (s, { id, value }) => {
    if (Array.isArray(value)) {
      return value.length
        ? RoMap.set(
            s,
            id,
            value.map((v) => v.value),
          )
        : RoMap.remove(s, id);
    } else {
      return RoMap.set(s, id, [(value as any)?.value as VariantItemId]);
    }
  });

sample({
  clock: currentCustomerSeted,
  target: $form,
  fn({ customerPropertyes }): Form {
    return new Map(customerPropertyes?.map((c) => [c.id, c.propertyValues]));
  },
});

export const formChanged = $form.updates;
