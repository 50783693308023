import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { IView } from '../../types';
import { TextButton2 } from 'ui/button/Button';
import cl from './PanelSpoiler.module.scss';

interface Props extends IView {
  title: ReactNode | string;
  titleStart?: ReactNode;
  titleEnd?: ReactNode;
  titleIsClickable?: boolean;
  expanded?: boolean;
  onExpand?: (expanded: boolean) => void;
  headClassName?: string;
  bodyClassName?: string;
}

export const PanelSpoiler: FC<Props> = ({
  title,
  titleStart = null,
  titleEnd = null,
  titleIsClickable = true,
  expanded,
  onExpand,
  className,
  children,
  headClassName,
  bodyClassName,
  ...rest
}) => {
  const [_expanded, _setExpanded] = useState(expanded ?? false);
  useEffect(() => {
    if (expanded !== undefined) {
      _setExpanded(expanded);
    }
  }, [expanded]);

  const handleToggle = useCallback(() => {
    const next = !_expanded;
    _setExpanded(next);
    onExpand?.(next);
  }, [_expanded, onExpand]);

  return (
    <div {...rest} className={cn(cl.root, _expanded ? cl._expanded : cl._collapsed, className)}>
      <div className={cn(cl.head, headClassName)}>
        {titleStart !== null && <div className={cl.hStart}>{titleStart}</div>}
        {titleIsClickable ? (
          <TextButton2 className={cl.hTitle} onClick={handleToggle}>
            {title}
          </TextButton2>
        ) : (
          <div className={cl.hTitle}>{title}</div>
        )}

        <TextButton2 size="small" icon="Down" onClick={handleToggle} className={cl.hToggle} />
        {titleEnd !== null && <div className={cl.hEnd}>{titleEnd}</div>}
      </div>
      <div className={cn(cl.body, bodyClassName)}>{children}</div>
    </div>
  );
};
