/**
 * Parse date in "loose" manner. Returns `null` for invalid input. When `Date` passed, a new `Date` will be returned.
 * @param input
 */
export const parseDate = (input: string | Date | null | undefined): Date | null => {
  if (!input) {
    return null;
  }

  if ('string' === typeof input) {
    // YYYY-MM-DD
    if (10 === input.length) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#using_date.parse
      //
      // > this is the same:
      // >
      // > Date.parse("2019-01-01")
      // > Date.parse("2019-01-01T00:00:00.000Z")
      // > Date.parse("2019-01-01T00:00:00.000+00:00")
      input = `${input}T00:00:00`;
    }
    try {
      return new Date(input);
    } catch (e) {
      return null;
    }
  }

  return new Date(input);
};

/**
 * Parse date in "strict" manner. Throws an error in case of invalid input
 * including a `Date` with invalid date.
 * @param input
 */
export const parseDateStrict = (input: string | Date): Date => {
  const result = parseDate(input);
  if (!result || isNaN(result.getTime())) {
    throw new Error('Invalid date');
  }
  return result;
};
